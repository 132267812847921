import api from "../index";

// ** --------- Blog API --------- ** //
// Create a blog
export const createBlog = (values: any) => {
  return api.post("/blog", values);
};

// Get all blogs
export const getAllBlogs = () => {
  return api.get("/blogs");
};

// Get all blogs paginated search
export const getAllBlogsPaginatedSearch = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(
      `/blogs/paginated/search?page=${page}&perPage=${perPage}`
    );
  } else {
    return await api.get(
      `/blogs/paginated/search?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get a blog by ID
export const getBlogById = (id: any) => {
  return api.get(`/blog/${id}`);
};

// Update a blog by ID
export const updateBlogById = (id: any, values: any) => {
  return api.put(`/blog/${id}`, values);
};

// Delete a blog by ID
export const deleteBlogById = (id: any) => {
  return api.delete(`/blog/${id}`);
};

// Upload blog cover image
export const uploadBlogCoverImage = async (values: any, config: any) => {
  return await api.post(`/blog/cover-image`, values, config);
};

// Upload blog content image
export const uploadBlogContentImage = async (values: any, config: any) => {
  return await api.post(`/blog/content-image`, values, config);
};

// Update blog approval by ID
export const updateBlogApprovalById = async (id: any, values: any) => {
  return await api.put(`/blog/approve/${id}`, values);
};

// ** --------- Blog Category API --------- ** //
// Create a blogCategory
export const createBlogCategory = async (values: any) => {
  return await api.post("/blog-category", values);
};

// Get all blogCategories
export const getAllBlogCategories = async () => {
  return await api.get(`/blog-categories`);
};

// Get a blogCategory by id
export const getBlogCategoryById = async (id: any) => {
  return await api.get(`/blog-category/${id}`);
};

// Update a blogCategory by id
export const updateBlogCategoryById = async (id: any, values: any) => {
  return await api.put(`/blog-category/${id}`, values);
};

// Delete a blogCategory by id
export const deleteBlogCategoryById = async (id: any) => {
  return await api.delete(`/blog-category/${id}`);
};

// ** --------- Blog Tag API --------- ** //
// Create a blog Tag
export const createBlogTag = async (values: any) => {
  return await api.post("/blog-tag", values);
};

// Get all blogBlogTags
export const getAllBlogTags = async () => {
  return await api.get(`/blog-tags`);
};

// Get a blogBlogTag by id
export const getBlogTagById = async (id: any) => {
  return await api.get(`/blog-tag/${id}`);
};

// Update a blogBlogTag by id
export const updateBlogTagById = async (id: any, values: any) => {
  return await api.put(`/blog-tag/${id}`, values);
};

// Delete a blogBlogTag by id
export const deleteBlogTagById = async (id: any) => {
  return await api.delete(`/blog-tag/${id}`);
};
