import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select, DatePicker } from "antd";
import { withRouter, useParams } from "react-router";
import { addUsageToAdmin } from "@api/user/superAdmin";
import { getAllPlans } from "@api/subscription/plan";
import { SuccessNotification, ErrorNotification } from "../..";

const initialState = {
  plan: "",
  startDate: "",
  endDate: "",
  chatsAllotted: 0,
  billInterval: "",
};

const UsageCreateModal = ({
  open,
  handleModalClick,
  handleFetchUsageHistory,
}) => {
  const { id } = useParams();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetchPlans();
  }, []);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Fetch all plans
  const fetchPlans = async () => {
    setLoading(true);
    try {
      const { data } = await getAllPlans();
      // Assuming each plan has an _id and a name, match that structure for "Lifetime"
      const lifetimePlan = { _id: "lifetime", name: "Lifetime" };
      // Add "Lifetime" option to the beginning or end of the plans array
      // This example adds it to the end
      setPlans([...data, lifetimePlan]);
    } catch (error) {
      console.error("Error fetching plans:", error);
      // Optionally handle the error (e.g., show an error notification)
    }
    setLoading(false);
  };

  // Handle tag create
  const handleUsageAdd = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validate form
    if (
      !values.plan ||
      !values.startDate ||
      !values.endDate ||
      !values.chatsAllotted ||
      !values.billInterval
    ) {
      setLoading(false);
      return ErrorNotification("Please enter all fields");
    }

    // Add usage
    addUsageToAdmin(id, values)
      .then(() => {
        setValues(initialState);
        handleModalClick();
        handleFetchUsageHistory();
        SuccessNotification("Usage added successfully");
      })
      .catch((err) => {
        setLoading(false);
        console.log("ADD_USAGE_ERROR", err);
        ErrorNotification("Usage could not be added");
      });
  };

  return (
    <>
      <Modal
        title="Add Usage"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleUsageAdd}
            loading={loading}
          >
            Add
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Plan */}
          <Form.Item label="Plan">
            <Select
              name="plan"
              placeholder="Select plan"
              value={values.plan}
              onChange={(value) => {
                setValues({
                  ...values,
                  plan: value,
                });
              }}
            >
              {plans.map((plan) => (
                <Select.Option key={plan._id} value={plan.name}>
                  {plan.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Start date */}
          <Form.Item label="Start Date">
            <DatePicker
              className="hp-mb-16 hp-mr-16"
              onChange={(date, dateString) =>
                setValues({ ...values, startDate: dateString })
              }
              autoFocus
              popupStyle={{
                height: "auto",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              style={{
                width: "100%",
              }}
              popupAlign={{
                offset: [0, 0],
              }}
            />
          </Form.Item>

          {/* End date */}
          <Form.Item label="End Date">
            <DatePicker
              className="hp-mb-16 hp-mr-16"
              onChange={(date, dateString) =>
                setValues({ ...values, endDate: dateString })
              }
              autoFocus
              popupStyle={{
                height: "auto",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              style={{
                width: "100%",
              }}
              popupAlign={{
                offset: [0, 0],
              }}
            />
          </Form.Item>

          {/* Chats Allotted */}
          <Form.Item label="Chats Allotted">
            <Input
              name="chatsAllotted"
              type="number"
              value={values.chatsAllotted}
              onChange={handleFormChange}
              placeholder="Chats Allotted"
            />
          </Form.Item>

          {/* Bill Interval */}
          <Form.Item label="Bill Interval">
            <Select
              name="billInterval"
              placeholder="Select bill interval"
              value={values.billInterval}
              onChange={(value) => {
                setValues({
                  ...values,
                  billInterval: value,
                });
              }}
            >
              {["month", "year"].map((interval, index) => (
                <Select.Option key={index} value={interval}>
                  {interval}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default withRouter(UsageCreateModal);
