import { useEffect, useState } from "react";
import { Col, Row, Tabs } from "antd";
import { useParams } from "react-router";
import { ReactFlowProvider } from "reactflow";

import Header from "./header";
// Redux Action
import { useDispatch, useSelector } from "react-redux";
import { getChatbotFlowAnalyticsAction } from "../../../../redux/actions/chatbotActions";
// Analytics components
import FlowAnalytics from "./flowAnalytics";
import { ResponseTable, VisitorDemographics } from "../../../../components";
import { useTranslation } from "react-i18next";

const ChatbotFlowAnalytics = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { bid } = useParams<{ bid: string }>();
  const { loading } = useSelector((state: any) => state?.alert);

  const [graphLoading, setGraphLoading] = useState(false);

  useEffect(() => {
    setGraphLoading(true);
    // Dispatch action to get current chatbot flow analytics
    dispatch(getChatbotFlowAnalyticsAction(bid, setGraphLoading));
  }, [dispatch, bid]);

  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "6px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <ReactFlowProvider>
            {/* Header */}
            <Header isAnalytics={true} />

            {loading ? (
              <div className="builder-dual-ring"></div>
            ) : (
              <Row>
                <Col
                  span={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    right: 0,
                    background: "#f0f2f8",
                    transition: "all 0.2s ease-out",
                    zIndex: 90,
                  }}
                >
                  <div
                    style={{
                      padding: "0px 0px",
                      height: "100%",
                      display: "flex",
                    }}
                  >
                    <Tabs
                      type="card"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        position: "absolute",
                      }}
                    >
                      {/* Flow Analytics */}
                      <Tabs.TabPane tab={t("analytics.flowAnalytics")} key="1">
                        <FlowAnalytics />
                      </Tabs.TabPane>

                      {/* Response Data */}
                      <Tabs.TabPane
                        // tab="Response Data"
                        tab={t("analytics.responseData")}
                        key="2"
                        style={{
                          height: "100%",
                          overflow: "auto",
                        }}
                      >
                        <ResponseTable
                          parentLoading={graphLoading}
                          selectedChatbot={bid}
                        />
                      </Tabs.TabPane>

                      {/* Demographics */}
                      <Tabs.TabPane
                        tab={t("analytics.demographics")}
                        key="3"
                        style={{
                          height: "100%",
                          overflow: "auto",
                        }}
                      >
                        <VisitorDemographics loading={graphLoading} />
                      </Tabs.TabPane>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            )}
          </ReactFlowProvider>
        </div>
      </div>
    </>
  );
};

export default ChatbotFlowAnalytics;
