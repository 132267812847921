import React, { useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface QuillEditorProps {
  initialContent?: string;
  onEditorChange: (content: string, fontSize: string) => void;
  placeholderText?: string;
}

const QuillEditor: React.FC<QuillEditorProps> = ({
  initialContent,
  onEditorChange,
  placeholderText,
}) => {
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (initialContent && quillRef.current) {
      quillRef.current.getEditor().getText();
    }
  }, [initialContent]);

  const handleEditorChange = (content: string) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const selection = editor.getSelection();

      if (editor) {
        onEditorChange(content, "");
      }

      if (selection) {
        const format = editor.getFormat(selection);
        if (format.size) {
          onEditorChange(content, format.size);
        } else {
          onEditorChange(content, "normal");
        }
      }
    }
  };

  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    };

    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.root.addEventListener("paste", handlePaste);
    }

    return () => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        quill.root.removeEventListener("paste", handlePaste);
      }
    };
  }, []);

  const TOOLBAR_OPTIONS = [
    ["bold", "italic", "underline", "strike", "link"],
    ["clean"],
  ];

  const quillStyle = `
    .ql-snow .ql-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: #fff;
    }
    .ql-snow .ql-picker.ql-size {
      margin-left: -14px;
      width:70px
    }
    .ql-snow .ql-list{
      margin-left: -5px;
    }
    .ql-snow .ql-link{
      margin-left: -5px;
    }

    .ql-snow .ql-strike,
    .ql-snow .ql-blockquote,
    .ql-snow .ql-link{
      margin-left: 5px;
    }
   
    .ql-snow .ql-editor {
      height: 90px;
      overflow-y: scroll;
    }
  `;

  return (
    <div>
      <style>{quillStyle}</style>
      <ReactQuill
        theme="snow"
        ref={quillRef}
        defaultValue={initialContent}
        onChange={handleEditorChange}
        modules={{
          toolbar: TOOLBAR_OPTIONS,
        }}
        placeholder={placeholderText}
        className="custom-quill-editor"
        style={{
          marginBottom: "20px",
          marginTop: "10px",
        }}
      />
    </div>
  );
};

export default QuillEditor;
