import { Link } from "react-router-dom";
import "@assets/css/Integrations.css";
import { useTranslation } from "react-i18next";

const IntegrationsCard = ({ CardData, workspaceId }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="integration-card-main">
        {CardData &&
          CardData?.map((item, index) => {
            const { Icon, heading, desc, pathName } = item;
            return (
              <Link
                to={`/a/ws/${workspaceId}/integration/${pathName}`}
                key={index}
              >
                <div className="integration-card">
                  <div className="coming-soon-ribbon">
                    {t("integrations.comingSoon")}
                  </div>
                  <div className="integration-card-inner">
                    <div>
                      <img src={Icon} alt="icon" />
                    </div>
                    <div className="heading"> {heading}</div>
                    <p>{desc}</p>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </>
  );
};

export default IntegrationsCard;
