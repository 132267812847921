// Chatbot Actions
export const CHATBOT_TYPES = {
  // Current Chatbot
  CURRENT_CHATBOT: "CURRENT_CHATBOT",
  CURRENT_BOT_FLOW_DATA: "CURRENT_BOT_FLOW_DATA",
  TOGGLE_NODES_SIDEBAR: "TOGGLE_NODES_SIDEBAR",
  // Templates
  GET_CATEGORIES_TEMPLATES: "GET_CATEGORIES_TEMPLATES",
  GET_CATEGORY_CHATBOTS: "GET_CATEGORY_CHATBOTS",
  TEMPLATE_PREVIEW: "TEMPLATE_PREVIEW",
  // Customizations
  UPDATE_CHATBOT_CUSTOMIZATIONS: "UPDATE_CHATBOT_CUSTOMIZATIONS",
  SAVE_CHATBOT_CUSTOMIZATIONS: "SAVE_CHATBOT_CUSTOMIZATIONS",
  SAVE_CHATBOT: "SAVE_CHATBOT",
  // Publish
  PUBLISH_CHATBOT: "PUBLISH_CHATBOT",
  GET_CHATBOT_PUBLISHED_FLOW_VERSION: "GET_CHATBOT_PUBLISHED_FLOW_VERSION",
  DELETE_CHATBOT_PUBLISHED_FLOW_VERSION:
    "DELETE_CHATBOT_PUBLISHED_FLOW_VERSION",
  // DISABLE_CHATBOT
  DISABLE_CHATBOT: "DISABLE_CHATBOT",
  // Analytics
  GET_CHATBOT_FLOW_ANALYTICS: "GET_CHATBOT_FLOW_ANALYTICS",
  // Responses
  GET_CHATBOT_RESPONSES_ANSWER_VARIABLES:
    "GET_CHATBOT_RESPONSES_ANSWER_VARIABLES",
  UPDATE_CHATBOT_RESPONSES_ANSWER_VARIABLES:
    "UPDATE_CHATBOT_RESPONSES_ANSWER_VARIABLES",
  // Clear
  CLEAR_CHATBOT_STATES: "CLEAR_CHATBOT_STATES",
  // NOTIFICATIONS
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
};
