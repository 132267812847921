// Icons
import GoogleSheets from "../../../assets/images/components/sheets.png";
import Calendly from "../../../assets/images/components/calendly.png";
import Salesforce from "../../../assets/images/components/salesforce.png";
import AirTable from "../../../assets/images/components/airtable.png";
import GoogleCalender from "../../../assets/images/components/google-calendar.png";
import HubSpot from "../../../assets/images/components/hubspot.png";
import Zapier from "../../../assets/images/components/zapier.png";
import Zoho from "../../../assets/images/components/zoho.png";

// translations
import { useTranslation } from "react-i18next";

const IntegrationData = () => {
  const { t } = useTranslation();

  const CardData = [
    {
      pathName: "calendly",
      Icon: Calendly,
      heading: t("integrations.calendly"),
      desc: t("integrations.calendyDesc"),
    },
    {
      pathName: "googlesheet",
      Icon: GoogleSheets,
      heading: t("integrations.googleSheet"),
      desc: t("integrations.googleSheetDesc"),
    },
    {
      pathName: "salesforce",
      Icon: Salesforce,
      heading: t("integrations.salesForce"),
      desc: t("integrations.salesForceDesc"),
    },
    {
      pathName: "airtable",
      Icon: AirTable,
      heading: t("integrations.airtable"),
      desc: t("integrations.airtableDesc"),
    },
    {
      pathName: "google Calendar",
      Icon: GoogleCalender,
      heading: t("integrations.googleCalendar"),
      desc: t("integrations.googleCalendarDesc"),
    },
    {
      pathName: "hubspot",
      Icon: HubSpot,
      heading: t("integrations.hubSpot"),
      desc: t("integrations.hubSpotDesc"),
    },
    {
      pathName: "zapier",
      Icon: Zapier,
      heading: t("integrations.zapier"),
      desc: t("integrations.zapierDesc"),
    },
    {
      pathName: "zoho",
      Icon: Zoho,
      heading: t("integrations.zoho"),
      desc: t("integrations.zohoDesc"),
    },
  ];
  return { CardData };
};

export default IntegrationData;
