import { FC } from "react";
import { Row, Col } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { WelcomeNodeData } from "../../../typings";
import useStore from "../../../../../store/store";
import StartHere from "@assets/images/pages/builder/start-here.png";
import { WaveIcon } from "@assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const WelcomeNode: FC<NodeProps<WelcomeNodeData>> = ({ id }) => {
  const { getSourceConnectionAllowed, getNode } = useStore((state: any) => ({
    getSourceConnectionAllowed: state?.allowSourceConnection,
    getNode: state?.getNode,
  }));
  const { t } = useTranslation();
  const node = getNode(id);

  if (!node) {
    return null; // or some fallback component
  }

  const allowSourceConnection = getSourceConnectionAllowed(id);

  return (
    <>
      {/* Start here image on top right */}
      <div
        style={{
          position: "absolute",
          top: "-100px",
          right: "-100px",
          zIndex: 1,
        }}
      >
        <img
          src={StartHere}
          alt="start here"
          style={{
            width: "150px",
            height: "150px",
          }}
        />
      </div>

      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "200px",
          maxWidth: "220px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
        id="welcome-node"
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={16}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={WaveIcon}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="Wave"
              />
              {t("flowBuilder.builderNodes.welcomeNode")}
            </Col>
          </Row>
        </div>

        <div>
          <Col span={24} className="hp-text-center">
            <div
              dangerouslySetInnerHTML={{ __html: node?.data?.text }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
              }}
            />

            {!node?.data?.disableImage && (
              <div>
                {/* Image */}
                <img
                  src={
                    node?.data?.image ||
                    "https://botdefaults.s3.ap-south-1.amazonaws.com/welcome_gif_1.gif"
                  }
                  alt="welcome"
                  style={{
                    borderRadius: "5px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                />
              </div>
            )}
          </Col>
        </div>
      </Col>
      <Handle
        type="source"
        isConnectable={allowSourceConnection}
        isConnectableStart={allowSourceConnection}
        position={Position?.Right}
        id="source"
        className="node-source"
      />
    </>
  );
};

export default WelcomeNode;
