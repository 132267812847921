import { useState, useEffect } from "react";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import Arrow from "@assets/images/pages/customize/down.png";
import PaintPaletteIcon from "@assets/images/pages/customize/paint-palette.png";
import "@assets/css/ChatUi.css";
import { PopoverColorPicker } from "../../../../../Popover/PopoverColorPicker";
import { useTranslation } from "react-i18next";

// Solid and Gradient Options
const options = [
  {
    label: "Solid",
    value: "Solid",
  },
  {
    label: "Gradient",
    value: "Gradient",
  },
];

const smoothThemes = [
  {
    colorCode: "blue",
  },
  {
    colorCode: "orange",
  },
  {
    colorCode: "pink",
  },
  {
    colorCode: "green",
  },
  {
    colorCode: "black",
  },
  {
    colorCode: "purple",
  },
  {
    colorCode: "yellow",
  },
  {
    colorCode: "hotpink",
  },
  {
    colorCode: "skyblue",
  },
  {
    colorCode: "brown",
  },
  {
    colorCode: "red",
  },
  {
    colorCode: "violet",
  },
  {
    colorCode: "fuchsia",
  },
  {
    colorCode: "lime",
  },
  {
    colorCode: "olive",
  },
  {
    colorCode: "navy",
  },
  {
    colorCode: "teal",
  },
  {
    colorCode: "aqua",
  },
];

const ModernThemes = [
  {
    colorCodeOne: "#FF3E9D",
    colorCodeTwo: "#0E1F40",
  },
  {
    colorCodeOne: "#d4fc79",
    colorCodeTwo: "#000",
  },
  {
    colorCodeOne: "#a357e5",
    colorCodeTwo: "#4a00e0",
  },
  {
    colorCodeOne: "#fc5c7d",
    colorCodeTwo: "#6a82fb",
  },
  {
    colorCodeOne: "#fbd786",
    colorCodeTwo: "#fe8186",
  },
  {
    colorCodeOne: "#646485",
    colorCodeTwo: "#1d1569",
  },
  {
    colorCodeOne: "#190a05",
    colorCodeTwo: "#870000",
  },
  {
    colorCodeOne: "#FF61D2",
    colorCodeTwo: "#FE9090",
  },
  {
    colorCodeOne: "#72FFB6",
    colorCodeTwo: "#10D164",
  },
  {
    colorCodeOne: "#FD8451",
    colorCodeTwo: "#FFBD6F",
  },
  {
    colorCodeOne: "#305170",
    colorCodeTwo: "#6DFC6B",
  },
  {
    colorCodeOne: "#00C0FF",
    colorCodeTwo: "#4218B8",
  },
  {
    colorCodeOne: "#009245",
    colorCodeTwo: "#FCEE21",
  },
  {
    colorCodeOne: "#0100EC",
    colorCodeTwo: "#FB36F4",
  },
  {
    colorCodeOne: "#38A2D7",
    colorCodeTwo: "#561139",
  },
  {
    colorCodeOne: "#FFDB01",
    colorCodeTwo: "#0E197D",
  },
  {
    colorCodeOne: "#bdc3c7 ",
    colorCodeTwo: "#2c3e50",
  },
  {
    colorCodeOne: "#004e92",
    colorCodeTwo: "#000428",
  },
];

const WidgetAppearance = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});

  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState("Solid");
  const [smoothColor, setSmoothColor] = useState("blue");
  const [modernColor, setModernColor] = useState("#FF3E9D");
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Set widgetIconThemeType
    setChecked(customizations?.widgetIconThemeType);
  }, []);

  // showMore and showLess Solid themes
  const displaySmoothThemes = showMore
    ? smoothThemes
    : smoothThemes.slice(0, 8);

  // showMore and showLess Gradient themes
  const displayModernThemes = showMore
    ? ModernThemes
    : ModernThemes.slice(0, 8);

  const onChangeTheme = ({ target: { value } }) => {
    value === "Solid" ? setChecked("Solid") : setChecked("Gradient");
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetIconThemeType",
        value === "Solid" ? "Solid" : "Gradient"
      )
    );
  };

  // handleSmoothColor
  const handleSmoothColor = (value) => {
    setSmoothColor(value);
    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "widgetIconBgColor", value)
    );
  };

  // handleCustomColor
  const handleCustomColor = (value) => {
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetIconBgColor",
        value.hex
      )
    );
  };
  // handleModernCustomColor
  const handleModernCustomColorOne = (value1) => {
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetGradientBgOne",
        value1
      )
    );
  };

  const handleModernCustomColorTwo = (value2) => {
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetGradientBgTwo",
        value2
      )
    );
  };

  // handleModernColor
  const handleModernColor = (value1, value2) => {
    setModernColor(value1);
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetGradientBgOne",
        value1
      )
    );
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetGradientBgTwo",
        value2
      )
    );
  };

  return (
    <div className="dropdown-menu">
      <div
        onClick={() => {
          setVisible(!visible);
        }}
        className="dropdown-trigger"
      >
        <span>
          <img
            src={PaintPaletteIcon}
            style={{
              height: "20px",
              width: "20px",
              marginRight: "8px",
            }}
            alt="position-icon"
          />
          {t("customize.widget.appearance")}
        </span>
        <img
          src={Arrow}
          className={visible ? "arrow-rotate" : ""}
          style={{
            height: "20px",
            width: "20px",
            marginLeft: "auto",
          }}
          alt="arrow-icon"
        />
      </div>
      {visible && (
        <>
          <div
            style={{
              padding: "20px 25px",
              backgroundColor: "#d5d7e4",
            }}
            className="fadeIn"
          >
            {/* Widget Color Toggle  */}
            <span> {t("customize.widget.chooseWidgetIconTheme")} </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "12px 0px 15px 0px",
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChangeTheme}
                value={checked}
                optionType="button"
                buttonStyle="solid"
              />
            </div>

            {checked === "Solid" ? (
              <div className="theme-container">
                {displaySmoothThemes?.map((item, index) => {
                  const { colorCode } = item;
                  return (
                    <div
                      className={`smooth-tab ${
                        showMore ? "showOff" : "smooth-tab"
                      }`}
                      style={{
                        backgroundColor: smoothColor === colorCode && "#2368ff",
                        borderRadius: "100%",
                        padding: "2px",
                        boxShadow:
                          smoothColor === colorCode &&
                          "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          height: "58px",
                          width: "58px",
                          border: `12px solid ${colorCode}`,
                          backgroundColor: "#fff",
                          borderRadius: "100%",
                          transition: "border 0.3s ease-in-out",
                        }}
                        onClick={() => handleSmoothColor(colorCode)}
                      ></div>
                    </div>
                  );
                })}

                <div className="color-picker">
                  <PopoverColorPicker
                    color={customizations?.widgetIconBgColor}
                    onChange={(color) => handleCustomColor(color)}
                  />
                </div>
                <span
                  onClick={() => setShowMore(!showMore)}
                  className="showMore"
                >
                  {showMore
                    ? t("customize.widget.showLess")
                    : t("customize.widget.showAll")}
                </span>
              </div>
            ) : (
              <div className="theme-container">
                {displayModernThemes?.map((item, index) => {
                  const { colorCodeOne, colorCodeTwo } = item;
                  return (
                    <div
                      className={`modern-tab ${
                        showMore ? "showOff" : "modern-tab"
                      }`}
                      style={{
                        backgroundColor:
                          modernColor === colorCodeOne && "#2368ff",
                        boxShadow:
                          modernColor === colorCodeOne &&
                          "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          height: "58px",
                          width: "58px",
                          border:
                            modernColor === colorCodeOne
                              ? "3px solid #fff"
                              : "",
                          background: `linear-gradient(to right, ${colorCodeOne}, ${colorCodeTwo})`,
                          borderRadius: "100%",
                          transition: "border 0.3s ease-in-out",
                        }}
                        onClick={() =>
                          handleModernColor(colorCodeOne, colorCodeTwo)
                        }
                      ></div>
                    </div>
                  );
                })}

                <span
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <label
                    htmlFor="color-picker"
                    className="flex-class-label"
                    style={{
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    {t("customize.widget.customGradientColor")}:
                    <div
                      className="color-picker"
                      style={{
                        marginLeft: "10px",
                        marginTop: "-5px",
                      }}
                    >
                      <PopoverColorPicker
                        color={customizations?.widgetGradientBgOne}
                        onChange={(color) =>
                          handleModernCustomColorOne(color.hex)
                        }
                      />
                    </div>
                    <div
                      className="color-picker"
                      style={{
                        marginLeft: "10px",
                        marginTop: "-5px",
                      }}
                    >
                      <PopoverColorPicker
                        color={customizations?.widgetGradientBgTwo}
                        onChange={(color) =>
                          handleModernCustomColorTwo(color.hex)
                        }
                      />
                    </div>
                  </label>
                </span>

                <span
                  onClick={() => setShowMore(!showMore)}
                  className="showMore"
                >
                  {showMore
                    ? t("customize.widget.showLess")
                    : t("customize.widget.showAll")}
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WidgetAppearance;
