import { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";
import "./style.scss";

const Tour = ({ tourId, steps, tutComplete, handleTourComplete }) => {
  useEffect(() => {
    // if Tutorial is completed return null
    if (tutComplete) {
      return null; // Skip Shepherd Tour if not the first visit
    }

    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        classes: "shepherd-tour",
        scrollTo: { behavior: "smooth", block: "center" },
      },
      useModalOverlay: true,
      disableScrolling: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      spotlightClicks: true,
      showProgress: true,
      showSkipButton: true,
    });

    const handleSkipClick = (e) => {
      if (e.target && e.target.matches(".shepherd-skip-link")) {
        tour.cancel();
        handleTourComplete(tourId, true);
      }
    };

    steps.forEach((step, index) => {
      const footerHtml = `
        <div class='shepherd-footer-skip'>
          <span class='shepherd-skip-link'>Skip</span>
        </div>
      `;

      const buttons = [];

      if (index === 0) {
        buttons.push({
          text: "Next",
          action: tour.next,
          classes: "shepherd-button-primary",
        });
      } else if (index === steps.length - 1) {
        buttons.push({
          text: "Previous",
          action: tour.back,
          classes: "shepherd-button-secondary",
        });
        buttons.push({
          text: step?.locale?.last || "Done",
          action: () => {
            tour.complete();
            handleTourComplete(tourId, true); // Pass the tourId and completion status as a callback response
          },
          classes: "shepherd-button-primary",
        });
      } else {
        buttons.push({
          text: "Previous",
          action: tour.back,
          classes: "shepherd-button-secondary",
        });
        buttons.push({
          text: "Next",
          action: tour.next,
          classes: "shepherd-button-primary",
        });
      }

      tour.addStep({
        ...step,
        text: ReactDOMServer.renderToString(step.text) + footerHtml,
        buttons,
      });
    });

    tour.start();

    // Attach event listener for skip clicks
    document.body.addEventListener("click", handleSkipClick);

    return () => {
      tour.complete();

      document.body.removeEventListener("click", handleSkipClick);
    };
  }, [steps, tourId, handleTourComplete, tutComplete]);

  return null;
};

export default Tour;
