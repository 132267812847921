import { Card, Row, Skeleton } from "antd";

const UpgradeCardSkeleton = () => (
  <Card
    hoverable
    style={{
      height: "100%",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      position: "relative",
      overflow: "hidden",
      margin: "0 auto",
      borderRadius: "8px",
    }}
  >
    <Row justify={"space-between"} style={{ flexWrap: "nowrap" }}>
      <Skeleton active paragraph={{ rows: 1 }} />
    </Row>

    {/* input text */}
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        marginTop: "25px",
      }}
    >
      <Skeleton active paragraph={{ rows: 2 }} />
    </div>

    {/* button */}
    <div
      style={{
        height: "40px",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "25px auto 0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
          height: "40px",
        }}
      />
    </div>

    <Row
      justify={"space-between"}
      style={{
        flexWrap: "nowrap",
        marginTop: "25px",
      }}
    >
      <Skeleton active paragraph={{ rows: 1 }} />
    </Row>

    {/* input text */}
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        marginTop: "25px",
      }}
    >
      <Skeleton active paragraph={{ rows: 2 }} />
    </div>

    {/* button */}
    <div
      style={{
        height: "40px",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "25px auto 0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
          height: "40px",
        }}
      />
    </div>

    {/* input text */}
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        marginTop: "25px",
      }}
    >
      <Skeleton active paragraph={{ rows: 1 }} />
    </div>

    {/* Image */}
    <div
      style={{
        height: "130px",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          height: "100px",
          width: "100%",
        }}
      />
    </div>
  </Card>
);
export default UpgradeCardSkeleton;
