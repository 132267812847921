import React, { FC, useState, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Radio,
  Tooltip,
  Switch,
  Select,
  Divider,
  Checkbox,
  message,
} from "antd";
import DatePicker from "react-multi-date-picker";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import { shallow } from "zustand/shallow";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import useStore, { selector } from "../../../../../store/store";
import { CalendarNode } from "../../../../../common/nodes/typings";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const { Option } = Select;

const CalendarNodeDataEditor: FC<CalendarNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);

  const [calendarPrompt, setCalendarPrompt] = useState(
    state?.data?.calendarPrompt
  );
  const [dateType, setDateType] = useState("date");
  const [showPastDates, setShowPastDates] = useState(
    state?.data?.showPastDates
  );
  const [excludeDays, setExcludeDays] = useState(state?.data?.excludeDays);
  const [excludeDates, setExcludeDates] = useState(state?.data?.excludeDates);
  const [showTimeSelection, setShowTimeSelection] = useState(
    state?.data?.showTimeSelection
  );
  const [weeklyHours, setWeeklyHours] = useState(state?.data?.weeklyHours);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const { t } = useTranslation();

  const daysOfWeek = [
    t("flowBuilder.sidebar.editor.askQuestionNodes.sunday"),
    t("flowBuilder.sidebar.editor.askQuestionNodes.monday"),
    t("flowBuilder.sidebar.editor.askQuestionNodes.tuesday"),
    t("flowBuilder.sidebar.editor.askQuestionNodes.wednesday"),
    t("flowBuilder.sidebar.editor.askQuestionNodes.thursday"),
    t("flowBuilder.sidebar.editor.askQuestionNodes.friday"),
    t("flowBuilder.sidebar.editor.askQuestionNodes.saturday"),
  ];

  // Each time calendarNodeData changes, update the state
  useEffect(() => {
    setCalendarPrompt(state?.data?.calendarPrompt);
    setShowPastDates(state?.data?.showPastDates);
    setExcludeDays(state?.data?.excludeDays);
    setExcludeDates(state?.data?.excludeDates);
    setShowTimeSelection(state?.data?.showTimeSelection);
    setWeeklyHours(state?.data?.weeklyHours);
    setAnswerVariable(state?.data?.answerVariable);
  }, [state]);

  useEffect(() => {
    // Extracting the day names that are not available
    const unavailableDaysNames = weeklyHours
      .filter((day: any) => !day.available)
      .map((day: any) => daysOfWeek[day.day]);
    setExcludeDays(unavailableDaysNames);
  }, [weeklyHours]);

  // Handle calendar prompt change
  const handleCalendarPromptChange = (content: string) => {
    setCalendarPrompt(content);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        calendarPrompt: content,
      },
    });
  };

  // Tab 1
  // Date Type Change
  const onDateTypeChange = (e: any) => {
    setDateType(e.target.value);
  };

  // Show Past Dates Change
  const onShowPastDatesChange = (checked: boolean) => {
    setShowPastDates(checked);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        showPastDates: checked,
      },
    });
  };

  // Tab 2

  // Exclude Dates Change
  const handleExcludeDatesChange = (value: any) => {
    setExcludeDates(value);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        excludeDates: value,
      },
    });
  };

  // Show Time Selection Change
  const onShowTimeSelectionChange = (checked: boolean) => {
    setShowTimeSelection(checked);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        showTimeSelection: checked,
      },
    });
  };

  // Weekly Hours Change
  const handleWeeklyHoursChange = (e: any, val: number) => {
    if (e.target.checked) {
      // Find the object in array with day === val and update available to true
      const dayToChange: any = state?.data?.weeklyHours.find(
        (day: any) => day.day === val
      );
      dayToChange.available = true;
      // if dayToChange.slots is empty, add default slot
      if (dayToChange.slots.length === 0) {
        dayToChange.slots.push({
          start: "09:00",
          end: "17:00",
        });
      }

      setWeeklyHours([...state?.data?.weeklyHours]);
    } else {
      // Find the object in array with day === val and update available to false
      const dayToChange: any = state?.data?.weeklyHours.find(
        (day: any) => day.day === val
      );
      dayToChange.available = false;
      setWeeklyHours([...state?.data?.weeklyHours]);
    }

    // Extracting the day names that are not available
    const unavailableDaysNames = weeklyHours
      .filter((day: any) => !day.available)
      .map((day: any) => daysOfWeek[day.day]);

    // Set excludeDays to all the unavailable weekdays
    setExcludeDays(unavailableDaysNames);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
        excludeDays: unavailableDaysNames,
      },
    });
  };

  const handleAddTimeSlot = (val: number) => {
    // Find the object in array with day === val and add a new time slot
    const dayToChange: any = state?.data?.weeklyHours.find(
      (day: any) => day.day === val
    );

    dayToChange.slots.push({
      start: "09:00",
      end: "17:00",
    });

    setWeeklyHours([...state?.data?.weeklyHours]);
    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
      },
    });
  };

  const handleDeleteTimeSlot = (val: number, index: number) => {
    // Find the object in array with day === val and delete the time slot
    const dayToChange: any = state?.data?.weeklyHours.find(
      (day: any) => day.day === val
    );

    // Remove that exact slot
    dayToChange.slots.splice(index, 1);

    // If there are no more slots, set available to false
    if (dayToChange.slots.length === 0) {
      dayToChange.available = false;
    }

    // Set checked to false if available is false

    setWeeklyHours([...state?.data?.weeklyHours]);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
      },
    });
  };

  const handleWeeklyHoursSlotChange = (
    value: any,
    day: number,
    slot: any,
    type: any
  ) => {
    // Find the object in array with day === val and update the time slot
    const dayToChange: any = state?.data?.weeklyHours.find(
      (dayT: any) => dayT.day === day
    );

    // Find the slot to change
    const slotToChange: any = dayToChange.slots.find(
      (slotToChange: any) => slotToChange === slot
    );

    // Update the slot
    slotToChange[type] = value;

    setWeeklyHours([...state?.data?.weeklyHours]);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
      },
    });
  };

  const times: any = [
    "00:00",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
    "04:15",
    "04:30",
    "04:45",
    "05:00",
    "05:15",
    "05:30",
    "05:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
  ];

  // Select options
  const renderTimeOptions = () => {
    return (
      <>
        {times.map((time: any, index: any) => (
          <Option key={index} value={time}>
            {time}
          </Option>
        ))}
      </>
    );
  };

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t(
              "flowBuilder.sidebar.editor.askQuestionNodes.askCalendarNodeInfo"
            )}
          </p>
        </Row>

        {/* User Prompt */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.askQuestionNodes.questionText")}
          </Col>
        </Divider>

        <Col span={24} style={{ marginTop: "8px" }}>
          <div>
            <QuillEditor
              initialContent={calendarPrompt}
              onEditorChange={handleCalendarPromptChange}
              placeholderText="Type question here!"
            />
          </div>
        </Col>
      </Row>

      <Col
        span={24}
        style={{
          marginTop: "20px",
          marginLeft: "10px",
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <p style={{ fontSize: "1rem", marginBottom: "-0.5rem" }}>
                {t("flowBuilder.sidebar.editor.askQuestionNodes.dateSelection")}
              </p>
            }
            key="1"
          >
            <h5 className="hp-mb-12 hp-mt-8">
              {t(
                "flowBuilder.sidebar.editor.askQuestionNodes.dateSelectionTitle"
              )}
            </h5>

            <Radio.Group
              onChange={onDateTypeChange}
              value={dateType}
              style={{ marginLeft: "4px" }}
            >
              <Radio value="date">
                {t("flowBuilder.sidebar.editor.askQuestionNodes.singleDate")}
              </Radio>

              <Tooltip
                placement="top"
                title={"This feature is being developed"}
                className="hp-ml-8"
              >
                <Radio value="date-range" disabled>
                  {t("flowBuilder.sidebar.editor.askQuestionNodes.dateRange")}
                </Radio>
              </Tooltip>
            </Radio.Group>

            {/* Show Past Dates Switch in the same line */}
            <Row
              style={{
                marginTop: "25px",
              }}
            >
              <Col span={14}>
                <h5 className="hp-mb-12 hp-mt-8">
                  {t(
                    "flowBuilder.sidebar.editor.askQuestionNodes.showPastDates"
                  )}
                </h5>
              </Col>

              <Col
                span={6}
                style={{
                  marginTop: "7px",
                }}
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked
                  onChange={onShowPastDatesChange}
                  checked={showPastDates}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane
            tab={
              <p style={{ fontSize: "1rem", marginBottom: "-0.5rem" }}>
                {t("flowBuilder.sidebar.editor.askQuestionNodes.timeSlot")}
              </p>
            }
            key="2"
          >
            {/* Show time selection */}
            <Row
              style={{
                marginTop: "5px",
              }}
            >
              <Col span={14}>
                <Tooltip
                  placement="top"
                  title="Once the user selects a date, they will be prompted to select a time"
                  className="hp-ml-8"
                >
                  <h5 className="hp-mb-12 hp-mt-8">
                    {t(
                      "flowBuilder.sidebar.editor.askQuestionNodes.showTimeSelection"
                    )}
                  </h5>
                </Tooltip>
              </Col>

              <Col
                span={6}
                style={{
                  marginTop: "7px",
                  marginLeft: "3px",
                }}
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={onShowTimeSelectionChange}
                  checked={showTimeSelection}
                />
              </Col>
            </Row>

            {/* Add your available time */}
            {showTimeSelection && (
              <>
                <Row
                  style={{
                    marginTop: "5px",
                  }}
                >
                  <Col span={24}>
                    <h5 className="hp-mb-12 hp-mt-8">
                      {t(
                        "flowBuilder.sidebar.editor.askQuestionNodes.setWeeklyHours"
                      )}
                    </h5>
                  </Col>
                </Row>

                <Row
                  style={{
                    marginTop: "2px",
                  }}
                >
                  <Col span={24}>
                    {weeklyHours?.map((day: any, index: any) => {
                      return (
                        <div key={index}>
                          <Row align="middle">
                            <Col span={12}>
                              <Checkbox
                                checked={day.available}
                                onChange={(e) =>
                                  handleWeeklyHoursChange(e, day.day)
                                }
                              >
                                {day.dayName}
                              </Checkbox>
                            </Col>
                          </Row>

                          {day.available &&
                            day.slots.map((slot: any, j: any) => {
                              return (
                                <Row
                                  key={j}
                                  align="middle"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {/* Display the time slot options based on the calendarNodeData.weeklyHours[0].slots */}
                                  <>
                                    <Col
                                      span={17}
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {/* Select Dropdown with time intervals of 15 mins 24h format */}
                                      <Select
                                        defaultValue={slot.start}
                                        style={{ width: 80 }}
                                        size="small"
                                        onChange={(value: any) =>
                                          handleWeeklyHoursSlotChange(
                                            value,
                                            day.day,
                                            slot,
                                            "start"
                                          )
                                        }
                                      >
                                        {renderTimeOptions()}
                                      </Select>

                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          marginRight: "5px",
                                        }}
                                      >
                                        -
                                      </span>

                                      <Select
                                        defaultValue={slot.end}
                                        style={{ width: 80 }}
                                        size="small"
                                        onChange={(value: any) =>
                                          handleWeeklyHoursSlotChange(
                                            value,
                                            day.day,
                                            slot,
                                            "end"
                                          )
                                        }
                                      >
                                        {renderTimeOptions()}
                                      </Select>
                                    </Col>

                                    {/* Plus Icon */}
                                    <Col span={3}>
                                      <div
                                        style={{
                                          marginLeft: "5px",
                                          marginRight: "5px",
                                          marginTop: "5px",
                                        }}
                                        onClick={() =>
                                          handleAddTimeSlot(day.day)
                                        }
                                      >
                                        <AiOutlinePlus
                                          style={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </Col>

                                    {/* Delete Icon */}
                                    <Col span={2}>
                                      <div
                                        style={{
                                          marginLeft: "5px",
                                          marginRight: "5px",
                                          marginTop: "5px",
                                        }}
                                        onClick={() =>
                                          handleDeleteTimeSlot(day.day, j)
                                        }
                                      >
                                        <AiOutlineDelete
                                          style={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </>
                                </Row>
                              );
                            })}
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </>
            )}

            {showTimeSelection && (
              <>
                <Row>
                  <h5 className="hp-mb-12 hp-mt-8">
                    {t(
                      "flowBuilder.sidebar.editor.askQuestionNodes.excludeSpecificDate"
                    )}
                  </h5>
                </Row>

                <DatePicker
                  multiple
                  format="DD-MM-YYYY"
                  value={excludeDates}
                  onChange={(dateObject) => {
                    let dateArray;
                    if (Array.isArray(dateObject)) {
                      dateArray = dateObject.map((date: any) => {
                        return date.format("DD-MM-YYYY");
                      });
                    } else {
                      dateArray = [dateObject.format("DD-MM-YYYY")];
                    }
                    handleExcludeDatesChange(dateArray);
                  }}
                  mapDays={({ date }) => {
                    if (excludeDays.includes(daysOfWeek[date.weekDay.index])) {
                      return {
                        disabled: true,
                        style: { color: "#ccc" },
                        onClick: () =>
                          message.info("This day is not available"),
                      };
                    }
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                  }}
                />
              </>
            )}
          </TabPane>
        </Tabs>
      </Col>
      <SaveAnswer id={id} type={type} variableName={answerVariable} />
    </>
  );
};

export default CalendarNodeDataEditor;
