import { FC, useState, useEffect } from "react";
import Draggable from "react-draggable";

interface DraggableModalProps {
  visible: any;
  children: any;
  positionValueX?: any;
  positionValueY: any;
}

const DraggableModal: FC<DraggableModalProps> = ({
  visible,
  children,
  positionValueX,
  positionValueY,
}) => {
  const initialX = positionValueX ? positionValueX : 550;
  const initialY = positionValueY ? positionValueY : 50;

  const [position, setPosition] = useState({ x: 165, y: 350 });

  useEffect(() => {
    const x = (window.innerWidth - initialX) / 2;
    const y = (window.innerHeight - initialY) / 2;
    setPosition({ x: x, y: y });

    const handleResize = () => {
      const newX = (window.innerWidth - initialX) / 2;
      const newY = (window.innerHeight - initialY) / 2;
      setPosition({ x: newX, y: newY });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [initialX, initialY]);

  const handleDrag = (e: any, ui: any) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  return (
    <div
      style={{
        position: "absolute",
        zIndex: "9999999999999999999999",
      }}
    >
      {visible && (
        <Draggable
          handle=".modal-drag-handle"
          onDrag={handleDrag}
          position={position}
        >
          <div style={{ position: "relative" }}>
            <div
              className="modal-drag-handle"
              style={{ cursor: "move", padding: "8px" }}
            >
              {children}
            </div>
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default DraggableModal;
