import { useEffect, useRef, useState, useCallback } from "react";
import {
  Background,
  BackgroundVariant,
  MiniMap,
  ReactFlow,
  useReactFlow,
} from "reactflow";
import { Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useDrop } from "react-dnd";
// Custom Nodes
import { NodeTypes } from "../types/types";
// Custom Edge
import CustomEdge from "./edges/CustomEdge";
import SelectedEdge from "./edges/SelectedEdge";
// Connection Line
import ConnectionLine from "./edges/ConnectionLine";
// Custom Controls
import CustomControls from "../shared/customControls/CustomControls";
// Store
import useStore, { selector } from "../store/store";
import { shallow } from "zustand/shallow";
import { motion } from "framer-motion";
import { FaRedo, FaUndo } from "react-icons/fa";
import ELK from "elkjs/lib/elk.bundled.js";

// Node Types
import { nodeTypes } from "../types/nodeTypes";
// EdgeDropContextMenu
import EdgeDropContextMenu from "../shared/edgeDropContextMenu/EdgeDropContextMenu";
// Draggable Modal
import DraggableModal from "../shared/draggableModal";

import "reactflow/dist/style.css";
import { Blocks } from "../../../../../assets/svg";
import BlocksTwo from "../../../../../assets/svg/BlocksTwo";

// Edge Types
const edgeTypes = {
  customedge: CustomEdge,
  selectededge: SelectedEdge,
};

// A Helper function to convert nodes from x6 to react-flow
type Ports = {
  [key: string]: any;
};

// Migrate from old x6 to react flow
const migrateData = (x6Data: any) => {
  let reactFlowNodes: any = [];
  let reactFlowEdges: any = [];

  // First loop to create all nodes
  x6Data.forEach((element: any) => {
    if (element.shape !== "edge") {
      let ports: Ports = {};
      if (element.ports) {
        // Check if ports property exists
        let outPorts = element.ports.items.filter(
          (item: any) => item.group === "out"
        );
        outPorts.forEach((port: any, index: number) => {
          ports[`source-${index + 1}`] = port;
        });
      }

      let node = {
        id: element.id,
        position: element.position,
        data: {
          ...element.data,
        },
        type: element.shape,
        width: element.size.width,
        height: element.size.height,
        selected: false,
        dragging: false,
        ports: ports,
      };
      reactFlowNodes.push(node);
    }
  });

  // Second loop to create all edges
  x6Data.forEach((element: any) => {
    if (element.shape === "edge") {
      let sourceHandle = "";
      let sourceNode = reactFlowNodes.find(
        (node: any) => node.id === element.source.cell
      );
      if (sourceNode) {
        // Check if the source node was found
        for (let handle in sourceNode.ports) {
          if (sourceNode.ports[handle].id === element.source.port) {
            sourceHandle = handle;
            break;
          }
        }
      }

      let edge = {
        animated: true,
        source: element.source.cell,
        sourceHandle: sourceHandle,
        target: element.target.cell,
        targetHandle: element.target.port,
        id: element.id,
        type: "customedge",
      };
      reactFlowEdges.push(edge);
    }
  });

  return {
    nodes: reactFlowNodes,
    edges: reactFlowEdges,
  };
};

// Define types
interface FlowBuilderProps {}

// Flow Builder
const FlowBuilder: React.FC<FlowBuilderProps> = () => {
  // Use store to get the state and actions
  const {
    setRef,
    setDropPosition,
    nodes,
    setNodes,
    setEdges,
    undo,
    redo,
    resetUndoRedo,
    canUndo,
    canRedo,
    onNodesChange,
    onEdgesChange,
    onNodeDragStart,
    onNodeDragStop,
    defaultEdgeOptions,
    onDrop,
    edges,
    onConnect,
    deleteNode,
    onEdgeUpdate,
    setSelectedNodes,
  } = useStore(selector, shallow);

  const ref = useRef(null);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();
  const { fitView } = useReactFlow();
  const { currentChatbot } = useSelector((state: any) => state?.chatbot ?? {});

  // Edge drop context menu states
  const [nodeOptionsVisible, setNodeOptionsVisible] = useState<any>();
  const [edgeDropCoordinates, setEdgeDropCoordinates] = useState<any>();
  const connectingNodeId = useRef<any>(null); // To store the connecting edge temporarily

  // On connect start
  const onConnectStart = useCallback((_: any, { nodeId }: any) => {
    connectingNodeId.current = nodeId;
  }, []);

  // Generate random id
  const getId = () => {
    return Math.random().toString(36).substr(2, 9); // Generate a random alphanumeric string
  };

  // On connect end
  const onConnectEnd = useCallback((event: any) => {
    if (!connectingNodeId.current) return;

    const targetIsPane = event.target.classList.contains("react-flow__pane");

    if (targetIsPane) {
      setNodeOptionsVisible(true);

      setEdgeDropCoordinates({ x: event.clientX, y: event.clientY });
    }
  }, []);

  // Handle Node Option click
  const handleNodeOptionClick = (nodeType: any) => {
    setNodeOptionsVisible(false);

    // Ensure you have access to the reactFlowInstance here
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    // Using the `project` method to convert screen coordinates to React Flow's coordinate space
    const position = reactFlowInstance.project({
      x: edgeDropCoordinates.x - reactFlowBounds.left,
      y: edgeDropCoordinates.y - reactFlowBounds.top,
    });

    // Add a new node
    const newNodeId = getId(); // Ensure this function generates unique IDs
    const newNode = {
      id: `${nodeType}${newNodeId}`,
      position, // Directly use the calculated position
      data: {},
      type: nodeType,
      height: 87,
      width: 270,
      selected: false,
      dragging: false,
      origin: [0.5, 0.0],
    };

    // Update nodes state with the new node
    setNodes([...nodes, newNode]);

    // Create a new edge connecting the previous node to the newly added node
    const newEdgeId = `${nodeType}-${newNodeId}`;
    const newEdge = {
      animated: true,
      id: newEdgeId,
      selected: false,
      source: connectingNodeId.current,
      sourceHandle: "source",
      target: `${nodeType}${newNodeId}`,
      targetHandle: "target",
      type: "customedge",
    };

    // Update edges state with the new edge
    setEdges([...edges, newEdge]);

    // Clear connectingNodeId.current to prepare for the next connection
    connectingNodeId.current = null;
  };

  // Remove the resizeObserver error
  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  // If currentChatbot is not null, then migrate the data
  useEffect(() => {
    if (currentChatbot?.elements && currentChatbot?.elements?.length > 0) {
      // If version is v1, then migrate the data, else set the initial nodes to the current chatbot elements
      if (currentChatbot?.version === "v1") {
        const { nodes, edges } = migrateData(currentChatbot?.elements);

        setNodes(nodes);
        setEdges(edges);
      } else {
        setNodes(currentChatbot?.elements[0].nodes);
        setEdges(currentChatbot?.elements[0].edges);
      }

      // Reset the setSelectedNodes
      setSelectedNodes([]);

      // Reset the undo/redo history
      resetUndoRedo();

      // Find the 'welcome-node' from the nodes
      const welcomeNode = currentChatbot?.elements[0].nodes.find(
        (node: any) => node.type === "welcome-node"
      );
      if (welcomeNode) {
        // Set the position to the welcome node's position
        reactFlowInstance.setCenter(
          welcomeNode.position.x + 500,
          welcomeNode.position.y + 150
        );
      } else {
        // If there's no 'welcome-node', then set the position to some default values
        reactFlowInstance.setCenter(500, 370);
      }

      // This will set the zoom level to 0.8
      reactFlowInstance.zoomTo(0.8);
    } else {
      // Set the initial node to the welcome node
      const initialNode: any = [
        {
          id: "1",
          position: { x: 80, y: 170 },
          data: {
            text: "Welcome to the chatbot!",
            image:
              "https://botdefaults.s3.ap-south-1.amazonaws.com/welcome_gif_1.gif",
            disableImage: false,
          },
          type: NodeTypes.Welcome,
          selected: false,
          dragging: false,
        },
      ];

      setEdges([]);

      setNodes(initialNode);

      // Reset the undo/redo history
      resetUndoRedo();

      // This will set the center of the flow
      reactFlowInstance.setCenter(500, 370);

      // This will set the zoom level to 0.6
      reactFlowInstance.zoomTo(0.8);
    }
  }, [
    currentChatbot,
    reactFlowInstance,
    resetUndoRedo,
    setEdges,
    setNodes,
    setSelectedNodes,
  ]);

  // Handle onDrop
  const handleOnDrop = (event: any) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - 50,
      y: event.clientY - reactFlowBounds.top - 50,
    });

    // Set the drop position
    setDropPosition(position);
  };

  // Drop ref for dropping nodes from node panel
  const [, dropRef] = useDrop({
    accept: "node",
    drop: (item: { id: string; type: NodeTypes }, monitor) =>
      onDrop(item, monitor),
  });

  // `mergeRefs` function that takes in all needed refs and merges them into one unified ref that can be used
  const mergeRefs = (...refs: any) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];

    return (inst: any) => {
      for (let ref of filteredRefs) {
        if (typeof ref === "function") {
          ref(inst);
        } else if (ref) {
          ref.current = inst && "getClientOffset" in inst ? ref.current : inst;
          setRef(ref);
        }
      }
    };
  };

  const elk: any = new ELK();

  // Use layout elements
  const useLayoutedElements = () => {
    const { getNodes, setNodes, getEdges } = useReactFlow();
    const defaultOptions = {
      "elk.algorithm": "layered",
      "elk.layered.spacing.nodeNodeBetweenLayers": 100,
      "elk.spacing.nodeNode": 80,
    };

    // Get layouted elements
    const getLayoutedElements = useCallback((options: any) => {
      const layoutOptions = { ...defaultOptions, ...options };
      const graph: any = {
        id: "root",
        layoutOptions: layoutOptions,
        children: getNodes(),
        edges: getEdges(),
      };

      elk.layout(graph).then((result: any) => {
        const children: any[] = result.children; // Change 'any[]' to a more specific type if known

        children.forEach((node: any) => {
          node.position = { x: node.x, y: node.y };
        });

        setNodes(children);
      });
    }, []);

    return { getLayoutedElements };
  };

  // Use layouted elements
  const { getLayoutedElements } = useLayoutedElements();

  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ type: "spring", duration: 0.2, delay: 0.1 }}
      className="hp-d-flex hp-h-100"
    >
      <div
        style={{
          height: "92vh",
          width: "100%",
          background: "#063181",
        }}
        id="react-flow-container"
        className="react-flow-container"
        ref={reactFlowWrapper}
      >
        {/* React Flow */}
        <ReactFlow
          id="container"
          ref={mergeRefs(dropRef, ref)}
          onDrop={handleOnDrop}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultEdgeOptions={defaultEdgeOptions}
          onConnect={onConnect}
          // onConnectStart={onConnectStart}
          // onConnectEnd={onConnectEnd}
          connectionLineComponent={ConnectionLine}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          onEdgeUpdate={onEdgeUpdate}
          onNodesDelete={deleteNode}
          selectNodesOnDrag={false}
          selectionKeyCode="null"
          minZoom={0.4}
          maxZoom={0.85}
          deleteKeyCode={null}
        >
          {/* Mini Controls */}
          <CustomControls />

          {/* DraggableModal */}
          {/* Show the list of nodes if nodeOptionsVisible is true */}
          <DraggableModal
            visible={nodeOptionsVisible}
            positionValueX={1600}
            positionValueY={1000}
          >
            <div>
              <EdgeDropContextMenu
                edgeDropCoordinates={edgeDropCoordinates}
                setNodeOptionsVisible={setNodeOptionsVisible}
                handleNodeOptionClick={handleNodeOptionClick}
              />
            </div>
          </DraggableModal>

          {/* Organize nodes */}
          <div
            style={{
              position: "absolute",
              bottom: "12px",
              left: "70px",
              zIndex: 999,
            }}
          >
            <Tooltip title="Organize Nodes">
              <Button
                icon={<Blocks />}
                size="small"
                style={{
                  marginRight: "10px",
                  background: "#fff",
                }}
                onClick={() => (
                  getLayoutedElements({
                    "elk.algorithm": "layered",
                    "elk.direction": "RIGHT",
                  }),
                  window.requestAnimationFrame(() => {
                    fitView();
                  })
                )}
              />
            </Tooltip>
            <Tooltip title="Organize Nodes Vertically">
              <Button
                icon={<BlocksTwo />}
                size="small"
                style={{ marginRight: "10px", background: "#fff" }}
                onClick={() => (
                  getLayoutedElements({
                    "elk.algorithm": "layered",
                    "elk.direction": "DOWN",
                  }),
                  window.requestAnimationFrame(() => {
                    fitView();
                  })
                )}
              />
            </Tooltip>
          </div>

          {/* Undo/Redo */}
          <div
            style={{
              position: "absolute",
              bottom: "12px",
              right: "220px",
              zIndex: 999,
            }}
          >
            <Button
              icon={<FaUndo size={15} />}
              size="small"
              style={{ marginRight: "10px", background: "#fff" }}
              onClick={() => undo()}
              disabled={!canUndo()}
            />
            <Button
              icon={<FaRedo size={15} />}
              size="small"
              style={{ marginRight: "10px", background: "#fff" }}
              onClick={() => redo()}
              disabled={!canRedo()}
            />
          </div>

          {/* Minimap */}
          <MiniMap
            id="minimap"
            nodeColor={"#367aee"}
            nodeStrokeWidth={3}
            zoomable
            pannable
          />

          <Background variant={BackgroundVariant.Lines} gap={50} size={1} />
        </ReactFlow>
      </div>
    </motion.div>
  );
};

export default FlowBuilder;
