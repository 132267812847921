import { Button } from "antd";

const BotMessageIcon = ({ name }) => {
  // If users name has 2 words, get the first letter of each word, else get the first letter of the name and uppercase
  const initials = name
    .replace(/\s*\([^)]*\)/g, "") // Remove any text within parentheses
    .split(" ")
    .filter((word) => word !== "") // Filter out any empty strings
    .slice(0, 2) // Take only the first two words
    .map((word) => word.charAt(0))
    .join("")
    .toUpperCase();

  return (
    <Button
      icon={initials}
      type="text"
      size="small"
      disabled={false}
      style={{
        background: "#0b4eb8",
        borderRadius: "50%",
        color: "#fff",
        height: "30px",
        width: "30px",
      }}
    />
  );
};

export default BotMessageIcon;
