export const RoleBasedRedirect = (res, history) => {
  const intended = history.location.state;
  if (intended) {
    history.push(intended.from);
  } else {
    if (!res.data.user) {
      history.push("/");
    } else if (res.data.user.role === "superadmin") {
      history.push("/super-admin/dashboard");
    } else if (res.data.user.role === "marketing") {
      history.push("/marketing-admin/dashboard");
    } else if (
      res.data.user.role === "admin" &&
      res.data.user.billingAdmin &&
      !res.data.user.onboarding_complete
    ) {
      history.push("/onboarding");
    } else if (
      res.data.user.role === "admin" &&
      res.data.user.billingAdmin &&
      !res.data.user.subscribe_complete &&
      res.data.user.currentPlan !== "Lifetime"
    ) {
      history.push("/subscribe");
    } else if (res.data.user.role === "admin") {
      history.push(`/a/ws/${res.data.user.workspaceId}/dashboard`);
    } else if (res.data.user.role === "agent") {
      history.push(`/m/ws/${res.data.user.workspaceId}/dashboard`);
    }
  }
};

export const RoleBasedRedirectWithUser = (user, history) => {
  const intended = history.location.state;
  if (intended) {
    history.push(intended.from);
  } else {
    if (!user) {
      history.push("/");
    } else if (user.role === "superadmin") {
      history.push("/super-admin/dashboard");
    } else if (user.role === "marketing") {
      history.push("/marketing-admin/dashboard");
    } else if (
      user.role === "admin" &&
      user.billingAdmin &&
      !user.onboarding_complete
    ) {
      history.push("/onboarding");
    } else if (
      user.role === "admin" &&
      user.billingAdmin &&
      !user.subscribe_complete
    ) {
      history.push("/subscribe");
    } else if (user.role === "admin") {
      history.push(`/a/ws/${user.workspaceId}/dashboard`);
    } else if (user.role === "agent") {
      history.push(`/m/ws/${user.workspaceId}/dashboard`);
    }
  }
};

export const redirectToDashboard = (user) => {
  if (user?.role === "superadmin") {
    return "/super-admin/dashboard";
  } else if (user?.role === "marketing") {
    return "/marketing-admin/dashboard";
  } else if (user?.role === "admin") {
    return `/a/ws/${user.workspaceId}/dashboard`;
  } else if (user?.role === "agent") {
    return `/m/ws/${user.workspaceId}/dashboard`;
  }
};

export const RedirectOnLogout = () => {
  window.location.href = `/login`;
};
