import { useEffect, useRef, useState } from "react";
import { message, Spin, Switch } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import InitialPageIcon from "@assets/images/pages/customize/initial-page-2.png";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
import { LoadingOutlined } from "@ant-design/icons";
import DeleteIcon from "@assets/images/pages/customize/bin.png";
import { useTranslation } from "react-i18next";

// Component to enable and customize the initial page of the chatbot
const InitialPageOptions = () => {
  // Get the chatbot ID from the URL parameters
  const { bid } = useParams();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // Reference to the file input for the card logo
  const CardLogoRef = useRef(null);

  // Get customizations from the Redux store
  const { customizations } = useSelector((state) => state?.chatbot ?? {});

  // State variables
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enableArticles, setEnableArticles] = useState(false);

  // Update visibility when customizations change
  useEffect(() => {
    setVisible(customizations?.enableInitialPage);
  }, [customizations]);

  // Toggle the initial page visibility
  const toggleEnableInitialPage = (key) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, !visible));
  };

  // Handle logo upload
  const handleChangeLogo = async (event) => {
    const file = event.target.files[0];
    const fmData = new FormData();

    // Don't allow size greater than 3MB
    if (file.size > 3 * 1024 * 1024) {
      message.error("Image/GIF size must be less than 3MB");
      return;
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload image
    try {
      setLoading(true);
      await uploadChatbotMedia(bid, fmData, config)
        .then((res) => {
          dispatch(
            updateCurrentChatbotCustomizationsAction(
              bid,
              "initialPageAvatar",
              res.data.url
            )
          );
          setLoading(false);
          message.success("Chat avatar updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Error updating chat avatar");
        });
    } catch (error) {
      setLoading(false);
      message.error("Error updating chat avatar");
    }
  };

  // Spinner indicator for loading state
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 35,
      }}
      spin
    />
  );

  // Toggle recent articles feature
  const handleEnableRecentArticles = () => {
    setEnableArticles(!enableArticles);

    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "enableRecentArticles",
        !enableArticles
      )
    );
  };

  return (
    <>
      <div className="dropdown-menu">
        {/* Toggle switch for enabling/disabling the initial page */}
        <div
          onClick={() => toggleEnableInitialPage("enableInitialPage")}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={InitialPageIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="cta"
            />
            {t("customize.initialPage.enableInitialPage")}
          </span>
          <Switch checked={visible} size="small" />
        </div>

        {/* Customization options (visible only when initial page is enabled) */}
        {visible && (
          <>
            <div
              style={{
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
                color: "#45496e",
              }}
            >
              {/* Page Title Input */}
              <span> {t("customize.initialPage.pageTitle")}</span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.initialPageTitle}
                  maxLength={25}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "initialPageTitle",
                        e.target.value
                      )
                    )
                  }
                />
              </div>

              {/* Page Description Input */}
              <span>{t("customize.initialPage.pageDesc")}</span>
              <div style={{ textAlign: "center" }}>
                <textarea
                  className="general-input"
                  type="text"
                  value={customizations?.initialPageDescription}
                  maxLength={150}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "initialPageDescription",
                        e.target.value
                      )
                    )
                  }
                  style={{ minHeight: "100px" }}
                />
              </div>

              {/* Page Card Logo Upload */}
              <span>{t("customize.initialPage.pageCardLogo")}</span>
              <div style={{ marginBottom: "8px" }}>
                <label
                  htmlFor="file-upload"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <button className="general-input-btn" htmlFor="file-upload">
                    {t("customize.initialPage.upload")}
                    <input
                      id="file-upload"
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => handleChangeLogo(e)}
                      ref={CardLogoRef}
                    />
                  </button>
                </label>

                {/* Display uploaded logo or loading spinner */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading ? (
                    <div style={{ textAlign: "center" }}>
                      <Spin indicator={antIcon} size="large" />
                    </div>
                  ) : (
                    <>
                      {customizations?.initialPageAvatar !== "" && (
                        <>
                          <img
                            src={customizations?.initialPageAvatar}
                            style={{ height: "80px", width: "80px" }}
                            alt="widget-icon"
                          />
                          <img
                            onClick={() => {
                              dispatch(
                                updateCurrentChatbotCustomizationsAction(
                                  bid,
                                  "initialPageAvatar",
                                  ""
                                )
                              );

                              // Reset the file input
                              if (CardLogoRef.current) {
                                CardLogoRef.current.value = "";
                              }

                              message.success(
                                "Widget logo deleted successfully"
                              );
                            }}
                            src={DeleteIcon}
                            style={{
                              marginTop: "-30px",
                              cursor: "pointer",
                            }}
                            alt="delete-icon"
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Card Sub Title Input */}
              <span> {t("customize.initialPage.cardSubTitle")} </span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.initialPageCardSubTitle}
                  maxLength={40}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "initialPageCardSubTitle",
                        e.target.value
                      )
                    )
                  }
                />
              </div>

              {/* Card Tagline Input */}
              <span>{t("customize.initialPage.cardTagline")} </span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.initialPageCardTagline}
                  maxLength={40}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "initialPageCardTagline",
                        e.target.value
                      )
                    )
                  }
                />
              </div>

              {/* Card Button Text */}
              <span>{t("customize.initialPage.cardButtonText")}</span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.initialPageCardButtonText}
                  maxLength={40}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "initialPageCardButtonText",
                        e.target.value
                      )
                    )
                  }
                />
              </div>

              {/* Toggle switch for enabling/disabling recent articles */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {t("customize.initialPage.enableRecentArticles")}
                <Switch
                  checked={customizations?.enableRecentArticles}
                  size="small"
                  onClick={handleEnableRecentArticles}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InitialPageOptions;
