// ChatbotCardSkeleton
import { Card, Col, Row, Skeleton } from "antd";

const ChatbotCardSkeleton = () => (
  <Col
    xs={4}
    sm={5}
    md={4}
    lg={4}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "296px",
      borderRadius: "30px",
      width: "100%",
      margin: "10px 10px 10px 10px",
      maxHeight: "300px",
      overflow: "hidden",
      maxWidth: "330px",
      boxShadow: "0px 0px 15px -12px rgba(0,0,0,0.75)",
    }}
  >
    <Card
      hoverable
      style={{
        height: "100%",
        width: "100%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
        margin: "0 auto",
      }}
    >
      {/* top text and more btn */}
      <Row justify={"space-between"} style={{ flexWrap: "nowrap" }}>
        <Skeleton active paragraph={{ rows: 0 }} />
        <Skeleton.Avatar
          active={true}
          size={"small"}
          shape={"square"}
          style={{ height: "20px" }}
        />
      </Row>

      {/* Image */}
      <div
        style={{
          height: "100px",
          width: "100%",
          maxWidth: "100%",
          overflow: "hidden",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          margin: "15px auto 0px auto",
        }}
      >
        <Skeleton.Input
          active={true}
          size={"small"}
          block={true}
          style={{
            backgroundColor: "#0000000f",
            height: "100px",
            width: "100%",
          }}
        />
      </div>

      {/* input text */}
      <div
        style={{
          height: "20px",
          width: "100%",
          maxWidth: "100%",
          overflow: "hidden",
          borderRadius: "20px",
          marginTop: "25px",
        }}
      >
        <Skeleton.Input
          active={true}
          size={"small"}
          block={true}
          style={{
            backgroundColor: "#0000000f",
            width: "100%",
          }}
        />
      </div>

      {/* button */}
      <div
        style={{
          height: "28px",
          width: "100%",
          maxWidth: "120px",
          overflow: "hidden",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          margin: "25px auto 0px auto",
        }}
      >
        <Skeleton.Input
          active={true}
          size={"small"}
          block={true}
          style={{
            backgroundColor: "#0000000f",
            width: "100%",
          }}
        />
      </div>
    </Card>
  </Col>
);
export default ChatbotCardSkeleton;
