import React, { useEffect, useState } from "react";
import {
  KnowledgeBaseArticleCreateForm,
  KnowledgeBaseArticleUpdateForm,
  KnowledgeBaseCategoryCreateModal,
  KnowledgeBaseCategoryUpdateModal,
} from "@components";
import ArticlesList from "./articles/ArticlesList";
import CategoriesList from "./categories/CategoriesList";
import {
  getKnowledgeBaseAdminBots,
  getAllKnowledgeBaseChatbotArticles,
  getKnowledgeBaseChatbotCategories,
} from "@api/knowledge-base/knowledgeBase";
import { useLocation } from "react-router-dom";

const KnowledgeBaseRight = ({
  createCategoryModal,
  setCreateCategoryModal,
}) => {
  const location = useLocation();

  // Local state
  const [articles, setArticles] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [chatbots, setChatbots] = useState([]);
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [categoryUpdateModal, setCategoryUpdateModal] = useState(false);
  const [articlesLoading, setArticlesLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [categoryId, setCategoryId] = useState();

  // Fetch chatbots on mount
  useEffect(() => {
    fetchKnowledgeBaseAdminChatbots();
  }, []);

  // Fetch articles or categories on chatbot select or path change
  useEffect(() => {
    if (selectedChatbot) {
      // If location is articles page, fetch articles
      if (location.pathname.includes("/articles")) {
        fetchKnowledgeBaseChatbotArticles(selectedChatbot);
      } else if (location.pathname.includes("/categories")) {
        fetchKnowledgeBaseChatbotCategories(selectedChatbot);
      }
    }
  }, [location.pathname, selectedChatbot]);

  // Handle create category modal click
  const handleCreateCategoryModalClick = () => {
    setCreateCategoryModal(false);
  };

  // Handle update category modal click
  const handleUpdateCategoryModalClick = () => {
    setCategoryUpdateModal(false);
  };

  // Fetch chatbots
  const fetchKnowledgeBaseAdminChatbots = async () => {
    setCategoriesLoading(true);
    try {
      const res = await getKnowledgeBaseAdminBots();
      setChatbots(res.data.chatbots);

      // If there are chatbots, set the first one as selected
      if (res.data.chatbots.length > 0) {
        setSelectedChatbot(res.data.chatbots[0]._id);
      }

      setCategoriesLoading(false);
    } catch (err) {
      console.error("Fetch knowledge base admin chatbots error", err);
      setCategoriesLoading(false);
    }
  };

  // Fetch chatbot articles
  const fetchKnowledgeBaseChatbotArticles = async (chatbotId) => {
    setArticlesLoading(true);
    try {
      const res = await getAllKnowledgeBaseChatbotArticles(chatbotId);
      setArticles(res?.data);
    } catch (err) {
      console.error("Fetch articles error", err);
    } finally {
      setArticlesLoading(false);
      setInitialLoading(false);
    }
  };

  // Fetch chatbot categories
  const fetchKnowledgeBaseChatbotCategories = async (selectedChatbot) => {
    setCategoriesLoading(true);
    try {
      const res = await getKnowledgeBaseChatbotCategories(selectedChatbot);
      setArticleCategories(res.data);
      setCategoriesLoading(false);
    } catch (err) {
      console.error("Fetch knowledge base chatbot categories error", err);
      setCategoriesLoading(false);
    }
  };

  return (
    <>
      <div className="knowledge-base-right">
        {location.pathname.endsWith("/create") ? (
          <KnowledgeBaseArticleCreateForm
            chatbots={chatbots}
            articleCategories={articleCategories}
            fetchArticles={() =>
              fetchKnowledgeBaseChatbotArticles(selectedChatbot)
            }
          />
        ) : location.pathname.includes("/update") ? (
          <KnowledgeBaseArticleUpdateForm
            chatbots={chatbots}
            articleCategories={articleCategories}
            fetchArticles={() =>
              fetchKnowledgeBaseChatbotArticles(selectedChatbot)
            }
          />
        ) : location.pathname.includes("/articles") ? (
          <ArticlesList
            // Loaders
            initialLoading={initialLoading}
            articlesLoading={articlesLoading}
            // Articles
            articles={articles}
            fetchArticles={() =>
              fetchKnowledgeBaseChatbotArticles(selectedChatbot)
            }
            // Chatbot
            chatbots={chatbots}
            selectedChatbot={selectedChatbot}
            setSelectedChatbot={setSelectedChatbot}
          />
        ) : location.pathname.includes("/categories") ? (
          <CategoriesList
            // Loaders
            loading={categoriesLoading}
            // Article categories
            articleCategories={articleCategories}
            setCategoryId={setCategoryId}
            setCategoryUpdateModal={setCategoryUpdateModal}
            fetchArticleCategories={fetchKnowledgeBaseChatbotCategories}
            // Chatbot
            chatbots={chatbots}
            selectedChatbot={selectedChatbot}
            setSelectedChatbot={setSelectedChatbot}
          />
        ) : null}

        {/* Knowledge base category create modal */}
        {createCategoryModal && (
          <KnowledgeBaseCategoryCreateModal
            open={createCategoryModal}
            handleModalClick={handleCreateCategoryModalClick}
            fetchCategories={fetchKnowledgeBaseChatbotCategories}
            chatbots={chatbots}
            selectedChatbot={selectedChatbot}
          />
        )}

        {/* Knowledge base category update modal */}
        {categoryUpdateModal && (
          <KnowledgeBaseCategoryUpdateModal
            open={categoryUpdateModal}
            handleModalClick={handleUpdateCategoryModalClick}
            fetchCategories={fetchKnowledgeBaseChatbotCategories}
            categoryId={categoryId}
            chatbots={chatbots}
            selectedChatbot={selectedChatbot}
          />
        )}
      </div>
    </>
  );
};

export default KnowledgeBaseRight;
