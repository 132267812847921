import { Row, Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const ChatMetadata = ({ selectedChat, isOpen, toggleDropdown }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <Col
          style={{
            marginRight: "10px",
          }}
        >
          <h5
            style={{
              display: "block",
              fontWeight: 600,
            }}
          >
            {t("chatMessages.visitor.chatMetadata")}
          </h5>
        </Col>
      </Row>

      {/* Variables */}
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Align items vertically
          padding: "10px 0", // Add some padding top and bottom
        }}
      >
        <div
          className="dropdown-container"
          style={{ position: "relative", width: "100%" }}
        >
          <button
            className="dropdown-button"
            onClick={toggleDropdown}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              textAlign: "left",
              padding: "10px",
              fontSize: "16px",
              fontWeight: "bold",
              background: "#fff",
              borderRadius: "5px",
              cursor: "pointer",
              border: "none",
            }}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="#42A5F5" d="M36 44H8V8h20l8 8z"></path>
              <path fill="#90CAF9" d="M40 40H12V4h20l8 8z"></path>
              <path fill="#E1F5FE" d="M38.5 13H31V5.5z"></path>
              <path
                fill="#0b4eb8"
                d="M23.4 29.9c0-.2 0-.4.1-.6s.2-.3.3-.5s.3-.2.5-.3s.4-.1.6-.1s.5 0 .7.1s.4.2.5.3s.2.3.3.5s.1.4.1.6s0 .4-.1.6s-.2.3-.3.5s-.3.2-.5.3s-.4.1-.7.1s-.5 0-.6-.1s-.4-.2-.5-.3s-.2-.3-.3-.5s-.1-.4-.1-.6zm2.7-3.1h-2.3l-.4-9.8h3l-.3 9.8z"
              ></path>
            </svg>
            <span style={{ marginLeft: "10px" }}>
              {t("chatMessages.visitor.answerVariables")} (
              {selectedChat?.answerVariables?.length})
            </span>
            <DownOutlined
              style={{
                transform: isOpen ? "rotate(180deg)" : "",
                fontSize: "14px",
                transition: "all 0.2s ease-out",
                marginLeft: "auto", // Push the icon to the right
              }}
            />
          </button>

          {isOpen && selectedChat?.answerVariables?.length > 0 && (
            <ul
              className="answer-variables-list"
              style={{ padding: "10px", listStyleType: "none" }}
            >
              {selectedChat?.answerVariables?.map((variable, index) => (
                <li style={{ padding: "5px 0" }} key={index}>
                  <span
                    style={{
                      fontWeight: "bold",
                      display: "block",
                      color: "#0b4eb8",
                    }}
                  >
                    {variable?.key}
                  </span>
                  <span style={{ display: "block" }}>
                    {variable?.value && typeof variable.value === "object"
                      ? variable.value.message // Render the message property of the object
                      : variable?.value && typeof variable.value === "string"
                      ? variable.value.replace(/<\/?[^>]+(>|$)/g, "")
                      : variable?.value}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Row>
    </>
  );
};

export default ChatMetadata;
