import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Spin, Table } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllBlogTags, deleteBlogTagById } from "@api/blog/blog";
import {
  SuccessNotification,
  ErrorNotification,
  BlogTagCreateModal,
  BlogTagUpdateModal,
} from "@components";

const BlogTagsList = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const [blogTags, setBlogTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [tagId, setTagId] = useState("");

  // Handle create tag modal
  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update tag modal
  const handleUpdateModalClick = (id) => {
    setTagId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  // Fetch all blogTags
  const fetchBlogTags = async () => {
    setLoading(true);
    const { data } = await getAllBlogTags();
    setBlogTags(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchBlogTags();
  }, []);

  // Handle tag delete
  const handleTagDelete = (id) => {
    // If the tag has blogs, don't allow delete
    const tag = blogTags.find((tag) => tag._id === id);
    if (tag?.blogs?.length > 0) {
      return ErrorNotification(
        "Cannot delete Tag with blogs in it. Please delete the blogs first"
      );
    }

    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this tag?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteBlogTagById(id);
          // Fetch all blogTags
          fetchBlogTags();
          // Show success notification
          SuccessNotification("Tag deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting tag");
        }
      },
    });
  };

  const columns = [
    {
      title: "Tag name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of Blogs",
      dataIndex: "blogs",
      key: "blogs",
      render: (blogs) => blogs.length,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleUpdateModalClick(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleTagDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {/* Create Tag Modal */}
      {createModalOpen && (
        <BlogTagCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchBlogTags={fetchBlogTags}
        />
      )}

      {/* Update Tag Modal */}
      {updateModalOpen && (
        <BlogTagUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchBlogTags={fetchBlogTags}
          tagId={tagId}
        />
      )}

      <Card
        style={{
          borderRadius: "0",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <h3>Tags List</h3>
          </Col>
          <Col>
            {/* Back Button */}
            <Button
              onClick={() => {
                // If user is superadmin, redirect to blogs
                if (user?.role === "superadmin") {
                  history.push("/super-admin/blogs");
                } else if (user?.role === "marketing") {
                  history.push("/marketing-admin/blogs");
                }
              }}
              style={{ marginRight: 16 }}
            >
              Go Back
            </Button>

            {/* Create Tag  */}
            <Button type="primary" onClick={handleCreateModalClick}>
              Create Tag
            </Button>
          </Col>
        </Row>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(80vh - 50px)",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={blogTags}
            rowKey="_id"
            pagination={false}
            style={{ overflowX: "auto" }}
          />
        )}
      </Card>
    </>
  );
};

export default BlogTagsList;
