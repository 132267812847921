import api from "../index";

// Create a coupon
export const createCoupon = async (values: any) => {
  return await api.post("/coupon", values);
};

// Get all coupons
export const getAllCoupons = async () => {
  return await api.get(`/coupons`);
};

// Get all coupons paginated
export const getAllCouponsPaginated = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(`/coupons/paginated?page=${page}&perPage=${perPage}`);
  } else {
    return await api.get(
      `/coupons/paginated?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get a coupon by id
export const getCouponById = async (id: any) => {
  return await api.get(`/coupon/${id}`);
};

// Update a coupon by id
export const updateCouponById = async (id: any, values: any) => {
  return await api.put(`/coupon/${id}`, values);
};

// Delete a coupon by id
export const deleteCouponById = async (id: any) => {
  return await api.delete(`/coupon/${id}`);
};

// Validate a coupon
export const validateCoupon = async (code: any) => {
  return await api.post(`/coupon/validate`, code);
};
