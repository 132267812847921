import api from "../index";

// ** --------- Template API --------- ** //
// Create a new chatbot template
export const createChatbotTemplate = async (values: any) => {
  return await api.post("/bot/template/create-chatbot-template", values);
};

// Get chatbot template by id
export const getChatbotTemplateById = async (tid: any) => {
  return await api.get(`/bot/template/get-chatbot-template/${tid}`);
};

// Get all chatbot templates paginated (Super Admin uses this)
export const getAllChatbotTemplatesPaginated = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(
      `/bot/templates/paginated?page=${page}&perPage=${perPage}`
    );
  } else {
    return await api.get(
      `/bot/templates/paginated?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get chatbot templates by category paginated
export const getChatbotTemplatesByCategoryPaginated = async (
  page: any,
  perPage: any,
  category: any,
  searchText: any
) => {
  if (!searchText && !category) {
    return await api.get(
      `/bot/templates/category/paginated?page=${page}&perPage=${perPage}`
    );
  } else if (!searchText) {
    return await api.get(
      `/bot/templates/category/paginated?page=${page}&perPage=${perPage}&category=${category}`
    );
  } else {
    return await api.get(
      `/bot/templates/category/paginated?page=${page}&perPage=${perPage}&category=${category}&searchText=${searchText}`
    );
  }
};

// Update a chatbot template
export const updateChatbotTemplate = async (tid: any, values: any) => {
  return await api.put(`/bot/template/update-chatbot-template/${tid}`, values);
};

// Delete a chatbot template by id
export const deleteChatbotTemplate = async (tid: any, bid: any) => {
  return await api.delete(
    `/bot/${bid}/template/delete-chatbot-template/${tid}`
  );
};

// Update chatbot template approval by ID
export const updateChatbotTemplateApprovalById = async (
  tid: any,
  values: any
) => {
  return await api.put(`/bot/template/${tid}/approve`, values);
};

// Upload template media
export const uploadTemplateMedia = async (values: any, config: any) => {
  return await api.post("/bot/templateMedia", values, config);
};

// Copy chatbot from template
export const copyChatbotTemplate = async (tid: any) => {
  return await api.post(`/bot/template/${tid}/copy`);
};

// Like chatbot from template
export const likeChatbotTemplate = async (tid: any) => {
  return await api.post(`/bot/template/${tid}/like`);
};

// ** --------- Template Category API --------- ** //
// Create a category
export const createCategory = async (values: any) => {
  return await api.post("/category", values);
};

// Get all categories
export const getAllCategories = async () => {
  return await api.get(`/categories`);
};

// Get a category by id
export const getCategoryById = async (id: any) => {
  return await api.get(`/category/${id}`);
};

// Update a category by id
export const updateCategoryById = async (id: any, values: any) => {
  return await api.put(`/category/${id}`, values);
};

// Delete a category by id
export const deleteCategoryById = async (id: any) => {
  return await api.delete(`/category/${id}`);
};

// ** --------- Template Tags API --------- ** //
// Create a tag
export const createTag = async (values: any) => {
  return await api.post("/tag", values);
};

// Get all tags
export const getAllTags = async () => {
  return await api.get(`/tags`);
};

// Get a tag by id
export const getTagById = async (id: any) => {
  return await api.get(`/tag/${id}`);
};

// Update a tag by id
export const updateTagById = async (id: any, values: any) => {
  return await api.put(`/tag/${id}`, values);
};

// Delete a tag by id
export const deleteTagById = async (id: any) => {
  return await api.delete(`/tag/${id}`);
};
