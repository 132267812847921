import { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Input, Pagination } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  Tour,
  TemplateCard,
  ErrorNotification,
  TemplateFilters,
} from "@components";
import {
  getCategoriesTemplatesAction,
  getCategoryChatbotsAction,
} from "@redux/actions/chatbotActions";
import { updateTutorialStatusAction } from "@redux/actions/adminActions";
import {
  copyChatbotTemplate,
  likeChatbotTemplate,
} from "@api/template/template";
// Guide Tour
import { botTemplatesListSteps } from "@helpers/shepherd/chatbotViewSteps";
import DraggableModal from "./builder/shared/draggableModal";
import ChatbotFlowPreview from "./builder/preview/ChatbotFlowPreview";
import "@assets/css/Templates.css";
// skeleton
import {
  TemplatesSidebarSkeleton,
  TemplateCardSkeletonTwo,
} from "@components/Loaders/skeleton/Templates";
// translations
import { useTranslation } from "react-i18next";

const { Search } = Input;

const TemplateList = ({ history }) => {
  // Redux states
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { templates, categories, paginationData } = useSelector(
    (state) => state.chatbot
  );
  const { t } = useTranslation();

  // Local states
  const [templateListTour, setTemplateListTour] = useState(
    user?.tutorials_complete?.botsTemplateList
  );
  // Pagination
  const [loading, setLoading] = useState(true);
  const [sidebarLoading, setSidebarLoading] = useState(true);
  const [category, setCategory] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: paginationData?.page || 1,
    pageSize: paginationData?.perPage || 8,
    total: paginationData?.totalTemplates || 0,
  });
  // Preview Modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalSize, setModalSize] = useState("mobile");

  // Shepherd tutorial
  useEffect(() => {
    const shepherdData = JSON.parse(localStorage.getItem("shepherd"));
    if (shepherdData) {
      // Check each tutorial status and update the server if it's not already updated
      if (!user?.tutorials_complete?.botsTemplateList && templateListTour) {
        dispatch(updateTutorialStatusAction(shepherdData));
      }
    }
  }, []);

  // Fetch templates and categories on initial load
  useEffect(() => {
    setLoading(true);
    dispatch(
      getCategoriesTemplatesAction(
        pagination.current,
        pagination.pageSize,
        searchText,
        "",
        setLoading
      )
    );
  }, []);

  // Update local pagination state when Redux state changes
  useEffect(() => {
    if (paginationData) {
      setPagination({
        current: paginationData.page || 1,
        pageSize: paginationData.perPage || 10,
        total: paginationData.totalTemplates || 0,
      });
    }
  }, [paginationData]);

  useEffect(() => {
    if (categories && categories.length > 0 && category === "") {
      setCategory(categories[0]); // Set the first category as the default
    }
  }, [categories]);

  // Remove duplicate categories
  const uniqCategories = categories.filter(
    (category, index, self) => self.indexOf(category) === index
  );

  // Modify the setCategory function
  const handleCategoryChange = (newCategory) => {
    setSidebarLoading(false);
    setLoading(true); // Enable spinner
    setCategory(newCategory); // Set the new category

    // Fetch templates by category
    dispatch(
      getCategoryChatbotsAction(
        1, // Reset to the first page
        pagination.pageSize,
        "",
        newCategory,
        () => setLoading(false), // Callback to stop loading spinner after fetch
        () => setSidebarLoading(false)
      )
    );
  };

  // Handle template copy click
  const handleTemplateCopy = (tid, name) => {
    // Confirm copy
    Modal.confirm({
      title: `Are you sure you want to copy ${name}?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: async () => {
        try {
          // Show loading
          setLoading(true);

          copyChatbotTemplate(tid).then(() => {
            const joyrideObj = JSON.parse(localStorage.getItem("joyride"));
            if (joyrideObj?.noChatbotsYet) {
              joyrideObj.clonedFirstBot = true;

              localStorage.setItem("joyride", JSON.stringify(joyrideObj));
            }

            history.push(`/a/ws/${user.workspaceId}/chatbots`);
          });
        } catch (error) {
          // Hide loading
          setLoading(false);

          // Show error notification
          ErrorNotification("Error copying chatbot");
        }
      },
    });
  };

  // Handle like template click
  const handleLikeTemplate = (tid) => {
    likeChatbotTemplate(tid)
      .then(() => {
        // Do nothing
      })
      .catch((error) => {
        // Show error notification
        console.log("Error liking template", error);
        ErrorNotification("Error liking template");
      });
  };

  // Handle TemplateList Guide Tour finish
  const handleTemplateListTourComplete = (_, completionStatus) => {
    if (completionStatus) {
      setTemplateListTour(true);

      // Update LocalStorage guideTour status
      const existingData = localStorage.getItem("shepherd");
      const parsedData = JSON.parse(existingData);
      const updatedData = {
        ...parsedData,
        botsTemplateList: true,
      };

      localStorage.setItem("shepherd", JSON.stringify(updatedData));

      // Update server with tutorial status
      dispatch(updateTutorialStatusAction(updatedData));
    }
  };

  // Handle search text change
  const handleSearch = () => {
    setSidebarLoading(false);

    setPagination({ ...pagination, current: 1 }); // Reset to first page on new search

    // Set loading
    setLoading(true);

    // Fetch templates by category
    dispatch(
      getCategoryChatbotsAction(
        1,
        pagination.pageSize,
        searchText,
        category,
        () => setLoading(false)
      )
    );
  };

  // Handle page change
  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page });

    // set sidebar loading
    setSidebarLoading(false);

    // Set loading
    setLoading(true);

    // Fetch templates by category
    dispatch(
      getCategoryChatbotsAction(page, pageSize, searchText, category, () =>
        setLoading(false)
      )
    );
  };

  // Handle close modal
  const closeModal = () => {
    setModalVisible(false);
  };

  // Handle set mobile / desktop preview
  const toggleModalSize = (value) => {
    setModalSize(value);
  };

  return (
    <>
      {/* Guide Tour */}
      {!loading && !templateListTour && templates && (
        <Tour
          steps={botTemplatesListSteps}
          tutComplete={templateListTour}
          tourId="templateListTour"
          handleTourComplete={handleTemplateListTourComplete}
        />
      )}
      <Col
        style={{
          padding: "20px",
          height: "calc(100vh - 0px)",
          backgroundColor: "#ffffff",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
        }}
      >
        {/* Header */}
        <Row
          style={{
            borderRadius: "6px",
            marginBottom: "20px",
          }}
          justify="space-between" // Adjust content spacing
        >
          <Col span={24}>
            <Row justify="space-between" align="middle">
              {/* Title */}
              <Col>
                <h1
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#2c3e50",
                  }}
                >
                  {t("templates.templateTitle")}
                </h1>
              </Col>

              {/* Search Input and Back Button */}
              <Col>
                <Row gutter={16} align="middle">
                  {/* Search Input */}
                  <Col>
                    <Search
                      placeholder={t("templates.searchPlaceHolder")}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      onSearch={handleSearch}
                      style={{ width: 300 }} // Fixed width for search bar
                    />
                  </Col>
                  {/* Back Button */}
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => history.goBack()}
                      size="small"
                      style={{ backgroundColor: "#0b4eb8" }}
                    >
                      {t("templates.goBack")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Sidebar */}
        <Row justify="space-between" gutter={[16, 16]}>
          <Col
            span={6}
            lg={5}
            style={{
              height: "100vh",
              backgroundColor: "#fff",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {loading && sidebarLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                  margin: "10px",
                  position: "relative",
                  paddingTop: "5px",
                }}
              >
                {/* Templates Sidebar Skeleton */}
                {Array.from({ length: 7 }, (_, index) => (
                  <>
                    <TemplatesSidebarSkeleton key={index} />
                  </>
                ))}
              </div>
            ) : (
              <TemplateFilters
                categories={uniqCategories}
                category={category}
                handleCategoryChange={handleCategoryChange}
              />
            )}
          </Col>

          {/* Templates */}
          <Col
            span={18}
            md={16}
            lg={19}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100vh - 90px)",
              paddingRight: "10px",
            }}
          >
            {loading ? (
              <Row gutter={[16, 16]} style={{ marginTop: "-10px" }}>
                {Array.from({ length: 8 }, (_, index) => (
                  <TemplateCardSkeletonTwo key={index} />
                ))}
              </Row>
            ) : (
              <>
                <Row gutter={[16, 16]}>
                  {templates?.length > 0 &&
                    templates.map((template, index) => {
                      return (
                        <TemplateCard
                          template={template}
                          index={index}
                          handleTemplateCopy={handleTemplateCopy}
                          handleLikeTemplate={handleLikeTemplate}
                          setModalVisible={setModalVisible}
                        />
                      );
                    })}
                </Row>
                {/* Pagination */}
                {templates?.length > 0 && (
                  <Row
                    justify="center"
                    style={{ marginTop: "20px", marginBottom: "40px" }}
                  >
                    <Pagination
                      current={pagination.current}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      onChange={handlePageChange}
                    />
                  </Row>
                )}
              </>
            )}
          </Col>

          {/* Preview Modal */}
          <DraggableModal visible={modalVisible} positionValueY={850}>
            {/* Content of the modal */}
            <div
              style={{
                height: "75vh",
                width: modalSize === "mobile" ? "25vw" : "45vw",
                transition: "all 0.1s ease-out",
                borderRadius: "8px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                overflow: "hidden",
              }}
            >
              {/* Modal body */}
              <div>
                <ChatbotFlowPreview
                  closeModal={closeModal}
                  toggleModalSize={toggleModalSize}
                />
              </div>
            </div>
          </DraggableModal>
        </Row>
      </Col>
    </>
  );
};

export default TemplateList;
