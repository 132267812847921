import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Spin, Table } from "antd";
import { useSelector } from "react-redux";
import { getAllBlogCategories, deleteBlogCategoryById } from "@api/blog/blog";
import {
  SuccessNotification,
  ErrorNotification,
  BlogCategoryCreateModal,
  BlogCategoryUpdateModal,
} from "@components";
import { useHistory } from "react-router-dom";

const BlogCategoriesList = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [blogCategories, setBlogCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    fetchBlogCategories();
  }, []);

  const fetchBlogCategories = async () => {
    setLoading(true);
    try {
      const { data } = await getAllBlogCategories();
      setBlogCategories(data);
    } catch (error) {
      console.error("Fetch blog categories error", error);
    }
    setLoading(false);
  };

  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const handleUpdateModalClick = (id) => {
    setCategoryId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  const handleCategoryDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteBlogCategoryById(id);
          fetchBlogCategories();
          SuccessNotification("Category deleted successfully");
        } catch (error) {
          ErrorNotification("Error deleting category");
        }
      },
    });
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of Blogs",
      dataIndex: "blogs",
      key: "blogs",
      render: (blogs) => blogs.length,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleUpdateModalClick(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleCategoryDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {createModalOpen && (
        <BlogCategoryCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchCategories={fetchBlogCategories}
        />
      )}

      {updateModalOpen && (
        <BlogCategoryUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchCategories={fetchBlogCategories}
          categoryId={categoryId}
        />
      )}

      <Card
        style={{
          borderRadius: "0",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row justify="space-between">
          <Col span={12}>
            <h3 className="hp-mb-16">Blog Categories List</h3>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* Back Button */}
            <Button
              onClick={() => {
                // If user is superadmin, redirect to blogs
                if (user?.role === "superadmin") {
                  history.push("/super-admin/blogs");
                } else if (user?.role === "marketing") {
                  history.push("/marketing-admin/blogs");
                }
              }}
              style={{ marginRight: "16px" }}
            >
              Go Back
            </Button>

            {/* Create Blog Category */}
            <Button type="primary" onClick={handleCreateModalClick}>
              Create Blog Category
            </Button>
          </Col>
        </Row>

        {loading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          />
        ) : (
          <Table columns={columns} dataSource={blogCategories} rowKey="_id" />
        )}
      </Card>
    </>
  );
};

export default BlogCategoriesList;
