export const ComparePlansData = [
  {
    title: "Base Features",
    subTitle: "All the essentials to get you started with Conferbot.",
    plans: [
      {
        title: "Price",
        free: "FREE",
        starter: "$19",
        pro: "$39",
        business: "$59",
      },
      {
        title: "Seats Included",
        free: "1",
        starter: "2",
        pro: "5",
        business: "15",
      },
      {
        title: "Extra Seat",
        free: "Not applicable",
        starter: "$5/seat",
        pro: "$5/seat",
        business: "$5/seat",
      },
      {
        title: "Channels",
        free: "Web",
        starter: "Web",
        pro: "Web, API",
        business: "Web, API, WhatApp (Coming Soon)",
      },
    ],
  },
  {
    title: "Usage Details",
    subTitle: "Adaptable chat quotas for your evolving needs and growth.",
    plans: [
      {
        title: "Chats Included",
        free: "300/month",
        starter: "1000/month",
        pro: "2500/month",
        business: "5000/month",
      },
      {
        title: "Extra Chats",
        free: "Not applicable",
        starter: "$0.01/chat",
        pro: "$0.01/chat",
        business: "custom",
      },
      {
        title: "Chats Inbox",
        free: "available",
        starter: "available",
        pro: "available",
        business: "available",
      },
    ],
  },
  {
    title: "Integrations",
    subTitle: "Seamlessly syncing your data, apps, and conversations.",
    plans: [
      {
        title: "Webhook",
        free: "",
        starter: "available",
        pro: "available",
        business: "available",
      },
      {
        title: "Zapier",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "Slack",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "Mailchimp",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "Stripe",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },

      {
        title: "SalesForce",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "HubSpot",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "Google Sheets",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "Airtable",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "Calendly",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
      {
        title: "Google Calendar",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
    ],
  },
  {
    title: "Logical Operations",
    subTitle: "Creating smart, advanced chatbot flows is now straightforward.",
    plans: [
      {
        title: "Variables",
        free: "available",
        starter: "available",
        pro: "available",
        business: "available",
      },
      {
        title: "Conditions",
        free: "",
        starter: "available",
        pro: "available",
        business: "available",
      },
      {
        title: "A/B Testing",
        free: "",
        starter: "available",
        pro: "available",
        business: "available",
      },
      {
        title: "Logic",
        free: "",
        starter: "",
        pro: "available",
        business: "available",
      },
    ],
  },
  {
    title: "Support",
    subTitle:
      "Consistently supporting your success with our ready-to-use resources.",
    plans: [
      {
        title: "YouTube Tutorials",
        free: "available",
        starter: "available",
        pro: "available",
        business: "available",
      },
      {
        title: "Community",
        free: "available",
        starter: "available",
        pro: "available",
        business: "available",
      },
      {
        title: "Email",
        free: "available",
        starter: "available",
        pro: "available",
        business: "available",
      },
      {
        title: "Priority",
        free: "",
        starter: "",
        pro: "",
        business: "available",
      },
      {
        title: "Training",
        free: "",
        starter: "",
        pro: "",
        business: "available",
      },
    ],
  },
];
