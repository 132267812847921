import React, { useState, useCallback } from "react";
import "../../../../assets/css/ChatArticlesUi.css";
import ArticlePlaceholder from "../../../../assets/images/pages/customize/article-placeholder.png";

// Renders the search bar component
const ArticleSearch = ({ handleArticleSearch }) => {
  return (
    <div className="search-bar-wrapper">
      <span className="search-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M10.77 18.3a7.53 7.53 0 1 1 7.53-7.53a7.53 7.53 0 0 1-7.53 7.53Zm0-13.55a6 6 0 1 0 6 6a6 6 0 0 0-6-6Z"
          />
          <path
            fill="currentColor"
            d="M20 20.75a.74.74 0 0 1-.53-.22l-4.13-4.13a.75.75 0 0 1 1.06-1.06l4.13 4.13a.75.75 0 0 1 0 1.06a.74.74 0 0 1-.53.22Z"
          />
        </svg>
      </span>
      <input
        type="text"
        placeholder="Search Articles"
        className="search-bar"
        onChange={handleArticleSearch}
      />
    </div>
  );
};

// Renders the list of article categories
const ArticleCategoryList = ({ articleCategories, handleArticleClick }) => {
  return (
    <div className="articles-categories-list">
      {articleCategories.map((articleCategory) => (
        <div
          key={articleCategory.name}
          className="article-category"
          onClick={() => handleArticleClick(articleCategory)}
        >
          <div className="article-category-info">
            <h3>{articleCategory.name}</h3>
            <p>{articleCategory.description}</p>
            <span style={{ color: "rgb(115, 115, 115)" }}>
              {articleCategory.articles.length === 1
                ? "1 article"
                : `${articleCategory.articles.length} articles`}
            </span>
          </div>
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 48 48"
            >
              <path
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M19 12L31 24L19 36"
              />
            </svg>
          </span>
        </div>
      ))}
    </div>
  );
};

// Renders the list of articles within a selected category
const ArticleList = ({
  articlesList,
  showArticleDetails,
  truncateText,
  customizations,
  handleBackBtnClick,
}) => {
  return (
    <div className="articles-list-container">
      <div
        className="article-details-header"
        style={{
          backgroundColor: customizations.headerBgColor,
          color: customizations.headerTextColor,
        }}
      >
        <span
          className="back-btn"
          onClick={() => handleBackBtnClick("articlesCatListPage")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 42 42"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M27.066 1L7 21.068l19.568 19.569l4.934-4.933l-14.637-14.636L32 5.933z"
            />
          </svg>
        </span>
        <h3
          className="article-title"
          style={{ fontFamily: customizations?.font, textAlign: "start" }}
        >
          {articlesList?.name}
        </h3>
      </div>
      <div className="articles-info">
        <h1 className="articles-category-title">{articlesList?.name}</h1>
        <p className="articles-category-description">
          {articlesList?.description}
        </p>
        <span style={{ color: "rgb(115, 115, 115)" }}>
          {articlesList.articles.length === 1
            ? "1 article"
            : `${articlesList.articles.length} articles`}
        </span>
      </div>
      <div className="articles-container">
        {articlesList?.articles?.map((article, index) => (
          <div
            key={index}
            className="article-card"
            onClick={() => showArticleDetails(article)}
          >
            {article.coverImage ? (
              <img
                class="article-img"
                src={article.coverImage}
                alt={article.title}
              />
            ) : (
              <img
                class="article-img-placeholder"
                src={ArticlePlaceholder}
                alt="article-placeholder"
              />
            )}
            <div className="article-content">
              <h4 className="article-title">{article.title}</h4>
              <p className="article-desc">
                {truncateText(article.description, 120)}
              </p>
              <div className="article-meta">
                <span className="article-author">{article.author.name}</span>
                <span className="article-date">
                  {new Date(article.publishedDate).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Renders the details of a selected article
const ArticleDetails = ({
  selectedArticle,
  customizations,
  parseAndRenderContent,
  handleBackBtnClick,
}) => {
  return (
    <div id="articles">
      <div className="article-details-container">
        <div
          className="article-details-header"
          style={{
            backgroundColor: customizations.headerBgColor,
            color: customizations.headerTextColor,
          }}
        >
          <span
            className="back-btn"
            onClick={() => handleBackBtnClick("articlesListPage")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 42 42"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M27.066 1L7 21.068l19.568 19.569l4.934-4.933l-14.637-14.636L32 5.933z"
              />
            </svg>
          </span>
          <h3
            className="article-title"
            style={{ fontFamily: customizations?.font }}
          >
            {selectedArticle?.title}
          </h3>
          <span className="max-min-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M14.78 2.28a.75.75 0 0 0-1.06-1.06L10.5 4.44V2.75a.75.75 0 0 0-1.5 0V7h4.25a.75.75 0 0 0 0-1.5h-1.69l3.22-3.22ZM5.5 11.56v1.69a.75.75 0 0 0 1.5 0V9H2.75a.75.75 0 0 0 0 1.5h1.69l-3.22 3.22a.75.75 0 1 0 1.06 1.06l3.22-3.22Z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <div
          className="details-inner"
          style={{ fontFamily: customizations.font }}
        >
          <h1 className="article-title-above-image">{selectedArticle.title}</h1>
          {selectedArticle.coverImage && (
            <img
              className="article-cover-image"
              src={selectedArticle.coverImage}
              alt="Cover Image"
            />
          )}
          <div className="article-meta">
            <div className="avatar-container">
              <img
                className="author-avatar"
                src={
                  selectedArticle.author.avatar ||
                  `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Crect width='40' height='40' fill='%23ccc'/%3E%3Ctext x='50%25' y='50%25' font-size='20' text-anchor='middle' dy='.3em' fill='%23fff'%3E${selectedArticle.author.name[0]}%3C/text%3E%3C/svg%3E`
                }
                alt={
                  selectedArticle.author.avatar
                    ? `${selectedArticle.author.name}'s avatar`
                    : "Author avatar placeholder"
                }
              />
            </div>
            <div className="author-date-container">
              <span className="article-author">
                {selectedArticle.author.name}
              </span>
              <span className="article-date">
                Published on{" "}
                {new Date(selectedArticle.publishedDate).toLocaleDateString()}
              </span>
            </div>
          </div>
          <div
            className="article-content"
            dangerouslySetInnerHTML={{
              __html: parseAndRenderContent(selectedArticle.content),
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ArticlesUi = ({
  customizations,
  isShowDetailsPage,
  setIsShowDetailsPage,
  categoryWithArticles,
}) => {
  // States
  const [articleCategories, setArticleCategories] =
    useState(categoryWithArticles);
  const [articlesList, setArticlesList] = useState(null);
  const [currentPage, setCurrentPage] = useState("articlesCatListPage");
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isShowArticlesListPage, setIsArticlesListPage] = useState(false);

  // Handle article search
  const handleArticleSearch = useCallback(
    (e) => {
      const query = e.target.value.toLowerCase();
      setArticleCategories(
        query === ""
          ? categoryWithArticles
          : categoryWithArticles.filter(
              (article) =>
                article.name.toLowerCase().includes(query) ||
                article.description.toLowerCase().includes(query)
            )
      );
    },
    [categoryWithArticles]
  );

  // Handle back to articles
  const handleBackBtnClick = (page) => {
    if (page === "articlesCatListPage") {
      setIsShowDetailsPage(false);
      setCurrentPage("articlesCatListPage");
    } else {
      setCurrentPage("articlesListPage");
      setIsShowDetailsPage(true);
      setIsArticlesListPage(true);
    }
  };

  // Handle article click
  const handleArticleClick = useCallback((articleCategory) => {
    setArticlesList(articleCategory);
    setCurrentPage("articlesListPage");
    setIsShowDetailsPage(true);
    setIsArticlesListPage(true);
  }, []);

  // Show article details
  const showArticleDetails = useCallback(
    (article) => {
      setSelectedArticle(article);
      setIsShowDetailsPage(true);
      setCurrentPage(null);
      setIsArticlesListPage(false);
    },
    [setIsShowDetailsPage]
  );

  // Truncate text and add ellipsis
  const truncateText = useCallback((text, maxLength) => {
    return text.length <= maxLength
      ? text
      : text.slice(0, maxLength - 3) + "...";
  }, []);

  // Parse and render content, including code snippets
  const parseAndRenderContent = useCallback((content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    doc.querySelectorAll("pre code").forEach((codeBlock) => {
      const language = codeBlock.className.replace("language-", "");
      const formattedCode = formatCodeBlock(codeBlock.textContent, language);
      codeBlock.innerHTML = formattedCode;
      codeBlock.parentElement.classList.add("code-block");
    });

    return doc.body.innerHTML;
  }, []);

  // Format code blocks without using a third-party library
  const formatCodeBlock = useCallback((code, language) => {
    const keywords = [
      "function",
      "const",
      "let",
      "var",
      "if",
      "else",
      "for",
      "while",
      "return",
    ];
    const specialChars = ["{", "}", "(", ")", "[", "]", ";", ",", "."];

    return code
      .split(/\r\n|\n/)
      .map((line) =>
        line
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(
            new RegExp(`\\b(${keywords.join("|")})\\b`, "g"),
            '<span class="keyword">$1</span>'
          )
          .replace(
            new RegExp(`([${specialChars.join("\\")}])`, "g"),
            '<span class="special-char">$1</span>'
          )
          .replace(
            /(\/\/.*|\/\*[\s\S]*?\*\/)/g,
            '<span class="comment">$1</span>'
          )
          .replace(/(".*?"|'.*?'|`.*?`)/g, '<span class="string">$1</span>')
      )
      .join("\n");
  }, []);

  return (
    <div
      className="chat__articles"
      id="articles-body-container"
      style={{ padding: "0px" }}
    >
      {isShowDetailsPage && !isShowArticlesListPage ? (
        <ArticleDetails
          selectedArticle={selectedArticle}
          customizations={customizations}
          parseAndRenderContent={parseAndRenderContent}
          handleBackBtnClick={handleBackBtnClick}
        />
      ) : currentPage === "articlesCatListPage" ? (
        <div id="articles" style={{ fontFamily: customizations?.font }}>
          <div className="article-container">
            <ArticleSearch handleArticleSearch={handleArticleSearch} />
            <ArticleCategoryList
              articleCategories={articleCategories}
              handleArticleClick={handleArticleClick}
            />
          </div>
        </div>
      ) : (
        <ArticleList
          articlesList={articlesList}
          showArticleDetails={showArticleDetails}
          truncateText={truncateText}
          customizations={customizations}
          handleBackBtnClick={handleBackBtnClick}
        />
      )}
    </div>
  );
};

export default ArticlesUi;
