import React, { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Select, Tooltip } from "antd";
import { shallow } from "zustand/shallow";
import { BooleanLogicNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { extractAnswerVariables } from "../../../../../helpers/extractAnswerVariables";
import { useTranslation } from "react-i18next";

const BooleanLogicNodeDataEditor: FC<BooleanLogicNode> = ({ id, type }) => {
  const { getNode, changeNodeData, nodes } = useStore(selector, shallow);

  const state = getNode(id);
  const { t } = useTranslation();

  // States for the component
  const [leftValue, setLeftValue] = useState(state?.data?.leftValue);
  const [operator, setOperator] = useState(state?.data?.operator || "AND");
  const [rightValue, setRightValue] = useState(state?.data?.rightValue);

  const [allAnswerVariables, setAllAnswerVariables] = useState([]);

  // Each time state?.data? changes, update the state
  useEffect(() => {
    setLeftValue(state?.data?.leftValue);
    setOperator(state?.data?.operator);
    setRightValue(state?.data?.rightValue);

    // Get all answer variables from nodes
    const answerVariables = extractAnswerVariables(nodes);

    // Set the answer variables
    setAllAnswerVariables(answerVariables);
  }, [id]);

  // Handle left value change
  const handleLeftValueChange = useCallback(
    (value: any) => {
      setLeftValue(value);

      // Change node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, leftValue: value },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle operator change
  const handleOperatorChange = useCallback(
    (value: any) => {
      setOperator(value);

      // Change node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, operator: value },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle right value change
  const handleRightValueChange = useCallback(
    (value: any) => {
      setRightValue(value);

      // Change node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, rightValue: value },
      });
    },
    [state, type, changeNodeData]
  );

  // Rendering function for value input with updated styles
  const renderValueInput = (
    value: any,
    onChangeHandler: any,
    tooltipTitle: any
  ) => (
    <Tooltip placement="top" title={tooltipTitle}>
      <Row gutter={8} align="middle">
        <Col span={24}>
          <Select
            size="small"
            showSearch
            style={{ width: "100%" }}
            value={value}
            onChange={onChangeHandler}
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {allAnswerVariables?.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Tooltip>
  );

  // Define logical operators with descriptions
  const logicalOperators = [
    { value: "AND", description: "True if both values are true" },
    { value: "OR", description: "True if either value is true" },
    {
      value: "NOT",
      description: "True if left value is true and right value is false",
    },
    { value: "XOR", description: "True if values are different" },
    { value: "NAND", description: "True if NOT both values are true" },
    { value: "NOR", description: "True if neither value is true" },
    { value: "XNOR", description: "True if values are the same" },
  ];

  return (
    <>
      <Row align="middle" gutter={[0, 16]} style={{ padding: "0 16px" }}>
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.logicalNodes.booleanLogicNodeInfo")}
          </p>
        </Row>

        {/* Left Value Input */}
        <Col span={24}>
          <label style={{ marginRight: "10px" }}>
            <b>{t("flowBuilder.sidebar.editor.logicalNodes.leftValue")}</b>
          </label>
          {renderValueInput(
            leftValue,
            handleLeftValueChange,
            t("flowBuilder.sidebar.editor.logicalNodes.selectLeftValue")
          )}
        </Col>

        {/* Operator Selection */}
        <Col span={24}>
          <label style={{ marginRight: "10px" }}>
            {t("flowBuilder.sidebar.editor.logicalNodes.operator")}
          </label>
          <Select
            style={{ width: "100%" }}
            onChange={handleOperatorChange}
            value={operator}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <div style={{ padding: "8px", textAlign: "center" }}>
                  <small style={{ color: "gray" }}>
                    {t(
                      "flowBuilder.sidebar.editor.logicalNodes.selectOperator"
                    )}
                  </small>
                </div>
              </div>
            )}
          >
            {logicalOperators.map((op) => (
              <Select.Option
                key={op.value}
                value={op.value}
                title={op.description}
              >
                {`${op.value} - ${op.description}`}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {/* Right Value Input */}
        <Col span={24}>
          <label style={{ marginRight: "10px" }}>
            <b>{t("flowBuilder.sidebar.editor.logicalNodes.rightValue")}</b>
          </label>
          {renderValueInput(
            rightValue,
            handleRightValueChange,
            t("flowBuilder.sidebar.editor.logicalNodes.selectRightValue")
          )}
        </Col>
      </Row>
    </>
  );
};

export default BooleanLogicNodeDataEditor;
