import React, { FC, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import useStore from "../../../../../store/store";
import ContextMenu from "../../../../components/contextMenu/ContextMenu";
import { RandomFlowNodeData } from "../../../typings";
import RandFlow from "././randFlow";
import { RandomFlow } from "@assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const RandomChoiceNode: FC<NodeProps<RandomFlowNodeData>> = ({ id, data }) => {
  const [nodeId, setNodeId] = useState<string | null>(null);

  const { getNode } = useStore((state: any) => ({
    getNode: state?.getNode,
  }));

  const node = getNode(id);
  const { t } = useTranslation();

  useEffect(() => {
    setNodeId(node?.id);
  }, [node?.id]);

  if (!node) {
    return null; // or some fallback component
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />
      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "230px",
          maxWidth: "230px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row gutter={16} align="middle">
            <Col
              span={19}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={RandomFlow}
                alt="number"
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
              />
              {t("flowBuilder.builderNodes.randomFlow")}
            </Col>

            <Col span={5}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          {/* Add choices based on the range of count */}
          {node?.data?.branches &&
            node?.data?.branches?.map((branch: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <RandFlow
                    id={branch?.id}
                    index={index}
                    weight={branch?.weight}
                  />
                </React.Fragment>
              );
            })}
        </div>
      </Col>
    </>
  );
};

export default RandomChoiceNode;
