import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin, Empty } from "antd";

// Utility function for filtering and grouping chat data
const processChatData = (data, range, customRange, group) => {
  const now = new Date();

  // Filter the data based on the time range
  const filterChatData = () => {
    let filteredData = [];
    switch (range) {
      case "today":
        filteredData = data.filter((item) => {
          const chatDate = new Date(item.chatDate);
          return (
            chatDate.getDate() === now.getDate() &&
            chatDate.getMonth() === now.getMonth() &&
            chatDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case "yesterday":
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        filteredData = data.filter((item) => {
          const chatDate = new Date(item.chatDate);
          return (
            chatDate.getDate() === yesterday.getDate() &&
            chatDate.getMonth() === yesterday.getMonth() &&
            chatDate.getFullYear() === yesterday.getFullYear()
          );
        });
        break;
      case "last7days":
        const sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(now.getDate() - 7);
        filteredData = data.filter((item) => {
          const chatDate = new Date(item.chatDate);
          return chatDate >= sevenDaysAgo && chatDate <= now;
        });
        break;
      case "last30days":
        const thirtyDaysAgo = new Date(now);
        thirtyDaysAgo.setDate(now.getDate() - 30);
        filteredData = data.filter((item) => {
          const chatDate = new Date(item.chatDate);
          return chatDate >= thirtyDaysAgo && chatDate <= now;
        });
        break;
      case "thismonth":
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        filteredData = data.filter((item) => {
          const chatDate = new Date(item.chatDate);
          return chatDate >= startOfMonth && chatDate <= now;
        });
        break;
      case "lastmonth":
        const startOfLastMonth = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          1
        );
        const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        filteredData = data.filter((item) => {
          const chatDate = new Date(item.chatDate);
          return chatDate >= startOfLastMonth && chatDate <= endOfLastMonth;
        });
        break;
      case "custom":
        if (customRange && customRange.length === 2) {
          const [startDate, endDate] = customRange.map(
            (date) => new Date(date)
          );
          filteredData = data.filter((item) => {
            const chatDate = new Date(item.chatDate);
            return chatDate >= startDate && chatDate <= endDate;
          });
        }
        break;
      default:
        filteredData = data;
        break;
    }
    return filteredData;
  };

  // Group the data based on the grouping interval
  const groupChatData = (filteredData) => {
    const groupedData = {};

    filteredData?.forEach((item) => {
      const chatDate = new Date(item.chatDate);
      let groupKey;

      switch (group) {
        case "hourly":
          groupKey = new Date(
            chatDate.getFullYear(),
            chatDate.getMonth(),
            chatDate.getDate(),
            chatDate.getHours()
          ).getTime();
          break;

        case "daily":
          groupKey = new Date(
            chatDate.getFullYear(),
            chatDate.getMonth(),
            chatDate.getDate()
          ).getTime();
          break;

        case "weekly":
          const startOfWeek = new Date(
            chatDate.getFullYear(),
            chatDate.getMonth(),
            chatDate.getDate() - chatDate.getDay()
          );
          groupKey = startOfWeek.getTime();
          break;

        case "monthly":
          groupKey = new Date(
            chatDate.getFullYear(),
            chatDate.getMonth(),
            1
          ).getTime();
          break;

        default:
          groupKey = chatDate.getTime();
          break;
      }

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = 0;
      }
      groupedData[groupKey] += 1;
    });

    return Object.entries(groupedData).map(([key, value]) => ({
      x: parseInt(key, 10),
      y: value,
    }));
  };

  const filteredData = filterChatData();
  return groupChatData(filteredData);
};

// LineChart component
const LineChart = ({
  chartHeight,
  chartWidth,
  chatData,
  timeRange,
  customDateRange,
  grouping,
}) => {
  const { t } = useTranslation();

  // Memoize the sorted and processed chat data
  const seriesData = useMemo(
    () => processChatData(chatData, timeRange, customDateRange, grouping),
    [chatData, timeRange, customDateRange, grouping]
  );

  const options = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      title: {
        text: t("metrics.chatsPerInterval"),
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
    colors: ["#008FFB"],
  };

  const series = [
    {
      name: t("metrics.chatsPerInterval"),
      data: seriesData,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={chartHeight}
      width={chartWidth}
    />
  );
};

// BarChart component
const BarChart = ({
  chartHeight,
  chartWidth,
  chatData,
  timeRange,
  customDateRange,
  grouping,
}) => {
  const { t } = useTranslation();

  // Use the common utility function to process the data for bar chart
  const processedData = useMemo(
    () => processChatData(chatData, timeRange, customDateRange, grouping),
    [chatData, timeRange, customDateRange, grouping]
  );

  const options = {
    chart: {
      id: "bar-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      title: {
        text: t("metrics.chatsPerInterval"),
      },
    },
    colors: ["#00E396"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
  };

  const series = [
    {
      name: t("metrics.chatsPerInterval"),
      data: processedData.map((item) => ({
        x: item.x,
        y: item.y,
      })),
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={chartHeight}
      width={chartWidth}
    />
  );
};

// PieChart component
const PieChart = ({
  chartHeight,
  chartWidth,
  chatData,
  timeRange,
  customDateRange,
  grouping,
}) => {
  const { t } = useTranslation();

  // Use the common utility function to process the data for pie chart
  const processedData = useMemo(
    () => processChatData(chatData, timeRange, customDateRange, grouping),
    [chatData, timeRange, customDateRange, grouping]
  );

  const options = {
    chart: {
      type: "pie",
    },
    labels: processedData.map((item) => new Date(item.x).toLocaleDateString()),
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#546E7A",
      "#26a69a",
    ],
  };

  const series = processedData.map((item) => item.y);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      height={chartHeight}
      width={chartWidth}
    />
  );
};

// Main MetricsCharts component
const MetricsCharts = ({ chartType, timeRange, customDateRange, grouping }) => {
  const [chartHeight, setChartHeight] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);
  const { t } = useTranslation();

  const { chatbotResponsesAnswerVariables, loading } = useSelector(
    (state) => state?.chatbot
  );

  // Fit the Chart size to all device screens
  useEffect(() => {
    const updateChartDimensions = () => {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      setChartHeight(windowHeight * 0.4);
      setChartWidth(windowWidth * 0.6);
    };

    window.addEventListener("resize", updateChartDimensions);
    updateChartDimensions();

    return () => {
      window.removeEventListener("resize", updateChartDimensions);
    };
  }, []);

  const renderChart = () => {
    if (loading) {
      return <Spin size="large" />;
    }

    if (
      !chatbotResponsesAnswerVariables ||
      chatbotResponsesAnswerVariables.length === 0
    ) {
      return <Empty description={t("metrics.noData")} />;
    }

    const props = {
      chartHeight,
      chartWidth,
      chatData: chatbotResponsesAnswerVariables,
      timeRange: timeRange,
      customDateRange: customDateRange,
      grouping: grouping,
    };

    switch (chartType) {
      case "Line":
        return <LineChart {...props} />;
      case "Bar":
        return <BarChart {...props} />;
      case "Pie":
        return <PieChart {...props} />;
      default:
        return <LineChart {...props} />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {renderChart()}
    </div>
  );
};

export default MetricsCharts;
