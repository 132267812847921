import { FC, useState, useEffect } from "react";
import { Row, Col, Input, Select, Tooltip } from "antd";
import { shallow } from "zustand/shallow";
import { QuizNode } from "../../../../common/nodes/typings";
import useStore, { selector } from "../../../../store/store";
import QuillEditor from "../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../common/components/saveAnswer/saveAnswer";

const QuizNodeDataEditor: FC<QuizNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);
  const [quizPrompt, setQuizPrompt] = useState(state?.data.quizPrompt);
  const [option1, setOption1] = useState(state?.data.option1);
  const [option2, setOption2] = useState(state?.data.option2);
  const [option3, setOption3] = useState(state?.data.option3);
  const [option4, setOption4] = useState(state?.data.option4);
  const [option5, setOption5] = useState(state?.data.option5);
  const [disableOption3, setDisableOption3] = useState(
    state?.data.disableOption3
  );
  const [disableOption4, setDisableOption4] = useState(
    state?.data.disableOption4
  );
  const [disableOption5, setDisableOption5] = useState(
    state?.data.disableOption5
  );
  const [correctAnswer, setCorrectAnswer] = useState(state?.data.correctAnswer);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  // Each time state?.data changes, update the state
  useEffect(() => {
    setQuizPrompt(state?.data?.quizPrompt);
    setOption1(state?.data?.option1);
    setOption2(state?.data?.option2);
    setOption3(state?.data?.option3);
    setOption4(state?.data?.option4);
    setOption5(state?.data?.option5);
    setDisableOption3(state?.data?.disableOption3);
    setDisableOption4(state?.data?.disableOption4);
    setDisableOption5(state?.data?.disableOption5);
    setCorrectAnswer(state?.data?.correctAnswer);
    setAnswerVariable(state?.data?.answerVariable);
  }, [state?.data]);

  // Handle quiz prompt change
  const handleQuizPromptChange = (content: string) => {
    setQuizPrompt(content);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, quizPrompt: content },
    });
  };

  // Handle quiz option change
  const handleQuizOptionChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "option1":
        setOption1(value);
        break;
      case "option2":
        setOption2(value);
        break;
      case "option3":
        setOption3(value);
        break;
      case "option4":
        setOption4(value);
        break;
      case "option5":
        setOption5(value);
        break;
      default:
        break;
    }

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, [name]: value },
    });
  };

  // Handle toggle disable option
  const handleToggleDisableOption = (e: any) => {
    const { name, checked } = e.target;
    switch (name) {
      case "disableOption3":
        setDisableOption3(checked);
        break;
      case "disableOption4":
        setDisableOption4(checked);
        break;
      case "disableOption5":
        setDisableOption5(checked);
        break;
      default:
        break;
    }

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, [name]: checked },
    });
  };

  // Handle quiz answer select change
  const handleQuizAnswerSelectChange = (value: any) => {
    setCorrectAnswer(value);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, correctAnswer: value },
    });
  };

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              color: "gray",
            }}
          >
            Use this node when you want to ask a question and give the user
            multiple choices to answer. This node should be followed by a
            "Variable Node" to store the user's answer count.
          </p>
        </Row>

        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={quizPrompt}
              onEditorChange={handleQuizPromptChange}
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "15px",
            width: "100%",
          }}
        />

        {/* Options */}
        <Col span={24} className="sidebar__heading">
          <p
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginBottom: "4px",
              textAlign: "center",
            }}
            className="sidebar__heading"
          >
            Options
          </p>

          {/* Option 1 */}
          <Row
            align="middle"
            style={{
              marginBottom: "5px",
            }}
          >
            <Col span={7} className="sidebar__heading">
              Option 1
            </Col>
            <Col span={14}>
              <Input
                value={option1}
                onChange={(e) => handleQuizOptionChange(e)}
                name="option1"
              />
            </Col>
          </Row>

          {/* Option 2 */}
          <Row
            align="middle"
            style={{
              marginBottom: "5px",
            }}
          >
            <Col span={7} className="sidebar__heading">
              Option 2
            </Col>
            <Col span={14}>
              <Input
                value={option2}
                onChange={(e) => handleQuizOptionChange(e)}
                name="option2"
              />
            </Col>
          </Row>

          {/* Option 3 */}
          <Row
            align="middle"
            style={{
              marginBottom: "5px",
            }}
          >
            <Col span={7} className="sidebar__heading">
              Option 3
            </Col>
            <Col span={14}>
              <Input
                value={option3}
                disabled={disableOption3}
                onChange={(e) => handleQuizOptionChange(e)}
                name="option3"
              />
            </Col>

            {/* Checkbox */}
            <Tooltip
              placement="top"
              title="Disable Option 3"
              className="hp-ml-8"
            >
              <Col span={2}>
                <Input
                  type="checkbox"
                  checked={disableOption3}
                  onChange={(e) => handleToggleDisableOption(e)}
                  style={{ border: "none", marginLeft: "10px" }}
                  name="disableOption3"
                />
              </Col>
            </Tooltip>
          </Row>

          {/* Option 4 */}
          <Row
            align="middle"
            style={{
              marginBottom: "5px",
            }}
          >
            <Col span={7} className="sidebar__heading">
              Option 4
            </Col>
            <Col span={14}>
              <Input
                value={option4}
                disabled={disableOption4}
                onChange={(e) => handleQuizOptionChange(e)}
                name="option4"
              />
            </Col>

            {/* Checkbox */}
            <Tooltip
              placement="top"
              title="Disable Option 4"
              className="hp-ml-8"
            >
              <Col span={2}>
                <Input
                  type="checkbox"
                  checked={disableOption4}
                  onChange={(e) => handleToggleDisableOption(e)}
                  style={{ border: "none", marginLeft: "10px" }}
                  name="disableOption4"
                />
              </Col>
            </Tooltip>
          </Row>

          {/* Option 5 */}
          <Row
            align="middle"
            style={{
              marginBottom: "5px",
            }}
          >
            <Col span={7} className="sidebar__heading">
              Option 5
            </Col>
            <Col span={14}>
              <Input
                value={option5}
                disabled={disableOption5}
                onChange={(e) => handleQuizOptionChange(e)}
                name="option5"
              />
            </Col>

            {/* Checkbox */}
            <Tooltip
              placement="top"
              title="Disable Option 5"
              className="hp-ml-8"
            >
              <Col span={2}>
                <Input
                  type="checkbox"
                  checked={disableOption5}
                  onChange={(e) => handleToggleDisableOption(e)}
                  style={{ border: "none", marginLeft: "10px" }}
                  name="disableOption5"
                />
              </Col>
            </Tooltip>
          </Row>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "20px",
            width: "100%",
          }}
        />

        <>
          <Col span={8} className="sidebar__heading">
            Correct Answer Option
          </Col>
          <Col span={14}>
            <Tooltip
              placement="left"
              title={"Choose the option with the correct answer"}
            >
              <Select
                style={{ width: "110%" }}
                onChange={(value: any) => handleQuizAnswerSelectChange(value)}
                value={correctAnswer}
              >
                <Select.Option value="option1">Option 1</Select.Option>
                <Select.Option value="option2">Option 2</Select.Option>
                {!disableOption3 && (
                  <Select.Option value="option3">Option 3</Select.Option>
                )}
                {!disableOption4 && (
                  <Select.Option value="option4">Option 4</Select.Option>
                )}
                {!disableOption5 && (
                  <Select.Option value="option5">Option 5</Select.Option>
                )}
              </Select>
            </Tooltip>
          </Col>
          <SaveAnswer
            id={id}
            type={type}
            variableName={answerVariable ? answerVariable : "quiz"}
          />
        </>
      </Row>
    </>
  );
};

export default QuizNodeDataEditor;
