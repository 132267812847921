import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import {
  MdClose,
  MdMobileFriendly,
  MdMonitor,
  MdMoreVert,
  MdRefresh,
} from "react-icons/md";

import {
  // v1 (LEGACY)
  _handleUserInputSubmit,
  _handleFileUpload,
  _handleUserRangeSubmit,
  // v2
  _handleAskFileUpload,
  _handleAskQuestionInputSubmit,
  _handleAskMultipleQuestionsSubmit,
} from "../../../views/admin/chatbot/builder/preview/ts/handlers";
import { _getCurrentStepsAndIndex } from "../../../views/admin/chatbot/builder/preview/ChatbotFlowPreview";
import botAvatar from "../../../assets/images/pages/customize/botLogo.png";
import BOT_LOGO from "../../../assets/images/logo/logo.png";
import CustomizeChatMessages from "../customize/components/CustomizeChatMessages";
import ChatInitialPageUi from "../customize/components/ChatInitialPageUi";
import ArticlesUi from "../customize/components/ArticlesUi";
import "./liveChatStyles.css";
import "../../../assets/css/ChatInitialPageUi.css";
import ArticlesData from "../../../utils/ArticlesData.json";

const FlowPreview = ({
  name,
  embedType,
  customizations,
  toggleModalSize,
  restartBot,
  closePreview,
  isCustomize,
}) => {
  // Local states
  const [isOpenInitialPage, setIsOpenInitialPage] = useState(false);
  const [isTab, setIsTab] = useState("chat");
  const [isShowDetailsPage, setIsShowDetailsPage] = useState(false);

  // upon sidebar options click
  useEffect(() => {
    if (customizations?.sidebarActiveOption === "knowledge-base") {
      setIsTab(customizations?.enableKnowledgeBase ? "articles" : "chat");
      setIsOpenInitialPage(false);
      setIsShowDetailsPage(false);
    } else if (customizations?.sidebarActiveOption === "initial-page") {
      setIsOpenInitialPage(customizations?.enableInitialPage || false);
      setIsShowDetailsPage(false);
      setIsTab("chat");
    } else {
      setIsTab("chat");
      setIsOpenInitialPage(false);
      setIsShowDetailsPage(false);
    }
  }, [isCustomize, customizations?.sidebarActiveOption]);

  // Enable knowledge-base
  useEffect(() => {
    if (!customizations?.enableKnowledgeBase || !isCustomize) {
      setIsTab("chat");
      setIsShowDetailsPage(false);
      document.documentElement.style.setProperty("--kbEnabled", `0`);
    } else {
      setIsTab("articles");
      document.documentElement.style.setProperty("--kbEnabled", `1`);
    }
  }, [customizations?.enableKnowledgeBase, isCustomize]);

  // Enable initial page
  useEffect(() => {
    setIsOpenInitialPage(customizations?.enableInitialPage || false);
    setIsShowDetailsPage(false);
    setIsTab("chat");
  }, [customizations?.enableInitialPage]);

  // Get Dynamic Background  function
  const getBackgroundStyle = () => {
    if (customizations.chatBgType === "image") {
      return { backgroundImage: `url(${customizations.chatBgImg})` };
    } else if (
      customizations.chatBgType === "color" ||
      customizations.chatBgType === "solid"
    ) {
      return { backgroundColor: customizations.chatBgColor };
    } else if (customizations.chatBgType === "gradient") {
      return {
        backgroundImage: `linear-gradient(to bottom, ${customizations.gradientBgOne}, ${customizations.gradientBgTwo})`,
      };
    } else {
      return { backgroundColor: customizations.chatBgColor };
    }
  };

  // ------------------- Add event listener to the input field -------------------
  const handleInputFieldSize = (event) => {
    event.stopPropagation();

    const target = event.target;
    target.rows = 1;
    const height = target.scrollHeight;
    target.rows = Math.ceil(height / 24);
    if (target.rows > 4) {
      target.rows = 4;
    }
  };

  // Handle focus on the input field
  const handleFocus = (event) => {
    event.stopPropagation();

    const target = event.target;
    target.rows = 1;
    const height = target.scrollHeight;
    target.rows = Math.ceil(height / 24);
    if (target.rows > 4) {
      target.rows = 4;
    }
  };

  // Handle key up on the input field
  const handleKeyUp = (event) => {
    event.stopPropagation();
    // Get current steps and index
    const { steps, currentIndex } = _getCurrentStepsAndIndex();

    if (event.keyCode === 13 && event.target.value !== "") {
      // Trim the value
      const value = event.target.value.trim();

      // If step type is user input node else user range node
      if (steps[currentIndex].type === "user-input-node") {
        _handleUserInputSubmit(embedType, customizations, value);
      } else if (steps[currentIndex].type === "user-range-node") {
        _handleUserRangeSubmit(embedType, customizations);
      } else if (steps[currentIndex].type === "ask-multiple-questions-node") {
        _handleAskMultipleQuestionsSubmit(embedType, customizations, value);
      } else {
        _handleAskQuestionInputSubmit(embedType, customizations, value);
      }
    }
  };

  // ------------------- Add event listener to the send button -------------------
  // Handle send button click
  const handleSendMessage = (event) => {
    event.stopPropagation();
    // Get current steps and index
    const { steps, currentIndex } = _getCurrentStepsAndIndex();

    const inputElement = document.querySelector("#inputField");

    if (inputElement.value !== "") {
      // Trim the value
      inputElement.value = inputElement.value.trim();

      // If step type is user input node else user range node
      if (steps[currentIndex].type === "user-input-node") {
        _handleUserInputSubmit(embedType, customizations, inputElement.value);
      } else if (steps[currentIndex].type === "user-range-node") {
        _handleUserRangeSubmit(embedType, customizations);
      } else if (steps[currentIndex].type === "ask-multiple-questions-node") {
        _handleAskMultipleQuestionsSubmit(
          embedType,
          customizations,
          event.target.value
        );
      } else {
        _handleAskQuestionInputSubmit(
          embedType,
          customizations,
          inputElement.value
        );
      }
    }
  };

  // ------------------- Add event listener to the upload button -------------------
  const handleFileUpload = (event) => {
    event.stopPropagation();

    // Get _getCurrentStepsAndIndex
    const { steps, currentIndex } = _getCurrentStepsAndIndex();

    const file = event.target.files[0];

    if (file) {
      // If step shape is user input node else user range node
      if (steps[currentIndex].type === "user-input-node") {
        // Send the file to the _handleFileUpload function
        _handleFileUpload(embedType, customizations, file);
      } else if (steps[currentIndex].type === "ask-file-node") {
        // Send the file to the _handleFileUpload function
        _handleAskFileUpload(embedType, customizations, file);
      }
    }
  };

  // Chat tab Buttos Ui
  const ChatTabButton = ({ customizations }) => {
    return (
      <div
        class="chat__tab__buttons"
        id="live-chat-tab-buttons"
        style={{
          top: customizations?.enableTagline ? "14%" : "",
        }}
      >
        <button
          class="chat__tab__button chat__tab__button--chat"
          id="chat-tab"
          style={{
            color: isTab === "chat" ? customizations?.headerTextColor : "#000",
            backgroundColor:
              isTab === "chat" ? customizations?.headerBgColor : "#f0f0f0",
          }}
          onClick={() => setIsTab("chat")}
        >
          Chat
        </button>
        <button
          class="chat__tab__button chat__tab__button--articles"
          id="articles-tab"
          onClick={() => setIsTab("articles")}
          style={{
            color:
              isTab === "articles" ? customizations?.headerTextColor : "#000",
            backgroundColor:
              isTab === "articles" ? customizations?.headerBgColor : "#f0f0f0",
          }}
        >
          Articles
        </button>
      </div>
    );
  };

  // Handle back btn click
  const handleIsBackBtnClick = () => {
    setIsOpenInitialPage(true);
    setIsTab("chat");
  };

  // Function for truncate text
  const truncate = (text, length) => {
    if (text?.length > length) {
      return text?.substring(0, length) + "...";
    }
    return text;
  };

  return (
    <div
      className={`${embedType}__chat__screen ${
        customizations?.widgetPosition === "left" ? "left" : ""
      }`}
    >
      {isCustomize && isOpenInitialPage ? (
        <ChatInitialPageUi
          customizations={customizations}
          setIsTab={setIsTab}
          setIsOpenInitialPage={setIsOpenInitialPage}
          recentArticles={ArticlesData?.recentArticles}
        />
      ) : (
        <>
          <div
            className={`${embedType}__chat__head`}
            style={{
              backgroundColor: customizations?.headerBgColor,
              color: customizations?.headerTextColor,
              display:
                (isCustomize && !isShowDetailsPage) || !isCustomize
                  ? "flex"
                  : "none",
            }}
          >
            {/* Back Btn */}
            {isCustomize && customizations?.enableInitialPage && (
              <span class="back-btn" onClick={handleIsBackBtnClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 42 42"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M27.066 1L7 21.068l19.568 19.569l4.934-4.933l-14.637-14.636L32 5.933z"
                  />
                </svg>
              </span>
            )}

            {/* Logo */}
            {!customizations?.hideLogo && (
              <img
                src={customizations?.logo || botAvatar}
                alt="Logo"
                className={`${embedType}__chat__logo`}
                loading="lazy"
                decoding="async"
              />
            )}

            <div className={`${embedType}__chat__header__container`}>
              <div
                className={
                  !customizations?.hideLogo
                    ? `${embedType}__chat__header__title`
                    : `${embedType}__chat__header__title__no_logo`
                }
              >
                {!isCustomize
                  ? truncate(name, 10)
                  : truncate(customizations?.logoText, 20)}{" "}
                - Preview
                {isCustomize && customizations?.enableTagline && (
                  <span style={{ display: "block" }}>
                    {truncate(customizations?.tagline, 30)}
                  </span>
                )}
              </div>

              <div className={`${embedType}__chat__header__icons`}>
                {!isCustomize ? (
                  <>
                    {/* Restart */}
                    <Tooltip title="Restart">
                      <MdRefresh
                        className="icon"
                        onClick={() => {
                          restartBot();
                        }}
                      />
                    </Tooltip>

                    {/* Desktop view */}
                    <Tooltip title="Desktop View">
                      <MdMonitor
                        className="icon"
                        onClick={() => {
                          toggleModalSize("desktop");
                        }}
                      />
                    </Tooltip>

                    {/* Mobile view */}
                    <Tooltip title="Mobile View">
                      <MdMobileFriendly
                        className="icon"
                        onClick={() => {
                          toggleModalSize("mobile");
                        }}
                      />
                    </Tooltip>

                    {/* Close */}
                    <Tooltip title="Close">
                      <MdClose className="icon" onClick={closePreview} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    {/* More */}
                    <Tooltip>
                      <MdMoreVert className="icon" />
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </div>

          {isTab === "chat" ? (
            <>
              <div
                className="chat__middle__container"
                style={{
                  ...getBackgroundStyle(),
                }}
              >
                {isCustomize && customizations?.enableKnowledgeBase && (
                  <ChatTabButton customizations={customizations} />
                )}
                <div
                  className={`${embedType}__chat__body`}
                  style={{
                    paddingBottom: "5px",
                  }}
                >
                  {!isCustomize ? (
                    <div
                      id="steps"
                      style={{
                        fontFamily: customizations?.font,
                        display: isCustomize ? "none" : "block",
                      }}
                    ></div>
                  ) : (
                    <div>
                      <CustomizeChatMessages customizations={customizations} />
                    </div>
                  )}
                </div>
              </div>

              <div className={`${embedType}__chat__input`}>
                <button id="emoji-button">
                  <svg
                    aria-hidden="true"
                    width="18"
                    height="18"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill={customizations.headerBgColor}
                      fill-rule="evenodd"
                      d="M8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zM8 1.333A6.674 6.674 0 0 0 1.333 8 6.674 6.674 0 0 0 8 14.667 6.674 6.674 0 0 0 14.667 8 6.674 6.674 0 0 0 8 1.333zm0 12c-1.942 0-3.705-1.167-4.601-3.046A.666.666 0 1 1 4.6 9.713C5.275 11.123 6.577 12 8 12c1.431 0 2.733-.875 3.397-2.285a.666.666 0 1 1 1.206.57c-.886 1.88-2.65 3.048-4.603 3.048zM10.667 8a1.335 1.335 0 0 1-1.334-1.333 1.335 1.335 0 0 1 2.667 0C12 7.402 11.402 8 10.667 8zM5.333 8A1.335 1.335 0 0 1 4 6.667c0-.736.599-1.334 1.333-1.334a1.335 1.335 0 0 1 0 2.667z"
                    ></path>
                  </svg>
                </button>

                <textarea
                  id="inputField"
                  placeholder="Type a message..."
                  disabled={true}
                  rows={2}
                  onChange={handleInputFieldSize}
                  onFocus={handleFocus}
                  onKeyUp={handleKeyUp}
                ></textarea>

                <div className="input__upload__icon">
                  <label htmlFor="uploadBtn">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="paper-clip"
                      width="24"
                      height="20"
                      id="uploadSvg"
                    >
                      <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                    </svg>
                  </label>
                  <input
                    type="file"
                    id="uploadBtn"
                    style={{ display: "none" }}
                    disabled
                    onChange={handleFileUpload}
                  />
                  <div
                    id="uploadNudge"
                    className="input__upload__nudge"
                    style={{ display: "none" }}
                  >
                    Upload here
                  </div>
                </div>

                <div className="input__send__icon">
                  <button
                    id="sendButton"
                    onClick={handleSendMessage}
                    style={{ backgroundColor: customizations?.headerBgColor }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="22"
                      viewBox="2 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-send"
                    >
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div
              className="chat__middle__container"
              style={{
                ...getBackgroundStyle(),
              }}
            >
              {!isShowDetailsPage && customizations?.enableKnowledgeBase && (
                <ChatTabButton customizations={customizations} />
              )}
              <ArticlesUi
                customizations={customizations}
                isShowDetailsPage={isShowDetailsPage}
                setIsShowDetailsPage={setIsShowDetailsPage}
                categoryWithArticles={ArticlesData?.categories}
              />
            </div>
          )}

          {/* Chat foot */}
          <div
            className={`${embedType}__chat__foot`}
            style={{
              display: customizations?.hideBrand ? "none" : "block",
            }}
          >
            {customizations?.enableCustomBrand ? (
              <a
                className="conferbot__link"
                href={"customizations?.customBrandLink"}
              >
                <span
                  style={{
                    alignItems: "center",
                    color: "#687882",
                    fontSize: "14px",
                  }}
                >
                  <b>{customizations?.customBrand}</b>
                </span>
              </a>
            ) : (
              <span
                style={{
                  alignItems: "center",
                  color: "#687882",
                  fontSize: "14px",
                }}
              >
                <b>Powered by</b>
                <span style={{ marginLeft: "6px" }}>
                  <a
                    className="conferbot__link"
                    href={`https://www.conferbot.com/?utm_source=preview-${window.location.href}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={BOT_LOGO}
                      alt="Logo"
                      loading="lazy"
                      decoding="async"
                      style={{
                        height: "22px",
                        width: "22px",
                      }}
                    />
                  </a>
                </span>
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FlowPreview;
