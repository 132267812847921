import { FC, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { BooleanLogicNodeData } from "../../../typings";
import useStore from "../../../../../store/store";
import ContextMenu from "../../../../components/contextMenu/ContextMenu";
import { Boolean } from "@assets/images/pages/builder";
import { CodeBlock, dracula } from "react-code-blocks";
import { useTranslation } from "react-i18next";

const BooleanLogicNode: FC<NodeProps<BooleanLogicNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);

  const { getNode } = useStore((state: any) => ({
    getNode: state?.getNode,
  }));

  const node = getNode(id);
  const { t } = useTranslation();

  useEffect(() => {
    setNodeId(node?.id);
  }, [node?.id]);

  if (!node) {
    return null; // or some fallback component
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />

      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "270px",
          maxWidth: "270px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={16}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Col
              span={19}
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={Boolean}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="boolean"
              />
              {t("flowBuilder.builderNodes.booleanLogicNode")}
            </Col>
            <Col span={2}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row gutter={12}>
              <CodeBlock
                language="py"
                text={`if @${node.data.leftValue || "LEFT_VALUE"} ${
                  node.data.operator || "AND"
                } @${node.data.rightValue || "RIGHT_VALUE"}`}
                codeBlock
                theme={dracula}
                showLineNumbers={false}
              />
            </Row>
          </Col>
        </div>

        {/* True */}
        <Row
          align="middle"
          style={{
            width: "100%",
            transition: "border-color 0.3s ease",
            borderRadius: "3px",
            marginTop: "-3px",
          }}
        >
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{ __html: "True" }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
                background: "#83c645",
                color: "#fff",
              }}
            />
          </Col>
          <Col span={24}>
            <Handle
              type="source"
              position={Position.Right}
              id={`source-0`}
              className="node-source"
              style={{ background: "#83c645", marginTop: "-20px" }}
            />
          </Col>
        </Row>

        {/* False */}
        <Row
          align="middle"
          style={{
            width: "100%",
            transition: "border-color 0.3s ease",
            borderRadius: "3px",
            marginTop: "-3px",
          }}
        >
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{ __html: "False" }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
                background: "#e40000",
                color: "#fff",
              }}
            />
          </Col>
          <Col span={24}>
            <Handle
              type="source"
              position={Position.Right}
              id={`source-1`}
              className="node-source"
              style={{ background: "#e40000", marginTop: "-20px" }}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default BooleanLogicNode;
