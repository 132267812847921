import { FC, useEffect, useState } from "react";
import { Row, Col, Tag } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { SelectOptionNodeData } from "../../typings";
import useStore from "../../../../store/store";
import ContextMenu from "../../../components/contextMenu/ContextMenu";
import { Select } from "@assets/images/pages/builder";

const SelectOptionNode: FC<NodeProps<SelectOptionNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);

  const { getSourceConnectionAllowed, getNode } = useStore((state: any) => ({
    getSourceConnectionAllowed: state?.allowSourceConnection,
    getNode: state?.getNode,
  }));

  const node = getNode(id);

  useEffect(() => {
    const getNodeId = node?.id;
    setNodeId(getNodeId);
  }, []);

  if (!node) {
    return null; // or some fallback component
  }

  const allowSourceConnection = getSourceConnectionAllowed(id);

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />

      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "250px",
          maxWidth: "250px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={16}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Tag
              style={{
                position: "absolute",
                top: "-15px",
                left: "50%",
                transform: "translateX(-50%)",
                background: "#f5222d",
                color: "white",
              }}
            >
              Legacy (Use "N Select Option")
            </Tag>

            <Col
              span={18}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={Select}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="select option"
              />
              Select Node
            </Col>

            {/* Delete & Duplicate Node */}
            <Col span={6}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          <Col span={24} className="hp-text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: node?.data?.selectPrompt,
              }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Options
          </Col>

          <Col span={24} className="hp-text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: node?.data?.option1,
              }}
              className="quill-textArea"
              style={{
                fontSize:
                  (node?.data?.optionTextFont === "small" && "12px") ||
                  (node?.data?.optionTextFont === "normal" && "16px") ||
                  (node?.data?.optionTextFont === "large" && "24px") ||
                  (node?.data?.optionTextFont === "huge" && "32px"),
                lineHeight: "1.2",
              }}
            />
          </Col>

          <Col span={24} className="hp-text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: node?.data?.option2,
              }}
              className="quill-textArea"
              style={{
                fontSize:
                  (node?.data?.optionTextFont === "small" && "12px") ||
                  (node?.data?.optionTextFont === "normal" && "16px") ||
                  (node?.data?.optionTextFont === "large" && "24px") ||
                  (node?.data?.optionTextFont === "huge" && "32px"),
                lineHeight: "1.2",
              }}
            />
          </Col>

          {!node?.data?.disableOption3 && (
            <>
              <Col span={24} className="hp-text-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: node?.data?.option3,
                  }}
                  className="quill-textArea"
                  style={{
                    fontSize:
                      (node?.data?.optionTextFont === "small" && "12px") ||
                      (node?.data?.optionTextFont === "normal" && "16px") ||
                      (node?.data?.optionTextFont === "large" && "24px") ||
                      (node?.data?.optionTextFont === "huge" && "32px"),
                    lineHeight: "1.2",
                  }}
                />
              </Col>
            </>
          )}

          {!node?.data?.disableOption4 && (
            <>
              <Col span={24} className="hp-text-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: node?.data?.option4,
                  }}
                  className="quill-textArea"
                  style={{
                    fontSize:
                      (node?.data?.optionTextFont === "small" && "12px") ||
                      (node?.data?.optionTextFont === "normal" && "16px") ||
                      (node?.data?.optionTextFont === "large" && "24px") ||
                      (node?.data?.optionTextFont === "huge" && "32px"),
                    lineHeight: "1.2",
                  }}
                />
              </Col>
            </>
          )}

          {!node?.data?.disableOption5 && (
            <>
              <Col span={24} className="hp-text-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: node?.data?.option5,
                  }}
                  className="quill-textArea"
                  style={{
                    fontSize:
                      (node?.data?.optionTextFont === "small" && "12px") ||
                      (node?.data?.optionTextFont === "normal" && "16px") ||
                      (node?.data?.optionTextFont === "large" && "24px") ||
                      (node?.data?.optionTextFont === "huge" && "32px"),
                    lineHeight: "1.2",
                  }}
                />
              </Col>
            </>
          )}
        </div>
      </Col>
      <Handle
        type="source"
        isConnectable={allowSourceConnection}
        isConnectableStart={allowSourceConnection}
        position={Position.Right}
        id="source"
        className="node-source"
      />
    </>
  );
};

export default SelectOptionNode;
