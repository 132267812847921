import { useCallback, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import useClickOutside from "./useClickOutside";

export const PopoverColorPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <>
      <div
        className="color-picker-circle"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div
          ref={popover}
          style={{
            position: "absolute",
            zIndex: "99999",
            right: "80px",
          }}
        >
          <SketchPicker color={color} onChange={(color) => onChange(color)} />
        </div>
      )}
    </>
  );
};
