import SuperAdmin from "./superAdmin";
import MarketingAdmin from "./marketingAdmin";
import Admin from "./admin";
import Member from "./member";

const Navigation = () => {
  // Destructure the array from components
  const { superAdminNavArray } = SuperAdmin();
  const { marketingAdminNavArray } = MarketingAdmin();
  const { adminNavArray } = Admin();
  const { memberNavArray } = Member();

  const superAdminNav = superAdminNavArray;
  const marketingAdminNav = marketingAdminNavArray;
  const adminNav = adminNavArray;
  const memberNav = memberNavArray;

  return { superAdminNav, marketingAdminNav, adminNav, memberNav };
};

export default Navigation;
