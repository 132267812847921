import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import { PopoverColorPicker } from "../../../../../Popover/PopoverColorPicker";
import Arrow from "@assets/images/pages/customize/down.png";
import BackgroundImg from "@assets/images/pages/customize/background.png";
import SolidCircle from "@assets/images/pages/customize/circle.png";
import ImageIcon from "@assets/images/pages/customize/image.png";
import DeleteIcon from "@assets/images/pages/customize/bin.png";
import { useTranslation } from "react-i18next";

const ChatBackground = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});

  const chatBgImageRef = useRef(null);
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeBackgrounds, setActiveBackgrounds] = useState(
    customizations?.chatBgType ?? "solid"
  );

  const toggleBackground = () => {
    setVisible(!visible);
  };

  // ------------ Chat Background ------------
  const handleColors = (key, value) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, value.hex));
  };

  const handleSetBgType = (value) => {
    setActiveBackgrounds(value);
    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "chatBgType", value)
    );
  };

  // Spinner indicator
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 35,
      }}
      spin
    />
  );

  // handle Change Chat Bg Image
  const handleChangeChatBgImage = async (event) => {
    const file = event.target.files[0];
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("image", file);
    // Make request to upload image
    try {
      setLoading(true);
      await uploadChatbotMedia(bid, fmData, config)
        .then((res) => {
          dispatch(
            updateCurrentChatbotCustomizationsAction(
              bid,
              "chatBgImg",
              res.data.url
            )
          );
          setLoading(false);
          message.success("Chat Background updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Error updating chat background");
        });
    } catch (error) {
      setLoading(false);
      message.error("Error updating chat background");
    }
  };

  return (
    <>
      <div className="dropdown-menu">
        <div onClick={toggleBackground} className="dropdown-trigger">
          <span>
            <img
              src={BackgroundImg}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="background-icon"
            />
            {t("customize.chat.background")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            alt="arrow"
          />
        </div>

        {visible && (
          <>
            <div
              style={{
                borderBottom: "1px solid #7386944d",
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
                position: "relative",
              }}
            >
              <span>{t("customize.chat.backgroundType")}</span>
              <div className="general-bg-main">
                <div
                  className={`general-bg-inner ${
                    activeBackgrounds === "solid" ? "general-bg-active" : ""
                  }`}
                  onClick={() => handleSetBgType("solid")}
                >
                  <img
                    src={SolidCircle}
                    style={{
                      height: "40px",
                      width: "40px",
                      display: "block",
                      marginBottom: "4px",
                    }}
                    alt="solid-circle"
                    className={`${
                      activeBackgrounds === "solid" ? "general-img-active" : ""
                    }`}
                  />
                  <span>{t("customize.chat.solid")}</span>
                </div>

                <div
                  className={`general-bg-inner ${
                    activeBackgrounds === "gradient" ? "general-bg-active" : ""
                  }`}
                  onClick={() => handleSetBgType("gradient")}
                >
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      display: "block",
                      marginBottom: "4px",
                      borderRadius: "100%",
                      background:
                        "linear-gradient(180deg, rgba(223,225,236,1) 10%, rgba(99,102,134,1) 90%)",
                      marginLeft: "5px",
                      textAlign: "center",
                    }}
                    className={`${
                      activeBackgrounds === "gradient"
                        ? "general-img-active"
                        : ""
                    }`}
                  ></div>
                  <span> {t("customize.chat.gradient")}</span>
                </div>

                <div
                  onClick={() => handleSetBgType("image")}
                  className={`general-bg-inner ${
                    activeBackgrounds === "image" ? "general-bg-active" : ""
                  }`}
                >
                  <img
                    src={ImageIcon}
                    style={{
                      height: "40px",
                      width: "40px",
                      display: "block",
                      marginBottom: "4px",
                    }}
                    className={`${
                      activeBackgrounds === "image" ? "general-img-active" : ""
                    }`}
                    alt="set icon"
                  />
                  <span> {t("customize.chat.image")}</span>
                </div>
              </div>
            </div>

            {activeBackgrounds === "solid" && (
              <div
                style={{
                  padding: "20px 25px 5px 25px",
                  backgroundColor: "#d5d7e4",
                }}
              >
                <label htmlFor="color-picker" className="flex-class-label">
                  {t("customize.chat.backgroundColor")}
                  <div className="color-picker">
                    <PopoverColorPicker
                      color={customizations?.chatBgColor}
                      onChange={(color) => handleColors("chatBgColor", color)}
                    />
                  </div>
                </label>
              </div>
            )}
            {activeBackgrounds === "gradient" && (
              <div
                style={{
                  padding: "20px 25px 10px 25px",
                  backgroundColor: "#d5d7e4",
                }}
              >
                <label htmlFor="color-picker" className="flex-class-label">
                  {t("customize.chat.gradientStart")}
                  <div className="color-picker">
                    <PopoverColorPicker
                      color={customizations?.gradientBgOne}
                      onChange={(color) => handleColors("gradientBgOne", color)}
                    />
                  </div>
                </label>

                <label htmlFor="color-picker" className="flex-class-label">
                  {t("customize.chat.gradientEnd")}
                  <PopoverColorPicker
                    color={customizations?.gradientBgTwo}
                    onChange={(color) => handleColors("gradientBgTwo", color)}
                  />
                </label>
              </div>
            )}

            {/* image customization */}
            {activeBackgrounds === "image" && (
              <div
                style={{
                  padding: "20px 25px 10px 25px",
                  backgroundColor: "#d5d7e4",
                }}
              >
                {/* Header Logo Image */}
                <label htmlFor="file-upload">
                  {t("customize.chat.logoImage")}
                  <button className="general-input-btn" htmlFor="file-upload">
                    {t("customize.chat.uploadImage")}
                    <input
                      id="file-upload"
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => handleChangeChatBgImage(e)}
                      ref={chatBgImageRef}
                    />
                  </button>
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading ? (
                    <div style={{ textAlign: "center" }}>
                      <Spin indicator={antIcon} size="large" />
                    </div>
                  ) : (
                    <>
                      {customizations?.chatBgImg !== "" && (
                        <>
                          <img
                            src={customizations?.chatBgImg}
                            style={{ height: "80px", width: "80px" }}
                            alt="logo"
                          />
                          <img
                            onClick={() => {
                              dispatch(
                                updateCurrentChatbotCustomizationsAction(
                                  bid,
                                  "chatBgImg",
                                  ""
                                )
                              );

                              // Reset the file input
                              if (chatBgImageRef.current) {
                                chatBgImageRef.current.value = "";
                              }

                              message.success("Logo deleted successfully");
                            }}
                            src={DeleteIcon}
                            style={{
                              marginTop: "-30px",
                              cursor: "pointer",
                            }}
                            alt="delete-icon"
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* bg Mask Color */}
                <label
                  htmlFor="color-picker"
                  className="flex-class-label"
                  style={{ marginTop: "15px" }}
                >
                  {t("customize.chat.backgroundImageColorMask")}
                  <div className="color-picker">
                    <PopoverColorPicker
                      color={customizations?.chatBgMaskColor}
                      onChange={(color) =>
                        dispatch(
                          updateCurrentChatbotCustomizationsAction(
                            bid,
                            "chatBgMaskColor",
                            color.hex
                          )
                        )
                      }
                    />
                  </div>
                </label>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ChatBackground;
