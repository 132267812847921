import React, { useState, useEffect } from "react";
import { Row, Col, Switch, Spin } from "antd";
import { useSelector } from "react-redux";
import PricingItem from "./item";
import { getAllPlans } from "../../api/subscription/plan";
import ComparePlans from "../Profile/ComparePlans";
import { useTranslation } from "react-i18next";

const OnboardSubscribe = () => {
  const { currentSubscription } = useSelector((state) => state.admin);
  const [plans, setPlans] = useState([]);
  const [annualCycle, setAnnualCycle] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const billedText = `Billed ${annualCycle ? "annually" : "monthly"}`;

  // Get all plans
  useEffect(() => {
    setLoading(true);
    getAllPlans()
      .then((res) => {
        setPlans(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  // Toggle billing cycle
  const onChange = (checked) => {
    if (checked) {
      setAnnualCycle(true);
    } else {
      setAnnualCycle(false);
    }
  };

  return (
    <Row gutter={[32, 32]} className="hp-mb-32">
      <Col span={24}>
        <Row align="middle" justify="center">
          {/* Header  */}
          <Col span={12}>
            <Row align="middle" justify="center" className="hp-mb-12">
              <h3 className="subscribe-heading">
                {t("onBoarding.subscribeHeading")}
              </h3>
            </Row>
          </Col>

          {/* Plans */}
          {loading ? (
            <Row
              align="middle"
              justify="center"
              style={{
                height: "60vh",
                marginLeft: "50%",
              }}
            >
              <Spin />
            </Row>
          ) : (
            <Col span={24}>
              <Row align="middle" justify="center" className="hp-mt-8 hp-mb-8">
                {/* Billed Monthly */}
                <span
                  className={`hp-caption ${
                    annualCycle
                      ? "hp-text-color-black-60"
                      : "hp-text-color-primary-1"
                  }`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginRight: "8px",
                  }}
                >
                  {t("onBoarding.billedMonthly")}
                </span>

                {/* Switch */}
                <Switch
                  className="hp-mx-8"
                  defaultChecked
                  onChange={onChange}
                />

                {/* Billed Annually */}
                <span
                  className={`hp-caption ${
                    annualCycle
                      ? "hp-text-color-primary-1"
                      : "hp-text-color-black-60"
                  }`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginLeft: "8px",
                  }}
                >
                  {t("onBoarding.billedAnnually")}
                </span>
              </Row>

              <Row align="middle" justify="center" className="hp-mt-32 hp-mb-8">
                <h3
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    background:
                      "linear-gradient(90deg, #FF0000 0%, #FF7F00 17.5%, #0000FF 70%, #4B0082 87.5%, #8F00FF 100%)",
                    color: "transparent",
                    WebkitBackgroundClip: "text", // for Safari
                    backgroundClip: "text",
                  }}
                >
                  {t("onBoarding.saveOnAnnualPlan")}
                </h3>
              </Row>

              {/* Pricing Item Component */}
              <PricingItem
                plans={plans}
                annualCycle={annualCycle}
                billedText={billedText}
                currentSubscription={currentSubscription}
              />

              {/* Plans */}
              <ComparePlans />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default OnboardSubscribe;
