import { message } from "antd";
import { create } from "zustand";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  DefaultEdgeOptions,
  Edge,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from "reactflow";

import { DropTargetMonitor } from "react-dnd";
import { NodeData, Nodes } from "../common/nodes/typings";
import { NodeTypes } from "../types/types";
import { dndAllNodes } from "./dndNodes";

// Max history size (undo/redo)
const MAX_HISTORY_SIZE = 50;

const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: true,
};

type RFState = {
  ref: any;
  setRef: (ref: any) => void;
  dropPosition: any;
  setDropPosition: (position: any) => void;
  nodes: Node<NodeData, NodeTypes>[];
  edges: Edge[];
  stateHistory: any[];
  currentPointer: number;
  maxPointer: number;
  setNodes: (nodes: Node<NodeData, NodeTypes>[]) => void;
  setEdges: (edges: Edge[]) => void;
  undo: () => void;
  redo: () => void;
  resetUndoRedo: () => void;
  canUndo: () => boolean;
  canRedo: () => boolean;
  wasLastActionUndo: () => boolean;
  selectedNodes: Array<Nodes>;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onNodeDragStart: (event: any, node: any) => void;
  onNodeDragStop: (event: any, node: any) => void;
  onConnect: OnConnect;
  allowTargetConnection: (id: string) => boolean;
  allowSourceConnection: (id: string) => boolean;
  addNode: (node: Node<NodeData, NodeTypes>) => void;
  addEdge: (edge: Edge) => void;
  onDrop: (
    item: { id: string; type: NodeTypes },
    monitor: DropTargetMonitor<unknown, unknown>
  ) => void;
  getNode: (id: string) => Node | undefined;
  changeNodeData: (args: {
    id: string;
    type?: NodeTypes;
    data?: NodeData;
    selected?: boolean;
  }) => void;
  setSelectedNodes: (nodes: Array<Nodes>) => void;
  deselectNodes: (id?: string) => void;
  deleteNode: (id: any) => void;
  defaultEdgeOptions: DefaultEdgeOptions;
  deleteEdge: (id: any) => void;
  onEdgeUpdate: (oldEdge: any, newConnection: any) => void;
  duplicateNode: (nodes: any) => void;
};

// This is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create<RFState>((set, get, store) => ({
  ref: null,
  setRef: (ref) => {
    set({
      ref: ref,
    });
  },
  dropPosition: null,
  setDropPosition: (position) => {
    set({
      dropPosition: position,
    });
  },
  nodes: [],
  edges: [],
  stateHistory: [],
  currentPointer: -1,
  maxPointer: -1,
  selectedNodes: [],

  // ** SET NODES ** //
  setNodes: (nodes: any) => {
    set({
      nodes: nodes,
    });
  },

  // ** SET EDGES ** //
  setEdges: (edges: any) => {
    // Set the state history
    const state = get();

    const { stateHistory, currentPointer, nodes } = state;

    const newStateHistory = [...stateHistory];
    let newCurrentPointer = currentPointer + 1;

    // Check if we need to remove the oldest history entry
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: nodes,
      edges: edges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newCurrentPointer,
      edges: edges,
    });
  },

  // ** UNDO ** //
  undo: () => {
    const state = get();
    let { stateHistory, currentPointer } = state;

    // Ensure we have a previous state to revert to
    if (currentPointer > 0) {
      currentPointer--;
      const previousState = stateHistory[currentPointer];

      set({
        nodes: previousState.nodes,
        edges: previousState.edges,
        currentPointer,
      });
    }
  },

  // ** REDO ** //
  redo: () => {
    const state = get();
    let { stateHistory, currentPointer, maxPointer } = state;

    // Ensure we have a state to redo to
    if (currentPointer < maxPointer) {
      currentPointer++;
      const newState = stateHistory[currentPointer];

      set({
        nodes: newState.nodes,
        edges: newState.edges,
        currentPointer,
      });
    }
  },

  // ** RESET UNDO REDO ** //
  resetUndoRedo: () => {
    set({
      currentPointer: -1,
      maxPointer: -1,
      stateHistory: [],
    });
  },

  // ** CAN UNDO ** //
  canUndo: () => {
    const state = get();
    let { currentPointer } = state;

    return currentPointer > 0;
  },

  // ** CAN REDO ** //
  canRedo: () => {
    const state = get();
    let { currentPointer, maxPointer } = state;

    return currentPointer < maxPointer;
  },

  // ** WAS LAST ACTION UNDO ** //
  wasLastActionUndo: () => {
    const state = get();
    let { currentPointer, maxPointer } = state;

    return currentPointer < maxPointer;
  },

  // ** ON NODES CHANGE ** //
  onNodesChange: (changes) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes) as Node<
        NodeData,
        NodeTypes
      >[],
    });
  },

  // ** ON EDGES CHANGE ** //
  onEdgesChange: (changes) => {
    // Set the state history
    const state = get();
    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];
    let newCurrentPointer = currentPointer + 1;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    // Add the new state to the history
    newStateHistory.push({
      nodes: get().nodes,
      edges: applyEdgeChanges(changes, get().edges),
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newCurrentPointer,
      edges: applyEdgeChanges(changes, get().edges),
    });
  },

  // ** ON NODE DRAG START ** //
  onNodeDragStart: (event: any, node: any) => {
    // Set the state history
    const state = get();

    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];
    let newCurrentPointer = currentPointer + 1;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: get().nodes,
      edges: get().edges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newCurrentPointer,
    });
  },

  // ** ON NODE DRAG STOP ** //
  onNodeDragStop: (event: any, node: any) => {
    // Set the state history
    const state = get();

    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];
    let newCurrentPointer = currentPointer + 1;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: get().nodes,
      edges: get().edges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newCurrentPointer,
    });
  },

  // ** ON CONNECT ** //
  onConnect: (connection) => {
    // Set the state history
    const state = get();

    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];
    let newCurrentPointer = currentPointer + 1;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: get().nodes,
      edges: addEdge({ ...connection, type: "customedge" }, get().edges),
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newCurrentPointer,
      edges: addEdge({ ...connection, type: "customedge" }, get().edges),
    });
  },

  // ** SOURCE CONNECTION LOGIC ** //
  allowSourceConnection: (id: string) => {
    const edges = get().edges;

    // check if there are edges with this node's id as source and have a target
    const isAlreadyConnected = edges.some((e) => {
      const { source, target } = e;

      if (source === id && target) {
        return true;
      }

      return false;
    });

    return !isAlreadyConnected;
  },

  // ** TARGET CONNECTION LOGIC ** //
  allowTargetConnection: (id: string) => {
    const edges = get().edges;

    const isAlreadyConnected = edges.some((e) => {
      const { source, target } = e;

      if (target === id && source) {
        return true;
      }

      return false;
    });

    return !isAlreadyConnected;
  },

  // ** ON DROP ** //
  onDrop: (item) => {
    const { id, type } = item;

    // Get drop position
    const dropPosition = get().dropPosition;

    const state = store.getState();

    const newNode = {
      id: `${id}-${Date.now()}`,
      position: dropPosition,
      data: dndAllNodes[type]?.data,
      type,
    };

    state.addNode(newNode);

    // Set the state history
    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];

    let newCurrentPointer = currentPointer + 1;
    const newMaxPointer = newCurrentPointer;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: [...get().nodes, newNode],
      edges: state.edges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newMaxPointer,
    });
  },

  // ** GET NODE ** //
  getNode: (id) => {
    return get().nodes.find((node) => node.id === id);
  },

  // ** CHANGE NODE DATA ** //
  changeNodeData: (args) => {
    const { id, data, selected } = args;

    const state = store.getState();
    const node = state.getNode(id);

    if (!node) return;

    const newNode = {
      ...node,
      selected,
      data: {
        ...node.data,
        ...data,
      },
    };

    const nodes = state.nodes.map((node) => {
      if (node.id === id) {
        return newNode;
      }

      return node;
    });

    // Set the state history
    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];

    let newCurrentPointer = currentPointer + 1;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: nodes,
      edges: state.edges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newCurrentPointer,
      nodes: nodes as Array<Node<NodeData, NodeTypes>>,
    });
  },

  // ** ADD NODE ** //
  addNode: (node) => {
    // Set the state history
    const state = get();

    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];

    let newCurrentPointer = currentPointer + 1;
    const newMaxPointer = newCurrentPointer;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: [...get().nodes, node],
      edges: state.edges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newMaxPointer,
      nodes: [...get().nodes, node],
    });
  },

  // ** ADD EDGE ** //
  addEdge: (edge) => {
    // Set the state history
    const state = get();

    const { stateHistory, currentPointer } = state;

    const newStateHistory = [...stateHistory];

    let newCurrentPointer = currentPointer + 1;
    const newMaxPointer = newCurrentPointer;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: state.nodes,
      edges: [...get().edges, edge],
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newMaxPointer,
      edges: [...get().edges, edge],
    });
  },

  // ** SET SELECTED NODES ** //
  setSelectedNodes: (nodes) => {
    // If nodes array is empty then change all edges to customedge
    if (nodes.length === 0) {
      const state = store.getState();

      const edges = state.edges.map((edge) => {
        return {
          ...edge,
          animated: true,
          type: "customedge",
        };
      });

      set({
        edges: edges,
        selectedNodes: nodes,
      });

      return;
    }

    // When a node is selected, get all its edges and change the type of edge to selectededge
    const state = store.getState();

    const selectedNodeIds = nodes.map((node) => node.id);

    const edges = state.edges.map((edge) => {
      if (
        selectedNodeIds.includes(edge.source) ||
        selectedNodeIds.includes(edge.target)
      ) {
        return {
          ...edge,
          animated: true,
          type: "selectededge",
        };
      }

      return edge;
    });

    set({
      edges: edges,
      selectedNodes: nodes,
    });
  },

  // ** DESELECT NODES ** //
  deselectNodes: (id) => {
    const state = store.getState();

    // if none selected, return
    if (state.selectedNodes.length === 0) return;

    let finalId = id;

    // if no id passed, use the first selected node's id
    if (!finalId) {
      const node = state.selectedNodes[0];
      finalId = node.id;
    }

    // deselect the node
    const nodes = state.nodes.map((node) => {
      if (node.id === finalId) {
        return {
          ...node,
          selected: false,
        };
      }

      return node;
    });

    set({
      nodes: nodes as Array<Node<NodeData, NodeTypes>>,
      selectedNodes: state.selectedNodes.filter((node) => node.id !== finalId),
    });
  },

  // ** DELETE NODE ** //
  deleteNode: (id) => {
    const state = get();
    const { nodes, edges, stateHistory, currentPointer } = state;

    // Disallow deletion of welcome node
    if (id === "1") {
      // Show error message
      message.error("Cannot delete the welcome node");
      return;
    }

    // Find the edges connected to the node to be deleted
    const connectedEdges = edges.filter(
      (edge) => edge.source === id || edge.target === id
    );

    // Filter out the deleted node and its connected edges
    const newNodes = nodes.filter((node) => node.id !== id);
    const newEdges = edges.filter((edge) => !connectedEdges.includes(edge));

    // Set the state history
    const newStateHistory = [...stateHistory];

    let newCurrentPointer = currentPointer + 1;
    const newMaxPointer = newCurrentPointer;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: newNodes,
      edges: newEdges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newMaxPointer,
      nodes: newNodes,
      edges: newEdges,
    });
  },

  // ** DEFAULT EDGE OPTIONS ** //
  defaultEdgeOptions,

  // ** DELETE EDGE ** //
  deleteEdge: (id: any) => {
    const state = get();
    const { edges, stateHistory, currentPointer } = state;

    // Filter out the deleted edge
    const newEdges = edges.filter((edge) => edge.id !== id);

    // Set the state history
    const newStateHistory = [...stateHistory];

    let newCurrentPointer = currentPointer + 1;
    const newMaxPointer = newCurrentPointer;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: state.nodes,
      edges: newEdges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newMaxPointer,
      edges: newEdges,
    });
  },

  // ** ON EDGE UPDATE ** //
  onEdgeUpdate: (oldEdge, newConnection) => {
    const state = get();
    const { edges, stateHistory, currentPointer } = state;

    const newEdges = edges.map((edge) => {
      if (edge.id === oldEdge.id) {
        return newConnection;
      }

      return edge;
    });

    // Set the state history
    const newStateHistory = [...stateHistory];

    let newCurrentPointer = currentPointer + 1;
    const newMaxPointer = newCurrentPointer;

    // Check if last action was undo
    const wasLastActionUndo = get().wasLastActionUndo();

    // If last action was undo, we need to slice the future state history
    if (wasLastActionUndo) {
      newStateHistory.splice(newCurrentPointer, newStateHistory.length);
    }

    // Add the new state to the history, ensuring we don't exceed the max history size
    if (newStateHistory.length >= MAX_HISTORY_SIZE) {
      newStateHistory.shift(); // Remove the oldest state
      // Decrement the pointers since we removed an element from the start
      newCurrentPointer--;
    }

    newStateHistory.push({
      nodes: state.nodes,
      edges: newEdges,
    });

    set({
      stateHistory: newStateHistory,
      currentPointer: newCurrentPointer,
      maxPointer: newMaxPointer,
      edges: newEdges,
    });
  },

  // ** DUPLICATE NODE ** //
  duplicateNode: (id: any) => {
    const state = get();
    const { nodes, stateHistory, currentPointer } = state;
    const nodeToDuplicate = nodes.find((el) => el.id === id);

    // Define an array of legacy (non-duplicable) node types
    const legacyNodeTypes = [
      "select-option-node",
      "two-choices-node",
      "three-choices-node",
      "user-rating-node",
      "user-range-node",
      "user-input-node",
      "quiz-node",
    ];

    // Check if the node is a legacy node
    if (nodeToDuplicate && legacyNodeTypes.includes(nodeToDuplicate.type)) {
      // Display a message or handle the case when a legacy node is attempted to be duplicated
      message.error(
        "Cannot duplicate legacy node. Please use the latest version of the node."
      );
      return; // Exit the function to prevent further execution
    }

    if (nodeToDuplicate) {
      const duplicatedNode = {
        data: nodeToDuplicate.data,
        id: `node-${Date.now()}`,
        position: {
          x: nodeToDuplicate.position.x + 100,
          y: nodeToDuplicate.position.y + 100,
        },
        type: nodeToDuplicate.type,
      };

      // Set the state history
      const newStateHistory = [...stateHistory];

      let newCurrentPointer = currentPointer + 1;
      const newMaxPointer = newCurrentPointer;

      // Check if last action was undo
      const wasLastActionUndo = get().wasLastActionUndo();

      // If last action was undo, we need to slice the future state history
      if (wasLastActionUndo) {
        newStateHistory.splice(newCurrentPointer, newStateHistory.length);
      }

      // Add the new state to the history, ensuring we don't exceed the max history size
      if (newStateHistory.length >= MAX_HISTORY_SIZE) {
        newStateHistory.shift(); // Remove the oldest state
        // Decrement the pointers since we removed an element from the start
        newCurrentPointer--;
      }

      newStateHistory.push({
        nodes: [...state.nodes, duplicatedNode],
        edges: state.edges,
      });

      set({
        stateHistory: newStateHistory,
        currentPointer: newCurrentPointer,
        maxPointer: newMaxPointer,
        nodes: [...state.nodes, duplicatedNode],
      });
    }
  },
}));

export const selector = (state: RFState) => ({
  // Set ref
  setRef: state.setRef,
  // Set drop position
  setDropPosition: state.setDropPosition,
  // Nodes and edges
  nodes: state.nodes,
  edges: state.edges,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  getNode: state.getNode,
  // Undo/redo
  undo: state.undo,
  redo: state.redo,
  resetUndoRedo: state.resetUndoRedo,
  canUndo: state.canUndo,
  canRedo: state.canRedo,
  // On change
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  changeNodeData: state.changeNodeData,
  // On drag
  onNodeDragStart: state.onNodeDragStart,
  onNodeDragStop: state.onNodeDragStop,
  // On connect
  onConnect: state.onConnect,
  // Add node
  addNode: state.addNode,
  // Add edge
  addEdge: state.addEdge,
  // On drop
  onDrop: state?.onDrop,
  defaultEdgeOptions: state.defaultEdgeOptions,
  // Connection logic
  allowTargetConnection: state.allowTargetConnection,
  allowSourceConnection: state.allowSourceConnection,
  // Selected nodes
  selectedNodes: state.selectedNodes,
  setSelectedNodes: state.setSelectedNodes,
  deselectNodes: state.deselectNodes,
  // Delete node
  deleteNode: state.deleteNode,
  // Delete edge
  deleteEdge: state.deleteEdge,
  onEdgeUpdate: state.onEdgeUpdate,
  // Duplicate node
  duplicateNode: state.duplicateNode,
});

export default useStore;
