import api from "../index";

// Check if the user is Member
export const currentMember = async () => {
  return await api.post("/currentMember", {});
};

// ********** Chatbots ********** //
// Get all member chatbots names
export const getMemberChatbotsName = async () => {
  return await api.get("/member/chatbots/name");
};

// ********** Responses ********** //
// Get member chatbot responses
export const getMemberChatbotResponses = async (
  id: any,
  page: any,
  size: any
) => {
  return await api.get(
    `/member/chatbot/${id}/responses?page=${page}&pageSize=${size}`
  );
};

// Upload chat input file
export const uploadChatInputFile = async (values: any, config: any) => {
  return await api.post(`/member/chat/upload`, values, config);
};

// Get member chat messages
export const getMemberChatResponseMessages = async (id: any) => {
  return await api.get(`/member/chat/response/${id}/messages`);
};

// ********** Status ********** //
// Update member status
export const updateMemberStatus = async (status: any) => {
  return await api.put(`/member/status`, { status });
};

// ********** Notifications ********** //
// Get member notifications
export const getMemberNotifications = async (page: any, size: any) => {
  return await api.get(`/member/notifications?page=${page}&pageSize=${size}`);
};

// Mark notification as read
export const markNotificationAsRead = async (id: any) => {
  return await api.put(`/member/notification/${id}/read`);
};

// Mark all notifications as read
export const markAllNotificationsAsRead = async () => {
  return await api.put(`/member/notifications/all-read`);
};

// ********** Changelogs ********** //
// Get member changelogs
export const getMemberChangelogs = async (page: any, size: any) => {
  return await api.get(`/member/changelogs?page=${page}&pageSize=${size}`);
};

// ********** Tickets ********** //
// Get member handled tickets
export const getMemberHandledTickets = async (page: any, size: any) => {
  return await api.get(`/member/handled/tickets?page=${page}&pageSize=${size}`);
};

// Get member unhandled tickets
export const getMemberUnHandledTickets = async (page: any, size: any) => {
  return await api.get(
    `/member/unhandled/tickets?page=${page}&pageSize=${size}`
  );
};

// Get member chat ticket by id
export const getMemberChatTicketById = async (id: any) => {
  return await api.get(`/member/chat/ticket/${id}`);
};

// ********** Team ********** //
// Get agent team members
export const getAgentTeamMembers = async () => {
  return await api.get("/member/team/members");
};

// Update the team member access
export const updateTeamMemberAccess = async (data: any) => {
  return await api.put("/member/team/access", data);
};
