import api from "../index";

// ** --------- Knowledge Base Article API --------- ** //
// Create a knowledge base article
export const createKnowledgeBaseArticle = (values: any) => {
  return api.post("/knowledge-base-article", values);
};

// Get all knowledge base articles
export const getAllKnowledgeBaseArticles = () => {
  return api.get("/knowledge-base-articles");
};

// Get all knowledge base articles paginated search
export const getAllKnowledgeBaseArticlesPaginatedSearch = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(
      `/knowledge-base-articles/paginated/search?page=${page}&perPage=${perPage}`
    );
  } else {
    return await api.get(
      `/knowledge-base-articles/paginated/search?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get a knowledge base article by ID
export const getKnowledgeBaseArticleById = (id: any) => {
  return api.get(`/knowledge-base-article/${id}`);
};

// Get a knowledge base article by chatbot ID
export const getAllKnowledgeBaseChatbotArticles = (cid: any) => {
  return api.get(`/knowledge-base-articles/by-chatbot/${cid}`);
};

// Update a knowledge base article by ID
export const updateKnowledgeBaseArticleById = (id: any, values: any) => {
  return api.put(`/knowledge-base-article/${id}`, values);
};

// Delete a knowledge base article by ID
export const deleteKnowledgeBaseArticleById = (id: any) => {
  return api.delete(`/knowledge-base-article/${id}`);
};

// Upload knowledge base article cover image
export const uploadKnowledgeBaseArticleCoverImage = async (
  values: any,
  config: any
) => {
  return await api.post(`/knowledge-base-article/cover-image`, values, config);
};

// Upload knowledge base article content image
export const uploadKnowledgeBaseArticleContentImage = async (
  values: any,
  config: any
) => {
  return await api.post(
    `/knowledge-base-article/content-image`,
    values,
    config
  );
};

// Update knowledge base article approval by ID
export const updateKnowledgeBaseArticleApprovalById = async (
  id: any,
  values: any
) => {
  return await api.put(`/knowledge-base-article/approve/${id}`, values);
};

// ** --------- Knowledge Base Article Category API --------- ** //
// Create a knowledge base article Category
export const createKnowledgeBaseArticleCategory = async (values: any) => {
  return await api.post("/knowledge-base-article-category", values);
};

// Get all knowledge base article Categories
export const getAllKnowledgeBaseArticleCategories = async () => {
  return await api.get(`/knowledge-base-article-categories`);
};

// Get knowledge base chatbot categories
export const getKnowledgeBaseChatbotCategories = (chatbotId: any) => {
  return api.get(`/knowledge-base-chatbot-categories/${chatbotId}`);
};

// Get knowledge base admin and bots
export const getKnowledgeBaseAdminBots = () => {
  return api.get(`/knowledge-base-admin-bots`);
};

// Get a knowledge base article Category by id
export const getKnowledgeBaseArticleCategoryById = async (id: any) => {
  return await api.get(`/knowledge-base-article-category/${id}`);
};

// Update a knowledge base article Category by id
export const updateKnowledgeBaseArticleCategoryById = async (
  id: any,
  values: any
) => {
  return await api.put(`/knowledge-base-article-category/${id}`, values);
};

// Delete a knowledge base article Category by id
export const deleteKnowledgeBaseArticleCategoryById = async (id: any) => {
  return await api.delete(`/knowledge-base-article-category/${id}`);
};
