import React from "react";
import { Drawer, Button, Spin, Tooltip, Tag } from "antd";
import CleanSlateSVG from "../../../../../../assets/images/pages/builder/clean-slate.svg";
import { useTranslation } from "react-i18next";

interface PublishHistoryDrawerProps {
  drawerVisible: boolean;
  toggleDrawer: () => void;
  publishedVersionHistory: any[];
  handleViewVersion: (id: string) => void;
  handleDeleteVersion: (id: string) => void;
  deleting: boolean;
}

const PublishHistoryDrawer: React.FC<PublishHistoryDrawerProps> = ({
  drawerVisible,
  toggleDrawer,
  publishedVersionHistory,
  handleViewVersion,
  handleDeleteVersion,
  deleting,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <span style={{ fontWeight: 600 }}>
              {t("flowBuilder.header.publishHistory")}
            </span>
          </div>
        }
        placement="right"
        onClose={toggleDrawer}
        open={drawerVisible}
      >
        {deleting ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 50px)",
              overflow: "hidden",
              fontSize: "20px",
            }}
          />
        ) : publishedVersionHistory && publishedVersionHistory.length > 0 ? (
          publishedVersionHistory.map((version: any, index: any) => (
            <div
              key={index}
              style={{
                borderBottom: "1px solid #f0f0f0",
                paddingBottom: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ position: "relative", marginBottom: "5px" }}>
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {new Date(version?.date).toLocaleString("en-US", {
                    weekday: "short", // "Wed"
                    day: "numeric", // "6"
                    month: "short", // "Dec"
                    hour: "2-digit", // "13"
                    minute: "2-digit", // "03"
                    hour12: true,
                  })}
                </span>

                {index === 0 && (
                  <Tag
                    color="#38a000"
                    style={{
                      position: "absolute",
                      top: "2px",
                      left: "170px",
                      background: "linear-gradient(to right, #6fdc6f, #38a000)",
                      color: "white",
                      border: "none",
                      borderRadius: "6px",
                    }}
                  >
                    Live
                  </Tag>
                )}

                <div>
                  <span>
                    - By{" "}
                    <span
                      style={{
                        fontWeight: 500,
                        color: "#1890ff",
                        fontStyle: "italic",
                      }}
                    >{`${version?.by?.name}`}</span>
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* View */}
                <Tooltip title="View">
                  <Button
                    size="small"
                    style={{
                      float: "right",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px", // Adjust padding if necessary
                      border: "none",
                    }}
                    onClick={() => handleViewVersion(version?._id)}
                  >
                    <svg
                      width="1.6em"
                      height="1.3em"
                      viewBox="0 0 795 614"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#004cff"
                        d="M397.25 278c38 0 69 31 69 69s-31 68-69 68s-68-30-68-68s30-69 68-69zm0-170c226 0 389 212 389 212c11 14 11 39 0 53c0 0-163 212-389 212s-389-212-389-212c-11-14-11-39 0-53c0 0 163-212 389-212zm0 410c94 0 171-77 171-171s-77-171-171-171s-171 77-171 171s77 171 171 171z"
                      ></path>
                    </svg>
                  </Button>
                </Tooltip>

                {/* Delete */}
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    danger
                    onClick={() => handleDeleteVersion(version?._id)}
                    disabled={deleting}
                    style={{
                      marginLeft: "15px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px", // Adjust padding if necessary
                      border: "none",
                    }}
                  >
                    <svg
                      width="1em"
                      height="1.3em"
                      viewBox="0 0 304 384"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#ff0000"
                        d="M21 341V85h256v256q0 18-12.5 30.5T235 384H64q-18 0-30.5-12.5T21 341zM299 21v43H0V21h75L96 0h107l21 21h75z"
                      ></path>
                    </svg>
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Ensures vertical stacking of the text lines
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 200px)",
              fontSize: "16px",
              color: "grey",
              textAlign: "center", // Centers the text horizontally
            }}
          >
            <img
              src={CleanSlateSVG}
              alt="No versions published"
              style={{
                width: "30%",
                objectFit: "contain",
                marginBottom: "20px", // optional, for spacing between image and text
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginTop: "20px",
              }}
            >
              It's a clean slate here! 📋 <br />
              No versions have been published yet. 🚀
            </p>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default PublishHistoryDrawer;
