import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { AiOutlineMessage } from "react-icons/ai";

const TotalMessages = () => {
  const { t } = useTranslation();
  const { analytics } = useSelector((state) => state.admin);

  // Calculate the percentage change in total messages
  const calculateTotalMessagesPercentageChange = () => {
    const { totalMessages } = analytics;

    // Assuming you have the previous total messages count available
    const previousTotalMessages = 0; // Replace with the actual previous total messages count

    if (previousTotalMessages === 0) {
      return 0;
    }

    const percentageChange =
      ((totalMessages - previousTotalMessages) / previousTotalMessages) * 100;
    return percentageChange.toFixed(2);
  };

  const totalMessagesPercentageChange =
    calculateTotalMessagesPercentageChange();
  const isTotalMessagesPositiveChange = totalMessagesPercentageChange >= 0;

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-center">
          <div className="analytics-card">
            <div className="analytics-header">
              <div>
                <AiOutlineMessage
                  style={{
                    fontSize: "24px",
                    marginRight: "5px",
                    color: "#0b4eb8",
                  }}
                />
              </div>
              <h5> {t("dashboard.messages")}</h5>
            </div>
            <div className="analytcis-middle" style={{ textAlign: "start" }}>
              <h5>{t("dashboard.totalMessages")}</h5>
              <div style={{ position: "relative" }}>
                <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {analytics?.totalMessages}
                </span>{" "}
                <FaArrowTrendUp
                  style={{
                    color: isTotalMessagesPositiveChange ? "green" : "red",
                    marginRight: "5px",
                    marginLeft: "12px",
                    fontWeight: "bold",
                    fontSize: "24px",
                    marginBottom: "-5px",
                  }}
                />
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {totalMessagesPercentageChange}%
                </span>
              </div>
            </div>
            <div className="analytics-bottom">
              <h5>{t("dashboard.last7days")}</h5>
              <div className="flex-property">
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltUp
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    />
                  </div>
                  <div>{analytics?.last7DaysMessages || 0}</div>
                </div>
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltDown
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    />
                  </div>
                  <div> 0 </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TotalMessages;
