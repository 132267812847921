import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Divider, Row, Switch, Input, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import { shallow } from "zustand/shallow";
import { NavigateNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { PiDotsNineBold } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const NavigateNodeDataEditor: FC<NavigateNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [navigatePrompt, setNavigatePrompt] = useState(
    state?.data?.navigatePrompt
  );
  const [navigateOptions, setNavigateOptions] = useState(
    state?.data?.navigateOptions || []
  );

  const [doneNavigatingText, setDoneNavigatingText] = useState(
    state?.data?.doneNavigatingText || ""
  );

  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  const updateTimeout = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }
    };
  }, []);

  // update the navigateOptions state
  useEffect(() => {
    setNavigateOptions(state?.data?.navigateOptions);
    setAnswerVariable(state?.data?.answerVariable);
  }, [state]);

  // Handle navigatePrompt change
  const handleChoicePromptChange = useCallback(
    (content: any) => {
      if (!state) return;
      setNavigatePrompt(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          navigatePrompt: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle adding a new choice
  const handleAddChoice = useCallback(() => {
    const choiceId = uuidv4();
    const newChoice = {
      id: choiceId,
      navigateText: "",
      navigateLink: "",
      openInNewTab: false, // Default to false or true as per your preference
    };

    setActive(choiceId);
    setNavigateOptions([...navigateOptions, newChoice]);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        navigateOptions: [...navigateOptions, newChoice],
      },
    });
  }, [navigateOptions, state, type, changeNodeData]);

  // Handle deleting a choice
  const handleDeleteChoice = useCallback(
    (choiceId: any) => {
      const updatedChoices = navigateOptions.filter(
        (choice: any) => choice.id !== choiceId
      );

      setNavigateOptions(updatedChoices);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          navigateOptions: updatedChoices,
        },
      });
    },
    [navigateOptions, state, type, changeNodeData]
  );
  // OnDrag Function
  const onDragEnd = (e: any) => {
    const { sourceIndex, targetIndex } = e;
    setNavigateOptions((prevQuestions: any) => {
      const updatedCards = [...prevQuestions];
      const [movedCard] = updatedCards.splice(sourceIndex, 1);
      updatedCards.splice(targetIndex, 0, movedCard);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state.data,
          navigateOptions: updatedCards,
        },
      });
      return updatedCards;
    });
  };

  // Open and Hide toggle Function
  const toggleDropdown = (id: any) => {
    setIsOpen(!isOpen);
    setActive(id);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [active]);

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t("flowBuilder.sidebar.editor.specialNodes.navigateOptionsNodeInfo")}
        </p>
      </Row>

      <div
        style={{
          height: "10px",
          width: "100%",
        }}
      />

      <Row>
        <Divider orientation="left">
          <Col
            span={24}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.specialNodes.questionText")}
          </Col>
        </Divider>

        <Col span={24} style={{ width: "100%" }}>
          <div>
            <QuillEditor
              initialContent={navigatePrompt}
              onEditorChange={handleChoicePromptChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.specialNodes.questionTextPH"
              )}
            />
          </div>
        </Col>

        <Divider orientation="left">
          <Col
            span={24}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.specialNodes.navigateOptions")}
          </Col>
        </Divider>
        <Col
          span={24}
          className="drag_multi_options_container"
          style={{ marginBottom: "15px" }}
        >
          {navigateOptions?.map((choice: any, index: any) => (
            <>
              <Col
                className={`drag_multi_options_inner ${
                  isOpen && active === choice.id ? "open" : ""
                }`}
              >
                <div
                  className="drag_dropdown_button"
                  onClick={() => toggleDropdown(choice?.id)}
                  draggable
                  onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", index.toString())
                  }
                  onDrop={(e) => {
                    e.preventDefault();
                    onDragEnd({
                      sourceIndex: Number(e.dataTransfer.getData("text/plain")),
                      targetIndex: index,
                    });
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    position: "relative",
                    border:
                      isOpen && active === choice.id
                        ? "2px solid #fff"
                        : "2px solid transparent",
                    boxShadow:
                      isOpen &&
                      active === choice.id &&
                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        left: "10px",
                      }}
                    >
                      {t("flowBuilder.sidebar.editor.specialNodes.navigate")}{" "}
                      {index + 1}
                    </div>
                  </Col>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      type="text"
                      icon={<PiDotsNineBold />}
                      size={"small"}
                      className="drag_button"
                    />
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      size={"small"}
                      style={{ borderRadius: "4px" }}
                      onClick={() => handleDeleteChoice(choice.id)}
                      className="drag_dlt_button"
                    />
                  </div>
                </div>
                {isOpen && active === choice.id && (
                  <div className="drag_isOpen_container">
                    {/* Label and Editor for Navigate Text */}
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        <Typography.Text
                          style={{
                            fontSize: "14px",
                            fontStyle: "italic",
                            fontWeight: 600,
                          }}
                        >
                          {t(
                            "flowBuilder.sidebar.editor.specialNodes.navigateText"
                          )}
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        <QuillEditor
                          initialContent={choice.navigateText}
                          onEditorChange={(content) => {
                            // Find the index of the matching choice in the navigateOptions array
                            const choiceIndex = navigateOptions.findIndex(
                              (c: any) => c.id === choice.id
                            );

                            // Copy the choice and modify the choiceText
                            const updatedChoice = {
                              ...navigateOptions[choiceIndex],
                              navigateText: content,
                            };

                            // Copy the navigateOptions array and replace the choice at the found index
                            const updatedChoices = [...navigateOptions];
                            updatedChoices[choiceIndex] = updatedChoice;

                            // Save the updated navigateOptions array back to the state
                            setNavigateOptions(updatedChoices);
                            changeNodeData({
                              ...state,
                              type,
                              data: {
                                ...state.data,
                                navigateOptions: updatedChoices,
                              },
                            });
                          }}
                          placeholderText={t(
                            "flowBuilder.sidebar.editor.specialNodes.questionTextPH"
                          )}
                        />
                      </Col>
                    </Row>

                    {/* Label and Input for Navigate Link */}
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        <Typography.Text
                          style={{
                            fontSize: "14px",
                            fontStyle: "italic",
                            fontWeight: 600,
                          }}
                        >
                          {t(
                            "flowBuilder.sidebar.editor.specialNodes.navigateLink"
                          )}
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Input
                          placeholder={t(
                            "flowBuilder.sidebar.editor.specialNodes.enterNavigateLink"
                          )}
                          value={choice.navigateLink}
                          onChange={(e) => {
                            const updatedChoices = navigateOptions.map(
                              (c: any) => {
                                if (c.id === choice.id) {
                                  return { ...c, navigateLink: e.target.value };
                                }
                                return c;
                              }
                            );
                            setNavigateOptions(updatedChoices);
                            changeNodeData({
                              ...state,
                              type,
                              data: {
                                ...state.data,
                                navigateOptions: updatedChoices,
                              },
                            });
                          }}
                        />
                      </Col>
                    </Row>

                    {/* Label and Switch for Open in New Tab */}
                    <Row gutter={[0, 8]} style={{ marginTop: "10px" }}>
                      <Col span={24}>
                        <Typography.Text
                          style={{
                            fontSize: "14px",
                            fontStyle: "italic",
                            fontWeight: 600,
                          }}
                        >
                          {t(
                            "flowBuilder.sidebar.editor.specialNodes.openInNewTab"
                          )}
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Switch
                          checkedChildren={t(
                            "flowBuilder.sidebar.editor.specialNodes.navigateNewTab"
                          )}
                          unCheckedChildren={t(
                            "flowBuilder.sidebar.editor.specialNodes.navigateSameTab"
                          )}
                          checked={choice.openInNewTab}
                          onChange={(checked: any) => {
                            const updatedChoices = navigateOptions.map(
                              (c: any) => {
                                if (c.id === choice.id) {
                                  return { ...c, openInNewTab: checked };
                                }
                                return c;
                              }
                            );
                            setNavigateOptions(updatedChoices);
                            changeNodeData({
                              ...state,
                              type,
                              data: {
                                ...state.data,
                                navigateOptions: updatedChoices,
                              },
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </>
          ))}

          <Col span={24}>
            <Button
              onClick={handleAddChoice}
              block
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("flowBuilder.sidebar.editor.flowOperationsNodes.addChoice")}
            </Button>
          </Col>
        </Col>

        {/* Add Done navigating text or button */}
        <Row>
          <Divider orientation="left">
            <Col
              span={24}
              style={{ alignSelf: "start" }}
              className="sidebar__heading"
            >
              {t("flowBuilder.sidebar.editor.specialNodes.doneNavigatingText")}
            </Col>
          </Divider>
          <Col span={24} style={{ marginTop: "8px" }}>
            <Input
              placeholder={t(
                "flowBuilder.sidebar.editor.specialNodes.doneNavigatingTextHelper"
              )}
              value={doneNavigatingText}
              onChange={(e) => setDoneNavigatingText(e.target.value)}
              onBlur={() => {
                changeNodeData({
                  ...state,
                  type,
                  data: {
                    ...state.data,
                    doneNavigatingText: doneNavigatingText,
                  },
                });
              }}
            />
          </Col>
        </Row>
      </Row>

      <SaveAnswer id={id} type={type} variableName={answerVariable} />
    </>
  );
};

export default NavigateNodeDataEditor;
