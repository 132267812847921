import React from "react";
import { Row, Col, Input, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";

const CurrentSubscription = ({ currentSubscription }) => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      <Row>
        <Col span={12}>
          <h4
            style={{
              marginBottom: "1.5rem",
            }}
          >
            Current Subscription
          </h4>

          {/* Current Plan */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Current Plan
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={currentSubscription?.currentPlan}
              />
            </Col>
          </Row>

          {/* Subscription Status */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Subscription Status
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={currentSubscription?.subscriptionStatus}
              />
            </Col>
          </Row>

          {/* Subscription Start Date */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Start Date
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={
                  currentSubscription?.startDate
                    ? new Date(
                        currentSubscription?.startDate * 1000
                      ).toUTCString()
                    : null
                }
              />
            </Col>
          </Row>

          {/* Subscription End Date */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              End Date
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={
                  currentSubscription?.endDate
                    ? new Date(
                        currentSubscription?.endDate * 1000
                      ).toUTCString()
                    : null
                }
              />
            </Col>
          </Row>

          {/* View Subscription History */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              View Subscription History
            </Col>

            <Col sm={9} span={12}>
              <Button
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                type="primary"
                onClick={() => {
                  history.push(`/super-admin/admin/${id}/subscription-history`);
                }}
              >
                View
              </Button>
            </Col>
          </Row>

          {/* Payment Status */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Payment Status
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={currentSubscription?.paymentStatus}
              />
            </Col>
          </Row>

          {/* View Payment History */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              View Payment History
            </Col>

            <Col sm={9} span={12}>
              <Button
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                type="primary"
                onClick={() => {
                  history.push(`/super-admin/admin/${id}/payment-history`);
                }}
              >
                View
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CurrentSubscription;
