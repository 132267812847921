import React, { useState, useEffect } from "react";
import { Button, message, Modal, Input, Spin, Tooltip, Space } from "antd";
import { useSelector } from "react-redux";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  generateApiToken,
  getApiTokens,
  revokeApiToken,
} from "../../api/workspace/workspace"; // Import your API methods
import { useTranslation } from "react-i18next";

const ApiKeys = () => {
  const { user } = useSelector((state: any) => state.auth);
  const [tokens, setTokens] = useState([]); // State to store tokens
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [fetchingTokens, setFetchingTokens] = useState(false); // State for fetching tokens indicator
  const { t } = useTranslation();

  useEffect(() => {
    fetchTokens();
  }, []);

  // Fetch tokens from API
  const fetchTokens = () => {
    setFetchingTokens(true);
    getApiTokens()
      .then((response) => {
        setTokens(response.data);
        setFetchingTokens(false);
      })
      .catch((error) => {
        console.error("Error fetching tokens:", error);
        message.error(t("workSpace.failedToFetchToken"));
        setFetchingTokens(false);
      });
  };

  // Handle generate key button click
  const handleGenerateToken = () => {
    // Say coming soon for everybody
    message.info("Coming soon");

    // If free plan user, say that they need to upgrade to generate an API key
    if (user.currentPlan === "Free" || user.currentPlan === "Lifetime") {
      message.info("You need to upgrade to a paid plan to generate an API key");
      return;
    }

    if (tokens.length >= 5) {
      // User already has 5 tokens, show an error message
      message.error(t("workSpace.handleGenerateToken"));
      return;
    }

    setLoading(true);

    Modal.confirm({
      title: t("workSpace.wantToGenerateNewKey"),
      okText: t("workSpace.yes"),
      okType: "danger",
      cancelText: t("workSpace.no"),
      onOk: async () => {
        try {
          generateApiToken()
            .then(() => {
              message.success(t("workSpace.tokenGenerateSuccessfully"));
              fetchTokens();

              // Reset loading state
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error generating key:", error);
              message.error(t("workSpace.failedToGenerateKey"));

              // Reset loading state
              setLoading(false);
            });
        } catch (error) {
          message.error(t("workSpace.failedToGenerateKey"));
        }
      },
      onCancel: () => {
        setLoading(false);
      },
    });
  };

  // Handle revoke key button click
  const handleRevokeToken = (tokenId: any) => {
    setLoading(true);

    Modal.confirm({
      title: t("workSpace.areYouSureToRevokeKey"),
      okText: t("workSpace.yes"),
      okType: "danger",
      cancelText: t("workSpace.no"),
      onOk: async () => {
        try {
          revokeApiToken(tokenId)
            .then(() => {
              message.success(t("workSpace.successToRevokeKey"));
              fetchTokens();

              // Reset loading state
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error revoking key:", error);
              message.error(t("workSpace.failedToRevokeKey"));

              // Reset loading state
              setLoading(false);
            });
        } catch (error) {
          message.error(t("workSpace.failedToRevokeKey"));
        }
      },
      onCancel: () => {
        setLoading(false);
      },
    });
  };

  // Copy key to clipboard
  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success(t("workSpace.ApiKeyCopyToClipboard"));
    });
  };

  // Function to mask the API key
  const maskApiKey = (key: string) => {
    const visiblePercentage = 15; // Percentage of key to be visible at both ends
    const visibleLength = Math.floor(key.length * (visiblePercentage / 100));
    const maskedLength = key.length - 2 * visibleLength;
    return `${key.substring(0, visibleLength)}${"*".repeat(
      maskedLength
    )}${key.substring(key.length - visibleLength)}`;
  };

  return (
    <>
      <p>{t("workSpace.generateApiKeyInfo")}</p>
      <Button
        type="primary"
        onClick={() => {
          message.info("Coming soon");
        }}
        disabled={loading || fetchingTokens}
        style={{ marginBottom: "20px" }}
        loading={loading}
      >
        {t("workSpace.generateApiKey")}
      </Button>

      {fetchingTokens ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            minHeight: "20rem",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          {tokens.map((key) => (
            <Space
              key={key._id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Input
                value={maskApiKey(key.key)}
                readOnly
                style={{
                  width: "500px",
                  maxWidth: "500px",
                }}
                addonAfter={
                  <Tooltip title={t("workSpace.copyApiKey")}>
                    <CopyOutlined
                      onClick={() => copyToClipboard(key.key)}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                }
              />
              <Tooltip title={t("workSpace.revokeToken")}>
                <DeleteOutlined
                  onClick={() => handleRevokeToken(key._id)}
                  style={{
                    fontSize: "16px",
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Space>
          ))}
        </div>
      )}
    </>
  );
};

export default ApiKeys;
