import NodeTypeRenderer from "./nodeTypeRenderer";

const SidebarSearchNodeOptions: any = ({ searchedNodes }: any) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "15px 12px",
      }}
    >
      {searchedNodes.map((node: any) => (
        <NodeTypeRenderer key={node.id} {...node} />
      ))}
    </div>
  );
};
export default SidebarSearchNodeOptions;
