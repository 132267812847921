// Global Actions
export const AUTH_TYPES = {
  AUTH: "AUTH",
  LOGOUT: "LOGOUT",
  // Validate User
  IS_USER: "IS_USER",
  IS_ADMIN: "IS_ADMIN",
  IS_MEMBER: "IS_MEMBER",
  IS_SUPER_ADMIN: "IS_SUPER_ADMIN",
  IS_MARKETING_ADMIN: "IS_MARKETING_ADMIN",
};
