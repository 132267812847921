import { useState, FC, useCallback } from "react";
import { Row, Col, Upload, message, Button, Divider } from "antd";
import { useParams } from "react-router";
import { shallow } from "zustand/shallow";
import { RiLoaderLine, RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { FileNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { uploadChatbotMedia } from "../../../../../../../../../api/chatbot/chatbot";
// translation
import { useTranslation } from "react-i18next";

const FileNodeDataEditor: FC<FileNode> = ({ type, id }) => {
  const { bid } = useParams<{ bid: string }>();
  const { getNode, changeNodeData } = useStore(selector, shallow);
  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);

  const { t } = useTranslation();

  const [fileName, setFileName] = useState(state?.data?.file);
  const [loading, setLoading] = useState(false);

  // Updated beforeUpload function to support file types
  const beforeUpload = (file: any) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
    ];

    if (!allowedTypes.includes(file.type)) {
      message.error(
        "Unsupported file type! Please upload a PDF or Word document."
      );
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("File must be smaller than 5MB!");
    }

    return allowedTypes.includes(file.type) && isLt5M;
  };

  // Updated handleUploadFile function for file upload
  const handleUploadFile = async (options: any) => {
    const { onError, file } = options;
    const formData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", file);

    try {
      setLoading(true);
      await uploadChatbotMedia(bid, formData, config)
        .then((res: any) => {
          changeNodeData({
            ...state,
            type,
            data: { ...state.data, file: res.data.url },
          });

          setFileName(res.data.url);

          setLoading(false);
          message.success("File uploaded successfully");
        })
        .catch((err: any) => {
          onError(err);
          setLoading(false);
          message.error("Error uploading file");
        });
    } catch (error) {
      onError(error);
    }
  };

  const removeFile = useCallback(
    (event: any) => {
      event.stopPropagation(); // Prevents triggering the upload dialog
      setFileName("");
      changeNodeData({
        ...state,
        type,
        data: { ...state.data, file: "" },
      });
    },
    [state, type, changeNodeData]
  );

  const uploadButton = (
    <Upload
      name="welcome-file"
      listType="picture-card"
      customRequest={handleUploadFile}
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      multiple={false}
      accept=".pdf, .doc, .docx"
    >
      <div style={{ width: "100%", borderRadius: "15px" }}>
        {loading ? (
          <RiLoaderLine
            className="remix-icon-loading remix-icon-spin remix-icon"
            size={24}
          />
        ) : (
          <RiAddLine size={24} />
        )}
        <div style={{ marginTop: 8 }}>
          {t("flowBuilder.sidebar.editor.sendResponseNodes.upload")}
        </div>
      </div>
    </Upload>
  );

  return (
    <>
      {/* Node Image */}
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.sendResponseNodes.fileNodeInfo")}
          </p>
        </Row>

        <Divider orientation="left">
          <Col span={8} className="sidebar__heading">
            <strong>
              {t("flowBuilder.sidebar.editor.sendResponseNodes.file")}
            </strong>
          </Col>
        </Divider>
        <Col span={14}>
          {fileName ? (
            <Row style={{ marginTop: "10px" }}>
              <Col span={24}>
                <a href={fileName} target="_blank" rel="noopener noreferrer">
                  {fileName.split("/").pop()}
                </a>
                <Button
                  type="link"
                  icon={<RiDeleteBin6Line />}
                  onClick={removeFile}
                  style={{ marginLeft: "10px" }}
                >
                  {t("flowBuilder.sidebar.editor.sendResponseNodes.remove")}
                </Button>
              </Col>
            </Row>
          ) : (
            uploadButton
          )}
        </Col>
      </Row>
    </>
  );
};

export default FileNodeDataEditor;
