import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Spin, Select, DatePicker } from "antd";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { getAllPlans } from "@api/subscription/plan";
import { getAdminUsageById, updateUsageOfAdmin } from "@api/user/superAdmin";
import { SuccessNotification, ErrorNotification } from "../..";
import moment from "moment";

const initialState = {
  plan: "",
  startDate: "",
  endDate: "",
  chatsAllotted: 0,
  billInterval: "",
};

const UsageUpdateModal = ({
  open,
  handleModalClick,
  handleFetchUsageHistory,
  usageId,
  adminId,
}) => {
  const { id } = useParams();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (open) {
      fetchPlans();
      handleFetchUsage();
    }
  }, []);

  // Handle fet usage and plans
  const handleFetchUsage = async () => {
    setLoading(true);
    const { data } = await getAdminUsageById(id, usageId);

    // Set values
    setValues({
      ...values,
      plan: data.subscriptionPlan,
      startDate: moment(data.subscriptionStartDate.split("T")[0], "YYYY/MM/DD"),
      endDate: moment(data.subscriptionEndDate.split("T")[0], "YYYY/MM/DD"),
      chatsAllotted: data.chatsAllotted,
      billInterval: data?.billInterval || "month",
    });
    setLoading(false);
  };

  // Fetch all plans
  const fetchPlans = async () => {
    setLoading(true);
    try {
      const { data } = await getAllPlans();
      // Assuming each plan has an _id and a name, match that structure for "Lifetime"
      const lifetimePlan = { _id: "lifetime", name: "Lifetime" };
      // Add "Lifetime" option to the beginning or end of the plans array
      // This example adds it to the end
      setPlans([...data, lifetimePlan]);
    } catch (error) {
      console.error("Error fetching plans:", error);
      // Optionally handle the error (e.g., show an error notification)
    }
    setLoading(false);
  };

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle usage update
  const handleUsageUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validate form
    if (
      !values.plan ||
      !values.startDate ||
      !values.endDate ||
      !values.chatsAllotted ||
      !values.billInterval
    ) {
      setLoading(false);
      return ErrorNotification("Please enter all fields");
    }

    // Update usage
    await updateUsageOfAdmin(adminId, usageId, values)
      .then(() => {
        SuccessNotification("Usage updated successfully");
        handleFetchUsageHistory();
        handleModalClick();
      })
      .catch((err) => {
        ErrorNotification("Error updating usage");
      });
  };

  return (
    <>
      <Modal
        title="Update Usage"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleUsageUpdate}
            loading={loading}
          >
            Update
          </Button>,
        ]}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(80vh - 50px)",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Form
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            {/* Plan */}
            <Form.Item label="Plan">
              <Select
                name="plan"
                placeholder="Select plan"
                value={values.plan}
                onChange={(value) => {
                  setValues({
                    ...values,
                    plan: value,
                  });
                }}
              >
                {plans.map((plan) => (
                  <Select.Option key={plan._id} value={plan.name}>
                    {plan.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* Start date */}
            {!loading && (
              <Form.Item label="Start Date">
                <DatePicker
                  className="hp-mb-16 hp-mr-16"
                  onChange={(date, dateString) =>
                    setValues({ ...values, startDate: dateString })
                  }
                  defaultValue={values.startDate}
                  popupStyle={{
                    height: "auto",
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{
                    width: "100%",
                  }}
                  popupAlign={{
                    offset: [0, 0],
                  }}
                />
              </Form.Item>
            )}

            {/* End date */}
            {!loading && (
              <Form.Item label="End Date">
                <DatePicker
                  className="hp-mb-16 hp-mr-16"
                  onChange={(date, dateString) =>
                    setValues({ ...values, endDate: dateString })
                  }
                  defaultValue={values.endDate}
                  popupStyle={{
                    height: "auto",
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{
                    width: "100%",
                  }}
                  popupAlign={{
                    offset: [0, 0],
                  }}
                />
              </Form.Item>
            )}

            {/* Chats Allotted */}
            <Form.Item label="Chats Allotted">
              <Input
                name="chatsAllotted"
                type="number"
                value={values.chatsAllotted}
                onChange={handleFormChange}
                placeholder="Chats Allotted"
              />
            </Form.Item>

            {/* Bill Interval */}
            <Form.Item label="Bill Interval">
              <Select
                name="billInterval"
                placeholder="Select bill interval"
                value={values.billInterval}
                onChange={(value) => {
                  setValues({
                    ...values,
                    billInterval: value,
                  });
                }}
              >
                {["month", "year"].map((interval, index) => (
                  <Select.Option key={index} value={interval}>
                    {interval}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default withRouter(UsageUpdateModal);
