import { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionInfo } from "@components";
import { getAdminSubscriptionAction } from "@redux/actions/adminActions";

const SubscriptionPlans = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Run only for admin
    if (user.role === "admin") {
      fetchAdminPlan();
    }
  }, []);

  // Fetch all admin subscription
  const fetchAdminPlan = async () => {
    setLoading(true);

    // Dispatch the get admin subs action
    dispatch(getAdminSubscriptionAction(setLoading));
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 10px)",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Row
          style={{
            paddingLeft: "50px",
            paddingRight: "40px",
            paddingBottom: "50px",
          }}
        >
          <Col
            span={24}
            style={{
              overflowX: "hidden",
            }}
          >
            <SubscriptionInfo />
          </Col>
        </Row>
      )}
    </>
  );
};

export default SubscriptionPlans;
