import { FC, useEffect, useState } from "react";
import { Row, Col, Input, Tag } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { RangeNodeData } from "../../typings";
import useStore from "../../../../store/store";
import ContextMenu from "../../../components/contextMenu/ContextMenu";
import { Range } from "@assets/images/pages/builder";

const RangeNode: FC<NodeProps<RangeNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);

  const { getSourceConnectionAllowed, getNode } = useStore((state: any) => ({
    getSourceConnectionAllowed: state?.allowSourceConnection,
    getNode: state?.getNode,
  }));

  const node = getNode(id);

  useEffect(() => {
    const getNodeId = node?.id;
    setNodeId(getNodeId);
  }, []);

  if (!node) {
    return null; // or some fallback component
  }

  const allowSourceConnection = getSourceConnectionAllowed(id);

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />
      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "250px",
          maxWidth: "250px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={24}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Tag
              style={{
                position: "absolute",
                top: "-15px",
                left: "50%",
                transform: "translateX(-50%)",
                background: "#f5222d",
                color: "white",
              }}
            >
              Legacy (Use "Range Option")
            </Tag>

            <Col
              span={18}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={Range}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="Range"
              />
              Range Node
            </Col>

            {/* Delete & Duplicate Node */}
            <Col span={6}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{ __html: node?.data?.rangePrompt }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
              }}
            />
          </Col>

          <Col span={24} className="hp-text-center">
            <Input
              size="small"
              value={node?.data?.type}
              disabled
              style={{
                color: "black",
                cursor: "pointer",
                marginTop: "10px",
                background: "#fff",
                borderRadius: "3px",
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "16px",
              marginBottom: "3px",
            }}
          >
            Range
          </Col>

          <Row style={{ padding: "0px 12px" }}>
            <Col span={11}>
              <Input
                size="small"
                type={
                  node?.data?.type === "number"
                    ? "number"
                    : node?.data?.type === "date"
                    ? "date"
                    : "text"
                }
                value={node?.data?.minVal}
                disabled
                style={{
                  color: "black",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  borderRadius: "3px",
                }}
              />
            </Col>

            <Col
              span={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "16px",
                marginBottom: "4px",
              }}
            >
              -
            </Col>

            <Col span={11}>
              <Input
                size="small"
                type={
                  node?.data?.type === "number"
                    ? "number"
                    : node?.data?.type === "date"
                    ? "date"
                    : "text"
                }
                value={node?.data?.maxVal}
                disabled
                style={{
                  color: "black",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  borderRadius: "3px",
                }}
              />
            </Col>
          </Row>

          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Condition
          </Col>

          <Col span={24}>
            <Input
              size="small"
              value="If less than [&lt;]"
              disabled
              style={{
                color: "black",
                cursor: "pointer",
                marginTop: "3px",
                background: "#fff",
                fontSize: "16px",
                textAlign: "center",
                borderRadius: "3px",
              }}
            />
          </Col>

          <Col span={24}>
            <Input
              size="small"
              value="If in range [=]"
              disabled
              style={{
                color: "black",
                cursor: "pointer",
                marginTop: "3px",
                background: "#fff",
                fontSize: "16px",
                textAlign: "center",
                borderRadius: "3px",
              }}
            />
          </Col>

          <Col span={24}>
            <Input
              size="small"
              value="If greater than [&gt;]"
              disabled
              style={{
                color: "black",
                cursor: "pointer",
                marginTop: "3px",
                background: "#fff",
                fontSize: "16px",
                textAlign: "center",
                borderRadius: "3px",
              }}
            />
          </Col>
        </div>
      </Col>
      {Object?.keys(node?.ports).map((handleId, index) => (
        <Handle
          key={index}
          type="source"
          isConnectable={allowSourceConnection}
          isConnectableStart={allowSourceConnection}
          position={Position.Right}
          id={handleId}
          style={{
            top: `${index === 0 ? "70%" : index === 1 ? "81%" : "92%"}`,
          }}
          className="node-source"
        />
      ))}
    </>
  );
};

export default RangeNode;
