import { useState, useEffect } from "react";
import { Divider, Row, Col, Switch } from "antd";
import PricingItem from "./item";
import { getAllPlans } from "@api/subscription/plan";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Pricing = () => {
  const { currentSubscription } = useSelector((state) => state.admin) ?? {};
  const [plans, setPlans] = useState([]);
  const [billCycle, setBillCycle] = useState(true);
  const billedText = `Billed ${billCycle ? "annually" : "monthly"}`;

  const { t } = useTranslation();

  // Get all plans
  useEffect(() => {
    getAllPlans().then((res) => {
      setPlans(res.data);
    });
  }, []);

  // Toggle billing cycle
  const onChange = (checked) => {
    if (checked) {
      setBillCycle(true);
    } else {
      setBillCycle(false);
    }
  };

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Row>
          {/* Header  */}
          <Col span={24}>
            <h2>{t("profile.plans.subscriptionDetails")}</h2>
            <p className="hp-p1-body hp-mb-0">
              {t("profile.plans.subscriptionDetailsInfo")}
            </p>
          </Col>

          <Divider className="hp-border-color-black-40 hp-border-color-dark-80" />

          {/* Plans */}
          <Col span={24}>
            <Row align="middle" justify="center" className="hp-mt-32 hp-mb-24">
              <span
                className={`hp-caption ${
                  billCycle
                    ? "hp-text-color-black-60"
                    : "hp-text-color-primary-1"
                }`}
              >
                {t("profile.plans.billedMonthly")}
              </span>

              <Switch className="hp-mx-8" defaultChecked onChange={onChange} />

              <span
                className={`hp-caption ${
                  billCycle
                    ? "hp-text-color-primary-1"
                    : "hp-text-color-black-60"
                }`}
              >
                {t("profile.plans.billedAnnually")}
              </span>
            </Row>

            <Row align="middle" justify="center" className="hp-mt-32 hp-mb-24">
              <h5 className="hp-text-color-primary-1">
                {t("profile.plans.save30%")}
              </h5>
            </Row>

            {/* Pricing Item Component */}
            <PricingItem
              plans={plans}
              billCycle={billCycle}
              billedText={billedText}
              currentSubscription={currentSubscription}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Pricing;
