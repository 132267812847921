import React  from "react";
import {
  Modal,
  Spin,
  Button,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useSelector } from "react-redux";

// Create the interface for the props
interface AgentChatInviteModalProps {
  modalVisible: boolean;
  handleCloseModal: () => void;
  loading: boolean;
  // Invite stuff
  isInviting: boolean;
  invitingAgentId: string;
  handleInviteToChatHandler: any;
}

const AgentChatInviteModal: React.FC<AgentChatInviteModalProps> = ({
  modalVisible,
  handleCloseModal,
  loading,
  // Invite stuff
  isInviting,
  invitingAgentId,
  handleInviteToChatHandler,
}) => {
  const { user } = useSelector((state: any) => state?.auth ?? {});
  const { teamList } = useSelector((state: any) => state.member);

  // Define the columns for the Table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: string) => {
        const color = role === "admin" ? "geekblue" : "green";
        return <Tag color={color}>{role.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "isOnline",
      key: "isOnline",
      render: (_: any, record: any) =>
        record.isEngaged ? (
          <span style={{ color: "red" }}>● Engaged</span>
        ) : record.isOnline ? (
          <span style={{ color: "green" }}>● Online</span>
        ) : (
          <span style={{ color: "grey" }}>● Offline</span>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        const isLoading = isInviting && invitingAgentId === record._id;
        return record._id === user._id ? (
          <Tag>Me</Tag>
        ) : record.isOnline ? (
          <Button
            type="primary"
            loading={isLoading}
            disabled={record.isEngaged}
            size="small"
            onClick={() =>
              handleInviteToChatHandler(record._id, record.isOnline)
            }
          >
            Invite to Chat
          </Button>
        ) : (
          <Tooltip title="Coming soon">
            <Button
              type="primary"
              loading={isLoading}
              disabled
              size="small"
              onClick={() =>
                handleInviteToChatHandler(record._id, record.isOnline)
              }
            >
              Send email
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Team Members"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={650}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80%",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div className="custom-table-wrapper">
            <Table
              dataSource={teamList}
              columns={columns}
              pagination={false}
              rowKey="id"
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default AgentChatInviteModal;
