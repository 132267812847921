import { Row, Col } from "antd";
import {
  WelcomeCard,
  UpgradePlanCard,
  VisitorsCard,
  TotalLeads,
  TotalChats,
  TotalMessages,
  VideoTutorials,
  CommunityCard,
  HelpCenter,
  LineChart,
} from "./components";
import { useSelector } from "react-redux";
// skeleton
import {
  AnalyticsCardSkeleton,
  SupportCardSkeleton,
  UpgradeCardSkeleton,
  WelcomeCardSkeleton,
  LiveChartSkeleton,
} from "../Loaders/skeleton/Analytics";
import "../../assets/css/AnalyticsCard.css";

const DashboardAnalytics = () => {
  const { user } = useSelector((state) => state.auth);
  const { analytics } = useSelector((state) => state.admin);

  return (
    <>
      {!analytics ? (
        <>
          <Row
            gutter={[32, 0]}
            style={{
              marginLeft: "0.1rem",
              marginBottom: "3rem",
              marginRight: "0.1rem",
            }}
          >
            {/* First Column for Skeletons */}
            <Col xs={24} lg={18}>
              <Row
                gutter={[32, 32]}
                style={{
                  marginBottom: "2rem",
                }}
              >
                <Col span={24}>
                  <WelcomeCardSkeleton />
                </Col>

                <Col span={24}>
                  <LiveChartSkeleton />
                </Col>

                {Array.from({ length: 4 }, (_, index) => (
                  <Col xs={24} sm={12} md={12} key={index}>
                    <AnalyticsCardSkeleton />
                  </Col>
                ))}
              </Row>

              <Row gutter={[32, 32]}>
                {Array.from({ length: 3 }, (_, index) => (
                  <Col xs={24} sm={12} md={8} key={index}>
                    <SupportCardSkeleton />
                  </Col>
                ))}
              </Row>
            </Col>

            {/* Second Column for Upgrade Card Skeleton */}
            {user?.role === "admin" && (
              <Col xs={24} lg={6}>
                <UpgradeCardSkeleton />
              </Col>
            )}
          </Row>
        </>
      ) : (
        <>
          <Row
            gutter={[32, 0]}
            style={{
              marginLeft: "0.1rem",
              marginBottom: "1rem",
              marginRight: "0.1rem",
            }}
          >
            {/* First Column */}
            <Col xs={24} lg={18}>
              <Row gutter={[32, 32]}>
                <Col span={24}>
                  <WelcomeCard />
                </Col>

                <Col span={24}>
                  <LineChart />
                </Col>

                <Col xs={24} sm={12} md={12}>
                  <VisitorsCard />
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <TotalLeads />
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <TotalChats />
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <TotalMessages />
                </Col>
              </Row>

              <Row gutter={[32, 32]}>
                <Col xs={24} sm={12} md={8}>
                  <VideoTutorials />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <CommunityCard />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <HelpCenter />
                </Col>
              </Row>
            </Col>

            {/* Second Column for Upgrade Plan Card */}
            {user?.role === "admin" && (
              <Col xs={24} lg={6}>
                <UpgradePlanCard />
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default DashboardAnalytics;
