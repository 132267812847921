import {
  // Find and display node
  _findAndDisplayNodeWithPort,
  // Current steps and index
  _getCurrentStepsAndIndex,
  // Variables
  _handleAddToTranscript,
  _pushDataToRecord,
  _setAnswerVariable,
} from "../../ChatbotFlowPreview";

// Handle choices selection and find the next node
export const _handleChoiceSelection = (choice: any, id: any, type: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  const selectedChoice =
    steps[currentIndex].type === "n-choices-node"
      ? steps[currentIndex].data.choices.find((c: any) => c.id === id)
          .choiceText
      : steps[currentIndex].data[choice];

  // Add the user selection to the transcript
  _handleAddToTranscript(
    "user",
    selectedChoice?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-choice",
    choices: steps[currentIndex].data,
    selectedChoice: selectedChoice?.replace(/<\/?[^>]+(>|$)/g, ""),
  });

  // Set the answer variable (send node id and value)
  _setAnswerVariable(
    steps[currentIndex].id,
    selectedChoice?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  let sourceKey;

  // Get the choice buttons & toggle disabled to true
  if (type === "2choiceNode") {
    const choice1Element = document.getElementById(
      "2choiceText1_" + currentIndex
    ) as any;
    choice1Element.disabled = true;

    // Select and disable second option only if it is not disabled
    if (!steps[currentIndex].data.disableSecondChoice) {
      const choice2Element = document.getElementById(
        "2choiceText2_" + currentIndex
      ) as any;
      choice2Element.disabled = true;
    }

    // Get the source handle key
    sourceKey = `source-${id + 1}`;
  } else if (type === "3choiceNode") {
    const choice1Element = document.getElementById(
      "3choiceText1_" + currentIndex
    ) as any;
    const choice2Element = document.getElementById(
      "3choiceText2_" + currentIndex
    ) as any;
    const choice3Element = document.getElementById(
      "3choiceText3_" + currentIndex
    ) as any;
    choice1Element.disabled = true;
    choice2Element.disabled = true;
    choice3Element.disabled = true;

    // Get the source handle key
    sourceKey = `source-${id + 1}`;
  } else if (type === "nChoiceNode") {
    // Filter out empty choices
    const filteredChoices = steps[currentIndex].data.choices.filter(
      (choice: { choiceText: any }) => choice.choiceText.trim() !== ""
    );

    // Disable all the choice buttons based on the number of choices
    for (let i = 0; i < filteredChoices.length; i++) {
      const choiceElement = document.getElementById(
        "nChoiceText_" + i + "_" + currentIndex
      ) as any;

      choiceElement.disabled = true;
    }

    // Get the source handle key
    sourceKey = `source-${id}`;
  }

  // Call the _findAndDisplayNodeWithPort function
  _findAndDisplayNodeWithPort(sourceKey);
};

// Handle image option selection
export const _handleImageChoiceSelection = (imageId: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Filter out images with empty URLs or labels
  const filteredImages = steps[currentIndex].data.images.filter(
    (image: any) => image.image?.trim() !== "" && image.label?.trim() !== ""
  );

  const selectedImage = filteredImages.find(
    (image: any) => image.id === imageId
  );

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-image-choice",
    images: steps[currentIndex].data,
    selectedImageLabel: selectedImage.label,
    selectedImage: selectedImage.image,
  });

  // Add the user input to the transcript
  _handleAddToTranscript(
    "user",
    selectedImage?.label?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Set the answer variable (send node id and value)
  _setAnswerVariable(
    steps[currentIndex].id,
    selectedImage?.label?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Get the image buttons & toggle disabled to true
  filteredImages.forEach((image: any) => {
    const imageElement = document.getElementById(
      `image_${image.id}_${currentIndex}`
    ) as any;

    // Disable the image after it's clicked
    imageElement.style.pointerEvents = "none";
  });

  // Get the source handle key
  const sourceKey = `source-${imageId}`;

  // Call the _findAndDisplayNodeWithPort function
  _findAndDisplayNodeWithPort(sourceKey);
};

// Handle _handleRatingChoiceSelection
export const _handleRatingChoiceSelection = (
  port: any,
  selectedRating: any
) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  const ratingType = steps[currentIndex].data.ratingType;

  const numRatings = ratingType === "smiley" ? 5 : Number(ratingType);

  // Push the user selection to the record array
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-rating-choice",
    options: steps[currentIndex].data,
    selectedRatingChoice: selectedRating,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", selectedRating);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, selectedRating);

  for (let i = 1; i <= numRatings; i++) {
    const ratingButton = document.getElementById(
      `rating_${i}_${currentIndex}`
    ) as any;

    // Disable the image after it's clicked
    ratingButton.style.pointerEvents = "none";
  }

  // Get the source handle key (port - 1, because in nodes handle, the id starts from 0)
  const sourceKey = `source-${port - 1}`;

  // Call the _findAndDisplayNodeWithPort function
  _findAndDisplayNodeWithPort(sourceKey);
};

// Handle yes or no choice selection and find the next node
export const _handleYesOrNoChoiceSelection = (optionId: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  const selectedChoice = steps[currentIndex].data.options.find(
    (option: any) => option.id === optionId
  ).label;

  // Add the user input to the transcript
  _handleAddToTranscript(
    "user",
    selectedChoice?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-yes-or-no-choice",
    options: steps[currentIndex].data,
    selectedYesOrNoChoice: selectedChoice.replace(/<\/?[^>]+(>|$)/g, ""),
  });

  // Set the answer variable (send node id and value)
  _setAnswerVariable(
    steps[currentIndex].id,
    selectedChoice?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Get the option buttons & toggle disabled to true
  for (let i = 0; i < steps[currentIndex].data.options.length; i++) {
    const yesNoOption = document.getElementById(
      "yesNoOption_" + i + "_" + currentIndex
    ) as any;

    yesNoOption.disabled = true;
  }

  // Get the source handle key
  const sourceKey = `source-${optionId}`;

  // Call the _findAndDisplayNodeWithPort function
  _findAndDisplayNodeWithPort(sourceKey);
};

// Handle _handleOpinionSelection
export const _handleOpinionSelection = (port: any, selectedOpinion: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-opinion-scale-choice",
    options: steps[currentIndex].data,
    selectedOpinionScaleChoice: selectedOpinion,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", selectedOpinion);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, selectedOpinion);

  const { from, to } = steps[currentIndex].data;

  for (let i = +from; i <= +to; i++) {
    const opinionButton = document.getElementById(
      `opinionScaleChoice_${i - +from}_${currentIndex}`
    ) as any;

    // Disable the button after it's clicked
    opinionButton.style.pointerEvents = "none";
  }

  // Get the source handle key
  const sourceKey = `source-${port}`;

  // Call the _findAndDisplayNodeWithPort function
  _findAndDisplayNodeWithPort(sourceKey);
};
