import EnglishIcon from "../assets/images/pages/language/english-icon.png";
import HindiIcon from "../assets/images/pages/language/hindi-icon.png";
import FrenchIcon from "../assets/images/pages/language/france-icon.png";
import ItalianIcon from "../assets/images/pages/language/italian-icon.png";
import JapniesIcon from "../assets/images/pages/language/japanies-icon.png";
import GermanIcon from "../assets/images/pages/language/german-icon.png";
export const languages = [
  {
    id: 0,
    title: "languageContent.english",
    code: "en",
    img: EnglishIcon,
  },
  {
    id: 1,
    title: "languageContent.hindi",
    code: "hi",
    img: HindiIcon,
  },
  {
    id: 2,
    title: "languageContent.french",
    code: "fr",
    img: FrenchIcon,
  },
  {
    id: 3,
    title: "languageContent.italian",
    code: "it",
    img: ItalianIcon,
  },
  {
    id: 4,
    title: "languageContent.japanese",
    code: "jp",
    img: JapniesIcon,
  },
  {
    id: 5,
    title: "languageContent.german",
    code: "de",
    img: GermanIcon,
  },
];
