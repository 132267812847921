import React, { useState, useEffect, useRef } from "react";
import { Avatar, Badge, Button, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { markNotificationAsRead } from "../../api/user/member";
import {
  getMemberNotificationsAction,
  markAllNotificationsAsReadAction,
} from "../../redux/actions/memberActions";
import "../../assets/css/Notifications.css";
import NotificationIcon from "../../assets/images/pages/profile/no-notification.png";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { notificationsData } = useSelector((state: any) => state.member);

  const { t } = useTranslation();

  // Notifications Pagination
  const [notificationsPage, setNotificationsPage] = useState(1);
  const [notificationsPageSize, setNotificationsPageSize] = useState(5);

  // ** LOAD MORE REFS ** //
  // Notifications Load More
  const loadMoreNotificationsRef = useRef(null);
  const [_, setShowLoadMoreNotifications] = useState(false);

  // Loading state
  const [loading, setLoading] = useState(true);
  const [loadMoreNotificationsLoading, setLoadMoreNotificationsLoading] =
    useState(false);

  // Get the latest 10 notifications
  useEffect(() => {
    setLoading(true);

    dispatch(
      getMemberNotificationsAction(
        notificationsPage,
        notificationsPageSize,
        setLoading
      )
    );
  }, []);

  // *** Load More *** //
  // Handle load more notifications
  const handleLoadMoreNotifications = () => {
    setLoadMoreNotificationsLoading(true);

    // Increase setNotificationsPage number
    setNotificationsPage(notificationsPage + 1);

    // Set per page to 5
    setNotificationsPageSize(5);

    // Dispatch the action to get the handled tickets
    dispatch(
      getMemberNotificationsAction(
        notificationsPage + 1,
        notificationsPageSize,
        setLoadMoreNotificationsLoading
      )
    );
  };

  // ** Notifications Load More ** //
  // If the last element is in view, show the Load More button.
  const chatsObserverCallback = (entries: any, _: any) => {
    const first = entries[0];
    if (first.isIntersecting) {
      setShowLoadMoreNotifications(true);
    } else {
      setShowLoadMoreNotifications(false);
    }
  };

  // Add IntersectionObserver to the last element in the chats list
  useEffect(() => {
    const options = { threshold: 0.1 };
    const observer = new IntersectionObserver(chatsObserverCallback, options);

    if (loadMoreNotificationsRef.current) {
      observer.observe(loadMoreNotificationsRef.current);
    }

    return () => observer.disconnect();
  }, [notificationsData]);

  // ** Notification Click ** //
  const handleNotificationClick = async (item: any) => {
    const basePath = user.role === "admin" ? "/a/ws" : "/m/ws";

    const currentPath = window.location.pathname;
    const isOnChatsPage = currentPath.includes(
      `${basePath}/${item.metaData.workspaceId}/chats`
    );

    // Parse item.createdAt and maxWaitTime, then check if current time is beyond max wait time
    const createdAt = new Date(item.createdAt);
    const maxWaitTimeInMilliseconds =
      item.metaData.handoverData.maxWaitTime * 60000; // Convert minutes to milliseconds
    const currentTime = new Date();
    const expiryTime = new Date(
      createdAt.getTime() + maxWaitTimeInMilliseconds
    );

    // If current time is beyond item.createdAt + maxWaitTime, redirect immediately
    if (currentTime > expiryTime) {
      window.location.href = item?.link;
      return; // Exit the function to prevent further execution
    }

    // If item is not read
    if (!item.read) {
      // Mark item as read
      // Make an API call to mark the item as read
      await markNotificationAsRead(item._id);

      // If not on the chats page, save the notification data to the session storage and redirect to the chats page
      if (!isOnChatsPage) {
        sessionStorage.setItem(
          "notificationData",
          JSON.stringify(item.metaData)
        );

        // Redirect to the chats page if not already there
        window.location.href = item?.link;
      } else {
        // If already on the chats page, handle the notification data appropriately
        window.dispatchEvent(
          new CustomEvent("handleNotification", { detail: item.metaData })
        );
      }
    } else {
      // If item is read, redirect to the link
      window.location.href = item?.link;
    }
  };

  // Get initials
  const getInitials = (name: any) => {
    return name
      .split(" ")
      .map((word: any) => word[0])
      .join("")
      .toUpperCase();
  };

  // Format date to now
  const formatToNow = (date: any) => {
    const now: any = new Date();
    const givenDate: any = new Date(date);
    const seconds = Math.round((now - givenDate) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const weeks = Math.round(days / 7);
    const months = Math.round(days / 30);
    const years = Math.round(days / 365);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (weeks < 5) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    }
  };

  // Mark all notifications as read
  const handleMarkAllAsRead = async () => {
    dispatch(markAllNotificationsAsReadAction());
  };

  return (
    <>
      <div>
        <div className="notifications-details-main">
          <div className="notifications-details-heading">
            <h2>{t("workSpace.myNotifications")}</h2>
            {notificationsData?.notifications?.length > 0 && (
              <h4 onClick={() => handleMarkAllAsRead()}>
                {t("workSpace.markAllRead")}
              </h4>
            )}
          </div>
          {loading ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(60vh - 50px)",
                overflow: "hidden",
                fontSize: "20px",
              }}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                borderRadius: "10px",
                overflow: "hidden",
                padding: "15px",
                paddingRight: "10px",
              }}
            >
              <div className="notifications-details">
                {notificationsData?.notifications &&
                !loading &&
                notificationsData.notifications.length > 0 ? (
                  notificationsData?.notifications?.map((notification: any) => (
                    <div
                      key={notification._id}
                      style={{
                        backgroundColor: notification.read ? "none" : "#0b4eb8",
                        color: notification?.read ? "#000" : "#fff",
                      }}
                      onClick={() => handleNotificationClick(notification)}
                      className="notifications-card"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        {notification?.read ? (
                          <Avatar className="notifications-avatar" size={50}>
                            {getInitials(notification.title)}
                          </Avatar>
                        ) : (
                          <Badge
                            dot
                            style={{
                              width: "8px",
                              height: "8px",
                              marginRight: "30px",
                              marginTop: "5px",
                            }}
                          >
                            <Avatar
                              className="notifications-avatar"
                              size={50}
                              style={{
                                backgroundColor: "#fff",
                                color: "#061db7",
                              }}
                            >
                              {getInitials(notification.title)}
                            </Avatar>
                          </Badge>
                        )}
                        <div style={{ textAlign: "start" }}>
                          <h4
                            className="notifications-title"
                            style={{
                              color: notification?.read ? "#000" : "#fff",
                            }}
                          >
                            {notification.title}
                          </h4>
                          <p className="notifications-desc">
                            {notification.description}
                          </p>
                          <small className="notifications-date">
                            {formatToNow(new Date(notification.createdAt))}
                          </small>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty-notifications">
                    <img
                      src={NotificationIcon}
                      alt="notification"
                      className="notification-img"
                    />
                    <h4> {t("workSpace.nothingToReport")} </h4>
                    <p>{t("workSpace.nothingToReportDesc")}</p>
                  </div>
                )}
                {notificationsData.hasMore && (
                  <div
                    ref={loadMoreNotificationsRef}
                    style={{ textAlign: "center", margin: "20px 0" }}
                  >
                    <Button
                      onClick={handleLoadMoreNotifications}
                      loading={loadMoreNotificationsLoading}
                    >
                      {t("workSpace.loadMore")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notifications;
