import { useEffect, useState } from "react";
import { Switch } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import CTA from "@assets/images/pages/customize/cta.png";
import { useTranslation } from "react-i18next";

const WidgetIconCTA = () => {
  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setVisible(customizations?.chatIconCta);
  }, [customizations]);

  const toggleEnableCTA = (key) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, !visible));
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => toggleEnableCTA("chatIconCta")}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={CTA}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="cta"
            />
            {t("customize.widget.widgetIconCTA")}
          </span>
          <Switch checked={visible} size="small" />
        </div>
        {visible && (
          <>
            <div
              style={{
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
                color: "#45496e",
              }}
            >
              {/* CTA Text */}
              <span> {t("customize.widget.ctaText")}</span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.chatIconCtaText}
                  maxLength={55}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "chatIconCtaText",
                        e.target.value
                      )
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WidgetIconCTA;
