import api from "../index";

// ** API TOKENS **
// Generate a new API token
export const generateApiToken = async () => {
  return await api.post("/tokens/generate");
};

// Get all API tokens
export const getApiTokens = async () => {
  return await api.get("/tokens");
};

// Revoke an API token
export const revokeApiToken = async (tokenId: any) => {
  return await api.delete(`/tokens/${tokenId}`);
};
