import { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { withRouter } from "react-router";
import { createBlogTag } from "@api/blog/blog";
import { SuccessNotification, ErrorNotification } from "../../..";

const initialState = {
  name: "",
};

const BlogTagCreateModal = ({ open, handleModalClick, fetchBlogTags }) => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle blogBlogTag create
  const handleBlogTagCreate = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validate form
    if (!values.name) {
      setLoading(false);
      return ErrorNotification("Please enter a name");
    }

    // Create a new blogBlogTag for the admin
    createBlogTag(values)
      .then(() => {
        setValues(initialState);
        handleModalClick();
        fetchBlogTags();
        SuccessNotification("BlogTag created successfully");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response.data.message);
      });
  };

  return (
    <>
      <Modal
        title="Create Blog BlogTag"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleBlogTagCreate}
            loading={loading}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* BlogTag name */}
          <Form.Item label="Name">
            <Input
              name="name"
              placeholder="Enter blogBlogTag name"
              onChange={handleFormChange}
              value={values.name}
              rules={[{ required: true }]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default withRouter(BlogTagCreateModal);
