import { Button } from "antd";
import { MdClose } from "react-icons/md";
import { Font } from "./subOptions";
import { useTranslation } from "react-i18next";

const General = ({ setActiveOption }) => {
  const handleCloseButtonClick = () => {
    setActiveOption(null);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="option-content">
        <div className="content-header">
          <h5 style={{ textTransform: "uppercase" }}>
            {t("customize.general.general")}
          </h5>
          <Button
            type="text"
            icon={<MdClose size={20} />}
            onClick={handleCloseButtonClick}
            size="small"
          />
        </div>

        <div className="general-body">
          {/* Font */}
          <Font />
        </div>
      </div>
    </>
  );
};

export default General;
