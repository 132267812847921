import { useEffect, FC, ReactElement } from "react";
import { useHistory } from "react-router-dom";

const RandomPageRedirect: FC = () : ReactElement => {
  const history = useHistory();

  useEffect(() => {
    history.goBack();
  });

  return null;
};

export default RandomPageRedirect;
