import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { FcMultipleDevices } from "react-icons/fc";

const VisitorDetails = ({ selectedChat, extractDeviceInfo }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Chat Date/Time */}
      <div className="visitor-content-inner">
        <Button
          type="text"
          icon={
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#ec4899"
                d="M32.25 6h-4v3a2.2 2.2 0 1 1-4.4 0V6H12.2v3a2.2 2.2 0 0 1-4.4 0V6h-4A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6ZM10 26H8v-2h2Zm0-5H8v-2h2Zm0-5H8v-2h2Zm6 10h-2v-2h2Zm0-5h-2v-2h2Zm0-5h-2v-2h2Zm6 10h-2v-2h2Zm0-5h-2v-2h2Zm0-5h-2v-2h2Zm6 10h-2v-2h2Zm0-5h-2v-2h2Zm0-5h-2v-2h2Z"
                className="clr-i-solid clr-i-solid-path-1"
              ></path>
              <path
                fill="#ec4899"
                d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1Z"
                className="clr-i-solid clr-i-solid-path-2"
              ></path>
              <path
                fill="#ec4899"
                d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1Z"
                className="clr-i-solid clr-i-solid-path-3"
              ></path>
              <path fill="none" d="M0 0h36v36H0z"></path>
            </svg>
          }
          size="small"
        />
        <div>
          <span
            style={{
              display: "block",
              marginTop: "-5px",
              fontWeight: 600,
            }}
          >
            {t("chatMessages.visitor.date")}
          </span>
          <span
            style={{
              fontSize: "0.9em",
              fontWeight: 600,
              color: "#0b4eb8",
            }}
          >
            <p>
              {" "}
              {new Date(selectedChat?.chatDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </span>
        </div>
      </div>

      {/* Visitor ID */}
      <div className="visitor-content-inner">
        <Button
          type="text"
          icon={
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" strokeLinejoin="round" strokeWidth="4">
                <path
                  fill="#2F88FF"
                  stroke="#000"
                  d="M42 8H6C4.89543 8 4 8.89543 4 10V38C4 39.1046 4.89543 40 6 40H42C43.1046 40 44 39.1046 44 38V10C44 8.89543 43.1046 8 42 8Z"
                ></path>
                <path
                  fill="#43CCF8"
                  stroke="#fff"
                  d="M36 16H28V24H36V16Z"
                ></path>
                <path stroke="#fff" strokeLinecap="round" d="M12 32H36"></path>
                <path stroke="#fff" strokeLinecap="round" d="M12 16H18"></path>
                <path stroke="#fff" strokeLinecap="round" d="M12 24H18"></path>
              </g>
            </svg>
          }
          size="small"
        />
        <div>
          <span
            style={{
              display: "block",
              marginTop: "-5px",
              fontWeight: 600,
            }}
          >
            {t("chatMessages.visitor.visitorId")}
          </span>
          <span
            style={{
              fontSize: "0.9em",
              fontWeight: 600,
              color: "#0b4eb8",
            }}
          >
            <p>#{selectedChat?.visitorId}</p>
          </span>
        </div>
      </div>

      {/* Session ID */}
      <div className="visitor-content-inner">
        <Button
          type="text"
          icon={
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#6366f1"
                d="m18.969 27l-4.25 2C7.531 28.356 2 24.531 2 19.887c0-4.469 5.156-8.188 11.981-9.019v2.688c-4.469.781-7.75 3.313-7.75 6.331c0 3.188 3.656 5.831 8.481 6.438V5.075L18.962 3v24zM30 18.188l-8.206-1.781l2.3-1.294c-1.219-.719-2.719-1.25-4.375-1.55v-2.688c2.887.344 5.481 1.219 7.519 2.456l2.188-1.238z"
              ></path>
            </svg>
          }
          size="small"
        />
        <div>
          <span
            style={{
              display: "block",
              marginTop: "-5px",
              fontWeight: 600,
            }}
          >
            {t("chatMessages.visitor.sessionId")}
          </span>
          <span
            style={{
              fontSize: "0.9em",
              fontWeight: 600,
              color: "#0b4eb8",
            }}
          >
            <p>#{selectedChat?.chatSessionId}</p>
          </span>
        </div>
      </div>

      {/* Location */}
      <div className="visitor-content-inner">
        <Button
          type="text"
          icon={
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#0ea5e9"
                d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7Z"
              ></path>
            </svg>
          }
          size="small"
        />
        <div>
          <span
            style={{
              display: "block",
              marginTop: "-5px",
              fontWeight: 600,
            }}
          >
            {t("chatMessages.visitor.location")}
          </span>
          <span
            style={{
              fontSize: "0.9em",
              fontWeight: 600,
              color: "#0b4eb8",
            }}
          >
            <p>
              {selectedChat?.visitorMeta?.country_name
                ? selectedChat?.visitorMeta?.country_name
                : selectedChat?.location
                ? selectedChat?.location
                : "Null"}
            </p>
          </span>
        </div>
      </div>

      {/* Device */}
      <div className="visitor-content-inner">
        <Button
          type="text"
          icon={<FcMultipleDevices size={15} />}
          size="small"
          style={{ marginRight: "15px" }}
        />
        <div style={{ width: "80%" }}>
          <span
            style={{
              display: "block",
              marginTop: "-5px",
              fontWeight: 600,
            }}
          >
            {t("chatMessages.visitor.device")}
          </span>
          <span
            style={{
              fontSize: "0.9em",
              fontWeight: 600,
              color: "#0b4eb8",
            }}
          >
            <p>
              {selectedChat?.deviceInfo
                ? `Browser: ${
                    extractDeviceInfo(selectedChat?.deviceInfo).browser
                  }, OS: ${extractDeviceInfo(selectedChat?.deviceInfo).os}`
                : "-"}
            </p>
          </span>
        </div>
      </div>
    </>
  );
};

export default VisitorDetails;
