import { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Select, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { RatingChoiceNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const RatingChoiceNodeDataEditor: FC<RatingChoiceNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [questionText, setQuestionText] = useState(state?.data?.questionText);
  const [ratingType, setRatingType] = useState(state?.data?.ratingType);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const { t } = useTranslation();
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setQuestionText(state?.data?.questionText);
    setRatingType(state?.data?.ratingType);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  const handleTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the Rating Prompt
      setQuestionText(content);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  const handleTypeChange = useCallback(
    (value: any) => {
      if (!state) return;

      setRatingType(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, ratingType: value },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t(
              "flowBuilder.sidebar.editor.flowOperationsNodes.ratingChoiceNodeInfo"
            )}
          </p>
        </Row>

        {/* Question Text */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.questionText")}
          </Col>
        </Divider>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={questionText}
              onEditorChange={handleTextChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.flowOperationsNodes.questionTextPH"
              )}
            />
          </div>
        </Col>

        {/* Rating Type */}
        <Col
          span={8}
          className="sidebar__heading"
          style={{ marginBottom: "15px" }}
        >
          {t("flowBuilder.sidebar.editor.flowOperationsNodes.ratingType")}
        </Col>
        <Col span={16} style={{ marginBottom: "15px" }}>
          <Select
            style={{ width: "100%", borderRadius: "4px" }}
            onChange={(value: any) => handleTypeChange(value)}
            value={ratingType}
          >
            <Select.Option value={3}>(3) ⭐⭐⭐</Select.Option>
            <Select.Option value={5}>(5) ⭐⭐⭐⭐⭐</Select.Option>
            <Select.Option value={10}>(10) ⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐</Select.Option>
            <Select.Option value="smiley">😡🙁😐🙂😍</Select.Option>
          </Select>
        </Col>

        <SaveAnswer id={id} type={type} variableName={answerVariable} />
      </Row>
    </>
  );
};

export default RatingChoiceNodeDataEditor;
