import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Chart from "react-apexcharts";
import { getSuperAdminAnalyticsAction } from "@redux/actions/superAdminActions";

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { analytics } = useSelector((state) => state.superAdmin);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleFetchAnalytics();
  }, []);

  const handleFetchAnalytics = () => {
    setLoading(true);
    dispatch(getSuperAdminAnalyticsAction(setLoading));
  };

  // Function to generate pie chart options for top visitor countries
  const getPieChartOptionsCountries = () => {
    return {
      labels:
        analytics?.topVisitorCountries?.map((country) => country._id) ?? [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  };

  // Pie chart data for top visitor countries
  const pieChartDataCountries =
    analytics?.topVisitorCountries?.map((country) => country.count) ?? [];

  // Process bar chart data for visitor sources
  const barChartDataSources =
    analytics?.topVisitorSources?.map((source) => source.count) ?? [];
  const barChartCategoriesSources =
    analytics?.topVisitorSources?.map((source) => source._id) ?? [];

  // Bar chart options for visitor sources
  const barChartOptionsSources = {
    chart: { type: "bar" },
    xaxis: { categories: barChartCategoriesSources },
    plotOptions: { bar: { horizontal: false } }, // You can adjust the orientation
  };

  return (
    <div style={{ height: "calc(100vh - 20px)", overflowY: "auto" }}>
      <Row gutter={[16, 16]} style={{ margin: "20px" }}>
        <Col
          span={24}
          style={{
            marginTop: "1rem",
          }}
        >
          <h2>Welcome back, {user?.name ? user.name.split(" ")[0] : ""} 👋</h2>
        </Col>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              minHeight: "20rem",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            {/* Daily Visitor Count and New Admins Last 7 Days - Line Chart */}
            <Col span={24}>
              <Card title="Daily Visitor Count and New Admins Last 7 Days">
                <Chart
                  options={{
                    chart: { type: "line" },
                    xaxis: {
                      categories: analytics?.visitorCountLast7Days?.map(
                        (entry) => entry._id
                      ),
                    },
                  }}
                  series={[
                    {
                      name: "Visitors",
                      data: analytics?.visitorCountLast7Days?.map(
                        (entry) => entry.count
                      ),
                    },
                    {
                      name: "New Admins",
                      data: analytics?.newAdminCountLast7Days?.map(
                        (entry) => entry.count
                      ),
                    },
                  ]}
                  type="line"
                  height={350}
                />
              </Card>
            </Col>

            {/* Total Users Card */}
            <Col span={6}>
              <Card title="Total Users">
                <h1>{analytics?.totalAdmins}</h1>
              </Card>
            </Col>

            {/* New Users Today Card */}
            <Col span={6}>
              <Card title="New Users Today">
                <h1>{analytics?.totalNewAdminsToday}</h1>
              </Card>
            </Col>

            {/* New Users Last 7 Days Card */}
            <Col span={6}>
              <Card title="New Users last 7 Days">
                <h1>{analytics?.totalNewAdminsLast7Days}</h1>
              </Card>
            </Col>

            {/* Number of Countries Card */}
            <Col span={6}>
              <Card title="Total Visitor Countries">
                <h1>{analytics?.totalVisitorCountries}</h1>
              </Card>
            </Col>

            {/* Total Visitors Card */}
            <Col span={6}>
              <Card title="Total Visitors">
                <h1>{analytics?.totalVisitors}</h1>
              </Card>
            </Col>

            {/* Visitors Last 7 Days Card */}
            <Col span={6}>
              <Card title="Visitors Last 7 Days">
                <h1>{analytics?.totalVisitorsLast7Days}</h1>
              </Card>
            </Col>

            {/* Visitors Last 14 Days Card */}
            <Col span={6}>
              <Card title="Visitors Last 14 Days">
                <h1>{analytics?.totalVisitorsLast14Days}</h1>
              </Card>
            </Col>

            {/* Visitors Last 30 Days Card */}
            <Col span={6}>
              <Card title="Visitors Last 30 Days">
                <h1>{analytics?.totalVisitorsLast30Days}</h1>
              </Card>
            </Col>

            {/* Top 10 Countries by Visitors - Pie Chart */}
            <Col span={12}>
              <Card title="Top 10 Countries by Visitors">
                <Chart
                  options={getPieChartOptionsCountries()}
                  series={pieChartDataCountries}
                  type="pie"
                  height={350}
                />
              </Card>
            </Col>

            {/* Top Visitor Sources - Bar Chart */}
            <Col span={12}>
              <Card title="Top Visitor Sources">
                <Chart
                  options={barChartOptionsSources}
                  series={[{ data: barChartDataSources }]}
                  type="bar"
                  height={350}
                />
              </Card>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default SuperAdminDashboard;
