import { ADMIN_TYPES } from "../constants/adminTypes";

// Define the structure of your state
interface AdminState {
  chatbots: any[];
  exampleTemplates: any[];
  chatbotNames: string[];
  selectedBotResponses: any;
  analytics?: any;
  tutorialStatus?: any;
  adminBotsPagination?: any;
  currentSubscription?: any;
}

// Define the type for your action
interface AdminAction {
  type: string;
  payload: any;
}

// Initial state with type
const initialState: AdminState = {
  chatbots: [],
  exampleTemplates: [],
  chatbotNames: [],
  selectedBotResponses: {},
};

// Reducer function with types
const adminReducer = (
  state = initialState,
  action: AdminAction
): AdminState => {
  switch (action.type) {
    // -------------- TUTORIAL STATUS --------------
    case ADMIN_TYPES.UPDATE_TUTORIAL_STATUS:
      return {
        ...state,
        tutorialStatus: action?.payload,
      };
    // -------------- DASHBOARD --------------
    case ADMIN_TYPES.GET_ADMIN_DASHBOARD_ANALYTICS:
      return {
        ...state,
        analytics: action?.payload,
        selectedBotResponses: {},
      };
    // -------------- CHATBOT --------------
    case ADMIN_TYPES.GET_ADMIN_CHATBOTS_WITH_TEMPLATES:
      return {
        ...state,
        chatbots: action.payload.chatbots,
        adminBotsPagination: action.payload.adminBotsPagination,
        exampleTemplates: action.payload.exampleTemplates,
        selectedBotResponses: {},
      };
    case ADMIN_TYPES.GET_ADMIN_CHATBOTS_NAME:
      return {
        ...state,
        chatbotNames: action?.payload,
      };
    case ADMIN_TYPES.CLEAR_ADMIN_CHATBOTS_WITH_TEMPLATES:
      return {
        ...state,
        chatbots: [],
        selectedBotResponses: {},
        exampleTemplates: [],
      };

    // -------------- CHATBOT RESPONSES --------------
    case ADMIN_TYPES.SELECTED_BOT_RESPONSES:
      // If the selectedBotResponses.id is same as the action.payload.id, then only update the selectedBotResponses.responses and selectedBotResponses.page
      if (state.selectedBotResponses?.id === action?.payload?.id) {
        return {
          ...state,
          selectedBotResponses: {
            ...state.selectedBotResponses,
            responses:
              action?.payload?.page === 1
                ? action?.payload?.responses
                : [
                    ...state.selectedBotResponses.responses,
                    ...action?.payload?.responses,
                  ],
            tickets: action?.payload?.tickets,
            page: action?.payload?.page,
            hasMore: action?.payload?.hasMore,
          },
        };
      } else {
        return {
          ...state,
          selectedBotResponses: action?.payload,
        };
      }
    case ADMIN_TYPES.UPDATE_SELECTED_BOT_RESPONSES:
      // Push the new response to the top of selectedBotResponses.responses array
      return {
        ...state,
        selectedBotResponses: {
          ...state.selectedBotResponses,
          responses: [action?.payload, ...state.selectedBotResponses.responses],
        },
      };
    case ADMIN_TYPES.UPDATE_CHAT_WITH_LATEST_MESSAGE:
      return {
        ...state,
        selectedBotResponses: {
          ...state.selectedBotResponses,
          responses: state.selectedBotResponses.responses.map(
            (response: any) => {
              if (response.chatSessionId === action?.payload?.chatSessionId) {
                return {
                  ...response,
                  record: action?.payload?.data?.record,
                  answerVariables: action?.payload?.data?.answerVariables,
                };
              } else {
                return response;
              }
            }
          ),
        },
      };
    case ADMIN_TYPES.UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES:
      return {
        ...state,
        selectedBotResponses: {
          ...state.selectedBotResponses,
          responses: state.selectedBotResponses.responses.map(
            (response: any) => {
              if (response.chatSessionId === action?.payload?.chatSessionId) {
                return {
                  ...response,
                  record: action?.payload?.record,
                  answerVariables: action?.payload?.answerVariables,
                };
              } else {
                return response;
              }
            }
          ),
        },
      };

    case ADMIN_TYPES.ARCHIVE_BOT_RESPONSE:
      // Filter out the archived response from the selectedBotResponses.responses array
      // Only modify the state if the botId matches with the current selectedBotResponses.id
      if (state.selectedBotResponses?.id === action?.payload?.botId) {
        return {
          ...state,
          selectedBotResponses: {
            ...state.selectedBotResponses,
            responses: state.selectedBotResponses.responses.filter(
              (response: any) => response._id !== action?.payload?.responseId
            ),
          },
        };
      } else {
        return state;
      }

    // -------------- SUBSCRIPTION --------------
    case ADMIN_TYPES.GET_ADMIN_SUBSCRIPTION:
      return {
        ...state,
        selectedBotResponses: {},
        currentSubscription: action?.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
