import React from "react";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import logoText from "@assets/images/logo/logoTextWhite.png";

const Terms = () => {
  const history = useHistory();

  return (
    <Row gutter={[32, 0]} className="cb-auth-container">
      <Col
        lg={24}
        span={24}
        className="hp-py-sm-0 hp-py-md-64"
        style={{
          overflowY: "auto",
          height: "100vh",
        }}
      >
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={15}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
            style={{ textAlign: "center", padding: "0px 10px 0px 10px" }}
          >
            {/* Go Back Button at the Top */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "20px" }}
            >
              <Col>
                <Button
                  style={{
                    background: "white",
                  }}
                  onClick={() => history.push("/login")}
                >
                  Go back
                </Button>
              </Col>
              <Col>
                <img
                  src={logoText}
                  alt="logo"
                  style={{
                    width: "40%",
                    height: "auto",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </Col>
              <Col>
                {" "}
                {/* This empty column ensures the button and logo align on the edges when there's enough space */}{" "}
              </Col>
            </Row>

            <h1 className="hp-mb-sm-0">Terms of use</h1>
            {/* Divider Line */}
            <br />
            <h3>Introduction</h3>
            <p>
              Welcome to Conferbot ("Company", "we", "our", "us"). These Terms
              of Service (“Terms”, “Terms of Service”) govern your use of our
              web services at https://www.conferbot.com and the related
              subdomains including but not limited to https://app.conferbot.com
              and https://api.conferbot.com operated by Netraga Pvt. Ltd.
            </p>
            <p>
              Our Privacy Policy also governs your use of our Service and
              explains how we collect, safeguard and disclose information that
              results from your use of our web pages. Please read it here
              https://app.conferbot.com/privacy.
            </p>
            <p>
              Your agreement with us includes these Terms and our Privacy Policy
              (“Agreements”). You acknowledge that you have read and understood
              Agreements, and agree to be bound of them.
            </p>
            <p>
              If you do not agree with (or cannot comply with) agreements, then
              you may not use the Service, but please let us know by emailing at
              conferbot@gmail.com so we can try to find a solution. These Terms
              apply to all visitors, users and others who wish to access or use
              Service.
            </p>
            <p>Thank you for being responsible.</p>
            <h3>Communications</h3>
            <p>
              By creating an Account on our Service, you agree to subscribe to
              newsletters, marketing or promotional materials and other
              information we may send. However, you may opt out of receiving
              any, or all, of these communications from us by following the
              unsubscribe link or by dropping an email to info@conferbot.com
            </p>
            <h3>Purchases</h3>
            <p>
              If you wish to purchase any product or service made available
              through Service (“Purchase”), you may be asked to supply certain
              information relevant to your Purchase including, without
              limitation, your credit card number, the expiration date of your
              credit card, your billing address, and your shipping information.
            </p>
            <p>
              You represent and warrant that: (i) you have the legal right to
              use any credit card(s) or other payment method(s) in connection
              with any Purchase; and that (ii) the information you supply to us
              is true, correct and complete.
            </p>
            <p>
              We may employ the use of third party services for the purpose of
              facilitating payment and the completion of Purchases. By
              submitting your information, you grant us the right to provide the
              information to these third parties subject to our Privacy Policy.
            </p>
            <p>
              We reserve the right to refuse or cancel your order at any time
              for reasons including but not limited to: product or service
              availability, errors in the description or price of the product or
              service, error in your order or other reasons.
            </p>
            <p>
              We reserve the right to refuse or cancel your order if fraud or an
              unauthorized or illegal transaction is suspected.
            </p>
            <h3>Subscriptions</h3>
            <p>
              Some parts of Service are billed on a subscription basis
              (“Subscription(s)”). You will be billed in advance on a recurring
              and periodic basis (“Billing Cycle”). Billing cycles are set
              either on a monthly or annual basis, depending on the type of
              subscription plan you select when purchasing a Subscription.
            </p>
            <p>
              At the end of each Billing Cycle, your Subscription will
              automatically renew under the exact same conditions unless you
              cancel it or Conferbot (Netraga Pvt. Ltd.) cancels it. You may
              cancel your Subscription renewal either through your online
              account management page or by contacting Conferbot
              (info@conferbot.com) customer support team.
            </p>
            <p>
              A valid payment method, including credit card, is required to
              process the payment for your subscription. You shall provide
              Conferbot with accurate and complete billing information including
              full name, address, state, zip code, telephone number, and a valid
              payment method information. By submitting such payment
              information, you automatically authorize Conferbot to charge all
              Subscription fees incurred through your account to any such
              payment instruments.
            </p>
            <p>
              Should automatic billing fail to occur for any reason, Conferbot
              shall issue an electronic invoice indicating that you must proceed
              manually, within a certain deadline date, with the full payment
              corresponding to the billing period as indicated on the invoice.
            </p>
            <h3>Fee changes</h3>

            <p>
              Conferbot, in its sole discretion and at any time, may modify
              Subscription fees for the Subscriptions. Any Subscription fee
              change will become effective at the end of the then-current
              Billing Cycle.
            </p>

            <p>
              Conferbot will provide you with a reasonable prior notice of any
              change in Subscription fees to give you an opportunity to
              terminate your Subscription before such change becomes effective.
            </p>

            <p>
              Your continued use of Service after Subscription fee change comes
              into effect constitutes your agreement to pay the modified
              Subscription fee amount.
            </p>

            <h3>Refunds</h3>

            <p>
              We issue refunds if you are not happy with the purchase within
              thirty (30) days of the original purchase. You shall drop an email
              to Conferbot support team at info@conferbot.com to request
              refunds.
            </p>

            <h3>Restrictions</h3>

            <p>
              You should comply with all applicable laws, including without
              limitation, privacy laws, intellectual property laws, anti-spam
              laws, equal opportunity laws and regulatory requirements. You
              shall never use Conferbot for performing any illegal activities or
              distribute content that is not owned by you.
            </p>

            <h3>Changes to service</h3>

            <p>
              We reserve the right to withdraw or amend our Service, and any
              service or material we provide via Service, in our sole discretion
              without notice. We will not be liable if for any reason all or any
              part of Service is unavailable at any time or for any period. From
              time to time, we may restrict access to some parts of Service, or
              the entire Service, to users, including registered users.
            </p>

            <h3>Amendments to terms</h3>

            <p>
              We may amend Terms at any time by posting the amended terms on
              this site. It is your responsibility to review these Terms
              periodically. Your continued use of the Platform following the
              posting of revised Terms means that you accept and agree to the
              changes. You are expected to check this page frequently so you are
              aware of any changes, as they are binding on you. By continuing to
              access or use our Service after any revisions become effective,
              you agree to be bound by the revised terms. If you do not agree to
              the new terms, you are no longer authorized to use Service.
            </p>

            <h3>Acknowledgement</h3>

            <p>
              BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
              THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
              THEM.
            </p>

            <h3>Contact us</h3>

            <p>
              Please send your feedback, comments, requests for technical
              support via email to info@conferbot.com
            </p>

            <Col
              className="hp-other-links hp-mt-24"
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button type="primary" onClick={() => history.push("/login")}>
                Go back
              </Button>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Terms;
