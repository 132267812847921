import React, { useRef, useEffect, useState } from "react";
import { Dropdown, Menu, Button, message } from "antd";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { AgentChatInviteModal } from "./subComponents";
import { getMemberTeamListAction } from "@redux/actions/memberActions";
import "react-quill/dist/quill.snow.css";

interface ChatInputProps {
  initialContent?: string;
  handleChatInputChange: (content: string, fontSize: string) => void;
  placeholderText?: string;
  handleInviteToChat?: any;
  disabled?: boolean;
  handleSendMessage?: any;
}

interface DropdownPosition {
  top: number;
  left: number;
}

// Toolbar Options
const TOOLBAR_OPTIONS = [
  ["bold", "italic", "underline", "strike", "link"],
  ["clean"],
];

const ChatInput: React.FC<ChatInputProps> = ({
  initialContent,
  handleChatInputChange,
  placeholderText,
  handleInviteToChat,
  disabled,
  handleSendMessage,
}) => {
  const dispatch = useDispatch();
  const chatInputRef = useRef<ReactQuill | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>({
    top: 0,
    left: 0,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [invitingAgentId, setInvitingAgentId] = useState(null);

  useEffect(() => {
    if (initialContent && chatInputRef.current) {
      chatInputRef.current.getEditor().getText();
    }
  }, [initialContent]);

  // Handle editor change
  const handleEditorChange = (content: string) => {
    if (chatInputRef.current) {
      const editor = chatInputRef.current.getEditor();
      const selection = editor.getSelection();

      if (editor) {
        handleChatInputChange(content, "");
      }

      if (selection) {
        const format = editor.getFormat(selection);
        if (format.size) {
          handleChatInputChange(content, format.size);
        } else {
          handleChatInputChange(content, "normal");
        }
      }
    }
  };

  useEffect(() => {
    // Handle paste
    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    };

    // Handle Slash
    const handleSlash = (event: KeyboardEvent) => {
      if (event.key === "/") {
        // Show dropdown options
        setShowDropdown(true);
        const chatInput = chatInputRef.current?.getEditor();
        if (chatInput) {
          const selection = chatInput.getSelection();
          if (selection) {
            chatInput.insertText(selection.index, "/");
            const bounds = chatInput.getBounds(selection.index);
            setDropdownPosition({
              top: bounds.top + 55,
              left: bounds.left + 15,
            });
          }
        }
        event.preventDefault();
      } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        // Navigate dropdown options
        event.preventDefault();
        const menuItems = document.querySelectorAll(".ant-dropdown-menu-item");
        if (menuItems.length > 0) {
          let currentIndex = Array.from(menuItems).findIndex(
            (item) => item === document.activeElement
          );
          if (currentIndex === -1) {
            // If no item is focused, focus the first item
            currentIndex = 0;
          } else {
            // Otherwise, navigate based on arrow key
            if (event.key === "ArrowUp") {
              currentIndex =
                currentIndex === 0 ? menuItems.length - 1 : currentIndex - 1;
            } else {
              currentIndex =
                currentIndex === menuItems.length - 1 ? 0 : currentIndex + 1;
            }
          }
          (menuItems[currentIndex] as HTMLLIElement).focus();
        }
      } else {
        setShowDropdown(false);
      }
    };

    // Handle backspace
    const handleBackspace = (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        setShowDropdown(false);
      }
    };

    if (chatInputRef.current) {
      const chatInput = chatInputRef.current.getEditor();
      chatInput.root.addEventListener("paste", handlePaste);
      chatInput.root.addEventListener("keydown", handleSlash);
      chatInput.root.addEventListener("keydown", handleBackspace);
    }

    return () => {
      if (chatInputRef.current) {
        const chatInput = chatInputRef.current.getEditor();
        chatInput.root.removeEventListener("paste", handlePaste);
        chatInput.root.removeEventListener("keydown", handleSlash);
        chatInput.root.removeEventListener("keydown", handleBackspace);
      }
    };
  }, [showDropdown]);

  // Quill editor styling
  const chatInputStyle = `
    .ql-snow .ql-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: #fff;
      border: none;
    }
    .ql-snow .ql-picker.ql-size {
      margin-left: -14px;
      width: 70px
    }
    .ql-snow .ql-list{
      margin-left: -5px;
    }
    .ql-snow .ql-link{
      margin-left: -5px;
    }

    .ql-snow .ql-strike,
    .ql-snow .ql-blockquote,
    .ql-snow .ql-link{
      margin-left: 5px;
    }
   
    .ql-snow .ql-editor {
      min-height: 50px;
      max-height: 123px;
      overflow-y: auto;
      font-size: 16px;
    }
  `;

  // Handle open teams modal
  const handleOpenTeamsModal = () => {
    setLoading(true);
    setModalVisible(true);
    setShowDropdown(false);

    // Dispatch action to fetch team members
    dispatch(getMemberTeamListAction(setLoading));

    // Clear the editor input
    if (chatInputRef.current) {
      const editor = chatInputRef.current.getEditor();
      editor.setText("");
    }
  };

  // Menu options
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={handleOpenTeamsModal}>
        Invite team member
      </Menu.Item>
      <Menu.Item key="2">
        <Button
          size="small"
          type="link"
          onClick={() => message.info("Coming soon")}
        >
          + Create
        </Button>
      </Menu.Item>
    </Menu>
  );

  // Handle close modal
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  // Handle invite to chat
  const handleInviteToChatHandler = (agentId: any, isOnline: any) => {
    setIsInviting(true);
    setInvitingAgentId(agentId);

    // Simulate the invite process (replace this with your actual invite logic)
    setTimeout(() => {
      setIsInviting(false);
      setInvitingAgentId(null);

      if (isOnline) {
        // Send invitation to chat
        handleInviteToChat(agentId);

        // Show success message
        message.success("Invitation has been sent.");
      } else {
        // Show success message
        message.info("An email requesting to join the chat has been sent.");
      }
    }, 2000); // Simulated delay
  };

  return (
    <div
      style={{ position: "relative" }}
      onClick={() => setShowDropdown(false)}
    >
      {/* Styles */}
      <style>{chatInputStyle}</style>

      {/* Editor */}
      <ReactQuill
        id="chatInput"
        theme="snow"
        ref={chatInputRef}
        defaultValue={initialContent}
        value={initialContent}
        onChange={handleEditorChange}
        modules={{
          toolbar: TOOLBAR_OPTIONS,
        }}
        placeholder={placeholderText}
        className="custom-quill-editor"
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          cursor: disabled ? "not-allowed" : "auto",
        }}
        readOnly={disabled}
        onKeyUp={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            // Check if Enter is pressed without the Shift key
            e.preventDefault(); // Prevent the default action (inserting a new line)

            // Send the message
            handleSendMessage();
          }
        }}
      />

      {/* Slash Commands Dropdown*/}
      {showDropdown && (
        <div
          style={{
            position: "absolute",
            top: dropdownPosition.top,
            left: dropdownPosition.left,
          }}
        >
          <Dropdown
            visible={showDropdown}
            overlay={menu}
            trigger={[]}
            getPopupContainer={() =>
              document.getElementById("editor-container") || document.body
            }
            placement="bottomLeft"
          >
            <span></span>
          </Dropdown>
        </div>
      )}

      {/* Team list modal */}
      <AgentChatInviteModal
        modalVisible={modalVisible}
        handleCloseModal={handleCloseModal}
        loading={loading}
        // Invite stuff
        isInviting={isInviting}
        invitingAgentId={invitingAgentId}
        handleInviteToChatHandler={handleInviteToChatHandler}
      />
    </div>
  );
};

export default ChatInput;
