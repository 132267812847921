import { Card, Skeleton } from "antd";

const SupportCardSkeleton = () => (
  <Card
    bodyStyle={{ padding: "15px" }}
    hoverable
    style={{
      height: "440px",
      maxHeight: "440px",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      position: "relative",
      overflow: "hidden",
      margin: "0 auto",
      borderRadius: "8px",
    }}
  >
    {/* Image */}
    <div
      style={{
        height: "100px",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          height: "100px",
          width: "100%",
        }}
      />
    </div>

    {/* main input text */}
    <div
      style={{
        height: "20px",
        width: "100%",
        maxWidth: "200px",
        overflow: "hidden",
        borderRadius: "20px",
        margin: "25px auto 0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>

    {/* input texts */}
    {/* 1 */}
    <div
      style={{
        height: "20px",
        width: "100%",
        maxWidth: "150px",
        overflow: "hidden",
        borderRadius: "20px",
        margin: "35px auto 0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>
    {/* 2 */}
    <div
      style={{
        height: "20px",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "20px",
        margin: "10px auto 0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>
    {/* 3 */}
    <div
      style={{
        height: "20px",
        width: "100%",
        maxWidth: "200px",
        overflow: "hidden",
        borderRadius: "20px",
        margin: "10px auto 0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>

    {/* button */}
    <div
      style={{
        width: "100%",
        maxWidth: "150px",
        overflow: "hidden",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "35px auto 0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
          height: "40px",
        }}
      />
    </div>
  </Card>
);
export default SupportCardSkeleton;
