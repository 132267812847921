import { Button } from "antd";
import { MdClose } from "react-icons/md";
import { AutoOpen, DisabledUrls, LoadWidgetDelay } from "./subOptions";
import { useTranslation } from "react-i18next";

const Advanced = ({ setActiveOption }) => {
  const handleCloseButtonClick = () => {
    setActiveOption(null);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="option-content">
        <div className="content-header">
          <h5 style={{ textTransform: "uppercase" }}>
            {t("customize.advanced.advanced")}
          </h5>
          <Button
            type="text"
            icon={<MdClose size={20} />}
            onClick={handleCloseButtonClick}
            size="small"
          />
        </div>
        <div className="general-body">
          {/* Load Widget Delay */}
          <LoadWidgetDelay />

          {/* Auto Open */}
          <AutoOpen />

          {/* Disabled URLs */}
          <DisabledUrls />
        </div>
      </div>
    </>
  );
};

export default Advanced;
