import { NodeTypes as FlowNodeTypes } from "reactflow";

import {
  // **********  v1 (Legacy Nodes) **********
  // ** DEPRECATED NODES **
  SelectOptionNode,
  TwoChoicesNode,
  ThreeChoicesNode,
  RatingNode,
  UserNode,
  RangeNode,
  QuizNode,

  // **********  v2 (New Nodes) **********
  // ** STARTER NODE **
  WelcomeNode,

  // ** SEND RESPONSE NODES **
  ImageNode,
  MessageNode,
  VideoNode,
  AudioNode,
  FileNode,

  // ** ASK QUESTION NODES **
  AskName,
  AskEmail,
  AskPhoneNumber,
  AskFile,
  AskUrl,
  AskNumberNode,
  AskLocationNode,
  AskCustomQuestionNode,
  AskMultipleQuestions,
  CalendarNode,
  NCheckOptionsNode,
  NSelectOptionNode,

  // ** FLOW OPERATION NODES **
  NChoicesNode,
  ImageChoiceNode,
  RatingChoice,
  YesOrNoNode,
  OpinionScaleNode,

  // ** LOGICAL NODES **
  ConditionNode,
  BusinessHoursNode,
  BooleanLogicNode,
  MathOperationNode,
  RandomFlowNode,
  VariableNode,

  // ** SPECIAL NODES **
  EmailNode,
  RedirectNode,
  HtmlNode,
  JumpToNode,
  HumanHandoverNode,
  DelayNode,
  NavigateNode,

  // ** INTEGRATION NODES **
  WebhookNode,
  GptNode,
  ZapierNode,
  GoogleSheetsNode,
  SlackNode,
  AirtableNode,
  ZohoNode,
  HubspotNode,
  MailChimpNode,
  SegmentNode,
  SalesforceNode,
} from "../common/nodes";

// Node Types
export const nodeTypes: FlowNodeTypes = {
  // **********  v1 (Legacy Nodes) **********
  // ** DEPRECATED NODES **
  "select-option-node": SelectOptionNode,
  "two-choices-node": TwoChoicesNode,
  "three-choices-node": ThreeChoicesNode,
  "user-rating-node": RatingNode,
  "user-range-node": RangeNode,
  "user-input-node": UserNode,
  "quiz-node": QuizNode,

  // **********  v2 (New Nodes) **********
  // ** STARTER NODE **
  "welcome-node": WelcomeNode,

  // ** SEND RESPONSE NODES **
  "message-node": MessageNode,
  "image-node": ImageNode,
  "video-node": VideoNode,
  "audio-node": AudioNode,
  "file-node": FileNode,

  // ** ASK QUESTION NODES **
  "ask-name-node": AskName,
  "ask-email-node": AskEmail,
  "ask-phone-number-node": AskPhoneNumber,
  "ask-file-node": AskFile,
  "ask-number-node": AskNumberNode,
  "ask-url-node": AskUrl,
  "ask-multiple-questions-node": AskMultipleQuestions,
  "calendar-node": CalendarNode,
  "n-check-options-node": NCheckOptionsNode,
  "n-select-option-node": NSelectOptionNode,
  "ask-location-node": AskLocationNode,
  "ask-custom-question-node": AskCustomQuestionNode,

  // ** FLOW OPERATION NODES **
  "n-choices-node": NChoicesNode,
  "image-choice-node": ImageChoiceNode,
  "rating-choice-node": RatingChoice,
  "yes-or-no-choice-node": YesOrNoNode,
  "opinion-scale-choice-node": OpinionScaleNode,

  // ** LOGICAL NODES **
  "condition-node": ConditionNode,
  "business-hours-node": BusinessHoursNode,
  "math-operation-node": MathOperationNode,
  "variable-node": VariableNode,
  "boolean-logic-node": BooleanLogicNode,
  "random-flow-node": RandomFlowNode,

  // ** SPECIAL NODES **
  "email-node": EmailNode,
  "html-node": HtmlNode,
  "user-redirect-node": RedirectNode,
  "jump-to-node": JumpToNode,
  "human-handover-node": HumanHandoverNode,
  "delay-node": DelayNode,
  "navigate-node": NavigateNode,

  // ** INTEGRATION NODES **
  "webhook-node": WebhookNode,
  "gpt-node": GptNode,
  "zapier-node": ZapierNode,
  "google-sheets-node": GoogleSheetsNode,
  "slack-node": SlackNode,
  "airtable-node": AirtableNode,
  "zoho-node": ZohoNode,
  "hubspot-node": HubspotNode,
  "mailchimp-node": MailChimpNode,
  "segment-node": SegmentNode,
  "salesforce-node": SalesforceNode,
};
