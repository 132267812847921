import api from "../index";

// Check if the user is super admin
export const currentSuperAdmin = async () => {
  return await api.post("/currentSuperAdmin", {});
};

// ********** Analytics ********** //
// Get super admin analytics
export const getSuperAdminAnalytics = async () => {
  return await api.get("/super-admin/analytics");
};

// ********** Admins ********** //
// Get all admins paginated
export const getAllAdminsPaginated = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(`/admins/paginated?page=${page}&perPage=${perPage}`);
  } else {
    return await api.get(
      `/admins/paginated?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get admin details by id
export const getAdminDetailsById = async (id: any) => {
  return await api.get(`/super/admin/${id}`);
};

// Get admin team details
export const getAdminTeamDetails = async (id: any) => {
  return await api.get(`/super/admin-team/${id}`);
};

// Assign chatbot to member
export const assignChatbotToMember = async (userId: any, chatbotId: any) => {
  return await api.post("/super/assign-chatbot", {
    userId,
    chatbotId,
  });
};

// Unassign chatbot from member
export const unassignChatbotFromMember = async (
  userId: any,
  chatbotId: any
) => {
  return await api.post("/super/unassign-chatbot", {
    userId,
    chatbotId,
  });
};

// Toggle disable user account
export const toggleDisableUserAccount = async (userId: any) => {
  return await api.put(`/user/disable/${userId}`);
};

// ** USAGE ** //
// Get admin usage history
export const getAdminUsageHistory = async (id: any) => {
  return await api.get(`/super/admin/${id}/usage/history`);
};

// Add usage to admin
export const addUsageToAdmin = async (id: any, data: any) => {
  return await api.post(`/super/admin/${id}/usage/add`, data);
};

// Get usage of admin by id
export const getAdminUsageById = async (adminId: any, usageId: any) => {
  return await api.get(`/super/admin/${adminId}/usage/${usageId}`);
};

// Update usage of admin
export const updateUsageOfAdmin = async (
  adminId: any,
  usageId: any,
  data: any
) => {
  return await api.put(`/super/admin/${adminId}/usage/${usageId}/update`, data);
};

// Delete usage records
export const deleteUsageRecords = async (adminId: any, data: any) => {
  return await api.put(`/super/admin/${adminId}/usage/delete`, data);
};

// ** SUBSCRIPTION ** //
// Get admin subscription history
export const getAdminSubscriptionHistory = async (id: any) => {
  return await api.get(`/super/admin/${id}/subscription/history`);
};

// ** PAYMENT ** //
// Get admin payment history
export const getAdminPaymentHistory = async (id: any) => {
  return await api.get(`/super/admin/${id}/payment/history`);
};

// ********** Visitors ********** //
// Get all visitors paginated
export const getAllVisitorsPaginated = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(`/visitors/paginated?page=${page}&perPage=${perPage}`);
  } else {
    return await api.get(
      `/visitors/paginated?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// ********** Leads ********** //
export const getAllLeads = async () => {
  return await api.get("/leads");
};
