import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slider, Switch } from "antd";
import { useParams } from "react-router-dom";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import BotAutoOpen from "@assets/images/pages/customize/open-bot.png";
import { useTranslation } from "react-i18next";

const AutoOpen = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setVisible(!visible);
    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "botAutoOpen", !visible)
    );
  };

  return (
    <>
      <div className="dropdown-menu">
        <div onClick={handleClick} className="dropdown-trigger">
          <span>
            <img
              src={BotAutoOpen}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="paint-palette-icon"
            />
            {t("customize.advanced.botAutoOpen")}
          </span>
          <Switch size="small" checked={customizations?.botAutoOpen} />
        </div>
        {visible && (
          <>
            <div style={{ padding: "20px 25px", backgroundColor: "#d5d7e4" }}>
              <span>
                {t("customize.advanced.botAutoOpenDelay(in seconds)")}
              </span>
              <div style={{ marginTop: "15px" }}>
                <Slider
                  style={{ marginBottom: "18px" }}
                  defaultValue={10}
                  min={0}
                  max={50}
                  onChange={(value) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "botAutoOpenDelay",
                        value
                      )
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AutoOpen;
