import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { YesOrNoNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const YesOrNoDataEditor: FC<YesOrNoNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);
  const [questionText, setQuestionText] = useState(state?.data?.questionText);
  const [yesLabel, setYesLabel] = useState(
    state?.data?.options?.find((option: any) => option.id === "1")?.label ||
      "Yes"
  );
  const [noLabel, setNoLabel] = useState(
    state?.data?.options?.find((option: any) => option.id === "2")?.label ||
      "No"
  );
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const { t } = useTranslation();

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setQuestionText(state?.data?.questionText);
    setYesLabel(
      state?.data?.options?.find((option: any) => option.id === "1")?.label ||
        "Yes"
    );
    setNoLabel(
      state?.data?.options?.find((option: any) => option.id === "2")?.label ||
        "No"
    );
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  // Handle Question Text change
  const handleQuestionTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the Rating Prompt
      setQuestionText(content);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle Yes Option change
  const handleYesOptionTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      setYesLabel(content); // Update the Yes Option label state

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          options: state?.data?.options.map((option: any) =>
            option.id === "1" ? { ...option, label: content } : option
          ),
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle No Option change
  const handleNoOptionTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      setNoLabel(content); // Update the No Option label state

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          options: state?.data?.options.map((option: any) =>
            option.id === "2" ? { ...option, label: content } : option
          ),
        },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row
        align="middle"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t(
              "flowBuilder.sidebar.editor.flowOperationsNodes.yesOrNoNodeInfo"
            )}
          </p>
        </Row>
        {/* Question Text */}
        <Divider orientation="left">
          <Col
            span={24}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.questionText")}
          </Col>
        </Divider>
        <Col span={24} style={{ width: "100%" }}>
          <div>
            <QuillEditor
              initialContent={questionText}
              onEditorChange={handleQuestionTextChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.flowOperationsNodes.questionTextPH"
              )}
            />
          </div>
        </Col>

        <Divider orientation="left">
          <Col
            span={24}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.yesOption")}
          </Col>
        </Divider>
        <Col span={24} style={{ width: "100%" }}>
          <div>
            <QuillEditor
              initialContent={yesLabel}
              onEditorChange={(content) => handleYesOptionTextChange(content)}
            />
          </div>
        </Col>
        <Divider orientation="left">
          <Col
            span={24}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.noOption")}
          </Col>
        </Divider>
        <Col span={24} style={{ width: "100%" }}>
          <div>
            <QuillEditor
              initialContent={noLabel}
              onEditorChange={(content) => handleNoOptionTextChange(content)}
            />
          </div>
        </Col>
      </Row>
      <SaveAnswer id={id} type={type} variableName={answerVariable} />
    </>
  );
};

export default YesOrNoDataEditor;
