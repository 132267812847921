import React from "react";
import { Col, Input, Row, message, Card } from "antd";
import { useParams } from "react-router";
import {
  Facebook,
  Twitter,
  Messenger,
  Whatsapp,
} from "../../../assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const ChatbotShareWithLink = () => {
  const { bid } = useParams<{ bid: string }>();
  const { t } = useTranslation();

  const shareUrl = `${process.env.REACT_APP_PUBLIC_SHARE_URL}/${bid}/index.html`;
  const shareTitle = "Check out this chatbot!"; // You can customize this

  const HandleOpenLink = () => {
    window.open(shareUrl, "_blank");
  };

  // Handle Copy Token
  const handleCopyToken = () => {
    const inputField = document.getElementById("myInput") as HTMLInputElement;
    if (inputField) {
      inputField.select();
      document.execCommand("copy");
      message.success("Copied to clipboard");
    }
  };

  // Social sharing functions
  const shareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`,
      "_blank"
    );
  };

  const shareTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareUrl
      )}&text=${encodeURIComponent(shareTitle)}`,
      "_blank"
    );
  };

  const shareMessenger = () => {
    window.open(
      `https://www.facebook.com/dialog/send?link=${encodeURIComponent(
        shareUrl
      )}&app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}`,
      "_blank"
    );
  };

  const shareWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareTitle + " " + shareUrl
      )}`,
      "_blank"
    );
  };

  // input style
  const inputStyle = {
    borderRadius: "3px",
    marginBottom: "15px",
    marginTop: "5px",
    padding: "15px",
  };

  return (
    <Card
      className="share_with_link"
      style={{
        height: "calc(100vh - 64px)",
        overflowY: "auto",
        borderRadius: "0px",
        padding: "0px 30px 30px 30px",
      }}
    >
      {/* Header */}
      <Row gutter={[32, 32]}>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              marginBottom: "1rem",
            }}
          >
            <h3>{t("share.shareWithLink")}</h3>
          </div>
        </Col>
      </Row>

      <div className="share_auth">
        <span className="title">{t("share.useThisLink")}</span>

        <Col span={24}>
          <Input
            readOnly
            suffix={
              <>
                <span
                  style={{ fontWeight: "600", cursor: "pointer" }}
                  onClick={HandleOpenLink}
                  className="open_btn"
                >
                  <a href={shareUrl} target="_blank" rel="noreferrer">
                    {t("share.open")}
                  </a>
                </span>
                <span
                  style={{ fontWeight: "600", cursor: "pointer" }}
                  onClick={handleCopyToken}
                  className="copy_btn"
                >
                  {t("share.copy")}
                </span>
              </>
            }
            id="myInput"
            value={shareUrl}
            style={inputStyle}
          />
        </Col>
      </div>

      <div className="share_with_platforms">
        <span
          className="title"
          style={{
            display: "flex",
            marginTop: "1rem",
            marginBottom: "1rem",
            fontSize: "1.2rem",
          }}
        >
          {t("share.shareItOn")}:
        </span>
        <div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{ backgroundColor: "#4e6ca0", cursor: "pointer" }}
                className="share_platform_card"
                onClick={shareFacebook}
              >
                <img src={Facebook} alt="Facebook" />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{ backgroundColor: "#5eaade", cursor: "pointer" }}
                className="share_platform_card"
                onClick={shareTwitter}
              >
                <img src={Twitter} alt="Twitter" />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{ backgroundColor: "#dfe1ec", cursor: "pointer" }}
                className="share_platform_card"
                onClick={shareMessenger}
              >
                <img src={Messenger} alt="Messenger" />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{ backgroundColor: "#37d09e", cursor: "pointer" }}
                className="share_platform_card"
                onClick={shareWhatsApp}
              >
                <img src={Whatsapp} alt="WhatsApp" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default ChatbotShareWithLink;
