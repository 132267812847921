import { FC, useState } from "react";
import { Button, Menu, Popover } from "antd";
import useStore, { selector } from "../../../store/store";
import { shallow } from "zustand/shallow";
import DeleteIcon from "@assets/images/pages/builder/delete.png";
import DuplicateIcon from "@assets/images/pages/builder/duplicate.png";
import MoreIcon from "@assets/images/pages/builder/more.png";

interface SidebarProps {
  nodeId: any;
}

const ContextMenu: FC<SidebarProps> = ({ nodeId }) => {
  const { deleteNode, duplicateNode } = useStore(selector, shallow);
  const [visible, setVisible] = useState(false);

  // Handle Node Delete
  const handleNodeDelete = () => {
    deleteNode(nodeId);
    setVisible(false);
  };

  // Handle Node Duplicate
  const handleNodeDuplicate = () => {
    duplicateNode(nodeId);
    setVisible(false);
  };

  // Handle Copy Node ID
  const handleCopyNodeId = () => {
    navigator.clipboard.writeText(nodeId);
    setVisible(false);
  };

  // Icons
  const copyIcon = (
    <svg
      width="1.3em"
      height="1.3em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" stroke="#000000" strokeWidth="1.5">
        <path d="M6 11c0-2.828 0-4.243.879-5.121C7.757 5 9.172 5 12 5h3c2.828 0 4.243 0 5.121.879C21 6.757 21 8.172 21 11v5c0 2.828 0 4.243-.879 5.121C19.243 22 17.828 22 15 22h-3c-2.828 0-4.243 0-5.121-.879C6 20.243 6 18.828 6 16v-5Z"></path>
        <path
          d="M6 19a3 3 0 0 1-3-3v-6c0-3.771 0-5.657 1.172-6.828C5.343 2 7.229 2 11 2h4a3 3 0 0 1 3 3"
          opacity=".5"
        ></path>
      </g>
    </svg>
  );

  const duplicateIcon = (
    <img
      src={DuplicateIcon}
      alt="Duplicate Icon"
      style={{
        height: "18px",
        width: "18px",
        marginRight: "8px",
      }}
    />
  );

  const deleteIcon = (
    <img
      src={DeleteIcon}
      alt="Delete Icon"
      style={{
        height: "15px",
        width: "15px",
        marginRight: "8px",
        marginTop: "-3px",
      }}
    />
  );

  const moreIcon = (
    <img
      src={MoreIcon}
      alt="More Icon"
      style={{
        height: "15px",
        width: "15px",
      }}
    />
  );

  const menu = (
    <Menu
      className="context-custom-menu"
      style={{
        borderRadius: "4px",
        padding: "0px",
        width: "150px",
        margin: "0px",
      }}
    >
      <Menu.Item
        key="option0"
        onClick={handleCopyNodeId}
        style={{ fontSize: "14px", marginLeft: "-2px" }}
        className="context-menu-item"
      >
        <span
          style={{
            marginRight: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {copyIcon}{" "}
          <span
            style={{
              fontSize: "14px",
              fontWeight: 500,
              marginLeft: "10px",
            }}
          >
            Node ID
          </span>
        </span>
      </Menu.Item>

      <Menu.Item
        key="option1"
        onClick={handleNodeDuplicate}
        style={{ fontSize: "14px", marginLeft: "-2px" }}
        className="context-menu-item"
      >
        {duplicateIcon} Duplicate
      </Menu.Item>

      <Menu.Item
        key="option2"
        onClick={handleNodeDelete}
        style={{ color: "red", fontSize: "14px" }}
        className="context-menu-item"
      >
        {deleteIcon} Delete
      </Menu.Item>
    </Menu>
  );

  // Don't show menu on analytics and version page
  const pathArray = window.location.pathname.split("/");
  const lastSegment = pathArray[pathArray.length - 1];
  const thirdLastSegment = pathArray[pathArray.length - 3];

  const shouldShowMenu = !(
    lastSegment === "analytics" || thirdLastSegment === "version"
  );

  return (
    <Popover
      content={shouldShowMenu ? menu : null}
      placement="rightTop"
      open={visible}
      onOpenChange={(newVisible) => setVisible(newVisible)}
    >
      <Button type="text" icon={moreIcon} size="small" />
    </Popover>
  );
};

export default ContextMenu;
