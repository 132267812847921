import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, InputNumber, message, Input, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { OpinionScaleNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const OpinionNodeDataEditor: FC<OpinionScaleNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [questionText, setQuestionText] = useState(state?.data?.questionText);
  const [fromValue, setFromValue] = useState(state?.data?.from);
  const [fromLabel, setFromLabel] = useState(state?.data?.fromLabel);
  const [toValue, setToValue] = useState(state?.data?.to);
  const [toLabel, setToLabel] = useState(state?.data?.toLabel);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const { t } = useTranslation();

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setQuestionText(state?.data?.questionText);
    setFromValue(state?.data?.from || 0);
    setFromLabel(
      state?.data?.fromLabel ||
        t("flowBuilder.sidebar.editor.flowOperationsNodes.worst")
    );
    setToValue(state?.data?.to || 2);
    setToLabel(
      state?.data?.toLabel ||
        t("flowBuilder.sidebar.editor.flowOperationsNodes.best")
    );
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  useEffect(() => {
    const numBoxes = Math.abs(toValue - fromValue) + 1;
    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, numOfOptions: numBoxes },
    });
  }, [fromValue, toValue]);

  // Handle Question Text Change
  const handleQuestionTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the welcome message
      setQuestionText(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle Start Value Change
  const handleStartChange = useCallback(
    (value: any) => {
      if (!state) return;

      if (toValue !== "" && parseFloat(value) >= parseFloat(toValue)) {
        message.error("From number must be less than To number");
      } else {
        setFromValue(value ? value : 0);
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, from: value },
        });
      }
    },
    [state, type, changeNodeData]
  );

  // Handle End Value Change
  const handleEndChange = useCallback(
    (value: any) => {
      if (!state) return;

      if (fromValue !== "" && parseFloat(value) <= parseFloat(fromValue)) {
        message.error("To number must be greater than From number");
      } else {
        setToValue(value ? value : 0);
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, to: value },
        });
      }
    },
    [state, type, changeNodeData]
  );

  // Handle From or To label change
  const handleLabelChange = useCallback(
    (value: any, id: any) => {
      if (!state) return;

      if (id === "from") {
        setFromLabel(value);
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, fromLabel: value },
        });
      } else {
        setToLabel(value);
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, toLabel: value },
        });
      }
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t(
              "flowBuilder.sidebar.editor.flowOperationsNodes.opinionNodeInfo"
            )}
          </p>
        </Row>

        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />

        {/* Question Text */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.questionText")}
          </Col>
        </Divider>
        <Col span={24}>
          <div style={{ marginTop: "6px", marginBottom: "10px" }}>
            <QuillEditor
              initialContent={questionText}
              onEditorChange={handleQuestionTextChange}
              placeholderText="Type question here!"
            />
          </div>
        </Col>

        {/* From */}
        <Divider orientation="left">
          <Col
            span={24}
            style={{ marginTop: "10px" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.from")}
          </Col>
        </Divider>
        <Col span={24}>
          <InputNumber
            min={0}
            max={15}
            value={fromValue}
            onChange={handleStartChange}
            style={{ width: "100%", marginTop: "5px", borderRadius: "4px" }}
          />
        </Col>

        <Col span={24}>
          <Input
            placeholder="Enter From label"
            value={fromLabel}
            onChange={(e) => handleLabelChange(e.target.value, "from")}
            style={{ marginTop: "5px", borderRadius: "4px" }}
          />
        </Col>

        {/* To */}
        <Divider orientation="left">
          <Col
            span={24}
            style={{ marginTop: "10px" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.to")}
          </Col>
        </Divider>
        <Col span={24}>
          <InputNumber
            min={0}
            max={15}
            value={toValue}
            onChange={handleEndChange}
            style={{ width: "100%", marginTop: "5px", borderRadius: "4px" }}
          />
        </Col>

        <Col span={24} style={{ marginBottom: "15px" }}>
          <Input
            placeholder="Enter To label"
            value={toLabel}
            onChange={(e) => handleLabelChange(e.target.value, "to")}
            style={{ marginTop: "5px", borderRadius: "4px" }}
          />
        </Col>

        {/* Save Answer */}
        <SaveAnswer id={id} type={type} variableName={answerVariable} />
      </Row>
    </>
  );
};

export default OpinionNodeDataEditor;
