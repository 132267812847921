import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { getValue, setValue } from "../../utils/localStorage";
import { languages } from "../../utils/languages";
import "../../assets/css/LanguageSelector.css";

const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>("English");

  const changeLanguage = (lang: string) => {
    const selectedLang = languages.find((language) => language.code === lang);
    if (selectedLang) {
      i18n.changeLanguage(selectedLang.code);
      setValue("language", selectedLang.code);
      setSelectedLanguage(selectedLang.title);
    }
  };

  useEffect(() => {
    const fetchLanguage = async () => {
      const selectedLanguage = await getValue("language");
      if (selectedLanguage) {
        const selectedLang = languages.find(
          (language) => language.code === selectedLanguage
        );
        if (selectedLang) {
          setSelectedLanguage(selectedLang.title);
        }
      }
    };
    fetchLanguage();
  }, []);

  return (
    <div className="language-selector">
      <div className="language-heading">
        <Col md={16} span={24}>
          <h3>{t("profile.personalInfo.language")}</h3>
        </Col>
        <h3 className="language-heading-text">{t(selectedLanguage)}</h3>
      </div>
      <Row
        gutter={[16, 16]}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {languages.map((language) => (
          <Col key={language.code}>
            <Card
              className={`language-card ${
                selectedLanguage === language.title ? "selected" : ""
              }`}
              onClick={() => changeLanguage(language.code)}
              cover={
                <img
                  alt={language.title}
                  src={language.img}
                  className="language-img"
                />
              }
            >
              <Card.Meta
                title={
                  <div
                    className={`card-language-text ${
                      selectedLanguage === language.title ? "selected" : ""
                    }`}
                  >
                    {t(language.title)}
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default LanguageSelector;
