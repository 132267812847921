import { useState } from "react";
import { Layout, Row, Col } from "antd";
import Sidebar from "./components/menu/Sidebar";
import ScrollTop from "./components/scroll-to-top";
import MenuHeader from "./components/header";

const { Content } = Layout;

const VerticalLayout = (props: any) => {
  const { children } = props;
  const [visible, setVisible] = useState(false);

  return (
    <Layout className="hp-app-layout">
      <Sidebar visible={visible} setVisible={setVisible} />

      <Layout
        className="hp-bg-color-dark-90"
        style={{
          margin: "0px -30px 0px -33px",
        }}
      >
        <Content className="hp-content-main">
          <div className="menu_header__hamburger">
            <MenuHeader setVisible={setVisible} />
          </div>
          <Row justify="center">
            <Col span={24}>{children}</Col>
          </Row>
        </Content>
      </Layout>

      <ScrollTop />
    </Layout>
  );
};

export default VerticalLayout;
