import {
  _getCurrentStepsAndIndex,
  _getCustomizationsRef,
  _pushDataToRecord,
  // Transcripts
  _getTranscript,
  _handleAddToTranscript,
  // Answer variables
  _setAnswerVariable,
} from "../../ChatbotFlowPreview";

// Helpers
import {
  _showTypingAnimationForGivenTime,
  _displayBotMessageHelper,
} from "../../utils/helpers";

// Handle webhook node
export const _handleWebhookNode = async (
  nodeData: any,
  nodeId: any,
  answerVariables: any
) => {
  // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
  if (nodeData.answerVariable) {
    answerVariables.push({
      nodeId: nodeId,
      key: nodeData.answerVariable,
      value: null,
    });
  }

  try {
    // Step 1: Authenticate and get the bearer token
    const authResponse = await fetch(nodeData.authentication.tokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: nodeData.authentication.username,
        password: nodeData.authentication.password,
      }),
    });

    const authData = await authResponse.json();

    // Make sure to get the token from the correct path in the response
    const token = authData.access;

    if (!token) {
      console.log("Token not found in authentication response");
      return;
    }
    // Step 2: Make the API call using the bearer token
    const initialHeaders = nodeData.headers || {};

    const apiHeaders = {
      ...initialHeaders,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let bodyData: any = {};

    // If nodeData.includeAnswerVariables is true, add the answerVariables to the body
    if (nodeData.includeAnswerVariables) {
      // Loop through the answerVariables array and add them to the body
      answerVariables.forEach((answerVariable: { key: string; value: any }) => {
        // If the value is null, set it to empty string
        if (answerVariable.value === null) {
          answerVariable.value = "";
        }

        // Add the answerVariable to the body
        bodyData[answerVariable.key] = answerVariable.value;
      });
    }

    let bodyContent =
      typeof nodeData.body === "string" && nodeData.body !== ""
        ? JSON.stringify(JSON.parse(nodeData.body))
        : JSON.stringify(nodeData.body);

    // Include the body data in the bodyContent
    bodyContent = JSON.stringify({
      ...JSON.parse(bodyContent),
      answers: bodyData,
    });

    const apiResponse = await fetch(nodeData.url, {
      method: nodeData.method,
      headers: apiHeaders,
      body: bodyContent,
    });

    const apiResponseData = await apiResponse.json();

    // Add the user selection to the transcript
    _handleAddToTranscript("bot", apiResponseData);

    // Set the answer variable
    _setAnswerVariable(nodeId, apiResponseData);
  } catch (error) {
    console.error("Webhook handling failed", error);
  }
};

// Handle GPT node
export const _handleGPTNode = async (nodeData: any) => {
  try {
    // Get _getCurrentStepsAndIndex
    const { steps, currentIndex } = _getCurrentStepsAndIndex();

    // Get the transcript
    const transcript = _getTranscript();

    // Get the customizations
    const customizations = _getCustomizationsRef();

    // Construct the message array
    let messages = transcript.map((entry: any) => {
      return {
        role: entry.by === "bot" ? "system" : entry.by, // Map 'bot' to 'system', keep 'user' as is
        content: entry.message,
      };
    });

    // Prepend the context as an initial message by the system
    if (nodeData.context && nodeData.context.trim() !== "") {
      messages.unshift({
        role: "system",
        content: nodeData.context,
      });
    }

    const data = {
      model: nodeData.selectedModel || "gpt-3.5-turbo",
      messages: messages,
      temperature: 0.7, // Adjust temperature if needed
    };
    // Make the API call
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${nodeData.apiKey}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const apiResponseData = await response.json();

    // Process and handle the response from GPT
    const gptResponse = apiResponseData.choices[0].message.content;

    // Display the bot message
    _showTypingAnimationForGivenTime("live_chat", customizations, 1000).then(
      () => {
        _displayBotMessageHelper("live_chat", customizations, gptResponse);
      }
    );

    // Record the user selection
    _pushDataToRecord({
      id: steps[currentIndex].id,
      shape: "gpt-node-response",
      value: gptResponse,
    });

    // Update your chatbot's state or UI with the GPT response
    _handleAddToTranscript("bot", gptResponse);
  } catch (error) {
    console.error("GPT handling failed", error);
  }
};
