import { Tabs, Card } from "antd";
import { useParams, useHistory } from "react-router-dom";
import BackIcon from "@assets/images/components/back.png";
import IntegrationData from "./IntegrationData";
import "@assets/css/Integrations.css";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const IntegrationDetails = () => {
  const params = useParams();
  const history = useHistory();

  const { CardData } = IntegrationData();

  const { t } = useTranslation();

  const Integration = CardData.filter((item, index) => {
    return item.pathName === params.id;
  });

  const tagClasses = {
    starter: "tag-starter",
    business: "tag-business",
    enterprise: "tag-enterprise",
  };

  const renderedTags = Card[0]?.tags?.map((tag, ind) => {
    if (tag) {
      const tagClass = tagClasses[tag.toLowerCase()];
      return (
        <div key={ind} className={`tag ${tagClass}`}>
          {tag}
        </div>
      );
    }
    return null;
  });

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#ffffff",
        height: "100vh",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
      }}
    >
      <div className="integration-heading">
        <div>
          <h1>
            {Integration[0].heading} {t("integrations.integartion")}
          </h1>
          <p>
            {t("integrations.integrate")} {Integration[0].heading}{" "}
            {t("integrations.withConferbot")}
          </p>
        </div>
      </div>

      <span
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#000",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => history.goBack()}
      >
        <img
          src={BackIcon}
          style={{ height: "20px", width: "20px", marginRight: "15px" }}
          alt="back"
        />
        {t("integrations.back")}
      </span>

      <Card
        style={{
          width: "100%",
          marginTop: "30px",
          borderRadius: "10px",
          height: "auto",
          minHeight: "65vh",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
          backgroundColor: "#ffffff",
        }}
      >
        <div className="integration-card-details">
          <div
            style={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <img
              src={Integration[0].Icon}
              style={{
                height: "50px",
                width: "50px",
                marginRight: "28px",
                marginTop: "8px",
                backgroundColor: "transparent",
              }}
              alt="icon"
            />
            <div className="integration-card-details-inner">
              <span>{Integration[0].heading}</span>
              <p>{Integration[0].desc}</p>
              <div className="tags-container">
                {t("integrations.availableOn")}:{renderedTags}
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          <TabPane tab="Description" key="1" className="tabs-desc">
            {t("integrations.appDesc")}
          </TabPane>
          <TabPane tab="Accounts" key="2">
            0 {t("integrations.account")}
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default IntegrationDetails;
