import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Spin,
  Table,
  Input,
  Tag,
  Modal,
  Switch,
  Select,
} from "antd";
import { useSelector } from "react-redux";
import {
  deleteKnowledgeBaseArticleById,
  updateKnowledgeBaseArticleApprovalById,
} from "@api/knowledge-base/knowledgeBase";
import EmptyArticle from "./EmptyArticle";
import { useHistory } from "react-router-dom";
import { SuccessNotification } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ArticlesList = ({
  // Loaders
  initialLoading,
  articlesLoading,
  // Articles
  articles,
  fetchArticles,
  // Chatbots
  chatbots,
  selectedChatbot,
  setSelectedChatbot,
}) => {
  // Redux state
  const { user } = useSelector((state) => state.auth);

  // Local state
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Router
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    // Initialize pagination and search from URL params
    const searchParams = new URLSearchParams(window.location.search);
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const search = searchParams.get("search") || "";

    setSearchText(search);
    setPagination({ current: page, pageSize, total: 0 });
  }, []);

  // Handle chatbot select
  const handleChatbotSelect = (value) => {
    setSelectedChatbot(value);
    setPagination({ ...pagination, current: 1 });
  };

  // Handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    const searchParams = new URLSearchParams();
    searchParams.set("page", page);
    searchParams.set("pageSize", pageSize);
    if (searchText) searchParams.set("search", searchText);

    history.push({ search: searchParams.toString() });
    setPagination({ ...pagination, current: page, pageSize });
  };

  // Function to filter chatbots based on input
  const filterChatbots = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // Handle search
  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    setPagination({ ...pagination, current: 1 });

    const searchParams = new URLSearchParams();
    searchParams.set("page", "1");
    searchParams.set("pageSize", pagination.pageSize);
    if (searchValue) searchParams.set("search", searchValue);
    history.push({ search: searchParams.toString() });
  };

  // Handle article update
  const handleArticleUpdate = (id) => {
    history.push(
      `/a/ws/${user.workspaceId}/knowledge-base/articles/${id}/update`
    );
  };

  // Handle article delete
  const handleArticleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this article?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteKnowledgeBaseArticleById(id);
          SuccessNotification("Article deleted successfully");
          fetchArticles();
        } catch (error) {
          console.error("Error deleting article", error);
        }
      },
    });
  };

  // Handle article approval
  const handleArticleApproval = async (checked, id) => {
    try {
      await updateKnowledgeBaseArticleApprovalById(id, { approved: checked });
      fetchArticles();
    } catch (error) {
      console.error("Error updating article approval", error);
    }
  };

  // Format date
  const formatDate = (date) => {
    if (!date) return "";
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  // Table columns configuration
  const columns = [
    {
      title: t("knowledgeBase.title"),
      dataIndex: "title",
      key: "title",
      render: (title) =>
        title.length > 25 ? `${title.substring(0, 25)}...` : title,
    },
    {
      title: t("knowledgeBase.chatbot"),
      dataIndex: "chatbotId",
      key: "chatbotId",
      render: (chatbot) => <Tag color="blue">{chatbot?.name}</Tag>,
    },
    {
      title: t("knowledgeBase.category"),
      dataIndex: "category",
      key: "category",
      render: (category) => category?.name,
      width: 80,
    },
    {
      title: t("knowledgeBase.author"),
      dataIndex: "author",
      key: "author",
      render: (author) => author?.name,
    },
    {
      title: t("knowledgeBase.publishedAt"),
      dataIndex: "publishedDate",
      key: "publishedDate",
      render: (date) => formatDate(date),
    },
    {
      title: t("knowledgeBase.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => formatDate(date),
    },
    {
      title: t("knowledgeBase.action"),
      key: "action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleArticleUpdate(record._id)}
            className="kb-edit-article"
          >
            {t("knowledgeBase.edit")}
          </Button>
          <Button
            type="danger"
            size="small"
            onClick={() => handleArticleDelete(record._id)}
            className="kb-delete-article"
          >
            {t("knowledgeBase.delete")}
          </Button>
        </div>
      ),
    },
    {
      title: t("knowledgeBase.approved"),
      dataIndex: "approved",
      key: "approved",
      render: (approved, record) => (
        <Switch
          checked={approved}
          onChange={(checked) => handleArticleApproval(checked, record._id)}
        />
      ),
    },
  ];

  // Render loading spinner
  const renderSpinner = (message) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 200px)",
      }}
    >
      <Spin indicator={antIcon} />
      <p style={{ marginTop: "20px" }}>{message}</p>
    </div>
  );

  // Render initial loading state
  if (initialLoading) {
    return (
      <Card
        style={{
          borderRadius: "0",
          height: "100vh",
          overflowY: "auto",
          border: "none",
        }}
      >
        {renderSpinner(t("knowledgeBase.loadingArticles"))}
      </Card>
    );
  }

  return (
    <Card
      style={{
        borderRadius: "0",
        height: "100vh",
        overflowY: "auto",
        border: "none",
      }}
    >
      {/* Header section */}
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h3>{t("knowledgeBase.articlesList")}</h3>
        </Col>
        <Col>
          <Select
            showSearch
            style={{ width: 300, marginRight: 10 }}
            placeholder={t("knowledgeBase.selectChatbot")}
            optionFilterProp="children"
            onChange={handleChatbotSelect}
            filterOption={filterChatbots}
            value={selectedChatbot}
            className="kb-chat-dropdown"
          >
            {chatbots.map((chatbot) => (
              <Option key={chatbot._id} value={chatbot._id}>
                {chatbot.name}
              </Option>
            ))}
          </Select>

          <Search
            placeholder={"Search by title/description"}
            style={{ width: "300px" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            className="kb-articles-search"
          />
        </Col>
      </Row>

      {/* Content section */}
      {articlesLoading ? (
        renderSpinner(t("knowledgeBase.loadingArticles"))
      ) : articles?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={articles}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          loading={articlesLoading}
          onChange={({ current, pageSize }) =>
            handlePaginationChange(current, pageSize)
          }
          rowKey={(record) => record._id}
          scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
          tableLayout="fixed"
          className="kb-articles-table"
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 200px)",
          }}
        >
          <EmptyArticle />
        </div>
      )}
    </Card>
  );
};

export default ArticlesList;
