import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateMemberStatusAction } from "@redux/actions/memberActions";
import { FaAngleDown } from "react-icons/fa";

const AgentsList = ({ agentFetchLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { teamList } = useSelector((state) => state.member);

  // Function to handle status change (Placeholder function)
  const handleStatusChange = (agentId, status, e) => {
    e.domEvent.stopPropagation(); // Prevent click event from propagating to the parent elements
    dispatch(updateMemberStatusAction(agentId, status));
  };

  // Menu for status selection
  const statusMenu = (agentId) => (
    <Menu onClick={(e) => handleStatusChange(agentId, e.key, e)}>
      <Menu.Item key="available">
        {t("chatMessages.chatList.status.available")}
      </Menu.Item>
      <Menu.Item key="away">{t("chatMessages.chatList.status.away")}</Menu.Item>
      <Menu.Item key="offline">
        {t("chatMessages.chatList.status.offline")}
      </Menu.Item>
    </Menu>
  );

  const getStatusColor = (agent) => {
    if (agent.isEngaged) return "#ff0000";
    if (agent.isOnline) return "#17e600";
    return "#ffd724";
  };

  const getStatusText = (agent) => {
    if (agent.isEngaged) return t("chatMessages.chatList.status.engaged");
    if (agent.isOnline) return t("chatMessages.chatList.status.available");
    return t("chatMessages.chatList.status.offline");
  };

  const renderAgentInitials = (agent) => {
    const nameParts = agent.name.split(" ");
    const initials =
      nameParts.length > 1
        ? nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase()
        : nameParts[0][0].toUpperCase();

    return (
      <Button
        icon={initials}
        type="text"
        size="small"
        disabled
        style={{
          backgroundColor: "#0b4eb8",
          color: "#fff",
          marginRight: "10px",
          borderRadius: "50%",
        }}
      />
    );
  };

  return (
    <>
      <div className="agents-list-container">
        {teamList?.length > 0 ? (
          teamList.map((agent, index) => (
            <div key={index} className="agent-item">
              {/* Initials and Online Indicator */}
              <div className="initials-and-indicator">
                <div
                  className="online-indicator"
                  style={{
                    backgroundColor: getStatusColor(agent),
                    borderRadius: "50%",
                    width: "10px",
                    height: "10px",
                    marginRight: "10px",
                  }}
                />
                {/* Agent Initials */}
                {renderAgentInitials(agent)}
              </div>
              {/* Name, Email & Optionally Status Dropdown */}
              <div>
                <div>{agent.name}</div>
                <div style={{ fontSize: "0.65rem" }}>{agent.email}</div>
                {user._id === agent._id ? ( // Only show for the logged-in user
                  <Dropdown overlay={statusMenu(agent._id)} trigger={["click"]}>
                    <button
                      className="ant-dropdown-link"
                      onClick={(e) => e.stopPropagation()} // Prevent opening the dropdown from clicking the agent item
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "#0b4eb8",
                        fontSize: "0.7rem",
                        marginTop: "0",
                        padding: "2px",
                      }}
                    >
                      {getStatusText(agent)} <FaAngleDown />
                    </button>
                  </Dropdown>
                ) : (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#0b4eb8",
                      fontSize: "0.7rem",
                      marginTop: "0",
                      padding: "2px",
                    }}
                  >
                    {getStatusText(agent)}
                  </button>
                )}
              </div>
            </div>
          ))
        ) : !agentFetchLoading && teamList?.length === 0 ? (
          <h3
            style={{
              height: "calc(100vh - 220px)",
              marginTop: "1.5rem",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            {t("chatMessages.chatList.noAgentsFound")}
          </h3>
        ) : null}
      </div>
    </>
  );
};

export default AgentsList;
