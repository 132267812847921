import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, message } from "antd";
import { ApiKeys, Notifications, Usage } from "@components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const Workspace = () => {
  const { user } = useSelector((state: any) => state.auth);
  const [activeTab, setActiveTab] = useState("api");
  const history = useHistory();
  const { t } = useTranslation();

  // Get the url
  const url = window.location.pathname;
  const segments = url.split("/"); // Split the URL by "/"
  const lastSlug = segments.pop();

  useEffect(() => {
    // Set the active tab based on the last slug from URL
    if (lastSlug === "api") {
      setActiveTab("api");
    } else if (lastSlug === "notifications") {
      setActiveTab("notifications");
    } else if (lastSlug === "usage") {
      setActiveTab("usage");
    }
  }, [lastSlug]);

  // Handle tab change
  const handleTabChange = (key: any) => {
    if (key === "api") {
      history.push(`/a/ws/${user?.workspaceId}/api`);
    } else if (key === "notifications") {
      history.push(`/a/ws/${user?.workspaceId}/notifications`);
    } else {
      message.info("Coming soon");
      history.push(`/a/ws/${user?.workspaceId}/usage`);
    }

    // Set the active tab
    setActiveTab(key);
  };

  return (
    <Row
      style={{
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
      }}
    >
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        style={{
          background: "rgb(255, 255, 255)",
          height: "100vh",
          boxShadow: "0px 0px 15px -12px rgba(0,0,0,0.75)",
          padding: "20px",
        }}
      >
        <h1
          style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#2c3e50" }}
        >
          {t("workSpace.workSpace")}
        </h1>

        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {/* API Keys */}
          <TabPane
            tab={t("workSpace.apiKeys")}
            key="api"
            style={{
              padding: "20px 0 0 0",
              background: "#fff",
              borderRadius: "5px",
            }}
          >
            <ApiKeys />
          </TabPane>

          {/* Notifications */}
          <TabPane tab={t("workSpace.notifications")} key="notifications">
            <Notifications />
          </TabPane>

          {/* Usage */}
          <TabPane tab={t("workSpace.usage")} key="usage">
            <Usage />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Workspace;
