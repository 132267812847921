import { useState } from "react";
import { Button } from "antd";
import { MdClose } from "react-icons/md";

import NodeTypeRenderer from "./nodeTypeRenderer";

import {
  MessageIcon,
  AskIcon,
  FlowIcon,
  LogicIcon,
  SwitchIcon,
  PowerIcon,
} from "../../../../../../assets/images/pages/builder";

import SidebarOptions from "./options";
import { useTranslation } from "react-i18next";

const SidebarNodeOptions: any = () => {
  const [activeNodesPanel, setActiveNodesPanel] = useState<string | null>(null);

  const { t } = useTranslation();

  // ----- Sidebar Options -----
  const {
    SendResponseNodes,
    AskQuestionNodes,
    FlowOperations,
    LogicalNodes,
    SpecialNodes,
    IntegrationNodes,
  } = SidebarOptions();

  const flowSidebarOptions = [
    // ** SEND RESPONSE NODES **
    {
      name: t("flowBuilder.sidebar.sendResponse"),
      slug: "SendResponse",
      desc: t("flowBuilder.sidebar.sendResponseDesc"),
      icon: MessageIcon,
    },
    // ** ASK QUESTION NODES **
    {
      name: t("flowBuilder.sidebar.askQuestion"),
      slug: "AskQuestion",
      desc: t("flowBuilder.sidebar.askQuestionDesc"),
      icon: AskIcon,
    },
    // ** FLOW OPERATION NODES **
    {
      name: t("flowBuilder.sidebar.flowOperations"),
      slug: "FlowOperations",
      desc: t("flowBuilder.sidebar.flowOperationsDesc"),
      icon: FlowIcon,
    },
    // ** LOGICAL NODES **
    {
      name: t("flowBuilder.sidebar.logical"),
      slug: "Logical",
      desc: t("flowBuilder.sidebar.logicalDesc"),
      icon: LogicIcon,
    },
    // ** SPECIAL NODES **
    {
      name: t("flowBuilder.sidebar.special"),
      slug: "Special",
      desc: t("flowBuilder.sidebar.specialDesc"),
      icon: PowerIcon,
    },
    // ** INTEGRATIONS **
    {
      name: t("flowBuilder.sidebar.integrations"),
      slug: "Integrations",
      desc: t("flowBuilder.sidebar.integrationsDesc"),
      icon: SwitchIcon,
    },
  ];

  // Handle option click
  const handleOptionClick = (slug: any) => {
    setActiveNodesPanel(slug);
  };

  // Handle close button click
  const handleCloseButtonClick = () => {
    setActiveNodesPanel(null);
  };

  return (
    <div style={{ width: "100%" }}>
      <ul className="nodes-main-options">
        {/* Base Sidebar Options */}
        {flowSidebarOptions.map((option, index) => (
          <li
            key={index}
            onClick={() => handleOptionClick(option.slug)}
            style={{ backgroundColor: "#fff" }}
          >
            <div className="nodes-options-inner">
              <img
                src={option.icon}
                style={{ height: "27px", width: "27px", marginRight: "15px" }}
                alt="layout icon"
              />
              <div>
                <h3 className="sidebar-label">{option.name}</h3>
                <p className="sidebar-desc">{option.desc}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/* ------------- SELECTED ITEM ------------- */}
      {/* Send a response nodes */}
      {activeNodesPanel === "SendResponse" && (
        <div className="nodes-option-content">
          <div className="nodes-options-header">
            <h5>{t("flowBuilder.sidebar.sendResponse")}</h5>
            <Button
              type="text"
              icon={<MdClose size={18} />}
              onClick={handleCloseButtonClick}
              size="small"
            />
          </div>
          <div
            style={{
              height: "calc(100vh - 115px)",
              overflowY: "auto",
              width: "100%",
              padding: "15px 12px",
            }}
          >
            {SendResponseNodes.map((node: any) => (
              <NodeTypeRenderer key={node.id} {...node} />
            ))}
          </div>
        </div>
      )}

      {/* Ask a question nodes */}
      {activeNodesPanel === "AskQuestion" && (
        <div className="nodes-option-content">
          <div className="nodes-options-header">
            <h5>{t("flowBuilder.sidebar.askQuestion")}</h5>
            <Button
              type="text"
              icon={<MdClose size={18} />}
              onClick={handleCloseButtonClick}
              size="small"
            />
          </div>
          <div
            style={{
              height: "calc(100vh - 115px)",
              overflowY: "auto",
              width: "100%",
              padding: "15px 12px",
            }}
          >
            {AskQuestionNodes.map((node: any) => (
              <NodeTypeRenderer key={node.id} {...node} />
            ))}
          </div>
        </div>
      )}

      {/* Flow Operations nodes */}
      {activeNodesPanel === "FlowOperations" && (
        <div className="nodes-option-content">
          <div className="nodes-options-header">
            <h5>{t("flowBuilder.sidebar.flowOperations")}</h5>
            <Button
              type="text"
              icon={<MdClose size={18} />}
              onClick={handleCloseButtonClick}
              size="small"
            />
          </div>
          <div
            style={{
              height: "calc(100vh - 115px)",
              overflowY: "auto",
              width: "100%",
              padding: "15px 12px",
            }}
          >
            {FlowOperations.map((node: any) => (
              <NodeTypeRenderer key={node.id} {...node} />
            ))}
          </div>
        </div>
      )}

      {/* Logical nodes */}
      {activeNodesPanel === "Logical" && (
        <div className="nodes-option-content">
          <div className="nodes-options-header">
            <h5>{t("flowBuilder.sidebar.logical")}</h5>
            <Button
              type="text"
              icon={<MdClose size={18} />}
              onClick={handleCloseButtonClick}
              size="small"
            />
          </div>
          <div
            style={{
              height: "calc(100vh - 115px)",
              overflowY: "auto",
              width: "100%",
              padding: "15px 12px",
            }}
          >
            {LogicalNodes.map((node: any) => (
              <NodeTypeRenderer key={node.id} {...node} />
            ))}
          </div>
        </div>
      )}

      {/* Special nodes */}
      {activeNodesPanel === "Special" && (
        <div className="nodes-option-content">
          <div className="nodes-options-header">
            <h5>{t("flowBuilder.sidebar.special")}</h5>
            <Button
              type="text"
              icon={<MdClose size={18} />}
              onClick={handleCloseButtonClick}
              size="small"
            />
          </div>
          <div
            style={{
              height: "calc(100vh - 115px)",
              overflowY: "auto",
              width: "100%",
              padding: "15px 12px",
            }}
          >
            {SpecialNodes.map((node: any) => (
              <NodeTypeRenderer key={node.id} {...node} />
            ))}
          </div>
        </div>
      )}

      {/* Integration nodes */}
      {activeNodesPanel === "Integrations" && (
        <div className="nodes-option-content">
          <div className="nodes-options-header">
            <h5>{t("flowBuilder.sidebar.integrations")}</h5>
            <Button
              type="text"
              icon={<MdClose size={18} />}
              onClick={handleCloseButtonClick}
              size="small"
            />
          </div>
          <div
            style={{
              height: "calc(100vh - 115px)",
              overflowY: "auto",
              width: "100%",
              padding: "15px 12px",
            }}
          >
            {IntegrationNodes.map((node: any) => (
              <NodeTypeRenderer key={node.id} {...node} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarNodeOptions;
