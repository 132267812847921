import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { BsPersonLinesFill } from "react-icons/bs";
import { useSelector } from "react-redux";

const LineChart = () => {
  const { analytics } = useSelector((state) => state.admin);

  const { t } = useTranslation();

  // Extract the month-wise chat data from analytics.monthWiseChats
  const monthWiseChats = analytics.monthWiseChats || [];

  // Create an object to store the chat counts for each month
  const chatCountsByMonth = {};

  // Iterate over the monthWiseChats array and populate the chatCountsByMonth object
  monthWiseChats.forEach((chat) => {
    const { year, month, count } = chat;
    const monthName = new Date(year, month - 1).toLocaleString("default", {
      month: "short",
    });
    chatCountsByMonth[monthName] = count;
  });

  // Generate the categories array based on the current month
  const currentMonth = new Date().getMonth();
  const categories = Array.from({ length: 12 }, (_, index) => {
    const monthIndex = (currentMonth - index + 12) % 12;
    return new Date(0, monthIndex).toLocaleString("default", {
      month: "short",
    });
  }).reverse();

  // Generate the data array based on the chatCountsByMonth object
  const data = categories.map((month) => chatCountsByMonth[month] || 0);

  const chartData = {
    series: [
      {
        name: t("dashboard.liveVisitors"),
        data: data,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: categories,
      },
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0px 20px",
        }}
      >
        <div>
          <BsPersonLinesFill
            style={{
              fontSize: "24px",
              color: "#0b4eb8",
              marginRight: "10px",
            }}
          />
        </div>
        <h5
          style={{
            color: "#000",
            fontWeight: "600",
            fontSize: "18px",
            marginTop: "5px",
          }}
        >
          {t("dashboard.monthWiseChats")}
        </h5>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={250}
      />
    </div>
  );
};

export default LineChart;
