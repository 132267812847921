import { FC, useState, useEffect } from "react";
import { Row, Col, Input } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { QuizNodeData } from "../../typings";
import useStore from "../../../../store/store";
import ContextMenu from "../../../components/contextMenu/ContextMenu";
import { Quiz } from "@assets/images/pages/builder";

const QuizNode: FC<NodeProps<QuizNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);

  const { getNode } = useStore((state: any) => ({
    getNode: state?.getNode,
  }));

  const node = getNode(id);

  useEffect(() => {
    const getNodeId = node?.id;
    setNodeId(getNodeId);
  }, []);

  if (!node) {
    return null; // or some fallback component
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />
      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "250px",
          maxWidth: "250px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={16}
            align="middle"
            style={{ padding: "0px 0px 0px 8px" }}
          >
            <Col
              span={18}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                alt="Quiz"
                src={Quiz}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
              />
              Quiz Node
            </Col>

            {/* Delete & Duplicate Node */}
            <Col span={6}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{ __html: node?.data?.quizPrompt }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Options
          </Col>

          <Col span={24}>
            <Input
              size="small"
              value={node?.data?.option1}
              disabled
              style={{
                color: "black",
                cursor: "pointer",
                marginTop: "3px",
                borderRadius: "3px",
                // If correct answer is option, background is light green else transparent
                background:
                  node?.data?.correctAnswer === "option1" ? "#d4edda" : "#fff",
                fontSize: "16px",
                textAlign: "center",
              }}
            />
          </Col>

          <Col span={24}>
            <Input
              size="small"
              value={node?.data?.option2}
              disabled
              style={{
                color: "black",
                cursor: "pointer",
                marginTop: "3px",
                borderRadius: "3px",
                // If correct answer is option, background is light green else transparent
                background:
                  node?.data?.correctAnswer === "option2" ? "#d4edda" : "#fff",
                fontSize: "16px",
                textAlign: "center",
              }}
            />
          </Col>

          {!node?.data?.disableOption3 && (
            <Col span={24}>
              <Input
                size="small"
                value={node?.data?.option3}
                disabled
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginTop: "3px",
                  borderRadius: "3px",
                  // If correct answer is option, background is light green else transparent
                  background:
                    node?.data?.correctAnswer === "option3"
                      ? "#d4edda"
                      : "#fff",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              />
            </Col>
          )}

          {!node?.data?.disableOption4 && (
            <Col span={24}>
              <Input
                size="small"
                value={node?.data?.option4}
                disabled
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginTop: "3px",
                  borderRadius: "3px",
                  // If correct answer is option, background is light green else transparent
                  background:
                    node?.data?.correctAnswer === "option4"
                      ? "#d4edda"
                      : "#fff",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              />
            </Col>
          )}

          {!node?.data?.disableOption5 && (
            <Col span={24}>
              <Input
                size="small"
                value={node?.data?.option5}
                disabled
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginTop: "3px",
                  borderRadius: "3px",
                  // If correct answer is option, background is light green else transparent
                  background:
                    node?.data?.correctAnswer === "option5"
                      ? "#d4edda"
                      : "#fff",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              />
            </Col>
          )}
        </div>
      </Col>
      {Object?.keys(node?.ports).map((handleId, index) => (
        <Handle
          key={index}
          type="source"
          position={Position.Right}
          id={handleId}
          style={{ top: `${index === 0 ? "68%" : "88%"}` }}
          className={index === 0 ? "node-source-wrong" : "node-source-correct"}
        />
      ))}
    </>
  );
};

export default QuizNode;
