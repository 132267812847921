import React, { useRef, useState } from "react";
import { Radio, Slider, Spin, Switch, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
// Widget Bubble Icons
import {
  WidgetBubbleIcon1,
  WidgetBubbleIcon2,
  WidgetBubbleIcon3,
  WidgetBubbleIcon4,
  WidgetBubbleIcon5,
  WidgetBubbleIcon6,
  WidgetBubbleIcon7,
  WidgetBubbleIcon8,
  WidgetBubbleIcon9,
  WidgetBubbleIcon10,
  WidgetBubbleIcon11,
  WidgetBubbleIcon12,
  WidgetBubbleIcon13,
  WidgetBubbleIcon14,
  WidgetBubbleIcon15,
} from "@assets/svg";
import shapesIcon from "@assets/images/pages/customize/shapes.png";
import Arrow from "@assets/images/pages/customize/down.png";
import DeleteIcon from "@assets/images/pages/customize/bin.png";
import { useTranslation } from "react-i18next";

const WidgetIconsAndShape = () => {
  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState("Icons");
  const [selectedIconName, setSelectedIconName] = useState(
    customizations?.widgetIconActive
  );
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(null);
  const [borderRadiusOn, setBorderRadiusOn] = useState(false);
  const widgetLogoImageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Icon and Shape Options
  const options = [
    {
      label: "Icons",
      value: "Icons",
    },
    {
      label: "Upload",
      value: "Upload",
    },
    {
      label: "Shape",
      value: "Shape",
    },
  ];

  // widget Bubble Icons
  const widgetIcons = [
    { name: "WidgetBubbleIcon1", icon: <WidgetBubbleIcon1 /> },
    { name: "WidgetBubbleIcon2", icon: <WidgetBubbleIcon2 /> },
    { name: "WidgetBubbleIcon3", icon: <WidgetBubbleIcon3 /> },
    { name: "WidgetBubbleIcon4", icon: <WidgetBubbleIcon4 /> },
    { name: "WidgetBubbleIcon5", icon: <WidgetBubbleIcon5 /> },
    { name: "WidgetBubbleIcon6", icon: <WidgetBubbleIcon6 /> },
    { name: "WidgetBubbleIcon7", icon: <WidgetBubbleIcon7 /> },
    { name: "WidgetBubbleIcon8", icon: <WidgetBubbleIcon8 /> },
    { name: "WidgetBubbleIcon9", icon: <WidgetBubbleIcon9 /> },
    { name: "WidgetBubbleIcon10", icon: <WidgetBubbleIcon10 /> },
    { name: "WidgetBubbleIcon11", icon: <WidgetBubbleIcon11 /> },
    { name: "WidgetBubbleIcon12", icon: <WidgetBubbleIcon12 /> },
    { name: "WidgetBubbleIcon13", icon: <WidgetBubbleIcon13 /> },
    { name: "WidgetBubbleIcon14", icon: <WidgetBubbleIcon14 /> },
    { name: "WidgetBubbleIcon15", icon: <WidgetBubbleIcon15 /> },
  ];

  // Widget Shape
  const widgetShapeIcons = [
    "circle-shape",
    "roundedSquare-shape",
    "square-shape",
  ];

  // Spinner indicator
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 35,
      }}
      spin
    />
  );

  //  Toggle Icons & Shape Button
  const handleTabsToggle = ({ target: { value } }) => {
    setChecked(value);

    // If value is Icons or Upload then set widgetIconType to Icon or Image
    if (value === "Icons" || value === "Upload") {
      dispatch(
        updateCurrentChatbotCustomizationsAction(
          bid,
          "widgetIconType",
          value === "Icons" ? "Icon" : "Image"
        )
      );
    }
  };

  // Handle Select Icon
  const handleSelectIcon = (iconName) => {
    setSelectedIconName(iconName); // Assuming this state now holds the name, not the index

    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "widgetIconSVG", iconName)
    );
  };

  // Change Select Shape
  const changeSelectShape = (value, index) => {
    setSelectedShapeIndex(index);

    // Default border radius is a circle
    let borderRadius = 50;
    // If shape is "circle-shape", border radius will be 50%, wid, "roundedSquare-shape" : 20px, "square-shape": 10px,
    if (value === "circle-shape") {
      borderRadius = 50;
    } else if (value === "roundedSquare-shape") {
      borderRadius = 20;
    } else if (value === "square-shape") {
      borderRadius = 10;
    }

    // Update widgetBorderRadius
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetBorderRadius",
        borderRadius
      )
    );
  };

  // Handle Toggle Border Radius
  const handleToggleBorderRadius = () => {
    setBorderRadiusOn(!borderRadiusOn);
  };

  // Handle Change Border Radius
  const handleChangeBorderRadius = (value) => {
    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "widgetBorderRadius", value)
    );
  };

  // handle Change Widget Logo
  const handleChangeWidgetLogo = async (event) => {
    const file = event.target.files[0];
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("image", file);
    // Make request to upload image
    try {
      setLoading(true);

      // Upload image
      await uploadChatbotMedia(bid, fmData, config)
        .then((res) => {
          dispatch(
            updateCurrentChatbotCustomizationsAction(
              bid,
              "widgetIconImage",
              res.data.url
            )
          );
          setLoading(false);
          message.success("Widget logo updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Error updating widget logo");
        });
    } catch (error) {
      setLoading(false);
      message.error("Error updating widget logo");
    }
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => {
            setVisible(!visible);
          }}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={shapesIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="position-icon"
            />
            {t("customize.widget.icons&Shape")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            style={{
              height: "20px",
              width: "20px",
              marginLeft: "auto",
            }}
            alt="arrow-icon"
          />
        </div>

        {visible && (
          <>
            <div style={{ padding: "20px 25px", backgroundColor: "#d5d7e4" }}>
              {/* Icons & Shape Tab Buttons */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "12px 0px 15px 0px",
                }}
              >
                <Radio.Group
                  options={options}
                  onChange={handleTabsToggle}
                  value={checked}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>

              {/* Icons */}
              {checked === "Icons" && (
                <>
                  <div className="widgetIcons_Wrapper">
                    {widgetIcons?.map((iconObj, index) => {
                      return (
                        <div
                          key={index}
                          className="widget_icon"
                          style={{
                            background:
                              (customizations?.widgetIconThemeType ===
                                "Solid" &&
                                customizations?.widgetIconBgColor) ||
                              (customizations?.widgetIconThemeType ===
                                "Gradient" &&
                                `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
                            border:
                              selectedIconName === iconObj.name &&
                              "3px solid #fff",
                            transform:
                              selectedIconName === iconObj.name
                                ? "scale(1.2)"
                                : "scale(1)",
                          }}
                          onClick={() => handleSelectIcon(iconObj.name)}
                        >
                          {iconObj.icon}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {/* Widget Upload */}
              {checked === "Upload" && (
                <div>
                  {/* Widget Logo Image */}
                  <label
                    htmlFor="file-upload"
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <button className="general-input-btn" htmlFor="file-upload">
                      {t("customize.widget.uploadImage/GIF")}
                      <input
                        id="file-upload"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => handleChangeWidgetLogo(e)}
                        ref={widgetLogoImageRef}
                      />
                    </button>
                  </label>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <Spin indicator={antIcon} size="large" />
                      </div>
                    ) : (
                      <>
                        {customizations?.widgetIconImage !== "" && (
                          <>
                            <img
                              src={customizations?.widgetIconImage}
                              style={{ height: "80px", width: "80px" }}
                              alt="widget-icon"
                            />
                            <img
                              onClick={() => {
                                dispatch(
                                  updateCurrentChatbotCustomizationsAction(
                                    bid,
                                    "widgetIconImage",
                                    ""
                                  )
                                );

                                // Reset the file input
                                if (widgetLogoImageRef.current) {
                                  widgetLogoImageRef.current.value = "";
                                }

                                message.success(
                                  "Widget logo deleted successfully"
                                );
                              }}
                              src={DeleteIcon}
                              style={{
                                marginTop: "-30px",
                                cursor: "pointer",
                              }}
                              alt="delete-icon"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* Shape */}
              {checked === "Shape" && (
                <>
                  <div className="widget_shape_icons_Wrapper">
                    {widgetShapeIcons?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`widget_shape_icon ${item}`}
                          style={{
                            background:
                              (customizations?.widgetIconThemeType ===
                                "Solid" &&
                                customizations?.widgetIconBgColor) ||
                              (customizations?.widgetIconThemeType ===
                                "Gradient" &&
                                `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
                            border:
                              selectedShapeIndex === index && "3px solid #fff",
                            transform:
                              selectedShapeIndex === index
                                ? "scale(1.2)"
                                : "scale(1)",
                            cursor: borderRadiusOn ? "not-allowed" : "pointer",
                          }}
                          onClick={() =>
                            !borderRadiusOn && changeSelectShape(item, index)
                          }
                        >
                          {customizations?.widgetIconSVG ? (
                            widgetIcons.map((icon) => {
                              if (icon.name === customizations?.widgetIconSVG) {
                                return icon.icon;
                              }
                              return null;
                            })
                          ) : (
                            <svg
                              className="open-svg"
                              id="open-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 25"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M21.5 18C21.5 18 20.5 18.5 20.5 20.1453V21.2858V22.5287V23.3572C20.5 24.131 20.0184 24.1046 19.3517 23.7118L18.75 23.3572L13.5 20C12.8174 19.6587 12.6007 19.5504 12.3729 19.516C12.267 19.5 12.1587 19.5 12 19.5H7.5C2.5 19.5 0 17.5 0 12.5V7.5C0 2.5 2.5 0 7.5 0H16.5C21.5 0 24 2.5 24 7.5V12.5C24 17.5 21.5 18 21.5 18ZM21 17.557C21.8581 17.557 24 13.557 23 13.057C22.3869 12.7505 21.8801 13.7414 21.4646 14.554C21.2023 15.0668 20.9764 15.5086 20.783 15.5086C20.283 15.5086 20 16.0554 20 16.7568C20 17.4582 20.1419 17.557 21 17.557Z"
                                fill="white"
                                opacity="1"
                              ></path>
                            </svg>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {/* Custom Border Radius */}
                  <div
                    onClick={handleToggleBorderRadius}
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span> {t("customize.widget.customBorderRadius")}</span>
                    <Switch checked={borderRadiusOn} size="small" />
                  </div>
                  {/* Slider */}
                  {borderRadiusOn && (
                    <div style={{ marginTop: "20px" }}>
                      <Slider
                        range={false}
                        step={1}
                        defaultValue={customizations?.widgetBorderRadius}
                        marks={{ 0: "0", 30: "30" }}
                        max={30}
                        onChange={handleChangeBorderRadius}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WidgetIconsAndShape;
