// -------------- Chatbot builder joyride -------------------
export const chatbotBuilderJoyrideSteps = [
  {
    attachTo: { on: "center" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            color: "#000",
            textAlign: "center",
          }}
        >
          Welcome to the Flow Builder!
        </h2>
        <video
          src="https://s3.ap-south-1.amazonaws.com/conferbot.defaults/onboarding/choose-your-node.mp4"
          autoPlay
          loop
          muted
          playsInline
          style={{ width: "100%", height: "auto" }}
        />

        <br />
        <br />
        <p
          style={{
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          Choose from a variety of nodes on the left panel to build your
          conversational flow
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 650,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Sidebar (Nodes Panel)
  {
    attachTo: { element: "#nodes-panel", on: "right" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h3 style={{ color: "#000" }}>Nodes Panel</h3>

        <video
          src="https://s3.ap-south-1.amazonaws.com/conferbot.defaults/onboarding/build-flow.mp4"
          autoPlay
          loop
          muted
          playsInline
          style={{ width: "100%", height: "auto" }}
        />

        <br />
        <br />
        <p
          style={{
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          Build your conversational flow by
          <b> dragging and dropping</b> nodes into the editor and{" "}
          <b>connecting them together</b>
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 600,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Click on a node
  {
    attachTo: { element: "#welcome-node", on: "right" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h3 style={{ color: "#000" }}>Edit Node</h3>

        <video
          src="https://s3.ap-south-1.amazonaws.com/conferbot.defaults/onboarding/click-to-edit.mp4"
          autoPlay
          loop
          muted
          playsInline
          style={{ width: "100%", height: "auto" }}
        />

        <br />
        <br />

        <p
          style={{
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          You can then <b>edit the properties</b> of the node by{" "}
          <b>clicking on it</b>
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 600,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Flow preview
  {
    attachTo: { element: "#flow-preview", on: "bottom" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <h3 style={{ color: "#000" }}>Flow Preview</h3>
        <p>Click on this button to preview the flow you have created.</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 50,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Save flow
  {
    attachTo: { element: "#save-flow", on: "bottom" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <h3 style={{ color: "#000" }}>Save Flow</h3>
        <p>To save your current chat flow, click on this button at any time.</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Publish flow
  {
    attachTo: { element: "#publish-flow", on: "bottom" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <h3 style={{ color: "#000" }}>Publish Flow</h3>
        <p>
          Once you have created the flow, you can publish it by clicking on this
          button.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Customize
  {
    attachTo: { element: "#bot-customize", on: "bottom" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <h3>Customize Bot</h3>
        <p>
          Once you have created the flow, you can customize your bot by clicking
          on this button.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Share
  {
    attachTo: { element: "#bot-share", on: "bottom" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <h3>Share/Embed Bot</h3>
        <p>
          Once you have created the flow, you can share your bot by clicking on
          this button.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // Analytics
  {
    attachTo: { element: "#bot-analytics", on: "bottom" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <h3>Analytics</h3>
        <p>
          You can view the analytics of your bot by clicking on this button.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: false,
    locale: {
      last: "Sure thing!",
    },
  },
];
