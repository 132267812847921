import React from "react";

const BlocksTwo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M19 5h-4.621h.029h-.023zm-5.615.615q0-.69.462-1.152Q14.31 4 15 4h3.385q.69 0 1.152.463q.463.462.463 1.152v8.27q0 .213-.143.356q-.144.144-.357.144t-.357-.144t-.143-.356v-8.27q0-.269-.173-.442T18.385 5H15q-.27 0-.442.173t-.173.442v14.231q-.46-.154-.73-.549q-.27-.395-.27-.912zM5.615 20q-.69 0-1.152-.462Q4 19.075 4 18.385V5.615q0-.69.463-1.152Q4.925 4 5.615 4H9.02q.69 0 1.153.463t.463 1.152v12.77q0 .69-.463 1.152Q9.71 20 9.02 20zm4.02-14.385q0-.269-.173-.442T9.019 5H5.615q-.269 0-.442.173T5 5.615v12.77q0 .269.173.442t.442.173H9.02q.27 0 .443-.173t.173-.442zm0-.615H5zM19 20h-1.73q-.214 0-.358-.143q-.143-.144-.143-.357t.144-.357t.356-.143H19v-1.73q0-.214.143-.358t.357-.143q.213 0 .357.144q.143.143.143.356V19h1.73q.214 0 .358.143t.143.357q0 .213-.144.357q-.143.143-.356.143H20v1.73q0 .214-.143.358q-.144.143-.357.143t-.357-.143Q19 21.944 19 21.73z"
      />
    </svg>
  );
};

export default BlocksTwo;
