import { useState, FC, useCallback } from "react";
import { Row, Col, Upload, message, Checkbox, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { RiLoaderLine, RiAddLine } from "react-icons/ri";
import { useParams } from "react-router";
import { WelcomeNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
import { useTranslation } from "react-i18next";

const WelcomeNodeDataEditor: FC<WelcomeNode> = ({ id, type }) => {
  const { bid } = useParams<{ bid: string }>();
  const { getNode, changeNodeData } = useStore(selector, shallow);

  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(state?.data?.text);
  const [disableImage, setDisableImage] = useState(state?.data?.disableImage);

  const handleWelcomeMessageChange = useCallback(
    (content: any) => {
      if (!state) return;

      setWelcomeMessage(content);

      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, text: content },
      });
    },
    [changeNodeData, state, type]
  );

  // Handle disable image change
  const handleDisableImage = (e: any) => {
    setDisableImage(e.target.checked);

    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, disableImage: e.target.checked },
    });
  };

  // Handle welcome image upload
  const beforeUpload = (file: any) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng =
      file?.type === "image/jpeg" ||
      file?.type === "image/png" ||
      file?.type === "image/gif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG/GIF file!");
    }
    const isLt4M = file?.size / 1024 / 1024 < 4;
    if (!isLt4M) {
      message.error("Image/GIF must smaller than 4MB!");
    }
    return isJpgOrPng && isLt4M;
  };

  // Handle upload image
  const handleUploadImage = async (options: any) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData?.append("image", file);
    // Make request to upload image

    try {
      setLoading(true);
      await uploadChatbotMedia(bid, fmData, config)
        .then((res: any) => {
          changeNodeData({
            ...state,
            type,
            data: { ...state?.data, image: res?.data?.url },
          });
          setLoading(false);
          message.success("Welcome media updated successfully");
        })
        .catch((err: any) => {
          onError(err);
          setLoading(false);
          message.error("Error updating media");
        });
    } catch (error) {
      onError(error);
    }
  };

  const uploadButton = (
    <div style={{ width: "100%", borderRadius: "15px" }}>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>
        {" "}
        {t("flowBuilder.sidebar.editor.welcomeNode.upload")}
      </div>
    </div>
  );

  return (
    <>
      {/* Node Message */}
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.welcomeNode.welcomeNodeInfo")}
          </p>
        </Row>

        {/* User Prompt */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.welcomeNode.message")}
          </Col>
        </Divider>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={welcomeMessage}
              onEditorChange={handleWelcomeMessageChange}
            />
          </div>
        </Col>
      </Row>

      {/* 2 Line Space */}
      <p>&nbsp;</p>

      {/* Radio Button to disable image/gif */}
      <Row
        style={{
          marginTop: "5px",
        }}
      >
        <Col span={16}>
          <p className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.welcomeNode.disableImage/GIF")}
          </p>
        </Col>
        <Col span={2}>
          <Checkbox
            checked={disableImage}
            onChange={(e) => handleDisableImage(e)}
          />
        </Col>
      </Row>

      {/* 1 Line space */}
      <div
        style={{
          height: "15px",
          width: "100%",
        }}
      />

      {/* Node Image */}
      {!disableImage && (
        <Row align="middle">
          <Col
            span={8}
            style={{
              marginTop: "14px",
            }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.welcomeNode.gif/Image")}
          </Col>

          <Col span={16}>
            <Upload
              name="welcome-image"
              listType="picture-card"
              customRequest={handleUploadImage}
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              multiple={false}
              accept="image/*,image/gif"
            >
              {state?.data?.image ? (
                <img
                  src={state?.data?.image}
                  alt="welcome-media"
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
        </Row>
      )}
    </>
  );
};

export default WelcomeNodeDataEditor;
