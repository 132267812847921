import api from "../index";

// ** --------- AI App API --------- ** //
// Create a chatbot-template-blog
export const createChatbotTemplateBlog = (values: any) => {
  return api.post("/chatbot-template-blog", values);
};

// Get all chatbot-template-blogs
export const getAllChatbotTemplateBlogs = () => {
  return api.get("/chatbot-template-blogs");
};

// Get all chatbot-template-blogs paginated search
export const getAllChatbotTemplateBlogsPaginatedSearch = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(
      `/chatbot-template-blogs/paginated/search?page=${page}&perPage=${perPage}`
    );
  } else {
    return await api.get(
      `/chatbot-template-blogs/paginated/search?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get a chatbot-template-blog by ID
export const getChatbotTemplateBlogById = (id: any) => {
  return api.get(`/chatbot-template-blog/${id}`);
};

// Update a chatbot-template-blog by ID
export const updateChatbotTemplateBlogById = (id: any, values: any) => {
  return api.put(`/chatbot-template-blog/${id}`, values);
};

// Delete a chatbot-template-blog by ID
export const deleteChatbotTemplateBlogById = (id: any) => {
  return api.delete(`/chatbot-template-blog/${id}`);
};

// Upload chatbot-template-blog cover image
export const uploadChatbotTemplateBlogCoverImage = async (
  values: any,
  config: any
) => {
  return await api.post(`/chatbot-template-blog/cover-image`, values, config);
};

// Upload chatbot-template-blog content image
export const uploadChatbotTemplateBlogContentImage = async (
  values: any,
  config: any
) => {
  return await api.post(`/chatbot-template-blog/content-image`, values, config);
};

// Update chatbot-template-blog approval by ID
export const updateChatbotTemplateBlogApprovalById = async (
  id: any,
  values: any
) => {
  return await api.put(`/chatbot-template-blog/approve/${id}`, values);
};

// ** --------- AI App Category API --------- ** //
// Create a chatbot-template-blog Category
export const createChatbotTemplateBlogCategory = async (values: any) => {
  return await api.post("/chatbot-template-blog-category", values);
};

// Get all chatbot-template-blog Categories
export const getAllChatbotTemplateBlogCategories = async () => {
  return await api.get(`/chatbot-template-blog-categories`);
};

// Get all chatbot-template-blogs paginated search
export const getAllChatbotTemplateBlogsCategoriesPaginatedSearch = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(
      `/chatbot-template-blog-categories/paginated/search?page=${page}&perPage=${perPage}`
    );
  } else {
    return await api.get(
      `/chatbot-template-blog-categories/paginated/search?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get a chatbot-template-blog Category by id
export const getChatbotTemplateBlogCategoryById = async (id: any) => {
  return await api.get(`/chatbot-template-blog-category/${id}`);
};

// Update a chatbot-template-blog Category by id
export const updateChatbotTemplateBlogCategoryById = async (
  id: any,
  values: any
) => {
  return await api.put(`/chatbot-template-blog-category/${id}`, values);
};

// Delete a chatbot-template-blog Category by id
export const deleteChatbotTemplateBlogCategoryById = async (id: any) => {
  return await api.delete(`/chatbot-template-blog-category/${id}`);
};
