import React from "react";
import { Row, Col, Input } from "antd";

const Analytics = ({ analytics }) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <h4
            style={{
              marginBottom: "1.5rem",
            }}
          >
            Analytics
          </h4>

          {/* Total Chatbots */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Total Chatbots
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={analytics?.totalChatbots}
              />
            </Col>
          </Row>

          {/* Total Chats */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Total Chats
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={analytics?.totalChats}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Analytics;
