import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Spin,
  Table,
  Input,
  Select,
  Modal,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { deleteKnowledgeBaseArticleCategoryById } from "@api/knowledge-base/knowledgeBase";
import EmptyCategories from "./EmptyCategories";
import { SuccessNotification } from "@components";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CategoriesList = ({
  // Loaders
  loading,
  // Article categories
  articleCategories,
  setCategoryId,
  setCategoryUpdateModal,
  fetchArticleCategories,
  // Chatbots
  chatbots,
  selectedChatbot,
  setSelectedChatbot,
}) => {
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get("search") || "";
    setSearchText(search);
  }, []);

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    // TODO: Implement actual search functionality
  };

  const handleArticleCategoryUpdate = (id) => {
    setCategoryId(id);
    setCategoryUpdateModal(true);
  };

  const filterChatbots = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleChatbotSelect = (value) => {
    setSelectedChatbot(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleArticleCategoryDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this article category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteKnowledgeBaseArticleCategoryById(id);
          SuccessNotification("Category deleted successfully");

          // Fetch article categories
          fetchArticleCategories(selectedChatbot);
        } catch (error) {
          console.error("Error deleting article category", error);
        }
      },
    });
  };

  const columns = [
    {
      title: t("knowledgeBase.name"),
      dataIndex: "name",
      key: "name",
      render: (name) =>
        name?.length > 25 ? `${name.substring(0, 25)}...` : name,
    },
    {
      title: t("knowledgeBase.noOfArticles"),
      dataIndex: "knowledgeBaseArticles",
      key: "knowledgeBaseArticles",
      render: (knowledgeBaseArticles) => knowledgeBaseArticles.length,
    },
    {
      title: t("knowledgeBase.action"),
      key: "action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleArticleCategoryUpdate(record._id)}
          >
            {t("knowledgeBase.edit")}
          </Button>
          <Button
            type="danger"
            size="small"
            onClick={() => handleArticleCategoryDelete(record._id)}
          >
            {t("knowledgeBase.delete")}
          </Button>
        </div>
      ),
    },
  ];

  const renderSpinner = (message) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 200px)",
      }}
    >
      <Spin indicator={antIcon} />
      <p style={{ marginTop: "20px" }}>{message}</p>
    </div>
  );

  const renderHeader = () => (
    <Row justify="space-between" style={{ marginBottom: 16 }}>
      <Col>
        <h3>{t("knowledgeBase.categoriesList")}</h3>
      </Col>
      <Col>
        <Select
          showSearch
          style={{ width: 300, marginRight: 10 }}
          placeholder={t("knowledgeBase.selectChatbot")}
          optionFilterProp="children"
          onChange={handleChatbotSelect}
          filterOption={filterChatbots}
          value={selectedChatbot}
        >
          {chatbots.map((chatbot) => (
            <Option key={chatbot._id} value={chatbot._id}>
              {chatbot.name}
            </Option>
          ))}
        </Select>
        <Search
          placeholder="Search by name"
          style={{ width: "300px" }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={handleSearch}
        />
      </Col>
    </Row>
  );

  return (
    <Card
      style={{
        borderRadius: "0",
        height: "100vh",
        overflowY: "auto",
        border: "none",
      }}
    >
      {renderHeader()}
      {loading ? (
        renderSpinner(t("knowledgeBase.loadingCategories"))
      ) : articleCategories.length > 0 ? (
        <Table
          loading={loading}
          columns={columns}
          dataSource={articleCategories}
          rowKey={(record) => record._id}
          scroll={{ y: "calc(100vh - 300px)" }}
          pagination={false}
        />
      ) : (
        <EmptyCategories />
      )}
    </Card>
  );
};

export default CategoriesList;
