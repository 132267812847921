import React from "react";
import { Row, Col } from "antd";

import bg from "@assets/images/pages/authentication/account-activated.svg";
import logo from "@assets/images/logo/logo.png";
import Styles from "@assets/css/AuthCommon.Module.css";

const LeftContent = () => {
  return (
    <Col
      lg={16}
      span={24}
      className="cb-auth-container"
      style={{
        overflow: "hidden",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Row justify="center">
        <Col className="hp-logo-item hp-m-sm-16 hp-m-md-32 hp-m-64">
          <img
            src={logo}
            alt="conferbotLogo"
            style={{
              transform: "scale(0.22)",
              marginLeft: "-150px",
              marginTop: "-150px",
            }}
          />
        </Col>
        <Row
          gutter={[16, 16]}
          span={24}
          align="middle"
          justify="center"
          className={Styles.BannerImg}
        >
          <Col xs={24} md={20} lg={24}>
            <img
              src={bg}
              alt="MainLogo"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          justify="center"
          align="middle"
          style={{
            fontFamily: "'Ubuntu', sans-serif",
            maxWidth: "75%",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <Col xs={24} md={22} lg={24}>
            <h2 className="hp-text-color-primary-1 hp-text-color-dark-0 hp-mx-lg-16 hp-mb-16">
              "No one can whistle a symphony. It takes a whole orchestra to play
              it"
            </h2>
          </Col>
        </Row>
      </Row>
    </Col>
  );
};

export default LeftContent;
