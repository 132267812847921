import { Button } from "antd";
import { MdClose } from "react-icons/md";
import {
  WidgetPosition,
  WidgetAppearance,
  WidgetIconCTA,
  WidgetIconsAndShape,
  WidgetToggleSound,
} from "./subOptions";
import { useTranslation } from "react-i18next";

const Widget = ({ setActiveOption }) => {
  const handleCloseButtonClick = () => {
    setActiveOption(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="option-content">
        <div className="content-header">
          <h5 style={{ textTransform: "uppercase" }}>
            {t("customize.widget.widget")}
          </h5>
          <Button
            type="text"
            icon={<MdClose size={20} />}
            onClick={handleCloseButtonClick}
            size="small"
          />
        </div>

        <div className="general-body">
          {/* Widget Position */}
          <WidgetPosition />

          {/* Widget Appearance */}
          <WidgetAppearance />

          {/* Widget Icon CTA */}
          <WidgetIconCTA />

          {/* WidgetIconsAndShape */}
          <WidgetIconsAndShape />

          {/* Widget Toggle Sound */}
          <WidgetToggleSound />
        </div>
      </div>
    </>
  );
};

export default Widget;
