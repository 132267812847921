import React, { useState, useEffect } from "react";
import { Row, Col, Input, Collapse, Spin, Card, Button } from "antd";
import { RiArrowRightSLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { getAdminPaymentHistory } from "../../../../../api/user/superAdmin";
import { useHistory } from "react-router";

const { Panel } = Collapse;

const AdminPaymentHistory = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [subscriptionHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    handleFetchUsageHistory();
  }, []);

  const handleFetchUsageHistory = async () => {
    setLoading(true);
    const { data } = await getAdminPaymentHistory(id);
    setPaymentHistory(data);
    setLoading(false);
  };

  const genExtra = () => (
    <RiArrowRightSLine
      size={24}
      className="hp-collapse-arrow hp-text-color-black-60"
    />
  );

  return (
    <>
      <Card
        style={{
          borderRadius: "0",
          height: "100vh",
        }}
      >
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          {/* Header Buttons */}
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row justify="space-between">
              {/* Heading */}
              <Col span={17}>
                <h3
                  style={{
                    marginBottom: "0.5rem",
                  }}
                >
                  Payment History
                </h3>
              </Col>

              {/* Go Back Button */}
              <Col
                span={7}
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  size="small"
                  type="primary"
                  style={{
                    marginRight: "15px",
                  }}
                  onClick={() => history.goBack()}
                >
                  Go Back
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Collapse>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(80vh - 50px)",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              {subscriptionHistory.map((item, index) => (
                <Panel
                  header={
                    <Row align="middle" justify="space-between">
                      {/* Start Date */}
                      <div
                        style={{
                          marginLeft: "30px",
                          marginRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          Start:
                        </span>{" "}
                        {new Date(item?.period_start).toUTCString()}
                      </div>

                      {/* End Date */}
                      <div
                        style={{
                          marginRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          End:
                        </span>{" "}
                        {new Date(item?.period_end).toUTCString()}
                      </div>
                    </Row>
                  }
                  key={index}
                  showArrow={false}
                  extra={genExtra()}
                >
                  {/* Start Date */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      Start Date
                    </Col>

                    <Col sm={9} span={12}>
                      <Input
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        value={new Date(item?.period_start).toUTCString()}
                      />
                    </Col>
                  </Row>

                  {/* End Date */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      End Date
                    </Col>

                    <Col sm={9} span={12}>
                      <Input
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        value={new Date(item?.period_end).toUTCString()}
                      />
                    </Col>
                  </Row>

                  {/* Payment ID */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      Invoice ID
                    </Col>

                    <Col sm={9} span={12}>
                      <Input
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        value={item?.id}
                      />
                    </Col>
                  </Row>
                </Panel>
              ))}
            </>
          )}
        </Collapse>
      </Card>
    </>
  );
};

export default AdminPaymentHistory;
