import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { AskNumberNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const AskNumberNodeDataEditor: FC<AskNumberNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [questionText, setQuestionText] = useState(state?.data?.questionText);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const { t } = useTranslation();
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setQuestionText(state?.data?.questionText);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  const handleNameChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the welcome message
      setQuestionText(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.askQuestionNodes.askNumberNodeInfo")}
          </p>
        </Row>

        {/* Question Text */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.askQuestionNodes.questionText")}
          </Col>
        </Divider>

        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={questionText}
              onEditorChange={handleNameChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.askQuestionNodes.questionTextPH"
              )}
            />
          </div>
        </Col>

        <SaveAnswer id={id} type={type} variableName={answerVariable} />
      </Row>
    </>
  );
};

export default AskNumberNodeDataEditor;
