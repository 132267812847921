import Welcome from "./components/Welcome";

export const adminSidebarSteps = [
  // ** ----- Intro ----- **
  {
    attachTo: { on: "center" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Welcome />
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 600,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // ** ----- Dashboard ----- **
  {
    attachTo: { element: ".joy-dash-admin", on: "right" },
    text: (
      <div>
        <h3>Dashboard</h3>
        <p>
          This is the dashboard. You can see the number of leads, bots, and
          messages across all your bots.
        </p>
      </div>
    ),
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // ** ----- Chatbots ----- **
  {
    attachTo: { element: ".joy-chatbots-admin", on: "right" },
    text: (
      <div>
        <h3>Chatbots</h3>
        <p>
          This is the chatbots page. You can see and manage all your chatbots
          here.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // ** ----- Chats ----- **
  {
    attachTo: { element: ".joy-chats-admin", on: "right" },
    text: (
      <div>
        <h3>Chats</h3>
        <p>
          View all your bots conversational history and interact with web
          visitors in real time
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },

  // ** ----- Knowledge Base ----- **
  {
    attachTo: { element: ".joy-kb-admin", on: "right" },
    text: (
      <div>
        <h3>Knowledge Base</h3>
        <p>
          View all your knowledge base articles and their metadata. You can
          create new articles and edit existing ones.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // ** ----- Metrics ----- **
  {
    attachTo: { element: ".joy-metrics-admin", on: "right" },
    text: (
      <div>
        <h3>Metrics</h3>
        <p>View all your bots conversational metrics with web visitors</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // ** ----- Team ----- **
  {
    attachTo: { element: ".joy-team-admin", on: "right" },
    text: (
      <div>
        <h3>Team</h3>
        <p>View and manage your team members</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // ** ----- Workspace ----- **
  {
    attachTo: { element: ".joy-workspace-admin", on: "right" },
    text: (
      <div>
        <h3>Workspace</h3>
        <p>
          View and manage your workspace settings like notifications, API key
          generation etc.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    locale: {
      last: "Okay",
    },
    showProgress: true,
  },
  // Prompt admin to click on the Chatbots sidebar menu
  {
    attachTo: { element: ".joy-chatbots-admin", on: "right" },
    text: (
      <div>
        <h3>Great! let's create your first chatbot 🤝</h3>
        Click on this menu to get started
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    locale: {
      last: "Okay",
    },
    showProgress: true,
  },
];

export const memberSidebarSteps = [
  // ** ----- Intro ----- **
  {
    attachTo: { on: "center" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Welcome />
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 600,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  // ** ----- Dashboard ----- **
  {
    attachTo: { element: ".joy-dash-member", on: "right" },
    text: (
      <div>
        <h3>Dashboard</h3>
        <p>This is the dashboard. You can see all your stats</p>
      </div>
    ),
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // ** ----- Chats ----- **
  {
    attachTo: { element: ".joy-chats-member", on: "right" },
    text: (
      <div>
        <h3>Chats</h3>
        <p>
          View all your bots conversational history and interact with web
          visitors in real time
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // ** ----- Team ----- **
  {
    attachTo: { element: ".joy-team-member", on: "right" },
    text: (
      <div>
        <h3>Team</h3>
        <p>View your team members</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // Prompt member to click on the chats
  {
    attachTo: { element: ".joy-chats-member", on: "right" },
    text: (
      <div>
        <h3>
          Great! whenever there is a real-time chat request, you will be
          redirected to this page 🤝
        </h3>
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    locale: {
      last: "Okay",
    },
    showProgress: true,
  },
];
