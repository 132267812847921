import { Card, Skeleton } from "antd";

const WelcomeCardSkeleton = () => (
  <Card
    hoverable
    bodyStyle={{ padding: "20px" }}
    style={{
      height: "100%",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      position: "relative",
      overflow: "hidden",
      margin: "0 auto",
      borderRadius: "8px",
    }}
  >
    {/* 1 */}
    <div
      style={{
        height: "25px",
        width: "60%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "20px",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>
    {/* 2 */}
    <div
      style={{
        height: "18px",
        width: "80%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "20px",
        marginTop: "20px",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>
    {/* 3*/}
    <div
      style={{
        height: "18px",
        width: "45%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "20px",
        marginTop: "10px",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>
  </Card>
);
export default WelcomeCardSkeleton;
