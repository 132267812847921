import { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Checkbox } from "antd";
import { shallow } from "zustand/shallow";
import { TwoChoicesNode } from "../../../../common/nodes/typings";
import useStore, { selector } from "../../../../store/store";
import QuillEditor from "../../../../common/components/quillEditor/QuillEditor";

const TwoChoicesNodeDataEditor: FC<TwoChoicesNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [choicePrompt, setChoicePrompt] = useState(state?.data?.choicePrompt);
  const [choiceText1, setChoiceText1] = useState(state?.data?.choiceText1);
  const [choiceText2, setChoiceText2] = useState(state?.data?.choiceText2);
  const [disableSecondChoice, setDisableSecondChoice] = useState(
    state?.data?.disableSecondChoice
  );
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setChoicePrompt(state?.data?.choicePrompt);
    setChoiceText1(state?.data?.choiceText1);
    setChoiceText2(state?.data?.choiceText2);
    setDisableSecondChoice(state?.data?.disableSecondChoice);
  }, [id]);

  const onTextAreaChange = useCallback(
    (content: any, fontSize: any) => {
      if (!state) return;

      // Set the setChoicePrompt
      setChoicePrompt(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          choicePrompt: content,
          choicePromptFont: fontSize,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle choice text change
  const handleChoiceTextChange = useCallback(
    (content: any, fontSize: any, attrKey: string) => {
      if (!state) return;

      if (attrKey === "choiceText1") {
        // Set the setChoiceText1
        setChoiceText1(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            choiceText1: content,
            choiceText1Font: fontSize,
          },
        });
      }
      if (attrKey === "choiceText2") {
        // Set the setChoiceText1
        setChoiceText2(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            choiceText2: content,
            choiceText2Font: fontSize,
          },
        });
      }
    },
    [state, type, changeNodeData]
  );

  // Handle disable second choice change
  const handleDisableSecondChoice = useCallback(
    (e: any) => {
      if (!state) return;

      // Set the setDisableSecondChoice
      setDisableSecondChoice(e.target.checked);

      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, disableSecondChoice: e.target.checked },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              color: "gray",
            }}
          >
            Use this node when you want the conversation to go in two different
            directions, based on the user's choice.
          </p>
        </Row>

        {/* QuestionText */}
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={choicePrompt}
              onEditorChange={onTextAreaChange}
              placeholderText="Ex: What would you like to do?"
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "15px",
            width: "100%",
          }}
        />

        {/* Radio Button to disable second choice */}
        <Col span={16}>
          <p
            style={{
              marginTop: "10px",
            }}
          >
            Disable Second Choice
          </p>
        </Col>
        <Col span={2}>
          <Checkbox
            checked={disableSecondChoice}
            onChange={(e) => handleDisableSecondChoice(e)}
          />
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "15px",
            width: "100%",
          }}
        />

        <Col span={24}>Choice 1</Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={choiceText1}
              onEditorChange={(content, fontSize) =>
                handleChoiceTextChange(content, fontSize, "choiceText1")
              }
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />
        {!disableSecondChoice && (
          <>
            <Col span={24}>Choice 2</Col>
            <Col span={24}>
              <div>
                <QuillEditor
                  initialContent={choiceText2}
                  onEditorChange={(content, fontSize) =>
                    handleChoiceTextChange(content, fontSize, "choiceText2")
                  }
                />
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default TwoChoicesNodeDataEditor;
