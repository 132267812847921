import { useCallback, useState, FC } from "react";
import { Row, Col, Upload, message, Button, Divider } from "antd";
import { useParams } from "react-router";
import { shallow } from "zustand/shallow";
import { RiLoaderLine, RiUpload2Line } from "react-icons/ri";
import { AudioNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
// translation
import { useTranslation } from "react-i18next";

const AudioNodeDataEditor: FC<AudioNode> = ({ type, id }) => {
  const { bid } = useParams<{ bid: string }>();
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const beforeUpload = (file: any) => {
    // Audio can be mp3, wav, ogg, flac or m4a
    const isAudio =
      file.type === "audio/mpeg" ||
      file.type === "audio/wav" ||
      file.type === "audio/ogg" ||
      file.type === "audio/flac" ||
      file.type === "audio/x-m4a";
    if (!isAudio) {
      message.error("You can only upload MP3/WAV/OGG/FLAC/M4A file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Audio must smaller than 5MB!");
    }
    return isAudio && isLt5M;
  };

  const handleUploadAudio = async (options: any) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("image", file);

    try {
      setLoading(true);
      await uploadChatbotMedia(bid, fmData, config)
        .then((res: any) => {
          changeNodeData({
            ...state,
            type,
            data: { ...state.data, audio: res.data.url },
          });
          setLoading(false);
          message.success("Welcome media updated successfully");
        })
        .catch((err: any) => {
          onError(err);
          setLoading(false);
          message.error("Error updating media");
        });
    } catch (error) {
      onError(error);
    }
  };

  const uploadButton = (
    <div style={{ width: "100%", borderRadius: "15px" }}>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <Button type="primary" icon={<RiUpload2Line className="remix-icon" />}>
          {t("flowBuilder.sidebar.editor.sendResponseNodes.audioUpload")}
        </Button>
      )}
    </div>
  );

  // Handle deleting an audio
  const handleDeleteAudio = useCallback(() => {
    if (!state) return;

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        audio: "",
      },
    });

    message.success("Audio deleted successfully");
  }, [state, type, changeNodeData]);

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t("flowBuilder.sidebar.editor.sendResponseNodes.audioNodeInfo")}
        </p>
      </Row>

      <Row style={{ marginBottom: "10px" }}>
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.sendResponseNodes.audio")}
          </Col>
        </Divider>
      </Row>

      <Row>
        {state?.data?.audio ? (
          <>
            <Col span={21}>
              <audio
                src={state.data.audio}
                style={{ width: "100%" }}
                controls
              />
            </Col>
            <Col span={3}>
              <Button
                onClick={handleDeleteAudio}
                icon={
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#ef4444"
                      d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM-200 0H360v-72h304v72z"
                    ></path>
                  </svg>
                }
                type="text"
              />
            </Col>
          </>
        ) : (
          <Upload
            name="welcome-audio"
            listType="text" // change this as per your requirements
            customRequest={handleUploadAudio}
            className="audio-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            multiple={false}
            accept="audio/*"
          >
            {uploadButton}
          </Upload>
        )}
      </Row>
    </>
  );
};

export default AudioNodeDataEditor;
