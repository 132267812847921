// -------------- Build from scratch or choose from templates -------------------
export const templatesOrScratchSteps = [
  // Intro
  {
    attachTo: { on: "center" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          You can build bots <b>from scratch</b> or choose from{" "}
          <b>100's of free templates</b>
        </p>
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  {
    attachTo: { element: "#joyride-bot-scratch", on: "right" },
    text: (
      <div>
        <p>To build a bot from scratch, you can click on this</p>
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  {
    attachTo: { element: "#joyride-bot-templates", on: "bottom" },
    text: (
      <div>
        <p>You can browse 100's of free chatbot templates to build on top of</p>
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // Template copy
  {
    attachTo: { element: "#joyride-bot-template-copy", on: "right" },
    text: (
      <div>
        <p>To get started, click on the "Copy Bot" icon</p>
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
    locale: {
      last: "Sure",
    },
  },
];

// -------------- Template list joyride -------------------
export const botTemplatesListSteps = [
  {
    attachTo: { element: "#joyride-bot-templates-filters", on: "right" },
    text: (
      <div>
        <p>Filter the templates based on the type of chatbot you want</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // Template preview
  {
    attachTo: { element: "#joyride-bot-template-preview", on: "bottom" },
    text: (
      <div>
        <p>You can preview any template by clicking the eye icon</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  // Template copy
  {
    attachTo: { element: "#joyride-bot-template-copy", on: "bottom" },
    text: (
      <div>
        <p>To copy a template, click the copy icon</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
    locale: {
      last: "Alrighty!",
    },
  },
];

// -------------- Cloned first chatbot joyride -------------------
export const clonedFirstBotSteps = [
  {
    attachTo: { on: "center" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          Awesome! You've cloned your first bot, let's get started!
        </p>
      </div>
    ),
    disableBeacon: true,
    // placement: "center",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  {
    attachTo: {
      element: "#joyride-first-bot-cloned-open-builder",
      on: "right",
    },
    text: (
      <div>
        <p>Click this button to open the no-code chatbot builder</p>
      </div>
    ),
    disableBeacon: true,
    // placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
    locale: {
      last: "Sure",
    },
  },
];
