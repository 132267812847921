import { Row, Col, Image } from "antd";
import Logo from "../../../assets/images/logo/logo.png";

export default function Welcome() {
  return (
    <>
      <Row>
        <Col md={24} span={24}>
          <div>
            <Image
              src={Logo}
              preview={false}
              width={100}
              className="hp-mb-12"
            />
            <h2 className="hp-text-3xl hp-font-bold hp-text-black-90 hp-text-dark-10">
              Welcome to the Conferbot!
            </h2>
            <h5 className="hp-text-lg hp-text-black-70 hp-text-dark-30">
              We are excited to have you onboard. Let's get started!
            </h5>
          </div>
        </Col>
      </Row>
    </>
  );
}
