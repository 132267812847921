import { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Skeleton,
  Tabs,
  message,
} from "antd";
import { useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { BsSortUp } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
import {
  BotsList,
  BotChatsList,
  AgentsList,
  HandledTickets,
  UnhandledTickets,
} from "./components";
import { archiveChatbotResponseAction } from "@redux/actions/adminActions";
import {
  AgentsSkeleton,
  ChatbotListSkeleton,
  ChatsListSkeleton,
  MyTicketsSkeleton,
} from "../../Loaders/skeleton/ChatList";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const ChatsList = ({
  // Chatbot
  selectedBotId,
  isBotSelected,
  selectedBotName,
  handleSelectChatbot,
  handleBackToChatbotsList,
  // Chat
  selectedChat,
  handleSelectChat,
  handleSelectTicketChat,
  hasChatEnded,
  // Loading
  loading,
  botNamesLoading,
  loadMoreChatsLoading,
  agentFetchLoading,
  loadMoreHandledTicketsLoading,
  loadMoreUnHandledTicketsLoading,
  // Load More
  handleLoadMoreChats,
  handleLoadMoreHandledTickets,
  handleLoadMoreUnHandledTickets,
  // Tabs
  activeListTab,
  handleChatsListTabChange,
  activeTicketsTab,
  handleTicketsTabChange,
}) => {
  // Translations
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // Redux state
  const { user } = useSelector((state) => state.auth ?? {});
  const adminState = useSelector((state) => state.admin);
  const memberState = useSelector((state) => state.member);
  const { handledTicketsData } = useSelector((state) => state.member);
  const { unHandledTicketsData } = useSelector((state) => state.member);

  // Filters
  const [filterValue, setFilterValue] = useState("All");

  // ** LOAD MORE REFS ** //
  // Chats Load More
  const loadMoreChatsRef = useRef(null);
  const [showLoadMoreChats, setShowLoadMoreChats] = useState(false);
  // Handled Tickets Load More
  const loadMoreHandledTicketsRef = useRef(null);
  const [showLoadMoreHandledTickets, setShowLoadMoreHandledTickets] =
    useState(false);
  // Unhandled Tickets Load More
  const loadMoreUnHandledTicketsRef = useRef(null);
  const [showLoadMoreUnHandledTickets, setShowLoadMoreUnHandledTickets] =
    useState(false);

  let chatbotNames, selectedBotResponses;
  if (user?.role === "admin") {
    chatbotNames = adminState.chatbotNames;
    selectedBotResponses = adminState?.selectedBotResponses;
  } else if (user?.role === "agent") {
    chatbotNames = memberState.chatbotNames;
    selectedBotResponses = memberState?.selectedBotResponses;
  }

  // ** Chats Load More ** //
  // If the last element is in view, show the Load More button.
  const chatsObserverCallback = (entries, observer) => {
    const first = entries[0];
    if (first.isIntersecting) {
      setShowLoadMoreChats(true);
    } else {
      setShowLoadMoreChats(false);
    }
  };

  // Add IntersectionObserver to the last element in the chats list
  useEffect(() => {
    const options = { threshold: 0.1 };
    const observer = new IntersectionObserver(chatsObserverCallback, options);

    if (loadMoreChatsRef.current) {
      observer.observe(loadMoreChatsRef.current);
    }

    return () => observer.disconnect();
  }, [selectedBotResponses]); // Add selectedBotResponses to dependency array

  // ** Handled Tickets Load More ** //
  // If the last element is in view, show the Load More button.
  const handledTicketsObserverCallback = (entries, observer) => {
    const first = entries[0];
    if (first.isIntersecting) {
      setShowLoadMoreHandledTickets(true);
    } else {
      setShowLoadMoreHandledTickets(false);
    }
  };

  // Add IntersectionObserver to the last element in the handled tickets list
  useEffect(() => {
    const options = { threshold: 0.1 };
    const observer = new IntersectionObserver(
      handledTicketsObserverCallback,
      options
    );

    if (loadMoreHandledTicketsRef.current) {
      observer.observe(loadMoreHandledTicketsRef.current);
    }

    return () => observer.disconnect();
  }, [handledTicketsData]); // Add handledTicketsData to dependency array

  // ** Unhandled Tickets Load More ** //
  // If the last element is in view, show the Load More button.
  const unhandledTicketsObserverCallback = (entries, observer) => {
    const first = entries[0];
    if (first.isIntersecting) {
      setShowLoadMoreUnHandledTickets(true);
    } else {
      setShowLoadMoreUnHandledTickets(false);
    }
  };

  // Add IntersectionObserver to the last element in the unhandled tickets list
  useEffect(() => {
    const options = { threshold: 0.1 };
    const observer = new IntersectionObserver(
      unhandledTicketsObserverCallback,
      options
    );

    if (loadMoreUnHandledTicketsRef.current) {
      observer.observe(loadMoreUnHandledTicketsRef.current);
    }

    return () => observer.disconnect();
  }, [unHandledTicketsData]); // Add unHandledTicketsData to dependency array

  // Handle delete chat
  const handleDeleteChat = (botId, responseId) => {
    // Confirm delete
    Modal.confirm({
      title:
        "Are you sure you want to delete this chat? Once deleted, it cannot be recovered.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Archive the chatbot response
          dispatch(archiveChatbotResponseAction(botId, responseId));

          // If the selected chat is the same as the deleted chat, then set the selected chat to null
          if (selectedChat?._id === responseId) {
            handleSelectChat(null);
          }

          // Show success notification
          message.success("Chat deleted successfully");
        } catch (error) {
          console.log("Error deleting response: ", error);
          message.error("Error deleting chat");
        }
      },
    });
  };

  // Handle filter
  const handleFilter = (e) => {
    setFilterValue(e.key);
  };

  return (
    <>
      {/* Filter and Search Bar on the same line */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "50px",
          backgroundColor: "#fff",
        }}
      >
        <Input
          style={{ width: "100%", borderRadius: "0px" }}
          suffix={
            <>
              {/* Type Filters */}
              <Dropdown
                overlay={
                  <Menu onClick={handleFilter} selectedKeys={[filterValue]}>
                    <Menu.Item key="All">
                      {t("chatMessages.chatList.all")}
                    </Menu.Item>
                    <Menu.Item key="open">
                      {t("chatMessages.chatList.open")}
                    </Menu.Item>
                    <Menu.Item key="inProgress">
                      {t("chatMessages.chatList.inProgress")}
                    </Menu.Item>
                    <Menu.Item key="closed">
                      {t("chatMessages.chatList.closed")}
                    </Menu.Item>
                    <Menu.Item key="pending">
                      {t("chatMessages.chatList.pending")}
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  type="text"
                  icon={<FiFilter style={{ marginRight: "5px" }} />}
                  size="small"
                />
              </Dropdown>

              {/* Sorting Filters */}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => message.info("Coming soon")}
                    >
                      {t("chatMessages.chatList.newest")}
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      onClick={() => message.info("Coming soon")}
                    >
                      {t("chatMessages.chatList.oldest")}
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button type="text" icon={<BsSortUp />} size="small" />
              </Dropdown>
            </>
          }
          bordered={false}
          prefix={<SearchOutlined />}
          placeholder={t("chatMessages.chatList.searchPH")}
        />
      </div>

      {/* Tabs for Chats, Bots, and Agents */}
      <Tabs
        defaultActiveKey="bots"
        activeKey={activeListTab}
        onChange={handleChatsListTabChange}
        centered={true}
        style={{
          marginBottom: "5px",
        }}
      >
        {/* Bots */}
        <TabPane tab={t("chatMessages.chatList.chatbots")} key="bots">
          {botNamesLoading ? (
            <>
              {Array.from({ length: 15 }, (_, index) => (
                <ChatbotListSkeleton key={index} />
              ))}
            </>
          ) : !isBotSelected ? (
            <BotsList
              chatbotNames={chatbotNames}
              selectedBotId={selectedBotId}
              handleSelectChatbot={handleSelectChatbot}
            />
          ) : loading ? (
            <>
              <Col
                style={{
                  height: "25px",
                  overflow: "hidden",
                  borderRadius: "20px",
                  width: "90%",
                  margin: "0px 15px",
                  marginTop: "10px",
                }}
              >
                <Skeleton.Input
                  active={true}
                  size={"small"}
                  block={true}
                  style={{
                    backgroundColor: "#0000000f",
                    width: "100%",
                  }}
                />
              </Col>
              <div style={{ marginTop: "25px" }}>
                {Array.from({ length: 15 }, (_, index) => (
                  <ChatsListSkeleton key={index} />
                ))}
              </div>
            </>
          ) : (
            <BotChatsList
              // Chatbot
              selectedBotName={selectedBotName}
              selectedBotResponses={selectedBotResponses}
              handleBackToChatbotsList={handleBackToChatbotsList}
              // Chat
              handleSelectChat={handleSelectChat}
              selectedChat={selectedChat}
              hasChatEnded={hasChatEnded}
              // Load More
              loadMoreChatsRef={loadMoreChatsRef}
              showLoadMoreChats={showLoadMoreChats}
              loadMoreChatsLoading={loadMoreChatsLoading}
              handleLoadMoreChats={handleLoadMoreChats}
              // Delete Response
              handleDeleteChat={handleDeleteChat}
            />
          )}
        </TabPane>

        {/* Agents */}
        <TabPane tab={t("chatMessages.chatList.agents")} key="agents">
          {/* AgentsList */}
          {agentFetchLoading ? (
            <>
              {Array.from({ length: 8 }, (_, index) => (
                <AgentsSkeleton key={index} />
              ))}
            </>
          ) : (
            <AgentsList agentFetchLoading={agentFetchLoading} />
          )}
        </TabPane>

        {/* Tickets */}
        <TabPane tab={t("chatMessages.chatList.myTickets")} key="tickets">
          <Tabs
            defaultActiveKey="handled"
            activeKey={activeTicketsTab}
            centered={true}
            onChange={handleTicketsTabChange}
          >
            {/* Handled Tickets Tab */}
            <TabPane tab={t("chatMessages.chatList.resolved")} key="handled">
              {loading ? (
                <>
                  {Array.from({ length: 15 }, (_, index) => (
                    <MyTicketsSkeleton key={index} />
                  ))}
                </>
              ) : (
                <HandledTickets
                  // Chat
                  handleSelectChat={handleSelectTicketChat}
                  selectedChat={selectedChat}
                  // Tickets
                  handledTicketsData={handledTicketsData}
                  // Load More
                  loadMoreHandledTicketsRef={loadMoreHandledTicketsRef}
                  showLoadMoreHandledTickets={showLoadMoreHandledTickets}
                  loadMoreHandledTicketsLoading={loadMoreHandledTicketsLoading}
                  handleLoadMoreHandledTickets={handleLoadMoreHandledTickets}
                />
              )}
            </TabPane>

            {/* Unhandled Tickets Tab */}
            <TabPane tab={t("chatMessages.chatList.declined")} key="unhandled">
              {loading ? (
                <>
                  {Array.from({ length: 15 }, (_, index) => (
                    <MyTicketsSkeleton key={index} />
                  ))}
                </>
              ) : (
                <UnhandledTickets
                  // Chat
                  handleSelectChat={handleSelectTicketChat}
                  selectedChat={selectedChat}
                  // Tickets
                  unHandledTicketsData={unHandledTicketsData}
                  // Load More
                  loadMoreUnHandledTicketsRef={loadMoreUnHandledTicketsRef}
                  showLoadMoreUnHandledTickets={showLoadMoreUnHandledTickets}
                  loadMoreUnHandledTicketsLoading={
                    loadMoreUnHandledTicketsLoading
                  }
                  handleLoadMoreUnHandledTickets={
                    handleLoadMoreUnHandledTickets
                  }
                />
              )}
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </>
  );
};

export default ChatsList;
