import { FC, useEffect, useState } from "react";
import { useOnSelectionChange } from "reactflow";
import { useDispatch } from "react-redux";
import { Button, Input, Layout } from "antd";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import SidebarNodeOptions from "./meta/sidebarNodeOptions";
import { toggleNodesSidebarAction } from "../../../../../redux/actions/chatbotActions";

import NodeDataEditor from "./editor";
import useStore from "../store/store";
import { Nodes } from "../common/nodes/typings";
import Constants from "./meta/constants";
import SidebarOptions from "./meta/options";
import SidebarSearchNodeOptions from "./meta/sidebarSearchNodeOptions";
import { CloseSquare } from "react-iconly";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

interface SidebarProps {
  collapsed: boolean;
}

const Sidebar: FC<SidebarProps> = ({ collapsed }) => {
  const dispatch = useDispatch();
  const { deselectNodes, setSelectedNodes, selectedNodes } = useStore(
    (state) => ({
      selectedNodes: state.selectedNodes,
      deselectNodes: state.deselectNodes,
      setSelectedNodes: state.setSelectedNodes,
    })
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { t } = useTranslation();
  const { labelsMap, iconsMap, howToUseLinksMap } = Constants();

  // ----- Sidebar Options -----
  const {
    SendResponseNodes,
    AskQuestionNodes,
    FlowOperations,
    LogicalNodes,
    SpecialNodes,
    IntegrationNodes,
  } = SidebarOptions();

  // Handle deselect
  const onDeselect = () => deselectNodes();

  // Handle selection change
  useOnSelectionChange({
    onChange: (elements) => {
      if (elements.nodes.length > 0) {
        setSelectedNodes(elements.nodes as Array<Nodes>);
      } else {
        setSelectedNodes([]);
      }
    },
  });

  // If a single node is selected, show the editor for that node
  const isSingleNodeSelected = selectedNodes.length === 1;

  // Id sidebar is hidden, show it when a single node is selected
  useEffect(() => {
    if (isSingleNodeSelected) {
      dispatch(toggleNodesSidebarAction(false));
    }
  }, [isSingleNodeSelected, dispatch]);

  // Get selected node
  const selectedNode = selectedNodes[0];
  if (isSingleNodeSelected) {
    return (
      <div
        className="node-editor__sidebar"
        style={{
          height: "calc(100vh - 72px)",
          position: "absolute",
          width: "400px",
          zIndex: "99",
        }}
      >
        <div
          className="node-editor__header"
          style={{
            padding: "10px 10px",
            fontWeight: 500,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000080",
            color: "#fff",
            borderRadius: "5px",
            boxShadow: "0 0 15px rgba(0,0,0,0.3)",
          }}
        >
          <p
            style={{
              marginLeft: "8px",
              marginTop: "15px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <img
              src={iconsMap[selectedNode.type]}
              style={{ height: "22px", width: "22px", marginRight: "10px" }}
              alt="editor icons"
            />
            {labelsMap[selectedNode.type]} {t("flowBuilder.sidebar.node")}
          </p>

          {/* How to use (Link to youtube in a new tab) */}
          <a
            href={howToUseLinksMap[selectedNode.type]}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              color: "#fff",
              textDecoration: "none", // Removes underline from link
            }}
          >
            <span style={{ marginRight: "5px" }}>
              {t("flowBuilder.sidebar.howToUse")}
            </span>
            <FiExternalLink size={16} />
          </a>

          {/* Close Button */}
          <Button
            type="text"
            icon={<MdClose size={20} className="back-btn__icon" />}
            onClick={onDeselect}
            size="small"
            className="sidebar__back-btn"
          />
        </div>

        <div
          style={{
            padding: "15px",
            height: "calc(100vh - 133px)",
            overflowY: "auto",
            background: "#ffffff",
            overflowX: "hidden",
          }}
        >
          <NodeDataEditor node={selectedNode} />
        </div>
      </div>
    );
  }

  // All nodes
  const allNodes = [
    ...SendResponseNodes,
    ...AskQuestionNodes,
    ...FlowOperations,
    ...LogicalNodes,
    ...SpecialNodes,
    ...IntegrationNodes,
  ];

  // Handle nodes search
  const handleNodesSearch = (event: any) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredResults = allNodes.filter(
      (node: any) =>
        node.label.toLowerCase().includes(term) ||
        node.description.toLowerCase().includes(term)
    );

    setSearchResults(filteredResults);
  };

  return (
    <Sider
      trigger={null}
      collapsed={collapsed}
      collapsedWidth={0}
      width="100%"
      style={{ background: "#f0f2f8", transition: "all 0.2s ease-out" }}
    >
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
        className="hp-d-flex hp-h-100"
        style={{ flexDirection: "column" }}
      >
        <div
          className="switch-container"
          style={{
            marginTop: "15px",
            marginBottom: "20px",
            background: "#e2e5ef",
          }}
        >
          <span
            className="switch-btn"
            style={{
              backgroundColor: "#fff",
              borderRadius: "4px",
              color: "#000",
              width: "100%",
              fontSize: "14px",
            }}
          >
            <h5>{t("flowBuilder.sidebar.allNodes")}</h5>
          </span>
        </div>

        {/* Search Nodes */}
        <div
          className="switch-container"
          style={{
            background: "#fff",
          }}
        >
          <Input
            bordered={false}
            id="chatInput"
            placeholder={t("flowBuilder.sidebar.searchNodes")}
            value={searchTerm}
            onChange={(e) => handleNodesSearch(e)}
            style={{
              boxShadow: "none",
              border: "0.5px solid lightGray",
              borderRadius: "4px",
            }}
            suffix={
              <>
                {searchTerm.length > 0 && (
                  <span
                    onClick={() => setSearchTerm("")}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CloseSquare
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                )}
              </>
            }
          />
        </div>
        <div
          style={{
            overflowY: "auto",
            height: "calc(100vh - 170px)",
          }}
          className="flow-sidebar-nodes"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0px 5px 0px",
            }}
          >
            {searchTerm.length > 0 ? (
              <SidebarSearchNodeOptions searchedNodes={searchResults} />
            ) : (
              <SidebarNodeOptions />
            )}
          </div>
        </div>
      </motion.div>
    </Sider>
  );
};

export default Sidebar;
