import { useTranslation } from "react-i18next";

const Admin = () => {
  const { t } = useTranslation();

  const adminNavArray = [
    // ** Dashboard ** //
    {
      id: "dashboard",
      title: t("navigation.admin.dashboard"),
      icon: (
        <svg
          className="joy-dash-admin"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0b4eb8"
            d="M13 9V3h8v6h-8ZM3 13V3h8v10H3Zm10 8V11h8v10h-8ZM3 21v-6h8v6H3Z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/dashboard`,
    },
    // ** Chatbots ** //
    {
      id: "chatbots",
      title: t("navigation.admin.chatbots"),
      icon: (
        <svg
          className="joy-chatbots-admin"
          width="1em"
          height="1em"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0b4eb8"
            d="M18 14.824V12.5A3.5 3.5 0 0 0 14.5 9h-2A1.5 1.5 0 0 1 11 7.5V5.176A2.395 2.395 0 0 0 12.4 3a2.4 2.4 0 1 0-4.8 0c0 .967.576 1.796 1.4 2.176V7.5A1.5 1.5 0 0 1 7.5 9h-2A3.5 3.5 0 0 0 2 12.5v2.324A2.396 2.396 0 0 0 3 19.4a2.397 2.397 0 0 0 1-4.576V12.5A1.5 1.5 0 0 1 5.5 11h2c.539 0 1.044-.132 1.5-.35v4.174a2.396 2.396 0 0 0 1 4.576a2.397 2.397 0 0 0 1-4.576V10.65c.456.218.961.35 1.5.35h2a1.5 1.5 0 0 1 1.5 1.5v2.324A2.395 2.395 0 0 0 14.6 17a2.4 2.4 0 1 0 4.8 0c0-.967-.575-1.796-1.4-2.176zM10 1.615a1.384 1.384 0 1 1 0 2.768a1.384 1.384 0 0 1 0-2.768zm-7 16.77a1.385 1.385 0 1 1 0-2.77a1.385 1.385 0 0 1 0 2.77zm7 0a1.385 1.385 0 1 1 0-2.77a1.385 1.385 0 0 1 0 2.77zm7 0a1.385 1.385 0 1 1 0-2.77a1.385 1.385 0 0 1 0 2.77z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/chatbots`,
    },
    // ** Chats ** //
    {
      id: "chats",
      title: t("navigation.admin.chats"),
      icon: (
        <svg
          className="joy-chats-admin"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="#0b4eb8" d="M10 6V0H6v6H4l4 5l4-5z"></path>
          <path
            fill="#0b4eb8"
            d="M13 1h-2v1h1.3l2.6 8H11v2H5v-2H1.1l2.6-8H5V1H3l-3 9v5h16v-5z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/chats`,
    },
    // ** Knowledge Base ** //
    {
      id: "knowledge-base",
      title: t("navigation.admin.knowledgeBase"),
      icon: (
        <svg
          className="joy-kb-admin"
          width="1em"
          height="1em"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="#0b4eb8"
            d="M202.24 74C166.11 56.75 115.61 48.3 48 48a31.36 31.36 0 0 0-17.92 5.33A32 32 0 0 0 16 79.9V366c0 19.34 13.76 33.93 32 33.93c71.07 0 142.36 6.64 185.06 47a4.11 4.11 0 0 0 6.94-3V106.82a15.89 15.89 0 0 0-5.46-12A143 143 0 0 0 202.24 74Zm279.68-20.7A31.33 31.33 0 0 0 464 48c-67.61.3-118.11 8.71-154.24 26a143.31 143.31 0 0 0-32.31 20.78a15.93 15.93 0 0 0-5.45 12v337.13a3.93 3.93 0 0 0 6.68 2.81c25.67-25.5 70.72-46.82 185.36-46.81a32 32 0 0 0 32-32v-288a32 32 0 0 0-14.12-26.61Z"
          />
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/knowledge-base/articles`,
    },
    // ** Metrics ** //
    {
      id: "metrics",
      title: t("navigation.admin.metrics"),
      icon: (
        <svg
          className="joy-metrics-admin"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0b4eb8"
            d="M2 14h14v2H0V0h2zm2.5-1a1.5 1.5 0 1 1 .131-2.994l1.612-2.687a1.5 1.5 0 1 1 2.514 0l1.612 2.687a1.42 1.42 0 0 1 .23-.002l2.662-4.658a1.5 1.5 0 1 1 1.14.651l-2.662 4.658a1.5 1.5 0 1 1-2.496.026L7.631 7.994a1.42 1.42 0 0 1-.262 0l-1.612 2.687A1.5 1.5 0 0 1 4.5 13z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/metrics/graph`,
    },
    // ** Team ** //
    {
      id: "team",
      title: t("navigation.admin.team"),
      icon: (
        <svg
          className="joy-team-admin"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0b4eb8"
            d="M12 10a4 4 0 1 0 0-8a4 4 0 0 0 0 8Zm-6.5 3a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5ZM21 10.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0Zm-9 .5a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5Zm-7 5c0-.693.1-1.362.288-1.994l-.17.014A3.5 3.5 0 0 0 2 17.5V22h3v-6Zm17 6v-4.5a3.5 3.5 0 0 0-3.288-3.494c.187.632.288 1.301.288 1.994v6h3Z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/team`,
    },
    // ** Workspace ** //
    {
      id: "workspace",
      title: t("navigation.admin.workspace"),
      icon: (
        <svg
          className="joy-workspace-admin"
          width="1em"
          height="1em"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" stroke="#0b4eb8" strokeWidth="4">
            <path
              strokeLinejoin="round"
              d="M42 43V6C42 4.89543 41.1046 4 40 4H33C31.8954 4 31 4.89543 31 6V24"
            />
            <path d="M24 22C14.0589 22 6 30.0589 6 40V44H42V40C42 30.0589 33.9411 22 24 22Z" />
            <path strokeLinecap="round" d="M8 31V8" />
            <path strokeLinecap="round" d="M16 24L16 14" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M31 14L42 14"
            />
            <path d="M17 42C17 41 17 40.0237 17 39C17 29.6112 20.134 22 24 22C27.866 22 31 29.6112 31 39C31 40.3778 31 41 31 42" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 44H44" />
            <path d="M7 35C7 35 17.3496 34 24 34C30.6504 34 41 35 41 35" />
          </g>
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/api`,
    },

    // ** Integrations ** //
    {
      id: "integrations",
      title: t("navigation.admin.integrations"),
      icon: (
        <svg
          className="joy-integrations-admin"
          width="1em"
          height="1em"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0b4eb8"
            fillOpacity=".15"
            d="M148.2 674.6zm106.7-92.3c-25 25-38.7 58.1-38.7 93.4s13.8 68.5 38.7 93.4c25 25 58.1 38.7 93.4 38.7c35.3 0 68.5-13.8 93.4-38.7l59.4-59.4l-186.8-186.8l-59.4 59.4zm420.8-366.1c-35.3 0-68.5 13.8-93.4 38.7l-59.4 59.4l186.8 186.8l59.4-59.4c24.9-25 38.7-58.1 38.7-93.4s-13.8-68.5-38.7-93.4c-25-25-58.1-38.7-93.4-38.7z"
          ></path>
          <path
            fill="#0b4eb8"
            d="M578.9 546.7a8.03 8.03 0 0 0-11.3 0L501 613.3L410.7 523l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3L441 408.6a8.03 8.03 0 0 0-11.3 0L363 475.3l-43-43a7.85 7.85 0 0 0-5.7-2.3c-2 0-4.1.8-5.7 2.3L206.8 534.2a199.45 199.45 0 0 0-58.6 140.4c-.2 39.5 11.2 79.1 34.3 113.1l-76.1 76.1a8.03 8.03 0 0 0 0 11.3l42.4 42.4c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l76.1-76.1c33.7 22.9 72.9 34.3 112.1 34.3c51.2 0 102.4-19.5 141.5-58.6l101.9-101.9c3.1-3.1 3.1-8.2 0-11.3l-43-43l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3l-36.6-36.2zM441.7 769.1a131.32 131.32 0 0 1-93.4 38.7c-35.3 0-68.4-13.7-93.4-38.7c-24.9-24.9-38.7-58.1-38.7-93.4s13.7-68.4 38.7-93.4l59.4-59.4l186.8 186.8l-59.4 59.4zm476-620.3l-42.4-42.4c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-76.1 76.1a199.27 199.27 0 0 0-112.1-34.3c-51.2 0-102.4 19.5-141.5 58.6L432.3 308.7a8.03 8.03 0 0 0 0 11.3L704 591.7c1.6 1.6 3.6 2.3 5.7 2.3c2 0 4.1-.8 5.7-2.3l101.9-101.9c68.9-69 77-175.7 24.3-253.5l76.1-76.1c3.1-3.2 3.1-8.3 0-11.4zM769.1 441.7l-59.4 59.4l-186.8-186.8l59.4-59.4c24.9-24.9 58.1-38.7 93.4-38.7s68.4 13.7 93.4 38.7c24.9 24.9 38.7 58.1 38.7 93.4s-13.8 68.4-38.7 93.4z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/a/ws/${workspaceId}/integrations`,
    },
  ];
  return { adminNavArray };
};

export default Admin;
