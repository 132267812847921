import React from "react";
import Pricing from "../Pricing";
import ComparePlans from "./ComparePlans";

const SubscriptionInfo = () => {
  return (
    <>
      <Pricing />
      <ComparePlans />
    </>
  );
};

export default SubscriptionInfo;
