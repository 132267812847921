import { AUTH_TYPES } from "../constants/authTypes";
import Cookies from "js-cookie";

// Define the structure of your auth payload, update it based on your needs
interface AuthPayload {
  accessToken: string;
  user: {
    _id: string;
    tutorials_complete?: boolean;
  };
  // Add other properties as required
}

// Define the type for your action
interface AuthAction {
  type: string;
  payload: AuthPayload;
}

// Define the type for your state
type AuthState = AuthPayload | {}; // State can be an AuthPayload or an empty object

// Initial state with type
const initialState: AuthState = {};

// Reducer function with types
const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    // ** Login
    case AUTH_TYPES.AUTH:
      Cookies.set("access", action.payload.accessToken, { expires: 1 / 24 }); // 13 minutes expiration
      localStorage.setItem("alLoggInnn", "true");
      return action.payload;
    // ** Is User / Admin / Member / Super Admin / Marketing Admin
    case AUTH_TYPES.IS_USER:
    case AUTH_TYPES.IS_ADMIN:
    case AUTH_TYPES.IS_MEMBER:
    case AUTH_TYPES.IS_SUPER_ADMIN:
    case AUTH_TYPES.IS_MARKETING_ADMIN:
      if (
        action.payload.user &&
        action.payload.user.tutorials_complete !== undefined
      ) {
        localStorage.setItem(
          "shepherd",
          JSON.stringify(action.payload.user.tutorials_complete)
        );
      }

      return action.payload;
    case AUTH_TYPES.LOGOUT:
      Cookies.remove("access");
      localStorage.removeItem("alLoggInnn");

      // Remove the shepherd from local storage
      localStorage.removeItem("shepherd");

      return action.payload;
    default:
      return state;
  }
};

export default authReducer;
