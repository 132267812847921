import {
  // Find and display node
  _findAndDisplayNode,
  // Current steps and index
  _getCurrentStepsAndIndex,
  // Variables
  _handleAddToTranscript,
  _pushDataToRecord,
  _setAnswerVariable,
  _setUserMetaData,
} from "../../ChatbotFlowPreview";
import {
  _sleep,
  _validateEmailHelper,
  _validatePhoneNumberHelper,
  _displayErrorMessage,
  parsedHtmlElement,
  _showTypingAnimationForGivenTime,
} from "../../utils/helpers";
import botAvatar from "../../../../../../../assets/images/pages/customize/botLogo.png";

// Handle ask question input
export const _handleAskQuestionInputSubmit = (
  embedType: any,
  customizations: any,
  value: any
) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;
  // Get the input element
  const inputElement = document.querySelector("#inputField") as any;

  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;

  // -------- ASK NAME NODE --------
  if (steps[currentIndex].type === "ask-name-node") {
    // Add the user input to the message element
    let userInputMessage = document.createElement("div");
    userInputMessage.style.textAlign = "right";

    userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user" 
            style="background-color: ${customizations.userMsgColor}; 
            color: ${customizations.userTextColor};" 
            id="user-input_${currentIndex}">
            ${value}
          </p>
        </div>`;

    // Add the user input to the steps element
    stepsElement?.appendChild(userInputMessage);

    // Set _setUserMetaData with name
    _setUserMetaData("name", value);

    // Record the user input
    _pushDataToRecord({
      id: steps[currentIndex].id,
      shape: "user-ask-name-response",
      type: steps[currentIndex].data.type,
      text: value,
    });

    // Add the user input to the transcript
    _handleAddToTranscript("user", value);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, value);

    // Clear the input field
    inputElement.value = "";

    // Select the chat body, scroll to bottom
    if (chatBody) {
      chatBody.scrollTop = stepsElement.scrollHeight + 800;
    }

    // Input element disabled to true
    inputElement.disabled = true;

    let nameGreetMessage = document.createElement("div");
    nameGreetMessage.style.position = "relative";

    // Get the nameGreetResponse text
    const nameGreetResponseText = steps[currentIndex].data.nameGreetResponse
      ? steps[currentIndex].data.nameGreetResponse.replace("{name}", value)
      : `Nice to meet you, ${value}`;

    // Parse the message text as HTML
    const parsedNameGreetResponse = parsedHtmlElement(
      nameGreetResponseText,
      "bot",
      customizations
    );

    // Show _showTypingAnimationForGivenTime and then display the bot message greet response
    _showTypingAnimationForGivenTime(embedType, customizations, 2000).then(
      () => {
        nameGreetMessage.innerHTML = `<div style="position: relative;">
          <div
            class="${embedType}__chat__bubble__wrapper"
          >
            <img src=${
              customizations?.avatar || botAvatar
            } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
              style="display: ${
                customizations?.hideAvatar ? "none" : "block"
              }; height: ${customizations?.avatarSize}px;"
            >
            ${parsedNameGreetResponse}
          </div>
        </div>`;

        // Add the bot message to the steps element
        stepsElement.appendChild(nameGreetMessage);

        // Record the user input
        _pushDataToRecord({
          id: steps[currentIndex].id,
          shape: "ask-name-response-greet",
          type: "ask-name-response-greet",
          text: nameGreetResponseText,
        });

        // Add the user input to the transcript
        _handleAddToTranscript("bot", nameGreetResponseText);

        // Select the chat body, scroll to bottom
        chatBody.scrollTop = stepsElement.scrollHeight + 800;

        // Display the next step
        _findAndDisplayNode();
      }
    );
  }

  // -------- ASK EMAIL NODE --------
  if (steps[currentIndex].type === "ask-email-node") {
    // Remove leading and trailing spaces
    const isValidEmail = _validateEmailHelper(value.trim());
    if (!isValidEmail) {
      _displayErrorMessage(
        embedType,
        customizations,
        steps[currentIndex].data.incorrectEmailResponse !== undefined
          ? steps[currentIndex].data.incorrectEmailResponse
          : "Please enter a valid email address"
      );
      return;
    }

    // Set _setUserMetaData with email
    _setUserMetaData("email", value);

    // Add the user input to the message element
    let userInputMessage = document.createElement("div");
    userInputMessage.style.textAlign = "right";

    userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user" 
            style="background-color: ${customizations.userMsgColor}; 
            color: ${customizations.userTextColor};" 
            id="user-input_${currentIndex}">
            ${value}
          </p>
        </div>`;

    // Add the user input to the steps element
    stepsElement?.appendChild(userInputMessage);

    // Record the user input
    _pushDataToRecord({
      id: steps[currentIndex].id,
      shape: "user-ask-email-response",
      type: steps[currentIndex].data.type,
      text: value,
    });

    // Add the user input to the transcript
    _handleAddToTranscript("user", value);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, value);

    // Select the chat body, scroll to bottom
    if (chatBody) {
      chatBody.scrollTop = stepsElement.scrollHeight + 800;
    }

    // Input element disabled to true
    inputElement.disabled = true;

    // Clear the input field
    inputElement.value = "";

    // Display the next step
    _findAndDisplayNode();
  }

  // -------- ASK PHONE NUMBER NODE --------
  if (steps[currentIndex].type === "ask-phone-number-node") {
    // Validate phone number
    const isValidPhoneNumber = _validatePhoneNumberHelper(value.trim());

    if (!isValidPhoneNumber) {
      _displayErrorMessage(
        embedType,
        customizations,
        steps[currentIndex].data.incorrectPhoneNumberResponse !== undefined
          ? steps[currentIndex].data.incorrectPhoneNumberResponse
          : "Please enter a valid phone number"
      );
      return;
    }

    // Set _setUserMetaData with phone number
    _setUserMetaData("phone", value);

    // Add the user input to the message element
    let userInputMessage = document.createElement("div");
    userInputMessage.style.textAlign = "right";

    userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user" 
            style="background-color: ${customizations.userMsgColor}; 
            color: ${customizations.userTextColor};" 
            id="user-input_${currentIndex}">
            ${value}
          </p>
        </div>`;

    // Add the user input to the steps element
    stepsElement?.appendChild(userInputMessage);

    // Record the user input
    _pushDataToRecord({
      id: steps[currentIndex].id,
      shape: "user-ask-phone-number-response",
      type: steps[currentIndex].data.type,
      text: value,
    });

    // Add the user input to the transcript
    _handleAddToTranscript("user", value);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, value);

    // Select the chat body, scroll to bottom
    if (chatBody) {
      chatBody.scrollTop = stepsElement.scrollHeight + 800;
    }

    // Input element disabled to true
    inputElement.disabled = true;

    // Clear the input field
    inputElement.value = "";

    // Display the next step
    _findAndDisplayNode();
  }

  // -------- ASK PHONE NUMBER NODE --------
  if (steps[currentIndex].type === "ask-number-node") {
    // Add the user input to the message element
    let userInputMessage = document.createElement("div");
    userInputMessage.style.textAlign = "right";

    userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user" 
            style="background-color: ${customizations.userMsgColor}; 
            color: ${customizations.userTextColor};" 
            id="user-input_${currentIndex}">
            ${value}
          </p>
        </div>`;

    // Add the user input to the steps element
    stepsElement?.appendChild(userInputMessage);

    // Record the user input
    _pushDataToRecord({
      id: steps[currentIndex].id,
      shape: "user-ask-number-response",
      type: steps[currentIndex].data.type,
      text: value,
    });

    // Add the user input to the transcript
    _handleAddToTranscript("user", value);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, Number(value));

    // Select the chat body, scroll to bottom
    if (chatBody) {
      chatBody.scrollTop = stepsElement.scrollHeight + 800;
    }

    // Input element disabled to true
    inputElement.disabled = true;

    // Clear the input field
    inputElement.value = "";

    // Display the next step
    _findAndDisplayNode();
  }

  // -------- ASK URL NODE --------
  if (steps[currentIndex].type === "ask-url-node") {
    // Add the user input to the message element
    let userInputMessage = document.createElement("div");
    userInputMessage.style.textAlign = "right";

    userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user" 
            style="background-color: ${customizations.userMsgColor}; 
            color: ${customizations.userTextColor};" 
            id="user-input_${currentIndex}">
            ${value}
          </p>
        </div>`;

    // Add the user input to the steps element
    stepsElement?.appendChild(userInputMessage);

    // Record the user input
    _pushDataToRecord({
      id: steps[currentIndex].id,
      shape: "user-ask-url-response",
      type: steps[currentIndex].data.type,
      text: value,
    });

    // Add the user input to the transcript
    _handleAddToTranscript("user", value);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, value);

    // Select the chat body, scroll to bottom
    if (chatBody) {
      chatBody.scrollTop = stepsElement.scrollHeight + 800;
    }

    // Input element disabled to true
    inputElement.disabled = true;

    // Clear the input field
    inputElement.value = "";

    // Display the next step
    _findAndDisplayNode();
  }

  // -------- ASK LOCATION NODE --------
  if (steps[currentIndex].type === "ask-location-node") {
    // Add the user input to the message element
    let userInputMessage = document.createElement("div");
    userInputMessage.style.textAlign = "right";

    userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user" 
            style="background-color: ${customizations.userMsgColor}; 
            color: ${customizations.userTextColor};" 
            id="user-input_${currentIndex}">
            ${value}
          </p>
        </div>`;

    // Add the user input to the steps element
    stepsElement?.appendChild(userInputMessage);

    // Add the user input to the transcript
    _handleAddToTranscript("user", value);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, value);

    // Select the chat body, scroll to bottom
    if (chatBody) {
      chatBody.scrollTop = stepsElement.scrollHeight + 800;
    }

    // Input element disabled to true
    inputElement.disabled = true;

    // Clear the input field
    inputElement.value = "";

    // Display the next step
    _findAndDisplayNode();
  }

  // -------- ASK LOCATION NODE --------
  if (steps[currentIndex].type === "ask-custom-question-node") {
    // Add the user input to the message element
    let userInputMessage = document.createElement("div");
    userInputMessage.style.textAlign = "right";

    userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user" 
            style="background-color: ${customizations.userMsgColor}; 
            color: ${customizations.userTextColor};" 
            id="user-input_${currentIndex}">
            ${value}
          </p>
        </div>`;

    // Add the user input to the steps element
    stepsElement?.appendChild(userInputMessage);

    // Add the user input to the transcript
    _handleAddToTranscript("user", value);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, value);

    // Select the chat body, scroll to bottom
    if (chatBody) {
      chatBody.scrollTop = stepsElement.scrollHeight + 800;
    }

    // Input element disabled to true
    inputElement.disabled = true;

    // Clear the input field
    inputElement.value = "";

    // Display the next step
    _findAndDisplayNode();
  }
};

// Handle _handleAskMultipleQuestionsLooper
let currentQuestionIndex = 0;
export const _handleAskMultipleQuestionsLooper = (
  embedType: any,
  customizations: any,
  questionIndex?: any
) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // If questionIndex is sent, set it, else increment it
  currentQuestionIndex =
    questionIndex !== undefined ? questionIndex : currentQuestionIndex;

  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;
  // Get the input element
  const inputElement = document.querySelector("#inputField") as any;

  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;

  // Create a function to append the question and listen for the answer
  const appendQuestion = (questionIndex: any) => {
    let questionText =
      steps[currentIndex]?.data?.questions[questionIndex]?.questionText;
    let parsedQuestion = parsedHtmlElement(questionText, "bot", customizations);

    let questionContainer = document.createElement("div");
    questionContainer.innerHTML = `<div style="position: relative;">
        <div class="${embedType}__chat__bubble__wrapper">
            <img src=${
              customizations?.avatar || botAvatar
            } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
              style="display: ${
                customizations?.hideAvatar ? "none" : "block"
              }; height: ${customizations?.avatarSize}px;"
            >
            ${parsedQuestion}
        </div>
    </div>`;

    // Show typing animation for 2 seconds
    _showTypingAnimationForGivenTime(embedType, customizations, 2000).then(
      () => {
        // Append it to the steps element
        stepsElement?.appendChild(questionContainer);

        // Record the user input
        _pushDataToRecord({
          id: currentIndex,
          shape: "ask-multiple-questions-question",
          type: "ask-multiple-questions-question",
          questionText: questionText,
        });

        // Add the bot message to the transcript
        _handleAddToTranscript("bot", questionText);

        // Select the chat body, scroll to bottom
        if (chatBody) {
          chatBody.scrollTop = stepsElement.scrollHeight + 800;
        }

        // Get the input element and toggle disabled to false
        inputElement.disabled = false;
        inputElement.focus();
      }
    );
  };

  // If the currentQuestionIndex is less than the questions length, then append the question
  if (currentQuestionIndex < steps[currentIndex]?.data?.questions?.length) {
    appendQuestion(currentQuestionIndex);
  } else {
    // Reset the currentQuestionIndex
    currentQuestionIndex = 0;

    // Else, display the next step
    _findAndDisplayNode();
  }
};

// Handle _handleAskMultipleQuestionsSubmit
export const _handleAskMultipleQuestionsSubmit = (
  embedType: any,
  customizations: any,
  value: any
) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;
  // Get the input element
  const inputElement = document.querySelector("#inputField") as any;

  // ** EMAIL VALIDATION ** //
  // If steps[currentIndex]?.data?.questions[currentQuestionIndex].answerVariable is email, validate email
  if (
    steps[currentIndex]?.data?.questions[currentQuestionIndex]
      .answerVariable === "email"
  ) {
    // Remove leading and trailing spaces
    const isValidEmail = _validateEmailHelper(value.trim());
    if (!isValidEmail) {
      _displayErrorMessage(
        embedType,
        customizations,
        steps[currentIndex].data.incorrectEmailResponse !== undefined
          ? steps[currentIndex].data.incorrectEmailResponse
          : "Please enter a valid email address"
      );
      return;
    }
  }

  // ** PHONE NUMBER VALIDATION ** //
  if (
    steps[currentIndex]?.data?.questions[currentQuestionIndex]
      .answerVariable === "mobile" ||
    steps[currentIndex]?.data?.questions[currentQuestionIndex]
      .answerVariable === "phone"
  ) {
    // Validate phone number
    const isValidPhoneNumber = _validatePhoneNumberHelper(value.trim());

    if (!isValidPhoneNumber) {
      _displayErrorMessage(
        embedType,
        customizations,
        steps[currentIndex].data.incorrectPhoneNumberResponse !== undefined
          ? steps[currentIndex].data.incorrectPhoneNumberResponse
          : "Please enter a valid phone number"
      );
      return;
    }
  }

  // If name, email or phone number, set _setUserMetaData
  if (
    steps[currentIndex]?.data?.questions[currentQuestionIndex]
      .answerVariable === "name" ||
    steps[currentIndex]?.data?.questions[currentQuestionIndex]
      .answerVariable === "email" ||
    steps[currentIndex]?.data?.questions[currentQuestionIndex]
      .answerVariable === "mobile" ||
    steps[currentIndex]?.data?.questions[currentQuestionIndex]
      .answerVariable === "phone"
  ) {
    // Set _setUserMetaData with phone number
    _setUserMetaData(
      steps[currentIndex]?.data?.questions[currentQuestionIndex].answerVariable,
      value
    );
  }

  // Add the user input to the message element
  let userInputMessage = document.createElement("div");
  userInputMessage.style.textAlign = "right";

  userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
        <p class="${embedType}__chat__bubble user" 
          style="background-color: ${customizations.userMsgColor}; 
          color: ${customizations.userTextColor};" 
          id="user-input_${currentIndex}">
          ${value}
        </p>
      </div>`;

  // Clear the input field
  inputElement.value = "";

  // Add the user input to the steps element
  stepsElement.appendChild(userInputMessage);

  // Record the user input
  _pushDataToRecord({
    id: currentIndex,
    shape: "user-ask-multiple-questions-question-response",
    type: "user-ask-multiple-questions-question-response",
    text: value,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", value);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id + currentQuestionIndex, value);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
  if (chatBody) {
    chatBody.scrollTop = stepsElement.scrollHeight + 800;
  }

  // Input element disabled to true
  inputElement.disabled = true;

  // Display the next question
  _handleAskMultipleQuestionsLooper(
    embedType,
    customizations,
    currentQuestionIndex + 1
  );
};

// Handle calendar node
export const _handleCalendarSelection = (data: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Record the calendar selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: `user-calendar-selection`,
    type: steps[currentIndex].data.type,
    data: data,
  });

  // Add to transcript
  _handleAddToTranscript("user", data.date);
  _handleAddToTranscript("user", data.timeSlotSelected);

  // Combine date and time slot selected
  const dateAndTimeSlotSelected = `${data.date} ${data.timeSlotSelected}`;

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, dateAndTimeSlotSelected);

  // Sleep for 400mx
  _sleep(400).then(() => {
    // Display the next step
    _findAndDisplayNode();
  });
};

// Handle check options selection
export const _handleCheckOptionsSelection = (selectedCheckOptions: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Add the user input to the transcript
  _handleAddToTranscript(
    "user",
    selectedCheckOptions?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-check-options",
    options: steps[currentIndex].data,
    selectedCheckOptions: selectedCheckOptions,
  });

  // Set the answer variable (send node id and value)
  _setAnswerVariable(
    steps[currentIndex].id,
    selectedCheckOptions?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Filter out empty options
  const filteredOptions = steps[currentIndex].data.options.filter(
    (option: { optionText: any }) => option.optionText.trim() !== ""
  );

  // Get the check options buttons & toggle disabled to true
  for (let i = 0; i < filteredOptions.length; i++) {
    const optionElement = document.getElementById(
      "nCheckOptionText_" + i + "_" + currentIndex
    ) as any;
    optionElement.disabled = true;
  }

  // Add the next node to the steps array
  _sleep(600).then(() => {
    _findAndDisplayNode();
  });
};

// Handle select option selection
export const _handleOptionSelection = (optionIndex: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  const selectedOption =
    steps[currentIndex].type === "n-select-option-node"
      ? steps[currentIndex].data.options.find(
          (option: any) => option.id === optionIndex
        ).optionText
      : steps[currentIndex].data[optionIndex];

  // Add the user input to the transcript
  _handleAddToTranscript(
    "user",
    selectedOption?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-option",
    options: steps[currentIndex].data,
    selectedOption: selectedOption?.replace(/<\/?[^>]+(>|$)/g, ""),
  });

  // Set the answer variable (send node id and value)
  _setAnswerVariable(
    steps[currentIndex].id,
    selectedOption?.replace(/<\/?[^>]+(>|$)/g, "")
  );

  if (steps[currentIndex].type === "select-option-node") {
    // Get the option buttons & toggle disabled to true
    const option1Element = document.getElementById(
      "selectOption1_" + currentIndex
    ) as any;
    option1Element.disabled = true;

    const option2Element = document.getElementById(
      "selectOption2_" + currentIndex
    ) as any;
    option2Element.disabled = true;

    // Select and disable third option only if it is not disabled
    if (!steps[currentIndex].data.disableOption3) {
      const option3Element = document.getElementById(
        "selectOption3_" + currentIndex
      ) as any;
      option3Element.disabled = true;
    }

    // Select and disable forth option only if it is not disabled
    if (!steps[currentIndex].data.disableOption4) {
      const option4Element = document.getElementById(
        "selectOption4_" + currentIndex
      ) as any;
      option4Element.disabled = true;
    }

    // Select and disable fifth option only if it is not disabled
    if (!steps[currentIndex].data.disableOption5) {
      const option5Element = document.getElementById(
        "selectOption5_" + currentIndex
      ) as any;
      option5Element.disabled = true;
    }
  } else if (steps[currentIndex].type === "n-select-option-node") {
    const filteredOptions = steps[currentIndex].data.options.filter(
      (option: { optionText: any }) => option.optionText.trim() !== ""
    );

    for (let i = 0; i < filteredOptions.length; i++) {
      const optionElement = document.getElementById(
        "nOptionText_" + i + "_" + currentIndex
      ) as any;
      optionElement.disabled = true;
    }
  }

  // Add the next node to the steps array
  _sleep(600).then(() => {
    _findAndDisplayNode();
  });
};

// Handle ask file upload
export const _handleAskFileUpload = async (
  embedType: any,
  customizations: any,
  file: any
) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // If the file size is larger than 5MB, display error message
  if (file.size > 5000000) {
    _displayErrorMessage(
      embedType,
      customizations,
      "Please upload a file smaller than 5MB"
    );
    return;
  }

  // Add the uploaded file name to the message element
  let userInputMessage = document.createElement("div");
  userInputMessage.style.textAlign = "right";

  userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
          <p class="${embedType}__chat__bubble user"
            style="background-color: ${customizations.userMsgColor};
            text-decoration: underline;
            color: ${customizations.userTextColor};"
            id="user-input_${currentIndex}">
            ${file.name}
          </p>
        </div>`;

  // Add the user input to the steps element
  const stepsElement = document.querySelector("#steps") as any;
  stepsElement.appendChild(userInputMessage);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
  chatBody.scrollTop = stepsElement.scrollHeight + 800;

  // uploadBtn disabled to true
  const uploadBtn = document.querySelector("#uploadBtn") as any;
  uploadBtn.disabled = true;

  // Set the display none for uploadNudge id
  const uploadNudge = document.querySelector("#uploadNudge") as any;
  uploadNudge.style.display = "none";

  // Get uploadSvg element and change cursor to not-allowed
  const uploadSvg = document.querySelector("#uploadSvg") as any;
  uploadSvg.style.cursor = "not-allowed";

  // Attach the file to the form data
  const fmData = new FormData();
  fmData.append("file", file);

  // Record the user input
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-ask-file-response",
    type: steps[currentIndex].data.type,
    fileName: file.name,
    url: file.name,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", file.name);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, file.name);

  // Display the next step
  _findAndDisplayNode();
};
