import { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Select, Input, Tooltip } from "antd";
import { shallow } from "zustand/shallow";
import useStore, { selector } from "../../../../store/store";
import { UserInputNode } from "../../../../common/nodes/typings";
import QuillEditor from "../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../common/components/saveAnswer/saveAnswer";

const UserInputNodeDataEditor: FC<UserInputNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [inputPrompt, setInputPrompt] = useState(state?.data?.inputPrompt);
  const [customInput, setCustomInput] = useState(state?.data?.customInput);
  const [optionType, setOptionType] = useState(state?.data?.type);
  const [nameGreet, setNameGreet] = useState(state?.data?.nameGreet);
  const [incorrectEmailResponse, setIncorrectEmailResponse] = useState(
    state?.data?.incorrectEmailResponse
  );
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );

  // Each time userInputNodeData changes, update the state
  useEffect(() => {
    setInputPrompt(state?.data?.inputPrompt);
    setOptionType(state?.data?.type);
    setCustomInput(state?.data?.customInput);
    setNameGreet(state?.data?.nameGreet);
    setIncorrectEmailResponse(state?.data?.incorrectEmailResponse);
    setAnswerVariable(state?.data?.answerVariable);
  }, [state?.data]);

  // Handle input prompt change
  const handleInputPromptChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the setChoicePrompt
      setInputPrompt(content);

      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, questionText: content },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle input type change
  const handleInputTypeChange = useCallback(
    (value: any) => {
      // Set the setChoicePrompt
      setOptionType(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, type: value },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle handleToggleCustomInput change
  const handleToggleCustomInput = useCallback(
    (e: any) => {
      // Set the setChoicePrompt
      setCustomInput(e.target.checked);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, customInput: e.target.checked },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle handleGreetNameChange change
  const handleGreetNameChange = useCallback(
    (content: any) => {
      // Set the setChoicePrompt
      setNameGreet(content);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, nameGreet: content },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle handleIncorrectEmailResponseChange change
  const handleIncorrectEmailResponseChange = useCallback(
    (content: any) => {
      // Set the setChoicePrompt
      setNameGreet(content);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          incorrectEmailResponse: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle handleCustomInputTypeChange change
  const handleCustomInputTypeChange = useCallback(
    (content: any) => {
      // Set the setChoicePrompt
      setCustomInput(content);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          customInput: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={inputPrompt}
              onEditorChange={handleInputPromptChange}
            />
          </div>
        </Col>

        {!customInput && (
          <>
            {/* 1 Line space */}
            <div
              style={{
                height: "20px",
                width: "100%",
              }}
            />

            <Col span={8} className="sidebar__heading">
              Type
            </Col>
            <Col span={14}>
              <Tooltip
                placement="top"
                title={
                  "Add the type of input you want to request from the user"
                }
              >
                <Select
                  style={{ width: "100%" }}
                  onChange={(value: any) => handleInputTypeChange(value)}
                  value={optionType}
                >
                  <Select.Option value="name">Name</Select.Option>
                  <Select.Option value="email">Email</Select.Option>
                  <Select.Option value="file">File</Select.Option>
                  <Select.Option value="phone">Phone</Select.Option>
                  <Select.Option value="location">Location</Select.Option>
                  <Select.Option value="address">Address</Select.Option>
                  <Select.Option value="date">Date</Select.Option>
                </Select>
              </Tooltip>
            </Col>
          </>
        )}

        {/* 1 Line space */}
        <div
          style={{
            height: "20px",
            width: "100%",
          }}
        />

        {customInput && (
          <>
            {/* 1 Line space */}
            <div
              style={{
                height: "20px",
                width: "100%",
              }}
            />

            <Tooltip
              placement="top"
              title={
                "Enter the name of input you want to request from the user"
              }
            >
              <Row align="middle">
                <Col span={24} className="sidebar__heading">
                  Enter custom input name
                </Col>
                <Col span={24}>
                  <div>
                    <QuillEditor
                      initialContent={optionType}
                      onEditorChange={handleCustomInputTypeChange}
                    />
                  </div>
                </Col>
              </Row>
            </Tooltip>
          </>
        )}

        {/* If input type is name, show greeting message */}
        {optionType === "name" && !customInput && (
          <>
            {/* 1 Line space */}
            <div
              style={{
                height: "10px",
                width: "100%",
              }}
            />
            <Col span={24} className="sidebar__heading">
              Greet Name Response
            </Col>
            <Col span={24}>
              <Tooltip
                placement="top"
                title={"Place {name} to show user's name"}
              >
                <div>
                  <QuillEditor
                    initialContent={nameGreet}
                    onEditorChange={handleGreetNameChange}
                    placeholderText="Ex:Nice meeting you, {name}"
                  />
                </div>
              </Tooltip>
            </Col>
          </>
        )}

        {/* If input type is email, show incorrect email response */}
        {optionType === "email" && !customInput && (
          <>
            {/* 1 Line space */}
            <div
              style={{
                height: "10px",
                width: "100%",
              }}
            />
            <Col span={24} className="sidebar__heading">
              Incorrect Email Response
            </Col>
            <Col span={24}>
              <Tooltip
                placement="top"
                title={
                  "Enter the response to show if user enters an incorrect email"
                }
              >
                <div>
                  <QuillEditor
                    initialContent={incorrectEmailResponse}
                    onEditorChange={handleIncorrectEmailResponseChange}
                    placeholderText="Please enter a valid email"
                  />
                </div>
              </Tooltip>
            </Col>
          </>
        )}

        {/* 1 Line space */}
        <div
          style={{
            height: "20px",
            width: "100%",
          }}
        />

        {/* Radio Button to enter custom input */}
        <Col span={16} className="sidebar__heading">
          Use custom input type?
        </Col>
        <Col span={2}>
          <Input
            type="checkbox"
            checked={customInput}
            onChange={(e) => handleToggleCustomInput(e)}
            style={{ border: "none" }}
          />
        </Col>
        <SaveAnswer
          id={id}
          type={type}
          variableName={answerVariable ? answerVariable : "string"}
        />
      </Row>
    </>
  );
};

export default UserInputNodeDataEditor;
