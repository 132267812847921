import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { RiLoaderLine, RiAddLine } from "react-icons/ri";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createChatbot } from "../../../api/chatbot/chatbot";
import { getAllCategories } from "../../../api/template/template";
import { ErrorNotification } from "../..";

const initialState: any = {
  name: "",
  category: "",
};

const ChatbotCreateModal = ({ open, handleModalClick }: any) => {
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const [values, setValues] = useState(initialState);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle fetch categories
  const fetchCategories = async () => {
    setFetchLoading(true);
    const res = await getAllCategories();
    setCategories(res.data);
    setFetchLoading(false);
  };

  // Handle form change
  const handleFormChange = (e: any) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle chatbot create
  const handleChatbotCreate = async (e: any) => {
    e.preventDefault();

    // Validate form
    if (!values.name) {
      return ErrorNotification("Please enter a name");
    }

    // Set version to "v2"
    values.version = "v2";

    setLoading(true);

    // Create a new chatbot for the admin
    createChatbot(values)
      .then((res) => {
        history.push(
          `/a/ws/${user.workspaceId}/u/${user._id.slice(-5)}/chatbot/${
            res.data._id
          }/builder`
        );

        // Reset the values
        setValues(initialState);

        // Set loading to false
        setLoading(false);
      })
      .catch((err) => {
        ErrorNotification(err.response.data.message);

        // Set loading to false
        setLoading(false);
      });
  };

  // Upload button
  const uploadButton = (
    <div>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal
        title="Create Chatbot"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleChatbotCreate}
            loading={loading}
          >
            Create
          </Button>,
        ]}
      >
        {!fetchLoading && (
          <Form
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            {/* Chatbot name */}
            <Form.Item label="Name">
              <Input
                name="name"
                placeholder="Name your bot"
                onChange={handleFormChange}
                value={values.name}
              />
            </Form.Item>

            {/* Select drop down with category */}
            <Form.Item label="Category">
              <Select
                placeholder="Select a category"
                onChange={(value) => setValues({ ...values, category: value })}
                value={values.category}
              >
                {categories.map((category) => (
                  <Select.Option key={category._id} value={category.name}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default withRouter(ChatbotCreateModal);
