import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Tag,
  Input,
  Menu,
  Dropdown,
  Switch,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RiAddLine, RiFileList2Line, RiBookmark3Line } from "react-icons/ri";
import { FaHashtag } from "react-icons/fa";
import { HiOutlineTemplate } from "react-icons/hi";
import moment from "moment";
import { setTemplatePreviewAction } from "@redux/actions/chatbotActions";
import {
  TemplateCreateModal,
  TemplateUpdateModal,
  SuccessNotification,
  ErrorNotification,
} from "@components";
import {
  deleteChatbotTemplate,
  getAllChatbotTemplatesPaginated,
  updateChatbotTemplateApprovalById,
} from "@api/template/template";
// Preview
import DraggableModal from "../../admin/chatbot/builder/shared/draggableModal";
import ChatbotFlowPreview from "../../admin/chatbot/builder/preview/ChatbotFlowPreview";

const { Search } = Input;

const ChatbotTemplateList = ({ history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [templates, setTemplates] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [updateTemplateId, setUpdateTemplateId] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalSize, setModalSize] = useState("mobile");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const search = searchParams.get("search") || "";

    setSearchText(search);
    setPagination({ current: page, pageSize, total: 0 });
    fetchChatbotTemplates(page, pageSize, search);
  }, [history.location.search]);

  const fetchChatbotTemplates = async (
    page = pagination.current,
    pageSize = pagination.pageSize,
    search = searchText
  ) => {
    setLoading(true);
    try {
      const res = await getAllChatbotTemplatesPaginated(page, pageSize, search);
      setTemplates(res.data.templates);
      setPagination((prev) => ({
        ...prev,
        total: res.data.paginationData.totalTemplates,
        current: res.data.paginationData.page,
        pageSize: res.data.paginationData.perPage,
      }));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching templates", err);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    const searchParams = new URLSearchParams();
    searchParams.set("page", page);
    searchParams.set("pageSize", pageSize);
    if (searchText) searchParams.set("search", searchText);

    history.push({ search: searchParams.toString() });
    fetchChatbotTemplates(page, pageSize, searchText);
  };

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchParams = new URLSearchParams();
    searchParams.set("page", "1");
    searchParams.set("pageSize", pagination.pageSize);
    if (searchValue) searchParams.set("search", searchValue);

    history.push({ search: searchParams.toString() });
    fetchChatbotTemplates(1, pagination.pageSize, searchValue);
  };

  // Handle create template modal open/close
  const handleCreateTemplateModal = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update chatbot modal open/close
  const handleUpdateTemplateModal = (tid) => {
    // Check if 'kon' cookie is present
    const konCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("kon="));

    if (!konCookie) {
      ErrorNotification("You don't have permission to update this template.");
      return;
    }

    setUpdateTemplateId(tid);
    setUpdateModalOpen(!updateModalOpen);
  };

  // Handle chatbot approval
  const handleChatbotTemplateApproval = async (checked, id) => {
    try {
      await updateChatbotTemplateApprovalById(id, { approved: checked });
      fetchChatbotTemplates(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error updating chatbot template approval", error);
    }
  };

  // Handle chatbot click
  const handleChatbotClick = (id) => {
    // If user is superadmin, redirect to chatbot builder
    if (user?.role === "superadmin") {
      history.push(`/super-admin/chatbot/${id}/builder`);
    } else if (user?.role === "marketing") {
      history.push(`/marketing-admin/chatbot/${id}/builder`);
    }
  };

  // Handle chatbot delete
  const handleTemplateDelete = (tid, id) => {
    // Check if 'kon' cookie is present
    const konCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("kon="));

    if (!konCookie) {
      ErrorNotification("You don't have permission to delete this template.");
      return;
    }

    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this template?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Delete chatbot
          await deleteChatbotTemplate(tid, id);
          // Fetch all chatbots
          fetchChatbotTemplates(pagination.current, pagination.pageSize);

          // Show success notification
          SuccessNotification("Template deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting template");
        }
      },
    });
  };

  const menu = (
    <Menu>
      {/* Create Template */}
      <Menu.Item
        key="1"
        icon={<RiFileList2Line />}
        onClick={() => handleCreateTemplateModal()}
      >
        Create Template
      </Menu.Item>
      {/* View all templates */}
      <Menu.Item
        key="2"
        icon={<HiOutlineTemplate />}
        onClick={() => {
          // If user role is super admin, redirect to super admin templates
          if (user?.role === "superadmin") {
            history.push("/super-admin/chatbot/templates");
          } else if (user?.role === "marketing") {
            history.push("/marketing-admin/chatbot/templates");
          }
        }}
      >
        View Templates
      </Menu.Item>

      {/* Template Categories */}
      <Menu.Item
        key="3"
        icon={<RiBookmark3Line />}
        onClick={() => {
          // If user role is super admin, redirect to super admin template categories
          if (user?.role === "superadmin") {
            history.push("/super-admin/templates/categories");
          } else if (user?.role === "marketing") {
            history.push("/marketing-admin/templates/categories");
          }
        }}
      >
        Template Categories
      </Menu.Item>

      {/* Template Tags */}
      <Menu.Item
        key="4"
        icon={<FaHashtag />}
        onClick={() => {
          // If user role is super admin, redirect to super admin template tags
          if (user?.role === "superadmin") {
            history.push("/super-admin/templates/tags");
          } else if (user?.role === "marketing") {
            history.push("/marketing-admin/templates/tags");
          }
        }}
      >
        Template Tags
      </Menu.Item>
    </Menu>
  );

  // Define columns for Ant Design Table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
            color: "#0011F7",
          }}
          onClick={() => handleChatbotClick(record.chatbot._id)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => <Tag color="blue">{category}</Tag>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).fromNow(),
    },
    {
      title: "Likes",
      dataIndex: "likes",
      key: "likes",
      render: (likes) => <span>{likes?.length ? likes.length : 0}</span>,
    },
    {
      title: "Forks",
      dataIndex: "forks",
      key: "forks",
      render: (forks) => <span>{forks?.length ? forks.length : 0}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 350, // Adjust as needed
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Open Builder */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleChatbotClick(record.chatbot._id)}
          >
            Open Builder
          </Button>

          {/* View */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleTemplatePreviewClick(record)}
          >
            Preview
          </Button>

          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleUpdateTemplateModal(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleTemplateDelete(record._id, record.chatbot._id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
    // Approved
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      // Render a switch to toggle template approval
      render: (approved, record) => (
        <Switch
          checked={approved}
          onChange={(checked) =>
            handleChatbotTemplateApproval(checked, record._id)
          }
        />
      ),
    },
  ];

  // Handle template view click
  const handleTemplatePreviewClick = (template) => {
    dispatch(setTemplatePreviewAction(template));
    setModalVisible(true);
  };

  // Handle close modal
  const closeModal = () => {
    setModalVisible(false);
  };

  // Handle set mobile / desktop preview
  const toggleModalSize = (value) => {
    setModalSize(value);
  };

  return (
    <>
      {/* Create Chatbot Modal */}
      {createModalOpen && (
        <TemplateCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateTemplateModal}
        />
      )}
      {/* Update Chatbot Modal */}
      {updateModalOpen && (
        <TemplateUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateTemplateModal}
          templateId={updateTemplateId}
          handleFetchChatbots={fetchChatbotTemplates}
        />
      )}

      <Card style={{ borderRadius: "0", height: "100vh" }}>
        {/* Preview Modal */}
        <DraggableModal visible={modalVisible} positionValueY={700}>
          {/* Content of the modal */}
          <div
            style={{
              height: "75vh",
              width: modalSize === "mobile" ? "25vw" : "45vw",
              transition: "all 0.1s ease-out",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              overflow: "hidden",
            }}
          >
            {/* Modal body */}
            <div>
              <ChatbotFlowPreview
                closeModal={closeModal}
                toggleModalSize={toggleModalSize}
              />
            </div>
          </div>
        </DraggableModal>

        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <h3>Chatbot Templates</h3>
          </Col>

          <Col>
            {/* Search templates */}
            <Search
              placeholder="Search by name/category"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={handleSearch}
              style={{ width: "300px", marginRight: "15px" }}
            />

            {/* Template Options */}
            <Dropdown overlay={menu} placement="bottomRight">
              <Button icon={<RiAddLine />} />
            </Dropdown>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={templates}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          loading={loading}
          onChange={({ current, pageSize }) =>
            handlePaginationChange(current, pageSize)
          }
          rowKey={(record) => record._id}
          scroll={{ y: "calc(100vh - 270px)" }}
        />
      </Card>
    </>
  );
};

export default ChatbotTemplateList;
