import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "antd";
import { MdArrowBack } from "react-icons/md";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  General,
  Layout,
  InitialPage,
  Chat,
  Widget,
  KnowledgeBase,
  AnnouncementBanner,
  Advanced,
} from "./options";
import {
  saveCurrentChatbotCustomizationsAction,
  updateCurrentChatbotCustomizationsAction,
} from "@redux/actions/chatbotActions";
import { useTranslation } from "react-i18next";
// Icons
import GeneralIcon from "@assets/images/pages/customize/general.png";
import InitialPageIcon from "@assets/images/pages/customize/initial-page.png";
import LayoutIcon from "@assets/images/pages/customize/layout.png";
import ChatIcon from "@assets/images/pages/customize/chat.png";
import AnnouncementBannerIcon from "@assets/images/pages/customize/announcement-banner.png";
import KnowledgeBaseIcon from "@assets/images/pages/customize/knowledge-base.png";
import ChatbotIcon from "@assets/images/pages/customize/chatbot.png";
import CodeIcon from "@assets/images/pages/customize/code.png";
// CSS
import "@assets/css/CustomizeSidebar.css";

const CustomizeSidebar = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const { t } = useTranslation();

  const [activeOption, setActiveOption] = useState(null);
  const [applyLoading, setApplyLoading] = useState(false);

  const basePath =
    user?.role === "superadmin"
      ? "/super-admin"
      : `/a/ws/${user?.workspaceId}/u/${user._id.slice(-5)}`;

  // Handle option click
  const handleOptionClick = (option) => {
    setActiveOption(option);
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "sidebarActiveOption",
        option
      )
    );
  };

  // Handle customizations save
  const handleCustomizationsSave = () => {
    setApplyLoading(true);
    dispatch(
      saveCurrentChatbotCustomizationsAction(
        bid,
        customizations,
        setApplyLoading
      )
    );
  };

  // Making the sidebarActiveOption to Null, when close the sidebar option
  useEffect(() => {
    if (activeOption === null) {
      dispatch(
        updateCurrentChatbotCustomizationsAction(bid, "sidebarActiveOption", "")
      );
    }
  }, [activeOption]);

  return (
    <Col
      span={12}
      sm={16}
      md={11}
      lg={7}
      style={{
        backgroundColor: "#fff",
        height: "100%",
      }}
    >
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
        className="hp-d-flex hp-h-100"
        style={{ flexDirection: "column" }}
      >
        <div
          className={`left-sidebar ${
            activeOption === null && "left-sidebar-padding"
          }`}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Link
              to={
                user.role === "superadmin"
                  ? `/super-admin/chatbot/${bid}/builder`
                  : `/a/ws/${user.workspaceId}/u/${user._id.slice(
                      -5
                    )}/chatbot/${bid}/builder`
              }
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Button
                type="text"
                icon={<MdArrowBack size={24} />}
                size="large"
              />
            </Link>
            <h3
              style={{
                margin: "0",
              }}
            >
              {t("customize.customizations")}
            </h3>
          </Row>

          {activeOption === null && (
            <ul className="options-list">
              {/* General */}
              <li onClick={() => handleOptionClick("general")}>
                <div className="options-list-inner">
                  <img
                    src={GeneralIcon}
                    style={{
                      height: "26px",
                      width: "30px",
                      marginRight: "15px",
                    }}
                    alt="general icon"
                  />
                  <div>
                    <h3 className="sidebar-label">
                      {t("customize.general.general")}
                    </h3>
                    <p className="sidebar-desc">
                      {t("customize.general.fontOtherSettings")}
                    </p>
                  </div>
                </div>
              </li>

              {/* Layout */}
              <li onClick={() => handleOptionClick("layout")}>
                <div className="options-list-inner">
                  <img
                    src={LayoutIcon}
                    style={{
                      height: "27px",
                      width: "27px",
                      marginRight: "15px",
                    }}
                    alt="layout icon"
                  />
                  <div>
                    <h3 className="sidebar-label">
                      {t("customize.layout.layout")}
                    </h3>
                    <p className="sidebar-desc">
                      {t("customize.layout.header&Footer")}
                    </p>
                  </div>
                </div>
              </li>

              {/* Initial Page */}
              <li onClick={() => handleOptionClick("initial-page")}>
                <div className="options-list-inner">
                  <img
                    src={InitialPageIcon}
                    style={{
                      height: "26px",
                      width: "30px",
                      marginRight: "15px",
                    }}
                    alt="general icon"
                  />
                  <div>
                    <h3 className="sidebar-label">
                      {t("customize.initialPage.initialPage")}
                    </h3>
                    <p className="sidebar-desc">
                      {t("customize.initialPage.initialPageDesc")}
                    </p>
                  </div>
                </div>
              </li>

              {/* Chat */}
              <li onClick={() => handleOptionClick("chat")}>
                <div className="options-list-inner">
                  <img
                    src={ChatIcon}
                    style={{
                      height: "30px",
                      width: "30px",
                      marginRight: "15px",
                    }}
                    alt="chat icon"
                  />
                  <div>
                    <h3 className="sidebar-label">
                      {t("customize.chat.chat")}
                    </h3>
                    <p className="sidebar-desc">
                      {t("customize.chat.AvatarMessage&Background")}
                    </p>
                  </div>
                </div>
              </li>

              {/* Knowledge Base */}
              <li onClick={() => handleOptionClick("knowledge-base")}>
                <div className="options-list-inner">
                  <img
                    src={KnowledgeBaseIcon}
                    style={{
                      height: "26px",
                      width: "30px",
                      marginRight: "15px",
                    }}
                    alt="general icon"
                  />
                  <div>
                    <h3 className="sidebar-label">
                      {t("customize.knowledgeBase.knowledgeBase")}
                    </h3>
                    <p className="sidebar-desc">
                      {t("customize.knowledgeBase.knowledgeBaseDesc")}
                    </p>
                  </div>
                </div>
              </li>

              {/* Widget */}
              <li onClick={() => handleOptionClick("widget")}>
                <div className="options-list-inner">
                  <img
                    src={ChatbotIcon}
                    style={{
                      height: "30px",
                      width: "30px",
                      marginRight: "15px",
                    }}
                    alt="widget icon"
                  />
                  <div>
                    <h3 className="sidebar-label">
                      {t("customize.widget.widget")}
                    </h3>
                    <p className="sidebar-desc">
                      {t("customize.widget.widgetPositionIcon&More")}
                    </p>
                  </div>
                </div>
              </li>

              {/* Announcement Banner */}
              <li onClick={() => handleOptionClick("announcement-banner")}>
                <div className="options-list-inner">
                  <img
                    src={AnnouncementBannerIcon}
                    style={{
                      height: "auto",
                      width: "40px",
                      marginRight: "15px",
                      marginLeft: "-5px",
                    }}
                    alt="general icon"
                  />
                  <div>
                    <h3 className="sidebar-label">Announcement Banner</h3>
                    <p className="sidebar-desc">
                      Showcase your latest announcements on the banner
                    </p>
                  </div>
                </div>
              </li>

              {/* Advanced */}
              <li onClick={() => handleOptionClick("advanced")}>
                <div className="options-list-inner">
                  <img
                    src={CodeIcon}
                    style={{
                      height: "30px",
                      width: "30px",
                      marginRight: "15px",
                    }}
                    alt="code icon"
                  />
                  <div>
                    <h3 className="sidebar-label">
                      {t("customize.advanced.advanced")}
                    </h3>
                    <p className="sidebar-desc">
                      {t("customize.advanced.auto-open,soundAndMore")}
                    </p>
                  </div>
                </div>
              </li>

              {/* Next Step */}
              <Link to={`${basePath}/chatbot/${bid}/embed`}>
                <Button
                  size={"large"}
                  type="primary"
                  style={{
                    width: "100%",
                    margin: "20px 0px",
                  }}
                >
                  {t("customize.nextStep")}
                </Button>
              </Link>
            </ul>
          )}

          {activeOption === "general" && (
            <General setActiveOption={setActiveOption} />
          )}

          {activeOption === "initial-page" && (
            <InitialPage setActiveOption={setActiveOption} />
          )}

          {activeOption === "layout" && (
            <Layout setActiveOption={setActiveOption} />
          )}

          {activeOption === "chat" && (
            <Chat setActiveOption={setActiveOption} />
          )}

          {activeOption === "knowledge-base" && (
            <KnowledgeBase setActiveOption={setActiveOption} />
          )}

          {activeOption === "widget" && (
            <Widget setActiveOption={setActiveOption} />
          )}

          {activeOption === "announcement-banner" && (
            <AnnouncementBanner setActiveOption={setActiveOption} />
          )}

          {activeOption === "advanced" && (
            <Advanced setActiveOption={setActiveOption} />
          )}
        </div>

        {activeOption !== null && (
          <div className="content-footer">
            <h5>{t("customize.applyChanges")}</h5>
            <div>
              <Button
                size={"small"}
                className="content-footer-btn"
                style={{
                  marginRight: "6px",
                  backgroundColor: "#ebf1f5",
                  color: "#45496e",
                }}
                onClick={() => setActiveOption(null)}
              >
                {t("customize.goBack")}
              </Button>
              <Button
                size={"small"}
                className="content-footer-btn"
                style={{ backgroundColor: "#38bf85", color: "#fff" }}
                onClick={() => {
                  handleCustomizationsSave();
                }}
                loading={applyLoading}
              >
                {t("customize.apply")}
              </Button>
            </div>
          </div>
        )}
      </motion.div>
    </Col>
  );
};

export default CustomizeSidebar;
