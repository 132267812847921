import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Divider, Switch, Button } from "antd";
import { useTranslation } from "react-i18next";

const SecurityInfo = () => {
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";

  const { t } = useTranslation();

  return (
    <div className="hp-profile-security">
      <h2>{t("profile.plans.securitySettings")}</h2>
      <p className="hp-p1-body hp-mb-0">
        {t("profile.plans.securitySettingsInfo")}
      </p>

      <Divider className={dividerClass} />

      <Row align="middle" justify="space-between">
        <Col md={12}>
          <h3> {t("profile.plans.saveMyActivity")}</h3>
          <p className="hp-p1-body hp-mb-0">
            {t("profile.plans.saveMyActivityInfo")}
          </p>
        </Col>

        <Col className="hp-mt-md-24">
          <Switch disabled />
        </Col>
      </Row>

      <Divider className={dividerClass} />

      <Row align="middle" justify="space-between">
        <Col md={12}>
          <h3>{t("profile.plans.changePassword")}</h3>
          <p className="hp-p1-body hp-mb-0">
            {t("profile.plans.changePasswordInfo")}
          </p>
        </Col>

        <Col className="hp-mt-md-24">
          <Link to="/profile/password">
            <Button type="primary">{t("profile.plans.changePassword")}</Button>
          </Link>
        </Col>
      </Row>

      <Divider className={dividerClass} />

      <Row align="middle" justify="space-between">
        <Col md={12}>
          <h3>{t("profile.plans.twoFactorAuth")}</h3>
          <p className="hp-p1-body hp-mb-0">
            {t("profile.plans.twoFactorAuthInfo")}
          </p>
        </Col>

        <Col className="hp-mt-md-24">
          <Button type="primary" disabled>
            {t("profile.plans.disable")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SecurityInfo;
