import { FC } from "react";
import { Nodes } from "../../common/nodes/typings";
import { NodeTypes } from "../../types/types";

// **********  v1 (Legacy Nodes) **********
import SelectOptionNodeDataEditor from "./nodes/v1/selectOptionNode";
import TwoChoicesNodeDataEditor from "./nodes/v1/twoChoicesNode";
import ThreeChoicesNodeDataEditor from "./nodes/v1/threeChoicesNode";
import RatingNodeDataEditor from "./nodes/v1/ratingNode";
import UserNodeDataEditor from "./nodes/v1/userInputNode";
import RangeNodeDataEditor from "./nodes/v1/rangeNode";
import QuizNodeDataEditor from "./nodes/v1/quizNode";

// **********  v2 (New Nodes) **********
// ** STARTER NODE **
import WelcomeNodeDataEditor from "./nodes/v2/Starter/welcomeNode";

// ** SEND RESPONSE NODES **
import ImageNodeDataEditor from "./nodes/v2/SendResponse/imageNode";
import MessageNodeDataEditor from "./nodes/v2/SendResponse/messageNode";
import VideoNodeDataEditor from "./nodes/v2/SendResponse/videoNode";
import AudioNodeDataEditor from "./nodes/v2/SendResponse/audioNode";
import FileNodeDataEditor from "./nodes/v2/SendResponse/fileNode";

// ** ASK QUESTION NODES **
import AskNameNodeDataEditor from "./nodes/v2/AskQuestions/askName";
import AskEmailNodeDataEditor from "./nodes/v2/AskQuestions/askEmail";
import AskPhoneNumberNodeDataEditor from "./nodes/v2/AskQuestions/askPhoneNumber";
import AskFileNodeDataEditor from "./nodes/v2/AskQuestions/askFile";
import AskNumberNodeDataEditor from "./nodes/v2/AskQuestions/askNumber";
import AskUrlNodeDataEditor from "./nodes/v2/AskQuestions/askUrl";
import AskMultipleQuestionsNodeDataEditor from "./nodes/v2/AskQuestions/askMultipleQuestions";
import CalendarNodeDataEditor from "./nodes/v2/AskQuestions/calendarNode";
import NCheckOptionsNodeDataEditor from "./nodes/v2/AskQuestions/nCheckOptionsNode";
import NSelectOptionNodeDataEditor from "./nodes/v2/AskQuestions/nSelectOptionNode";
import AskLocationNodeDataEditor from "./nodes/v2/AskQuestions/askLocation";
import AskCustomQuestionNodeDataEditor from "./nodes/v2/AskQuestions/askCustomQuestions";

// ** FLOW OPERATION NODES **
import NChoicesNodeDataEditor from "./nodes/v2/FlowOperations/nChoicesNode";
import ImageChoiceNodeDataEditor from "./nodes/v2/FlowOperations/imageChoice";
import RatingChoiceNodeDataEditor from "./nodes/v2/FlowOperations/ratingChoice";
import YesOrNoDataEditor from "./nodes/v2/FlowOperations/yesOrNo";
import OpinionNodeDataEditor from "./nodes/v2/FlowOperations/opinionScale";

// ** LOGICAL NODES **
import BusinessHoursNodeDataEditor from "./nodes/v2/Logical/businessHours";
import ConditionNodeDataEditor from "./nodes/v2/Logical/conditionNode";
import MathOperationsNodeDataEditor from "./nodes/v2/Logical/mathOperations";
import BooleanLogicNodeDataEditor from "./nodes/v2/Logical/booleanLogicNode";
import RandomFlowNodeDataEditor from "./nodes/v2/Logical/randomFlow";
import VariableNodeDataEditor from "./nodes/v2/Logical/variableNode";

// ** SPECIAL NODES **
import EmailNodeDataEditor from "./nodes/v2/Special/emailNode";
import HtmlNodeDataEditor from "./nodes/v2/Special/htmlNode";
import RedirectNodeDataEditor from "./nodes/v2/Special/redirectNode";
import GptNodeDataEditor from "./nodes/v2/Integrations/gptNode";
import JumpToNodeDataEditor from "./nodes/v2/Special/jumpToNode";
import HumanHandoverNodeDataEditor from "./nodes/v2/Special/humanHandoverNode";
import DelayNodeDataEditor from "./nodes/v2/Special/delayNode";
import NavigateNodeDataEditor from "./nodes/v2/Special/navigateNode";

// ** INTEGRATION NODES **
import WebhookNodeDataEditor from "./nodes/v2/Integrations/webhookNode";
import GoogleSheetsNodeDataEditor from "./nodes/v2/Integrations/googleSheetsNode";
import ZapierNodeDataEditor from "./nodes/v2/Integrations/zapier";
import SlackNodeDataEditor from "./nodes/v2/Integrations/slack";
import AirtableNodeDataEditor from "./nodes/v2/Integrations/airtable";
import ZohoNodeDataEditor from "./nodes/v2/Integrations/zoho";
import HubspotNodeDataEditor from "./nodes/v2/Integrations/hubspot";
import MailChimpNodeDataEditor from "./nodes/v2/Integrations/mailChimpNode";
import SegmentNodeDataEditor from "./nodes/v2/Integrations/segment";
import SalesforceNodeDataEditor from "./nodes/v2/Integrations/salesforce";

// A component which renders the specific editor for the selected node
const NodeDataEditor: FC<{ node: Nodes }> = ({ node }) => {
  // Individual node data editors handle their own state and save it as needed
  switch (node.type) {
    // **********  v1 (Legacy Nodes) **********
    // ** DEPRECATED NODES **
    case NodeTypes.SelectOption:
      return <SelectOptionNodeDataEditor {...node} />;
    case NodeTypes.TwoChoices:
      return <TwoChoicesNodeDataEditor {...node} />;
    case NodeTypes.ThreeChoices:
      return <ThreeChoicesNodeDataEditor {...node} />;
    case NodeTypes.Rating:
      return <RatingNodeDataEditor {...node} />;
    case NodeTypes.UserInput:
      return <UserNodeDataEditor {...node} />;
    case NodeTypes.Range:
      return <RangeNodeDataEditor {...node} />;
    case NodeTypes.Quiz:
      return <QuizNodeDataEditor {...node} />;

    // **********  v2 (New Nodes) **********
    // ** STARTER NODE **
    case NodeTypes.Welcome:
      return <WelcomeNodeDataEditor {...node} />;

    // ** SEND RESPONSE NODES **
    case NodeTypes.Message:
      return <MessageNodeDataEditor {...node} />;
    case NodeTypes.Image:
      return <ImageNodeDataEditor {...node} />;
    case NodeTypes.Video:
      return <VideoNodeDataEditor {...node} />;
    case NodeTypes.Audio:
      return <AudioNodeDataEditor {...node} />;

    // ** ASK QUESTION NODES **
    case NodeTypes.AskName:
      return <AskNameNodeDataEditor {...node} />;
    case NodeTypes.AskEmail:
      return <AskEmailNodeDataEditor {...node} />;
    case NodeTypes.AskUrl:
      return <AskUrlNodeDataEditor {...node} />;
    case NodeTypes.AskPhoneNumber:
      return <AskPhoneNumberNodeDataEditor {...node} />;
    case NodeTypes.AskFile:
      return <AskFileNodeDataEditor {...node} />;
    case NodeTypes.AskNumber:
      return <AskNumberNodeDataEditor {...node} />;
    case NodeTypes.AskMultipleQuestions:
      return <AskMultipleQuestionsNodeDataEditor {...node} />;
    case NodeTypes.Calendar:
      return <CalendarNodeDataEditor {...node} />;
    case NodeTypes.NCheckOptions:
      return <NCheckOptionsNodeDataEditor {...node} />;
    case NodeTypes.NSelectOption:
      return <NSelectOptionNodeDataEditor {...node} />;
    case NodeTypes.AskLocation:
      return <AskLocationNodeDataEditor {...node} />;
    case NodeTypes.AskCustomQuestion:
      return <AskCustomQuestionNodeDataEditor {...node} />;

    // ** FLOW OPERATION NODES **
    case NodeTypes.NChoices:
      return <NChoicesNodeDataEditor {...node} />;
    case NodeTypes.ImageChoice:
      return <ImageChoiceNodeDataEditor {...node} />;
    case NodeTypes.RatingChoice:
      return <RatingChoiceNodeDataEditor {...node} />;
    case NodeTypes.YesOrNo:
      return <YesOrNoDataEditor {...node} />;
    case NodeTypes.OpinionScale:
      return <OpinionNodeDataEditor {...node} />;

    // ** LOGICAL NODES **
    case NodeTypes.Condition:
      return <ConditionNodeDataEditor {...node} />;
    case NodeTypes.BusinessHours:
      return <BusinessHoursNodeDataEditor {...node} />;
    case NodeTypes.BooleanLogic:
      return <BooleanLogicNodeDataEditor {...node} />;
    case NodeTypes.MathOperation:
      return <MathOperationsNodeDataEditor {...node} />;
    case NodeTypes.RandomFlow:
      return <RandomFlowNodeDataEditor {...node} />;
    case NodeTypes.Variable:
      return <VariableNodeDataEditor {...node} />;

    // ** SPECIAL NODES **
    case NodeTypes.Email:
      return <EmailNodeDataEditor {...node} />;
    case NodeTypes.Html:
      return <HtmlNodeDataEditor {...node} />;
    case NodeTypes.Redirect:
      return <RedirectNodeDataEditor {...node} />;
    case NodeTypes.Gpt:
      return <GptNodeDataEditor {...node} />;
    case NodeTypes.JumpTo:
      return <JumpToNodeDataEditor {...node} />;
    case NodeTypes.HumanHandover:
      return <HumanHandoverNodeDataEditor {...node} />;
    case NodeTypes.Delay:
      return <DelayNodeDataEditor {...node} />;
    case NodeTypes.File:
      return <FileNodeDataEditor {...node} />;
    case NodeTypes.Navigate:
      return <NavigateNodeDataEditor {...node} />;

    // ** INTEGRATION NODES **
    case NodeTypes.Webhook:
      return <WebhookNodeDataEditor {...node} />;
    case NodeTypes.GoogleSheets:
      return <GoogleSheetsNodeDataEditor {...node} />;
    case NodeTypes.Zapier:
      return <ZapierNodeDataEditor {...node} />;
    case NodeTypes.Slack:
      return <SlackNodeDataEditor {...node} />;
    case NodeTypes.Airtable:
      return <AirtableNodeDataEditor {...node} />;
    case NodeTypes.Zoho:
      return <ZohoNodeDataEditor {...node} />;
    case NodeTypes.HubSpot:
      return <HubspotNodeDataEditor {...node} />;
    case NodeTypes.MailChimp:
      return <MailChimpNodeDataEditor {...node} />;
    case NodeTypes.Segment:
      return <SegmentNodeDataEditor {...node} />;
    case NodeTypes.Salesforce:
      return <SalesforceNodeDataEditor {...node} />;

    default:
      return null;
  }
};

export default NodeDataEditor;
