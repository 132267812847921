import { useState, useEffect, FC, useCallback } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Select,
  Tooltip,
  message,
  Switch,
} from "antd";
import { shallow } from "zustand/shallow";
import {
  DeleteOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { PiDotsNineBold } from "react-icons/pi";
import { WebhookNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import Arrow from "../../../../../../../../../assets/images/pages/customize/down.png";
import axios from "axios";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const WebhookNodeDataEditor: FC<WebhookNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [tokenUrl, setTokenUrl] = useState(
    state?.data?.authentication?.tokenUrl
  );
  const [username, setUsername] = useState(
    state?.data?.authentication?.username || ""
  );
  const [password, setPassword] = useState(
    state?.data?.authentication?.password || ""
  );
  const [isTokenUrlValid, setIsTokenUrlValid] = useState(false);

  const [url, setUrl] = useState(state?.data.url);
  const [method, setMethod] = useState(state?.data.method);
  const [headers, setHeaders] = useState(state?.data?.headers || []);
  const [body, setBody] = useState(state?.data?.body);
  const [includeAnswerVariables, setIncludeAnswerVariables] = useState(
    state?.data?.includeAnswerVariables
  );
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );

  const [toggleHeader, setToggleHeader] = useState(true);
  const [toggleBody, setToggleBody] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  const { Option } = Select;
  const { t } = useTranslation();

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setUrl(state?.data?.url);
    setMethod(state?.data?.method);
    setHeaders(state?.data?.headers);
    setBody(state?.data?.body);
    setIncludeAnswerVariables(state?.data?.includeAnswerVariables);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  // Test the token URL if username and password are provided
  useEffect(() => {
    if (username && password) {
      testTokenUrl();
    }
  }, []);

  const handleSetTokenUrl = (e: any) => {
    if (!state) return;

    setTokenUrl(e.target.value);

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        authentication: {
          ...state?.data?.authentication,
          tokenUrl: e.target.value,
        },
      },
    });
  };

  const handleSetUsername = (e: any) => {
    if (!state) return;
    setUsername(e.target.value);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        authentication: {
          ...state?.data?.authentication,
          username: e.target.value,
        },
      },
    });
  };

  const handleSetPassword = (e: any) => {
    if (!state) return;
    setPassword(e.target.value);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        authentication: {
          ...state?.data?.authentication,
          password: e.target.value,
        },
      },
    });
  };

  // Function to test Token URL
  const testTokenUrl = async () => {
    // Validate the token URL format
    if (!tokenUrl || !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(tokenUrl)) {
      // Show a message that it failed
      message.warn("Invalid Token URL format. Please provide a valid URL.");
      return;
    }

    // Validate the username and password
    if (!username || !password) {
      // Show a message that it failed
      message.warn("Username and password cannot be empty.");
      return;
    }

    try {
      // Make a POST request to the token URL
      const response = await axios.post(tokenUrl, {
        username,
        password,
      });

      // If response contains "access" token set isTokenUrlValid to true
      if (response.data.access) {
        setIsTokenUrlValid(true);

        // Show a message that ir is successful
        message.success("Token URL test successful.");
      } else {
        setIsTokenUrlValid(false);

        // Show a message that it failed
        message.warn(
          "Token URL failed. Response does not contain an 'access' token."
        );
      }
    } catch (error) {
      setIsTokenUrlValid(false);

      // Show a message that it failed
      message.error(
        "Token URL failed. Response does not contain an 'access' token."
      );
    }
  };

  // Handle setting the url
  const handleSetUrl = (e: any) => {
    if (!state) return;

    setUrl(e.target.value);

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        url: e.target.value,
      },
    });
  };

  // Handle setting the method
  const handleSetMethod = (value: any) => {
    if (!state) return;

    // Set the welcome message
    setMethod(value);

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        method: value,
      },
    });
  };

  // Handle setting the includeAnswerVariables
  const handleIncludeAnswerVariablesChange = (value: any, key: string) => {
    if (!state) return;

    // Set the welcome message
    setIncludeAnswerVariables(value);

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        [key]: value,
      },
    });
  };

  // Handle adding a new header
  const handleAddHeader = useCallback(() => {
    const headerId = uuidv4();

    const newHeader = {
      id: headerId,
      key: "",
      value: "",
    };

    setActive(headerId);
    setHeaders([...headers, newHeader]);

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        headers: [...headers, newHeader],
      },
    });
  }, [headers, state, type, changeNodeData]);

  // Handle deleting a header
  const handleDeleteHeader = useCallback(
    (headerId: any) => {
      const updatedOptions = headers.filter(
        (header: any) => header.id !== headerId
      );
      setHeaders(updatedOptions);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          headers: updatedOptions,
        },
      });
    },
    [headers, state, type, changeNodeData]
  );

  // OnDrag Function
  const onDragEnd = (e: any) => {
    const { sourceIndex, targetIndex } = e;
    setHeaders((prevQuestions: any) => {
      const updatedCards = [...prevQuestions];
      const [movedCard] = updatedCards.splice(sourceIndex, 1);

      updatedCards.splice(targetIndex, 0, movedCard);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state.data,
          headers: updatedCards,
        },
      });
      return updatedCards;
    });
  };

  // Open and Hide toggle Function
  const toggleDropdown = (id: any) => {
    setIsOpen(!isOpen);
    setActive(id);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [active]);

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.integrationNodes.webhookNodeInfo")}
          </p>
        </Row>

        <Col span={24} className="sidebar__heading">
          <Tooltip
            title={t(
              "flowBuilder.sidebar.editor.integrationNodes.authenticationTT"
            )}
          >
            {t("flowBuilder.sidebar.editor.integrationNodes.authentication")}
            <span
              style={{
                fontSize: "13px",
                color: "gray",
                fontStyle: "italic",
              }}
            ></span>
          </Tooltip>
        </Col>

        <Col span={24}>
          <Input
            addonBefore="Token URL"
            placeholder="Token URL"
            style={{
              borderRadius: "4px",
              marginTop: "8px",
              marginBottom: "20px",
            }}
            value={tokenUrl}
            onChange={(e) => handleSetTokenUrl(e)}
          />
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <Input
            addonBefore={t(
              "flowBuilder.sidebar.editor.integrationNodes.username"
            )}
            placeholder={t(
              "flowBuilder.sidebar.editor.integrationNodes.username"
            )}
            value={username}
            onChange={handleSetUsername}
          />
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <Input.Password
            addonBefore={t(
              "flowBuilder.sidebar.editor.integrationNodes.password"
            )}
            placeholder={t(
              "flowBuilder.sidebar.editor.integrationNodes.password"
            )}
            value={password}
            onChange={handleSetPassword}
          />
        </Col>

        {/* Add Test Token URL Button */}
        <Col
          span={24}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isTokenUrlValid ? (
            // Render a checkmark icon and "Test Token URL" label if the token URL is valid
            <Tooltip
              title={t(
                "flowBuilder.sidebar.editor.integrationNodes.validTokenTT"
              )}
            >
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                style={{ backgroundColor: "#319500", borderColor: "#52c41a" }}
                onClick={testTokenUrl}
              >
                {t("flowBuilder.sidebar.editor.integrationNodes.testTokenURL")}
              </Button>
            </Tooltip>
          ) : (
            // Render a question mark icon and "Test Token URL" label if the token URL is not valid
            <Tooltip
              title={t(
                "flowBuilder.sidebar.editor.integrationNodes.notValidTokenTT"
              )}
            >
              <Button
                type="primary"
                icon={<QuestionCircleOutlined />}
                onClick={testTokenUrl}
              >
                {t("flowBuilder.sidebar.editor.integrationNodes.testTokenURL")}
              </Button>
            </Tooltip>
          )}
        </Col>

        {/* Webhook URL */}
        <Col span={24} className="sidebar__heading">
          {t("flowBuilder.sidebar.editor.integrationNodes.webhookURL")}
        </Col>

        <Col span={24} style={{ marginBottom: "12px" }}>
          <Input
            addonBefore="URL"
            placeholder="Webhook URL"
            style={{ borderRadius: "4px", marginTop: "8px" }}
            value={url}
            onChange={(e) => handleSetUrl(e)}
            disabled={!isTokenUrlValid}
          />
        </Col>

        {/* Webhook Configuration */}
        <Col span={24} className="api_configration">
          <div className="toggle_Btn">
            <span>
              {t("flowBuilder.sidebar.editor.integrationNodes.webhookConfig")}
            </span>
          </div>
          <>
            <div>
              <Row
                justify="space-between"
                style={{ fontWeight: "600", marginTop: "10px" }}
              >
                {t("flowBuilder.sidebar.editor.integrationNodes.method")}
                <Select
                  defaultValue="GET"
                  size="small"
                  style={{ width: "100px" }}
                  onChange={handleSetMethod}
                  value={method}
                  disabled={!isTokenUrlValid}
                >
                  <Option value="GET">
                    {t("flowBuilder.sidebar.editor.integrationNodes.get")}
                  </Option>
                  <Option value="POST">
                    {t("flowBuilder.sidebar.editor.integrationNodes.post")}
                  </Option>
                  <Option value="PUT">
                    {t("flowBuilder.sidebar.editor.integrationNodes.put")}
                  </Option>
                  <Option value="DELETE">
                    {t("flowBuilder.sidebar.editor.integrationNodes.delete")}
                  </Option>
                  <Option value="PATCH">
                    {t("flowBuilder.sidebar.editor.integrationNodes.patch")}
                  </Option>
                </Select>
              </Row>
            </div>

            <div className="dropdown-menu" style={{ marginTop: "10px" }}>
              <div
                onClick={() => setToggleHeader(!toggleHeader)}
                className="dropdown-trigger"
                style={{ padding: "8px 10px" }}
              >
                <span>
                  {t("flowBuilder.sidebar.editor.integrationNodes.headers")}
                </span>
                <img
                  src={Arrow}
                  className={toggleHeader ? "arrow-rotate" : ""}
                  alt="arrow"
                />
              </div>

              {toggleHeader && (
                <div>
                  <Col span={24} className="drag_multi_options_container">
                    {headers.map((header: any, index: any) => (
                      <>
                        <Col
                          className={`drag_multi_options_inner ${
                            isOpen && active === header.id ? "open" : ""
                          }`}
                        >
                          <div
                            className="drag_dropdown_button"
                            onClick={() => toggleDropdown(header?.id)}
                            draggable
                            onDragStart={(e) =>
                              e.dataTransfer.setData(
                                "text/plain",
                                index.toString()
                              )
                            }
                            onDrop={(e) => {
                              e.preventDefault();
                              onDragEnd({
                                sourceIndex: Number(
                                  e.dataTransfer.getData("text/plain")
                                ),
                                targetIndex: index,
                              });
                            }}
                            onDragOver={(e) => e.preventDefault()}
                            style={{
                              position: "relative",
                              border:
                                isOpen && active === header.id
                                  ? "2px solid #fff"
                                  : "2px solid transparent",
                              boxShadow:
                                isOpen &&
                                active === header.id &&
                                "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            }}
                          >
                            <Col>
                              <div
                                style={{
                                  left: "10px",
                                }}
                              >
                                {t(
                                  "flowBuilder.sidebar.editor.integrationNodes.header"
                                )}
                                {index + 1}
                              </div>
                            </Col>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                type="text"
                                icon={<PiDotsNineBold />}
                                size={"small"}
                                className="drag_button"
                              />
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                size={"small"}
                                style={{ borderRadius: "4px" }}
                                onClick={() => handleDeleteHeader(header.id)}
                                className="drag_dlt_button"
                              />
                            </div>
                          </div>

                          {isOpen && active === header.id && (
                            <div className="drag_isOpen_container">
                              <Row>
                                <Col span={24}>
                                  <Row>
                                    <Col span={4} style={{ marginTop: "8px" }}>
                                      {t(
                                        "flowBuilder.sidebar.editor.integrationNodes.key"
                                      )}
                                    </Col>
                                    <Col span={20}>
                                      <Input
                                        value={header?.key}
                                        onChange={(e) => {
                                          // Find the index of the matching header in the questions array
                                          const questionIndex =
                                            headers.findIndex(
                                              (q: any) => q.id === header.id
                                            );

                                          // Copy the header and modify the questionText
                                          const updatedHeader = {
                                            ...headers[questionIndex],
                                            key: e.target.value,
                                          };

                                          // Copy the questions array and replace the header at the found index
                                          const updatedHeaders = [...headers];
                                          updatedHeaders[questionIndex] =
                                            updatedHeader;

                                          // Save the updated questions array back to the state
                                          setHeaders(updatedHeaders);
                                          changeNodeData({
                                            ...state,
                                            type,
                                            data: {
                                              ...state.data,
                                              headers: updatedHeaders,
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: "6px" }}>
                                    <Col span={4} style={{ marginTop: "8px" }}>
                                      {t(
                                        "flowBuilder.sidebar.editor.integrationNodes.value"
                                      )}
                                    </Col>
                                    <Col span={20}>
                                      <Input
                                        value={header?.value}
                                        onChange={(e) => {
                                          // Find the index of the matching header in the questions array
                                          const questionIndex =
                                            headers.findIndex(
                                              (q: any) => q.id === header.id
                                            );

                                          // Copy the header and modify the questionText
                                          const updatedHeader = {
                                            ...headers[questionIndex],
                                            value: e.target.value,
                                          };

                                          // Copy the questions array and replace the header at the found index
                                          const updatedHeaders = [...headers];
                                          updatedHeaders[questionIndex] =
                                            updatedHeader;

                                          // Save the updated questions array back to the state
                                          setHeaders(updatedHeaders);
                                          changeNodeData({
                                            ...state,
                                            type,
                                            data: {
                                              ...state.data,
                                              headers: updatedHeaders,
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                      </>
                    ))}

                    <Col span={24}>
                      <Button
                        onClick={handleAddHeader}
                        block
                        type="primary"
                        icon={<PlusOutlined />}
                        disabled={!isTokenUrlValid}
                      >
                        {t(
                          "flowBuilder.sidebar.editor.integrationNodes.addHeader"
                        )}
                      </Button>
                    </Col>
                  </Col>
                </div>
              )}
            </div>

            <div className="dropdown-menu" style={{ marginTop: "10px" }}>
              <div
                onClick={() => setToggleBody(!toggleBody)}
                className="dropdown-trigger"
                style={{ padding: "8px 10px" }}
              >
                <span>
                  {t("flowBuilder.sidebar.editor.integrationNodes.body")}
                </span>
                <img
                  src={Arrow}
                  className={toggleBody ? "arrow-rotate" : ""}
                  alt="arrow"
                />
              </div>

              {toggleBody && (
                <div style={{ marginTop: "6px" }}>
                  <Input.TextArea
                    rows={4}
                    value={body}
                    onChange={(e) => {
                      setBody(e.target.value);

                      changeNodeData({
                        ...state,
                        type,
                        data: {
                          ...state.data,
                          body: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </>
        </Col>

        {/* Include Answer Variables Switch */}
        <Col span={20} style={{ marginTop: "16px", marginBottom: "16px" }}>
          <h3 className="hp-mb-6 hp-mt-8 sidebar__heading">
            {t("flowBuilder.sidebar.editor.integrationNodes.sendWebhookBody")}
          </h3>
          <p
            className="hp-mb-12 hp-mt-4"
            style={{
              fontSize: "10px",
              color: "gray",
            }}
          >
            {t(
              "flowBuilder.sidebar.editor.integrationNodes.sendWebhookBodyInfo"
            )}
          </p>
        </Col>

        <Col span={4} style={{ marginBottom: "16px" }}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked
            checked={includeAnswerVariables}
            onChange={(checked: any) => {
              setIncludeAnswerVariables(checked);
              handleIncludeAnswerVariablesChange(
                checked,
                "includeAnswerVariables"
              );
            }}
            disabled={!isTokenUrlValid}
          />
        </Col>

        <SaveAnswer id={id} type={type} variableName={answerVariable} />
      </Row>
    </>
  );
};

export default WebhookNodeDataEditor;
