import React from "react";
import { Table, Button, Row, Col, Tag } from "antd";
import { useHistory, useParams } from "react-router-dom";

const Team = ({ team }) => {
  const { id } = useParams();

  const history = useHistory();

  const getRoleColor = (role) => {
    switch (role.toLowerCase()) {
      case "admin":
        return "red";
      case "agent":
        return "green";
      default:
        return "blue";
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => (
        <Tag color={getRoleColor(role)}>{role.toUpperCase()}</Tag>
      ),
    },
  ];

  const handleViewTeamDetails = () => {
    history.push(`/super-admin/admin/${id}/team-details`);
  };

  return (
    <div>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "1.5rem" }}
      >
        <Col>
          <h4 style={{ margin: 0 }}>Team</h4>
        </Col>
        <Col>
          <Button type="primary" onClick={handleViewTeamDetails}>
            View Team Details
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={team}
        columns={columns}
        rowKey="_id"
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default Team;
