import api from "../index";

// ** --------- Changelog API --------- ** //
// Create a changelog
export const createChangelog = async (values: any) => {
  return await api.post("/changelog", values);
};

// Get all blogCategories
export const getAllChangelogs = async () => {
  return await api.get(`/changelogs`);
};

// Get a changelog by id
export const getChangelogById = async (id: any) => {
  return await api.get(`/changelog/${id}`);
};

// Update a changelog by id
export const updateChangelogById = async (id: any, values: any) => {
  return await api.put(`/changelog/${id}`, values);
};

// Delete a changelog by id
export const deleteChangelogById = async (id: any) => {
  return await api.delete(`/changelog/${id}`);
};
