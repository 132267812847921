import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// English
import en from "./en.json";
// Hindi
import hi from "./hi.json";
// French
import fr from "./fr.json";
// Italian
import it from "./it.json";
// Japanese
import jp from "./jp.json";
// German
import de from "./de.json";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: "en",
  resources: {
    en: en,
    hi: hi,
    fr: fr,
    it: it,
    jp: jp,
    de: de,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
