import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tag, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAdminSubscriptionAction } from "@redux/actions/adminActions";
import { manageSubscription } from "@api/subscription/stripe";
import { useTranslation } from "react-i18next";

const MySubscription = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { currentSubscription } = useSelector((state) => state.admin) ?? {};
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (user.role === "admin") {
      fetchAdminSubscription();
    }
  }, []);

  const fetchAdminSubscription = async () => {
    setLoading(true);
    dispatch(getAdminSubscriptionAction(setLoading));
  };

  const handleManageSub = (e) => {
    e.preventDefault();
    setLoading(true);
    manageSubscription()
      .then((res) => {
        window.location.href = res.data.portalUrl;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const formatDate = (timestamp) => {
    return timestamp ? moment.unix(timestamp).format("DD MMM YYYY") : "N/A";
  };

  const renderBillingDetails = () => {
    if (loading) {
      return <Skeleton active paragraph={{ rows: 5 }} />;
    }

    const isFreePlan = currentSubscription?.currentPlan === "Free";

    return (
      <ul>
        <li>
          <span className="hp-p1-body"> {t("profile.plans.planName")}</span>
          <span
            className="hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0"
            style={{ fontWeight: "bold" }}
          >
            {currentSubscription?.currentPlan}
          </span>
        </li>
        <li className="hp-mt-18">
          <span className="hp-p1-body">{t("profile.plans.status")}</span>
          <span className="hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
            <Tag color="blue">{currentSubscription?.subscriptionStatus}</Tag>
          </span>
        </li>
        <li className="hp-mt-18">
          <span className="hp-p1-body">{t("profile.plans.startDate")}</span>
          <span className="hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
            {isFreePlan
              ? currentSubscription?.subscription?.startPeriod
              : formatDate(currentSubscription?.subscription?.startPeriod)}
          </span>
        </li>
        <li className="hp-mt-18">
          <span className="hp-p1-body">{t("profile.plans.billCycle")}</span>
          <span
            className="hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0"
            style={{ textTransform: "capitalize", fontWeight: "bold" }}
          >
            {currentSubscription?.subscription?.billInterval
              ? currentSubscription?.subscription?.billInterval + "ly"
              : "N/A"}
          </span>
        </li>
        <li className="hp-mt-18">
          <span className="hp-p1-body">
            {isFreePlan
              ? t("profile.plans.nextAllocation")
              : t("profile.plans.nextBilling")}
          </span>
          <span className="hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
            {isFreePlan
              ? currentSubscription?.subscription?.endPeriod
              : formatDate(currentSubscription?.subscription?.endPeriod)}
          </span>
        </li>
      </ul>
    );
  };

  return (
    <div style={{ margin: "50px" }}>
      <Row justify="space-between" align="middle" style={{ width: "100%" }}>
        <Col md={16} span={24}>
          <h3>{t("profile.plans.myBillingDetails")}</h3>
        </Col>
        <Col md={8} span={24} className="hp-profile-action-btn hp-text-right">
          <Button
            type="primary"
            ghost
            onClick={handleManageSub}
            disabled={
              currentSubscription?.currentPlan === "Free" ||
              currentSubscription?.currentPlan === "Lifetime" ||
              !currentSubscription?.billingAdmin
            }
            loading={loading}
          >
            {t("profile.plans.manageSubscription")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          className="hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120"
        >
          {renderBillingDetails()}
        </Col>
      </Row>
    </div>
  );
};

export default MySubscription;
