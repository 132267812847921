import React from "react";
import { useTranslation } from "react-i18next";

const CustomizeChatMessages = ({ customizations }) => {
  const { t } = useTranslation();
  return (
    <div className="customize-chat-container">
      <div className="msg-container">
        {!customizations?.hideAvatar && (
          <>
            <img
              src={customizations?.avatar}
              style={{
                height: `${customizations?.avatarSize}px`,
                width: `${customizations?.avatarSize}px`,
                marginRight: `${customizations?.avatar && "10px"} `,
                borderRadius: "50%",
              }}
              alt="avatar"
            />
          </>
        )}
        <span
          className="message"
          style={{
            borderRadius: customizations?.bubbleBorderRadius,
            backgroundColor: customizations?.botMsgColor,
            color: customizations?.botTextColor,
          }}
        >
          {t("customize.chatUi.welcomeToTheConferbot")}🎉
        </span>
      </div>

      <div className="msg-container">
        {!customizations?.hideAvatar && (
          <>
            <img
              src={customizations?.avatar}
              style={{
                height: `${customizations?.avatarSize}px`,
                width: `${customizations?.avatarSize}px`,
                marginRight: `${customizations?.avatar && "10px"} `,
                borderRadius: "50%",
              }}
              alt="avatar"
            />
          </>
        )}
        <span
          className="message"
          style={{
            borderRadius: customizations?.bubbleBorderRadius,
            backgroundColor: customizations?.botMsgColor,
            color: customizations?.botTextColor,
          }}
        >
          {t("customize.chatUi.simpleNodCodeInfo")} 😎
        </span>
      </div>

      <div className="user-message-container">
        <div
          className="user-message"
          style={{
            borderRadius: customizations?.bubbleBorderRadius,
            backgroundColor: customizations?.userMsgColor,
            color: customizations?.userTextColor,
          }}
        >
          {t("customize.chatUi.heyConferbot")} 🎉
        </div>
      </div>

      <div className="msg-container">
        {!customizations?.hideAvatar && (
          <>
            <img
              src={customizations?.avatar}
              style={{
                height: `${customizations?.avatarSize}px`,
                width: `${customizations?.avatarSize}px`,
                marginRight: `${customizations?.avatar && "10px"} `,
                borderRadius: "50%",
              }}
              alt="avatar"
            />
          </>
        )}
        <span
          className="message"
          style={{
            borderRadius: customizations?.bubbleBorderRadius,
            backgroundColor: customizations?.botMsgColor,
            color: customizations?.botTextColor,
          }}
        >
          {t("customize.chatUi.howCanIHelpYou")}
        </span>
      </div>

      <div className="user-message-container">
        <div
          className="user-message"
          style={{
            borderRadius: customizations?.bubbleBorderRadius,
            backgroundColor: customizations?.userMsgColor,
            color: customizations?.userTextColor,
          }}
        >
          {t("customize.chatUi.knowMoreAboutProduct")}🎉
        </div>
      </div>

      <div className="msg-container">
        {!customizations?.hideAvatar && (
          <>
            <img
              src={customizations?.avatar}
              style={{
                height: `${customizations?.avatarSize}px`,
                width: `${customizations?.avatarSize}px`,
                marginRight: `${customizations?.avatar && "10px"} `,
                borderRadius: "50%",
              }}
              alt="avatar"
            />
          </>
        )}
        <span
          className="message"
          style={{
            borderRadius: customizations?.bubbleBorderRadius,
            backgroundColor: customizations?.botMsgColor,
            color: customizations?.botTextColor,
          }}
        >
          {t("customize.chatUi.sureICanHelp")} 🎉
        </span>
      </div>

      {/* Option Bubbles */}
      <div className="msg-container">
        <div className="options-container">
          <span
            className="option"
            style={{
              borderRadius: customizations?.optionBubbleBorderRadius,
              backgroundColor: customizations?.optionBubbleMsgColor,
              color: customizations?.optionBubbleTextColor,
            }}
          >
            {t("customize.chatUi.aboutProduct")}
          </span>
          <span
            className="option"
            style={{
              borderRadius: customizations?.optionBubbleBorderRadius,
              backgroundColor: customizations?.optionBubbleMsgColor,
              color: customizations?.optionBubbleTextColor,
            }}
          >
            {t("customize.chatUi.contactUs")}
          </span>
          <span
            className="option"
            style={{
              borderRadius: customizations?.optionBubbleBorderRadius,
              backgroundColor: customizations?.optionBubbleMsgColor,
              color: customizations?.optionBubbleTextColor,
            }}
          >
            {t("customize.chatUi.pricing")}
          </span>
        </div>
      </div>

      <div className="user-message-container">
        <div
          className="user-message"
          style={{
            borderRadius: customizations?.bubbleBorderRadius,
            backgroundColor: customizations?.userMsgColor,
            color: customizations?.userTextColor,
          }}
        >
          {t("customize.chatUi.aboutProductDetails")}
        </div>
      </div>
    </div>
  );
};

export default CustomizeChatMessages;
