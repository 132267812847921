import { useState } from "react";
import { Dropdown, InputNumber, Menu } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import Arrow from "@assets/images/pages/customize/down.png";
import PositionIcon from "@assets/images/pages/customize/position.png";
import { useTranslation } from "react-i18next";

const WidgetPosition = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const handleWidgetPositionChange = (e) => {
    handleUpdate("widgetPosition", e.key);
  };

  // Handle update
  const handleUpdate = (key, value) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, value));
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => {
            setVisible(!visible);
          }}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={PositionIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="position-icon"
            />
            {t("customize.widget.position")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            style={{
              height: "20px",
              width: "20px",
              marginLeft: "auto",
            }}
            alt="arrow-icon"
          />
        </div>
        {visible && (
          <>
            <div style={{ padding: "20px 25px", backgroundColor: "#d5d7e4" }}>
              <span> {t("customize.widget.widgetPosition")}</span>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu onClick={handleWidgetPositionChange}>
                    <Menu.Item key="right">
                      {t("customize.widget.right")}
                    </Menu.Item>
                    <Menu.Item key="left">
                      {t("customize.widget.left")}
                    </Menu.Item>
                  </Menu>
                }
              >
                <button
                  className="Avatar-dropdown-button"
                  style={{ marginTop: "15px" }}
                >
                  {customizations?.widgetPosition
                    ? customizations?.widgetPosition?.charAt(0).toUpperCase() +
                      customizations?.widgetPosition?.slice(1)
                    : "Select an Position"}
                </button>
              </Dropdown>

              <span>
                {t("customize.widget.offset")} {customizations?.widgetPosition}{" "}
                {t("customize.widget.position").toLowerCase()}
              </span>
              <InputNumber
                value={
                  customizations?.widgetPosition === "right"
                    ? customizations?.widgetOffsetRight || 10
                    : customizations?.widgetOffsetLeft || 10
                }
                addonAfter="px"
                min={10}
                style={{ margin: "12px 0px 15px 0px ", width: "100%" }}
                onChange={(value) =>
                  customizations?.widgetPosition === "right"
                    ? dispatch(
                        updateCurrentChatbotCustomizationsAction(
                          bid,
                          "widgetOffsetRight",
                          value
                        )
                      )
                    : dispatch(
                        updateCurrentChatbotCustomizationsAction(
                          bid,
                          "widgetOffsetLeft",
                          value
                        )
                      )
                }
              />

              <span> {t("customize.widget.offsetBottomPosition")}</span>
              <InputNumber
                value={customizations?.widgetOffsetBottom}
                addonAfter="px"
                min={10}
                style={{ margin: "12px 0px 15px 0px ", width: "100%" }}
                onChange={(value) =>
                  dispatch(
                    updateCurrentChatbotCustomizationsAction(
                      bid,
                      "widgetOffsetBottom",
                      value
                    )
                  )
                }
              />

              <span>{t("customize.widget.chatIconSize")}</span>
              <InputNumber
                defaultValue={50}
                addonAfter="px"
                min={40}
                max={80}
                style={{ margin: "12px 0px 15px 0px ", width: "100%" }}
                onChange={(value) =>
                  dispatch(
                    updateCurrentChatbotCustomizationsAction(
                      bid,
                      "widgetSize",
                      value
                    )
                  )
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WidgetPosition;
