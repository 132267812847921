import {
  // Find and display node
  _findAndDisplayNode,
} from "../../ChatbotFlowPreview";
import { _sleep } from "../../utils/helpers";

// Handle delay node
export const _handleDelayNode = (nodeData: any) => {
  // Node data contains "delay" in seconds, delay the next node by that much time
  setTimeout(() => {
    _findAndDisplayNode();
  }, nodeData.delay * 1000);
};

// Handle navigation done3 selection
export const _handleNavigationDoneSelection = () => {
  // Add the next node to the steps array
  _sleep(600).then(() => {
    _findAndDisplayNode();
  });
};
