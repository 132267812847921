import confetti from "canvas-confetti";

const defaults = {
  zIndex: 1500,
};

function fire() {
  confetti(
    Object.assign({}, defaults, {
      particleCount: 300,
      angle: 40,
      spread: 200,
      origin: { x: 0 },
      colors: colors,
    })
  );
  confetti(
    Object.assign({}, defaults, {
      particleCount: 300,
      angle: 120,
      spread: 200,
      origin: { x: 1 },
      colors: colors,
    })
  );
}

var colors = [
  "#FFF", // white
  "#2e67f8", // blue
  "#2e67f8", // blue
  "#14BB69", // brand green, repeated to increase rate
  "#50BAE2",
  "#A4FFF5",
  "#FFBF28",
  "#FF5447",
  "#f6b1be",
];

export const fireConfetti = () => {
  fire();
};
