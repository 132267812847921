import { Row, Col, Button, Card, Tag, Dropdown, Menu, Switch } from "antd";
import { FiMoreVertical } from "react-icons/fi";
import { RiEdit2Fill, RiDeleteBin6Fill } from "react-icons/ri";
import { HiDuplicate } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const ChatbotCard = ({
  index,
  chatbot,
  // Open
  handleOpenChatbotBuilder,
  // Update
  handleUpdateChatbot,
  // Disable
  handleDisableChatbot,
  // Duplicate
  handleDuplicateChatbot,
  // Delete
  handleDeleteChatbot,
  // Background Image
  backgroundImage,
}: any) => {
  const { t } = useTranslation();

  return (
    <Col
      key={index}
      xs={4}
      sm={5}
      md={4}
      lg={4}
      style={{
        margin: "10px",
      }}
    >
      <Card
        style={{
          boxShadow: "0px 0px 15px -12px rgba(0,0,0,0.75)",
        }}
      >
        {chatbot?.disabled && (
          <div className="chatbot-card-ribbon">
            <span>{t("chatbotList.disabledText")}</span>
          </div>
        )}
        <div
          style={{ maxHeight: "300px", overflow: "hidden", maxWidth: "300px" }}
        >
          <Row>
            <Col className="hp-text-center" span={24}>
              <Row
                justify="space-between"
                align="top"
                style={{
                  marginBottom: "2rem",
                  marginRight: "2.5rem",
                }}
              >
                <Tag className="hp-border-none" color="red">
                  {chatbot.category}
                </Tag>

                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      {/* Edit */}
                      <Menu.Item
                        key={0}
                        onClick={() => handleUpdateChatbot(chatbot._id)}
                        icon={
                          <RiEdit2Fill
                            size={16}
                            style={{ marginRight: "14px" }}
                          />
                        }
                      >
                        {t("chatbotList.edit")}
                      </Menu.Item>

                      {/* Disable */}
                      <Menu.Item key="toggle-enable">
                        <Switch
                          size="small"
                          checked={chatbot?.disabled}
                          onChange={(checked) =>
                            handleDisableChatbot(chatbot._id, checked)
                          }
                          style={{ marginRight: "10px" }}
                        />
                        {t("chatbotList.disableBot")}
                      </Menu.Item>

                      {/* Duplicate */}
                      <Menu.Item
                        key={3}
                        onClick={() => handleDuplicateChatbot(chatbot._id)}
                        icon={
                          <HiDuplicate
                            size={16}
                            style={{ marginRight: "14px" }}
                          />
                        }
                      >
                        {t("chatbotList.duplicateBot")}
                      </Menu.Item>

                      {/* Delete */}
                      <Menu.Item
                        key={4}
                        onClick={() => handleDeleteChatbot(chatbot?._id)}
                        icon={
                          <RiDeleteBin6Fill
                            size={16}
                            style={{ marginRight: "14px" }}
                          />
                        }
                      >
                        {t("chatbotList.delete")}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    size="small"
                    style={{
                      border: "none",
                      position: "absolute",
                      right: "-10px",
                      top: "-5px",
                    }}
                  >
                    <FiMoreVertical className="remix-icon" size={24} />
                  </Button>
                </Dropdown>
              </Row>

              <img
                className="hp-my-8"
                src={backgroundImage}
                alt="chatbot"
                style={{ maxHeight: "90px" }}
              />

              <h5
                className="hp-mt-16 hp-mb-16 hp-font-weight-500"
                style={{
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "#0b4eb8",
                }}
              >
                {chatbot.name}
              </h5>

              <Button
                type="primary"
                size="small"
                onClick={() => handleOpenChatbotBuilder(chatbot._id)}
                id={index === 0 && "joyride-first-bot-cloned-open-builder"}
                style={{
                  backgroundColor: "#0b4eb8",
                }}
              >
                {t("chatbotList.openBuilder")}
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default ChatbotCard;
