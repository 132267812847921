import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Tag,
  Table,
  Input,
  Pagination,
} from "antd";
import {
  getAllCouponsPaginated,
  deleteCouponById,
} from "@api/subscription/coupon";
import { SuccessNotification, ErrorNotification } from "@components";

const { Search } = Input;

const CouponsList = ({ history }) => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });

  useEffect(() => {
    fetchCoupons();
  }, [pagination.current, pagination.pageSize]);

  // Fetch coupons with pagination and optional search text
  const fetchCoupons = async () => {
    setLoading(true);
    getAllCouponsPaginated(pagination.current, pagination.pageSize, searchText)
      .then((res) => {
        setCoupons(res.data.coupons);
        setPagination((p) => ({
          ...p,
          total: res.data.paginationData.totalCoupons,
          current: res.data.paginationData.page, // Update current page if needed
          pageSize: res.data.paginationData.perPage, // Update page size if needed
        }));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Fetch coupons error", err);
      });
  };

  const handleCouponCreate = () => {
    history.push("/super-admin/coupons/create");
  };

  const handleCouponUpdate = (id) => {
    history.push(`/super-admin/coupons/update/${id}`);
  };

  const handleCouponDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this coupon?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteCouponById(id);
          SuccessNotification("Coupon deleted successfully");
          fetchCoupons(pagination.current, pagination.pageSize, searchText);
        } catch (error) {
          ErrorNotification("Error deleting coupon");
        }
      },
    });
  };

  const columns = [
    {
      title: "Coupon Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text) => <span>{text} %</span>, // Assuming discount is a percentage
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry",
      key: "expiry",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleCouponUpdate(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleCouponDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <Card style={{ borderRadius: "0", height: "100vh" }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h3>Coupons List</h3>
        </Col>
        <Col>
          <Button type="primary" onClick={handleCouponCreate}>
            Create Coupon
          </Button>
          <Search
            placeholder="Search by name/code"
            style={{ width: "300px", marginLeft: 16 }}
            onSearch={(value) => {
              setSearchText(value);

              fetchCoupons();
            }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={coupons}
        pagination={false}
        loading={loading}
        rowKey={(record) => record._id}
      />
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        pagination={pagination}
        onChange={(p) =>
          setPagination({
            ...pagination,
            current: p,
          })
        }
        style={{ marginTop: 16 }}
        scroll={{ y: "calc(100vh - 270px)" }} // Adjust height as needed
      />
    </Card>
  );
};

export default CouponsList;
