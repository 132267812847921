function ChatDate({ dateTime }) {
  return (
    <>
      {new Date(dateTime).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        // Display time
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      })}
    </>
  );
}

export default ChatDate;
