import { Dispatch } from "redux";
import { ACTION_TYPES } from "../constants/actionTypes";
import { MEMBER_TYPES } from "../constants/memberTypes";
import {
  // Chatbot
  getMemberChatbotsName,
  // Chatbot Responses
  getMemberChatbotResponses,
  getMemberChatResponseMessages,
  // Team
  getAgentTeamMembers,
  // Status
  updateMemberStatus,
  // Tickets
  getMemberHandledTickets,
  getMemberUnHandledTickets,
  // Notifications
  getMemberNotifications,
  markAllNotificationsAsRead,
} from "../../api/user/member";

// Assuming the setLoading function takes a boolean and returns void
type SetLoadingType = (isLoading: boolean) => void;

/********************************************
  Get member chatbots name action
*********************************************/
export const getMemberChatbotsNameAction =
  (setBotNamesLoading: SetLoadingType) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await getMemberChatbotsName();

      // Dispatch chatbot names
      dispatch({
        type: MEMBER_TYPES.GET_MEMBER_CHATBOTS_NAME,
        payload: res.data,
      });

      setBotNamesLoading(false);

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_MEMBER_CHATBOT_NAMES_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Get selected chatbot responses action
*********************************************/
export const getAgentSelectedChatbotResponsesAction =
  (
    id: any,
    page: any,
    size: any,
    setSelectedBotName: any,
    setLoadMoreLoading: SetLoadingType
  ) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const response = await getMemberChatbotResponses(id, page, size);

      // If response was successful, dispatch success action to store responses
      dispatch({
        type: MEMBER_TYPES.SELECTED_BOT_RESPONSES,
        payload: {
          id: id,
          version: response.data.version,
          responses: response.data.responses,
          tickets: response.data.tickets,
          hasMore: response.data.hasMore,
          page: page,
          size: size,
        },
      });

      // Set selected bot name
      setSelectedBotName(response.data.name);

      // Set load more loading to false
      setLoadMoreLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_MEMBER_SELECTED_CHATBOT_RESPONSES_ERROR",
        },
      });
    }
  };

/********************************************
  Update chatbot responses with latest chat
*********************************************/
export const updateAgentSelectedChatbotResponsesAction =
  (data: any, setSelectedChat: any, setMessageLoading: any) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Dispatch selected chatbot responses only if the user is on the /chats page
      // If they're not on the /chats page, then the selected chatbot responses will be updated when they navigate to the /chats page
      if (data?.isOnChatsPage) {
        dispatch({
          type: MEMBER_TYPES.UPDATE_SELECTED_BOT_RESPONSES,
          payload: data,
        });
      }

      // Set the selected chat
      setSelectedChat(data);
      setMessageLoading(false);
      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_SELECTED_BOT_RESPONSES_ERROR",
        },
      });
    }
  };

/********************************************
 * Get member team list action
 *******************************************/
export const getMemberTeamListAction =
  (setTeamListLoading: SetLoadingType) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await getAgentTeamMembers();

      // Dispatch team list
      dispatch({
        type: MEMBER_TYPES.GET_MEMBER_TEAM_LIST,
        payload: res.data,
      });

      setTeamListLoading(false);

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_MEMBER_TEAM_LIST_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
 * Update member status action
 *******************************************/
export const updateMemberStatusAction =
  (id: any, status: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      await updateMemberStatus(status);

      // Determine the isOnline and isEngaged values based on the status
      let isOnline = false;
      let isEngaged = false;
      if (status === "available") {
        isOnline = true;
        isEngaged = false;
      } else if (status === "offline") {
        isOnline = false;
        isEngaged = false;
      }

      // Dispatch team list with updated status, isOnline, and isEngaged values
      dispatch({
        type: MEMBER_TYPES.UPDATE_MEMBER_STATUS,
        payload: { _id: id, status, isOnline, isEngaged },
      });

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_MEMBER_STATUS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Update chatbot response with latest message
*********************************************/
export const updateAgentBotWithLatestMessageAction =
  (chatSessionId: any, data: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Dispatch a success alert
      dispatch({
        type: MEMBER_TYPES.UPDATE_CHAT_WITH_LATEST_MESSAGE,
        payload: {
          chatSessionId: chatSessionId,
          data: data,
        },
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_SELECTED_BOT_RESPONSE_ERROR",
        },
      });
    }
  };

/********************************************
 * Get member handled tickets action
 *******************************************/
export const getMemberHandledTicketsAction =
  (page: Number, size: Number, setLoadMoreLoading: SetLoadingType) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const response = await getMemberHandledTickets(page, size);

      // If response was successful, dispatch success action to store responses
      dispatch({
        type: MEMBER_TYPES.GET_MEMBER_HANDLED_TICKETS,
        payload: {
          handledTickets: response.data.handledTickets,
          hasMore: response.data.hasMore,
          page: page,
          size: size,
        },
      });

      // Set load more loading to false
      setLoadMoreLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_MEMBER_HANDLED_TICKETS_ERROR",
        },
      });
    }
  };

/********************************************
 * Get member unHandled tickets action
 *******************************************/
export const getMemberUnHandledTicketsAction =
  (page: Number, size: Number, setLoadMoreLoading: SetLoadingType) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const response = await getMemberUnHandledTickets(page, size);

      // If response was successful, dispatch success action to store responses
      dispatch({
        type: MEMBER_TYPES.GET_MEMBER_UNHANDLED_TICKETS,
        payload: {
          unHandledTickets: response.data.unHandledTickets,
          hasMore: response.data.hasMore,
          page: page,
          size: size,
        },
      });

      // Set load more loading to false
      setLoadMoreLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_MEMBER_UNHANDLED_TICKETS_ERROR",
        },
      });
    }
  };

/********************************************
  Update selected chat with latest messages
*********************************************/
export const updateAgentSelectedChatWithLatestResponseMessagesAction =
  (chatId: any, setSelectedChat: any, setMessageLoading: any) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Get the latest messages for the selected chat
      const res = await getMemberChatResponseMessages(chatId);

      // Dispatch a success alert
      dispatch({
        type: MEMBER_TYPES.UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES,
        payload: res.data.response,
      });

      // Set the selected chat
      setSelectedChat(res.data.response);

      // Set message loading to false
      setMessageLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES_ERROR",
        },
      });
    }
  };

/********************************************
  Get member notifications action
*********************************************/
export const getMemberNotificationsAction =
  (page: any, size: any, setLoadMoreLoading: any) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const response = await getMemberNotifications(page, size);

      // If response was successful, dispatch success action to store responses
      dispatch({
        type: MEMBER_TYPES.GET_MEMBER_NOTIFICATIONS,
        payload: {
          notifications: response.data.notifications,
          hasMore: response.data.hasMore,
          page: page,
          size: size,
        },
      });

      // Set load more loading to false
      setLoadMoreLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_MEMBER_NOTIFICATIONS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Mark all notifications as read action
*********************************************/
export const markAllNotificationsAsReadAction =
  () => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Make the API call
      await markAllNotificationsAsRead();

      // If response was successful, dispatch success action to store responses
      dispatch({
        type: MEMBER_TYPES.MARK_ALL_NOTIFICATIONS_AS_READ,
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "MARK_ALL_NOTIFICATIONS_AS_READ_ACTION_ERROR",
        },
      });
    }
  };
