import api from "../index";

// Check if the user is admin
export const currentAdmin = async () => {
  return await api.post("/currentAdmin", {});
};

// ********** Chatbots ********** //
// Get all admin chatbots with templates paginated
export const getAdminChatbotsWithTemplates = async (
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(
      `/admin/chatbots-templates?page=${page}&perPage=${perPage}`
    );
  } else {
    return await api.get(
      `/admin/chatbots-templates?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Get all admin chatbots names
export const getAdminChatbotsName = async () => {
  return await api.get("/admin/chatbots/name");
};

// Get admin chatbot responses
export const getAdminChatbotResponses = async (
  id: any,
  page: any,
  size: any
) => {
  return await api.get(
    `/admin/chatbot/${id}/responses?page=${page}&pageSize=${size}`
  );
};

// Archive chatbot response
export const archiveChatbotResponse = async (bid: any, rid: any) => {
  return await api.put(`/admin/chatbot/${bid}/response/${rid}/archive`);
};

// Send embed email instructions
export const sendEmbedEmailInstructions = async (values: any) => {
  return await api.post("/admin/embed/email/instructions", values);
};

// ********** Chats ********** //
// Get admin chat messages
export const getAdminChatResponseMessages = async (id: any) => {
  return await api.get(`/admin/chat/response/${id}/messages`);
};

// ********** Chat Tickets ********** //
// Get admin chat ticket by id
export const getAdminChatTicketById = async (id: any) => {
  return await api.get(`/admin/chat/ticket/${id}`);
};

// ********** Analytics ********** //
// Get admin dashboard analytics
export const getAdminDashboardAnalytics = async () => {
  return await api.get("/admin/dashboard/analytics");
};

// ********** Subscription ********** //
// Get admin subscription
export const getAdminSubscription = async () => {
  return await api.get("/admin/subscription");
};

// ********** Team ********** //
// Invite team member
export const inviteTeamMember = async (values: any) => {
  return await api.post("/admin/team/invite", values);
};

// Accept team invite
export const acceptTeamInvite = async (values: any) => {
  return await api.post("/admin/team/account/activate", values);
};

// Get team members
export const getTeamMembers = async () => {
  return await api.get("/admin/team/members");
};

// Remove team member
export const removeTeamMember = async (uid: any) => {
  return await api.delete(`/admin/team/member/${uid}`);
};
