import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slider, Switch } from "antd";
import { useParams } from "react-router-dom";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import loadWidgetDelay from "@assets/images/pages/customize/load-widget.png";
import { useTranslation } from "react-i18next";

const LoadWidgetDelay = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(customizations?.loadWidgetDelay);
  const { t } = useTranslation();

  const handleClick = () => {
    setVisible(!visible);
    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "loadWidgetDelay", !visible)
    );
  };

  return (
    <>
      <div className="dropdown-menu">
        <div onClick={handleClick} className="dropdown-trigger">
          <span>
            <img
              src={loadWidgetDelay}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="paint-palette-icon"
            />
            {t("customize.advanced.loadWidgetDelay")}
          </span>
          <Switch size="small" checked={customizations?.loadWidgetDelay} />
        </div>

        {visible && (
          <>
            <div style={{ padding: "20px 25px", backgroundColor: "#d5d7e4" }}>
              <span>
                {" "}
                {t("customize.advanced.loadWidgetAfter(in seconds)")}
              </span>
              <div style={{ marginTop: "15px" }}>
                <Slider
                  style={{ marginBottom: "18px" }}
                  value={customizations?.loadWidgetAfter}
                  min={1}
                  max={50}
                  onChange={(value) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "loadWidgetAfter",
                        value
                      )
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LoadWidgetDelay;
