import React, { FC } from "react";
import { Row, Col, Input } from "antd";
import { Handle, Position } from "reactflow";

const RatingChoice: FC<any> = ({
  id,
  index,
  ratingData,
}: {
  id: any;
  index: any;
  ratingData: any;
}) => {
  const node = ratingData;

  return (
    <Row
      key={index}
      align="middle"
      style={{
        width: "100%",
        transition: "border-color 0.3s ease",
        cursor: "all-scroll",
        borderRadius: "3px",
        marginTop: "1px",
      }}
    >
      <Col span={24}>
        <Input readOnly value={node} style={{ borderRadius: "3px" }} />
      </Col>

      <Col span={24}>
        <Handle
          type="source"
          position={Position.Right}
          id={`source-${id}`}
          className="node-source"
          style={{
            background: "#e40000",
            marginTop: "-20px",
          }}
        />
      </Col>
    </Row>
  );
};

export default RatingChoice;
