import { Dispatch } from "redux";
import { ACTION_TYPES } from "../constants/actionTypes";
import { ADMIN_TYPES } from "../constants/adminTypes";
import { CHATBOT_TYPES } from "../constants/chatbotTypes";
import {
  // Dashboard
  getAdminDashboardAnalytics,
  // Chatbot
  getAdminChatbotsWithTemplates,
  getAdminChatbotsName,
  // Chatbot Responses
  getAdminChatbotResponses,
  archiveChatbotResponse,
  getAdminChatResponseMessages,
  // Subscription
  getAdminSubscription,
} from "../../api/user/admin";
import { saveUserTutorialStatus } from "../../api/user/user";

/********************************************
  Update tutorial status action
*********************************************/
export const updateTutorialStatusAction =
  (data: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await saveUserTutorialStatus(data);

      // Dispatch User tutorial status
      dispatch({
        type: ADMIN_TYPES.UPDATE_TUTORIAL_STATUS,
        payload: res.data,
      });

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_TUTORIAL_STATUS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Get admin dashboard analytics action
*********************************************/
export const getAdminDashboardAnalyticsAction =
  () => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await getAdminDashboardAnalytics();

      // Dispatch dashboard analytics
      dispatch({
        type: ADMIN_TYPES.GET_ADMIN_DASHBOARD_ANALYTICS,
        payload: res.data,
      });

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_ADMIN_DASHBOARD_ANALYTICS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Get admin chatbots with templates action
*********************************************/
export const getAdminChatbotsWithTemplatesAction =
  (page: any, perPage: any, searchText: any, setLoading: any) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      // Fetch response from server
      const res = await getAdminChatbotsWithTemplates(
        page,
        perPage,
        searchText
      );

      const payload = {
        chatbots: res.data.chatbots,
        exampleTemplates: res.data.templates,
        adminBotsPagination: res.data.pagination,
      };

      // Dispatch a success alert
      dispatch({
        type: ADMIN_TYPES.GET_ADMIN_CHATBOTS_WITH_TEMPLATES,
        payload: payload,
      });

      // Set loading to false after successful data fetching
      setLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Handle the error appropriately (log, show an error message, etc.)
      console.error("Error fetching data:", error);

      // Dispatch an error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_ADMIN_CHATBOTS_ERROR",
        },
      });

      // Set loading to false in case of an error
      setLoading(false);
    }
  };
/********************************************
  Get admin chatbots name action
*********************************************/
export const getAdminChatbotsNameAction =
  (setBotNamesLoading: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await getAdminChatbotsName();

      // Dispatch chatbot names
      dispatch({
        type: ADMIN_TYPES.GET_ADMIN_CHATBOTS_NAME,
        payload: res.data,
      });

      setBotNamesLoading(false);

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_ADMIN_CHATBOT_NAMES_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Get admin subscription action
*********************************************/
export const getAdminSubscriptionAction =
  (setLoading: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await getAdminSubscription();

      // Dispatch subscription
      dispatch({
        type: ADMIN_TYPES.GET_ADMIN_SUBSCRIPTION,
        payload: res.data,
      });

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });

      // Set loading to false
      setLoading(false);
    } catch (error) {
      setLoading(false);

      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_ADMIN_SUBSCRIPTION_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Get selected chatbot responses action
*********************************************/
export const getSelectedChatbotResponsesAction =
  (
    id: any,
    page: any,
    size: any,
    setSelectedBotName: any,
    setLoadMoreLoading: any
  ) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const response = await getAdminChatbotResponses(id, page, size);

      // If response was successful, dispatch success action to store responses
      dispatch({
        type: ADMIN_TYPES.SELECTED_BOT_RESPONSES,
        payload: {
          id: id,
          version: response.data.version,
          responses: response.data.responses,
          tickets: response.data.tickets,
          hasMore: response.data.hasMore,
          page: page,
          size: size,
        },
      });

      // Set the selected bot name
      setSelectedBotName(response.data?.name);

      // Set load more loading to false
      setLoadMoreLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_SELECTED_CHATBOT_RESPONSES_ERROR",
        },
      });
    }
  };

/********************************************
  Update chatbot responses with latest chat action
*********************************************/
export const updateSelectedChatbotResponsesAction =
  (data: any, setSelectedChat: any, setMessageLoading: any) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Dispatch selected chatbot responses only if the user is on the /chats page
      // If they're not on the /chats page, then the selected chatbot responses will be updated when they navigate to the /chats page
      if (data?.isOnChatsPage) {
        dispatch({
          type: ADMIN_TYPES.UPDATE_SELECTED_BOT_RESPONSES,
          payload: data,
        });
      }

      // Set the selected chat
      setSelectedChat(data);
      setMessageLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_SELECTED_BOT_RESPONSES_ERROR",
        },
      });
    }
  };

/********************************************
  Archive chatbot response action
*********************************************/
export const archiveChatbotResponseAction =
  (botId: any, responseId: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Make the API call to archive the chatbot response
      await archiveChatbotResponse(botId, responseId)
        .then(() => {
          // Dispatch archive chatbot response
          dispatch({
            type: ADMIN_TYPES.ARCHIVE_BOT_RESPONSE,
            payload: {
              botId: botId,
              responseId: responseId,
            },
          });
        })
        .catch((error) => {
          // Dispatch a error alert
          dispatch({
            type: ACTION_TYPES.ALERT,
            payload: {
              message: "ARCHIVE_CHATBOT_RESPONSE_ERROR",
            },
          });
        });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "ARCHIVE_CHATBOT_RESPONSE_ERROR",
        },
      });
    }
  };

/********************************************
  Update chatbot response with latest message
*********************************************/
export const updateBotWithLatestMessageAction =
  (chatSessionId: any, data: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Dispatch a success alert
      dispatch({
        type: ADMIN_TYPES.UPDATE_CHAT_WITH_LATEST_MESSAGE,
        payload: {
          chatSessionId: chatSessionId,
          data: data,
        },
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_SELECTED_BOT_RESPONSE_ERROR",
        },
      });
    }
  };

/********************************************
  Update selected chat with latest messages
*********************************************/
export const updateSelectedChatWithLatestResponseMessagesAction =
  (chatId: any, setSelectedChat: any, setMessageLoading: any) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Get the latest messages for the selected chat
      const res = await getAdminChatResponseMessages(chatId);

      // Dispatch a success alert
      dispatch({
        type: ADMIN_TYPES.UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES,
        payload: res.data.response,
      });

      // Set the selected chat
      setSelectedChat(res.data.response);

      // Set message loading to false
      setMessageLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES_ERROR",
        },
      });
    }
  };
