import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menu, Tag } from "antd";
// Navigation
import Navigation from "../../../navigation";

const MenuItem = (props) => {
  const { onClose } = props;
  const { user } = useSelector((state) => state.auth);
  const [navigation, setNavigation] = useState([]);

  // Navigation
  const { superAdminNav, marketingAdminNav, adminNav, memberNav } =
    Navigation();

  useEffect(() => {
    if (user?.role === "superadmin") {
      setNavigation(superAdminNav);
    } else if (user?.role === "marketing") {
      setNavigation(marketingAdminNav);
    } else if (user?.role === "admin") {
      setNavigation(adminNav);
    } else if (user?.role === "agent") {
      setNavigation(memberNav);
    }
  }, [user]);

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const menuItem =
    navigation &&
    navigation?.map((item, index) => {
      let itemNavLink;

      // If user is superadmin
      if (user?.role === "superadmin" || user?.role === "marketing") {
        itemNavLink = item?.navLink.split("/");
      } else if (user?.role === "admin" || user?.role === "agent") {
        // Call the function with workspaceId to get the URL string
        const navLink = item?.navLink(user?.workspaceId);
        itemNavLink = navLink.split("/");
      }

      return (
        // Level 1
        <Menu.Item
          key={index}
          icon={item.icon}
          onClick={onClose}
          className={
            splitLocation[splitLocation?.length - 2] +
              "/" +
              splitLocation[splitLocation?.length - 1] ===
            itemNavLink[itemNavLink?.length - 2] +
              "/" +
              itemNavLink[itemNavLink?.length - 1]
              ? "ant-menu-item-selected"
              : "ant-menu-item-selected-in-active"
          }
          style={item.tag && { pointerEvents: "none" }}
        >
          {item.tag ? (
            <p
              className="hp-d-flex hp-align-items-center hp-d-flex-between"
              style={{ pointerEvents: "none" }}
            >
              <span>{item.title}</span>
              <Tag
                className="hp-mr-0 hp-border-none hp-text-color-black-100 hp-bg-success-3 hp-border-radius-full hp-px-8"
                style={{ marginRight: -14 }}
              >
                {item.tag}
              </Tag>
            </p>
          ) : (
            <>
              {user?.role === "superadmin" || user?.role === "marketing" ? (
                <Link to={item.navLink}>{item.title}</Link>
              ) : (
                <Link to={item.navLink(user?.workspaceId)}>{item.title}</Link>
              )}
            </>
          )}
        </Menu.Item>
      );
    });

  return (
    menuItem && (
      <Menu
        mode="inline"
        defaultOpenKeys={[
          splitLocation?.length === 5
            ? splitLocation[splitLocation?.length - 3]
            : null,
          splitLocation[splitLocation?.length - 2],
        ]}
        theme={"light"}
      >
        {menuItem}
      </Menu>
    )
  );
};

export default MenuItem;
