import { Skeleton } from "antd";

const TemplatesSidebarSkeleton = () => (
  <Skeleton.Input
    active={true}
    size={"small"}
    style={{
      width: "93%",
      height: "40px",
      marginBottom: "15px",
    }}
    block
  />
);

export default TemplatesSidebarSkeleton;
