import React from "react";
import { Col, Row } from "antd";
import { LuArrowBigRightDash } from "react-icons/lu";
import EmptyArticle1 from "@assets/images/pages/knowledgeBase/empty-articles-1.svg";
import EmptyArticle2 from "@assets/images/pages/knowledgeBase/empty-articles-2.svg";
import EmptyArticle3 from "@assets/images/pages/knowledgeBase/empty-articles-3.svg";
import EmptyArticle4 from "@assets/images/pages/knowledgeBase/empty-articles-4.svg";

const EmptyArticle = () => {
  return (
    <div className="empty-article">
      {/* Top content */}
      <div className="empty-article-top-content">
        <img
          src={EmptyArticle1}
          className="empty-cat-top-img"
          alt="empty-article-1"
        />
        <div>
          <h5 className="heading">Article</h5>
          <span style={{ marginBottom: "20px" }}>
            Design a free help center in minutes, launch instantly, and empower
            your customers to help themselves.
          </span>
          <div class="empty-article-description">
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Make self-service effortless and reduce help requests with the
                in-widget search bar and article display
              </span>
            </div>
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Customize the colors, layout, and logo for your brand - no
                developer or designer required
              </span>
            </div>
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Move up in search rankings with built-in metadata, Open Graph
                images and suggested article features
              </span>
            </div>
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Publish public and private articles and prioritize content with
                custom categories and sort order
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom 3 cards  */}
      <div className="empty-article-bottom-content">
        <Row gutter={[16, 16]}>
          <Col md={8}>
            <div className="bottom-card-content">
              <img src={EmptyArticle2} className="empty-cat-bottom-img" />
              <div style={{ textAlign: "center" }}>
                <h5>Save time</h5>
                <span>
                  Build quickly in the drag-and-drop block editor. Format for
                  code, quotes, videos and more.
                </span>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="bottom-card-content">
              <img src={EmptyArticle3} className="empty-cat-bottom-img" />
              <div style={{ textAlign: "center" }}>
                <h5>Get insights</h5>
                <span>
                  Discover your most popular articles, view keyword searches and
                  monitor feedback with built-in reporting.
                </span>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="bottom-card-content">
              <img src={EmptyArticle4} className="empty-cat-bottom-img" />
              <div style={{ textAlign: "center" }}>
                <h5>Translate easily</h5>
                <span>
                  Built-in translation features make it super-simple to support
                  customers from all over the world.
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmptyArticle;
