import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import bg from "@assets/images/pages/authentication/signup.svg";
import logo from "@assets/images/logo/logo.png";
import Star from "@assets/images/pages/authentication/star.png";
import ReCaptcha from "@assets/images/pages/authentication/re-captcha.png";
import Styles from "@assets/css/AuthCommon.Module.css";

const LeftContent = () => {
  return (
    <Col
      lg={16}
      span={24}
      className="cb-auth-container"
      style={{
        overflow: "hidden",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Row justify="center">
        <Col className="hp-logo-item hp-m-sm-16 hp-m-md-32 hp-m-64">
          <img
            src={logo}
            alt="conferbotLogo"
            style={{
              transform: "scale(0.22)",
              marginLeft: "-150px",
              marginTop: "-150px",
            }}
          />
        </Col>

        <Row
          gutter={[16, 16]}
          span={24}
          align="middle"
          justify="center"
          className={Styles.BannerImg}
        >
          <Col xs={24} md={16} lg={18}>
            <img src={bg} alt="Signup" className="loginLeftImg" />
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          justify="center"
          align="middle"
          style={{
            fontFamily: "'Ubuntu', sans-serif",
            maxWidth: "75%",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <Col xs={24} md={22} lg={24}>
            <h2
              className="hp-text-color-primary-1 hp-text-color-dark-0 hp-mx-lg-16 hp-mt-16"
              style={{
                fontSize: "1.5rem",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              “I'm not just a chatbot, I'm your personal genie in a bottle. What
              can I help you with?”
            </h2>
          </Col>
        </Row>
      </Row>

      {/* Login Rating Cards */}
      <Col className="rating-cards">
        {/* Capterra */}
        <a
          className="rating-card-main"
          href="https://www.capterra.com/p/264691/Conferbot/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
          target="_blank"
          rel="noreferrer"
        >
          <div className="rating-card-header">
            {Array.from({ length: 5 }, (_, index) => (
              <img src={Star} className="rating-star" alt="star" />
            ))}
          </div>
          <div className="rating-card-body">
            <span className="rating-card-text">5 star rating on</span>
            <img
              src="https://assets.capterra.com/badge/313da35673e63bb6a0ae8d507b62930d.svg?v=2215134&p=264691"
              alt="Capterra"
              style={{ width: "auto", height: "50px" }}
            />
          </div>
        </a>

        {/* GetApp */}
        <a
          className="rating-card-main"
          href="https://www.getapp.com/customer-service-support-software/a/conferbot/reviews/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="rating-card-header">
            {Array.from({ length: 5 }, (_, index) => (
              <img src={Star} className="rating-star" alt="star" />
            ))}
          </div>
          <div className="rating-card-body">
            <span className="rating-card-text">5 star rating on</span>
            <img
              src="https://www.getapp.com/ext/reviews_widget/v1/dark/conferbot-application"
              alt="GetApp"
              style={{ width: "auto", height: "50px" }}
            />
          </div>
        </a>

        {/* Software Advice */}
        <a
          className="rating-card-main"
          href="https://www.softwareadvice.com/live-chat/conferbot-profile/reviews/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="rating-card-header">
            {Array.from({ length: 5 }, (_, index) => (
              <img src={Star} className="rating-star" alt="star" />
            ))}
          </div>
          <div className="rating-card-body">
            <span className="rating-card-text">5 star rating on</span>
            <img
              src="https://badges.softwareadvice.com/reviews/be9af734-d751-4469-8189-7f6ce413f077"
              alt="Software Advice"
              style={{ width: "auto", height: "50px" }}
            />
          </div>
        </a>
      </Col>

      <div className="re-captcha-container">
        <img
          src={ReCaptcha}
          alt="re-captcha-logo"
          className="re-captcha-logo"
        />
        <div className="slider-captcha">
          <span style={{ display: "block" }}>protected by reCAPTCHA</span>
          <div>
            <Link to="/privacy">Privacy</Link> - <Link to="/terms">Terms</Link>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default LeftContent;
