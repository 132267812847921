import { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isUserAction } from "@redux/actions/authActions";
import { LoadingToRedirect } from "@components";

const OnboardingRoute = ({ children, ...restProps }) => {
  const dispatch = useDispatch();
  const [ok, setOk] = useState(false);

  // Check if current user is admin
  useEffect(() => {
    dispatch(isUserAction({ setOk }));
  }, []);

  return (
    <>
      {ok ? <Route {...restProps} render={children} /> : <LoadingToRedirect />}
    </>
  );
};

export default OnboardingRoute;
