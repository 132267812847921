import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Input, Button, Select, Tooltip, message } from "antd";
import { shallow } from "zustand/shallow";
import axios from "axios";
import { CheckCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { GptNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const GptNodeDataEditor: FC<GptNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);
  const { t } = useTranslation();

  const [apiKey, setApiKey] = useState(state?.data?.apiKey);
  const [isApiKeyValid, setIsApiKeyValid] = useState(false);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(
    state?.data?.selectedModel || ""
  );
  const [context, setContext] = useState(state?.data?.context || "");
  const [responseVariable, setResponseVariable] = useState(
    state?.data?.responseVariable
  );

  useEffect(() => {
    setContext(state?.data?.context);
    setSelectedModel(state?.data?.selectedModel);
    setApiKey(state?.data?.apiKey);
    setResponseVariable(state?.data?.responseVariable);
  }, [id]);

  // Test the api key if its not empty
  useEffect(() => {
    if (apiKey) {
      testApiKey();
    }
  }, []);

  const testApiKey = async () => {
    try {
      // Set the headers for the API call
      const headers = {
        Authorization: `Bearer ${apiKey}`,
      };

      // Make the API call to OpenAI to fetch models
      const response = await axios.get("https://api.openai.com/v1/models", {
        headers,
      });

      console.log("res", response.data);
      // Filter the models to only include gpt-3 and gpt-4 models
      const modelNames = response.data.data
        .map((model: any) => model.id)
        .filter((id: any) => id.startsWith("gpt-3") || id.startsWith("gpt-4"));

      // If the call succeeds, set the models and mark the API key as valid
      setModels(modelNames);
      setIsApiKeyValid(true);
      message.success("API key is valid and models are fetched successfully");
    } catch (error) {
      setIsApiKeyValid(false);
      message.error("Invalid API key or Error fetching models: " + error);
    }
  };

  const handleSetApiKey = (e: any) => {
    setApiKey(e.target.value);

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        apiKey: e.target.value,
      },
    });
  };

  const handleModelChange = (value: any) => {
    setSelectedModel(value);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        selectedModel: value,
      },
    });
  };

  const handleContextChange = useCallback(
    (content: any) => {
      if (content.length <= 800) {
        setContext(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            context: content,
          },
        });
      } else {
        message.warn("Context cannot exceed 800 characters");
      }
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t("flowBuilder.sidebar.editor.integrationNodes.gptNodeInfo")}
        </p>
      </Row>

      <div
        style={{
          height: "10px",
          width: "100%",
        }}
      />

      <Row align="middle">
        {/* API Key */}
        <Col span={24}>
          <Input
            addonBefore="API Key"
            placeholder="Enter API Key"
            value={apiKey}
            onChange={handleSetApiKey}
            style={{ marginBottom: "20px" }}
          />
        </Col>

        <Col
          span={24}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isApiKeyValid ? (
            // Render a checkmark icon and "Test API Key" label if the API key is valid
            <Tooltip title="API Key is valid">
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                style={{ backgroundColor: "#319500", borderColor: "#52c41a" }}
                onClick={testApiKey}
              >
                {t("flowBuilder.sidebar.editor.integrationNodes.testApiKey")}
              </Button>
            </Tooltip>
          ) : (
            // Render a question mark icon and "Test API Key" label if the API key is not valid
            <Tooltip title="API Key is not valid">
              <Button
                type="primary"
                icon={<QuestionCircleOutlined />}
                onClick={testApiKey}
              >
                {t("flowBuilder.sidebar.editor.integrationNodes.testApiKey")}
              </Button>
            </Tooltip>
          )}
        </Col>

        {/* Model Selection */}
        <Col span={24} style={{ marginTop: "20px" }}>
          {/* Label */}
          <div style={{ marginBottom: "10px" }}>Model</div>

          {/* Select */}
          <Select
            style={{ width: "100%" }}
            placeholder={t(
              "flowBuilder.sidebar.editor.integrationNodes.selectModalPH"
            )}
            onChange={handleModelChange}
            value={selectedModel}
            disabled={!isApiKeyValid}
          >
            {models.map((model) => (
              <Option key={model} value={model}>
                {model}
              </Option>
            ))}
          </Select>
        </Col>

        {/* GPT Context */}
        <Col span={24} style={{ marginTop: "20px" }}>
          {/* Label */}
          <div style={{ marginBottom: "10px" }}>
            {t("flowBuilder.sidebar.editor.integrationNodes.context")}
          </div>

          {/* Context text area */}
          <Input.TextArea
            rows={4} // Adjust the number of rows as needed
            value={context}
            onChange={(e) => handleContextChange(e.target.value)}
            placeholder={t(
              "flowBuilder.sidebar.editor.integrationNodes.contextPH"
            )}
            maxLength={800} // Set maximum length
            disabled={!isApiKeyValid} // Disable the field if the API key is not valid
          />
        </Col>

        <SaveAnswer
          id={id}
          type={type}
          variableName={
            responseVariable
              ? responseVariable
              : `generated_response_${id.slice(-5)}`
          }
        />
      </Row>
    </>
  );
};

export default GptNodeDataEditor;
