import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Spin,
  Table,
  Input,
  Menu,
  Dropdown,
  Tag,
  Switch,
} from "antd";
import { useSelector } from "react-redux";
import { RiAddLine, RiFileList2Line, RiBookmark3Line } from "react-icons/ri";
import { FaHashtag } from "react-icons/fa";
import {
  getAllBlogsPaginatedSearch,
  deleteBlogById,
  updateBlogApprovalById,
} from "@api/blog/blog";

const { Search } = Input;

const BlogsList = ({ history }) => {
  const { user } = useSelector((state) => state.auth);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const search = searchParams.get("search") || "";

    setSearchText(search);
    setPagination({ current: page, pageSize, total: 0 });
    fetchBlogs(page, pageSize, search);
  }, []);

  const fetchBlogs = async (page, pageSize, searchText = "") => {
    setLoading(true);
    try {
      const res = await getAllBlogsPaginatedSearch(page, pageSize, searchText);
      setBlogs(res.data.blogs);
      setPagination((p) => ({
        ...p,
        total: res.data.paginationData.totalBlogs,
        current: res.data.paginationData.page,
        pageSize: res.data.paginationData.perPage,
      }));
    } catch (err) {
      console.error("Fetch blogs error", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    const searchParams = new URLSearchParams();
    searchParams.set("page", page);
    searchParams.set("pageSize", pageSize);
    if (searchText) searchParams.set("search", searchText);

    history.push({ search: searchParams.toString() });
    setPagination({ ...pagination, current: page, pageSize });
    fetchBlogs(page, pageSize, searchText);
  };

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    setPagination({ ...pagination, current: 1 }); // Reset to first page
    fetchBlogs(1, pagination.pageSize, searchValue);

    const searchParams = new URLSearchParams();
    searchParams.set("page", "1");
    searchParams.set("pageSize", pagination.pageSize);
    if (searchValue) searchParams.set("search", searchValue);
    history.push({ search: searchParams.toString() });
  };

  // Function to handle blog update
  const handleBlogUpdate = (id) => {
    // If user is superadmin, redirect to blog update page
    if (user?.role === "superadmin") {
      history.push(`/super-admin/blog/${id}/update`);
    } else if (user?.role === "marketing") {
      history.push(`/marketing-admin/blog/${id}/update`);
    }
  };

  // Function to handle blog delete
  const handleBlogDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this blog?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteBlogById(id);
          fetchBlogs(pagination.current, pagination.pageSize);
        } catch (error) {
          console.error("Error deleting blog", error);
        }
      },
    });
  };

  // Function to handle blog approval
  const handleBlogApproval = async (checked, id) => {
    try {
      await updateBlogApprovalById(id, { approved: checked });
      fetchBlogs(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error updating blog approval", error);
    }
  };

  // Function to format date
  const formatDate = (date) => {
    if (!date) return "";
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const randomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<RiFileList2Line />}
        onClick={() => {
          // If user is superadmin, redirect to blog create page
          if (user?.role === "superadmin") {
            history.push("/super-admin/blog/create");
          } else if (user?.role === "marketing") {
            history.push("/marketing-admin/blog/create");
          }
        }}
      >
        Create Blog
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<RiBookmark3Line />}
        onClick={() => {
          // If user is superadmin, redirect to blog categories page
          if (user?.role === "superadmin") {
            history.push("/super-admin/blogs/blog-categories");
          } else if (user?.role === "marketing") {
            history.push("/marketing-admin/blogs/blog-categories");
          }
        }}
      >
        Blog Categories
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<FaHashtag />}
        onClick={() => {
          // If user is superadmin, redirect to blog tags page
          if (user?.role === "superadmin") {
            history.push("/super-admin/blogs/tags");
          } else if (user?.role === "marketing") {
            history.push("/marketing-admin/blogs/tags");
          }
        }}
      >
        Blog Tags
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title) =>
        title.length > 25 ? `${title.substring(0, 25)}...` : title,
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      render: (author) => author?.name,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => <Tag color="blue">{category?.name}</Tag>,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => (
        <>
          {tags?.map((tag) => (
            <Tag color={randomColor()} key={tag._id}>
              {tag?.name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Published At",
      dataIndex: "publishedDate",
      key: "publishedDate",
      render: (date) => formatDate(date),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => formatDate(date),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => formatDate(date),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleBlogUpdate(record._id)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            size="small"
            onClick={() => handleBlogDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
    // Approved
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      // Render a switch to toggle blog approval
      render: (approved, record) => (
        <Switch
          checked={approved}
          onChange={(checked) => handleBlogApproval(checked, record._id)}
        />
      ),
    },
  ];

  return (
    <Card style={{ borderRadius: "0", height: "100vh", overflowY: "auto" }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h3>Blogs List</h3>
        </Col>
        <Col>
          {/* Search */}
          <Search
            placeholder="Search by title/description"
            style={{ width: "300px", marginRight: 10 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
          />

          {/* Blog Options */}
          <Dropdown overlay={menu} placement="bottomRight">
            <Button icon={<RiAddLine />} style={{ marginLeft: "10px" }} />
          </Dropdown>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={blogs}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          loading={loading}
          onChange={({ current, pageSize }) =>
            handlePaginationChange(current, pageSize)
          }
          rowKey={(record) => record._id}
          scroll={{ y: "calc(100vh - 300px)" }}
        />
      </Spin>
    </Card>
  );
};

export default BlogsList;
