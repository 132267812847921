import React, { useState, useMemo } from "react";
import { Modal, Button, Input, DatePicker, Divider, Form, Spin } from "antd";
import { createChangelog } from "@api/workspace/changelog"; // Adjust the import path based on your API
import { SuccessNotification, ErrorNotification } from "../../"; // Adjust imports based on your components
import JoditEditor from "jodit-react";
import moment from "moment";

const ChangelogCreateModal = ({ open, handleModalClick, fetchChangelogs }) => {
  const [changelogData, setChangelogData] = useState({
    version: "",
    releaseDate: null,
    updates: "",
  });
  const [loading, setLoading] = useState(false);
  const editor = React.useRef(null);

  const handleInputChange = (value, name) => {
    setChangelogData({ ...changelogData, [name]: value });
  };

  const handleChangelogCreate = async () => {
    setLoading(true);
    try {
      await createChangelog({
        ...changelogData,
        releaseDate: changelogData.releaseDate
          ? changelogData.releaseDate.format("YYYY-MM-DD")
          : null,
      });
      SuccessNotification("Changelog created successfully");
      fetchChangelogs();
      handleModalClick();
    } catch (error) {
      ErrorNotification("Error creating changelog");
    } finally {
      setLoading(false);
    }
  };

  // Config for JoditEditor
  const joditConfig = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      minHeight: 200, // Set minimum height for the editor
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
      buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "font",
        "fontsize",
        "|",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "superscript",
        "subscript",
        "|",
        "brush",
        "paragraph",
        "|",
        "align",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "undo",
        "redo",
        "|",
        "cut",
        "copy",
        "paste",
        "selectall",
        "|",
        "link",
        "unlink",
        "|",
        "image",
        "video",
        "|",
        "table",
        "hr",
        "|",
        "symbol",
        "eraser",
        "|",
        "print",
        "fullsize",
        "about",
        "spellcheck",
        "|",
        "preview",
        "print",
        "about",
      ],
    }),
    []
  );

  return (
    <Modal
      title="Create Changelog"
      visible={open}
      onCancel={handleModalClick}
      footer={[
        <Button key="back" onClick={handleModalClick} loading={loading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleChangelogCreate}
          loading={loading}
        >
          Create
        </Button>,
      ]}
      width={1100}
      style={{ top: 20 }}
    >
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="Version" required>
            <Input
              value={changelogData.version}
              onChange={(e) => handleInputChange(e.target.value, "version")}
              placeholder="e.g., 1.0.0"
            />
          </Form.Item>
          <Form.Item label="Release Date" required>
            <DatePicker
              style={{ width: "100%" }}
              value={
                changelogData.releaseDate
                  ? moment(changelogData.releaseDate)
                  : null
              }
              onChange={(date) => handleInputChange(date, "releaseDate")}
            />
          </Form.Item>
          <Divider>Updates</Divider>
          <Form.Item label="Updates" required>
            <JoditEditor
              ref={editor}
              value={changelogData.updates}
              config={joditConfig}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => handleInputChange(newContent, "updates")} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ChangelogCreateModal;
