// Global Actions
export const CUSTOMIZE_TYPES = {
  THEME: "THEME",
  CONTENT_WIDTH: "CONTENT_WIDTH",
  SIDEBAR_COLLAPSED: "SIDEBAR_COLLAPSED",
  SIDEBAR_COLLAPSE_BUTTON: "SIDEBAR_COLLAPSE_BUTTON",
  LAYOUT_CHANGE: "LAYOUT_CHANGE",
  NAVIGATION_FULL: "NAVIGATION_FULL",
  NAVIGATION_BG: "NAVIGATION_BG",
  DIRECTION: "DIRECTION",
};
