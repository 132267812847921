import React from "react";
import { Row, Col, Input, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";

const UsageDetails = ({ currentUsageDetails }) => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      <Row>
        <Col span={12}>
          <h4
            style={{
              marginBottom: "1.5rem",
            }}
          >
            Current Usage Details
          </h4>

          {/* Plan */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Plan
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={currentUsageDetails?.subscriptionPlan}
              />
            </Col>
          </Row>

          {/* Start Date */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Start Date
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={new Date(currentUsageDetails?.startDate).toUTCString()}
              />
            </Col>
          </Row>

          {/* End Date */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              End Date
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={new Date(currentUsageDetails?.endDate).toUTCString()}
              />
            </Col>
          </Row>

          {/* Chats Allotted */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Chats Allotted
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={currentUsageDetails?.chatsAllotted}
              />
            </Col>
          </Row>

          {/* Chats Used */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Chats Used
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={currentUsageDetails?.chatsUsed}
              />
            </Col>
          </Row>

          {/* View Usage History */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              View Usage History
            </Col>

            <Col sm={9} span={12}>
              <Button
                type="primary"
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                onClick={() => {
                  history.push(`/super-admin/admin/${id}/usage-history`);
                }}
              >
                View
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UsageDetails;
