import { useState } from "react";
import { Divider, Row, Col, Spin, Button } from "antd";
import { VisitorDetails, ChatMetadata, ChatTicket } from "./components";
import { useTranslation } from "react-i18next";

const ChatbotVisitor = ({ selectedChat, loading, messageLoading }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { t } = useTranslation();

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Extract device information
  const extractDeviceInfo = (userAgent) => {
    let deviceInfo = {};

    // Extract browser information
    let browserMatch =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    let tempMatch;
    if (/trident/i.test(browserMatch[1])) {
      tempMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      deviceInfo.browser = `IE ${tempMatch[1]}`;
    } else if (browserMatch[1] === "Chrome") {
      tempMatch = userAgent.match(/\bOPR|Edge\/(\d+)/);
      if (tempMatch != null) {
        deviceInfo.browser = `Opera ${tempMatch[1]}`;
      }
    }
    if (!deviceInfo.browser) {
      browserMatch = browserMatch[2]
        ? [browserMatch[1], browserMatch[2]]
        : [navigator.appName, navigator.appVersion, "-?"];
      tempMatch = userAgent.match(/version\/(\d+)/i);
      if (tempMatch != null) {
        browserMatch.splice(1, 1, tempMatch[1]);
      }
      deviceInfo.browser = browserMatch.join(" ");
    }

    // Extract operating system information
    let osMatch = userAgent.match(/(linux|macintosh|windows|android)/i);
    deviceInfo.os = osMatch && osMatch[0];

    return deviceInfo;
  };

  // Handle view ticket details
  const handleViewTicketDetails = (tid) => {
    setDrawerVisible(true);
  };

  return loading || messageLoading ? (
    <Spin
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 125px)",
      }}
    />
  ) : (
    <div>
      {Object.keys(selectedChat)?.length > 0 ? (
        <>
          <div className="visitor-details-header">
            <Row className="visitor-details-row">
              <Col className="visitor-details-col">
                <h5 className="visitor-details-title">
                  {t("chatMessages.visitor.visitorDetails")}
                </h5>
              </Col>
            </Row>
          </div>

          <div className="visitor-details-container">
            {/* Visitor Details */}
            <VisitorDetails
              selectedChat={selectedChat}
              extractDeviceInfo={extractDeviceInfo}
            />

            {/* Divider */}
            <Divider />

            {/* Chat Metadata */}
            <ChatMetadata
              selectedChat={selectedChat}
              isOpen={isOpen}
              toggleDropdown={toggleDropdown}
            />

            {/* Divider */}
            <Divider />

            {/* If selectedChat.ticket contains an ID, add a button saying view ticket details */}
            {selectedChat?.ticket && (
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <Col
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <h5
                      style={{
                        display: "block",
                        fontWeight: 600,
                      }}
                    >
                      {t("chatMessages.visitor.ticketDetails")}
                    </h5>
                  </Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  <Button
                    style={{
                      background: "#0b4eb8",
                      color: "#fff",
                      borderRadius: "8px",
                      height: "30px",
                      width: "100%",
                    }}
                    size="small"
                    type="primary"
                    onClick={() =>
                      handleViewTicketDetails(selectedChat?.ticket)
                    }
                  >
                    {t("chatMessages.visitor.viewTicket")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 125px)",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {t("chatMessages.visitor.visitorDetails")}
          <br />
          {t("chatMessages.visitor.toBreakTheIce")}❄️🔨💬
        </span>
      )}

      {/* Chat Ticket */}
      {drawerVisible && (
        <ChatTicket
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          selectedChat={selectedChat}
        />
      )}
    </div>
  );
};

export default ChatbotVisitor;
