import { SUPER_ADMIN_TYPES } from "../constants/superAdminTypes";

// Define the structure of your state
interface SuperAdminState {
  analytics?: any;
}

// Define the type for your action
interface SuperAdminAction {
  type: string;
  payload: any;
}

// Initial state with type
const initialState: SuperAdminState = {};

// Reducer function with types
const superAdminReducer = (
  state = initialState,
  action: SuperAdminAction
): SuperAdminState => {
  switch (action.type) {
    case SUPER_ADMIN_TYPES.GET_SUPER_ADMIN_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
      };
    default:
      return state;
  }
};

export default superAdminReducer;
