import React, { useState, useRef } from "react";
import { Row, Col, Form, Input, Button, Checkbox } from "antd";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { signupAction, googleLoginAction } from "@redux/actions/authActions";
import LeftContent from "./leftContent";
import { ErrorNotification } from "@components";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCaptcha } from "@api/auth/auth";
import { validateEmail } from "@utils/validations";
import logoText from "@assets/images/logo/logoText.png";
import Styles from "@assets/css/AuthCommon.Module.css";

const initialFormData = {
  name: "",
  email: "",
  password: "",
};

const Signup = ({ history }) => {
  const dispatch = useDispatch();
  const captchaRef = useRef(null);
  const [formData, setFormData] = useState(initialFormData);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);

  // Extract role from cookies
  const role = Cookies.get("rl");

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle custom email sign up
  const handleEmailSignup = (e) => {
    e.preventDefault();
    const { name, email, password } = formData;

    // Validate email
    if (!validateEmail(email)) {
      ErrorNotification("Please enter a valid email");
      return;
    }

    // If fields are empty, throw error
    if (name === "" || email === "" || password === "") {
      ErrorNotification("Please fill in all fields");
      return;
    }

    // Make sure the name field doesn't contain any numbers, html, script tags and should not be longer than 40 characters
    if (
      name.length > 40 ||
      name.includes("<") ||
      name.includes(">") ||
      name.includes("script")
    ) {
      ErrorNotification("Nice try hacker ;)");
      return;
    }

    // If not agreed, show error
    if (!agree) {
      ErrorNotification("You need to agree to the terms and conditions");
      return;
    }

    // If captcha not verified, show error
    if (!captchaSuccess) {
      ErrorNotification("Please verify that you are not a robot");
      return;
    }

    // Password length should not be more than 40 characters
    if (password.length > 40) {
      ErrorNotification("Password should not be more than 40 characters");
      return;
    }

    try {
      // Dispatch name, email, and password
      dispatch(
        signupAction({
          name,
          email,
          password,
          rl: role || "admin",
          setFormData,
          initialFormData,
          setLoading,
        })
      );
    } catch (error) {
      console.log("SIGNUP_ERROR", error);
    }
  };

  // Handle google login success
  const handleGoogleSuccess = async (credentialResponse) => {
    const userObject = jwt_decode(credentialResponse.credential);
    const { name, email } = userObject;
    setLoading(true);

    // Dispatch email, password and history to action
    dispatch(
      googleLoginAction({
        name,
        email,
        history,
        setLoading,
      })
    );
  };

  // Handle google login failure
  const handleGoogleFailure = () => {
    console.log("GOOGLE_SIGNUP_ERROR");
  };

  // Handle recaptcha change
  const handleRecaptchaChange = (value) => {
    verifyCaptcha(value)
      .then((res) => {
        if (res.data.success) {
          setCaptchaSuccess(true);
        } else {
          setCaptchaSuccess(false);
        }
      })
      .catch((err) => {
        console.log("RECAPTCHA_ERROR", err);
        setCaptchaSuccess(false);
      });
  };

  return (
    <>
      <Row
        gutter={[32, 0]}
        className={`hp-authentication-page ${Styles.centerContent}`}
        style={{ overflowY: "auto", height: "100vh" }}
      >
        <LeftContent />

        <Col
          lg={8}
          md={16}
          sm={16}
          span={24}
          className="hp-py-sm-0 hp-py-md-64"
        >
          <Row className="hp-h-100" align="middle" justify="center">
            <Col
              xxl={11}
              xl={15}
              lg={20}
              md={18}
              sm={24}
              className="hp-px-sm-8 hp-pt-24 hp-pb-48"
            >
              <img
                src={logoText}
                alt="logo"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  margin: "0 auto",
                }}
              />

              <h2
                className="hp-mb-sm-0"
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  color: "#000000",
                }}
              >
                Create Account
              </h2>

              <Form
                layout="vertical"
                name="basic"
                className="hp-mt-sm-16 hp-mt-8"
              >
                <Form.Item label="Name :" className="hp-mb-8">
                  <Input
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                </Form.Item>

                <Form.Item label="Email :" className="hp-mb-8">
                  <Input
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleFormChange}
                  />
                </Form.Item>

                <Form.Item label="Password :" className="hp-mb-8">
                  <Input.Password
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleFormChange}
                  />
                </Form.Item>

                {/* Check box agreeing to terms and privacy */}
                <Form.Item>
                  <Row>
                    <Col span={24}>
                      <Checkbox
                        checked={agree}
                        onChange={() => setAgree(!agree)}
                      >
                        I agree to the{" "}
                        <Link
                          to="/terms"
                          className="hp-text-color-blue-100 hp-text-color-dark-80"
                        >
                          Term of use
                        </Link>{" "}
                        &{" "}
                        <Link
                          to="/privacy"
                          className="hp-text-color-blue-100 hp-text-color-dark-80"
                        >
                          Privacy policy
                        </Link>
                      </Checkbox>
                    </Col>
                  </Row>
                </Form.Item>

                {/* Recaptcha check */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </div>

                <Form.Item className="hp-mt-8 hp-mb-8">
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={handleEmailSignup}
                    loading={loading}
                  >
                    Sign up
                  </Button>
                </Form.Item>
              </Form>

              <div className="hp-form-info">
                <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                  Already have an account?{" "}
                </span>

                <Link
                  to="/login"
                  className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                >
                  Login
                </Link>
              </div>

              <Col className="hp-or-line hp-text-center hp-mt-8">
                <span className="hp-caption hp-text-color-black-80 hp-text-color-dark-30 hp-px-16 hp-bg-color-black-0 hp-bg-color-dark-100">
                  Or
                </span>
              </Col>

              <Col
                className="hp-account-buttons hp-mt-8"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleGoogleSuccess(credentialResponse);
                  }}
                  onError={() => {
                    handleGoogleFailure();
                  }}
                />
              </Col>

              <Col
                className="hp-other-links hp-mt-24"
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Link
                  to="/privacy"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Privacy Policy
                </Link>

                {/* A dot in between */}
                <span
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                  style={{ margin: "0 8px", fontSize: "12px" }}
                >
                  .
                </span>

                <Link
                  to="/terms"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Terms of use
                </Link>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Signup;
