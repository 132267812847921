import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spin } from "antd";
// API
import { markNotificationAsRead } from "../../../../api/user/member";
// Redux
import {
  getMemberNotificationsAction,
  markAllNotificationsAsReadAction,
} from "../../../../redux/actions/memberActions";
// Assets
import Avatar from "../../../../assets/images/pages/profile/avatar.png";
import NoNotificationsImage from "../../../../assets/images/pages/chats/noNotifications.svg";
import { useTranslation } from "react-i18next";

const Notification = ({ popoverVisible }: any) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { notificationsData } = useSelector((state: any) => state.member);

  const { t } = useTranslation();

  // Notifications Pagination
  const [notificationsPage, setNotificationsPage] = useState(1);
  const [notificationsPageSize, setNotificationsPageSize] = useState(5);

  // ** LOAD MORE REFS ** //
  // Notifications Load More
  const loadMoreNotificationsRef = useRef(null);
  const [_, setShowLoadMoreNotifications] = useState(false);

  // Loading state
  const [loading, setLoading] = useState(true);
  const [loadMoreNotificationsLoading, setLoadMoreNotificationsLoading] =
    useState(false);

  // Get the latest 10 notifications
  useEffect(() => {
    setLoading(true);

    // If popover is visible, fetch the notifications
    if (popoverVisible) {
      dispatch(
        getMemberNotificationsAction(
          notificationsPage,
          notificationsPageSize,
          setLoading
        )
      );
    }
  }, [popoverVisible]);

  // *** Load More *** //
  // Handle load more notifications
  const handleLoadMoreNotifications = () => {
    setLoadMoreNotificationsLoading(true);

    // Increase setNotificationsPage number
    setNotificationsPage(notificationsPage + 1);

    // Set per page to 5
    setNotificationsPageSize(5);

    // Dispatch the action to get the handled tickets
    dispatch(
      getMemberNotificationsAction(
        notificationsPage + 1,
        notificationsPageSize,
        setLoadMoreNotificationsLoading
      )
    );
  };

  // ** Notifications Load More ** //
  // If the last element is in view, show the Load More button.
  const chatsObserverCallback = (entries: any, _: any) => {
    const first = entries[0];
    if (first.isIntersecting) {
      setShowLoadMoreNotifications(true);
    } else {
      setShowLoadMoreNotifications(false);
    }
  };

  // Add IntersectionObserver to the last element in the chats list
  useEffect(() => {
    const options = { threshold: 0.1 };
    const observer = new IntersectionObserver(chatsObserverCallback, options);

    if (loadMoreNotificationsRef.current) {
      observer.observe(loadMoreNotificationsRef.current);
    }

    return () => observer.disconnect();
  }, [notificationsData]);

  // ** Notification Click ** //
  const handleNotificationClick = async (item: any) => {
    const basePath = user.role === "admin" ? "/a/ws" : "/m/ws";

    const currentPath = window.location.pathname;
    const isOnChatsPage = currentPath.includes(
      `${basePath}/${item.metaData.workspaceId}/chats`
    );

    // Parse item.createdAt and maxWaitTime, then check if current time is beyond max wait time
    const createdAt = new Date(item.createdAt);
    const maxWaitTimeInMilliseconds =
      item.metaData.handoverData.maxWaitTime * 60000; // Convert minutes to milliseconds
    const currentTime = new Date();
    const expiryTime = new Date(
      createdAt.getTime() + maxWaitTimeInMilliseconds
    );

    // If current time is beyond item.createdAt + maxWaitTime, redirect immediately
    if (currentTime > expiryTime) {
      window.location.href = item?.link;
      return; // Exit the function to prevent further execution
    }

    // If item is not read
    if (!item.read) {
      // Mark item as read
      // Make an API call to mark the item as read
      await markNotificationAsRead(item._id);

      // If not on the chats page, save the notification data to the session storage and redirect to the chats page
      if (!isOnChatsPage) {
        sessionStorage.setItem(
          "notificationData",
          JSON.stringify(item.metaData)
        );

        // Redirect to the chats page if not already there
        window.location.href = item?.link;
      } else {
        // If already on the chats page, handle the notification data appropriately
        window.dispatchEvent(
          new CustomEvent("handleNotification", { detail: item.metaData })
        );
      }
    } else {
      // If item is read, redirect to the link
      window.location.href = item?.link;
    }
  };

  // Mark all notifications as read
  const handleMarkAllAsRead = async () => {
    dispatch(markAllNotificationsAsReadAction());
  };

  // Format date to now
  const formatToNow = (date: any) => {
    const now: any = new Date();
    const givenDate: any = new Date(date);
    const seconds = Math.round((now - givenDate) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const weeks = Math.round(days / 7);
    const months = Math.round(days / 30);
    const years = Math.round(days / 365);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (weeks < 5) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <>
      <div className="notification-header">
        <span style={{ fontWeight: "600" }}>
          {t("notifications.notifications")}
        </span>
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => handleMarkAllAsRead()}
        >
          {t("notifications.markAllRead")}
        </span>
      </div>

      <div className="notification-container">
        {loading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "250px",
            }}
          />
        ) : (
          <>
            {notificationsData.notifications &&
            notificationsData.notifications.length > 0 ? (
              notificationsData.notifications?.map(
                (notification: any, index: any) => (
                  <div
                    key={index}
                    className="notification-item"
                    style={{
                      backgroundColor: notification.read
                        ? "#ffffff"
                        : "#e6f7ff",
                    }}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <div style={{ marginRight: "15px" }}>
                      <img
                        src={notification.avatar || Avatar}
                        alt="avatar"
                        style={{
                          height: "40px",
                          width: "42px",
                          minWidth: "42px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div>
                      <span>
                        <strong>{notification.name}</strong>{" "}
                        {notification.description}
                      </span>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#6c757d",
                          marginTop: "5px",
                        }}
                      >
                        {formatToNow(notification.createdAt)}
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                }}
              >
                <img
                  src={NoNotificationsImage}
                  alt="no-notifications"
                  style={{
                    width: "40%",
                    height: "40%",
                    objectFit: "contain",
                  }}
                />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                >
                  {t("notifications.noNotificationFound")}
                </p>
              </div>
            )}

            {notificationsData.hasMore && (
              <div style={{ textAlign: "center", margin: "10px 0" }}>
                <Button
                  onClick={handleLoadMoreNotifications}
                  loading={loadMoreNotificationsLoading}
                >
                  {t("notifications.loadMore")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Notification;
