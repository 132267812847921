import { useEffect, useRef, useState } from "react";
import { Radio, Spin, Switch, message } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import ConfettiIcon from "@assets/images/pages/customize/confetti.png";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import DeleteIcon from "@assets/images/pages/customize/bin.png";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
// Fire Works Icons
import {
  FireWork1,
  FireWork2,
  FireWork3,
  FireWork4,
  FireWork5,
  FireWork6,
  FireWork7,
  FireWork8,
  FireWork9,
  FireWork10,
} from "@assets/images";

const AnnouncementBannerOptions = () => {
  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});

  const [visible, setVisible] = useState(
    customizations?.enableAnnouncementBanner
  );
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState("Icons");
  const [selectedIconName, setSelectedIconName] = useState(
    customizations?.widgetIconSVG
  );

  const { t } = useTranslation();
  const BannerLogoImageRef = useRef(null);

  useEffect(() => {
    setVisible(customizations?.enableAnnouncementBanner);
  }, [customizations]);

  const toggleEnableAnnouncementBanner = (key) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, !visible));
  };

  // Handle Change banner Icon
  const handleChangeBannerIcon = async (event) => {
    const file = event.target.files[0];
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("image", file);
    // Make request to upload image
    try {
      setLoading(true);

      // Upload image
      await uploadChatbotMedia(bid, fmData, config)
        .then((res) => {
          dispatch(
            updateCurrentChatbotCustomizationsAction(
              bid,
              "bannerIconImage",
              res.data.url
            )
          );
          setLoading(false);
          message.success("Banner logo updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Error updating banner logo");
        });
    } catch (error) {
      setLoading(false);
      message.error("Error updating banner logo");
    }
  };

  // Spinner indicator
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 35,
      }}
      spin
    />
  );

  // Icon and Shape Options
  const options = [
    {
      label: "Icons",
      value: "Icons",
    },
    {
      label: "Upload",
      value: "Upload",
    },
  ];

  // Banner Icons
  const bannerIcons = [
    { name: "FireWork1", icon: FireWork1 },
    { name: "FireWork2", icon: FireWork2 },
    { name: "FireWork3", icon: FireWork3 },
    { name: "FireWork4", icon: FireWork4 },
    { name: "FireWork5", icon: FireWork5 },
    { name: "FireWork6", icon: FireWork6 },
    { name: "FireWork7", icon: FireWork7 },
    { name: "FireWork8", icon: FireWork8 },
    { name: "FireWork9", icon: FireWork9 },
    { name: "FireWork10", icon: FireWork10 },
  ];

  //  Toggle Icons & Upload Button
  const handleTabsToggle = ({ target: { value } }) => {
    setChecked(value);

    // If value is Icons or Upload then set bannerIconType to Icon or Image
    if (value === "Icons" || value === "Upload") {
      dispatch(
        updateCurrentChatbotCustomizationsAction(
          bid,
          "bannerIconType",
          value === "Icons" ? "Icon" : "Image"
        )
      );
    }
  };

  // Handle Select Icon
  const handleSelectIcon = (iconName) => {
    setSelectedIconName(iconName); // Assuming this state now holds the name, not the index

    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "bannerIconSVG", iconName)
    );
  };

  const buttonNumberToWord = {
    1: "One",
    2: "Two",
  };

  const handleButtonChange = (buttonWord, field, value) => {
    if (field === "Title" && value.length > 12) {
      return; // Prevent updating if the title is longer than 12 characters
    }
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        `bannerButton${buttonWord}${field}`,
        value
      )
    );
  };

  const renderButtonSettings = (buttonNumber) => {
    const buttonWord = buttonNumberToWord[buttonNumber];

    return (
      <div className="button-settings">
        <span>{`Button ${buttonWord}`}</span>
        <input
          className="general-input"
          type="text"
          value={customizations[`bannerButton${buttonWord}Title`]}
          onChange={(e) =>
            handleButtonChange(buttonWord, "Title", e.target.value)
          }
          placeholder="Button Text"
          maxLength={12}
        />

        <input
          className="general-input"
          type="text"
          value={customizations[`bannerButton${buttonWord}Link`]}
          onChange={(e) =>
            handleButtonChange(buttonWord, "Link", e.target.value)
          }
          placeholder="https://www.example.com"
        />

        <div className="switch-container">
          <span
            style={{
              marginRight: "8px",
            }}
          >
            {t("customize.widget.openInNewTab")}
          </span>

          <Switch
            checked={customizations[`bannerButton${buttonWord}NewTab`]}
            onChange={(checked) =>
              handleButtonChange(buttonWord, "NewTab", checked)
            }
            size="small"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() =>
            toggleEnableAnnouncementBanner("enableAnnouncementBanner")
          }
          className="dropdown-trigger"
        >
          <span>
            <img
              src={ConfettiIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="cta"
            />
            {t("customize.widget.announcementBanner")}
          </span>
          <Switch checked={visible} size="small" />
        </div>

        {visible && (
          <>
            <div
              style={{
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
                color: "#45496e",
              }}
            >
              {/* Banner Logo */}
              <span>{t("customize.widget.bannerLogo")} </span>
              {/* Icons & Upload Tab Buttons */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "12px 0px 15px 0px",
                }}
              >
                <Radio.Group
                  options={options}
                  onChange={handleTabsToggle}
                  value={checked}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>

              {/* Banner Icons */}
              {checked === "Icons" && (
                <>
                  <div
                    className="widgetIcons_Wrapper"
                    style={{ marginBottom: "15px" }}
                  >
                    {bannerIcons?.map((iconObj, index) => {
                      return (
                        <div
                          key={index}
                          className="widget_icon"
                          style={{
                            background:
                              (customizations?.widgetIconThemeType ===
                                "Solid" &&
                                customizations?.widgetIconBgColor) ||
                              (customizations?.widgetIconThemeType ===
                                "Gradient" &&
                                `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
                            border:
                              selectedIconName === iconObj.name &&
                              "3px solid #fff",
                            transform:
                              selectedIconName === iconObj.name
                                ? "scale(1.2)"
                                : "scale(1)",
                          }}
                          onClick={() => handleSelectIcon(iconObj.name)}
                        >
                          <img
                            src={iconObj.icon}
                            alt="icon"
                            style={{ height: "100%", width: "100%" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {/* Banner Upload Icon */}
              {checked === "Upload" && (
                <div style={{ marginBottom: "8px" }}>
                  {/* Widget Logo Image */}
                  <label
                    htmlFor="file-upload"
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <button className="general-input-btn" htmlFor="file-upload">
                      {t("customize.widget.uploadImage/GIF")}
                      <input
                        id="file-upload"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => handleChangeBannerIcon(e)}
                        ref={BannerLogoImageRef}
                      />
                    </button>
                  </label>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <Spin indicator={antIcon} size="large" />
                      </div>
                    ) : (
                      <>
                        {customizations?.bannerIconImage !== "" && (
                          <>
                            <img
                              src={customizations?.bannerIconImage}
                              style={{ height: "80px", width: "80px" }}
                              alt="widget-icon"
                            />
                            <img
                              onClick={() => {
                                dispatch(
                                  updateCurrentChatbotCustomizationsAction(
                                    bid,
                                    "bannerIconImage",
                                    ""
                                  )
                                );

                                // Reset the file input
                                if (BannerLogoImageRef.current) {
                                  BannerLogoImageRef.current.value = "";
                                }

                                message.success(
                                  "Widget logo deleted successfully"
                                );
                              }}
                              src={DeleteIcon}
                              style={{
                                marginTop: "-30px",
                                cursor: "pointer",
                              }}
                              alt="delete-icon"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* Title Text */}
              <span>{t("customize.widget.title")}</span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.bannerTitle}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "bannerTitle",
                        e.target.value
                      )
                    )
                  }
                />
              </div>

              {/* Description Text */}
              <span>{t("customize.widget.description")}</span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.bannerDescription}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "bannerDescription",
                        e.target.value
                      )
                    )
                  }
                />
              </div>

              {/* Horizontal Line */}
              <hr />

              {/* Button One */}
              {renderButtonSettings(1)}

              {/* Button Two */}
              {renderButtonSettings(2)}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AnnouncementBannerOptions;
