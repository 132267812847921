import { useEffect, useState } from "react";
import { Switch, message } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import Arrow from "@assets/images/pages/customize/down.png";
import BrandIcon from "@assets/images/pages/customize/branding.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { user } = useSelector((state) => state?.auth);
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);
  const [hideBrand, setHideBrand] = useState(true);
  const [customBrand, setCustomBrand] = useState(true);
  const { t } = useTranslation();

  // remove Branding
  const handleRemoveBrand = () => {
    // If user.currentPlan is free send a message saying upgrade to remove branding
    if (user?.currentPlan === "Free") {
      message.warning("Upgrade to remove branding");
      return;
    }

    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "hideBrand", !hideBrand)
    );
  };

  // update brand state
  useEffect(() => {
    setHideBrand(customizations?.hideBrand);
    setCustomBrand(customizations?.enableCustomBrand);
  }, [customizations]);

  // handling Custom Brand
  const handleCustomBrand = () => {
    // If user.currentPlan is free send a message saying upgrade to remove branding
    if (user?.currentPlan === "Free") {
      message.warning("Upgrade to set custom branding");
      return;
    }

    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "enableCustomBrand",
        !customBrand
      )
    );
  };
  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => {
            setVisible(!visible);
          }}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={BrandIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="footer-icon"
            />
            {t("customize.layout.footer")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            alt="arrow"
          />
        </div>

        {visible && (
          <>
            <div
              style={{
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
              }}
            >
              {/* Branding */}
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                {/* Hide Conferbot Brand */}
                <div className="branding" onClick={handleRemoveBrand}>
                  <span> {t("customize.layout.hideBrand")}</span>
                  <Switch checked={hideBrand} size="small" />
                </div>

                {/* Set Custom Brand */}
                <div className="branding" onClick={handleCustomBrand}>
                  <span>{t("customize.layout.setCustomBrand")}</span>
                  <Switch checked={customBrand} size="small" />
                </div>
                {customizations?.enableCustomBrand && (
                  <div style={{ textAlign: "center" }}>
                    <input
                      className="general-input"
                      type="text"
                      value={customizations?.customBrand}
                      onChange={(e) =>
                        dispatch(
                          updateCurrentChatbotCustomizationsAction(
                            bid,
                            "customBrand",
                            e.target.value
                          )
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Footer;
