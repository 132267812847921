import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Input, Modal } from "antd";
import { shallow } from "zustand/shallow";
import { SegmentNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { Segment, Conferbot } from "@assets/images/pages/builder";

const SegmentNodeDataEditor: FC<SegmentNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [url, setUrl] = useState(state?.data?.questionText);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );

  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setUrl(state?.data?.questionText);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  const handleUrlChange = useCallback(
    (e: any) => {
      if (!state) return;

      // Set the welcome message
      setUrl(e.target.value);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: e.target.value,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // handleNameChange
  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  // handleAccessToken
  const handleAccessTokenChange = (e: any) => {
    setAccessToken(e.target.value);
  };
  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            ℹ️ Use this node for Segment.
          </p>
        </Row>

        {/* Connect Segment */}
        <div className="connect_wrapper">
          <span className="heading">Connect your Segment account</span>
          <button className="connect_btn" onClick={() => setOpenModal(true)}>
            connect now!
          </button>
        </div>

        {/* Connect now Modal */}
        <Modal
          visible={openModal}
          closable={true}
          onCancel={() => setOpenModal(false)}
          footer=""
          width={650}
        >
          <div className="connect_modal">
            <div className="connect_icons_wrapper">
              <img src={Conferbot} className="conferbot_logo" />
              <span>&lt; &gt;</span>
              <img src={Segment} className="connector_logo" />
            </div>
            <span className="main_heading">Link your segment account</span>

            {/* Name */}
            <Col span={24} className="sidebar__heading">
              Source name
            </Col>
            <span className="sub_heading">
              Type a name to identify the Segment source
            </span>
            <Col span={24}>
              <Input
                value={name}
                placeholder="Source name"
                onChange={(e) => handleNameChange(e)}
                style={{ borderRadius: "3px", marginBottom: "15px" }}
              />
            </Col>

            {/* Access token */}
            <Col span={24} className="sidebar__heading">
              Segment Connection Key
            </Col>
            <span className="sub_heading">
              Paste the Write Key of the HTTP API source
            </span>
            <Col span={24}>
              <Input
                value={accessToken}
                placeholder="Write key"
                onChange={(e) => handleAccessTokenChange(e)}
                style={{ borderRadius: "3px", marginBottom: "20px" }}
              />
            </Col>
            <Col>
              <button className="connect_btn_two">connect</button>
            </Col>
          </div>
        </Modal>

        {/* Question Text */}
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>

        <Col span={24}>
          <Input.TextArea
            value={url}
            placeholder="Enter your question here"
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={(e) => handleUrlChange(e)}
          />
        </Col>

        <SaveAnswer
          id={id}
          type={type}
          variableName={answerVariable ? answerVariable : "url"}
        />
      </Row>
    </>
  );
};

export default SegmentNodeDataEditor;
