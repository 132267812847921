import { useState, useEffect } from "react";
import { Row, Col, Card, Tooltip, Select } from "antd";
import { useParams } from "react-router";
import { CopyBlock, dracula } from "react-code-blocks";
import { BsWordpress } from "react-icons/bs";
import {
  SiWebflow,
  SiWix,
  SiSquarespace,
  SiShopify,
  SiNextdotjs,
} from "react-icons/si";
import { RiReactjsFill } from "react-icons/ri";
import { EmbedType, EmailInstructionsModal } from "./components";
import { useTranslation } from "react-i18next";

const ChatbotEmbed = () => {
  const { bid } = useParams<{ bid: string }>();
  // State for selected platform and embed type
  const [platform, setPlatform] = useState("html");
  const [embedType, setEmbedType] = useState("live_chat");
  const [codeBlock, setCodeBlock] = useState(``);
  const [open, setOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  // Set embed type to live chat by default
  useEffect(() => {
    handleEmbedTypeChange("live_chat");
  }, []);

  // List of web builders for UI rendering
  const [webBuildersList] = useState([
    {
      name: "React JS",
      icon: <RiReactjsFill size={32} />,
      url: "https://www.conferbot.com/resources/install-guide/react",
    },
    {
      name: "Next JS",
      icon: <SiNextdotjs size={32} />,
      url: "https://www.conferbot.com/resources/install-guide/next",
    },
    {
      name: "Wordpress",
      icon: <BsWordpress size={32} />,
      url: "https://www.conferbot.com/resources/install-guide/wordpress",
    },
    {
      name: "Webflow",
      icon: <SiWebflow size={32} />,
      url: "https://www.conferbot.com/resources/install-guide",
    },
    {
      name: "Wix",
      icon: <SiWix size={32} />,
      url: "https://www.conferbot.com/resources/install-guide",
    },
    {
      name: "Squarespace",
      icon: <SiSquarespace size={32} />,
      url: "https://www.conferbot.com/resources/install-guide",
    },
    {
      name: "Shopify",
      icon: <SiShopify size={32} />,
      url: "https://www.conferbot.com/resources/install-guide/shopify",
    },
  ]);

  const defaultCodeBlock = `<!--Start of Conferbot Script-->
  <script type="text/javascript">
    (function (d, s, id) {
      var js,
        el = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.async = true;
      js.src = 'https://s3.ap-south-1.amazonaws.com/conferbot.defaults/dist/v1/widget.min.js';
      js.id = id;
      js.charset = 'UTF-8';
      el.parentNode.insertBefore(js, el);
      // Initializes the widget when the script is ready
      js.onload = function () {
          var w = window.ConferbotWidget("${bid}", "${embedType}");
      };
    })(document, 'script', 'conferbot-js');
  </script>
  <!--End of Conferbot Script-->`;

  // Update code block when embed type changes
  const handleEmbedTypeChange = (embedType: string) => {
    setEmbedType(embedType);

    // Update codeBlock based on platform and embedType
    const newCodeBlock = generateCodeBlock(platform, embedType);
    setCodeBlock(newCodeBlock);
  };

  // Function to generate code block based on platform and embed type
  const generateCodeBlock = (platform: string, embedType: string) => {
    // Define code blocks for different platforms
    // Base URL for the script
    const scriptURL =
      "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/dist/v1/widget.min.js";

    // Template for script insertion
    const scriptTemplate = (additionalContent = "") => `
    <!--Start of Conferbot Script-->
    <script type="text/javascript">
      (function (d, s, id) {
        var js, el = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.async = true;
        js.src = '${scriptURL}';
        js.id = id;
        js.charset = 'UTF-8';
        el.parentNode.insertBefore(js, el);
        js.onload = function () {
          ${additionalContent}
        };
      })(document, 'script', 'conferbot-js');
    </script>
    <!--End of Conferbot Script-->
  `;

    // Generate specific code block based on platform
    switch (platform) {
      case "reactjs":
        return scriptTemplate(
          `var w = window.ConferbotWidget("${bid}", "${embedType}");`
        );

      case "nextjs":
        return `
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: ${JSON.stringify(
              scriptTemplate(
                `var w = window.ConferbotWidget("${bid}", "${embedType}");`
              )
            )}
          }}
        />
      `;

      case "html":
        // HTML implementation (similar to React JS)
        return scriptTemplate(
          `var w = window.ConferbotWidget("${bid}", "${embedType}");`
        );

      case "wordpress":
      case "webflow":
      case "wix":
      case "squarespace":
      case "shopify":
        // Assuming a similar implementation for these platforms
        return scriptTemplate(
          `var w = window.ConferbotWidget("${bid}", "${embedType}");`
        );

      default:
        return ""; // Return empty string for unknown platform
    }
  };

  // Handle modal open/close
  const handleEmailInstructionsClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {open && (
        <EmailInstructionsModal
          open={open}
          handleModalClick={handleEmailInstructionsClick}
          codeBlock={codeBlock ? codeBlock : defaultCodeBlock}
        />
      )}

      <Row gutter={32}>
        <Col span={24}>
          <Card
            style={{
              height: "calc(100vh - 64px)",
              overflowY: "auto",
              borderRadius: "0px",
              paddingLeft: "40px",
            }}
          >
            {/* Header */}
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                  }}
                >
                  <h3>{t("share.embedHeading")}</h3>
                </div>
              </Col>
            </Row>

            {/* Platform/Framework Selection */}
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <h5>{t("share.selectPlatform")}</h5>
                <Select
                  style={{ width: "33%", marginLeft: "1rem" }}
                  placeholder="Select platform/framework"
                  onChange={(value: any) => {
                    setPlatform(value);
                    const newCodeBlock = generateCodeBlock(value, embedType);
                    setCodeBlock(newCodeBlock);
                  }}
                  defaultValue={platform}
                >
                  <Select.Option value="html">HTML</Select.Option>
                  <Select.Option value="reactjs">React JS</Select.Option>
                  <Select.Option value="nextjs">Next JS</Select.Option>
                  <Select.Option value="wordpress">Wordpress</Select.Option>
                  <Select.Option value="webflow">Webflow</Select.Option>
                  <Select.Option value="wix">Wix</Select.Option>
                  <Select.Option value="squarespace">Squarespace</Select.Option>
                  <Select.Option value="shopify">Shopify</Select.Option>
                </Select>
              </Col>
            </Row>

            {/* 2 line space */}
            <br />
            <br />

            {/* 2 - Choose type of display */}
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <EmbedType
                  embedType={embedType}
                  handleEmbedTypeChange={handleEmbedTypeChange}
                />
              </Col>
            </Row>

            {/* 2 line space */}
            <br />
            <br />

            {/* 3 - Copy Paste the code */}
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <h5>
                  {platform === "nextjs"
                    ? t("share.nextJsInstruction")
                    : platform === "reactjs"
                    ? t("share.reactJsInstruction")
                    : platform === "html"
                    ? t("share.htmlInstruction")
                    : platform === "wordpress"
                    ? t("share.wordPressInstruction")
                    : platform === "webflow"
                    ? t("share.webFlowInstruction")
                    : platform === "wix"
                    ? t("share.wixInstruction")
                    : platform === "squarespace"
                    ? t("share.squarespaceInstruction")
                    : platform === "shopify"
                    ? t("share.shopifyInstruction")
                    : ""}
                </h5>

                <br />

                <div>
                  <CopyBlock
                    language="html"
                    text={codeBlock}
                    codeBlock
                    wrapLines={true}
                    theme={dracula}
                    showLineNumbers={false}
                  />
                </div>
              </Col>
            </Row>

            {/* 2 line space */}
            <br />
            <br />

            <Row gutter={[32, 32]}>
              <Col span={12}>
                <Card
                  title={t("share.stillNotSure")}
                  className="hp-elevatior"
                  style={{
                    backgroundColor: "#f0f2f5",
                    borderRadius: "0.5rem",
                  }}
                >
                  {/* Display all icons in one row */}
                  <Row gutter={[32, 32]}>
                    {webBuildersList?.map((webBuilder) => {
                      return (
                        <Col span={3} key={webBuilder.name}>
                          <Tooltip
                            title={webBuilder.name}
                            placement="top"
                            arrowPointAtCenter
                          >
                            <a
                              href={webBuilder.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {/* Circle surrounding the icon */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "3rem",
                                  height: "3rem",
                                  borderRadius: "50%",
                                  backgroundColor: "#bdbfc0",
                                  marginBottom: "1rem",
                                }}
                              >
                                {webBuilder.icon}
                              </div>
                            </a>
                          </Tooltip>
                        </Col>
                      );
                    })}
                  </Row>

                  <br />

                  <h5>
                    Or{" "}
                    <p
                      onClick={handleEmailInstructionsClick}
                      style={{
                        display: "inline",
                        color: "#1890ff",
                        cursor: "pointer",
                        marginRight: "4px",
                      }}
                    >
                      {t("share.emailInstruction")}
                    </p>
                    {t("share.toYourDeveloper")}
                  </h5>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ChatbotEmbed;
