import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Input, DatePicker, Divider, Form, Spin } from "antd";
import moment from "moment";
import {
  getChangelogById,
  updateChangelogById,
} from "@api/workspace/changelog"; // Adjust the import path based on your API
import { SuccessNotification, ErrorNotification } from "../../"; // Adjust imports based on your components
import JoditEditor from "jodit-react";

const ChangelogUpdateModal = ({
  open,
  handleModalClick,
  fetchChangelogs,
  changelogId,
}) => {
  const [changelogData, setChangelogData] = useState({
    version: "",
    releaseDate: null,
    updates: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (changelogId && open) {
      fetchChangelogDetails();
    }
  }, [changelogId, open]);

  const fetchChangelogDetails = async () => {
    setLoading(true);
    try {
      const { data } = await getChangelogById(changelogId);
      setChangelogData({
        version: data.version,
        releaseDate: moment(data.releaseDate),
        updates: data.updates || "",
      });
    } catch (error) {
      ErrorNotification("Failed to fetch changelog details");
      console.error("Error fetching changelog details", error);
    }
    setLoading(false);
  };

  const handleInputChange = (value, name) => {
    setChangelogData({ ...changelogData, [name]: value });
  };

  const handleChangelogUpdate = async () => {
    setLoading(true);
    try {
      await updateChangelogById(changelogId, {
        ...changelogData,
        releaseDate: changelogData.releaseDate.format("YYYY-MM-DD"),
      });
      SuccessNotification("Changelog updated successfully");
      fetchChangelogs();
      handleModalClick();
    } catch (error) {
      ErrorNotification("Error updating changelog");
    } finally {
      setLoading(false);
    }
  };

  // Config for JoditEditor
  // Config for JoditEditor
  const joditConfig = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      minHeight: 200, // Set minimum height for the editor
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
      buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "font",
        "fontsize",
        "|",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "superscript",
        "subscript",
        "|",
        "brush",
        "paragraph",
        "|",
        "align",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "undo",
        "redo",
        "|",
        "cut",
        "copy",
        "paste",
        "selectall",
        "|",
        "link",
        "unlink",
        "|",
        "image",
        "video",
        "|",
        "table",
        "hr",
        "|",
        "symbol",
        "eraser",
        "|",
        "print",
        "fullsize",
        "about",
        "spellcheck",
        "|",
        "preview",
        "print",
        "about",
      ],
    }),
    []
  );

  return (
    <Modal
      title="Update Changelog"
      visible={open}
      onCancel={handleModalClick}
      footer={[
        <Button key="back" onClick={handleModalClick} loading={loading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleChangelogUpdate}
          loading={loading}
        >
          Update
        </Button>,
      ]}
      width={1100}
      style={{ top: 20 }}
    >
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="Version" required>
            <Input
              value={changelogData.version}
              onChange={(e) => handleInputChange(e.target.value, "version")}
              placeholder="e.g., 1.0.0"
            />
          </Form.Item>
          <Form.Item label="Release Date" required>
            <DatePicker
              style={{ width: "100%" }}
              value={changelogData.releaseDate}
              onChange={(date) => handleInputChange(date, "releaseDate")}
            />
          </Form.Item>
          <Divider>Updates</Divider>
          <Form.Item label="Updates" required>
            <JoditEditor
              value={changelogData.updates}
              config={joditConfig}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => handleInputChange(newContent, "updates")}
              onChange={(newContent) => {}}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ChangelogUpdateModal;
