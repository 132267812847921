import { FC, useState, useCallback } from "react";
import { Row, Col, Select, Switch, message, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { EmailNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { useTranslation } from "react-i18next";

const EmailNodeDataEditor: FC<EmailNode> = ({ type, id }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [emailTo, setEmailTo] = useState(state?.data?.to);
  const [emailCc, setEmailCc] = useState(state?.data?.cc);
  const [emailBcc, setEmailBcc] = useState(state?.data?.bcc);
  const [emailSubject, setEmailSubject] = useState(state?.data?.subject);
  const [emailBody, setEmailBody] = useState(state?.data?.body);
  const [includeChatTranscript, setIncludeChatTranscript] = useState(
    state?.data?.includeChatTranscript
  );
  const { t } = useTranslation();

  // Handle email data change
  const handleEmailDataChange = useCallback(
    (content: any, key: string) => {
      if (!state) return;

      // setBotHtml(content);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, [key]: content },
      });
    },
    [state, type, changeNodeData]
  );

  // Email validation helper function
  const validateEmailHelper = (emails: any) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emails.every((email: string) =>
      re.test(String(email).toLowerCase())
    );
  };

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t("flowBuilder.sidebar.editor.specialNodes.sendEmailNodeInfo")}
        </p>
      </Row>

      {/* Email to */}
      <Row align="middle">
        <Divider orientation="left">
          <Col span={4} className="sidebar__heading">
            To
          </Col>
        </Divider>
        <Col span={20}>
          <Select
            mode="tags"
            placeholder="Ex: john@xyz.com"
            value={emailTo}
            onChange={(value: any) => {
              if (validateEmailHelper(value)) {
                setEmailTo(value);
                handleEmailDataChange(value, "to");
              } else {
                message.error("Invalid email address");
              }
            }}
            style={{ minWidth: "100%" }}
          ></Select>
        </Col>
      </Row>

      {/* Email cc */}
      <Row
        align="middle"
        style={{
          marginTop: "10px",
        }}
      >
        <Divider orientation="left">
          <Col span={4} className="sidebar__heading">
            Cc
          </Col>
        </Divider>
        <Col span={20}>
          <Select
            mode="tags"
            placeholder="Ex: john@xyz.com"
            value={emailCc}
            onChange={(value: any) => {
              if (validateEmailHelper(value)) {
                setEmailCc(value);
                handleEmailDataChange(value, "cc");
              } else {
                message.error("Invalid email address");
              }
            }}
            style={{ width: "100%" }}
          ></Select>
        </Col>
      </Row>

      {/* Email bcc */}
      <Row
        align="middle"
        style={{
          marginTop: "10px",
        }}
      >
        <Divider orientation="left">
          <Col span={4} className="sidebar__heading">
            Bcc
          </Col>
        </Divider>
        <Col span={20}>
          <Select
            mode="tags"
            style={{ width: "100%", borderRadius: "4px" }}
            placeholder="Ex: jane@abc.com"
            value={emailBcc}
            onChange={(value: any) => {
              if (validateEmailHelper(value)) {
                setEmailBcc(value);
                handleEmailDataChange(value, "bcc");
              } else {
                message.error("Invalid email address");
              }
            }}
          ></Select>
        </Col>
      </Row>

      {/* Email subject */}
      <Row
        align="middle"
        style={{
          marginTop: "15px",
        }}
      >
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.subject")}
          </Col>
        </Divider>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={emailSubject}
              onEditorChange={(content) => {
                setEmailSubject(content);
                handleEmailDataChange(content, "subject");
              }}
              placeholderText={t(
                "flowBuilder.sidebar.editor.specialNodes.subjectPH"
              )}
            />
          </div>
        </Col>
      </Row>

      {/* Email body */}
      <Row align="middle">
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.body")}
          </Col>
        </Divider>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={emailBody}
              onEditorChange={(content) => {
                setEmailBody(content);
                handleEmailDataChange(content, "body");
              }}
              placeholderText={t(
                "flowBuilder.sidebar.editor.specialNodes.bodyPH"
              )}
            />
          </div>
        </Col>
      </Row>

      {/* Email include chat transcript */}
      <Row align="middle">
        <Col span={17}>
          <h5 className="hp-mb-6 hp-mt-8 sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.chatTranscript")}
          </h5>
          <p
            className="hp-mb-12 hp-mt-4"
            style={{
              fontSize: "10px",
              color: "gray",
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.chatTranscriptInfo")}
          </p>
        </Col>
        <Col span={6}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked
            checked={includeChatTranscript}
            onChange={(checked: any) => {
              setIncludeChatTranscript(checked);
              handleEmailDataChange(checked, "includeChatTranscript");
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default EmailNodeDataEditor;
