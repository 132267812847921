import { useTranslation } from "react-i18next";
import { NodeTypes } from "../../../types/types";

const SidebarOptions = () => {
  const { t } = useTranslation();

  // ** ALL (V1) LEGACY NODES ** //
  const LegacyNodes = [
    {
      id: NodeTypes.Rating,
      label: t("flowBuilder.sidebar.legacyNodes.rating"),
      type: NodeTypes.Rating,
    },
    {
      id: NodeTypes.Range,
      label: t("flowBuilder.sidebar.legacyNodes.range"),
      type: NodeTypes.Range,
    },
    {
      id: NodeTypes.UserInput,
      label: t("flowBuilder.sidebar.legacyNodes.user"),
      type: NodeTypes.UserInput,
    },
    {
      id: NodeTypes.Quiz,
      label: t("flowBuilder.sidebar.legacyNodes.quiz"),
      type: NodeTypes.Quiz,
    },
  ];

  // ** SEND RESPONSE NODES **
  const SendResponseNodes = [
    {
      id: NodeTypes.Message,
      label: t("flowBuilder.sidebar.sendResponseNodes.textMessage"),
      type: NodeTypes.Message,
      description: t("flowBuilder.sidebar.sendResponseNodes.textMessageDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Image,
      label: t("flowBuilder.sidebar.sendResponseNodes.imageMessage"),
      type: NodeTypes.Image,
      description: t("flowBuilder.sidebar.sendResponseNodes.imageMessageDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Video,
      label: t("flowBuilder.sidebar.sendResponseNodes.videoMessage"),
      type: NodeTypes.Video,
      description: t("flowBuilder.sidebar.sendResponseNodes.videoMessageDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Audio,
      label: t("flowBuilder.sidebar.sendResponseNodes.audioMessage"),
      type: NodeTypes.Audio,
      description: t("flowBuilder.sidebar.sendResponseNodes.audioMessageDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.File,
      label: t("flowBuilder.sidebar.sendResponseNodes.fileMessage"),
      type: NodeTypes.File,
      description: t("flowBuilder.sidebar.sendResponseNodes.fileMessageDesc"),
    },
  ];

  // ** ASK QUESTION NODES **
  const AskQuestionNodes = [
    {
      id: NodeTypes.NSelectOption,
      label: t("flowBuilder.sidebar.askQuestionNodes.nSelectOptions"),
      type: NodeTypes.NSelectOption,
      description: t("flowBuilder.sidebar.askQuestionNodes.nSelectOptionsDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.NCheckOptions,
      label: t("flowBuilder.sidebar.askQuestionNodes.nCheckOptions"),
      type: NodeTypes.NCheckOptions,
      description: t("flowBuilder.sidebar.askQuestionNodes.nCheckOptionsDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskMultipleQuestions,
      label: t("flowBuilder.sidebar.askQuestionNodes.multiQuestions"),
      type: NodeTypes.AskMultipleQuestions,
      description: t("flowBuilder.sidebar.askQuestionNodes.multiQuestionsDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Calendar,
      label: t("flowBuilder.sidebar.askQuestionNodes.askCalendar"),
      type: NodeTypes.Calendar,
      description: t("flowBuilder.sidebar.askQuestionNodes.askCalendarDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskName,
      label: t("flowBuilder.sidebar.askQuestionNodes.askName"),
      type: NodeTypes.AskName,
      description: t("flowBuilder.sidebar.askQuestionNodes.askNameDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskEmail,
      label: t("flowBuilder.sidebar.askQuestionNodes.askEmail"),
      type: NodeTypes.AskEmail,
      description: t("flowBuilder.sidebar.askQuestionNodes.askEmailDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskPhoneNumber,
      label: t("flowBuilder.sidebar.askQuestionNodes.askPhoneNumber"),
      type: NodeTypes.AskPhoneNumber,
      description: t("flowBuilder.sidebar.askQuestionNodes.askPhoneNumberDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskFile,
      label: t("flowBuilder.sidebar.askQuestionNodes.askFile"),
      type: NodeTypes.AskFile,
      description: t("flowBuilder.sidebar.askQuestionNodes.askFileDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskNumber,
      label: t("flowBuilder.sidebar.askQuestionNodes.askNumber"),
      type: NodeTypes.AskNumber,
      description: t("flowBuilder.sidebar.askQuestionNodes.askNumberDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskUrl,
      label: t("flowBuilder.sidebar.askQuestionNodes.askUrl"),
      type: NodeTypes.AskUrl,
      description: t("flowBuilder.sidebar.askQuestionNodes.askUrlDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.AskLocation,
      label: t("flowBuilder.sidebar.askQuestionNodes.askLocation"),
      type: NodeTypes.AskLocation,
      description: t("flowBuilder.sidebar.askQuestionNodes.askLocationDesc"),
      isPremium: false,
    },

    {
      id: NodeTypes.AskCustomQuestion,
      label: t("flowBuilder.sidebar.askQuestionNodes.customQuestion"),
      type: NodeTypes.AskCustomQuestion,
      description: t("flowBuilder.sidebar.askQuestionNodes.customQuestionDesc"),
      isPremium: false,
    },
  ];

  // ** FLOW OPERATIONS NODES **
  const FlowOperations = [
    {
      id: NodeTypes.NChoices,
      label: t("flowBuilder.sidebar.flowOperationsNodes.nChoices"),
      type: NodeTypes.NChoices,
      description: t("flowBuilder.sidebar.flowOperationsNodes.nChoicesDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.ImageChoice,
      label: t("flowBuilder.sidebar.flowOperationsNodes.imageChoice"),
      type: NodeTypes.ImageChoice,
      description: t("flowBuilder.sidebar.flowOperationsNodes.imageChoiceDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.RatingChoice,
      label: t("flowBuilder.sidebar.flowOperationsNodes.ratingChoice"),
      type: NodeTypes.RatingChoice,
      description: t(
        "flowBuilder.sidebar.flowOperationsNodes.ratingChoiceDesc"
      ),
      isPremium: false,
    },
    {
      id: NodeTypes.YesOrNo,
      label: t("flowBuilder.sidebar.flowOperationsNodes.yesOrNo"),
      type: NodeTypes.YesOrNo,
      description: t("flowBuilder.sidebar.flowOperationsNodes.yesOrNoDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.OpinionScale,
      label: t("flowBuilder.sidebar.flowOperationsNodes.opinionScale"),
      type: NodeTypes.OpinionScale,
      description: t(
        "flowBuilder.sidebar.flowOperationsNodes.opinionScaleDesc"
      ),
      isPremium: false,
    },
  ];

  // ** LOGICAL NODES **
  const LogicalNodes = [
    {
      id: NodeTypes.BusinessHours,
      label: t("flowBuilder.sidebar.logicalNodes.businessHours"),
      type: NodeTypes.BusinessHours,
      description: t("flowBuilder.sidebar.logicalNodes.businessHoursDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Condition,
      label: t("flowBuilder.sidebar.logicalNodes.condition"),
      type: NodeTypes.Condition,
      description: t("flowBuilder.sidebar.logicalNodes.conditionDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.MathOperation,
      label: t("flowBuilder.sidebar.logicalNodes.mathOperation"),
      type: NodeTypes.MathOperation,
      description: t("flowBuilder.sidebar.logicalNodes.mathOperationDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.BooleanLogic,
      label: t("flowBuilder.sidebar.logicalNodes.booleanLogic"),
      type: NodeTypes.BooleanLogic,
      description: t("flowBuilder.sidebar.logicalNodes.booleanLogicDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.RandomFlow,
      label: t("flowBuilder.sidebar.logicalNodes.randomFlow"),
      type: NodeTypes.RandomFlow,
      description: t("flowBuilder.sidebar.logicalNodes.randomFlowDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Variable,
      label: t("flowBuilder.sidebar.logicalNodes.variable"),
      type: NodeTypes.Variable,
      description: t("flowBuilder.sidebar.logicalNodes.variableDesc"),
      isPremium: false,
    },
  ];

  // ** SPECIAL NODES **
  const SpecialNodes = [
    {
      id: NodeTypes.Email,
      label: t("flowBuilder.sidebar.specialNodes.sendAnEmail"),
      type: NodeTypes.Email,
      description: t("flowBuilder.sidebar.specialNodes.sendAnEmailDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Html,
      label: t("flowBuilder.sidebar.specialNodes.html"),
      type: NodeTypes.Html,
      description: t("flowBuilder.sidebar.specialNodes.htmlDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Redirect,
      label: t("flowBuilder.sidebar.specialNodes.redirect"),
      type: NodeTypes.Redirect,
      description: t("flowBuilder.sidebar.specialNodes.redirectDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.JumpTo,
      label: t("flowBuilder.sidebar.specialNodes.jumpTo"),
      type: NodeTypes.JumpTo,
      description: t("flowBuilder.sidebar.specialNodes.jumpToDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.HumanHandover,
      label: t("flowBuilder.sidebar.specialNodes.humanHandover"),
      type: NodeTypes.HumanHandover,
      description: t("flowBuilder.sidebar.specialNodes.humanHandoverDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Delay,
      label: t("flowBuilder.sidebar.specialNodes.delay"),
      type: NodeTypes.Delay,
      description: t("flowBuilder.sidebar.specialNodes.delayDesc"),
      isPremium: false,
    },
    {
      id: NodeTypes.Navigate,
      label: t("flowBuilder.sidebar.specialNodes.navigate"),
      type: NodeTypes.Navigate,
      description: t("flowBuilder.sidebar.specialNodes.delayDesc"),
      isPremium: false,
    },
  ];

  // ** INTEGRATION NODES **
  const IntegrationNodes = [
    {
      id: NodeTypes.Webhook,
      label: t("flowBuilder.sidebar.integrationNodes.webhook"),
      type: NodeTypes.Webhook,
      description: t("flowBuilder.sidebar.integrationNodes.webhookDesc"),
      isPremium: true,
      plans: ["Pro", "Business", "Lifetime"],
    },
    {
      id: NodeTypes.Gpt,
      label: t("flowBuilder.sidebar.integrationNodes.gpt"),
      type: NodeTypes.Gpt,
      description: t("flowBuilder.sidebar.integrationNodes.gptDesc"),
      isPremium: true,
      plans: ["Pro", "Business", "Lifetime"],
    },
    {
      id: NodeTypes.Zapier,
      label: t("flowBuilder.sidebar.integrationNodes.zapier"),
      type: NodeTypes.Zapier,
      description: t("flowBuilder.sidebar.integrationNodes.zapierDesc"),
      isPremium: true,
      plans: ["Pro", "Business"],
    },
    // {
    //   id: NodeTypes.GoogleSheets,
    //   label: "Google Sheets",
    //   type: NodeTypes.GoogleSheets,
    //   description:
    //     "Enables interaction with Google Sheets, allowing for data retrieval and updates within spreadsheets.",
    //   isPremium: true,
    //   plans: ["Pro", "Business", "Lifetime"],
    // },
    // {
    //   id: NodeTypes.Slack,
    //   label: "Slack",
    //   type: NodeTypes.Slack,
    //   description:
    //     "Facilitates sending messages or receiving notifications through Slack channels.",
    //   isPremium: true,
    //   plans: ["Pro", "Business"],
    // },
    // {
    //   id: NodeTypes.Airtable,
    //   label: "Airtable",
    //   type: NodeTypes.Airtable,
    //   description:
    //     "Integrates with Airtable to manage and automate workflows using database-like functionalities.",
    //   isPremium: true,
    //   plans: ["Pro", "Business"],
    // },
    // {
    //   id: NodeTypes.Zoho,
    //   label: "Zoho",
    //   type: NodeTypes.Zoho,
    //   description:
    //     "Connects with Zoho CRM for customer relationship management, enabling data synchronization and retrieval.",
    //   isPremium: true,
    //   plans: ["Pro", "Business"],
    // },
    // {
    //   id: NodeTypes.HubSpot,
    //   label: "HubSpot",
    //   type: NodeTypes.HubSpot,
    //   description:
    //     "Links with HubSpot for CRM, marketing, sales, and service software integrations.",
    //   isPremium: true,
    //   plans: ["Pro", "Business"],
    // },
    // {
    //   id: NodeTypes.MailChimp,
    //   label: "MailChimp",
    //   type: NodeTypes.MailChimp,
    //   description:
    //     "Integrates with MailChimp for email marketing, audience data management, and campaign automation.",
    //   isPremium: true,
    //   plans: ["Pro", "Business"],
    // },
    // {
    //   id: NodeTypes.Segment,
    //   label: "Segment",
    //   type: NodeTypes.Segment,
    //   description:
    //     "Connects with Segment for consolidating, managing, and routing user data to various analytics services.",
    //   isPremium: true,
    //   plans: ["Pro", "Business"],
    // },
    // {
    //   id: NodeTypes.Salesforce,
    //   label: "Salesforce",
    //   type: NodeTypes.Salesforce,
    //   description:
    //     "Enables Salesforce integration for advanced customer relationship management and data syncing.",
    //   isPremium: true,
    //   plans: ["Pro", "Business"],
    // },
  ];
  return {
    LegacyNodes,
    SendResponseNodes,
    AskQuestionNodes,
    FlowOperations,
    LogicalNodes,
    SpecialNodes,
    IntegrationNodes,
  };
};

export default SidebarOptions;
