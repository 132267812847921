import { useTranslation } from "react-i18next";
import { Home, User, Show, Send, Ticket, Discount } from "react-iconly";
import { FaBlogger } from "react-icons/fa";
import { FcFlowChart } from "react-icons/fc";

const SuperAdmin = () => {
  const { t } = useTranslation();

  const superAdminNavArray = [
    {
      id: "dashboard",
      title: t("navigation.superAdmin.dashboard"),
      icon: (
        <svg
          width="18px"
          height="18px"
          className="remix-icon"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#000000"
            d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z"
          ></path>
        </svg>
      ),
      navLink: "/super-admin/dashboard",
    },
    {
      id: "admins",
      title: t("navigation.superAdmin.admins"),
      icon: (
        <svg
          width="18px"
          height="18px"
          className="remix-icon"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#000000"
            d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4Zm9 6h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
          />
        </svg>
      ),
      navLink: "/super-admin/admins",
    },
    {
      id: "visitors",
      title: t("navigation.superAdmin.visitors"),
      icon: (
        <svg
          width="18px"
          height="18px"
          className="remix-icon"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#bfc7cb"
            d="M46.594 50.28c0 7.577-6.625 13.723-14.797 13.723c-8.171 0-14.796-6.146-14.796-13.723V13.724c0-7.577 6.625-13.721 14.795-13.721c8.172 0 14.797 6.144 14.797 13.721V50.28z"
          />
          <path
            fill="#1f2e35"
            d="M44.46 48.716c0 6.938-5.624 12.562-12.564 12.562c-6.938 0-12.564-5.624-12.564-12.562v-33.47c0-6.938 5.626-12.564 12.564-12.564c6.94 0 12.564 5.626 12.564 12.564v33.47"
          />
          <path
            fill="#152228"
            d="M26.849 48.714V15.246c0-5.631 3.706-10.395 8.806-11.993a12.567 12.567 0 0 0-3.758-.572c-6.938 0-12.564 5.626-12.564 12.564v33.468c0 6.94 5.626 12.564 12.564 12.564c1.309 0 2.572-.2 3.758-.573c-5.1-1.595-8.806-6.361-8.806-11.99"
          />
          <circle cx="31.897" cy="13.656" r="8.121" fill="#ec1c24" />
          <circle cx="31.897" cy="31.979" r="8.121" fill="#ffdd15" />
          <circle cx="31.897" cy="50.26" r="8.121" fill="#4fba80" />
        </svg>
      ),
      navLink: "/super-admin/visitors",
    },
    {
      id: "chatbot templates",
      title: t("navigation.superAdmin.chatbotTemplates"),
      icon: <FcFlowChart set="curved" className="remix-icon" />,
      navLink: "/super-admin/chatbot-templates",
    },
    {
      id: "blogs",
      title: t("navigation.superAdmin.blogs"),
      icon: (
        <svg
          className="remix-icon"
          width="18px"
          height="18px"
          viewBox="0 0 650 679"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#000000"
            d="M326 306h-73c-12 0-23-11-23-24c0-12 11-23 23-23h73c13 0 23 11 23 23c0 13-10 24-23 24m-73 98h145c13 0 23 10 23 23s-10 23-23 23H253c-12 0-23-10-23-23s11-23 23-23M98 29h454c54 0 98 44 98 98v454c0 54-44 98-98 98H98c-54 0-98-44-98-98V127c0-54 44-98 98-98m428 403v-96c0-18-5-30-27-30h-17c-18 0-28-11-28-24v-11c0-57-62-117-116-117h-85c-71 0-127 56-127 127v152c0 67 58 121 117 121h161c65 0 122-55 122-122"
          />
        </svg>
      ),
      navLink: "/super-admin/blogs",
    },

    // ------- SEO -------
    {
      id: "seo",
      title: "SEO",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="remix-icon"
          width="18px"
          height="18px"
          viewBox="0 0 48 48"
        >
          <mask id="ipSSeo0">
            <g fill="none" stroke-width="4">
              <rect
                width="40"
                height="32"
                x="4"
                y="8"
                fill="#fff"
                stroke="#fff"
                rx="1.633"
              />
              <path
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16 18.948c-2-2.948-5.502-1.01-5.251 2.02C11 24 15 24 15.249 27.032C15.5 30.062 12 32 10 29.051M26 18h-4v13h4m-4-6h4"
              />
              <rect
                width="6"
                height="13"
                x="32"
                y="18"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                rx="3"
              />
            </g>
          </mask>
          <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSSeo0)" />
        </svg>
      ),
      navLink: "/super-admin/seo/chatbot-template-blogs",
    },

    {
      id: "changelogs",
      title: t("navigation.superAdmin.changelogs"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12 2a10 10 0 0 0-6.88 2.77V3a1 1 0 0 0-2 0v4.5a1 1 0 0 0 1 1h4.5a1 1 0 0 0 0-2h-2.4A8 8 0 1 1 4 12a1 1 0 0 0-2 0A10 10 0 1 0 12 2Zm0 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2h-1V9a1 1 0 0 0-1-1Z"
          />
        </svg>
      ),
      navLink: "/super-admin/changelogs",
    },
    {
      id: "plans",
      title: t("navigation.superAdmin.plans"),
      icon: (
        <svg
          width="18px"
          height="18px"
          className="remix-icon"
          viewBox="0 0 20 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#000000"
            d="M19.25 0h-6c-.412 0-.989.239-1.28.53L4.531 7.969a.752.752 0 0 0 0 1.061l6.439 6.439a.752.752 0 0 0 1.061 0L19.47 8.03c.292-.292.53-.868.53-1.28v-6a.752.752 0 0 0-.75-.75zM15.5 6a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 15.5 6z"
          />
          <path
            fill="#000000"
            d="M2 8.5L10.5 0H9.25c-.412 0-.989.239-1.28.53L.531 7.969a.752.752 0 0 0 0 1.061l6.439 6.439a.752.752 0 0 0 1.061 0l.47-.47l-6.5-6.5z"
          />
        </svg>
      ),
      navLink: "/super-admin/plans",
    },
    {
      id: "coupons",
      title: t("navigation.superAdmin.coupons"),
      icon: (
        <svg
          width="18px"
          height="18px"
          className="remix-icon"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#000000"
            d="M14.8 8L16 9.2L9.2 16L8 14.8L14.8 8M4 4h16c1.11 0 2 .89 2 2v4a2 2 0 1 0 0 4v4c0 1.11-.89 2-2 2H4a2 2 0 0 1-2-2v-4c1.11 0 2-.89 2-2a2 2 0 0 0-2-2V6a2 2 0 0 1 2-2m0 2v2.54a3.994 3.994 0 0 1 0 6.92V18h16v-2.54a3.994 3.994 0 0 1 0-6.92V6H4m5.5 2c.83 0 1.5.67 1.5 1.5S10.33 11 9.5 11S8 10.33 8 9.5S8.67 8 9.5 8m5 5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5Z"
          />
        </svg>
      ),
      navLink: "/super-admin/coupons",
    },
    {
      id: "leads",
      title: t("navigation.superAdmin.leads"),
      icon: (
        <svg
          className="remix-icon"
          width="18px"
          height="18px"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#000000"
            d="M22 22v6H6V4h10V2H6a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6Z"
          />
          <path
            fill="#000000"
            d="m29.54 5.76l-3.3-3.3a1.6 1.6 0 0 0-2.24 0l-14 14V22h5.53l14-14a1.6 1.6 0 0 0 0-2.24ZM14.7 20H12v-2.7l9.44-9.45l2.71 2.71ZM25.56 9.15l-2.71-2.71l2.27-2.27l2.71 2.71Z"
          />
        </svg>
      ),
      navLink: "/super-admin/leads",
    },
  ];
  return { superAdminNavArray };
};

export default SuperAdmin;
