import { useEffect } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AdminDashboardAnalytics } from "@components";
import { getAdminDashboardAnalyticsAction } from "@redux/actions/adminActions";
import { useTranslation } from "react-i18next";

const AdminDashboard = () => {
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // *** Get Admin Dashboard Analytics *** //
  useEffect(() => {
    if (user) {
      dispatch(getAdminDashboardAnalyticsAction());
    }
  }, [dispatch, user]);

  return (
    <>
      <Row
        style={{
          backgroundColor: "#ffffff",
          height: "100vh",
          padding: "20px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
        }}
      >
        <Col
          span={24}
          style={{
            marginLeft: "20px",
          }}
        >
          <h2>
            {t("dashboard.welcome", { name: user?.name.split(" ")[0] })} 👋
          </h2>
        </Col>

        {/* Dashboard Analytics */}
        <Col
          span={24}
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100vh - 85px)",
          }}
        >
          <AdminDashboardAnalytics />
        </Col>
      </Row>
    </>
  );
};

export default AdminDashboard;
