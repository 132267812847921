import { useEffect, useState } from "react";
import { Input, Button, message, Switch, Tooltip } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import Arrow from "@assets/images/pages/customize/down.png";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useTranslation } from "react-i18next";

// standard URL validation regex
const urlPattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

const DisabledUrls = () => {
  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);
  const [disabledUrls, setDisabledUrls] = useState([""]);

  const { t } = useTranslation();

  useEffect(() => {
    if (customizations?.disabledUrls?.length > 0) {
      setDisabledUrls(customizations?.disabledUrls);
      setVisible(true);
    }
  }, [customizations]);

  const handleAddUrl = () => {
    if (disabledUrls?.length === 0) {
      setDisabledUrls([""]);
      return;
    }

    let lastUrl = disabledUrls[disabledUrls?.length - 1];
    if (urlPattern.test(lastUrl)) {
      setDisabledUrls([...disabledUrls, ""]);
      dispatch(
        updateCurrentChatbotCustomizationsAction(bid, "disabledUrls", [
          ...disabledUrls,
          "",
        ])
      );
    } else {
      message.error(t("customize.advanced.validUrlError"));
    }
  };

  const handleUrlChange = (e, index) => {
    const newUrls = [...disabledUrls];
    newUrls[index] = e.target.value;
    setDisabledUrls(newUrls);
    // dispatching changes to Redux state here!
    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "disabledUrls", newUrls)
    );
  };

  const handleRemoveUrl = (index) => {
    const newUrls = [...disabledUrls];
    newUrls.splice(index, 1);
    setDisabledUrls(newUrls);
    // dispatching changes to Redux state here as well
    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "disabledUrls", newUrls)
    );
  };

  // Handle disable sub URLs
  const handleDisableSubUrls = () => {
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "disableSubUrls",
        !customizations?.disableSubUrls
      )
    );
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => {
            setVisible(!visible);
          }}
          className="dropdown-trigger"
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdOutlineDisabledVisible
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="footer-icon"
            />
            {t("customize.advanced.disabledURL")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            alt="arrow"
          />
        </div>

        {visible && (
          <div
            style={{
              padding: "20px 25px",
              backgroundColor: "#d5d7e4",
            }}
          >
            {/* Sub URLs */}
            <Tooltip
              placement="topLeft"
              title={t("customize.advanced.subUrlPH")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                {t("customize.advanced.disableAllSubURLs")}
                <Switch
                  checked={customizations.disableSubUrls}
                  // Disabled if there are no disabled URLs
                  disabled={
                    disabledUrls?.length === 0 ||
                    (disabledUrls?.length === 1 && disabledUrls[0] === "")
                  }
                  onClick={handleDisableSubUrls}
                />
              </div>
            </Tooltip>

            {disabledUrls.map((url, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Input
                  value={url}
                  onChange={(e) => handleUrlChange(e, index)}
                  placeholder="Enter URL. Ex: https://www.google.com"
                  style={{ marginRight: "10px" }}
                />
                <Button
                  type="danger"
                  onClick={() => handleRemoveUrl(index)}
                  style={{
                    fontWeight: "bold",
                    fontSize: "26px",
                  }}
                >
                  -
                </Button>
              </div>
            ))}
            <Button
              type="primary"
              onClick={handleAddUrl}
              style={{ width: "100%", fontWeight: "bold", fontSize: "26px" }}
            >
              +
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default DisabledUrls;
