import { Button, Dropdown, Menu, message } from "antd";
import {
  DeleteOutlined,
  MailOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BotChatsList = ({
  // Chatbot
  selectedBotName,
  selectedBotResponses,
  handleBackToChatbotsList,
  // Chat
  handleSelectChat,
  selectedChat,
  hasChatEnded,
  // Load More
  loadMoreChatsRef,
  showLoadMoreChats,
  loadMoreChatsLoading,
  handleLoadMoreChats,
  // Delete Chat
  handleDeleteChat,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  // Function to check if chat is active and within 1 hour
  const isChatActiveAndRecent = (chat) => {
    // If hasChatEnded is true, return false
    if (hasChatEnded) return false;

    // Convert chatDate to Date object
    const chatDate = new Date(chat.chatDate);
    const now = new Date();
    const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
    return chat.isActive && now - chatDate <= oneHour;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px 10px",
          backgroundColor: "#fff",
        }}
      >
        {/* SVG Back Icon */}
        <svg
          width="1.5em"
          height="1.5em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleBackToChatbotsList}
          style={{ cursor: "pointer", marginRight: "10px" }}
        >
          {/* SVG path */}
          <path
            fill="#000000"
            d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23a1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2Z"
          ></path>
        </svg>

        {/* Chatbot Name (Truncated to 12 characters) */}
        <div
          style={{
            fontWeight: "bold",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "200px", // Adjust as needed
          }}
        >
          {selectedBotName?.length > 25
            ? `${selectedBotName.substring(0, 25)}...`
            : selectedBotName}
        </div>
      </div>

      <div className="chats-list-container">
        {selectedBotResponses?.responses?.length > 0 ? (
          <>
            {selectedBotResponses?.responses?.map((chat, index) => {
              const isActiveAndRecent = isChatActiveAndRecent(chat);

              return (
                <div
                  key={index}
                  className="chats-list"
                  onClick={() => handleSelectChat(chat)}
                  style={{
                    backgroundColor:
                      selectedChat?.chatSessionId === chat?.chatSessionId
                        ? "#0b4eb8"
                        : "#fff",
                    color:
                      selectedChat?.chatSessionId === chat?.chatSessionId
                        ? "#fff"
                        : "#000",
                    transition: "background-color 0.3 ease-out",
                  }}
                  ref={
                    index === selectedBotResponses?.responses?.length - 1
                      ? loadMoreChatsRef
                      : null
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* Wrapper for Initials and Engagement Indicator */}
                    <div
                      className="initials-and-indicator"
                      style={{
                        position: "relative",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      {/* Engagement Indicator */}
                      {isActiveAndRecent && (
                        <div
                          className="engaged-indicator"
                          style={{
                            position: "absolute",
                            top: "-2px", // Adjusted for 12px dot
                            right: "-2px", // Adjusted for 12px dot
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "12px", // Set to 12px
                            height: "12px", // Set to 12px
                            border: "2px solid #fff", // Add border to ensure visibility against button background
                          }}
                        />
                      )}

                      {/* Initials Button */}
                      <Button
                        icon={"V"} // Replace with your logic for initials
                        type="text"
                        size="small"
                        disabled={false}
                        style={{
                          backgroundColor:
                            selectedChat?._id === chat?._id
                              ? "#fff"
                              : "#0b4eb8",
                          color:
                            selectedChat?._id === chat?._id ? "#000" : "#fff",
                          borderRadius: "50%",
                          minWidth: "30px",
                        }}
                      />
                    </div>

                    <div className="chat-text">
                      {t("chatMessages.chatList.visitor")} #{chat?.visitorId}
                      <p
                        style={{
                          padding: "0",
                          margin: "0",
                          wordWrap: "break-word",
                          bottom: "1px",
                          fontSize: "12px",
                        }}
                      >
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "short", // 'short' will give you the abbreviated month name
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }).format(new Date(chat?.chatDate))}
                      </p>
                    </div>
                  </div>

                  {/* Chat Options */}
                  {/* Only admins can delete or send email */}
                  {user?.role === "admin" && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="1"
                            onClick={() => message.info("Coming soon")}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MailOutlined style={{ marginRight: "8px" }} />
                            {t("chatMessages.chatList.mailTranscript")}
                          </Menu.Item>
                          {/* Delete */}
                          <Menu.Item
                            key="2"
                            onClick={() =>
                              handleDeleteChat(chat?.botId, chat?._id)
                            }
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <DeleteOutlined style={{ marginRight: "8px" }} />{" "}
                            {t("chatMessages.chatList.delete")}
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        type="text"
                        icon={<EllipsisOutlined style={{ color: "#fff" }} />}
                        style={{ borderRadius: "4px", border: "none" }}
                        size="large"
                        className="button-no-hover-bg"
                      />
                    </Dropdown>
                  )}
                </div>
              );
            })}

            {/* Load More Button */}
            {showLoadMoreChats &&
              selectedBotResponses?.responses?.length >= 10 &&
              selectedBotResponses.hasMore && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0.5rem",
                    }}
                  >
                    <Button
                      type="text"
                      size="small"
                      className="end-btn"
                      onClick={handleLoadMoreChats}
                      loading={loadMoreChatsLoading}
                      style={{
                        background: "#0b4eb8",
                        color: "#fff",
                        borderRadius: "4px",
                        height: "30px",
                        width: "100%",
                      }}
                    >
                      {t("chatMessages.chatList.loadMore")}
                    </Button>
                  </div>
                </>
              )}
          </>
        ) : (
          <h3
            style={{
              height: "calc(100vh - 220px)",
              marginTop: "1.5rem",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            {t("chatMessages.chatList.noChatGhost")}
          </h3>
        )}
      </div>
    </>
  );
};

export default BotChatsList;
