import { useState, useEffect } from "react";
import { Modal, Button, Form, Input } from "antd";

import { useSelector } from "react-redux";
import { SuccessNotification, ErrorNotification } from "../../..";
import { sendEmbedEmailInstructions } from "../../../../api/user/admin";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: "",
  instructions: "",
};

const EmailInstructionsModal = ({ open, handleModalClick, codeBlock }) => {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const [values, setValues] = useState(initialValues);
  const [processing, setProcessing] = useState(false);
  const [instructions, setInstructions] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const message = `${t("share.hi")}, ${user.name} ${t(
      "share.emailInstructionText"
    )}

      ${codeBlock}
      `;
    setInstructions(message);
  }, [codeBlock]);

  // Handle values change
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle instructions send click
  const handleInstructionsSendClick = async (e) => {
    e.preventDefault();

    const data = {
      email: values.email,
      codeblock: codeBlock,
    };

    // Check if email is valid
    if (values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setProcessing(true);
      // Send embed email instructions
      sendEmbedEmailInstructions(data)
        .then((res) => {
          if (res.status === 200) {
            // Success
            SuccessNotification(res.data.message);
            handleModalClick();
            setProcessing(false);
          }
        })
        .catch((err) => {
          // Error
          ErrorNotification(err.response.data.type[0].message);
          console.log("EMBED_INSTRUCTIONS_ERROR", err);
          setProcessing(false);
        });
    } else {
      ErrorNotification("Please enter a valid email");
    }
  };

  return (
    <>
      <Modal
        title={t("share.emailInstructionToDeveloper")}
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleInstructionsSendClick}
            loading={processing}
          >
            {t("share.send")}
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Email */}
          <Form.Item label={t("share.email")}>
            <Input
              name="email"
              placeholder={t("share.emailPH")}
              onChange={handleChange}
              value={values.email}
              rules={[{ type: "email" }]}
            />
          </Form.Item>

          {/* Instructions */}

          <Form.Item label={t("share.instructions")}>
            <Input.TextArea
              name="instructions"
              placeholder={t("share.instructionsPH")}
              rows={8}
              onChange={handleChange}
              value={instructions}
              disabled
              style={{
                color: "black",
                backgroundColor: "transparent",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EmailInstructionsModal;
