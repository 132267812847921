import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Input, Dropdown, Button, Space, Menu } from "antd";
import { shallow } from "zustand/shallow";
import { SlackNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { DownOutlined } from "@ant-design/icons";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { Slack } from "@assets/images/pages/builder";

const SlackNodeDataEditor: FC<SlackNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [message, setMessage] = useState(state?.data?.questionText);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setMessage(state?.data?.questionText);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  // Handle questionText change
  const handleQuestionTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the welcome message
      setMessage(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // handle dropdown menu click
  const handleMenuClick = (value: string) => {
    setSelectedValue(value);
  };

  const menuProps: React.ReactElement<Menu> = (
    <Menu
      onClick={(e) => handleMenuClick(e.key)}
      style={{ borderRadius: "3px" }}
    >
      <Menu.Item key="Option 1">Option 1</Menu.Item>
      <Menu.Item key="Option 2">Option 2</Menu.Item>
      <Menu.Item key="Option 3">Option 3</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row align="middle">
        <Col span={24}>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            ℹ️ Use this node for Slack.
          </p>
        </Col>

        {/* Add to slack */}
        <Col span={24}>
          <span>Connect your Slack account</span>
        </Col>
        <Col span={9}>
          <div className="add_to_slack">
            <img
              src={Slack}
              style={{ height: "30px", width: "30px", marginRight: "8px" }}
              alt="slack"
            />
            <span style={{ fontWeight: "600" }}>Add to slack</span>
          </div>
        </Col>

        {/* slack dropdown */}
        <Col span={24}>
          <Dropdown overlay={menuProps} placement="bottomLeft">
            <Button
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "3px",
                marginBottom: "25px",
              }}
            >
              <span>{selectedValue || "Select an option"}</span>
              <span>
                <DownOutlined />
              </span>
            </Button>
          </Dropdown>
        </Col>

        {/* Question Text */}
        <Col span={24} className="sidebar__heading">
          Slack Message
        </Col>

        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={message}
              onEditorChange={handleQuestionTextChange}
              placeholderText="Example: New lead onboard! @name * @email"
            />
          </div>
        </Col>

        <SaveAnswer
          id={id}
          type={type}
          variableName={answerVariable ? answerVariable : "url"}
        />
      </Row>
    </>
  );
};

export default SlackNodeDataEditor;
