import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { IoCloseOutline } from "react-icons/io5";
import ChatbotFlowPreview from "@views/admin/chatbot/builder/preview/ChatbotFlowPreview";
import { useSelector } from "react-redux";
// Widget Bubble Icons
import {
  WidgetBubbleIcon1,
  WidgetBubbleIcon2,
  WidgetBubbleIcon3,
  WidgetBubbleIcon4,
  WidgetBubbleIcon5,
  WidgetBubbleIcon6,
  WidgetBubbleIcon7,
  WidgetBubbleIcon8,
  WidgetBubbleIcon9,
  WidgetBubbleIcon10,
  WidgetBubbleIcon11,
  WidgetBubbleIcon12,
  WidgetBubbleIcon13,
  WidgetBubbleIcon14,
  WidgetBubbleIcon15,
} from "@assets/svg";
// Fire Works
import {
  FireWork1,
  FireWork2,
  FireWork3,
  FireWork4,
  FireWork5,
  FireWork6,
  FireWork7,
  FireWork8,
  FireWork9,
  FireWork10,
} from "@assets/images";

const ChatUi = () => {
  const { customizations } = useSelector((state) => state?.chatbot ?? {});

  const [modalVisible, setModalVisible] = useState(true);
  const [modalSize, setModalSize] = useState("mobile");

  useEffect(() => {
    if (customizations?.sidebarActiveOption === "announcement-banner") {
      setModalVisible(false);
    } else {
      setModalVisible(true);
    }
  }, [customizations]);

  // Handle close modal
  const closeModal = () => {
    setModalVisible(false);
  };

  // Handle set mobile / desktop preview
  const toggleModalSize = (value) => {
    setModalSize(value);
  };

  // widget Bubble Icons
  const widgetIcons = [
    { name: "WidgetBubbleIcon1", icon: <WidgetBubbleIcon1 /> },
    { name: "WidgetBubbleIcon2", icon: <WidgetBubbleIcon2 /> },
    { name: "WidgetBubbleIcon3", icon: <WidgetBubbleIcon3 /> },
    { name: "WidgetBubbleIcon4", icon: <WidgetBubbleIcon4 /> },
    { name: "WidgetBubbleIcon5", icon: <WidgetBubbleIcon5 /> },
    { name: "WidgetBubbleIcon6", icon: <WidgetBubbleIcon6 /> },
    { name: "WidgetBubbleIcon7", icon: <WidgetBubbleIcon7 /> },
    { name: "WidgetBubbleIcon8", icon: <WidgetBubbleIcon8 /> },
    { name: "WidgetBubbleIcon9", icon: <WidgetBubbleIcon9 /> },
    { name: "WidgetBubbleIcon10", icon: <WidgetBubbleIcon10 /> },
    { name: "WidgetBubbleIcon11", icon: <WidgetBubbleIcon11 /> },
    { name: "WidgetBubbleIcon12", icon: <WidgetBubbleIcon12 /> },
    { name: "WidgetBubbleIcon13", icon: <WidgetBubbleIcon13 /> },
    { name: "WidgetBubbleIcon14", icon: <WidgetBubbleIcon14 /> },
    { name: "WidgetBubbleIcon15", icon: <WidgetBubbleIcon15 /> },
  ];

  // Banner Icons
  const bannerIcons = [
    { name: "FireWork1", icon: FireWork1 },
    { name: "FireWork2", icon: FireWork2 },
    { name: "FireWork3", icon: FireWork3 },
    { name: "FireWork4", icon: FireWork4 },
    { name: "FireWork5", icon: FireWork5 },
    { name: "FireWork6", icon: FireWork6 },
    { name: "FireWork7", icon: FireWork7 },
    { name: "FireWork8", icon: FireWork8 },
    { name: "FireWork9", icon: FireWork9 },
    { name: "FireWork10", icon: FireWork10 },
  ];

  return (
    <>
      {/* Preview Modal */}
      <div className="preview-modal-wrapper">
        <div className="preview-header">
          <div className="browser-controls">
            <div className="browser-control red"></div>
            <div className="browser-control yellow"></div>
            <div className="browser-control green"></div>
          </div>
          <div className="url-bar">
            <span className="url-bar-icon">🔒</span>
            <span className="url-text">https://www.conferbot.com</span>
          </div>
        </div>

        {customizations?.widgetPosition && (
          <div
            className={`chat-preview-${customizations?.widgetPosition} ${
              modalVisible ? "show__chat" : "hide__chat"
            }`}
            style={{
              position: "absolute",
              left:
                customizations?.widgetPosition === "left"
                  ? `${customizations?.widgetOffsetLeft + 10}px`
                  : "",
              right:
                customizations?.widgetPosition === "right"
                  ? `${customizations?.widgetOffsetRight + 10}px`
                  : "",
              bottom: `${customizations?.widgetOffsetBottom + 65}px`,
              zIndex: 999999999999999,
            }}
          >
            <Draggable disabled={true}>
              <div
                style={{
                  height: "72vh",
                  width: "25vw",
                  overflow: "hidden",
                }}
              >
                {/* Modal body */}
                <div>
                  <ChatbotFlowPreview
                    isCustomize={true}
                    closeModal={closeModal}
                    toggleModalSize={toggleModalSize}
                  />
                </div>
              </div>
            </Draggable>
          </div>
        )}

        {/* Widget icon */}
        <div
          className="bot-widget-icon"
          onClick={() => setModalVisible(!modalVisible)}
          style={{
            position: "absolute",
            height: `${customizations?.widgetSize}px`,
            width: `${customizations?.widgetSize}px`,
            left:
              customizations?.widgetPosition === "left"
                ? `${customizations?.widgetOffsetLeft + 10}px`
                : "",
            right:
              customizations?.widgetPosition === "right"
                ? `${customizations?.widgetOffsetRight + 10}px`
                : "",
            bottom: customizations?.widgetOffsetBottom,
            background:
              (customizations?.widgetIconThemeType === "Solid" &&
                customizations?.widgetIconBgColor) ||
              (customizations?.widgetIconThemeType === "Gradient" &&
                `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
            transition: " transform 0.3s ease , background 0.3s ease-in-out",
            borderRadius:
              customizations?.widgetBorderRadius &&
              `${customizations?.widgetBorderRadius}px`,
            zIndex: 999999999,
          }}
        >
          <div
            className={`bot-icon-wrapper ${
              modalVisible ? "bot-show-close" : "bot-show-robot"
            }`}
          >
            {/* Close Icon */}
            <IoCloseOutline
              className="bot-icon bot-close-icon"
              style={{ color: "#fff" }}
              size={35}
            />

            {/* Bot Avatar Icon */}
            <div className="bot-icon bot-avatar-icon">
              {customizations?.widgetIconType === "Image" ? (
                <>
                  <img
                    src={customizations?.widgetIconImage}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    }}
                    alt="widget-icon"
                  />
                </>
              ) : (
                <div
                  className="icon-center"
                  style={{
                    height: `35px`,
                    width: `35px`,
                  }}
                >
                  {customizations?.widgetIconSVG ? (
                    widgetIcons.map((icon) => {
                      if (icon.name === customizations?.widgetIconSVG) {
                        return icon.icon;
                      }
                      return null;
                    })
                  ) : (
                    <svg
                      className="open-svg"
                      id="open-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.5 18C21.5 18 20.5 18.5 20.5 20.1453V21.2858V22.5287V23.3572C20.5 24.131 20.0184 24.1046 19.3517 23.7118L18.75 23.3572L13.5 20C12.8174 19.6587 12.6007 19.5504 12.3729 19.516C12.267 19.5 12.1587 19.5 12 19.5H7.5C2.5 19.5 0 17.5 0 12.5V7.5C0 2.5 2.5 0 7.5 0H16.5C21.5 0 24 2.5 24 7.5V12.5C24 17.5 21.5 18 21.5 18ZM21 17.557C21.8581 17.557 24 13.557 23 13.057C22.3869 12.7505 21.8801 13.7414 21.4646 14.554C21.2023 15.0668 20.9764 15.5086 20.783 15.5086C20.283 15.5086 20 16.0554 20 16.7568C20 17.4582 20.1419 17.557 21 17.557Z"
                        fill="white"
                        opacity="1"
                      ></path>
                    </svg>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Widget CTA */}
        {customizations?.chatIconCta && (
          <div
            className="chatbot-widget-cta"
            style={{
              position: "absolute",
              left:
                customizations?.widgetPosition === "left"
                  ? `${customizations?.widgetOffsetLeft + 75}px`
                  : "",
              right:
                customizations?.widgetPosition === "right"
                  ? `${customizations?.widgetOffsetRight + 75}px`
                  : "",
              bottom: customizations?.widgetOffsetBottom,
              background:
                (customizations?.widgetIconThemeType === "Solid" &&
                  customizations?.widgetIconBgColor) ||
                (customizations?.widgetIconThemeType === "Gradient" &&
                  `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
              display: modalVisible ? "none" : "",
            }}
          >
            <p style={{ margin: "0px", padding: "3px 0px" }}>
              {customizations?.chatIconCtaText}
            </p>
          </div>
        )}

        {/* Announcement Banner */}
        {customizations?.widgetPosition && (
          <div
            className={`announcement_banner-container ${
              customizations?.enableAnnouncementBanner
                ? "show-banner"
                : "hide-banner"
            }`}
            style={{
              position: "absolute",
              left:
                customizations?.widgetPosition === "left"
                  ? `${customizations?.widgetOffsetLeft + 10}px`
                  : "",
              right:
                customizations?.widgetPosition === "right"
                  ? `${customizations?.widgetOffsetRight + 10}px`
                  : "",
              bottom: `${customizations?.widgetOffsetBottom + 75}px`,
              zIndex: 9999,
              display: modalVisible ? "none" : "",
            }}
          >
            <div className="announcement_banner">
              <div
                className="header"
                style={{
                  background:
                    (customizations?.widgetIconThemeType === "Solid" &&
                      customizations?.widgetIconBgColor) ||
                    (customizations?.widgetIconThemeType === "Gradient" &&
                      `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
                }}
              >
                <div style={{ height: "120px", width: "120px" }}>
                  {customizations?.bannerIconType === "Image" ? (
                    <>
                      <img
                        src={FireWork3}
                        style={{ height: "100%", width: "100%" }}
                        alt="icon"
                      />
                    </>
                  ) : (
                    <div
                      className="icon-center"
                      style={{ height: "100%", width: "100%" }}
                    >
                      {customizations?.bannerIconSVG
                        ? bannerIcons.map((icon) => {
                            if (icon.name === customizations?.bannerIconSVG) {
                              return (
                                <img
                                  src={icon.icon}
                                  style={{ height: "100%", width: "100%" }}
                                  alt="icon"
                                />
                              );
                            }
                            return null;
                          })
                        : ""}
                    </div>
                  )}
                </div>
              </div>

              <div className="content">
                <h2>{customizations?.bannerTitle}</h2>
                <p>{customizations?.bannerDescription}</p>
                <div className="buttons">
                  <a
                    href={customizations?.bannerButtonOneLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className="signup"
                      style={{
                        background:
                          (customizations?.widgetIconThemeType === "Solid" &&
                            customizations?.widgetIconBgColor) ||
                          (customizations?.widgetIconThemeType === "Gradient" &&
                            `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
                      }}
                    >
                      {customizations?.bannerButtonOneTitle}
                    </button>
                  </a>
                  <a
                    href={customizations?.bannerButtonTwoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="demo">
                      {customizations?.bannerButtonTwoTitle}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatUi;
