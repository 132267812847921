import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface HeaderNavProps {
  user: {
    workspaceId: string;
    _id: string;
    role: string;
  };
  bid: string;
  isBuilder: boolean;
  isCustomize: boolean;
  isShare: boolean;
  isAnalytics: boolean;
}

const HeaderNav: React.FC<HeaderNavProps> = ({
  user,
  bid,
  isBuilder,
  isCustomize,
  isShare,
  isAnalytics,
}) => {
  const { t } = useTranslation();

  const basePath =
    user?.role === "superadmin"
      ? "/super-admin"
      : user?.role === "marketing"
      ? "/marketing-admin"
      : `/a/ws/${user?.workspaceId}/u/${user._id.slice(-5)}`;

  return (
    <>
      {/* Builder */}
      <Link to={`${basePath}/chatbot/${bid}/builder`}>
        <button
          className="header__Btn"
          style={{
            backgroundColor: isBuilder ? "#0100EB" : "",
            color: isBuilder ? "white" : "#353232",
          }}
        >
          {t("flowBuilder.header.builder")}
        </button>
      </Link>

      <div style={{ fontSize: "10px", margin: "0px 3px" }}>&gt;</div>

      {/* Customize */}
      <Link to={`${basePath}/chatbot/${bid}/customize`}>
        <button
          id="bot-customize"
          className="header__Btn"
          style={{
            backgroundColor: isCustomize ? "#0100EB" : "",
            color: isCustomize ? "white" : "#353232",
          }}
        >
          {t("flowBuilder.header.customize")}
        </button>
      </Link>

      <div style={{ fontSize: "10px", margin: "0px 3px" }}>&gt;</div>

      {/* Embed */}
      <Link to={`${basePath}/chatbot/${bid}/embed`}>
        <button
          id="bot-share"
          className="header__Btn"
          style={{
            backgroundColor: isShare ? "#0100EB" : "",
            color: isShare ? "white" : "#353232",
          }}
        >
          {t("flowBuilder.header.share")}
        </button>
      </Link>

      {/* Analytics */}
      {user?.role !== "superadmin" && user?.role !== "marketing" && (
        <>
          <div style={{ fontSize: "10px", margin: "0px 3px" }}>&gt;</div>

          {/* Analytics */}
          <Link
            to={`/a/ws/${user?.workspaceId}/u/${user._id.slice(
              -5
            )}/chatbot/${bid}/analytics`}
          >
            <button
              id="bot-analytics"
              className="header__Btn"
              style={{
                backgroundColor: isAnalytics ? "#0100EB" : "",
                color: isAnalytics ? "white" : "#353232",
              }}
            >
              {t("flowBuilder.header.analytics")}
            </button>
          </Link>
        </>
      )}
    </>
  );
};

export default HeaderNav;
