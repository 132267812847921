import React, { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Input, Select, Tooltip, Switch } from "antd";
import { shallow } from "zustand/shallow";
import { ConditionNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { extractAnswerVariables } from "../../../../../helpers/extractAnswerVariables";
import { useTranslation } from "react-i18next";

const ConditionNodeDataEditor: FC<ConditionNode> = ({ type, id }) => {
  const { getNode, changeNodeData, nodes } = useStore(selector, shallow);

  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);
  const { t } = useTranslation();

  // States for the component
  const [isNumber, setIsNumber] = useState(true);
  const [leftValue, setLeftValue] = useState(state?.data?.leftValue);
  const [operator, setOperator] = useState(state?.data?.operator || "=");
  const [rightValue, setRightValue] = useState(state?.data?.rightValue);

  const [allAnswerVariables, setAllAnswerVariables] = useState([]);

  // State to manage whether to use dropdown or custom input
  const [useCustomLeftValue, setUseCustomLeftValue] = useState(
    state?.data?.customLeftValue
  );
  const [useCustomRightValue, setUseCustomRightValue] = useState(
    state?.data?.customRightValue
  );

  // Each time state?.data? changes, update the state
  useEffect(() => {
    setIsNumber(state?.data?.isNumber);
    setLeftValue(state?.data?.leftValue);
    setOperator(state?.data?.operator);
    setRightValue(state?.data?.rightValue);

    setUseCustomLeftValue(state?.data?.customLeftValue);
    setUseCustomRightValue(state?.data?.customRightValue);

    // Get all answer variables from nodes
    const answerVariables = extractAnswerVariables(nodes);

    // Set the answer variables
    setAllAnswerVariables(answerVariables);
  }, [id]);

  // Handle set is number
  const handleSetIsNumber = useCallback(
    (value: any) => {
      // Change node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, isNumber: value },
      });

      setIsNumber(value);
    },
    [state, type, changeNodeData]
  );

  // Handle set custom left value
  const handleSetCustomLeftValue = useCallback(
    (value: any) => {
      // Change node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, customLeftValue: value },
      });

      setUseCustomLeftValue(value);
    },
    [state, type, changeNodeData]
  );

  // Handle left value change
  const handleLeftValueChange = useCallback(
    (e: any) => {
      if (!state) return;

      // If useCustomLeftValue is false, then we are using a variable
      if (!useCustomLeftValue) {
        // Change node data
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, leftValue: e },
        });

        setLeftValue(e);
      } else {
        // Change node data
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, leftValue: e.target.value },
        });
        setLeftValue(e.target.value);
      }
    },
    [state, type, changeNodeData]
  );

  // Handle operator change
  const handleOperatorChange = useCallback(
    (value: any) => {
      setOperator(value);

      // Change node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, operator: value },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle set custom right value
  const handleSetCustomRightValue = useCallback(
    (value: any) => {
      // Change node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, customRightValue: value },
      });

      setUseCustomRightValue(value);
    },
    [state, type, changeNodeData]
  );

  // Handle operator value change
  const handleRightValueChange = useCallback(
    (e: any) => {
      if (!state) return;

      // If useCustomRightValue is false, then we are using a variable
      if (!useCustomRightValue) {
        // Change node data
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, rightValue: e },
        });

        setRightValue(e);
      } else {
        // Change node data
        changeNodeData({
          ...state,
          type,
          data: { ...state?.data, rightValue: e.target.value },
        });
        setRightValue(e.target.value);
      }
    },
    [state, type, changeNodeData]
  );

  // Rendering function for value input with updated styles
  const renderValueInput = (
    value: any,
    onChangeHandler: any,
    isCustom: any,
    tooltipTitle: any
  ) => (
    <Tooltip placement="top" title={tooltipTitle}>
      <Row gutter={8} align="middle">
        {isCustom ? (
          <Col span={24}>
            <Input
              size="small"
              type="text"
              value={value}
              onChange={onChangeHandler}
              style={{ width: "100%" }}
            />
          </Col>
        ) : (
          <Col span={24}>
            <Select
              size="small"
              showSearch
              style={{ width: "100%" }}
              value={value}
              onChange={(value: any) => onChangeHandler(value)}
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allAnswerVariables?.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>
    </Tooltip>
  );

  // Operator options based on the selected type
  const numberOperators = ["=", "!=", ">", "<", ">=", "<="];

  const stringOperators = [
    "contains",
    "does not contain",
    "starts with",
    "ends with",
    "matches",
    "does not match",
  ];

  return (
    <>
      <Row align="middle" gutter={[0, 16]} style={{ padding: "0 16px" }}>
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.logicalNodes.conditionNodeInfo")}
          </p>
        </Row>

        {/* Type Selection Switch */}
        <Col span={24}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ marginRight: "10px" }}>
              <b>Evaluate as:</b>
            </label>
            <Switch
              size="small"
              checked={isNumber}
              onChange={(value) => handleSetIsNumber(value)}
            />
            <span style={{ marginLeft: "10px" }}>
              {isNumber ? "Number" : "String"}
            </span>
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "2px",
            width: "100%",
          }}
        />

        {/* Left Value Switch and Input */}
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <label style={{ marginRight: "10px" }}>
              <b>{t("flowBuilder.sidebar.editor.logicalNodes.leftValue")}</b>
            </label>
            <Switch
              size="small"
              checked={useCustomLeftValue}
              onChange={(value) => handleSetCustomLeftValue(value)}
            />
            <span style={{ marginLeft: "10px" }}>
              {useCustomLeftValue ? "Type Value" : "Answer Variable"}
            </span>
          </div>
          {renderValueInput(
            leftValue,
            handleLeftValueChange,
            useCustomLeftValue,
            t("flowBuilder.sidebar.editor.logicalNodes.selectLeftValue")
          )}
        </Col>

        {/* Operator Selection */}
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <label style={{ marginRight: "10px" }} className="sidebar__heading">
              {t("flowBuilder.sidebar.editor.logicalNodes.operator")}
            </label>
            <Select
              style={{ flex: 1 }}
              onChange={(value) => handleOperatorChange(value)}
              value={operator}
            >
              {(isNumber ? numberOperators : stringOperators).map((op) => (
                <Select.Option key={op} value={op}>
                  {op.toUpperCase().replace(/_/g, " ")}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>

        {/* Right Value Switch and Input */}
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <label style={{ marginRight: "10px" }}>
              <b>{t("flowBuilder.sidebar.editor.logicalNodes.rightValue")}</b>
            </label>
            <Switch
              size="small"
              checked={useCustomRightValue}
              onChange={(value) => handleSetCustomRightValue(value)}
            />
            <span style={{ marginLeft: "10px" }}>
              {useCustomRightValue ? "Type Value" : "Answer Variable"}
            </span>
          </div>
          {renderValueInput(
            rightValue,
            handleRightValueChange,
            useCustomRightValue,
            t("flowBuilder.sidebar.editor.logicalNodes.selectRightValue")
          )}
        </Col>
      </Row>
    </>
  );
};

export default ConditionNodeDataEditor;
