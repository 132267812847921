import React from "react";
import { Col, Card, Rate } from "antd";

const OnboardTestimonial = () => {
  const testimonialData = [
    {
      review:
        "Conferbot's survey chatbot helped us gather customer insights effortlessly. It's user-friendly and highly efficient.",
      stars: 5,
      name: "Mia Thompson",
      title: "Senior Market Analyst at TechWave",
      link: "https://conferbot.com",
    },
    {
      review:
        "The lead qualification process with Conferbot is remarkable. It has significantly increased our conversion rates.",
      stars: 5,
      name: "Jacob Lee",
      title: "Sales Manager at GreenLeaf Enterprises",
      link: "https://conferbot.com",
    },
    {
      review:
        "Our customer engagement has improved dramatically since we started using Conferbot. It's an excellent tool for real-time interaction.",
      stars: 5,
      name: "Sara Alvarado",
      title: "Customer Relations Head at BrightSolutions",
      link: "https://conferbot.com",
    },
    {
      review:
        "Conferbot's adaptability across our digital platforms has been a game-changer for our online marketing strategy.",
      stars: 5,
      name: "Omar Farooq",
      title: "Digital Strategist at CreativEdge",
      link: "https://conferbot.com",
    },
    {
      review:
        "Integrating Conferbot for customer support on our site was seamless. It's incredibly responsive and user-friendly.",
      stars: 5,
      name: "Lily Peterson",
      title: "IT Manager at Streamline Tech",
      link: "https://conferbot.com",
    },
    {
      review:
        "Conferbot has been a vital tool in enhancing our customer service and sales. The AI-driven responses are impressively accurate.",
      stars: 5,
      name: "Daniel Kim",
      title: "Head of E-commerce at ShopRight",
      link: "https://conferbot.com",
    },
    {
      review:
        "The efficiency of Conferbot in handling customer queries has saved us countless hours and improved overall satisfaction.",
      stars: 5,
      name: "Fatima Zahra",
      title: "Operations Manager at QuickServe",
      link: "https://conferbot.com",
    },
    {
      review:
        "Adopting Conferbot for our website transformed the way we interact with visitors. It's intuitive and aligns perfectly with our needs.",
      stars: 5,
      name: "Ethan Wright",
      title: "Marketing Director at BlueOcean Media",
      link: "https://conferbot.com",
    },
  ];

  return (
    <>
      <h2 className="subscribe-heading">
        Still not sure? Hear from those who took the Leap!
      </h2>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
          marginTop: "32px",
        }}
      >
        {testimonialData.map((testimonial, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              style={{
                width: "90%",
                minHeight: "100%",
                textAlign: "center",
                margin: "0 auto",
                backgroundColor: "#fff",
                border: "1px solid #E8E8E8",
                borderRadius: "8px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
                cursor: "pointer",
              }}
              onClick={() => window.open(testimonial.link, "_blank")}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "16px",
                  fontStyle: "italic",
                  minHeight: "100px",
                }}
              >
                "{testimonial.review}"
              </p>
              <Rate value={testimonial.stars} disabled />
              <h4
                style={{
                  marginTop: "16px",
                  color: "#0010F7",
                }}
              >
                {testimonial.name}
              </h4>
              <p
                style={{
                  color: "#0010F7",
                }}
              >
                {testimonial.title}
              </p>
            </Card>
          </Col>
        ))}
      </div>
    </>
  );
};

export default OnboardTestimonial;
