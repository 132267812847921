import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Tooltip, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { AskEmailNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const AskEmailNodeDataEditor: FC<AskEmailNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [questionText, setQuestionText] = useState(state?.data?.questionText);
  const [incorrectEmailResponse, setIncorrectEmailResponse] = useState(
    state?.data?.incorrectEmailResponse
  );
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const { t } = useTranslation();
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setQuestionText(state?.data?.questionText);
    setIncorrectEmailResponse(state?.data?.incorrectEmailResponse);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  // Handle questionText change
  const handleQuestionTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the welcome message
      setQuestionText(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle handleIncorrectEmailResponseChange change
  const handleIncorrectEmailResponseChange = useCallback(
    (content: any) => {
      // Set the setIncorrectEmailResponse
      setIncorrectEmailResponse(content);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          incorrectEmailResponse: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.askQuestionNodes.askEmailNodeInfo")}
          </p>
        </Row>

        {/* Question Text */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.askQuestionNodes.questionText")}
          </Col>
        </Divider>

        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={questionText}
              onEditorChange={handleQuestionTextChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.askQuestionNodes.questionText"
              )}
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.askQuestionNodes.incorrectEmail")}
          </Col>
        </Divider>

        <Col span={24}>
          <Tooltip
            placement="top"
            title={t(
              "flowBuilder.sidebar.editor.askQuestionNodes.incorrectEmailTT"
            )}
          >
            <div>
              <QuillEditor
                initialContent={incorrectEmailResponse}
                onEditorChange={handleIncorrectEmailResponseChange}
                placeholderText={t(
                  "flowBuilder.sidebar.editor.askQuestionNodes.incorrectEmailPH"
                )}
              />
            </div>
          </Tooltip>
        </Col>

        <SaveAnswer id={id} type={type} variableName={answerVariable} />
      </Row>
    </>
  );
};

export default AskEmailNodeDataEditor;
