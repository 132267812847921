import { useCallback, useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
// Components
import { WarningNotification } from "@components";
// Chat Components
import { ChatLeft, ChatRight } from "./components";
// Redux Actions
import {
  // Responses
  getSelectedChatbotResponsesAction,
  updateSelectedChatbotResponsesAction,
  updateBotWithLatestMessageAction,
  // Response Messages
  updateSelectedChatWithLatestResponseMessagesAction,
} from "@redux/actions/adminActions";
import {
  // Responses
  getAgentSelectedChatbotResponsesAction,
  updateAgentSelectedChatbotResponsesAction,
  updateAgentBotWithLatestMessageAction,
  // Response Messages
  updateAgentSelectedChatWithLatestResponseMessagesAction,
} from "@redux/actions/memberActions";
// WebSocket Context
import { useWebSocket } from "@context/WebSocketContext";
// Assets
import SelectChatImage from "@assets/images/pages/chats/selectChat.svg";
// CSS
import "@assets/css/ChatHistory.css";
import "@assets/css/ChatUi.css";
import "@assets/css/ChatTickets.css";
import { useTranslation } from "react-i18next";

const Chats = () => {
  const { t } = useTranslation();
  const { socket } = useWebSocket();
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // ** URL params
  const { bid, cid } = useParams();

  // ** Loaders
  const [loading, setLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);

  // ** Chatbot
  const [selectedBotId, setSelectedBotId] = useState("");
  const [isBotSelected, setIsBotSelected] = useState(false);
  const [selectedBotName, setSelectedBotName] = useState("");
  const [selectedChat, setSelectedChat] = useState({ record: [] });

  // ** Chats Pagination
  const [chatsPage, setChatsPage] = useState(1);
  const [chatsPageSize, setChatsPageSize] = useState(20);

  // ** Input
  // Is Chat Enabled
  const [isChatEnabled, setIsChatEnabled] = useState(false);
  // Is Leave Chat Enabled
  const [isLeaveChatEnabled, setIsLeaveChatEnabled] = useState(false);
  // Has Chat ended
  const [hasChatEnded, setHasChatEnded] = useState(false);
  // Show Accept Handover button
  const [showAcceptHandoverButton, setShowAcceptHandoverButton] =
    useState(false);
  // Accept handover button loading
  const [acceptHandoverButtonLoading, setAcceptHandoverButtonLoading] =
    useState(false);

  // ** Visitor Input
  // Visitor Input
  const [isVisitorInputEnabled, setIsVisitorInputEnabled] = useState(true);

  // ** Handover data
  // Initial load
  const [initialLoad, setInitialLoad] = useState(true);
  // Handover data
  const [handoverData, setHandoverData] = useState(null);

  // Function to execute upon connecting or re-connecting
  const handleRejoinConnections = useCallback(() => {
    // If there's a selectedChat, join its room
    if (selectedChat) {
      // Join room of the new chat
      socket.current.emit(
        "join-chat-room-agent",
        { chatSessionId: selectedChat.chatSessionId, userId: user._id },
        (ack) => {
          if (ack) {
            setIsChatEnabled(ack.canJoin);
            setIsLeaveChatEnabled(ack.canLeave);
          } else {
            setIsChatEnabled(false);
            setIsLeaveChatEnabled(false);
          }
        }
      );
    }
  }, [selectedChat, socket, user]);

  // ** RELOAD PAGE FETCH ** //
  // If bid or cid is present, retain the selected bot or chat
  useEffect(() => {
    const asyncEffect = async () => {
      if (bid && !cid) {
        setLoading(true);
        setIsBotSelected(true);
        setSelectedBotId(bid);

        // Await the completion of getSelectedChatbotResponsesAction
        // If admin
        if (user?.role === "admin") {
          await dispatch(
            getSelectedChatbotResponsesAction(
              bid,
              1,
              20,
              setSelectedBotName,
              setLoading
            )
          );
        } else if (user?.role === "agent") {
          await dispatch(
            getAgentSelectedChatbotResponsesAction(
              bid,
              1,
              20,
              setSelectedBotName,
              setLoading
            )
          );
        }
      } else if (bid && cid) {
        setLoading(true);
        setIsBotSelected(true);
        setSelectedBotId(bid);

        // Await the completion of getSelectedChatbotResponsesAction
        if (user?.role === "admin") {
          await dispatch(
            getSelectedChatbotResponsesAction(
              bid,
              1,
              20,
              setSelectedBotName,
              setLoading
            )
          );
        } else if (user?.role === "agent") {
          await dispatch(
            getAgentSelectedChatbotResponsesAction(
              bid,
              1,
              20,
              setSelectedBotName,
              setLoading
            )
          );
        }

        if (selectedChat?._id === cid) return;

        // Set message loading
        setMessageLoading(true);

        // Dispatch based on user role after awaiting the chatbot responses
        if (user?.role === "admin") {
          dispatch(
            updateSelectedChatWithLatestResponseMessagesAction(
              cid,
              setSelectedChat,
              setMessageLoading
            )
          );
        } else if (user?.role === "agent") {
          dispatch(
            updateAgentSelectedChatWithLatestResponseMessagesAction(
              cid,
              setSelectedChat,
              setMessageLoading
            )
          );
        }
      }
    };

    asyncEffect();
  }, []);

  // ** RELOAD JOIN CHAT ROOM ** //
  useEffect(() => {
    // Ensure selectedChat is not null and has the necessary chatSessionId
    if (selectedChat && selectedChat?.chatSessionId && !hasChatEnded) {
      // Perform the socket join operation here
      socket.current.emit(
        "join-chat-room-agent",
        { chatSessionId: selectedChat.chatSessionId, userId: user._id },
        (ack) => {
          if (ack) {
            setIsChatEnabled(ack.canJoin);
            setIsLeaveChatEnabled(ack.canLeave);
          } else {
            setIsChatEnabled(false);
            setIsLeaveChatEnabled(false);
          }
        }
      );

      // Socket message to check if the accept handover button has to be shown
      socket.current.emit(
        "show-accept-handover-button",
        { chatSessionId: selectedChat.chatSessionId, userId: user._id },
        (ack) => {
          if (ack) {
            setShowAcceptHandoverButton(ack.showAcceptHandoverButton);
          } else {
            setShowAcceptHandoverButton(false);
          }
        }
      );
    }
  }, [selectedChat]);

  // *** SOCKET CONNECTION *** //
  // Connect to socket server
  useEffect(() => {
    // Assign socket.current to a local variable
    const currentSocket = socket.current;

    // Check if the socket is connected
    if (currentSocket) {
      // Listen to 'connect' event for initial connection and re-connection
      currentSocket.on("connect", handleRejoinConnections);

      // Cleanup function to unregister event listeners
      return () => {
        currentSocket.off("connect", handleRejoinConnections);
      };
    }
  }, [handleRejoinConnections, socket]);

  // *** SOCKET EVENTS *** //
  // Listen to socket events from server
  useEffect(() => {
    // Capture the current socket at the time of effect execution
    const currentSocket = socket.current;

    // ** Listen to 'bot-response' event from server
    currentSocket.on("bot-response", botResponseHandler);

    // ** Listen to 'visitor-disconnected' event from server
    currentSocket.on("visitor-disconnected", handleVisitorDisconnected);

    // ** Listen to 'visitor-reconnected' event from server
    currentSocket.on("visitor-reconnected", handleVisitorReconnected);

    // ** Listen to 'multiple-agents-present' event from server
    currentSocket.on("multiple-agents-present", handleMultipleAgentsPresent);

    // ** Listen to 'single-agent-present' event from server
    currentSocket.on("single-agent-present", (data) => {
      // If data.chatSessionId is equal to selectedChat.chatSessionId
      if (data.chatSessionId === selectedChat.chatSessionId) {
        // If data.totalAgents is equal to 1, set isLeaveChatEnabled to false
        if (data.totalAgents === 1) {
          setIsLeaveChatEnabled(false);
        }
      }
    });

    // ** Listen for 'visitor-input-toggled' event from server
    currentSocket.on("visitor-input-toggled", (data) => {
      // If the data.chatSessionId is equal to selectedChat.chatSessionId
      if (data.chatSessionId === selectedChat.chatSessionId) {
        setIsVisitorInputEnabled(data.isInputEnabled);
      }
    });

    // ** Listen to 'agent-invitation-declined' event from server
    currentSocket.on(
      "agent-invitation-declined",
      handleAgentInvitationDeclined
    );

    // ** Listen to 'agent-can-leave' event from server
    currentSocket.on("agent-can-leave", (data) => {
      setIsLeaveChatEnabled(data.canLeave);
    });

    // ** Listen to 'agent-left' event from server
    currentSocket.on("agent-left", (data) => {
      // If data.agentDetails._id is equal to user._id
      if (data.agentDetails._id === user._id) {
        // disable the chat
        setHasChatEnded(true);
        setIsChatEnabled(false);
        setIsLeaveChatEnabled(false);
      }
    });

    // ** Listen to 'handover-ended' event from server
    currentSocket.on("handover-ended", (data) => {
      // If data.agentDetails._id is equal to user._id
      if (data.agentDetails._id === user._id) {
        setIsChatEnabled(false);
        setIsLeaveChatEnabled(false);
      }
    });

    // ** Listen to 'chat-ended' event from server
    currentSocket.on("chat-ended", (data) => {
      // If data.chatSessionId is equal to selectedChat.chatSessionId, disable the chat
      if (data.chatSessionId === selectedChat.chatSessionId) {
        setHasChatEnded(true);
        setIsChatEnabled(false);
        setIsLeaveChatEnabled(false);
      }
    });

    // ** Cleanup function using the captured value
    return () => {
      // Unregister event listeners
      currentSocket.off("bot-response", botResponseHandler);
      currentSocket.off("visitor-disconnected", handleVisitorDisconnected);
      currentSocket.off("visitor-reconnected", handleVisitorReconnected);
      currentSocket.off("multiple-agents-present", handleMultipleAgentsPresent);
      currentSocket.off("single-agent-present");
      currentSocket.off("visitor-input-toggled");
      currentSocket.off(
        "agent-invitation-declined",
        handleAgentInvitationDeclined
      );
      currentSocket.off("agent-can-leave");
      currentSocket.off("agent-left");
      currentSocket.off("handover-ended");
      currentSocket.off("chat-ended");
    };
  }, [socket, selectedChat]);

  // *** HANDOVER DATA (If not on the chats page) *** //
  // Handover data
  useEffect(() => {
    if (initialLoad) {
      // Handover data
      const storedHandoverData = sessionStorage.getItem("handoverData");
      // Invitation data
      const storedInvitationData = sessionStorage.getItem("invitationData");
      // Notification data
      const storedNotificationData = sessionStorage.getItem("notificationData");

      if (storedHandoverData) {
        const data = JSON.parse(storedHandoverData);
        const { botId } = data.chatMetaData;

        if (botId) {
          // Select the chatbot that the handover is from
          handleSelectChatbot(botId);

          // Set the handover data (Current chat)
          setHandoverData(data);
        }
        sessionStorage.removeItem("handoverData"); // Clean up
      } else if (storedInvitationData) {
        const data = JSON.parse(storedInvitationData);
        const { botId } = data.chatMetaData;

        if (botId) {
          // Select the chatbot that the invitation is from
          handleSelectChatbot(botId);

          // Set the handover data (Current chat)
          setHandoverData(data);
        }
        sessionStorage.removeItem("invitationData"); // Clean up
      } else if (storedNotificationData) {
        const data = JSON.parse(storedNotificationData);
        const { botId } = data.chatMetaData;

        if (botId) {
          // Select the chatbot that the notification is from
          handleSelectChatbot(botId);

          // Set the handover data (Current chat)
          setHandoverData(data);
        }
        sessionStorage.removeItem("notificationData"); // Clean up
      }

      setInitialLoad(false);
    }
  }, [initialLoad]);

  // *** HANDOVER ACCEPT *** //
  // Call acceptHandover after chats are loaded
  useEffect(() => {
    if (handoverData && !loading) {
      // Ensure chats are loaded
      acceptHandover(handoverData);

      setHandoverData(null); // Reset handover data
    }
  }, [handoverData, loading]);

  // *** HANDOVER ACCEPT (If already on chats page) *** //
  // Listen to the Handle Handover event
  useEffect(() => {
    const handleHandoverEvent = (e) => {
      const data = e.detail;
      const { botId } = data.chatMetaData;

      // Call the select chatbot function
      handleSelectChatbot(botId);

      // Select the chat
      handleSelectChat(data.chatMetaData);

      // Call the accept handover function
      acceptHandover(data);
    };

    window.addEventListener("handleHandover", handleHandoverEvent);

    return () => {
      window.removeEventListener("handleHandover", handleHandoverEvent);
    };
  }, []);

  // *** INVITATION ACCEPT (If already on chats page) *** //
  // Listen to the Handle Invitation event
  useEffect(() => {
    const handleInvitationEvent = (e) => {
      const data = e.detail;
      const { botId } = data.chatMetaData;

      // Call the select chatbot function
      handleSelectChatbot(botId);

      // Select the chat
      handleSelectChat(data.chatMetaData);

      // Call the accept handover function
      acceptHandover(data);
    };

    window.addEventListener("handleInvitation", handleInvitationEvent);

    return () => {
      window.removeEventListener("handleInvitation", handleInvitationEvent);
    };
  }, []);

  // *** NOTIFICATION ACCEPT (If already on chats page) *** //
  // Listen to the Handle Notification event
  useEffect(() => {
    const handleNotificationEvent = (e) => {
      const data = e.detail;
      const { botId } = data.chatMetaData;

      // Call the select chatbot function
      handleSelectChatbot(botId);

      // Select the chat
      handleSelectChat(data.chatMetaData);

      // Call the accept handover function
      acceptHandover(data);
    };

    window.addEventListener("handleNotification", handleNotificationEvent);

    return () => {
      window.removeEventListener("handleNotification", handleNotificationEvent);
    };
  }, []);

  // Handle select chatbot
  const handleSelectChatbot = (botId) => {
    setLoading(true);

    // Set isBotSelected to true
    setIsBotSelected(true);

    // Set the selected bot id
    setSelectedBotId(botId);

    // Reset the chatsPage number
    setChatsPage(1);

    // Reset the chatsPage size
    setChatsPageSize(20);

    // Reset the selected chat
    setSelectedChat([]);

    // If admin
    if (user?.role === "admin") {
      // History push to /a/ws/:wid/chats/bot/:bid if not initialLoad
      if (!initialLoad) {
        history.push(`/a/ws/${user?.workspaceId}/chats/bot/${botId}`);
      }

      // Dispatch the action to get the chatbot responses
      dispatch(
        getSelectedChatbotResponsesAction(
          botId,
          1,
          20,
          setSelectedBotName,
          setLoading
        )
      );
    } else if (user?.role === "agent") {
      // History push to /m/ws/:wid/chats/bot/:bid if not initialLoad
      if (!initialLoad) {
        history.push(`/m/ws/${user?.workspaceId}/chats/bot/${botId}`);
      }

      dispatch(
        getAgentSelectedChatbotResponsesAction(
          botId,
          1,
          20,
          setSelectedBotName,
          setLoading
        )
      );
    }
  };

  // Handle select chat
  const handleSelectChat = (chat) => {
    // If chat is null (when archived), clear the selected chat
    if (!chat) {
      setSelectedChat({ record: [] });
      return;
    }

    // If the chat is already selected, return
    if (selectedChat?._id === chat._id) return;

    // Set message loading on selectChat
    setMessageLoading(true);

    // If there was a previously selected chat, leave its room
    if (selectedChat) {
      socket.current.emit("leave-chat-room", selectedChat.chatSessionId);
    }

    // Join room of the new chat
    socket.current.emit(
      "join-chat-room-agent",
      { chatSessionId: chat.chatSessionId, userId: user._id },
      (ack) => {
        if (ack) {
          setIsChatEnabled(ack.canJoin);
          setIsLeaveChatEnabled(ack.canLeave);
        } else {
          setIsChatEnabled(false);
          setIsLeaveChatEnabled(false);
        }
      }
    );

    // Socket message to check if the accept handover button has to be shown
    socket.current.emit(
      "show-accept-handover-button",
      { chatSessionId: chat.chatSessionId, userId: user._id },
      (ack) => {
        if (ack) {
          setShowAcceptHandoverButton(ack.showAcceptHandoverButton);
        } else {
          setShowAcceptHandoverButton(false);
        }
      }
    );

    // If admin
    if (user?.role === "admin") {
      // History push to /a/ws/:wid/chats/bot/:bid/chat/:cid
      history.push(
        `/a/ws/${user?.workspaceId}/chats/bot/${chat.botId}/chat/${chat?._id}`
      );

      // Dispatch the action to get the chatbot responses
      dispatch(
        updateSelectedChatWithLatestResponseMessagesAction(
          chat._id,
          setSelectedChat,
          setMessageLoading
        )
      );
    } else if (user?.role === "agent") {
      // History push to /m/ws/:wid/chats/bot/:bid/chat/:cid
      history.push(
        `/m/ws/${user?.workspaceId}/chats/bot/${chat.botId}/chat/${chat?._id}`
      );

      dispatch(
        updateAgentSelectedChatWithLatestResponseMessagesAction(
          chat._id,
          setSelectedChat,
          setMessageLoading
        )
      );
    }
  };

  // Handle select ticket chat
  const handleSelectTicketChat = (chat) => {
    // Set message loading on selectChat
    setMessageLoading(true);

    // If chat is null (when archived), clear the selected chat
    if (!chat) {
      setSelectedChat({ record: [] });
      return;
    }

    // If the chat is already selected, return
    if (selectedChat?._id === chat._id) {
      setMessageLoading(false);
      return;
    }
    // Set selectedBotName to chatbot name
    setSelectedBotName(chat?.botId?.name);

    setTimeout(() => {
      // Set the selected chat
      setSelectedChat(chat);

      setMessageLoading(false);
    }, 200);
  };

  // Handle accept handover
  const acceptHandover = (data) => {
    // If there was a previously selected chat, leave its room
    if (selectedChat.chatSessionId !== data.chatMetaData.chatSessionId) {
      socket.current.emit("leave-chat-room", selectedChat.chatSessionId);
    }

    // Create the agent data object
    const agentDetails = {
      _id: user._id,
      name: user.name,
      email: user.email,
    };

    // Initialize handoverData based on the presence of data.handoverData
    let handoverData = data.handoverData
      ? data.handoverData
      : {
          chatSessionId: data?.chatMetaData?.chatSessionId,
          priority: data?.priority,
          maxWaitTime: data?.maxWaitTime,
          // Assignment details
          assignmentType: data?.assignmentType, // "auto" or "manual"
          assignmentStrategy: data?.agentAssignmentStrategy,
          assignedAgents: data?.assignedAgents,
          // Notification details
          enableHandoverEmail: data?.enableHandoverEmail,
          enableHandoverSound: data?.enableHandoverSound,
          checkedSoundTab: data?.checkedSoundTab,
          selectedSound: data?.selectedSound,
          customHandoverSoundUrl: data?.customHandoverSoundUrl,
        };

    // Emit accept-handover event
    socket.current.emit(
      "accept-handover",
      {
        agentDetails,
        chatSessionId: data.chatSessionId,
        workspaceId: user?.workspaceId,
        handoverData,
      },
      (ack) => {
        if (!ack.hasLeft) {
          // Update UI based on the role of the user
          if (user?.role === "admin") {
            dispatch(
              updateSelectedChatbotResponsesAction(
                data.chatMetaData,
                setSelectedChat,
                setMessageLoading
              )
            );
          } else if (user?.role === "agent") {
            dispatch(
              updateAgentSelectedChatbotResponsesAction(
                data.chatMetaData,
                setSelectedChat,
                setMessageLoading
              )
            );
          }

          // Update selected chat with an 'agent-joined-message', if not already present
          if (ack.isChatAvailable) {
            setSelectedChat((prevSelectedChat) => {
              const alreadyJoined = prevSelectedChat.record.some(
                (message) =>
                  message.type === "agent-joined-message" &&
                  message._id === user._id
              );
              if (alreadyJoined) {
                return prevSelectedChat;
              }
              return {
                ...prevSelectedChat,
                record: [
                  ...prevSelectedChat.record,
                  {
                    _id: user._id,
                    type: "agent-joined-message",
                    time: new Date(),
                    name: user.name,
                  },
                ],
              };
            });
          }

          // Now, emit join-chat-room-agent within this callback to ensure sequence
          socket.current.emit(
            "join-chat-room-agent",
            { chatSessionId: data.chatSessionId, userId: user._id },
            (ac) => {
              setIsChatEnabled(ac?.canJoin ?? false);
              setIsLeaveChatEnabled(ac?.canLeave ?? false);
            }
          );

          // Focus on the chat input after joining
          document.getElementById("chatInput")?.focus();
        }
      }
    );
  };

  // Handle accept handover from chat
  const acceptHandoverFromChat = () => {
    setAcceptHandoverButtonLoading(true);

    // Create the agent data object
    let acceptHandoverData = {
      chatSessionId: selectedChat.chatSessionId,
      agentDetails: { _id: user._id, name: user.name, email: user.email },
      workspaceId: user?.workspaceId,
    };

    // Send an event to the server to accept the chat
    socket.current.emit(
      "accept-handover-from-chat",
      acceptHandoverData,
      (ack) => {
        setShowAcceptHandoverButton(false);

        // Ack contains the following: hasAccepted, hasLeft, isChatAvailable
        if ((ack.hasAccepted, ack.isChatAvailable)) {
          setIsChatEnabled(true);
          setAcceptHandoverButtonLoading(false);
        }
      }
    );
  };

  // Listen to 'bot-response' event from server
  const botResponseHandler = (data) => {
    if (selectedChat) {
      setSelectedChat((prevSelectedChat) => ({
        ...prevSelectedChat,
        record: data?.record,
        answerVariables: data.answerVariables,
      }));

      // Scroll to the bottom of the chatContainer
      const chatContainer = document.getElementById("chatMessageContainer");

      if (chatContainer) {
        setTimeout(() => {
          chatContainer.scrollTo({
            top: chatContainer.scrollHeight,
            behavior: "smooth",
          });
        }, 0);
      }

      // Update the selected bot chat with the latest message record and answerVariables
      // So that even if a different chat is clicked and come back, the latest messages are still available
      // If admin
      if (user?.role === "admin") {
        dispatch(
          updateBotWithLatestMessageAction(data.chatSessionId, {
            record: data?.record,
            answerVariables: data.answerVariables,
          })
        );
      } else if (user?.role === "agent") {
        dispatch(
          updateAgentBotWithLatestMessageAction(data.chatSessionId, {
            record: data?.record,
            answerVariables: data.answerVariables,
          })
        );
      }
    }
  };

  // Handle visitor disconnected
  const handleVisitorDisconnected = () => {
    // Scroll to the bottom of the chatContainer
    const chatContainer = document.getElementById("chatMessageContainer");

    // Push the message to the selected chat
    setSelectedChat((prevSelectedChat) => ({
      ...prevSelectedChat,
      record: [
        ...prevSelectedChat.record,
        {
          _id: user._id,
          type: "visitor-disconnected-message",
          time: new Date(),
        },
      ],
    }));

    if (chatContainer) {
      setTimeout(() => {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth",
        });
      }, 0);
    }
  };

  // Handle visitor reconnected
  const handleVisitorReconnected = () => {
    // Scroll to the bottom of the chatContainer
    const chatContainer = document.getElementById("chatMessageContainer");

    // Push the message to the selected chat
    setSelectedChat((prevSelectedChat) => ({
      ...prevSelectedChat,
      record: [
        ...prevSelectedChat.record,
        {
          _id: user._id,
          type: "visitor-reconnected-message",
          time: new Date(),
        },
      ],
    }));

    if (chatContainer) {
      setTimeout(() => {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth",
        });
      }, 0);
    }
  };

  // Handle multiple agents present
  const handleMultipleAgentsPresent = (data) => {
    // If data.chatSessionId is equal to selectedChat.chatSessionId
    if (data.chatSessionId === selectedChat.chatSessionId) {
      // If data.totalAgents is greater than 1, set isLeaveChatEnabled to true
      if (data.totalAgents > 1) {
        setIsLeaveChatEnabled(true);
      } else {
        setIsLeaveChatEnabled(false);
      }
    }
  };

  // Handle agent invitation declined
  const handleAgentInvitationDeclined = (data) => {
    // If data.chatSessionId is equal to selectedChat.chatSessionId
    if (data.chatSessionId === selectedChat.chatSessionId) {
      // Show notification data.declinedAgentDetails.name has declined the invitation
      WarningNotification(
        `👋 ${data.declinedAgentDetails.name} has declined the invitation!`
      );
    }
  };

  // ** Visitor Input ** //
  // Handle toggle visitor input
  const handleToggleVisitorInput = (chatSessionId, state) => {
    setIsVisitorInputEnabled(state);

    // Emit toggle-visitor-input event
    socket.current.emit("toggle-visitor-input", {
      chatSessionId,
      isInputEnabled: state,
    });
  };

  return (
    <>
      <Row
        style={{
          overflow: "hidden",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
        }}
      >
        {/* Left Chats List */}
        <ChatLeft
          // Loaders
          loading={loading}
          setLoading={setLoading}
          // Chatbot
          isBotSelected={isBotSelected}
          setIsBotSelected={setIsBotSelected}
          selectedBotId={selectedBotId}
          setSelectedBotId={setSelectedBotId}
          selectedBotName={selectedBotName}
          setSelectedBotName={setSelectedBotName}
          handleSelectChatbot={handleSelectChatbot}
          // Chats Pagination
          chatsPage={chatsPage}
          setChatsPage={setChatsPage}
          chatsPageSize={chatsPageSize}
          setChatsPageSize={setChatsPageSize}
          // Chat
          handleSelectChat={handleSelectChat}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          hasChatEnded={hasChatEnded}
          // Ticket Chat
          handleSelectTicketChat={handleSelectTicketChat}
        />

        {/* Please Select a Chat */}
        {selectedChat?.record?.length === 0 && (
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={19}
            style={{
              background: "#ffffff",
              boxShadow: "0px 0px 15px -12px rgba(0,0,0,0.75)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center", // This centers content vertically
            }}
          >
            {loading ? (
              <Spin
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 85px)",
                }}
              />
            ) : (
              <>
                <img
                  src={SelectChatImage}
                  alt="Select Chat"
                  style={{
                    width: "40%",
                    height: "40%",
                    objectFit: "contain",
                  }}
                />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                >
                  🎬 {t("chatMessages.messages.choiceABotInfo")} 👈
                </p>
              </>
            )}
          </Col>
        )}

        {/* Middle Chatbot Messages and Right Chatbot Visitor */}
        {selectedChat?.record?.length !== 0 && (
          <>
            <ChatRight
              socket={socket}
              // Loaders
              loading={loading}
              messageLoading={messageLoading}
              // Chatbot
              selectedBotName={selectedBotName}
              // Chat
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              showAcceptHandoverButton={showAcceptHandoverButton}
              acceptHandoverButtonLoading={acceptHandoverButtonLoading}
              acceptHandoverFromChat={acceptHandoverFromChat}
              // Chat Input
              isChatEnabled={isChatEnabled}
              setIsChatEnabled={setIsChatEnabled}
              isLeaveChatEnabled={isLeaveChatEnabled}
              setIsLeaveChatEnabled={setIsLeaveChatEnabled}
              setHasChatEnded={setHasChatEnded}
              // Visitor Input
              isVisitorInputEnabled={isVisitorInputEnabled}
              handleToggleVisitorInput={handleToggleVisitorInput}
            />
          </>
        )}
      </Row>
    </>
  );
};

export default Chats;
