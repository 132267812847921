import { FC } from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NodeTypes } from "../../types/types";
import Constants from "./constants";
import { Popover, Tag, Tooltip, message } from "antd";
import Img from "../../../../../../assets/images/pages/builder/screenshot.png";

export interface NodeTypeProps {
  id: string;
  label: string;
  type: NodeTypes;
  description: string;
  isPremium: boolean;
  plans: string[];
}

const NodeTypeRenderer: FC<NodeTypeProps> = ({
  id,
  type,
  label,
  description,
  isPremium,
  plans,
}) => {
  const history = useHistory();
  const { user } = useSelector((state: any) => state?.auth ?? {});
  const { iconsMap } = Constants();

  // Allow dragging if the node is not premium, or if the user has 'Pro' or 'Business' plan,
  // or if the user is a superadmin
  const canDrag =
    !isPremium ||
    (isPremium && plans.includes(user.currentPlan)) ||
    user.role === "superadmin";

  const [, drag] = useDrag(
    () => ({
      type: "node",
      item: { id, type, label },
      canDrag: canDrag,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (_, monitor) => {
        if (!canDrag && monitor.didDrop()) {
          message.warn("You need to upgrade your plan to use this node");
        }
      },
    }),
    [user.currentPlan, isPremium]
  );

  // If the node is premium and the user is not premium, don't attach the drag ref
  const dragRef = canDrag ? drag : undefined;

  // Get the icon for the node
  const Icon = iconsMap[type];

  // Handle Pro tag click
  const onProTagClick = () => {
    if (!user.isPremium) {
      history.push("/profile/plans");
    }
  };

  const content = (
    <div>
      <img
        src={Img}
        alt="tooltip"
        style={{ maxWidth: "200px", height: "auto" }}
      />
    </div>
  );

  return (
    <div style={{ position: "relative" }}>
      {/* Pro Tag with Tooltip */}
      {isPremium && (
        <Tooltip title="Upgrade your plan to use this node">
          <Tag
            onClick={onProTagClick}
            style={{
              position: "absolute",
              top: 5,
              right: 2,
              zIndex: 1,
              fontSize: "12px",
              padding: "0px 8px 0px 8px",
              background: "linear-gradient(to right, #0059b2, #0073e6)",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            Pro
          </Tag>
        </Tooltip>
      )}

      <Popover content={content} placement="right" trigger="hover">
        <ul className="nodes-list">
          <li ref={dragRef} id={id} style={{ opacity: canDrag ? 1 : 0.5 }}>
            {/* Node Icon */}
            <img
              src={Icon}
              style={{ height: "25px", width: "25px", marginRight: "15px" }}
              alt="layout icon"
            />

            <div>
              {/* Node Name */}
              <h3 className="node-label">{label}</h3>
              {/* Node Description */}
              <p className="node-desc">{description}</p>
            </div>
          </li>
        </ul>
      </Popover>
    </div>
  );
};

export default NodeTypeRenderer;
