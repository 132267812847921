import React from "react";
import { Row, Col, Switch, Input } from "antd";

const MetaData = ({ adminMetaData, handleToggleUserDisable }) => {
  return (
    <>
      {/* Meta Data */}
      <Row>
        <Col span={12}>
          <h4
            style={{
              marginBottom: "1.5rem",
            }}
          >
            Meta Data
          </h4>

          {/* Name */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Name
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={adminMetaData?.name}
              />
            </Col>
          </Row>

          {/* Email */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Email
            </Col>

            <Col sm={9} span={12}>
              <Input
                className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                value={adminMetaData?.email}
              />
            </Col>
          </Row>

          {/* Disabled */}
          <Row
            align="middle"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col
              sm={15}
              span={12}
              className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
            >
              Disabled
            </Col>

            <Switch
              className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
              checked={adminMetaData?.disabled}
              checkedChildren="On"
              unCheckedChildren="Off"
              onChange={() => handleToggleUserDisable(adminMetaData.user)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MetaData;
