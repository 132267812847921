import { Dispatch } from "redux";
import { ACTION_TYPES } from "../constants/actionTypes";
import { CHATBOT_TYPES } from "../constants/chatbotTypes";
import { ADMIN_TYPES } from "../constants/adminTypes";
import {
  // Get a chatbot by id with admin check
  getChatbotByIdWithAdminCheck,
  // Get a chatbot by id
  getChatbotById,
  // Update chatbot by id
  updateChatbotById,
  // Update chatbot customizations by id
  updateChatbotCustomizationsById,
  // Get chatbot flow analytics
  getChatbotFlowAnalytics,
  // Get chatbot with answer variables
  getChatbotWithAnswerVariables,
  // Publish chatbot
  publishChatbot,
  // Delete chatbot published flow version
  getChatbotPublishedFlowVersion,
  // Delete chatbot published flow version
  deleteChatbotPublishedFlowVersion,
} from "../../api/chatbot/chatbot";
import { getChatbotTemplatesByCategoryPaginated } from "../../api/template/template";
import {
  SuccessNotification,
  ErrorNotification,
  WarningNotification,
} from "../../components";

// Assuming the setLoading function takes a boolean and returns void
type SetLoadingType = (isLoading: boolean) => void;

/********************************************
  Get admin chatbot action
*********************************************/
export const getAdminChatbotAction =
  (id: any, setLoading: SetLoadingType) => (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      // Get chatbot by id
      getChatbotByIdWithAdminCheck(id)
        .then((res: any) => {
          const customizations = {
            // ------- GENERAL -------
            // Font
            font: res.data.font ?? "Arial",
            fontSize: res.data.fontSize ?? 14,

            // ------- LAYOUT -------
            // Header
            hideLogo: res.data.hideLogo ?? false,
            logo:
              res.data.logo ??
              "https://s3.amazonaws.com/aws.conferbot/62829a1c49f355163dfdbfb2/conferbot-logo-1710782074234.png",
            logoText: res.data.logoText ?? "Conferbot Support",
            enableTagline: res.data.enableTagline ?? false,
            tagline: res.data.tagline ?? "We're here to help",
            headerBgColor: res.data.headerBgColor ?? "#0100EC",
            headerTextColor: res.data.headerTextColor ?? "#fff",
            // Footer
            // Branding
            hideBrand: res.data.hideBrand ?? false,
            enableCustomBrand: res.data.enableCustomBrand ?? false,
            customBrand: res.data.customBrand ?? "My Brand...",

            // ------- CHAT -------
            // Avatar
            hideAvatar: res.data.hideAvatar ?? false,
            avatar:
              res.data.avatar ??
              "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/chat-logo-1681904317346.png",
            avatarSize: res.data.avatarSize ?? 40,
            // Message Bubbles
            bubbleBorderRadius: res.data.bubbleBorderRadius
              ? res.data.bubbleBorderRadius
              : 10,
            botMsgColor: res.data.botMsgColor ?? "#0100EC",
            botTextColor: res.data.botTextColor ?? "#fff",
            userMsgColor: res.data.userMsgColor ?? "#f0f0f0",
            userTextColor: res.data.userTextColor ?? "#000000",
            // Option Bubbles
            optionBubbleBorderRadius: res.data.optionBubbleBorderRadius
              ? res.data.optionBubbleBorderRadius
              : 10,
            optionBubbleMsgColor: res.data.optionBubbleMsgColor ?? "#e7e4e4",
            optionBubbleTextColor: res.data.optionBubbleTextColor ?? "#000",
            // Chat Background
            chatBgType: res.data.chatBgType ?? "solid",
            chatBgColor: res.data.chatBgColor ?? "#ffffff",
            gradientBgOne: res.data.gradientBgOne ?? "#fffcf1",
            gradientBgTwo: res.data.gradientBgTwo ?? "#0100EC",
            chatBgImg:
              res.data.chatBgImg ??
              "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/backgroundImage-1682085988477.jpeg",
            chatBgMaskColor: res.data.chatBgMaskColor ?? "#000",

            // ------- WIDGET -------
            // Widget Position
            widgetPosition: res.data.widgetPosition ?? "right",
            widgetOffsetRight: res.data.widgetOffsetRight ?? 0,
            widgetOffsetLeft: res.data.widgetOffsetLeft ?? 0,
            widgetOffsetBottom: res.data.widgetOffsetBottom ?? 25,
            // Widget Size
            widgetSize: res.data.widgetSize ?? 50,
            // Widget Appearance
            widgetIconThemeType: res.data.widgetIconThemeType ?? "Solid",
            widgetIconBgColor: res.data.widgetIconBgColor ?? "#0100EC",
            widgetGradientBgOne: res.data.widgetGradientBgOne ?? "#FF3E9D",
            widgetGradientBgTwo: res.data.widgetGradientBgTwo ?? "#0E1F40",
            // Widget Icon
            widgetIconType: res.data.widgetIconType ?? "Icon",
            widgetIconSVG: res.data.widgetIconSVG ?? "",
            widgetIconImage: res.data.widgetIconImage ?? "",
            // Widget Icon Shape
            widgetBorderRadius: res.data.widgetBorderRadius ?? "",
            // Widget Icon CTA
            chatIconCta: res.data.chatIconCta ?? false,
            chatIconCtaText:
              res.data.chatIconCtaText ?? "Hi👋, how can I help?",
            // Widget Toggle Sound
            enableWidgetToggleSound: res.data.enableWidgetToggleSound ?? false,
            checkedSoundTab: res.data.checkedSoundTab ?? "choose",
            widgetToggleSound: res.data.widgetToggleSound ?? "",
            customWidgetSoundUrl: res.data.customWidgetSoundUrl ?? "",
            // Announcement Banner
            enableAnnouncementBanner:
              res.data.enableAnnouncementBanner ?? false,
            bannerIconType: res.data.bannerIconType,
            bannerIconImage: res.data.bannerIconImage,
            bannerIconSVG: res.data.bannerIconSVG,
            bannerTitle: res.data.bannerTitle ?? "Announcement Title",
            bannerDescription:
              res.data.bannerDescription ?? "Announcement Description",
            bannerButtonOneTitle:
              res.data.bannerButtonOneTitle ?? "Signup Free",
            bannerButtonOneLink: res.data.bannerButtonOneLink,
            bannerButtonOneNewTab: res.data.bannerButtonOneNewTab,
            bannerButtonTwoTitle: res.data.bannerButtonTwoTitle ?? "Book Demo",
            bannerButtonTwoLink: res.data.bannerButtonTwoLink,
            bannerButtonTwoNewTab: res.data.bannerButtonTwoNewTab,

            // ------- ADVANCED -------
            // Auto Open
            botAutoOpen: res.data.botAutoOpen ?? false,
            botAutoOpenDelay: res.data.botAutoOpenDelay ?? 0,
            // Load Widget Delay
            loadWidgetDelay: res.data.loadWidgetDelay ?? false,
            loadWidgetAfter: res.data.loadWidgetAfter ?? 0,
            // Disabled URLs
            disabledUrls: res.data.disabledUrls ?? [],
            disableSubUrls: res.data.disableSubUrls ?? false,

            // ------- INITIAL PAGE --------
            enableInitialPage: res.data.enableInitialPage,
            initialPageTitle: res.data.initialPageTitle ?? "Hello 👋",
            initialPageDescription:
              res.data.initialPageDescription ??
              `Welcome to the Conferbot
We help your business to connect with your customer`,
            initialPageCardSubTitle:
              res.data.initialPageCardSubTitle ?? "Our usual reply time",
            initialPageAvatar: res.data.initialPageAvatar,
            initialPageCardTagline:
              res.data.initialPageCardTagline ?? "Later Today",
            initialPageCardButtonText:
              res.data.initialPageCardButtonText ?? "Chat with us",
            enableRecentArticles: res.data.enableRecentArticles,

            // ------- KNOWLEDGE-BASE --------
            enableKnowledgeBase: res.data.enableKnowledgeBase,

            // ------- Sidebar Active Pption --------
            sidebarActiveOption: res.data.sidebarActiveOption,
          };

          const currentBotFlowData = {
            _id: res.data._id ?? "",
            logo: res.data.logo ?? "",
            name: res.data.name ?? "",
            version: res.data.version ?? "",
            elements: res.data.elements ?? [],
          };

          // Create a payload
          const payload = {
            currentChatbot: res.data,
            currentBotFlowData: currentBotFlowData,
            customizations: customizations,
            publishedVersionHistory: res?.data?.versions,
          };

          // Dispatch a success alert
          dispatch({
            type: CHATBOT_TYPES.CURRENT_CHATBOT,
            payload: payload,
          });
        })
        .catch((error: any) => {
          // Warning notification
          WarningNotification(error.response.data.message);
          // Dispatch a error alert
          dispatch({
            type: ACTION_TYPES.ALERT,
            payload: {
              error: true,
              message: error.response.data.message,
            },
          });
        });

      // Set loading to false
      setLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "SET_CURRENT_CHATBOT_ERROR",
        },
      });
    }
  };

/********************************************
  Get admin superadmin chatbot action
*********************************************/
export const getSuperAdminChatbotAction =
  (id: any, setLoading: SetLoadingType) => (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      // Get chatbot by id
      getChatbotById(id)
        .then((res) => {
          const customizations = {
            // ------- GENERAL -------
            // Font
            font: res.data.font ?? "Arial",
            fontSize: res.data.fontSize ?? 14,

            // ------- LAYOUT -------
            // Header
            hideLogo: res.data.hideLogo ?? false,
            logo:
              res.data.logo ??
              "https://s3.amazonaws.com/aws.conferbot/62829a1c49f355163dfdbfb2/conferbot-logo-1710782074234.png",
            logoText: res.data.logoText ?? "Conferbot Support",
            enableTagline: res.data.enableTagline ?? false,
            tagline: res.data.tagline ?? "We're here to help",
            headerBgColor: res.data.headerBgColor ?? "#0100EC",
            headerTextColor: res.data.headerTextColor ?? "#fff",
            // Footer
            // Branding
            hideBrand: res.data.hideBrand ?? false,
            enableCustomBrand: res.data.enableCustomBrand ?? false,
            customBrand: res.data.customBrand ?? "My Brand...",

            // ------- CHAT -------
            // Avatar
            hideAvatar: res.data.hideAvatar ?? false,
            avatar:
              res.data.avatar ??
              "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/chat-logo-1681904317346.png",
            avatarSize: res.data.avatarSize ?? 40,
            // Message Bubbles
            bubbleBorderRadius: res.data.bubbleBorderRadius
              ? res.data.bubbleBorderRadius
              : 10,
            botMsgColor: res.data.botMsgColor ?? "#0100EC",
            botTextColor: res.data.botTextColor ?? "#fff",
            userMsgColor: res.data.userMsgColor ?? "#f0f0f0",
            userTextColor: res.data.userTextColor ?? "#000000",
            // Option Bubbles
            optionBubbleBorderRadius: res.data.optionBubbleBorderRadius
              ? res.data.optionBubbleBorderRadius
              : 10,
            optionBubbleMsgColor: res.data.optionBubbleMsgColor ?? "#e7e4e4",
            optionBubbleTextColor: res.data.optionBubbleTextColor ?? "#000",
            // Chat Background
            chatBgType: res.data.chatBgType ?? "solid",
            chatBgColor: res.data.chatBgColor ?? "#ffffff",
            gradientBgOne: res.data.gradientBgOne ?? "#fffcf1",
            gradientBgTwo: res.data.gradientBgTwo ?? "#0100EC",
            chatBgImg:
              res.data.chatBgImg ??
              "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/backgroundImage-1682085988477.jpeg",
            chatBgMaskColor: res.data.chatBgMaskColor ?? "#000",

            // ------- WIDGET -------
            // Widget Position
            widgetPosition: res.data.widgetPosition ?? "right",
            widgetOffsetRight: res.data.widgetOffsetRight ?? 0,
            widgetOffsetLeft: res.data.widgetOffsetLeft ?? 0,
            widgetOffsetBottom: res.data.widgetOffsetBottom ?? 25,
            // Widget Size
            widgetSize: res.data.widgetSize ?? 50,
            // Widget Appearance
            widgetIconThemeType: res.data.widgetIconThemeType ?? "Solid",
            widgetIconBgColor: res.data.widgetIconBgColor ?? "#0100EC",
            widgetGradientBgOne: res.data.widgetGradientBgOne ?? "#FF3E9D",
            widgetGradientBgTwo: res.data.widgetGradientBgTwo ?? "#0E1F40",
            // Widget Icon
            widgetIconType: res.data.widgetIconType ?? "Icon",
            widgetIconSVG: res.data.widgetIconSVG ?? "",
            widgetIconImage: res.data.widgetIconImage ?? "",
            // Widget Icon Shape
            widgetBorderRadius: res.data.widgetBorderRadius ?? "",
            // Widget Icon CTA
            chatIconCta: res.data.chatIconCta ?? false,
            chatIconCtaText:
              res.data.chatIconCtaText ?? "Hi👋, how can I help?",
            // Widget Toggle Sound
            enableWidgetToggleSound: res.data.enableWidgetToggleSound ?? false,
            checkedSoundTab: res.data.checkedSoundTab ?? "choose",
            widgetToggleSound: res.data.widgetToggleSound ?? "",
            customWidgetSoundUrl: res.data.customWidgetSoundUrl ?? "",
            // Announcement Banner
            enableAnnouncementBanner:
              res.data.enableAnnouncementBanner ?? false,
            bannerIconType: res.data.bannerIconType,
            bannerIconSVG: res.data.bannerIconSVG,
            bannerIconImage: res.data.bannerIconImage,
            bannerTitle: res.data.bannerTitle ?? "Announcement Title",
            bannerDescription:
              res.data.bannerDescription ?? "Announcement Description",
            bannerButtonOneTitle:
              res.data.bannerButtonOneTitle ?? "Signup Free",
            bannerButtonOneLink: res.data.bannerButtonOneLink,
            bannerButtonOneNewTab: res.data.bannerButtonOneNewTab,
            bannerButtonTwoTitle: res.data.bannerButtonTwoTitle ?? "Book Demo",
            bannerButtonTwoLink: res.data.bannerButtonTwoLink,
            bannerButtonTwoNewTab: res.data.bannerButtonTwoNewTab,

            // ------- ADVANCED -------
            // Auto Open
            botAutoOpen: res.data.botAutoOpen ?? false,
            botAutoOpenDelay: res.data.botAutoOpenDelay ?? 0,
            // Load Widget Delay
            loadWidgetDelay: res.data.loadWidgetDelay ?? false,
            loadWidgetAfter: res.data.loadWidgetAfter ?? 0,
            // Disabled URLs
            disabledUrls: res.data.disabledUrls ?? [],
            disableSubUrls: res.data.disableSubUrls ?? false,

            // ------- INITIAL PAGE --------
            enableInitialPage: res.data.enableInitialPage,
            initialPageTitle: res.data.initialPageTitle ?? "Hello 👋",
            initialPageDescription:
              res.data.initialPageDescription ??
              `Welcome to the Conferbot
We help your business to connect with your customer`,
            initialPageCardSubTitle:
              res.data.initialPageCardSubTitle ?? "Our usual reply time",
            initialPageAvatar: res.data.initialPageAvatar,
            initialPageCardTagline:
              res.data.initialPageCardTagline ?? "Later Today",
            initialPageCardButtonText:
              res.data.initialPageCardButtonText ?? "Chat with us",
            enableRecentArticles: res.data.enableRecentArticles,

            // ------- KNOWLEDGE-BASE --------
            enableKnowledgeBase: res.data.enableKnowledgeBase,

            // ------- Sidebar Active Pption --------
            sidebarActiveOption: res.data.sidebarActiveOption,
          };

          const currentBotFlowData = {
            _id: res.data._id ?? "",
            logo: res.data.logo ?? "",
            name: res.data.name ?? "",
            version: res.data.version ?? "",
            elements: res.data.elements ?? [],
          };

          // Create a payload
          const payload = {
            currentChatbot: res.data,
            currentBotFlowData: currentBotFlowData,
            customizations: customizations,
            publishedVersionHistory: res?.data?.versions,
          };

          // Dispatch a success alert
          dispatch({
            type: CHATBOT_TYPES.CURRENT_CHATBOT,
            payload: payload,
          });
        })
        .catch((error) => {
          // Warning notification
          WarningNotification(error.response.data.message);
          // Dispatch a error alert
          dispatch({
            type: ACTION_TYPES.ALERT,
            payload: {
              error: true,
              message: error.response.data.message,
            },
          });
        });

      // Set loading to false
      setLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "SET_CURRENT_CHATBOT_ERROR",
        },
      });
    }
  };

/********************************************
  Set current chatbot flow data action
*********************************************/
export const setCurrentBotFlowDataAction =
  (data: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      dispatch({
        type: CHATBOT_TYPES.CURRENT_BOT_FLOW_DATA,
        payload: data,
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "SET_CURRENT_BOT_FLOW_ELEMENTS_ERROR",
        },
      });
    }
  };

/********************************************
  Get categories templates action
*********************************************/
export const getCategoriesTemplatesAction =
  (
    page: any,
    perPage: any,
    searchText: any,
    category: any,
    setLoading: SetLoadingType
  ) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      // Get chatbot templates
      const res = await getChatbotTemplatesByCategoryPaginated(
        page,
        perPage,
        category,
        searchText
      );

      // Create a payload
      const payload = {
        templates: res.data.templates,
        categories: res.data.categories,
        paginationData: res.data.paginationData,
      };

      dispatch({
        type: CHATBOT_TYPES.GET_CATEGORIES_TEMPLATES,
        payload: payload,
      });

      // Set loading to false after successful data fetching
      setLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Handle the error appropriately (log, show an error message, etc.)
      console.error("Error fetching data:", error);

      // Dispatch an error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_CHATBOT_TEMPLATES_ERROR",
        },
      });

      // Set loading to false in case of an error
      setLoading(false);
    }
  };

/********************************************
  Get category chatbots action
*********************************************/
export const getCategoryChatbotsAction =
  (
    page: any,
    perPage: any,
    searchText: any,
    category: any,
    setLoading: any,
    callback: any
  ) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      // Get chatbot templates
      const res = await getChatbotTemplatesByCategoryPaginated(
        page,
        perPage,
        category,
        searchText
      );

      // Create a payload
      const payload = {
        templates: res.data.templates,
        paginationData: res.data.paginationData,
      };

      dispatch({
        type: CHATBOT_TYPES.GET_CATEGORY_CHATBOTS,
        payload: payload,
      });

      // Set loading and sidebar loading to false after successful data fetching
      setLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });

      if (callback && typeof callback === "function") {
        callback(); // Call the callback if it's a function
      }
    } catch (error) {
      // Set loading and sidebar loading to false in case of an error
      setLoading(false);

      // Handle the error appropriately (log, show an error message, etc.)
      console.error("Error fetching data:", error);

      if (callback && typeof callback === "function") {
        callback(); // Call the callback if it's a function
      }

      // Dispatch an error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_CHATBOT_TEMPLATES_ERROR",
        },
      });
    }
  };

/********************************************
  Set current chatbot flow data action
*********************************************/
export const setTemplatePreviewAction =
  (template: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      const customizations = {
        // ------- GENERAL -------
        // Font
        font: template?.chatbot.font ?? "Arial",
        fontSize: template?.chatbot.fontSize ?? 14,

        // ------- LAYOUT -------
        // Header
        hideLogo: template?.chatbot.hideLogo ?? false,
        logo:
          template?.chatbot.logo ??
          "https://s3.amazonaws.com/aws.conferbot/62829a1c49f355163dfdbfb2/conferbot-logo-1710782074234.png",
        logoText: template?.chatbot.logoText ?? "Conferbot Support",
        enableTagline: template?.chatbot.enableTagline ?? false,
        tagline: template?.chatbot.tagline ?? "We're here to help",
        headerBgColor: template?.chatbot.headerBgColor ?? "#0100EC",
        headerTextColor: template?.chatbot.headerTextColor ?? "#fff",
        // Footer
        // Branding
        hideBrand: template?.chatbot.hideBrand ?? false,
        enableCustomBrand: template?.chatbot.enableCustomBrand ?? false,
        customBrand: template?.chatbot.customBrand ?? "My Brand...",

        // ------- CHAT -------
        // Avatar
        hideAvatar: template?.chatbot.hideAvatar ?? false,
        avatar:
          template?.chatbot.avatar ??
          "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/chat-logo-1681904317346.png",
        avatarSize: template?.chatbot.avatarSize ?? 40,
        // Message Bubbles
        bubbleBorderRadius: template?.chatbot.bubbleBorderRadius
          ? template?.chatbot.bubbleBorderRadius
          : 10,
        botMsgColor: template?.chatbot.botMsgColor ?? "#0100EC",
        botTextColor: template?.chatbot.botTextColor ?? "#fff",
        userMsgColor: template?.chatbot.userMsgColor ?? "#f0f0f0",
        userTextColor: template?.chatbot.userTextColor ?? "#000000",
        // Option Bubbles
        optionBubbleBorderRadius: template?.chatbot.optionBubbleBorderRadius
          ? template?.chatbot.optionBubbleBorderRadius
          : 10,
        optionBubbleMsgColor:
          template?.chatbot.optionBubbleMsgColor ?? "#e7e4e4",
        optionBubbleTextColor:
          template?.chatbot.optionBubbleTextColor ?? "#000",

        // Chat Background
        chatBgType: template?.chatbot.chatBgType ?? "solid",
        chatBgColor: template?.chatbot.chatBgColor ?? "#ffffff",
        gradientBgOne: template?.chatbot.gradientBgOne ?? "#fffcf1",
        gradientBgTwo: template?.chatbot.gradientBgTwo ?? "#0100EC",
        chatBgImg:
          template?.chatbot.chatBgImg ??
          "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/backgroundImage-1682085988477.jpeg",
        chatBgMaskColor: template?.chatbot.chatBgMaskColor ?? "#000",

        // ------- WIDGET -------
        // Widget Position
        widgetPosition: template?.chatbot.widgetPosition ?? "right",
        widgetOffsetRight: template?.chatbot.widgetOffsetRight ?? 0,
        widgetOffsetLeft: template?.chatbot.widgetOffsetLeft ?? 0,
        widgetOffsetBottom: template?.chatbot.widgetOffsetBottom ?? 25,
        // Widget Size
        widgetSize: template?.chatbot.widgetSize ?? 50,
        // Widget Appearance
        widgetIconThemeType: template?.chatbot.widgetIconThemeType ?? "Solid",
        widgetIconBgColor: template?.chatbot.widgetIconBgColor ?? "#0100EC",
        widgetGradientBgOne: template?.chatbot.widgetGradientBgOne ?? "#FF3E9D",
        widgetGradientBgTwo: template?.chatbot.widgetGradientBgTwo ?? "#0E1F40",
        // Widget Icon
        widgetIconType: template?.chatbot.widgetIconType ?? "Icon",
        widgetIconSVG: template?.chatbot.widgetIconSVG ?? "",
        widgetIconImage: template?.chatbot.widgetIconImage ?? "",
        // Widget Icon Shape
        widgetBorderRadius: template?.chatbot.widgetBorderRadius ?? "",
        // Widget Icon CTA
        chatIconCta: template?.chatbot.chatIconCta ?? false,
        chatIconCtaText:
          template?.chatbot.chatIconCtaText ?? "Hi👋, how can I help?",
        // Widget Toggle Sound
        enableWidgetToggleSound:
          template?.chatbot.enableWidgetToggleSound ?? false,
        widgetToggleSound: template?.chatbot.widgetToggleSound ?? "",
        customWidgetSoundUrl: template?.chatbot.customWidgetSoundUrl ?? "",
        checkedSoundTab: template?.chatbot.checkedSoundTab ?? "choose",
        // Announcement Banner
        enableAnnouncementBanner:
          template?.chatbot.enableAnnouncementBanner ?? false,
        bannerIconType: template.chatbot.bannerIconType,
        bannerIconSVG: template?.chatbot.bannerIconSVG,
        bannerIconImage: template?.chatbot.bannerIconImage,
        bannerTitle: template?.chatbot.bannerTitle ?? "Announcement Title",
        bannerDescription:
          template?.chatbot.bannerDescription ?? "Announcement Description",
        bannerButtonOneTitle:
          template?.chatbot.bannerButtonOneTitle ?? "Signup Free",
        bannerButtonOneLink: template?.chatbot.bannerButtonOneLink,
        bannerButtonOneNewTab: template?.chatbot.bannerButtonOneNewTab,
        bannerButtonTwoTitle:
          template?.chatbot.bannerButtonTwoTitle ?? "Book Demo",
        bannerButtonTwoLink: template?.chatbot.bannerButtonTwoLink,
        bannerButtonTwoNewTab: template?.chatbot.bannerButtonTwoNewTab,

        // ------- ADVANCED -------
        // Auto Open
        botAutoOpen: template?.chatbot.botAutoOpen ?? false,
        botAutoOpenDelay: template?.chatbot.botAutoOpenDelay ?? 0,
        // Load Widget Delay
        loadWidgetDelay: template.chatbot.loadWidgetDelay ?? false,
        loadWidgetAfter: template.chatbot.loadWidgetAfter ?? 0,
        // Disabled URLs
        disabledUrls: template?.chatbot.disabledUrls ?? [],
        disableSubUrls: template?.chatbot.disableSubUrls ?? false,

        // ------- INITIAL PAGE --------
        enableInitialPage: template?.chatbot.enableInitialPage,
        initialPageTitle: template?.chatbot.initialPageTitle ?? "Hello 👋",
        initialPageDescription:
          template?.chatbot.initialPageDescription ??
          `Welcome to the Conferbot
We help your business to connect with your customer`,
        initialPageCardSubTitle:
          template?.chatbot.initialPageCardSubTitle ?? "Our usual reply time",
        initialPageAvatar: template?.chatbot.initialPageAvatar,
        initialPageCardTagline:
          template?.chatbot.initialPageCardTagline ?? "Later Today",
        initialPageCardButtonText:
          template?.chatbot.initialPageCardButtonText ?? "Chat with us",
        enableRecentArticles: template?.chatbot.enableRecentArticles,

        // ------- KNOWLEDGE-BASE --------
        enableKnowledgeBase: template?.chatbot.enableKnowledgeBase,

        // ------- Sidebar Active Pption --------
        sidebarActiveOption: template?.chatbot.sidebarActiveOption,
      };

      const currentBotFlowData = {
        _id: template?.chatbot._id,
        logo: template?.chatbot.logo,
        name: template?.chatbot.name,
        version: template?.chatbot.version,
      };

      // Check if 'template.chatbot.elements' is an array
      // If it is an array, use the first element, otherwise use it as an object or an empty object if undefined
      let elements = Array.isArray(template?.chatbot?.elements)
        ? template?.chatbot?.elements[0]
        : template?.chatbot?.elements || {};

      // Create the updated data structure
      let updatedData = {
        ...currentBotFlowData,
        elements: {
          nodes: elements.nodes || [],
          edges: elements.edges || [],
        },
      };

      // Create a payload
      const data = {
        customizations: customizations,
        currentBotFlowData: updatedData,
      };

      dispatch({
        type: CHATBOT_TYPES.TEMPLATE_PREVIEW,
        payload: data,
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "SET_CURRENT_BOT_FLOW_ELEMENTS_ERROR",
        },
      });
    }
  };

/********************************************
  Toggle nodes sidebar action
*********************************************/
export const toggleNodesSidebarAction =
  (value: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      dispatch({
        type: CHATBOT_TYPES.TOGGLE_NODES_SIDEBAR,
        payload: value,
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "TOGGLE_NODES_SIDEBAR_ERROR",
        },
      });
    }
  };

/********************************************
  Update current chatbot customizations action
*********************************************/
export const updateCurrentChatbotCustomizationsAction =
  (id: any, key: any, value: any) => (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // From the current redux state, update the customizations
      dispatch({
        type: CHATBOT_TYPES.UPDATE_CHATBOT_CUSTOMIZATIONS,
        payload: {
          key: key,
          value: value,
        },
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_CHATBOT_CUSTOMIZATIONS_ERROR",
        },
      });
    }
  };

/********************************************
  Save Current chatbot customizations action
*********************************************/
export const saveCurrentChatbotCustomizationsAction =
  (id: any, customizations: any, setApplyLoading: any) =>
  (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Save chatbot customizations
      updateChatbotCustomizationsById(id, customizations)
        .then(() => {
          // Success notification
          SuccessNotification("Chatbot customizations saved successfully");
          setApplyLoading(false);
        })
        .catch(() => {
          // Error notification
          ErrorNotification("Chatbot customizations could not be saved");
          setApplyLoading(false);
        });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "SAVE_CHATBOT_CUSTOMIZATIONS_ERROR",
        },
      });
    }
  };

/********************************************
  Save chatbot action
*********************************************/
export const saveChatbotAction =
  (id: any, values: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Update chatbot by id
      updateChatbotById(id, { values });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "SAVE_CHATBOT_ERROR",
        },
      });
    }
  };

/********************************************
  Get chatbot analytics action
*********************************************/
export const getChatbotFlowAnalyticsAction =
  (id: any, setGraphLoading: any) => (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      // Get chatbot by id
      getChatbotFlowAnalytics(id)
        .then((res) => {
          const customizations = {
            // ------- GENERAL -------
            // Font
            font: res.data.font ?? "Arial",
            fontSize: res.data.fontSize ?? 14,

            // ------- LAYOUT -------
            // Header
            hideLogo: res.data.hideLogo ?? false,
            logo:
              res.data.logo ??
              "https://s3.amazonaws.com/aws.conferbot/62829a1c49f355163dfdbfb2/conferbot-logo-1710782074234.png",
            logoText: res.data.logoText ?? "Conferbot Support",
            enableTagline: res.data.enableTagline ?? false,
            tagline: res.data.tagline ?? "We're here to help",
            headerBgColor: res.data.headerBgColor ?? "#0100EC",
            headerTextColor: res.data.headerTextColor ?? "#fff",
            // Footer
            // Branding
            hideBrand: res.data.hideBrand ?? false,
            enableCustomBrand: res.data.enableCustomBrand ?? false,
            customBrand: res.data.customBrand ?? "My Brand...",

            // ------- CHAT -------
            // Avatar
            hideAvatar: res.data.hideAvatar ?? false,
            avatar:
              res.data.avatar ??
              "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/chat-logo-1681904317346.png",
            avatarSize: res.data.avatarSize ?? 40,
            // Message Bubbles
            bubbleBorderRadius: res.data.bubbleBorderRadius
              ? res.data.bubbleBorderRadius
              : 10,
            botMsgColor: res.data.botMsgColor ?? "#0100EC",
            botTextColor: res.data.botTextColor ?? "#fff",
            userMsgColor: res.data.userMsgColor ?? "#f0f0f0",
            userTextColor: res.data.userTextColor ?? "#000000",
            // Option Bubbles
            optionBubbleBorderRadius: res.data.optionBubbleBorderRadius
              ? res.data.optionBubbleBorderRadius
              : 10,
            optionBubbleMsgColor: res.data.optionBubbleMsgColor ?? "#e7e4e4",
            optionBubbleTextColor: res.data.optionBubbleTextColor ?? "#000",
            // Chat Background
            chatBgType: res.data.chatBgType ?? "solid",
            chatBgColor: res.data.chatBgColor ?? "#ffffff",
            gradientBgOne: res.data.gradientBgOne ?? "#fffcf1",
            gradientBgTwo: res.data.gradientBgTwo ?? "#0100EC",
            chatBgImg:
              res.data.chatBgImg ??
              "https://conbot-test.s3.ap-south-1.amazonaws.com/642d634620a83e213dcb962f/backgroundImage-1682085988477.jpeg",
            chatBgMaskColor: res.data.chatBgMaskColor ?? "#000",

            // ------- WIDGET -------
            // Widget Position
            widgetPosition: res.data.widgetPosition ?? "right",
            widgetOffsetRight: res.data.widgetOffsetRight ?? 0,
            widgetOffsetLeft: res.data.widgetOffsetLeft ?? 0,
            widgetOffsetBottom: res.data.widgetOffsetBottom ?? 25,
            // Widget Size
            widgetSize: res.data.widgetSize ?? 50,
            // Widget Appearance
            widgetIconThemeType: res.data.widgetIconThemeType ?? "Solid",
            widgetIconBgColor: res.data.widgetIconBgColor ?? "#0100EC",
            widgetGradientBgOne: res.data.widgetGradientBgOne ?? "#FF3E9D",
            widgetGradientBgTwo: res.data.widgetGradientBgTwo ?? "#0E1F40",
            // Widget Icon
            widgetIconType: res.data.widgetIconType ?? "Icon",
            widgetIconSVG: res.data.widgetIconSVG ?? "",
            widgetIconImage: res.data.widgetIconImage ?? "",
            // Widget Icon Shape
            widgetBorderRadius: res.data.widgetBorderRadius ?? "",
            // Widget Icon CTA
            chatIconCta: res.data.chatIconCta ?? false,
            chatIconCtaText:
              res.data.chatIconCtaText ?? "Hi👋, how can I help?",
            // Widget Toggle Sound
            enableWidgetToggleSound: res.data.enableWidgetToggleSound ?? false,
            checkedSoundTab: res.data.checkedSoundTab ?? "choose",
            widgetToggleSound: res.data.widgetToggleSound ?? "",
            customWidgetSoundUrl: res.data.customWidgetSoundUrl ?? "",
            // Announcement Banner
            enableAnnouncementBanner:
              res.data.enableAnnouncementBanner ?? false,
            bannerIconType: res.data.bannerIconType,
            bannerIconSVG: res.data.bannerIconSVG,
            bannerIconImage: res.data.bannerIconImage,
            bannerTitle: res.data.bannerTitle ?? "Announcement Title",
            bannerDescription:
              res.data.bannerDescription ?? "Announcement Description",
            bannerButtonOneTitle:
              res.data.bannerButtonOneTitle ?? "Signup Free",
            bannerButtonOneLink: res.data.bannerButtonOneLink,
            bannerButtonOneNewTab: res.data.bannerButtonOneNewTab,
            bannerButtonTwoTitle: res.data.bannerButtonTwoTitle ?? "Book Demo",
            bannerButtonTwoLink: res.data.bannerButtonTwoLink,
            bannerButtonTwoNewTab: res.data.bannerButtonTwoNewTab,

            // ------- ADVANCED -------
            // Auto Open
            botAutoOpen: res.data.botAutoOpen ?? false,
            botAutoOpenDelay: res.data.botAutoOpenDelay ?? 0,
            // Load Widget Delay
            loadWidgetDelay: res.data.loadWidgetDelay ?? false,
            loadWidgetAfter: res.data.loadWidgetAfter ?? 0,
            // Disabled URLs
            disabledUrls: res.data.disabledUrls ?? [],
            disableSubUrls: res.data.disableSubUrls ?? false,

            // ------- INITIAL PAGE --------
            enableInitialPage: res.data.enableInitialPage,
            initialPageTitle: res.data.initialPageTitle ?? "Hello 👋",
            initialPageDescription:
              res.data.initialPageDescription ??
              `Welcome to the Conferbot
We help your business to connect with your customer`,
            initialPageCardSubTitle:
              res.data.initialPageCardSubTitle ?? "Our usual reply time",
            initialPageAvatar: res.data.initialPageAvatar,
            initialPageCardTagline:
              res.data.initialPageCardTagline ?? "Later Today",
            initialPageCardButtonText:
              res.data.initialPageCardButtonText ?? "Chat with us",
            enableRecentArticles: res.data.enableRecentArticles,

            // ------- KNOWLEDGE-BASE --------
            enableKnowledgeBase: res.data.enableKnowledgeBase,

            // ------- Sidebar Active Pption --------
            sidebarActiveOption: res.data.sidebarActiveOption,
          };

          const currentBotFlowData = {
            _id: res.data._id ?? "",
            logo: res.data.logo ?? "",
            name: res.data.name ?? "",
            version: res.data.version ?? "",
            elements: res.data.elements ?? [],
          };

          // Create a payload
          const payload = {
            currentChatbot: res.data,
            currentBotFlowData: currentBotFlowData,
            customizations: customizations,
            currentFlowAnalytics: res.data.elements,
            responses: res.data.responses,
          };

          // Dispatch a success alert
          dispatch({
            type: CHATBOT_TYPES.GET_CHATBOT_FLOW_ANALYTICS,
            payload: payload,
          });
        })
        .catch((error) => {
          // Warning notification
          WarningNotification(error.response.data.message);
          // Dispatch a error alert
          dispatch({
            type: ACTION_TYPES.ALERT,
            payload: {
              error: true,
              message: error.response.data.message,
            },
          });
        });

      setGraphLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_CHATBOT_FLOW_ANALYTICS_ERROR",
        },
      });
    }
  };

/********************************************
  Get chatbot responses with answer variables
*********************************************/
export const getChatbotResponsesAnswerVariablesAction =
  (id: any, setGraphLoading: any) => async (dispatch: Dispatch) => {
    try {
      // Get chatbot responses
      const response = await getChatbotWithAnswerVariables(id);

      // Dispatch a success alert
      dispatch({
        type: CHATBOT_TYPES.GET_CHATBOT_RESPONSES_ANSWER_VARIABLES,
        payload: response.data,
      });
      setGraphLoading(false);
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_CHATBOT_RESPONSES_ANSWER_VARIABLES_ERROR",
        },
      });
    }
  };

/********************************************
  Publish chatbot action
*********************************************/
export const publishChatbotAction =
  (id: any, elements: any, setPublishLoading: any) =>
  async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Update chatbot by id
      publishChatbot(id, elements)
        .then((res) => {
          // Success notification
          SuccessNotification("Chatbot published successfully");
          // Dispatch publish chatbot action
          dispatch({
            type: CHATBOT_TYPES.PUBLISH_CHATBOT,
            payload: res.data.versions,
          });
        })
        .catch(() => {
          // Error notification
          ErrorNotification("Chatbot could not be published");
        });

      setPublishLoading(false);

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "PUBLISH_CHATBOT_ERROR",
        },
      });
    }
  };

/********************************************
  Delete chatbot flow version action
*********************************************/
export const deleteChatbotFlowVersionAction =
  (bid: any, vid: any, setDeleteLoading: any) => async (dispatch: Dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Delete chatbot flow version
        const res = await deleteChatbotPublishedFlowVersion(bid, vid);

        // Dispatch a success alert
        dispatch({
          type: CHATBOT_TYPES.DELETE_CHATBOT_PUBLISHED_FLOW_VERSION,
          payload: res.data.flowVersions,
        });

        setDeleteLoading(false);
        resolve(res.data); // Resolve the promise with response data
      } catch (error) {
        // Dispatch an error alert
        dispatch({
          type: ACTION_TYPES.ALERT,
          payload: {
            message: "DELETE_CHATBOT_PUBLISHED_FLOW_VERSION_ERROR",
          },
        });

        setDeleteLoading(false);
        reject(error); // Reject the promise with error
      }
    });
  };

/********************************************
  Get chatbot flow version action
*********************************************/
export const getChatbotFlowVersionAction =
  (id: any, vid: any) => (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    // Dispatch clearChatbotStatesAction
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    try {
      // Get chatbot by id
      getChatbotPublishedFlowVersion(id, vid).then((res) => {
        const currentBotFlowData = {
          _id: res.data.flowVersion.botId._id ?? "",
          logo: res.data.flowVersion.botId.logo ?? "",
          name: res.data.flowVersion.botId.name ?? "",
          version: res.data.flowVersion.botId.version ?? "",
          elements: res.data.flowVersion.elements ?? [],
        };

        // Create a payload
        const payload = {
          currentBotFlowData: currentBotFlowData,
          currentFlowVersion: res.data.flowVersion.elements,
        };

        // Dispatch a success alert
        dispatch({
          type: CHATBOT_TYPES.GET_CHATBOT_PUBLISHED_FLOW_VERSION,
          payload: payload,
        });
      });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_CHATBOT_PUBLISHED_FLOW_VERSION_ERROR",
        },
      });
    }
  };

/********************************************
  Clear chatbot states action
*********************************************/
export const clearChatbotStatesAction = () => (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Dispatch clear chatbot states action
    dispatch({
      type: CHATBOT_TYPES.CLEAR_CHATBOT_STATES,
    });

    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "SET_CURRENT_CHATBOT_ERROR",
      },
    });
  }
};

/********************************************
  Disable chatbot action
*********************************************/
export const disableChatbotAction =
  (id: any, values: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Update chatbot by id
      updateChatbotById(id, values)
        .then(() => {
          // Success notification
          SuccessNotification(
            `Chatbot ${values.disabled ? "disabled" : "enabled"}`
          );
          // Dispatch CLEAR_ADMIN_CHATBOTS_WITH_TEMPLATES
          dispatch({
            type: ADMIN_TYPES.CLEAR_ADMIN_CHATBOTS_WITH_TEMPLATES,
          });
        })
        .catch(() => {
          // Error notification
          ErrorNotification(
            `Error ${values.disabled ? "disabling" : "enabling"} chatbot`
          );
        });

      // Dispatch a loading alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "DISABLE_CHATBOT_ERROR",
        },
      });
    }
  };

/********************************************
  Show notification action
*********************************************/
export const showNotificationAction = (isShow: any) => (dispatch: Dispatch) => {
  // Dispatch a notification alert
  dispatch({
    type: CHATBOT_TYPES.SHOW_NOTIFICATION,
    payload: isShow,
  });
};
