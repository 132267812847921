import { useState, FC } from "react";
import { Row, Col, Upload, message, Divider } from "antd";
import { useParams } from "react-router";
import { shallow } from "zustand/shallow";
import { RiLoaderLine, RiAddLine } from "react-icons/ri";
import { ImageNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
// translation
import { useTranslation } from "react-i18next";

const ImageNodeDataEditor: FC<ImageNode> = ({ type, id }) => {
  const { bid } = useParams<{ bid: string }>();
  const { getNode, changeNodeData } = useStore(selector, shallow);

  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // Handle welcome image upload
  const beforeUpload = (file: any) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG/GIF file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image/GIF must smaller than 5MB!");
    }
    return isJpgOrPng && isLt5M;
  };

  // Handle upload image
  const handleUploadImage = async (options: any) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("image", file);

    try {
      setLoading(true);
      await uploadChatbotMedia(bid, fmData, config)
        .then((res: any) => {
          changeNodeData({
            ...state,
            type,
            data: { ...state.data, image: res.data.url },
          });
          setLoading(false);
          message.success("Welcome media updated successfully");
        })
        .catch((err: any) => {
          onError(err);
          setLoading(false);
          message.error("Error updating media");
        });
    } catch (error) {
      onError(error);
    }
  };

  const uploadButton = (
    <div style={{ width: "100%", borderRadius: "15px" }}>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>
        {t("flowBuilder.sidebar.editor.sendResponseNodes.upload")}
      </div>
    </div>
  );

  return (
    <>
      {/* Node Image */}
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.sendResponseNodes.imageNodeInfo")}
          </p>
        </Row>

        <Divider orientation="left">
          <Col span={8} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.sendResponseNodes.image")}
          </Col>
        </Divider>
        <Col span={14}>
          <Upload
            name="welcome-image"
            listType="picture-card"
            customRequest={handleUploadImage}
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            multiple={false}
            accept="image/*,image/gif"
          >
            {state?.data?.image ? (
              <img
                src={state.data.image}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Col>
      </Row>
    </>
  );
};

export default ImageNodeDataEditor;
