import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Spin, Table } from "antd";
import { getAllPlans, deletePlanById } from "@api/subscription/plan";
import { SuccessNotification, ErrorNotification } from "@components";

const PlansList = ({ history }) => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const { data } = await getAllPlans();
      setPlans(data);
    } catch (error) {
      console.error("Error fetching plans", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlanCreate = () => {
    // Check if 'kon' cookie is present
    const konCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("kon="));

    if (!konCookie) {
      ErrorNotification("You don't have permission to create a plan.");
      return;
    }

    history.push("/super-admin/plans/create");
  };

  const handlePlanUpdate = (id) => {
    // Check if 'kon' cookie is present
    const konCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("kon="));

    if (!konCookie) {
      ErrorNotification("You don't have permission to update this plan.");
      return;
    }

    history.push(`/super-admin/plans/update/${id}`);
  };

  const handlePlanDelete = (id) => {
    // Check if 'kon' cookie is present
    const konCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("kon="));

    if (!konCookie) {
      ErrorNotification("You don't have permission to delete this plan.");
      return;
    }

    Modal.confirm({
      title: "Are you sure you want to delete this plan?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deletePlanById(id);
          fetchPlans();
          SuccessNotification("Plan deleted successfully");
        } catch (error) {
          ErrorNotification("Error deleting plan");
        }
      },
    });
  };

  const columns = [
    {
      title: "Plan name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Meta description",
      dataIndex: "metaDescription",
      key: "metaDescription",
    },
    {
      title: "Monthly price",
      dataIndex: "monthlyPrice",
      key: "monthlyPrice",
      render: (text) => `$${text.toFixed(2)}`, // Assuming price is a number
    },
    {
      title: "Annual price",
      dataIndex: "annualPrice",
      key: "annualPrice",
      render: (text) => `$${text.toFixed(2)}`, // Assuming price is a number
    },
    {
      title: "Max chatbots",
      dataIndex: "maxChatbots",
      key: "maxChatbots",
    },
    {
      title: "Max users",
      dataIndex: "maxUsers",
      key: "maxUsers",
    },
    {
      title: "Trial days",
      dataIndex: "trialDays",
      key: "trialDays",
      render: (text) => `${text} days`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handlePlanUpdate(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handlePlanDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <Card style={{ borderRadius: "0", height: "100vh" }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h3>Plan List</h3>
        </Col>
        <Col>
          <Button type="primary" onClick={handlePlanCreate}>
            Create Plan
          </Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={plans}
          rowKey={(record) => record._id}
          scroll={{ y: "calc(100vh - 270px)" }} // Adjust height as needed
        />
      </Spin>
    </Card>
  );
};

export default PlansList;
