import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, withRouter } from "react-router";
import { useSelector } from "react-redux";
import { RiLoaderLine, RiAddLine } from "react-icons/ri";
import {
  getBlogById,
  updateBlogById,
  uploadBlogCoverImage,
  getAllBlogTags,
  getAllBlogCategories,
} from "@api/blog/blog";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Upload,
  message,
  Spin,
} from "antd";
import { SuccessNotification, ErrorNotification } from "../..";
import JoditEditor from "jodit-react";
import moment from "moment";

const initialState = {
  title: "",
  coverImage: "",
  description: "",
  tags: [],
  content: "",
  category: "",
  publishedDate: "",
  images: [],
};

const BlogUpdateForm = ({ history }) => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);

  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);

  const editorRef = useRef(null);

  useEffect(() => {
    fetchBlog();
  }, []);

  // Get blog by id
  const fetchBlog = async () => {
    setLoading(true);
    try {
      const { data } = await getBlogById(id);

      // Fetch categories
      const cats = await getAllBlogCategories();
      setCategories(cats.data);

      // Fetch tags
      const tags = await getAllBlogTags();
      setTags(tags.data);

      // Set category & tags
      data.category = data.category._id;
      data.tags = data.tags.map((tag) => tag._id);

      // Convert date to moment object
      if (data.publishedDate) {
        data.publishedDate = moment(
          data.publishedDate?.split("T")[0],
          "YYYY/MM/DD"
        );
      }

      setValues({
        ...data,
        content: data.content,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_BLOG_BY_ID_ERROR", error);
    }
  };

  // Handle form changes
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const contentFieldChanged = (newContent) => {
    setValues({
      ...values,
      content: newContent,
    });
  };

  // Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Set update loading to true
    setUpdateLoading(true);

    // Validate the form
    if (
      !values.title ||
      !values.description ||
      !values.category ||
      !values.publishedDate ||
      !values.content
    ) {
      setUpdateLoading(false);
      return ErrorNotification("Please fill all the required fields");
    }

    updateBlogById(id, values)
      .then(() => {
        SuccessNotification(`Blog updated successfully`);

        // Set update loading to false
        setUpdateLoading(false);

        // If user is superadmin, redirect to blogs
        if (user?.role === "superadmin") {
          history.push("/super-admin/blogs");
        } else if (user?.role === "marketing") {
          history.push("/marketing-admin/blogs");
        }
      })
      .catch((error) => {
        setUpdateLoading(false);
        ErrorNotification("Error updating blog");
        console.log("UPDATE_BLOG_ERROR", error);
      });
  };

  // Before image upload
  const beforeCoverUpload = (file) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Image must smaller than 3MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // Handle cover image upload
  const handleUploadCoverImage = async (options) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload image
    try {
      setLoading(true);
      const res = await uploadBlogCoverImage(fmData, config);
      setValues({
        ...values,
        coverImage: res.data.url,
      });
      setLoading(false);
    } catch (error) {
      onError(error);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  // Twitter SVG
  const twitterSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 432 432">
   <path fill="currentColor" d="M335 159q22-18 28-30q-13 6-31 9q18-13 24-32q-20 11-37 14q-12-14-31-16.5t-35.5 5t-26.5 25t-5 38.5q-67-4-118-59q-11 20-4.5 43.5T120 189q-11-1-24-7q1 43 44 57q-12 3-24 1q12 36 53 40q-15 13-39 19.5T85 303q45 28 92 26q70-3 113.5-49.5T335 159zM384 3q18 0 30.5 12.5T427 45v342q0 17-12.5 29.5T384 429H43q-18 0-30.5-12.5T0 387V45q0-17 12.5-29.5T43 3h341z"/>
   </svg>`;

  // Config for JoditEditor
  const joditConfig = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      minHeight: 200, // Set minimum height for the editor
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
      buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "font",
        "fontsize",
        "|",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "superscript",
        "subscript",
        "|",
        "brush",
        "paragraph",
        "|",
        "align",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "undo",
        "redo",
        "|",
        "cut",
        "copy",
        "paste",
        "selectall",
        "|",
        "link",
        "unlink",
        "|",
        "image",
        "video",
        {
          name: "Twitter",
          icon: twitterSvg,
          tooltip: "Insert Twitter",
          exec: (editor) => {
            const twitterURL = prompt("Enter Your Twitter Embed Code:"); // Prompt user for Twitter URL

            // Check if URL is provided and not empty
            if (twitterURL && twitterURL.trim() !== "") {
              // Used for embed twitter in jodit
              const script = document.createElement("script");
              script.src = "https://platform.twitter.com/widgets.js";
              script.async = true;
              document.body.appendChild(script);
              // Insert Twitter embed HTML into the editor
              editor.s.insertHTML(
                `<blockquote class="twitter-tweet" style="width: 100%; height: 100%;">${twitterURL}</blockquote>`
              );
              return () => {
                document.body.removeChild(script);
              };
            }
          },
        },
        "|",
        "table",
        "hr",
        "|",
        "symbol",
        "eraser",
        "|",
        "print",
        "fullsize",
        "about",
        "spellcheck",
        "|",
        "preview",
        "print",
        "about",
      ],
    }),
    []
  );

  // Used for embed twitter in jodit
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Row>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 50px)",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Col
          span={24}
          style={{
            overflowY: "auto",
            height: "calc(100vh - 100px)",
          }}
        >
          <Form
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 18,
            }}
          >
            {/* Blog Title */}
            <Form.Item label="Title">
              <Input
                name="title"
                placeholder="Enter title"
                onChange={handleFormChange}
                value={values.title}
                rules={[{ required: true }]}
              />
            </Form.Item>

            {/* Blog cover image */}
            <Form.Item label="Cover Image">
              <Upload
                name="coverImage"
                listType="picture-card"
                customRequest={handleUploadCoverImage}
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeCoverUpload}
                multiple={false}
                accept="image/*,image/gif"
              >
                {values.coverImage ? (
                  <img
                    src={values.coverImage}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            {/* SEO meta description */}
            <Form.Item label="Meta Description">
              <Input
                name="description"
                placeholder="Enter meta description"
                onChange={handleFormChange}
                value={values.description}
                rules={[{ required: true }]}
              />
            </Form.Item>

            {/* Select drop down with category */}
            <Form.Item label="Category">
              <Select
                name="category"
                placeholder="Select a category"
                onChange={(value) => setValues({ ...values, category: value })}
                value={values.category}
                rules={[{ required: true }]}
              >
                {categories.map((category) => (
                  <Select.Option key={category._id} value={category._id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* Tags */}
            <Form.Item label="Tags">
              <Select
                mode="multiple"
                name="tags"
                placeholder="Select tags"
                onChange={(value) => setValues({ ...values, tags: value })}
                value={values.tags}
                rules={[{ required: true }]}
              >
                {tags.map((tag) => (
                  <Select.Option key={tag._id} value={tag._id}>
                    {tag.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* Publish date */}
            {!loading && (
              <Form.Item label="Published Date">
                <DatePicker
                  className="hp-mb-16 hp-mr-16"
                  onChange={(date, dateString) =>
                    setValues({ ...values, publishedDate: dateString })
                  }
                  defaultValue={values.publishedDate}
                />
              </Form.Item>
            )}

            {/* Blog Content */}
            {!loading && (
              <Form.Item label="Content">
                <Col
                  style={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <JoditEditor
                    ref={editorRef}
                    value={values?.content}
                    config={joditConfig}
                    onBlur={(newContent) => contentFieldChanged(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                  />
                </Col>
              </Form.Item>
            )}

            {/* Submit button */}
            <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
              <Button
                type="primary"
                onClick={handleFormSubmit}
                loading={updateLoading}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Col>
      )}
    </Row>
  );
};

export default withRouter(BlogUpdateForm);
