import React from "react";
import { Card, Row, Col, Button } from "antd";
import { InfoNotification } from "../../../components";
import illustration from "../../../assets/images/illustrations/listening-feedback.svg";
import cardBg2 from "../../../assets/images/dashboard/ecommerce-card-bg.png";
import { useTranslation } from "react-i18next";

const HelpCenter = () => {
  const { t } = useTranslation();

  return (
    <Card
      className="hp-mt-32"
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Row>
        <Col className="hp-text-center" span={24}>
          <Col
            style={{
              backgroundImage: "url(" + cardBg2 + ")",
              backgroundSize: "cover",
              backgroundPosition: "center right",
              borderRadius: "8px",
            }}
          >
            <img
              src={illustration}
              alt="video tutorials"
              style={{ width: "60%" }}
            />
          </Col>

          <h4 className="hp-mb-0 hp-my-24 hp-mr-4 hp-font-weight-700">
            {t("dashboard.helpCenterTitle")}
          </h4>

          <p className="hp-mb-0 hp-my-24 hp-mr-4 hp-font-weight-400">
            {t("dashboard.helpCenterSubTitle")}
          </p>
        </Col>
      </Row>

      <Row justify="center">
        <Button
          className="da-float-right hp-mt-8 da-mt-xs-16 da-text-color-primary-1 da-bg-dark-primary-1 da-border-color-dark-primary-1 da-text-color-dark-0"
          onClick={() => {
            InfoNotification("Coming soon!");
          }}
        >
          {t("dashboard.learnMore")}
        </Button>
      </Row>
    </Card>
  );
};

export default HelpCenter;
