// ------------------ Types of nodes ------------------
export enum NodeTypes {
  // **********  v1 (Legacy Nodes) **********
  // ** DEPRECATED NODES **
  SelectOption = "select-option-node",
  TwoChoices = "two-choices-node",
  ThreeChoices = "three-choices-node",
  Rating = "user-rating-node",
  Range = "user-range-node",
  UserInput = "user-input-node",
  Quiz = "quiz-node",

  // **********  v2 (New Nodes) **********
  // ** STARTER NODE **
  Welcome = "welcome-node",

  // ** SEND RESPONSE NODES **
  Message = "message-node",
  Image = "image-node",
  Video = "video-node",
  Audio = "audio-node",
  File = "file-node",

  // ** ASK QUESTION NODES **
  AskName = "ask-name-node",
  AskEmail = "ask-email-node",
  AskPhoneNumber = "ask-phone-number-node",
  AskFile = "ask-file-node",
  AskNumber = "ask-number-node",
  AskUrl = "ask-url-node",
  AskMultipleQuestions = "ask-multiple-questions-node",
  AskLocation = "ask-location-node",
  AskCustomQuestion = "ask-custom-question-node",
  Calendar = "calendar-node",
  NCheckOptions = "n-check-options-node",
  NSelectOption = "n-select-option-node",

  // ** FLOW OPERATION NODES **
  NChoices = "n-choices-node",
  ImageChoice = "image-choice-node",
  RatingChoice = "rating-choice-node",
  YesOrNo = "yes-or-no-choice-node",
  OpinionScale = "opinion-scale-choice-node",

  // ** LOGICAL NODES **
  BusinessHours = "business-hours-node",
  Condition = "condition-node",
  MathOperation = "math-operation-node",
  BooleanLogic = "boolean-logic-node",
  RandomFlow = "random-flow-node",
  Variable = "variable-node",

  // ** SPECIAL NODES **
  Email = "email-node",
  Html = "html-node",
  Redirect = "user-redirect-node",
  JumpTo = "jump-to-node",
  HumanHandover = "human-handover-node",
  Delay = "delay-node",
  Navigate = "navigate-node",

  // ** INTEGRATION NODES **
  Webhook = "webhook-node",
  Gpt = "gpt-node",
  Zapier = "zapier-node",
  GoogleSheets = "google-sheets-node",
  Slack = "slack-node",
  Airtable = "airtable-node",
  Zoho = "zoho-node",
  HubSpot = "hubspot-node",
  MailChimp = "mailchimp-node",
  Segment = "segment-node",
  Salesforce = "salesforce-node",
}
