import { Col, Image, Row } from "antd";
import EmptyArticle1 from "@assets/images/pages/knowledgeBase/empty-articles-1.svg";
import ThumbsUp from "@assets/images/pages/knowledgeBase/thumbs-up.png";

// -------------- Cloned first chatbot joyride -------------------
export const knowledgeBaseSteps = [
  {
    attachTo: { on: "center" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Row>
          <Col md={24} span={24}>
            <div>
              <Image
                src={EmptyArticle1}
                preview={false}
                width={100}
                className="hp-mb-12"
              />
              <h2 className="hp-text-3xl hp-font-bold hp-text-black-90 hp-text-dark-10">
                Welcome to the Knowledge Base
              </h2>
              <h5 className="hp-text-lg hp-text-black-70 hp-text-dark-30">
                We're excited to introduce you to our new features. Let's get
                started!
              </h5>
            </div>
          </Col>
        </Row>
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 600,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
  {
    attachTo: { element: ".create-button", on: "right" },
    text: (
      <div>
        <h3>Create Articles & Categories</h3>
        <p>
          Click the create button to access options for creating new{" "}
          <b>Articles</b> and <b>Categories</b>.
        </p>
      </div>
    ),
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  {
    attachTo: { element: ".articles-tab", on: "right" },
    text: (
      <div>
        <h3>View All Articles</h3>
        <p>
          Click this tab to see a list of all created <b>Articles</b> on the
          right side.
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  {
    attachTo: { element: ".categories-tab", on: "right" },
    text: (
      <div>
        <h3>View All Categories</h3>
        <p>
          Click this tab to see a list of all created <b>Categories</b> on the
          right side.
        </p>
      </div>
    ),
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  {
    attachTo: { element: ".kb-chat-dropdown", on: "bottom" },
    text: (
      <div>
        <h3>Select Chatbot</h3>
        <p>
          Here you can see a list of your available chatbots. Select any of them
          to create articles and categories for that specific chatbot.
        </p>
      </div>
    ),
    placement: "left",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    showProgress: true,
  },
  {
    attachTo: { on: "center" },
    text: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Row>
          <Col md={24} span={24}>
            <div>
              <Image
                src={ThumbsUp}
                preview={false}
                width={100}
                className="hp-mb-12"
              />
              <h2 className="hp-text-3xl hp-font-bold hp-text-black-90 hp-text-dark-10">
                You're all set! 🎉
              </h2>
              <h5 className="hp-text-lg hp-text-black-70 hp-text-dark-30">
                <b>Let's start building your Knowledge Base! 🚀</b>
              </h5>
            </div>
          </Col>
        </Row>
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 600,
        zIndex: 1000,
      },
    },
    showProgress: false,
  },
];
