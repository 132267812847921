import React, { FC, useState, useEffect } from "react";
import { Row, Col, Tabs, Select, Divider, Checkbox, message } from "antd";
import DatePicker from "react-multi-date-picker";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import moment from "moment-timezone";
import { shallow } from "zustand/shallow";
import useStore, { selector } from "../../../../../store/store";
import { BusinessHoursNode } from "../../../../../common/nodes/typings";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const { Option } = Select;

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const BusinessHoursNodeDataEditor: FC<BusinessHoursNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);
  const { t } = useTranslation();

  const [timezone, setTimezone] = useState(state?.data?.timezone);
  const [excludeDays, setExcludeDays] = useState(state?.data?.excludeDays);
  const [excludeDates, setExcludeDates] = useState(state?.data?.excludeDates);
  const [weeklyHours, setWeeklyHours] = useState(state?.data?.weeklyHours);

  // Each time calendarNodeData changes, update the state
  useEffect(() => {
    setTimezone(state?.data?.timezone);
    setExcludeDays(state?.data?.excludeDays);
    setExcludeDates(state?.data?.excludeDates);
    setWeeklyHours(state?.data?.weeklyHours);
  }, [state]);

  useEffect(() => {
    // Extracting the day names that are not available
    const unavailableDaysNames = weeklyHours
      .filter((day: any) => !day.available)
      .map((day: any) => daysOfWeek[day.day]);
    setExcludeDays(unavailableDaysNames);
  }, [weeklyHours]);

  // Timezone options
  const timezones = moment.tz.names();

  const formatTimezone = (timezone: any) => {
    const offset = moment.tz(timezone).utcOffset();
    const hours = Math.floor(offset / 60);
    const minutes = offset % 60;
    const sign = hours < 0 ? "-" : "+";
    return `${timezone} (GMT${sign}${Math.abs(hours)
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")})`;
  };

  // Handle timezone change
  const handleTimezoneChange = (content: string) => {
    setTimezone(content);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        timezone: content,
      },
    });
  };

  // Exclude Dates Change
  const handleExcludeDatesChange = (value: any) => {
    setExcludeDates(value);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        excludeDates: value,
      },
    });
  };

  // Weekly Hours Change
  const handleWeeklyHoursChange = (e: any, val: number) => {
    if (e.target.checked) {
      // Find the object in array with day === val and update available to true
      const dayToChange: any = state?.data?.weeklyHours.find(
        (day: any) => day.day === val
      );
      dayToChange.available = true;
      // if dayToChange.slots is empty, add default slot
      if (dayToChange.slots.length === 0) {
        dayToChange.slots.push({
          start: "09:00",
          end: "17:00",
        });
      }

      setWeeklyHours([...state?.data?.weeklyHours]);
    } else {
      // Find the object in array with day === val and update available to false
      const dayToChange: any = state?.data?.weeklyHours.find(
        (day: any) => day.day === val
      );
      dayToChange.available = false;
      setWeeklyHours([...state?.data?.weeklyHours]);
    }

    // Extracting the day names that are not available
    const unavailableDaysNames = weeklyHours
      .filter((day: any) => !day.available)
      .map((day: any) => daysOfWeek[day.day]);

    // Set excludeDays to all the unavailable weekdays
    setExcludeDays(unavailableDaysNames);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
        excludeDays: unavailableDaysNames,
      },
    });
  };

  const handleAddTimeSlot = (val: number) => {
    // Find the object in array with day === val and add a new time slot
    const dayToChange: any = state?.data?.weeklyHours.find(
      (day: any) => day.day === val
    );

    dayToChange.slots.push({
      start: "09:00",
      end: "17:00",
    });

    setWeeklyHours([...state?.data?.weeklyHours]);
    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
      },
    });
  };

  const handleDeleteTimeSlot = (val: number, index: number) => {
    // Find the object in array with day === val and delete the time slot
    const dayToChange: any = state?.data?.weeklyHours.find(
      (day: any) => day.day === val
    );

    // Remove that exact slot
    dayToChange.slots.splice(index, 1);

    // If there are no more slots, set available to false
    if (dayToChange.slots.length === 0) {
      dayToChange.available = false;
    }

    // Set checked to false if available is false

    setWeeklyHours([...state?.data?.weeklyHours]);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
      },
    });
  };

  const handleWeeklyHoursSlotChange = (
    value: any,
    day: number,
    slot: any,
    type: any
  ) => {
    // Find the object in array with day === val and update the time slot
    const dayToChange: any = state?.data?.weeklyHours.find(
      (dayT: any) => dayT.day === day
    );

    // Find the slot to change
    const slotToChange: any = dayToChange.slots.find(
      (slotToChange: any) => slotToChange === slot
    );

    // Update the slot
    slotToChange[type] = value;

    setWeeklyHours([...state?.data?.weeklyHours]);

    // Set cell data
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        weeklyHours,
      },
    });
  };

  const times: any = [
    "00:00",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
    "04:15",
    "04:30",
    "04:45",
    "05:00",
    "05:15",
    "05:30",
    "05:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
  ];

  // Select options
  const renderTimeOptions = () => {
    return (
      <>
        {times.map((time: any, index: any) => (
          <Option key={index} value={time}>
            {time}
          </Option>
        ))}
      </>
    );
  };

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.logicalNodes.businessHoursNodeInfo")}
          </p>
        </Row>

        {/* Timezone */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.logicalNodes.timeZone")}
          </Col>
        </Divider>

        <Col span={24} style={{ marginTop: "8px" }}>
          <Select
            value={timezone}
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a timezone"
            optionFilterProp="children"
            onChange={handleTimezoneChange}
            filterOption={(input, option) =>
              option.children
                ?.toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {timezones.map((timezone) => (
              <Option key={timezone} value={timezone}>
                {formatTimezone(timezone)}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Col
        span={24}
        style={{
          marginTop: "20px",
          marginLeft: "10px",
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <p style={{ fontSize: "1rem", marginBottom: "-0.5rem" }}>
                {t("flowBuilder.sidebar.editor.logicalNodes.openBusinessHours")}
              </p>
            }
            key="1"
          >
            {/* Add your available time */}
            <Row
              style={{
                marginTop: "5px",
              }}
            >
              <Col span={24}>
                <h5 className="hp-mb-12 hp-mt-8">
                  {t("flowBuilder.sidebar.editor.logicalNodes.setWeeklyHours")}
                </h5>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: "2px",
              }}
            >
              <Col span={24}>
                {weeklyHours?.map((day: any, index: any) => {
                  return (
                    <div key={index}>
                      <Row align="middle">
                        <Col span={12}>
                          <Checkbox
                            checked={day.available}
                            onChange={(e) =>
                              handleWeeklyHoursChange(e, day.day)
                            }
                          >
                            {day.dayName}
                          </Checkbox>
                        </Col>
                      </Row>

                      {day.available &&
                        day.slots.map((slot: any, j: any) => {
                          return (
                            <Row
                              key={j}
                              align="middle"
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {/* Display the time slot options based on the calendarNodeData.weeklyHours[0].slots */}
                              <>
                                <Col
                                  span={17}
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  {/* Select Dropdown with time intervals of 15 mins 24h format */}
                                  <Select
                                    defaultValue={slot.start}
                                    style={{ width: 80 }}
                                    size="small"
                                    onChange={(value: any) =>
                                      handleWeeklyHoursSlotChange(
                                        value,
                                        day.day,
                                        slot,
                                        "start"
                                      )
                                    }
                                  >
                                    {renderTimeOptions()}
                                  </Select>

                                  <span
                                    style={{
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    -
                                  </span>

                                  <Select
                                    defaultValue={slot.end}
                                    style={{ width: 80 }}
                                    size="small"
                                    onChange={(value: any) =>
                                      handleWeeklyHoursSlotChange(
                                        value,
                                        day.day,
                                        slot,
                                        "end"
                                      )
                                    }
                                  >
                                    {renderTimeOptions()}
                                  </Select>
                                </Col>

                                {/* Plus Icon */}
                                <Col span={3}>
                                  <div
                                    style={{
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                      marginTop: "5px",
                                    }}
                                    onClick={() => handleAddTimeSlot(day.day)}
                                  >
                                    <AiOutlinePlus
                                      style={{
                                        fontSize: "1.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Col>

                                {/* Delete Icon */}
                                <Col span={2}>
                                  <div
                                    style={{
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                      marginTop: "5px",
                                    }}
                                    onClick={() =>
                                      handleDeleteTimeSlot(day.day, j)
                                    }
                                  >
                                    <AiOutlineDelete
                                      style={{
                                        fontSize: "1.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Col>
                              </>
                            </Row>
                          );
                        })}
                    </div>
                  );
                })}
              </Col>
            </Row>

            <Divider />

            <Row style={{ marginTop: "-25px" }}>
              <h5 className="hp-mb-12 hp-mt-8">
                {t(
                  "flowBuilder.sidebar.editor.logicalNodes.excludeSpecificDates"
                )}
              </h5>
            </Row>

            <Row style={{ marginTop: "2px" }}>
              <Col span={24}>
                <DatePicker
                  multiple
                  format="DD-MM-YYYY"
                  value={excludeDates}
                  onChange={(dateObject) => {
                    let dateArray;
                    if (Array.isArray(dateObject)) {
                      dateArray = dateObject.map((date: any) => {
                        return date.format("DD-MM-YYYY");
                      });
                    } else {
                      dateArray = [dateObject.format("DD-MM-YYYY")];
                    }
                    handleExcludeDatesChange(dateArray);
                  }}
                  mapDays={({ date }) => {
                    if (excludeDays.includes(daysOfWeek[date.weekDay.index])) {
                      return {
                        disabled: true,
                        style: { color: "#ccc" },
                        onClick: () =>
                          message.info(
                            t(
                              "flowBuilder.sidebar.editor.logicalNodes.notAvailable"
                            )
                          ),
                      };
                    }
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Col>
    </>
  );
};

export default BusinessHoursNodeDataEditor;
