import { Link, useLocation } from "react-router-dom";

import { Col, Avatar, Badge, Menu } from "antd";
import { User, Discount, Setting, Password } from "react-iconly";

import menuImg from "@assets/images/pages/profile/menu-img.svg";
import avatar from "@assets/images/memoji/user1.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MenuProfile = (props) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const { t } = useTranslation();

  const menuIconClass = "remix-icon hp-mr-8";

  const menuFooterItem = () => {
    if (props.footer !== "none") {
      return (
        <div className="hp-profile-menu-footer">
          <img src={menuImg} alt="Profile" />
        </div>
      );
    }
  };

  const moreBtn = () => {
    if (props.moreBtnCheck !== "none") {
      return (
        <Col className="hp-menu-header-btn hp-pr-16 hp-mb-12 hp-text-right">
          {props.moreBtn()}
        </Col>
      );
    }
  };

  const LanguageIcon = () => (
    <div style={{ marginRight: "6px", marginTop: "3px" }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={menuIconClass}
      >
        <g
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M4 21V8a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H8z" />
          <path d="M10 14v-4a2 2 0 1 1 4 0v4m0-2h-4" />
        </g>
      </svg>
    </div>
  );

  return (
    <Col
      className="hp-profile-menu hp-py-24"
      style={{
        position: "fixed",
        top: 0,
        width: "320px",
      }}
    >
      <div className="hp-w-100">
        <div className="hp-profile-menu-header hp-mt-md-16 hp-text-center">
          {moreBtn()}

          <Badge>
            <Avatar size={80} src={avatar} />
          </Badge>

          <h3 className="hp-mt-24 hp-mb-4">{user?.name}</h3>
          <a href={`mailto:${user?.email}`} className="hp-p1-body">
            {user?.email}
          </a>
        </div>

        <Menu
          mode="inline"
          className="hp-w-100 hp-profile-menu-body custom-scroll-bar"
          theme={"light"}
          style={{
            overflowY: "auto",
            height: "calc(100vh - 290px)",
            overflowX: "hidden",
          }}
        >
          {/* Personal Information */}
          <Menu.Item
            key="1"
            icon={<User set="curved" className={menuIconClass} />}
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${
                splitLocation[splitLocation.length - 1] === "information"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            `}
            onClick={props.onCloseDrawer}
          >
            <Link to="/profile/information">
              {t("profile.plans.personalInformation")}
            </Link>
          </Menu.Item>

          {/* Plans & Pricing */}
          {(user?.role === "admin" || user?.role === "superadmin") && (
            <Menu.Item
              key="2"
              icon={
                <svg
                  className={menuIconClass}
                  style={{
                    height: "24px",
                    width: "24px",
                  }}
                  viewBox="0 0 20 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#636E71"
                    d="M19.25 0h-6c-.412 0-.989.239-1.28.53L4.531 7.969a.752.752 0 0 0 0 1.061l6.439 6.439a.752.752 0 0 0 1.061 0L19.47 8.03c.292-.292.53-.868.53-1.28v-6a.752.752 0 0 0-.75-.75M15.5 6a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 15.5 6"
                  />
                  <path
                    fill="#636E71"
                    d="M2 8.5L10.5 0H9.25c-.412 0-.989.239-1.28.53L.531 7.969a.752.752 0 0 0 0 1.061l6.439 6.439a.752.752 0 0 0 1.061 0l.47-.47z"
                  />
                </svg>
              }
              className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${
                splitLocation[splitLocation.length - 1] === "plans"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            `}
              onClick={props.onCloseDrawer}
            >
              <Link to="/profile/plans">
                {t("profile.plans.plans&Pricing")}
              </Link>
            </Menu.Item>
          )}

          {/* Subscription */}
          {user.role === "admin" && (
            <Menu.Item
              key="3"
              icon={<Discount set="curved" className={menuIconClass} />}
              className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${
                splitLocation[splitLocation.length - 1] === "subscription"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            `}
              onClick={props.onCloseDrawer}
            >
              <Link to="/profile/subscription">
                {t("profile.plans.mySubscription")}
              </Link>
            </Menu.Item>
          )}

          {/* Language */}
          {user.role === "admin" && (
            <Menu.Item
              key="4"
              icon={<LanguageIcon />}
              className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${
                splitLocation[splitLocation.length - 1] === "language"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            `}
              onClick={props.onCloseDrawer}
            >
              <Link to="/profile/language">
                {t("profile.plans.changeLanguage")}
              </Link>
            </Menu.Item>
          )}

          {/* Security */}
          <Menu.Item
            key="5"
            icon={<Setting set="curved" className={menuIconClass} />}
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${
                splitLocation[splitLocation.length - 1] === "security"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            `}
            onClick={props.onCloseDrawer}
          >
            <Link to="/profile/security">
              {t("profile.plans.securitySettings")}
            </Link>
          </Menu.Item>

          {/* Password Change */}
          <Menu.Item
            key="6"
            icon={<Password set="curved" className={menuIconClass} />}
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${
                splitLocation[splitLocation.length - 1] === "password"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            `}
            onClick={props.onCloseDrawer}
          >
            <Link to="/profile/password">
              {t("profile.plans.changePassword")}
            </Link>
          </Menu.Item>
        </Menu>
      </div>

      {menuFooterItem()}
    </Col>
  );
};

export default MenuProfile;
