import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { ReactFlowProvider } from "reactflow";
import { useParams } from "react-router";
// Redux Action
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminChatbotAction,
  getSuperAdminChatbotAction,
  toggleNodesSidebarAction,
} from "../../../../redux/actions/chatbotActions";
import { updateTutorialStatusAction } from "../../../../redux/actions/adminActions";
// DnD
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
// Builder components
import FlowBuilder from "./flowBuilder";
import Sidebar from "./sidebar";
import Header from "./header";
// Guide Tour
import { Tour } from "../../../../components";
import { chatbotBuilderJoyrideSteps } from "../../../../helpers/shepherd/chatbotBuilderSteps";
import SliderIcon from "../../../../assets/images/pages/builder/slider.png";
import "../../../../assets/css/ChatBuilder.css";

const ChatbotFlowBuilder = () => {
  const dispatch = useDispatch();
  const { bid } = useParams<{ bid: string }>();
  const { user } = useSelector((state: any) => state.auth);
  const { currentChatbot, sidebarCollapsed } = useSelector(
    (state: any) => state?.chatbot ?? {}
  );
  const [loading, setLoading] = useState(true);
  const [chatBuilderTour, setChatBuilderTour] = useState(
    user?.tutorials_complete?.chatbotBuilderFlowTut ||
      JSON.parse(localStorage.getItem("shepherd"))?.chatbotBuilderFlowTut
  );

  useEffect(() => {
    // Set loading to true
    setLoading(true);

    // If user role is admin
    if (user?.role === "admin") {
      // Dispatch action to set current chatbot
      dispatch(getAdminChatbotAction(bid, setLoading));
    } else if (user?.role === "superadmin" || user?.role === "marketing") {
      // Dispatch action to set current chatbot
      dispatch(getSuperAdminChatbotAction(bid, setLoading));
    }
  }, [dispatch, bid, user?.role]);

  useEffect(() => {
    const shepherdData = JSON.parse(localStorage.getItem("shepherd"));
    if (shepherdData) {
      // Check each tutorial status and update the server if it's not already updated
      if (!user?.tutorials_complete?.chatbotBuilderFlowTut && chatBuilderTour) {
        dispatch(updateTutorialStatusAction(shepherdData));
      }
    }
  }, [
    chatBuilderTour,
    dispatch,
    user?.tutorials_complete?.chatbotBuilderFlowTut,
  ]);

  // Handle Sidebar collapse
  const toggleCollapsed = () => {
    // Dispatch action to toggle sidebar
    dispatch(toggleNodesSidebarAction(!sidebarCollapsed));
  };

  // Handle Sidebar Guide Tour finish
  const handleSidebarTourComplete = (_: string, completionStatus: boolean) => {
    if (completionStatus) {
      // Set setChatBuilderTour status to true
      setChatBuilderTour(true);

      // Update LocalStorage guideTour status
      const existingData = localStorage.getItem("shepherd");
      const parsedData = JSON.parse(existingData);

      const newData = {
        chatbotBuilderFlowTut: true,
      };

      const updatedData = { ...parsedData, ...newData };

      localStorage.setItem("shepherd", JSON.stringify(updatedData));

      // Update server with tutorial status
      dispatch(updateTutorialStatusAction(updatedData));
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {loading ? (
        <div className="builder-dual-ring"></div>
      ) : (
        <>
          {!chatBuilderTour && !loading && (
            <Tour
              steps={chatbotBuilderJoyrideSteps}
              tutComplete={chatBuilderTour}
              tourId="ChatBuilderFlowTour"
              handleTourComplete={handleSidebarTourComplete}
            />
          )}
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
            id="react-flow-provider"
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "6px",
                overflow: "hidden",
              }}
              id="joyride-chatbot-builder-intro"
            >
              <DndProvider backend={HTML5Backend}>
                <ReactFlowProvider>
                  {/* Header */}
                  <Header isBuilder={true} />

                  {!currentChatbot ? (
                    <div className="builder-dual-ring"></div>
                  ) : (
                    <Row>
                      <Col
                        span={24}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        style={{
                          transition: "all 0.2s ease-out",
                          opacity: sidebarCollapsed ? 0 : 1,
                        }}
                        id="nodes-panel"
                      >
                        <Sidebar collapsed={sidebarCollapsed} />
                      </Col>

                      <Col
                        span={sidebarCollapsed ? 24 : 19}
                        sm={sidebarCollapsed ? 24 : 19}
                        md={sidebarCollapsed ? 24 : 19}
                        lg={sidebarCollapsed ? 24 : 19}
                        xl={sidebarCollapsed ? 24 : 19}
                        style={{
                          height: "92%",
                          width: "100%",
                          position: "absolute",
                          right: 0,
                          top: "60px",
                          background: "#f0f2f8",
                          transition: "all 0.2s ease-out",
                          zIndex: 90,
                          overflow: "hidden",
                        }}
                      >
                        {/* Minimize/Maximize Sidebar Icon */}
                        <div
                          onClick={toggleCollapsed}
                          className="sidebar__sider"
                        >
                          <img
                            src={SliderIcon}
                            className="sidebar-sider__Img"
                            style={{
                              transform: sidebarCollapsed
                                ? "rotate(180deg)"
                                : "",
                            }}
                            alt="slider-icon"
                          />
                        </div>

                        {/* Flow Builder */}
                        <FlowBuilder />
                      </Col>
                    </Row>
                  )}
                </ReactFlowProvider>
              </DndProvider>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatbotFlowBuilder;
