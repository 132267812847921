import { CUSTOMIZE_TYPES } from "../constants/customizeTypes";
import themeConfig from "../../configs/themeConfig";

// Define the type for your state
interface CustomizeState {
  theme: string; // Update the types based on actual data types used
  contentWidth: string;
  sidebarCollapsed: boolean;
  sidebarCollapseButton: boolean;
  layout: string;
  navigationFull: boolean;
  navigationBg: boolean;
  direction: string;
}

// Define the type for your action
interface CustomizeAction {
  type: string;
  payload: any; // You can replace 'any' with a more specific type if needed
}

// Set the initial state with the type
const INITIAL_STATE: CustomizeState = {
  theme: themeConfig.theme,
  contentWidth: themeConfig.contentWidth,
  sidebarCollapsed: themeConfig.sidebarCollapsed,
  sidebarCollapseButton: themeConfig.sidebarCollapseButton,
  layout: themeConfig.layout,
  navigationFull: themeConfig.navigationFull,
  navigationBg: themeConfig.navigationBg,
  direction: themeConfig.direction,
};

// Update the reducer function with types
const customizeReducer = (
  state = INITIAL_STATE,
  action: CustomizeAction
): CustomizeState => {
  switch (action.type) {
    case CUSTOMIZE_TYPES.THEME:
      return {
        ...state,
        theme: action.payload,
      };

    case CUSTOMIZE_TYPES.CONTENT_WIDTH:
      return {
        ...state,
        contentWidth: action.payload,
      };

    case CUSTOMIZE_TYPES.SIDEBAR_COLLAPSED:
      return {
        ...state,
        sidebarCollapsed: action.payload,
      };

    case CUSTOMIZE_TYPES.SIDEBAR_COLLAPSE_BUTTON:
      return {
        ...state,
        sidebarCollapseButton: action.payload,
      };

    case CUSTOMIZE_TYPES.LAYOUT_CHANGE:
      return {
        ...state,
        layout: action.payload,
      };

    case CUSTOMIZE_TYPES.NAVIGATION_FULL:
      return {
        ...state,
        navigationFull: action.payload,
      };

    case CUSTOMIZE_TYPES.NAVIGATION_BG:
      return {
        ...state,
        navigationBg: action.payload,
      };

    case CUSTOMIZE_TYPES.DIRECTION:
      return {
        ...state,
        direction: action.payload,
      };

    default:
      return state;
  }
};

export default customizeReducer;
