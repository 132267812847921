import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Upload,
  message,
  Select,
  Spin,
} from "antd";
import { RiLoaderLine, RiAddLine, RiArrowGoBackFill } from "react-icons/ri";
import {
  getKnowledgeBaseArticleById,
  updateKnowledgeBaseArticleById,
  uploadKnowledgeBaseArticleCoverImage,
} from "../../../api/knowledge-base/knowledgeBase";
import { withRouter } from "react-router";
import { SuccessNotification, ErrorNotification } from "../..";
import JoditEditor from "jodit-react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const KnowledgeBaseArticleUpdateForm = ({
  chatbots,
  articleCategories,
  fetchArticles,
}) => {
  // Local state
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const editorRef = useRef(null);
  const history = useHistory();
  const { articleId } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    fetchKnowledgeBaseArticleById();
  }, []);

  // Fetch knowledge base article by ID
  const fetchKnowledgeBaseArticleById = async () => {
    setLoading(true);
    try {
      const res = await getKnowledgeBaseArticleById(articleId);
      setValues(res.data);
      setLoading(false);
    } catch (error) {
      console.log("FETCH_KNOWLEDGE_BASE_ARTICLE_ERROR", error);
    }
  };

  // Handle form changes
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Content field change
  const contentFieldChanged = (data) => {
    setValues({
      ...values,
      content: data,
    });
  };

  // Handle chatbot select
  const handleChatbotSelect = (value) => {
    setValues({
      ...values,
      chatbotId: value,
    });
  };

  // Function to filter chatbots based on input
  const filterChatbots = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate the form
    if (
      !values.title ||
      !values.category ||
      !values.chatbotId ||
      !values.content
    ) {
      return ErrorNotification("Please fill all the required fields");
    }

    setLoading(true);
    updateKnowledgeBaseArticleById(articleId, values)
      .then(() => {
        setLoading(false);
        SuccessNotification(`Knowledge base article updated successfully`);
        fetchArticles();
        history.goBack();
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response.data.message);
        console.log(
          "UPDATE_KNOWLEDGE_BASE_ARTICLE_ERROR",
          err.response.data.message
        );
      });
  };

  // Before image upload
  const beforeCoverUpload = (file) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Image must smaller than 3MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // Handle cover image upload
  const handleUploadCoverImage = async (options) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload image
    try {
      setLoading(true);
      const res = await uploadKnowledgeBaseArticleCoverImage(fmData, config);
      setValues({
        ...values,
        coverImage: res.data.url,
      });
      setLoading(false);
    } catch (error) {
      onError(error);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>{t("knowledgeBase.upload")}</div>
    </div>
  );

  // Twitter SVG
  const twitterSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 432 432">
      <path fill="currentColor" d="M335 159q22-18 28-30q-13 6-31 9q18-13 24-32q-20 11-37 14q-12-14-31-16.5t-35.5 5t-26.5 25t-5 38.5q-67-4-118-59q-11 20-4.5 43.5T120 189q-11-1-24-7q1 43 44 57q-12 3-24 1q12 36 53 40q-15 13-39 19.5T85 303q45 28 92 26q70-3 113.5-49.5T335 159zM384 3q18 0 30.5 12.5T427 45v342q0 17-12.5 29.5T384 429H43q-18 0-30.5-12.5T0 387V45q0-17 12.5-29.5T43 3h341z"/>
    </svg>`;

  // Config for Jodit Editor
  const joditConfig = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      height: "92vh",
      minHeight: "100%", // Set minimum height for the editor
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
      buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "font",
        "fontsize",
        "|",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "superscript",
        "subscript",
        "|",
        "brush",
        "paragraph",
        "|",
        "align",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "undo",
        "redo",
        "|",
        "cut",
        "copy",
        "paste",
        "selectall",
        "|",
        "link",
        "unlink",
        "|",
        "image",
        "video",
        {
          name: "Twitter",
          icon: twitterSvg,
          tooltip: "Insert Twitter",
          exec: (editor) => {
            const twitterURL = prompt("Enter Your Twitter Embed Code:"); // Prompt user for Twitter URL

            // Check if URL is provided and not empty
            if (twitterURL && twitterURL.trim() !== "") {
              // Used for embed twiter in jodit
              const script = document.createElement("script");
              script.src = "https://platform.twitter.com/widgets.js";
              script.async = true;
              document.body.appendChild(script);
              // Insert Twitter embed HTML into the editor
              editor.s.insertHTML(
                `<blockquote class="twitter-tweet" style="width: 100%; height: 100%;">${twitterURL}</blockquote>`
              );
              return () => {
                document.body.removeChild(script);
              };
            }
          },
        },
        "|",
        "table",
        "hr",
        "|",
        "symbol",
        "eraser",
        "|",
        "print",
        "fullsize",
        "about",
        "spellcheck",
        "|",
        "preview",
        "print",
        "about",
      ],
    }),
    []
  );

  // Used for embed twitter in jodit
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Row>
      <h3>
        <RiArrowGoBackFill
          style={{
            fontSize: "22px",
            cursor: "pointer",
            marginRight: "10px",
            marginBottom: "-3px",
          }}
          onClick={() => history.goBack()}
        />
        {t("knowledgeBase.updateKbArticle")}
      </h3>

      <Col
        span={24}
        style={{
          overflowY: "auto",
          height: "calc(100vh - 55px)",
          padding: "50px 0px",
        }}
      >
        {loading ? (
          <Spin
            spinning={loading}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 100px)",
              widdth: "100%",
            }}
          />
        ) : (
          <Form
            labelCol={{
              span: 3,
            }}
            wrapperCol={{
              span: 18,
            }}
          >
            {/* Knowledge Base Article Title */}
            <Form.Item label={t("knowledgeBase.title")}>
              <Input
                name="title"
                placeholder={t("knowledgeBase.titlePH")}
                onChange={handleFormChange}
                value={values.title}
                rules={[{ required: true }]}
              />
            </Form.Item>

            {/* Knowledge Base Article cover image */}
            <Form.Item label={t("knowledgeBase.coverImage")}>
              <Upload
                name="coverImage"
                listType="picture-card"
                customRequest={handleUploadCoverImage}
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeCoverUpload}
                multiple={false}
                accept="image/*,image/gif"
              >
                {values.coverImage ? (
                  <img
                    src={values.coverImage}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            {/* SEO meta description */}
            <Form.Item label={t("knowledgeBase.metaDescription")}>
              <Input
                name="description"
                placeholder={t("knowledgeBase.metaDescriptionPH")}
                onChange={handleFormChange}
                value={values.description}
                rules={[{ required: true }]}
              />
            </Form.Item>

            {/* Select drop down with category */}
            <Form.Item label={t("knowledgeBase.category")}>
              <Select
                name="category"
                placeholder={t("knowledgeBase.categoryPH")}
                onChange={(value) => setValues({ ...values, category: value })}
                value={values?.category?.name ?? values?.category}
                rules={[{ required: true }]}
              >
                {articleCategories?.map((category) => (
                  <Select.Option key={category._id} value={category._id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* Select drop down for chatbots list */}
            <Form.Item label={t("knowledgeBase.chatbot")}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={t("knowledgeBase.chatbotPH")}
                optionFilterProp="children"
                onChange={handleChatbotSelect}
                filterOption={filterChatbots}
                value={values?.chatbotId?._id}
              >
                {chatbots.map((chatbot) => (
                  <Option key={chatbot._id} value={chatbot._id}>
                    {chatbot.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Publish date */}
            <Form.Item label={t("knowledgeBase.publishedDate")}>
              <DatePicker
                className="hp-mb-16 hp-mr-16"
                value={
                  values.publishedDate ? moment(values.publishedDate) : null
                }
                onChange={(date, dateString) =>
                  setValues({ ...values, publishedDate: dateString })
                }
              />
            </Form.Item>

            {/* Knowledge Base Article Content */}
            <Form.Item label={t("knowledgeBase.content")}>
              <Col
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <JoditEditor
                  ref={editorRef}
                  value={values?.content}
                  config={joditConfig}
                  onBlur={(newContent) => contentFieldChanged(newContent)}
                  onChange={(newContent) => {}}
                />
              </Col>
            </Form.Item>

            {/* Update button */}
            <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
              <Button
                type="primary"
                onClick={handleFormSubmit}
                loading={loading}
              >
                {t("knowledgeBase.update")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default withRouter(KnowledgeBaseArticleUpdateForm);
