import { Controls, useReactFlow } from "reactflow";
import "./customControls.css";

const CustomControls = () => {
  const { zoomIn, zoomOut } = useReactFlow();

  return (
    <Controls
      className="custom-controls"
      onZoomIn={() => zoomIn({ duration: 800 })}
      onZoomOut={() => zoomOut({ duration: 800 })}
    />
  );
};

export default CustomControls;
