import React, { useState } from "react";
import { Divider, Row, Col, Tooltip, Popover, Modal } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RiSettings3Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { showNotificationAction } from "../../../../redux/actions/chatbotActions";

import HeaderUser from "../../header/HeaderUser";
import Notification from "./Notification";
import Changelog from "./Changelog";
import "../../../../assets/css/Notifications.css";

const MenuFooter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { showNotification } = useSelector((state) => state.chatbot);
  const [changelogModalVisible, setChangelogModalVisible] = useState(false);

  // Popover visibility
  const [popoverVisible, setPopoverVisible] = useState(false);

  // Determine the correct link path based on user role
  const linkPath =
    user?.role === "superadmin"
      ? "/profile/plans"
      : user?.role === "marketing"
      ? "/profile/plans"
      : "/profile/subscription";

  // Handle open change
  const handleOpenChange = (newOpen) => {
    setPopoverVisible(newOpen);

    // Dispatch action to hide notification
    dispatch(showNotificationAction(false));
  };

  // Handle close changelog modal
  const handleCloseChangelogModal = () => {
    setChangelogModalVisible(false);
  };

  // Custom style for Divider with box shadow
  const dividerStyle = {
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    width: "100%",
  };

  return props.collapsed === false ? (
    <Row
      className="hp-sidebar-footer hp-bg-color-dark-90"
      align="middle"
      justify="space-between"
    >
      <Divider
        className="hp-border-color-black-40 hp-border-color-dark-100 hp-mt-0"
        style={dividerStyle}
      />

      {/* User avatar */}
      <Col>
        {/* Notification */}
        <Popover
          content={<Notification popoverVisible={popoverVisible} />}
          placement="rightTop"
          trigger="click"
          overlayClassName="custom-popover"
          onOpenChange={handleOpenChange}
        >
          <Col
            className={`${showNotification ? "bell-pulse" : ""}`}
            style={{
              marginBottom: "23px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {showNotification && (
              <span
                style={{
                  position: "absolute",
                  top: "-5px", // Adjusted for better visibility
                  right: "-5px", // Adjusted for better visibility
                  background: "red",
                  borderRadius: "50%",
                  width: "20px", // Increased size for readability
                  height: "20px", // Increased size for readability
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "12px", // Adjust font size as needed
                  border: "2px solid white",
                }}
              >
                1+
              </span>
            )}
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#0b4eb8"
                d="M10.146 3.248a2 2 0 0 1 3.708 0A7.003 7.003 0 0 1 19 10v4.697l1.832 2.748A1 1 0 0 1 20 19h-4.535a3.501 3.501 0 0 1-6.93 0H4a1 1 0 0 1-.832-1.555L5 14.697V10c0-3.224 2.18-5.94 5.146-6.752M10.586 19a1.5 1 0 0 0 2.829 0zM12 5a5 5 0 0 0-5 5v5a1 1 0 0 1-.168.555L5.869 17H18.13l-.963-1.445A1 1 0 0 1 17 15v-5a5 5 0 0 0-5-5"
              />
            </svg>

            <span
              className="hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body"
              style={{ marginLeft: "24px" }}
            >
              {t("navigation.footer.notification")}
            </span>
          </Col>
        </Popover>

        {/* Changelog */}
        {user?.role === "admin" && (
          <>
            <Col
              style={{
                marginBottom: "23px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => setChangelogModalVisible(!changelogModalVisible)}
            >
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#0b4eb8"
                  d="M4.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h4.47l-.25 1H4.5A2.5 2.5 0 0 1 2 11.5v-7A2.5 2.5 0 0 1 4.5 2h7A2.5 2.5 0 0 1 14 4.5V5h-1v-.5A1.5 1.5 0 0 0 11.5 3H10v2.119a1.5 1.5 0 0 0-.8.804l-.2.48V3H4.5Zm2.587 9a1.48 1.48 0 0 1 0-1H4.5a.5.5 0 0 0 0 1h2.587Zm.415-2l.354-.851A.498.498 0 0 0 7.5 9h-3a.5.5 0 0 0 0 1h3.002ZM4 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-3ZM5 5v2h2V5H5Zm3.502 7h1.75l-.59 2.36c-.12.482.462.826.826.486l4.873-4.548A.75.75 0 0 0 14.849 9h-1.097l.78-2.342A.5.5 0 0 0 14.059 6h-3.473a.5.5 0 0 0-.461.308l-2.084 5a.5.5 0 0 0 .462.692Z"
                />
              </svg>
              <span
                className="hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body"
                style={{ marginLeft: "24px" }}
              >
                {t("navigation.footer.whatsNew")}
              </span>
            </Col>

            {changelogModalVisible && (
              <Modal
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 600,
                      fontSize: "1.25rem",
                    }}
                  >
                    {t("navigation.footer.whatsNew")} 🎉✨🚀
                  </div>
                }
                visible={changelogModalVisible}
                onCancel={handleCloseChangelogModal}
                footer={null}
                width={800}
                style={{ top: 20 }}
                bodyStyle={{ padding: 10 }}
              >
                <Changelog />
              </Modal>
            )}
          </>
        )}

        {/* Subscription */}
        {user?.role === "admin" && (
          <Link to={linkPath}>
            <Col
              style={{
                marginBottom: "23px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#0b4eb8">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                  <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                </g>
              </svg>
              <span
                className="hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body"
                style={{ marginLeft: "24px" }}
              >
                {t("navigation.footer.subscription")}
              </span>
            </Col>
          </Link>
        )}

        {/* View Profile */}
        <Row align="middle" style={{ marginLeft: "-6px" }}>
          <HeaderUser placement={"topRight"} />

          <div className="hp-mt-6" style={{ marginLeft: "10px" }}>
            <span className="hp-d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body">
              {user?.name}
            </span>

            <Link
              to="/profile/information"
              className="hp-badge-text hp-text-color-dark-30 hp-font-weight-400"
              onClick={props.onClose}
            >
              {t("navigation.footer.viewProfile")}
            </Link>
          </div>
        </Row>
      </Col>

      <Col>
        <Link to="/profile/security" onClick={props.onClose}>
          <RiSettings3Line
            className="remix-icon hp-text-color-black-100 hp-text-color-dark-0"
            size={24}
            style={
              (props.mobileMenu ? { marginRight: "12px" } : null,
              { marginTop: "60px" })
            }
          />
        </Link>
      </Col>
    </Row>
  ) : (
    <Row
      className="hp-sidebar-footer hp-bg-color-dark-90"
      align="middle"
      justify="center"
      style={{ flexDirection: "column" }}
    >
      <Divider
        className="hp-border-color-black-40 hp-border-color-dark-100 hp-mt-0"
        style={dividerStyle}
      />

      {/* Notification */}
      {/* Don't show to superadmin and marketing */}
      {user?.role !== "superadmin" && user?.role !== "marketing" && (
        <Popover
          content={<Notification popoverVisible={popoverVisible} />}
          placement="rightTop"
          trigger="click"
          overlayClassName="custom-popover"
          onOpenChange={handleOpenChange}
        >
          <Col
            className={`${showNotification ? "bell-pulse" : ""}`}
            style={{
              marginBottom: "23px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#0b4eb8"
                d="M10.146 3.248a2 2 0 0 1 3.708 0A7.003 7.003 0 0 1 19 10v4.697l1.832 2.748A1 1 0 0 1 20 19h-4.535a3.501 3.501 0 0 1-6.93 0H4a1 1 0 0 1-.832-1.555L5 14.697V10c0-3.224 2.18-5.94 5.146-6.752M10.586 19a1.5 1 0 0 0 2.829 0zM12 5a5 5 0 0 0-5 5v5a1 1 0 0 1-.168.555L5.869 17H18.13l-.963-1.445A1 1 0 0 1 17 15v-5a5 5 0 0 0-5-5"
              />
            </svg>
            {showNotification && (
              <span
                style={{
                  position: "absolute",
                  top: "-5px", // Adjusted for better visibility
                  right: "-5px", // Adjusted for better visibility
                  background: "red",
                  borderRadius: "50%",
                  width: "20px", // Increased size for readability
                  height: "20px", // Increased size for readability
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "12px", // Adjust font size as needed
                  border: "2px solid white",
                }}
              >
                1+
              </span>
            )}
          </Col>
        </Popover>
      )}

      {/* Changelog */}
      {user?.role === "admin" && (
        <>
          <Col
            style={{ marginBottom: "23px", cursor: "pointer" }}
            onClick={() => setChangelogModalVisible(!changelogModalVisible)}
          >
            <Tooltip title={t("navigation.footer.whatsNew")} placement="right">
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#0b4eb8"
                  d="M4.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h4.47l-.25 1H4.5A2.5 2.5 0 0 1 2 11.5v-7A2.5 2.5 0 0 1 4.5 2h7A2.5 2.5 0 0 1 14 4.5V5h-1v-.5A1.5 1.5 0 0 0 11.5 3H10v2.119a1.5 1.5 0 0 0-.8.804l-.2.48V3H4.5Zm2.587 9a1.48 1.48 0 0 1 0-1H4.5a.5.5 0 0 0 0 1h2.587Zm.415-2l.354-.851A.498.498 0 0 0 7.5 9h-3a.5.5 0 0 0 0 1h3.002ZM4 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-3ZM5 5v2h2V5H5Zm3.502 7h1.75l-.59 2.36c-.12.482.462.826.826.486l4.873-4.548A.75.75 0 0 0 14.849 9h-1.097l.78-2.342A.5.5 0 0 0 14.059 6h-3.473a.5.5 0 0 0-.461.308l-2.084 5a.5.5 0 0 0 .462.692Z"
                />
              </svg>
            </Tooltip>
          </Col>
          {changelogModalVisible && (
            <Modal
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: 600,
                    fontSize: "1.25rem",
                  }}
                >
                  {t("navigation.footer.whatsNew")} 🎉✨🚀
                </div>
              }
              visible={changelogModalVisible}
              onCancel={handleCloseChangelogModal}
              footer={null}
              width={800}
              style={{ top: 20 }}
              bodyStyle={{ padding: 10 }}
            >
              <Changelog />
            </Modal>
          )}
        </>
      )}

      {/* Subscription */}
      {user?.role === "admin" && (
        <Link to={linkPath}>
          <Col style={{ marginBottom: "23px", cursor: "pointer" }}>
            <Tooltip
              title={t("navigation.footer.subscription")}
              placement="right"
            >
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#0b4eb8">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                  <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                </g>
              </svg>
            </Tooltip>
          </Col>
        </Link>
      )}

      {/* Header User Logout */}
      <Col>
        <HeaderUser placement={"right"} />
      </Col>
    </Row>
  );
};

export default MenuFooter;
