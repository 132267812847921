import { useEffect, useRef, useState } from "react";
import { ComparePlansData } from "./ComparePlansData";
import CheckIcon from "@assets/images/components/available.png";
import "@assets/css/ComparePlans.css";
import { useTranslation } from "react-i18next";

const ComparePlans = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  // ref for table
  const tableHeaderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const header = tableHeaderRef.current;
      if (header) {
        const rect = header.getBoundingClientRect();
        setIsSticky(rect.top <= 150);
        setActive(false);
        if (rect.top <= -4250) {
          setActive(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        maxWidth: "900px",
        margin: "0 auto",
      }}
    >
      {/* Compare Plans */}
      <div className="compare_plans_main container">
        <div>
          <h2 className="heading">{t("profile.plans.comparePlans")} </h2>
        </div>

        <div className="compare_plan_table_main" id="scrollableElement">
          <div
            className={`header_wrapper ${isSticky ? "sticky" : ""}`}
            style={{ opacity: active ? 0 : 1 }}
          >
            <div className="plan_table_header">
              <ul>
                <li></li>
                <li>Free</li>
                <li>Starter</li>
                <li>
                  <span>most popular</span>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#fff",
                    }}
                  >
                    Pro
                  </span>
                </li>
                <li>{t("profile.plans.business")}</li>
              </ul>
            </div>
          </div>

          <div className="plan_table_body" ref={tableHeaderRef}>
            {ComparePlansData?.map((item, ind) => {
              return (
                <div key={ind}>
                  <h4 class="table_body_heading">{item?.title}</h4>
                  <div className="table_body_content">
                    <ul>
                      {item?.plans.map((plan, index) => {
                        return (
                          <li key={index}>
                            <span>{plan?.title}</span>
                            <span style={{ textAlign: "center" }}>
                              {plan?.free === "available" ? (
                                <img src={CheckIcon} />
                              ) : (
                                plan?.free
                              )}
                            </span>
                            <span style={{ textAlign: "center" }}>
                              {plan?.starter === "available" ? (
                                <img src={CheckIcon} />
                              ) : (
                                plan?.starter
                              )}
                            </span>
                            <span style={{ textAlign: "center" }}>
                              {plan?.pro === "available" ? (
                                <img src={CheckIcon} />
                              ) : (
                                plan?.pro
                              )}
                            </span>
                            <span style={{ textAlign: "center" }}>
                              {plan?.business === "available" ? (
                                <img src={CheckIcon} />
                              ) : (
                                plan?.business
                              )}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePlans;
