import { Card, Col, Skeleton } from "antd";

const TemplateCardSkeletonTwo = () => (
  <Col
    xs={24}
    sm={12}
    md={8}
    lg={6}
    xl={6}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      marginBottom: "20px",
      height: "420px",
      borderRadius: "16px",
      width: "100%",
      padding: "10px",
    }}
  >
    <Card
      hoverable
      style={{
        height: "400px",
        width: "100%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "14px",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          padding: "70px 0px",
          backgroundColor: "#0000000f",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
      <div style={{ marginTop: "115px" }}>
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "20px",
          alignSelf: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Skeleton.Button shape="circle" active />
        <Skeleton.Button shape="circle" active />
      </div>
      <Skeleton active paragraph={{ rows: 2 }} />
    </Card>
  </Col>
);
export default TemplateCardSkeletonTwo;
