import React, { useState } from "react";
import { Row, Col, Form, Button, Input } from "antd";
import LeftContent from "./leftContent";
import { Link, useLocation } from "react-router-dom";
import { acceptTeamInvite } from "@api/user/admin";
import { SuccessNotification, ErrorNotification } from "@components";
import Styles from "@assets/css/AuthCommon.Module.css";
import logoText from "@assets/images/logo/logoText.png";

const initialData = {
  name: "",
  password: "",
  confirmPassword: "",
};

const TeamInviteAccept = () => {
  const [values, setValues] = useState(initialData);
  const [success, setSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  // Handle form change
  const handleFormChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    const { name, password, confirmPassword } = values;

    // Validate all fields
    if (!name || !password || !confirmPassword) {
      ErrorNotification("Please fill all fields");
      setSubmitLoading(false);
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      ErrorNotification("Passwords don't match");
      setSubmitLoading(false);
      return;
    } else {
      acceptTeamInvite({ token, name, password })
        .then(() => {
          // Success
          SuccessNotification("Successful! Log in to continue");
          setSubmitLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          // Error
          ErrorNotification(err.response.data.message);
          setSubmitLoading(false);
          console.log("TEAM_ACCEPT_ERROR", err);
        });
    }
  };

  return (
    <>
      <Row
        gutter={[32, 0]}
        className={`hp-authentication-page ${Styles.centerContent}`}
        style={{ overflowY: "auto", height: "100vh" }}
      >
        <LeftContent />

        <Col
          lg={8}
          md={16}
          sm={16}
          span={24}
          className="hp-py-sm-0 hp-py-md-0"
          justify="center"
        >
          <Row className="hp-h-100" align="middle" justify="center">
            <Col
              xxl={11}
              xl={15}
              lg={20}
              md={16}
              sm={24}
              className="hp-px-sm-8 hp-pt-24 hp-pb-48"
            >
              <>
                $
                {!success && (
                  <>
                    <img
                      src={logoText}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />

                    <h2
                      style={{
                        textAlign: "center",
                        fontSize: "24px",
                        color: "#000000",
                      }}
                    >
                      Join Team
                    </h2>
                  </>
                )}
              </>

              {!success ? (
                <>
                  <Form
                    layout="vertical"
                    name="basic"
                    className="hp-mt-sm-16 hp-mt-32"
                  >
                    {/* Name */}
                    <Form.Item
                      className="hp-mb-16"
                      rules={[
                        { required: true, message: "Please enter your name" },
                      ]}
                    >
                      <Input
                        id="validating"
                        name="name"
                        placeholder="Enter your name"
                        value={values.name}
                        onChange={handleFormChange}
                      />
                    </Form.Item>

                    {/* Password */}
                    <Form.Item
                      className="hp-mb-16"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password",
                        },
                      ]}
                    >
                      <Input.Password
                        id="warning2"
                        name="password"
                        value={values.password}
                        placeholder="Enter password"
                        onChange={handleFormChange}
                      />
                    </Form.Item>

                    {/* Confirm Password */}
                    <Form.Item
                      className="hp-mb-16"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                      ]}
                    >
                      <Input.Password
                        id="warning2"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        placeholder="Confirm password"
                        onChange={handleFormChange}
                      />
                    </Form.Item>

                    {/* Submit Button */}
                    <Form.Item className="hp-mt-16">
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit}
                        loading={submitLoading}
                      >
                        Join
                      </Button>
                    </Form.Item>
                  </Form>

                  <div className="hp-form-info">
                    <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                      Already joined a team?{" "}
                    </span>

                    <Link
                      to="/login"
                      className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                    >
                      Login
                    </Link>
                  </div>
                </>
              ) : !success ? (
                <Col className="hp-form-info">
                  <img
                    src={logoText}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />

                  <h2
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      color: "#000000",
                    }}
                  >
                    Click below to join team
                  </h2>

                  {/* Confirm to join button */}
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                    loading={submitLoading}
                  >
                    Join
                  </Button>
                </Col>
              ) : (
                <Col className="hp-form-info">
                  <h1>Joined Successfully</h1>

                  <Link
                    className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                    to="/login"
                    style={{
                      display: "inline-block",
                      marginTop: "16px",
                      fontSize: "24px",
                    }}
                  >
                    Login
                  </Link>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TeamInviteAccept;
