import React from "react";
import { useSelector } from "react-redux";
import { RiArrowGoBackFill } from "react-icons/ri";
import { ChatbotTemplateBlogUpdateForm } from "@components";
import { Card, Row, Col } from "antd";

const ChatbotTemplateBlogUpdate = ({ history }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Card style={{ borderRadius: "0", height: "100vh", overflowY: "auto" }}>
        <Row>
          <Col span={24}>
            <Row>
              <RiArrowGoBackFill
                style={{ fontSize: "2em", cursor: "pointer" }}
                onClick={() => {
                  // If user is superadmin, redirect to chatbot-template-blogs
                  if (user?.role === "superadmin") {
                    history.goBack();
                  } else if (user?.role === "marketing") {
                    history.goBack();
                  }
                }}
              />
              <h3
                className="hp-mb-16"
                style={{
                  marginLeft: "1em",
                  fontSize: "1.75em",
                }}
              >
                Update AI App
              </h3>
            </Row>
            {/* Space */}
            <Row style={{ marginTop: "1em" }} />
            {/* ChatbotTemplateBlog Update Form */}
            <ChatbotTemplateBlogUpdateForm />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ChatbotTemplateBlogUpdate;
