import React, { useCallback, useState } from "react";
import { Col, Modal } from "antd";
import { useSelector } from "react-redux";
import { RiErrorWarningFill } from "react-icons/ri";
import { ChatbotMessages, ChatbotVisitor } from "@components";

const ChatRight = ({
  socket,
  // Loaders
  loading,
  messageLoading,
  // Chatbot
  selectedBotName,
  // Chat
  selectedChat,
  setSelectedChat,
  showAcceptHandoverButton,
  acceptHandoverButtonLoading,
  acceptHandoverFromChat,
  // Chat Input
  isChatEnabled,
  setIsChatEnabled,
  isLeaveChatEnabled,
  setIsLeaveChatEnabled,
  setHasChatEnded,
  // Visitor Input
  isVisitorInputEnabled,
  handleToggleVisitorInput,
}) => {
  const { user } = useSelector((state) => state.auth);

  // Chat Input
  const [chatInput, setChatInput] = useState({ message: "", file: "" });
  // Is file input
  const [isFileInput, setIsFileInput] = useState(false);
  // Uploaded file
  const [uploadedFile, setUploadedFile] = useState(null);
  // Uploaded file URL
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);

  // *** Chat Input *** //
  // Handle chat input change
  const handleChatInputChange = useCallback((content) => {
    setChatInput((prevInput) => ({ ...prevInput, message: content }));
  }, []);

  // *** Send Message *** //
  // Handle send message
  const handleSendMessage = () => {
    // Utility function to check if the message has content
    const isMessageEmpty = (message) => {
      // Remove HTML tags
      const strippedMessage = message.replace(/<[^>]*>?/gm, "").trim();
      // Check if there's any content left
      return strippedMessage === "";
    };

    // If chat input is empty, return
    if (!chatInput) return;

    // If the message is effectively empty, return
    if (!chatInput.message || isMessageEmpty(chatInput.message)) return;

    // Generate a random id for the message
    const agentMessageId = Math.floor(Math.random() * 1000000);

    // Agent details
    const agentDetails = {
      _id: user._id,
      name: user.name,
      email: user.email,
    };

    // Send message to server
    socket.current.emit("send-agent-message", {
      agentMessageId,
      agentDetails,
      chatSessionId: selectedChat.chatSessionId,
      message: !isFileInput ? chatInput?.message : uploadedFileUrl,
      isFileInput,
      uploadedFile,
    });

    // If not file input
    if (!isFileInput) {
      // Push the message to the selected chat
      setSelectedChat((prevSelectedChat) => ({
        ...prevSelectedChat,
        record: [
          ...prevSelectedChat.record,
          {
            _id: agentMessageId,
            type: "agent-message",
            time: new Date(),
            text: chatInput.message,
            agentDetails,
          },
        ],
      }));
    } else {
      // Push the message to the selected chat
      setSelectedChat((prevSelectedChat) => ({
        ...prevSelectedChat,
        record: [
          ...prevSelectedChat.record,
          {
            _id: agentMessageId,
            type: "agent-message-file",
            time: new Date(),
            file: uploadedFileUrl,
          },
        ],
      }));

      // Set isFileInput to false
      setIsFileInput(false);
    }

    // Reset the chat input
    setChatInput({ message: "", file: "" });

    // Scroll to the bottom of the chatContainer
    const chatContainer = document.getElementById("chatMessageContainer");

    if (chatContainer) {
      setTimeout(() => {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth",
        });
      }, 0);
    }
  };

  // *** End Chat *** //
  // Handle end chat
  const handleEndChat = () => {
    // If confirmed, send the end chat request to the server
    Modal.confirm({
      title: "End Chat",
      icon: <RiErrorWarningFill style={{ color: "#1BE7FF" }} />,
      content: "Are you sure you want to end this chat?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        // If there was a previously selected chat, leave its room
        if (selectedChat) {
          // Send the request to the server
          socket.current.emit("end-chat", {
            chatSessionId: selectedChat.chatSessionId,
            agentDetails: { _id: user._id },
          });

          // Scroll to the bottom of the chatContainer
          const chatContainer = document.getElementById("chatMessageContainer");

          // Push the message to the selected chat
          setSelectedChat((prevSelectedChat) => ({
            ...prevSelectedChat,
            record: [
              ...prevSelectedChat.record,
              {
                _id: user._id,
                type: "agent-chat-ended",
                time: new Date(),
              },
            ],
          }));

          // Set has chat ended to true
          setHasChatEnded(true);

          // Set end chat to false
          setIsChatEnabled(false);

          // Set leave chat to false
          setIsLeaveChatEnabled(false);

          if (chatContainer) {
            setTimeout(() => {
              chatContainer.scrollTo({
                top: chatContainer.scrollHeight,
                behavior: "smooth",
              });
            }, 0);
          }
        }
      },
    });
  };

  // *** Leave Chat *** //
  // Handle leave chat
  const handleLeaveChat = () => {
    // If confirmed, send the leave chat request to the server
    Modal.confirm({
      title: "Leave Chat",
      icon: <RiErrorWarningFill style={{ color: "#1BE7FF" }} />,
      content: "Are you sure you want to leave this chat?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        // If there was a previously selected chat, leave its room
        if (selectedChat) {
          // Send the request to the server
          socket.current.emit("leave-chat", {
            chatSessionId: selectedChat.chatSessionId,
            agentDetails: { _id: user._id, name: user.name, email: user.email },
          });

          // Scroll to the bottom of the chatContainer
          const chatContainer = document.getElementById("chatMessageContainer");

          // Push the message to the selected chat
          setSelectedChat((prevSelectedChat) => ({
            ...prevSelectedChat,
            record: [
              ...prevSelectedChat.record,
              {
                _id: user._id,
                type: "agent-left-chat",
                time: new Date(),
                name: user?.name,
              },
            ],
          }));

          // Set has chat ended to true
          setHasChatEnded(true);

          // Set end chat to false
          setIsChatEnabled(false);

          // Set leave chat to false
          setIsLeaveChatEnabled(false);

          if (chatContainer) {
            setTimeout(() => {
              chatContainer.scrollTo({
                top: chatContainer.scrollHeight,
                behavior: "smooth",
              });
            }, 0);
          }
        }

        // Set leave chat to false
        setIsLeaveChatEnabled(false);
      },
    });
  };

  // *** Invite Team Member to Chat *** //
  // Handle invite team member to chat
  const handleInviteToChat = (agentId) => {
    // If there was a previously selected chat, leave its room
    if (selectedChat) {
      // Send the request to the server
      socket.current.emit("invite-agent-to-chat", {
        chatSessionId: selectedChat.chatSessionId,
        agentDetails: { _id: user._id, name: user.name, email: user.email },
        invitedAgentUserId: agentId,
        workspaceId: user?.workspaceId,
      });
    }
  };

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={14}
        style={{
          background: "#ffffff",
        }}
      >
        <ChatbotMessages
          // Selected Chatbot
          selectedBotName={selectedBotName}
          // Selected Chat
          selectedChat={selectedChat}
          showAcceptHandoverButton={showAcceptHandoverButton}
          acceptHandoverButtonLoading={acceptHandoverButtonLoading}
          acceptHandoverFromChat={acceptHandoverFromChat}
          // Chat Input
          chatInput={chatInput}
          setChatInput={setChatInput}
          handleChatInputChange={handleChatInputChange}
          handleSendMessage={handleSendMessage}
          handleEndChat={handleEndChat}
          handleLeaveChat={handleLeaveChat}
          isChatEnabled={isChatEnabled}
          isLeaveChatEnabled={isLeaveChatEnabled}
          // File Input
          isFileInput={isFileInput}
          setIsFileInput={setIsFileInput}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          uploadedFileUrl={uploadedFileUrl}
          setUploadedFileUrl={setUploadedFileUrl}
          // Visitor Input
          isVisitorInputEnabled={isVisitorInputEnabled}
          handleToggleVisitorInput={handleToggleVisitorInput}
          // Invite to Chat
          handleInviteToChat={handleInviteToChat}
          // Loading
          loading={loading}
          messageLoading={messageLoading}
        />
      </Col>

      {/* Right Chatbot Visitor */}
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={6}
        xl={5}
        style={{
          background: "#ffffff",
        }}
      >
        <ChatbotVisitor
          selectedChat={selectedChat}
          // Loading
          loading={loading}
          messageLoading={messageLoading}
        />
      </Col>
    </>
  );
};

export default ChatRight;
