import React, { useState, useCallback, useEffect } from "react";
import { Card, Row, Col, Select, Tooltip, Typography, DatePicker } from "antd";
import {
  LineChartOutlined,
  BarChartOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import MetricsCharts from "./MetricsCharts";

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const Overview = () => {
  const { t } = useTranslation();
  const { chatbotResponsesAnswerVariables, loading } = useSelector(
    (state) => state.chatbot
  );

  const [chartType, setChartType] = useState("Line");
  const [timeRange, setTimeRange] = useState("last30Days");
  const [grouping, setGrouping] = useState("daily");
  const [customDateRange, setCustomDateRange] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days"),
    moment(),
  ]);

  const timeRangeOptions = [
    { value: "today", label: t("metrics.today") },
    { value: "yesterday", label: t("metrics.yesterday") },
    { value: "last7days", label: t("metrics.last7Days") },
    { value: "last30days", label: t("metrics.last30Days") },
    { value: "thismonth", label: t("metrics.thisMonth") },
    { value: "lastmonth", label: t("metrics.lastMonth") },
    { value: "custom", label: t("metrics.custom") },
  ];

  const groupingOptions = [
    { value: "hourly", label: t("metrics.hourly") },
    { value: "daily", label: t("metrics.daily") },
    { value: "weekly", label: t("metrics.weekly") },
    { value: "monthly", label: t("metrics.monthly") },
  ];

  const chartTypeOptions = [
    { value: "Line", label: t("metrics.line"), icon: <LineChartOutlined /> },
    { value: "Bar", label: t("metrics.bar"), icon: <BarChartOutlined /> },
    { value: "Pie", label: t("metrics.pie"), icon: <PieChartOutlined /> },
  ];

  const handleChartTypeChange = useCallback((value) => {
    setChartType(value);
  }, []);

  const handleTimeRangeChange = useCallback((value) => {
    setTimeRange(value);
    if (value !== "custom") {
      const newDateRange = getDateRangeFromTimeRange(value);
      setDateRange(newDateRange);
      setCustomDateRange(null);
    }
  }, []);

  const handleGroupingChange = useCallback((value) => {
    setGrouping(value);
  }, []);

  const handleCustomDateChange = useCallback((dates) => {
    setCustomDateRange(dates);
    setDateRange(dates);
    setTimeRange("custom");
  }, []);

  const getDateRangeFromTimeRange = (range) => {
    const now = moment();
    switch (range) {
      case "today":
        return [now.startOf("day"), now.endOf("day")];
      case "yesterday":
        return [
          now.subtract(1, "days").startOf("day"),
          now.subtract(1, "days").endOf("day"),
        ];
      case "last_7_days":
        return [now.subtract(7, "days").startOf("day"), now.endOf("day")];
      case "last_30_days":
        return [now.subtract(30, "days").startOf("day"), now.endOf("day")];
      case "this_month":
        return [now.startOf("month"), now.endOf("month")];
      case "last_month":
        return [
          now.subtract(1, "month").startOf("month"),
          now.subtract(1, "month").endOf("month"),
        ];
      default:
        return [now.subtract(30, "days").startOf("day"), now.endOf("day")];
    }
  };

  return (
    <div className="overview-container">
      <Card className="filter-card">
        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} lg={5}>
            <Title level={5}>{t("metrics.timeRange")}</Title>
            <Select
              value={timeRange}
              style={{ width: "100%" }}
              onChange={handleTimeRangeChange}
            >
              {timeRangeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} lg={5}>
            <Title level={5}>{t("metrics.customRange")}</Title>
            <RangePicker
              value={customDateRange}
              onChange={handleCustomDateChange}
              disabled={timeRange !== "custom"}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} lg={5}>
            <Title level={5}>{t("metrics.grouping")}</Title>
            <Select
              value={grouping}
              style={{ width: "100%" }}
              onChange={handleGroupingChange}
            >
              {groupingOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} lg={5}>
            <Title level={5}>{t("metrics.chartType")}</Title>
            <Select
              value={chartType}
              style={{ width: "100%" }}
              onChange={handleChartTypeChange}
            >
              {chartTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {React.cloneElement(option.icon, {
                    style: { marginRight: 8 },
                  })}
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Card>

      <Card className="chart-card">
        <MetricsCharts
          chartType={chartType}
          timeRange={timeRange}
          customDateRange={customDateRange}
          grouping={grouping}
          data={chatbotResponsesAnswerVariables}
          loading={loading}
        />
      </Card>

      <Row gutter={[16, 16]} className="metrics-summary">
        <Col xs={24} sm={12} md={8} lg={8}>
          <Card className="metric-card">
            <Tooltip title={t("metrics.totalChatsTooltip")}>
              <Title level={4}>{t("metrics.totalChats")}</Title>
              <Title level={2} className="metric-value">
                {chatbotResponsesAnswerVariables.length}
              </Title>
            </Tooltip>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Card className="metric-card">
            <Tooltip title={t("metrics.averageChatsPerDayTooltip")}>
              <Title level={4}>{t("metrics.averageChatsPerDay")}</Title>
              <Title level={2} className="metric-value">
                {(
                  chatbotResponsesAnswerVariables.length /
                  Math.max(1, moment(dateRange[1]).diff(dateRange[0], "days"))
                ).toFixed(2)}
              </Title>
            </Tooltip>
          </Card>
        </Col>
        {/* Add more metric cards as needed */}
      </Row>

      <style jsx global>{`
        .overview-container {
          padding: 24px;
          background-color: #f0f2f5;
          overflow: auto;
          height: 100vh;
        }
        .filter-card,
        .chart-card,
        .metric-card {
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          background-color: #ffffff;
        }
        .filter-card {
          margin-bottom: 24px;
        }
        .chart-card {
          margin-bottom: 24px;
        }
        .metrics-summary {
          margin-top: 24px;
        }
        .metric-card {
          text-align: center;
          transition: all 0.3s ease;
        }
        .metric-card:hover {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }
        .metric-value {
          color: #1890ff;
        }
        .ant-select-selector,
        .ant-picker {
          border-radius: 6px !important;
        }
        .ant-btn {
          border-radius: 6px;
        }
        .ant-card-body {
          padding: 24px;
        }
      `}</style>
    </div>
  );
};

export default Overview;
