import { ACTION_TYPES } from "../constants/actionTypes";

// Define the structure of the alert payload, update it based on your needs
interface AlertPayload {
  message: string;
  type: string; // e.g., 'success', 'error', etc.
  // Add other properties as required
}

// Define the type for your action
interface AlertAction {
  type: string;
  payload: AlertPayload;
}

// Define the type for your state, which is the same as the AlertPayload
type AlertState = AlertPayload | {}; // State can be an AlertPayload or an empty object

// Initial state with type
const initialState: AlertState = {};

// Reducer function with types
const alertReducer = (
  state = initialState,
  action: AlertAction
): AlertState => {
  switch (action.type) {
    case ACTION_TYPES.ALERT:
      return action.payload; // Payload is directly returned
    default:
      return state;
  }
};

export default alertReducer;
