import { Dispatch } from "redux";
import { ACTION_TYPES } from "../constants/actionTypes";
import { AUTH_TYPES } from "../constants/authTypes";
import {
  signUp,
  login,
  googleCreateOrLogin,
  logout,
  sumoSignUp,
} from "../../api/auth/auth";
import { currentUser } from "../../api/user/user";
import { currentAdmin } from "../../api/user/admin";
import { currentMember } from "../../api/user/member";
import { currentSuperAdmin } from "../../api/user/superAdmin";
import { currentMarketingAdmin } from "../../api/user/marketingAdmin";
import {
  RoleBasedRedirect,
  RedirectOnLogout,
} from "../../utils/roleBasedRedirect";
import {
  SuccessNotification,
  ErrorNotification,
  InfoNotification,
} from "../../components";

/********************************************
  Sign up a user
*********************************************/
export const signupAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  data.setLoading(true);

  try {
    // Fetch response from server
    const res = await signUp(data.name, data.email, data.rl, data.password);

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: res.data.message,
      },
    });

    SuccessNotification(res.data.message);
    InfoNotification("🥴 Check mail spam if not found 🥴");
    // Clear the form on success
    data.setFormData(data.initialFormData);
    data.setLoading(false);
  } catch (error: any) {
    ErrorNotification(error.response.data?.type[0]?.message);
    data.setLoading(false);
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.type[0]?.message,
      },
    });
  }
};

/********************************************
  Login an user
*********************************************/
export const loginAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading notify
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  data.setLoading(true);
  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await login(data.email, data.password);

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.AUTH,
      payload: {
        accessToken: res.data.accessToken,
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });

    data.setLoading(false);
    // Redirect user based on role
    RoleBasedRedirect(res, data.history);
  } catch (error: any) {
    data.setLoading(false);
    // Dispatch a error notify
    ErrorNotification(error?.response?.data?.type[0]?.message);
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "LOGIN_AUTH_ACTION_ERROR",
      },
    });
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  }
};

/********************************************
 Google login an user 
*********************************************/
export const googleLoginAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await googleCreateOrLogin(data.name, data.email);

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.AUTH,
      payload: {
        accessToken: res.data.accessToken,
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    // Redirect user based on role
    RoleBasedRedirect(res, data.history);
  } catch (error: any) {
    // Dispatch a error notify
    ErrorNotification(error.response?.data?.type[0]?.message);
    data.setLoading(false);

    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GOOGLE_LOGIN_AUTH_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Is user super admin action
*********************************************/
export const isSuperAdminAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await currentSuperAdmin();

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.IS_SUPER_ADMIN,
      payload: {
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_SUPER_ADMIN_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Is user marketing admin action
*********************************************/
export const isMarketingAdminAction =
  (data: any) => async (dispatch: Dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      let errorMessage = "";

      // Fetch response from server
      const res = await currentMarketingAdmin();

      // Dispatch token and user
      dispatch({
        type: AUTH_TYPES.IS_MARKETING_ADMIN,
        payload: {
          user: res.data.user,
        },
      });

      // Dispatch a success/error login notify
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: errorMessage ? errorMessage : res.data.message,
        },
      });

      data.setOk(true);
    } catch (error) {
      // Dispatch a error notify
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "IS_MARKETING_ADMIN_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Is user admin action
*********************************************/
export const isAdminAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await currentAdmin();

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.IS_ADMIN,
      payload: {
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_ADMIN_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Is user member action
*********************************************/
export const isMemberAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await currentMember();

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.IS_MEMBER,
      payload: {
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_MEMBER_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Is user action
*********************************************/
export const isUserAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await currentUser();

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.IS_USER,
      payload: {
        user: res.data.user,
      },
    });

    // If data.setOk exists, set it to true
    if (data?.setOk) {
      data.setOk(true);
    }

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    // Redirect user based on role
    RoleBasedRedirect(res, data.history);
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_USER_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Log out an user
*********************************************/
export const logoutAction = () => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });
  try {
    // Fetch response from server
    const res = await logout();

    // Dispatch logout
    dispatch({
      type: AUTH_TYPES.LOGOUT,
      payload: {},
    });

    // Dispatch a success/error logout alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: res.data.message,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
    // Redirect user to login page
    RedirectOnLogout();
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "LOGOUT_ACTION_ERROR",
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  }
};

/********************************************
  Sumo sign up a user
*********************************************/
export const sumoSignupAction = (data: any) => async (dispatch: Dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  data.setLoading(true);

  try {
    // Fetch response from server
    const res = await sumoSignUp(
      data.name,
      data.email,
      data.password,
      data.code
    );

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: res.data.message,
      },
    });

    SuccessNotification(res.data.message);
    InfoNotification("🥴 Check mail spam if not found 🥴");
    // Clear the form on success
    data.setFormData(data.initialFormData);
    data.setLoading(false);
  } catch (error: any) {
    ErrorNotification(error.response.data?.type[0].message);
    data.setLoading(false);
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.type[0].message,
      },
    });
  }
};
