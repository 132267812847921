import { Dispatch } from "redux";
import { ACTION_TYPES } from "../constants/actionTypes";
import { SUPER_ADMIN_TYPES } from "../constants/superAdminTypes";
import { getSuperAdminAnalytics } from "../../api/user/superAdmin";

// Assuming the setLoading function takes a boolean and returns void
type SetLoadingType = (isLoading: boolean) => void;

export const getSuperAdminAnalyticsAction =
  (setLoading: SetLoadingType) => async (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await getSuperAdminAnalytics();

      dispatch({
        type: SUPER_ADMIN_TYPES.GET_SUPER_ADMIN_ANALYTICS,
        payload: res.data,
      });

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });

      setLoading(false);
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_SUPER_ADMIN_ANALYTICS_ACTION_ERROR",
        },
      });
    }
  };
