import { useState, useEffect, FC } from "react";
import { Row, Col, Button, Select, Input, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { JumpToNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const JumpToNodeDataEditor: FC<JumpToNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const { nodes } = useStore((state) => ({
    nodes: state.nodes,
  }));

  const state = getNode(id);

  const [selectedNode, setSelectedNode] = useState(state?.data.nodeId);
  const [nodeOptions, setNodeOptions] = useState(nodes);
  const { t } = useTranslation();

  // Set the jump to node nodeOptions
  useEffect(() => {
    setNodeOptions(nodes);
  }, [nodes, nodes.length]);

  // Handle select node
  const handleSelectNode = (id: any, value: any) => {
    if (!state) return;

    setSelectedNode(value);

    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, nodeId: id },
    });
  };

  // Handle paste node id
  const handlePasteNodeId = (e: any) => {
    if (!state) return;

    setSelectedNode(e.target.value);

    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, nodeId: e.target.value },
    });
  };

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.jumpToNodeInfo")}
          </p>
        </Row>

        {/* Header  */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.selectNodeToJump")}
          </Col>
        </Divider>

        <Col
          span={24}
          style={{
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%", marginTop: "8px" }}>
            <Button
              type="text"
              size="small"
              style={{
                borderRadius: "4px",
                background: "#edecec",
                color: "#000000",
                width: "100%",
                fontWeight: "500",
              }}
            >
              <Select
                showSearch
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  color: "#000000",
                  textAlign: "start",
                }}
                placeholder={
                  <span style={{ color: "black" }}>Select a node</span>
                }
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size="small"
                bordered={false}
                onChange={(value, option) => {
                  handleSelectNode(option.key, value);
                }}
                value={selectedNode}
              >
                {/* Loop chatbots name */}
                {nodes?.length > 0 &&
                  nodeOptions?.map(
                    (chatbot: any, index: any) =>
                      chatbot.type !== "welcome-node" && (
                        <Option key={chatbot?.id} value={chatbot?.type}>
                          {`${chatbot?.id}`}
                        </Option>
                      )
                  )}
              </Select>
            </Button>
          </div>
        </Col>
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.or")}
          </Col>
        </Divider>

        {/* Header  */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.pasteNodeId")}
          </Col>
        </Divider>

        <Col span={24}>
          <Input
            placeholder={t(
              "flowBuilder.sidebar.editor.specialNodes.pasteNodeId"
            )}
            value={selectedNode}
            onChange={handlePasteNodeId}
          />
        </Col>
      </Row>
    </>
  );
};

export default JumpToNodeDataEditor;
