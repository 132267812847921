import { useState, FC } from "react";
import { Row, Col, Upload, message, Divider } from "antd";
import { useParams } from "react-router";

import { shallow } from "zustand/shallow";
import { RiLoaderLine, RiAddLine } from "react-icons/ri";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
import { VideoNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
// translation
import { useTranslation } from "react-i18next";

const VideoNodeDataEditor: FC<VideoNode> = ({ id, type }) => {
  const { bid } = useParams<{ bid: string }>();
  const { getNode, changeNodeData } = useStore(selector, shallow);

  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  function beforeUpload(file: any) {
    // Video can be mp4, webm, or ogg
    const isVideo =
      file.type === "video/mp4" ||
      file.type === "video/webm" ||
      file.type === "video/ogg";
    if (!isVideo) {
      message.error("You can only upload MP4/WEBM/OGG file!");
    }
    // Video must be smaller than 15MB
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isLt15M) {
      message.error("Video must smaller than 15MB!");
    }
    return isVideo && isLt15M;
  }

  // Handle upload video
  const handleUploadVideo = async (options: any) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload video
    try {
      setLoading(true);
      const res = await uploadChatbotMedia(bid, fmData, config);

      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, video: res?.data?.url },
      });
      setLoading(false);
    } catch (error) {
      onError(error);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>
        {t("flowBuilder.sidebar.editor.sendResponseNodes.upload")}
      </div>
    </div>
  );

  return (
    <>
      {/* Node Video */}
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.sendResponseNodes.videoNodeInfo")}
          </p>
        </Row>

        <Divider orientation="left">
          <Col span={8} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.sendResponseNodes.video")}
          </Col>
        </Divider>

        <Col span={14}>
          <Upload
            name="welcome-video"
            listType="picture-card"
            customRequest={handleUploadVideo}
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            multiple={false}
            accept="video/*,video/gif"
          >
            {state?.data?.video ? (
              <video
                src={state?.data?.video}
                autoPlay
                loop
                muted
                playsInline
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Col>
      </Row>
    </>
  );
};

export default VideoNodeDataEditor;
