import { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Select } from "antd";
import { shallow } from "zustand/shallow";
import { RatingNode } from "../../../../common/nodes/typings";
import useStore, { selector } from "../../../../store/store";
import QuillEditor from "../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../common/components/saveAnswer/saveAnswer";

const RatingNodeDataEditor: FC<RatingNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [ratingPrompt, setRatingPrompt] = useState(state?.data?.ratingPrompt);
  const [ratingType, setRatingType] = useState(state?.data?.type);
  const [range, setRange] = useState(state?.data?.range);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setRatingPrompt(state?.data?.ratingPrompt);
    setRatingType(state?.data?.type);
    setRange(state?.data?.range);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  const handleRatingPromptChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the Rating Prompt
      setRatingPrompt(content);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          ratingPrompt: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  const handleTypeChange = useCallback(
    (value: any) => {
      if (!state) return;

      setRatingType(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, type: value },
      });
    },
    [state, type, changeNodeData]
  );

  const handleRangeChange = useCallback(
    (value: any) => {
      if (!state) return;

      setRange(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, range: value },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              color: "gray",
            }}
          >
            Use this node when you want to ask the user to rate or collect
            feedback from the user. The user can rate using a number, emoji or
            star.
          </p>
        </Row>

        {/* Question Text */}
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={ratingPrompt}
              onEditorChange={handleRatingPromptChange}
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "14px",
            width: "100%",
          }}
        />

        {/* Rating Type */}
        <Col span={6} className="sidebar__heading">
          Rating Type
        </Col>
        <Col span={16}>
          <Select
            style={{ width: "100%" }}
            onChange={(value: any) => handleTypeChange(value)}
            value={ratingType}
          >
            <Select.Option value="number">Number</Select.Option>
            <Select.Option value="star">Star</Select.Option>
            <Select.Option value="smiley">Smiley</Select.Option>
          </Select>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "14px",
            width: "100%",
          }}
        />

        {/* Rating Range */}
        {/* Display this only after type is selected */}
        {ratingType && (
          <>
            <Col span={6} className="sidebar__heading">
              Rating Range
            </Col>
            <Col span={16}>
              <Select
                style={{ width: "100%" }}
                onChange={(value: any) => handleRangeChange(value)}
                value={range}
              >
                <Select.Option value="5">5</Select.Option>
                {ratingType === "number" && (
                  <Select.Option value="10">10</Select.Option>
                )}
              </Select>
            </Col>
          </>
        )}
        <SaveAnswer
          id={id}
          type={type}
          variableName={answerVariable ? answerVariable : "rating"}
        />
      </Row>
    </>
  );
};

export default RatingNodeDataEditor;
