import {
  EdgeProps,
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
} from "reactflow";

export default function CustomEdgeFlow({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const dropOffRate = 100 - parseFloat(data?.label);

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          strokeWidth: "3px",
          stroke: "#26a100",
          filter: "drop-shadow(0 0 5px #fff)",
        }}
      />

      {data?.label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              background: "#d8f261",
              padding: 10,
              borderRadius: 5,
              fontSize: 18,
              fontWeight: 700,
            }}
            className="nodrag nopan"
          >
            {data?.label}
          </div>
        </EdgeLabelRenderer>
      )}

      {data?.isFlowOperation === false && (
        <>
          <path
            id={id}
            style={{ opacity: "0.5" }}
            stroke="#ff0000"
            strokeWidth="2.5"
            d={`M ${sourceX},${sourceY} L ${sourceX + 100},${sourceY} M ${
              sourceX + 100
            },${sourceY} L ${sourceX + 100},${sourceY + 100}`}
          />
          <foreignObject
            x={sourceX + 100 - 75} // Adjust x coordinate so box is centered around line end
            y={sourceY + 100}
            width="150"
            height="50"
          >
            <div
              style={{
                background: "#ff0000",
                padding: 10,
                borderRadius: 5,
                fontSize: 16,
                fontWeight: 700,
                color: "#fff",
              }}
            >
              {`Drop-off: ${dropOffRate}%`}
            </div>
          </foreignObject>
        </>
      )}
    </>
  );
}
