import { useState } from "react";
import { Col, Row } from "antd";
import Header from "./header";
import { ChatbotEmbed, ChatbotShareWithLink } from "@components";
import { FaCode } from "react-icons/fa";
import { BiLinkAlt } from "react-icons/bi";
import "@assets/css/ChatbotShare.css";
import { useTranslation } from "react-i18next";

const ChatbotShare = () => {
  const [activeTab, setActiveTab] = useState("embed");
  const { t } = useTranslation();

  // Side bar tabs
  const sidebarTabs = [
    {
      title: t("share.sidebar.embedIntoWebsite"),
      icon: <FaCode style={{ fontSize: "18px" }} />,
      slug: "embed",
    },
    {
      title: t("share.sidebar.shareWithLink"),
      icon: <BiLinkAlt style={{ fontSize: "18px" }} />,
      slug: "share",
    },
  ];

  // Handle set active tab
  const handleActiveTab = (slug) => {
    setActiveTab(slug);
  };

  return (
    <>
      {/* Header */}
      <div>
        <Header isShare={true} />
      </div>

      <Row>
        {/* Sidebar tabs */}
        <Col span={5}>
          <div className="chatbot_share_sidebar">
            <ul>
              {sidebarTabs?.map((item, ind) => {
                return (
                  <li
                    key={ind}
                    onClick={() => handleActiveTab(item?.slug)}
                    className={`${activeTab === item?.slug && "active"}`}
                  >
                    <span style={{ marginTop: "6px" }}>{item?.icon}</span>
                    <span style={{ marginLeft: "10px" }}> {item?.title} </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </Col>

        {/* Embed Side Tab */}
        {activeTab === "embed" && (
          <Col span={19}>{activeTab === "embed" && <ChatbotEmbed />}</Col>
        )}

        {/* Share Link Tab */}
        {activeTab === "share" && (
          <Col span={19} style={{ backgroundColor: "#fff" }}>
            <ChatbotShareWithLink />
          </Col>
        )}
      </Row>
    </>
  );
};

export default ChatbotShare;
