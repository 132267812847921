import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ImUsers } from "react-icons/im";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";

const VisitorsCard = () => {
  const { t } = useTranslation();
  const { analytics } = useSelector((state) => state.admin);

  // Calculate the percentage change in visitors
  const calculatePercentageChange = () => {
    const { totalVisitors, last7DaysVisitors } = analytics;

    // Assuming you have the previous total visitors count available
    const previousTotalVisitors = last7DaysVisitors; // Replace with the actual previous total visitors count

    if (previousTotalVisitors === 0) {
      return 0;
    }

    const percentageChange =
      ((totalVisitors - previousTotalVisitors) / previousTotalVisitors) * 100;
    return percentageChange.toFixed(2);
  };

  const percentageChange = calculatePercentageChange();
  const isPositiveChange = percentageChange >= 0;

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Row gutter={16} align="middle">
        <Col span={24}>
          <div className="analytics-card">
            <div className="analytics-header">
              <div>
                <ImUsers
                  style={{
                    fontSize: "24px",
                    marginRight: "5px",
                    color: "#0b4eb8",
                  }}
                />
              </div>
              <h5>{t("dashboard.visitors")}</h5>
            </div>
            <div className="analytics-middle">
              <h5>{t("dashboard.totalVisitors")}</h5>
              <div style={{ position: "relative" }}>
                <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {analytics?.totalVisitors}
                </span>{" "}
                <FaArrowTrendUp
                  style={{
                    color: isPositiveChange ? "green" : "red",
                    marginRight: "5px",
                    marginLeft: "12px",
                    fontWeight: "bold",
                    fontSize: "24px",
                    marginBottom: "-5px",
                  }}
                />
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {percentageChange}%
                </span>
              </div>
            </div>
            <div className="analytics-bottom">
              <h5>{t("dashboard.last7days")}</h5>
              <div className="flex-property">
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltUp
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    />
                  </div>
                  <div>{analytics?.last7DaysVisitors}</div>
                </div>
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltDown
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    />
                  </div>
                  <div>0</div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VisitorsCard;
