import React, { FC, useCallback, useEffect, useState, useRef } from "react";
import { Col, Row, Button, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { AskMultipleQuestionsNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { PiDotsNineBold } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const AskMultipleQuestionsNodeDataEditor: FC<AskMultipleQuestionsNode> = ({
  id,
  type,
}) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [message, seMessage] = useState(
    state?.data?.message || "Answer the following questions"
  );
  const [questions, setQuestions] = useState(state?.data?.questions || []);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  const updateTimeout = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    setQuestions(state?.data?.questions);
  }, [state]);

  // OnDrag Function
  const onDragEnd = (e: any) => {
    const { sourceIndex, targetIndex } = e;
    setQuestions((prevQuestions: any) => {
      const updatedCards = [...prevQuestions];
      const [movedCard] = updatedCards.splice(sourceIndex, 1);
      updatedCards.splice(targetIndex, 0, movedCard);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state.data,
          questions: updatedCards,
        },
      });
      return updatedCards;
    });
  };

  // Open and Hide toggle Function
  const toggleDropdown = (id: any) => {
    setIsOpen(!isOpen);
    setActive(id);
  };

  // Handle message change
  const handleMessageChange = useCallback(
    (content: any) => {
      if (!state) return;
      seMessage(content);

      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }

      updateTimeout.current = setTimeout(() => {
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            message: content,
          },
        });
      }, 5000); // 5 seconds
    },
    [state, type, changeNodeData]
  );
  // Handle adding a new question
  const handleAddQuestion = useCallback(() => {
    const questionId = (questions.length + 1).toString();
    const newQuestion = {
      id: questionId,
      questionText: "",
      answerVariable: "",
    };
    setActive(questionId);
    setQuestions([...questions, newQuestion]);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        questions: [...questions, newQuestion],
      },
    });
  }, [questions, state, type, changeNodeData]);

  // Handle deleting a question
  const handleDeleteQuestion = useCallback(
    (questionId: any) => {
      const updatedQuestions = questions.filter(
        (question: any) => question.id !== questionId
      );

      setQuestions(updatedQuestions);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questions: updatedQuestions,
        },
      });
    },
    [questions, state, type, changeNodeData]
  );

  useEffect(() => {
    setIsOpen(true);
  }, [active]);

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t(
            "flowBuilder.sidebar.editor.askQuestionNodes.multiQuestionsNodeInfo"
          )}
        </p>
      </Row>
      <Row>
        <Divider orientation="left">
          <Col
            span={6}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.askQuestionNodes.message")}
          </Col>
        </Divider>

        <Col span={24} style={{ width: "100%" }}>
          <div>
            <QuillEditor
              initialContent={message}
              onEditorChange={handleMessageChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.askQuestionNodes.questionTextPH"
              )}
            />
          </div>
        </Col>
        <Divider orientation="left">
          <Col
            span={6}
            style={{ alignSelf: "start", marginBottom: "15px" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.askQuestionNodes.questions")}
          </Col>
        </Divider>

        <Col span={24} className="drag_multi_options_container">
          {questions.map((question: any, index: any) => (
            <>
              <Col
                style={{}}
                className={`drag_multi_options_inner ${
                  isOpen && active === question.id ? "open" : ""
                }`}
              >
                <div
                  className="drag_dropdown_button"
                  onClick={() => toggleDropdown(question?.id)}
                  draggable
                  onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", index.toString())
                  }
                  onDrop={(e) => {
                    e.preventDefault();
                    onDragEnd({
                      sourceIndex: Number(e.dataTransfer.getData("text/plain")),
                      targetIndex: index,
                    });
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    position: "relative",
                    border:
                      isOpen && active === question.id
                        ? "2px solid #fff"
                        : "2px solid transparent",
                    boxShadow:
                      isOpen &&
                      active === question.id &&
                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        left: "10px",
                      }}
                    >
                      {t(
                        "flowBuilder.sidebar.editor.askQuestionNodes.question"
                      )}{" "}
                      {index + 1}
                    </div>
                  </Col>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      type="text"
                      icon={<PiDotsNineBold />}
                      size={"small"}
                      className="drag_button"
                    />
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      size={"small"}
                      style={{ borderRadius: "4px" }}
                      onClick={() => handleDeleteQuestion(question.id)}
                      className="drag_dlt_button"
                    />
                  </div>
                </div>
                {isOpen && active === question.id && (
                  <div className="drag_isOpen_container">
                    <Row>
                      <Col span={24}>
                        <QuillEditor
                          initialContent={question.questionText}
                          onEditorChange={(content) => {
                            // Find the index of the matching question in the questions array
                            const questionIndex = questions.findIndex(
                              (q: any) => q.id === question.id
                            );

                            // Copy the question and modify the questionText
                            const updatedQuestion = {
                              ...questions[questionIndex],
                              questionText: content,
                            };

                            // Copy the questions array and replace the question at the found index
                            const updatedQuestions = [...questions];
                            updatedQuestions[questionIndex] = updatedQuestion;

                            // Save the updated questions array back to the state
                            setQuestions(updatedQuestions);
                            changeNodeData({
                              ...state,
                              type,
                              data: {
                                ...state.data,
                                questions: updatedQuestions,
                              },
                            });
                          }}
                          placeholderText={t(
                            "flowBuilder.sidebar.editor.askQuestionNodes.questionTextPH"
                          )}
                        />
                        <SaveAnswer
                          id={id}
                          type={type}
                          variableName={question.answerVariable}
                          questionId={question.id}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </>
          ))}

          <Col span={24}>
            <Button
              onClick={handleAddQuestion}
              block
              type="primary"
              icon={<PlusOutlined />}
              style={{ borderRadius: "4px" }}
            >
              {t("flowBuilder.sidebar.editor.askQuestionNodes.addQuestions")}
            </Button>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default AskMultipleQuestionsNodeDataEditor;
