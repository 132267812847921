import React, { FC, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import useStore from "../../../../../store/store";
import { OpinionScaleNodeData } from "../../../typings";
import ContextMenu from "../../../../components/contextMenu/ContextMenu";
import OptionScale from "./opinionScale";
import { Opinions } from "@assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const OpinionScaleNode: FC<NodeProps<OpinionScaleNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);
  const { getNode } = useStore((state: any) => ({
    getNode: state?.getNode,
  }));
  const { t } = useTranslation();
  const node = getNode(id);

  const [numOfOptions, setNumOfOptions] = useState(0);

  // Num options depends on the node.data.from and node.data.to, from least is 0 to max is 15
  // So if from is 0 and to is 9, total options is 10, if from is 4 and to is 9, total options is 6
  // Set the number of options based on the range of from and to

  useEffect(() => {
    const numBoxes = Math.abs(
      (node?.data?.to || 2) - (node?.data?.from || 0) + 1
    ); // added brackets and '+1' to include both 'from' and 'to' in the count
    setNumOfOptions(numBoxes);
  }, [node?.data?.from, node?.data?.to]);

  useEffect(() => {
    const getNodeId = node?.id;
    setNodeId(getNodeId);

    const numBoxes = Math.abs(
      (node?.data?.to || 2) - (node?.data?.from || 0) + 1
    );
    setNumOfOptions(numBoxes);
  }, []);

  if (!node) {
    return null; // or some fallback component
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />
      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "230px",
          maxWidth: "230px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={16}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Col
              span={18}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={Opinions}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="Opinion"
              />
              {t("flowBuilder.builderNodes.opinionNode")}
            </Col>

            {/* Delete & Duplicate Node */}
            <Col span={6}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          <Col span={24} className="hp-text-center">
            <div
              dangerouslySetInnerHTML={{ __html: node?.data?.questionText }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
                marginBottom: "2px",
              }}
            />
          </Col>
          {/* Add options based on the range of count */}
          {Array.from(Array(numOfOptions).keys()).map((_, index) => {
            return (
              <React.Fragment key={index}>
                <OptionScale
                  value={node?.data?.from + index}
                  index={index}
                  from={node?.data?.from}
                  fromLabel={node?.data?.fromLabel}
                  to={node?.data?.to}
                  toLabel={node?.data?.toLabel}
                />
              </React.Fragment>
            );
          })}
        </div>
      </Col>
    </>
  );
};

export default OpinionScaleNode;
