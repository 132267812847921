import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Card,
  Button,
  Spin,
  Input,
  DatePicker,
  Modal,
  message,
} from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  exportChatbotDataToCsv,
  getChatbotWithAnswerVariablesPaginated,
} from "@api/chatbot/chatbot";
import { useHistory } from "react-router-dom";

const { RangePicker } = DatePicker;

const ResponseTable = ({ parentLoading, selectedChatbot }) => {
  // Redux state
  const { chatbotResponsesAnswerVariables } = useSelector(
    (state) => state?.chatbot
  );

  // Component state
  const [data, setData] = useState([]); // Formatted data for the table
  const [exportLoading, setExportLoading] = useState(false); // Loading state for export
  const [searchText, setSearchText] = useState(""); // Search input value
  const [filteredData, setFilteredData] = useState([]); // Data after applying filters
  const [dateRange, setDateRange] = useState([]); // Selected date range
  const [exportModalVisible, setExportModalVisible] = useState(false); // Controls export loading modal
  const [downloadLinkModalVisible, setDownloadLinkModalVisible] =
    useState(false); // Controls download link modal
  const [downloadLink, setDownloadLink] = useState(""); // Stores the download link from the server
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    totalResponses: 0,
  }); // Pagination
  const [loading, setLoading] = useState(false); //Loading
  const history = useHistory();

  // Translations
  const { t } = useTranslation();

  // Effect to format and set data when chatbotResponsesAnswerVariables changes
  useEffect(() => {
    if (chatbotResponsesAnswerVariables) {
      const formattedData = formatData(chatbotResponsesAnswerVariables);
      setData(formattedData);
      setFilteredData(formattedData);
    }
  }, [chatbotResponsesAnswerVariables]);

  // Effect to set data when pagination & selectedChatbot changes
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const search = searchParams.get("search") || "";
    const bid = selectedChatbot;

    setSearchText(search);
    setPagination({ current: page, pageSize, total: 0, totalResponses: 0 });

    if (bid) {
      fetchChatbotWithAnswerVariables(bid, page, pageSize, search);
    }
  }, [history.location.search, selectedChatbot]);

  // Format the raw data for the table
  const replaceVariables = useCallback((text, variables) => {
    if (typeof text !== "string") return text;
    return text.replace(/\{([^}]+)\}/g, (match, key) => {
      const variable = variables.find((v) => v.key === key);
      return variable ? variable.value : match;
    });
  }, []);

  // Updated formatData function
  const formatData = useCallback(
    (rawData) => {
      return rawData.map((item, index) => ({
        key: index + 1,
        ...item,
        chatDate: dayjs(item.chatDate).format("YYYY-MM-DD HH:mm:ss"),
        answerVariables: item.answerVariables.map((v) => ({
          ...v,
          value: replaceVariables(v.value, item.answerVariables),
        })),
      }));
    },
    [replaceVariables]
  );

  // Generate dynamic columns based on the data
  const generateColumns = useCallback(() => {
    if (data.length === 0) return [];

    // Base columns that are always present
    const baseColumns = [
      {
        title: "#",
        key: "serialNumber",
        width: 60,
        render: (text, record, index) => {
          return (pagination.current - 1) * pagination.pageSize + index + 1;
        },
      },
      {
        title: t("metrics.visitorId"),
        dataIndex: "visitorId",
        key: "visitorId",
        width: 120,
      },
      {
        title: t("metrics.chatDate"),
        dataIndex: "chatDate",
        key: "chatDate",
        width: 180,
      },
      {
        title: t("metrics.country"),
        dataIndex: "country",
        key: "country",
        width: 120,
      },
      {
        title: t("metrics.countryCode"),
        dataIndex: "country_code",
        key: "country_code",
        width: 120,
      },
      { title: t("metrics.city"), dataIndex: "city", key: "city", width: 120 },
      {
        title: t("metrics.iPAddress"),
        dataIndex: "ipAddress",
        key: "ipAddress",
        width: 140,
      },
      {
        title: t("metrics.device"),
        dataIndex: "device",
        key: "device",
        width: 120,
      },
    ];

    // Dynamic columns based on answer variables
    const answerColumns = data[0].answerVariables.map((variable) => ({
      title: variable.key.charAt(0).toUpperCase() + variable.key.slice(1),
      dataIndex: ["answerVariables", variable.key],
      key: variable.key,
      render: (text, record) => {
        const answer = record.answerVariables.find(
          (v) => v.key === variable.key
        );
        return answer ? answer.value : "";
      },
    }));

    return [...baseColumns, ...answerColumns];
  }, [data, t]);

  // Handle search functionality
  const handleSearch = useCallback(
    (value) => {
      setSearchText(value);
      const filtered = data.filter((item) =>
        Object.values(item).some(
          (val) =>
            val &&
            typeof val === "string" &&
            val.toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filtered);
    },
    [data]
  );

  // Handle date range filter
  const handleDateRangeChange = useCallback(
    (dates) => {
      setDateRange(dates);
      if (dates && dates.length === 2) {
        const filtered = data.filter((item) => {
          const chatDate = dayjs(item.chatDate);
          return chatDate.isAfter(dates[0]) && chatDate.isBefore(dates[1]);
        });
        setFilteredData(filtered);
      } else {
        setFilteredData(data);
      }
    },
    [data]
  );

  // Handle CSV export
  const handleExportCsv = useCallback(async () => {
    try {
      setExportLoading(true);
      setExportModalVisible(true);

      // Call the API to export data
      const response = await exportChatbotDataToCsv(selectedChatbot);

      setExportLoading(false);
      setExportModalVisible(false);

      if (response && response.data.downloadLink) {
        setDownloadLink(response.data.downloadLink);
        setDownloadLinkModalVisible(true);
      } else {
        throw new Error("No download link received");
      }
    } catch (error) {
      setExportLoading(false);
      setExportModalVisible(false);
      message.error(t("metrics.exportFailed"));
      console.error("Export failed:", error);
    }
  }, [t]);

  // Generate table columns
  const columns = generateColumns();

  // Fetch chatbot with answer variables
  const fetchChatbotWithAnswerVariables = async (
    bid,
    page = pagination.current,
    pageSize = pagination.pageSize,
    search = searchText
  ) => {
    setLoading(true);
    try {
      const res = await getChatbotWithAnswerVariablesPaginated(
        bid,
        page,
        pageSize,
        search
      );

      setFilteredData(res.data.responses);
      setData(res.data.responses);
      setPagination((prev) => ({
        ...prev,
        current: res.data.paginationData.page,
        pageSize: res.data.paginationData.perPage,
        total: res.data.paginationData.totalPages,
        totalResponses: res.data.paginationData.totalResponses,
      }));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching templates", err);
    }
  };

  // Handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    const searchParams = new URLSearchParams();
    searchParams.set("page", page);
    searchParams.set("pageSize", pageSize);
    if (searchText) searchParams.set("search", searchText);

    history.push({ search: searchParams.toString() });
    fetchChatbotWithAnswerVariables(
      selectedChatbot,
      page,
      pageSize,
      searchText
    );
  };

  return (
    <Card className="response-table-card" bodyStyle={{ padding: 0 }}>
      {/* Header section with title and actions */}
      <div className="response-table-header">
        <h2>{t("metrics.responseTable")}</h2>
        <div className="response-table-actions">
          <Input
            placeholder={t("metrics.search")}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200, marginRight: 16 }}
          />
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExportCsv}
            loading={exportLoading}
          >
            {t("metrics.exportData")}
          </Button>
        </div>
      </div>

      {/* Loading spinner when parent component is loading */}
      {parentLoading ? (
        <div className="loading-container">
          <Spin size="large" />
          <p>{t("metrics.loadingData")}</p>
        </div>
      ) : !chatbotResponsesAnswerVariables ? (
        // Message when no data is available
        <div className="loading-container">
          <p>{t("metrics.noDataAvailable")}</p>
        </div>
      ) : (
        // Table component to display the data
        <Table
          columns={columns}
          dataSource={filteredData}
          scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.totalResponses,
            showTotal: (total) =>
              `${t("metrics.total")} ${total} ${t("metrics.items")}`,
            style: {
              display: "flex",
              justifyContent: "center",
            },
          }}
          onChange={({ current, pageSize }) =>
            handlePaginationChange(current, pageSize)
          }
          rowKey={(record) => record._id}
          loading={loading}
          tableLayout="fixed"
        />
      )}

      {/* Modal to show loading state during export */}
      <Modal
        visible={exportModalVisible}
        footer={null}
        closable={false}
        centered
      >
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="large" />
          <p style={{ marginTop: "10px" }}>{t("metrics.exportingData")}</p>
        </div>
      </Modal>

      {/* Modal to show download link after successful export */}
      <Modal
        visible={downloadLinkModalVisible}
        onOk={() => {
          window.open(downloadLink, "_blank");
          setDownloadLinkModalVisible(false);
        }}
        onCancel={() => setDownloadLinkModalVisible(false)}
        centered
        title={t("metrics.exportComplete")}
      >
        <p>{t("metrics.exportReadyMessage")}</p>
        <a href={downloadLink} target="_blank" rel="noopener noreferrer">
          {t("metrics.clickToDownload")}
        </a>
      </Modal>

      {/* Styles for the component */}
      <style jsx>{`
        .response-table-card {
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          height: 100vh;
        }
        .response-table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 24px;
          border-bottom: 1px solid #f0f0f0;
        }
        .response-table-header h2 {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
        }
        .response-table-actions {
          display: flex;
          align-items: center;
        }
        .loading-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 200px);
        }
        :global(.ant-table-thead > tr > th) {
          background-color: #f7f7f7;
          font-weight: 600;
        }
        :global(.ant-table-tbody > tr > td) {
          padding: 12px 16px;
        }
        :global(.ant-table-tbody > tr:hover > td) {
          background-color: #f5f5f5;
        }
      `}</style>
    </Card>
  );
};

export default ResponseTable;
