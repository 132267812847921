import { Menu } from "antd";

const TemplateFilters = ({ categories, category, handleCategoryChange }) => {
  return (
    <>
      <Menu
        style={{
          height: "100%",
          borderRadius: "8px",
          position: "relative",
        }}
        selectedKeys={[category]}
        mode="inline"
        id="joyride-bot-templates-filters"
      >
        {categories?.map((category) => (
          <Menu.Item
            key={category}
            selectedKeys={[category]}
            onClick={(value) => handleCategoryChange(value.key)}
            style={{
              color: "#0b4eb8",
            }}
          >
            {category}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};

export default TemplateFilters;
