import { FC, useCallback, useEffect, useState } from "react";
import { Col, Row, Checkbox } from "antd";
import { shallow } from "zustand/shallow";
import { SelectOptionNode } from "../../../../common/nodes/typings";
import useStore, { selector } from "../../../../store/store";
import QuillEditor from "../../../../common/components/quillEditor/QuillEditor";

const SelectOptionNodeDataEditor: FC<SelectOptionNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [selectPrompt, setSelectPrompt] = useState(state?.data?.selectPrompt);
  const [option1, setOption1] = useState(state?.data?.option1);
  const [option2, setOption2] = useState(state?.data?.option2);
  const [option3, setOption3] = useState(state?.data?.option3);
  const [option4, setOption4] = useState(state?.data?.option4);
  const [option5, setOption5] = useState(state?.data?.option5);
  const [disableOption3, setDisableOption3] = useState(
    state?.data?.disableOption3
  );
  const [disableOption4, setDisableOption4] = useState(
    state?.data?.disableOption4
  );
  const [disableOption5, setDisableOption5] = useState(
    state?.data?.disableOption5
  );

  // Each time nCheckOptionsNodeData changes, update the state
  useEffect(() => {
    setSelectPrompt(state?.data?.selectPrompt);
    setOption1(state?.data?.option1);
    setOption2(state?.data?.option2);
    setOption3(state?.data?.option3);
    setOption4(state?.data?.option4);
    setOption5(state?.data?.option5);
    setDisableOption3(state?.data?.disableOption3);
    setDisableOption4(state?.data?.disableOption4);
    setDisableOption5(state?.data?.disableOption5);
  }, [id]);

  // Handle checkOptions prompt change
  const handleSelectPromptChange = (content: any) => {
    setSelectPrompt(content);

    if (!state) return;

    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        selectPrompt: content,
      },
    });
  };

  // Handle Select Options option change
  const handleSelectOptionTextChange = useCallback(
    (content: any, attrKey: any) => {
      if (!state) return;

      if (attrKey === "option1") {
        // Set the option1
        setOption1(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            option1: content,
          },
        });
      }
      if (attrKey === "option2") {
        // Set the option2
        setOption2(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            option2: content,
          },
        });
      }
      if (attrKey === "option3") {
        // Set the option3
        setOption2(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            option3: content,
          },
        });
      }
      if (attrKey === "option4") {
        // Set the option4
        setOption2(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            option4: content,
          },
        });
      }
      if (attrKey === "option5") {
        // Set the option5
        setOption2(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            option5: content,
          },
        });
      }
    },
    [state, type, changeNodeData]
  );

  // Handle disable options change
  const handleDisableOptionChange = useCallback(
    (e: any, attrKey: any) => {
      if (!state) return;

      if (attrKey === "option3") {
        // Set the option3
        setDisableOption3(e.target.checked);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            disableOption3: e.target.checked,
          },
        });
      }
      if (attrKey === "option4") {
        // Set the option4
        setDisableOption4(e.target.checked);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            disableOption4: e.target.checked,
          },
        });
      }
      if (attrKey === "option5") {
        // Set the option5
        setDisableOption5(e.target.checked);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            disableOption5: e.target.checked,
          },
        });
      }
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              color: "gray",
            }}
          >
            Use this node when you want the user to select an option from 2 - 10
            options. The flow will continue in the same direction irrespective
            of the option selected.
          </p>
        </Row>

        {/* Question Text */}
        <Col span={6} className="sidebar__heading">
          Question Text
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={selectPrompt}
              onEditorChange={handleSelectPromptChange}
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />

        <Col
          span={24}
          style={{
            marginRight: "10px",
          }}
          className="sidebar__heading"
        >
          Option 1
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={option1}
              onEditorChange={(content) =>
                handleSelectOptionTextChange(content, 1)
              }
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />

        <Col
          span={24}
          style={{
            marginRight: "10px",
          }}
          className="sidebar__heading"
        >
          Option 2
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={option2}
              onEditorChange={(content) =>
                handleSelectOptionTextChange(content, 1)
              }
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />

        {/* Radio Button to disable third option */}
        <Col span={16}>
          <p
            style={{
              marginTop: "10px",
            }}
            className="sidebar__heading"
          >
            Disable third option
          </p>
        </Col>
        <Col span={2}>
          <Checkbox
            checked={disableOption3}
            onChange={(e) => handleDisableOptionChange(e, "option3")}
          />
        </Col>

        {/* 1 Line space */}
        {!disableOption3 && (
          <>
            <div
              style={{
                height: "15px",
                width: "100%",
              }}
            />

            <Col
              span={24}
              style={{
                marginRight: "10px",
              }}
              className="sidebar__heading"
            >
              Option 3
            </Col>
            <Col span={24}>
              <div>
                <QuillEditor
                  initialContent={option3}
                  onEditorChange={(content) =>
                    handleSelectOptionTextChange(content, 1)
                  }
                />
              </div>
            </Col>
          </>
        )}

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />

        {/* Radio Button to disable fourth option */}
        <Col span={16}>
          <p
            style={{
              marginTop: "10px",
            }}
            className="sidebar__heading"
          >
            Disable fourth option
          </p>
        </Col>
        <Col span={2}>
          <Checkbox
            checked={disableOption4}
            onChange={(e) => handleDisableOptionChange(e, "option4")}
          />
        </Col>

        {!disableOption4 && (
          <>
            {/* 1 Line space */}
            <div
              style={{
                height: "15px",
                width: "100%",
              }}
            />

            <Col
              span={24}
              style={{
                marginRight: "10px",
              }}
              className="sidebar__heading"
            >
              Option 4
            </Col>
            <Col span={24}>
              <div>
                <QuillEditor
                  initialContent={option4}
                  onEditorChange={(content) =>
                    handleSelectOptionTextChange(content, 1)
                  }
                />
              </div>
            </Col>
          </>
        )}

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />

        {/* Radio Button to disable fifth option */}
        <Col span={16}>
          <p
            style={{
              marginTop: "10px",
            }}
            className="sidebar__heading"
          >
            Disable fifth option
          </p>
        </Col>
        <Col span={2}>
          <Checkbox
            checked={disableOption5}
            onChange={(e) => handleDisableOptionChange(e, "option5")}
          />
        </Col>

        {!disableOption5 && (
          <>
            {/* 1 Line space */}
            <div
              style={{
                height: "15px",
                width: "100%",
              }}
            />

            <Col
              span={24}
              style={{
                marginRight: "10px",
              }}
              className="sidebar__heading"
            >
              Option 5
            </Col>
            <Col span={24}>
              <div>
                <QuillEditor
                  initialContent={option5}
                  onEditorChange={(content) =>
                    handleSelectOptionTextChange(content, 1)
                  }
                />
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default SelectOptionNodeDataEditor;
