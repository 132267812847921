import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  createOnboardingSubscription,
  handleFreePlanSubscription,
} from "../../api/subscription/stripe";

const PricingItem = (props) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // If the user is already subscribed, redirect to dashboard
  useEffect(() => {
    if (user?.subscribe_complete) {
      history.push(`/a/ws/${user.workspaceId}/dashboard`);
    }
  }, [user, history]);

  // Handle subscribe click
  const handlePlanClick = async (e, item) => {
    e.preventDefault();

    // Base pricing id that the user pays upfront
    const basePricingId = props.annualCycle
      ? item.stripeAnnualPriceId
      : item.stripeMonthlyPriceId;

    // Metered pricing id that the user pays at the end of each month if usage exceeds the base pricing
    const meteredPricingId = props.annualCycle
      ? item.stripeAnnualMeteredPriceId
      : item.stripeMonthlyMeteredPriceId;

    // If new subscription
    setLoading(true);
    createOnboardingSubscription({ basePricingId, meteredPricingId })
      .then((res) => {
        window.location.href = res.data.checkoutUrl;
      })
      .catch(() => {
        setLoading(false);
        history.push("/subscribe");
      });
  };

  const handleFreePlanClick = (e) => {
    e.preventDefault();
    setLoading(true);
    // Run this with 2 seconds delay to show the loading state
    setTimeout(() => {
      handleFreePlanSubscription()
        .then(() => {
          history.push(`/a/ws/${user.workspaceId}/dashboard`);
        })
        .catch(() => {
          setLoading(false);
          history.push("/subscribe");
        });
    }, 2000);
  };

  // SVG Tick Icon
  const listSVGTick = (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99992 13.6667C3.31792 13.6667 0.333252 10.682 0.333252 7.00004C0.333252 3.31804 3.31792 0.333374 6.99992 0.333374C10.6819 0.333374 13.6666 3.31804 13.6666 7.00004C13.6666 10.682 10.6819 13.6667 6.99992 13.6667ZM6.99992 12.3334C8.41441 12.3334 9.77096 11.7715 10.7712 10.7713C11.7713 9.77108 12.3333 8.41453 12.3333 7.00004C12.3333 5.58555 11.7713 4.229 10.7712 3.2288C9.77096 2.22861 8.41441 1.66671 6.99992 1.66671C5.58543 1.66671 4.22888 2.22861 3.22868 3.2288C2.22849 4.229 1.66659 5.58555 1.66659 7.00004C1.66659 8.41453 2.22849 9.77108 3.22868 10.7713C4.22888 11.7715 5.58543 12.3334 6.99992 12.3334ZM6.33525 9.66671L3.50659 6.83804L4.44925 5.89537L6.33525 7.78137L10.1059 4.01004L11.0493 4.95271L6.33525 9.66671Z"
        fill="#0010F7"
      />
    </svg>
  );

  const getDiscountPercentage = (originalPrice, currentPrice) => {
    return Math.round(((originalPrice - currentPrice) / originalPrice) * 100);
  };

  // Sort plans in the desired order
  const order = ["Free", "Starter", "Pro", "Business"];
  const sortedPlans = props.plans.sort(
    (a, b) => order.indexOf(a.name) - order.indexOf(b.name)
  );

  const listMap = sortedPlans.map((item, index) => (
    <Col
      className={`hp-pricing-item hp-p-8 hp-mx-xl-8 hp-mx-16 hp-mb-sm-24 hp-mb-16`}
      key={index}
      align="middle"
      style={{
        position: "relative",
        backgroundColor: "#fff",
        border: "1px solid #E8E8E8",
        borderRadius: "8px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
        padding: "32px",
        width: "100%",
        maxWidth: "250px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {item.name === "Pro" && <div className="most-popular">Most Popular</div>}

      <div style={{ flex: 1 }}>
        <Row justify="space-between">
          <Col span={24}>
            <h4
              className="hp-mb-0 hp-pricing-item-title"
              style={{
                marginTop: "8px",
                fontSize: "22px",
                fontWeight: "600",
                textAlign: "center",
                color: "#0010F7",
              }}
            >
              {item.name}
            </h4>
            <p className="hp-pricing-item-subtitle hp-caption hp-mb-sm-8 hp-mb-32 hp-text-color-black-60"></p>
          </Col>
        </Row>

        <span className="hp-pricing-item-price h2">
          {/* Display meta description on top */}
          <span
            className="hp-pricing-item-price-free hp-caption hp-text-color-primary-1"
            style={{
              display: "block",
              marginBottom: "8px",
              fontSize: "14px",
              marginTop: "-16px",
              color: "#0010F7",
            }}
          >
            {item.metaDescription}
          </span>

          {/* Check if the price is not 0 */}
          {props.annualCycle ? (
            item.annualPrice !== 0 ? (
              <>
                <span
                  style={{
                    color: "red",
                    fontSize: "22px",
                    fontWeight: "bold",
                    display: "block",
                    position: "relative",
                  }}
                >
                  -
                  {getDiscountPercentage(
                    item.annualPrice * 10,
                    item.annualPrice
                  )}
                  %
                </span>
                <span className="actual-price">${item.annualPrice}</span>
                <span className="scratched-price" style={{ marginLeft: "8px" }}>
                  ${Math.round(item.annualPrice * 10 * 100) / 100}
                </span>
              </>
            ) : (
              <span className="actual-price">${item.annualPrice}</span>
            )
          ) : item.monthlyPrice !== 0 ? (
            <>
              <span
                style={{
                  color: "red",
                  fontSize: "22px",
                  fontWeight: "bold",
                  display: "block",
                  position: "relative",
                }}
              >
                -
                {getDiscountPercentage(
                  item.monthlyPrice * 10,
                  item.monthlyPrice
                )}
                %
              </span>
              <span className="actual-price">${item.monthlyPrice}</span>
              <span className="scratched-price" style={{ marginLeft: "8px" }}>
                ${Math.round(item.monthlyPrice * 10 * 100) / 100}
              </span>
            </>
          ) : (
            <span className="actual-price">${item.monthlyPrice}</span>
          )}
        </span>

        <p
          className="hp-pricing-item-billed hp-caption hp-mt-sm-0 hp-mt-4 hp-mb-0 hp-text-color-black-90"
          style={{
            fontSize: "16px",
            textAlign: "center",
            color: "#0010F7",
          }}
        >
          {props.billedText}
        </p>

        <ul className="hp-mt-24 hp-mb-0 hp-p-0">
          {item.features.map((item, index) => (
            <li
              key={index}
              className={`hp-pricing-item-list hp-d-flex-center hp-mt-8`}
            >
              {listSVGTick}
              <span
                className={`hp-d-block hp-ml-8 hp-caption`}
                style={{
                  paddingLeft: "8px",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                {item}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {item?.name === "Free" ? (
        <Button
          className="hp-mt-32"
          block
          onClick={(e) => {
            handleFreePlanClick(e);
          }}
          loading={loading}
          style={{
            backgroundColor: "green",
            color: "#fff",
            fontWeight: "bold",
            marginTop: "auto",
          }}
        >
          Start FREE
        </Button>
      ) : (
        <Button
          className="hp-mt-32"
          block
          type="primary"
          onClick={(e) => {
            handlePlanClick(e, item);
          }}
          loading={loading}
          style={{
            fontWeight: "bold",
            marginTop: "auto",
          }}
        >
          Subscribe
        </Button>
      )}
    </Col>
  ));

  return (
    <>
      <Row
        className="hp-mt-32 hp-mb-32"
        justify="center"
        style={{
          height: "70%",
        }}
      >
        {listMap}
      </Row>
    </>
  );
};

export default PricingItem;
