import { useEffect, useState } from "react";
import { Switch } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "../../../../../../redux/actions/chatbotActions";
import KbIcon from "../../../../../../assets/images/pages/customize/knowledge-base-2.png";
import { useTranslation } from "react-i18next";

const KnowledgeBaseOptions = () => {
  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setVisible(customizations?.enableKnowledgeBase);
  }, [customizations?.enableKnowledgeBase]);

  const toggleEnableKnowledgeBase = (key) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, !visible));
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => toggleEnableKnowledgeBase("enableKnowledgeBase")}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={KbIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="cta"
            />
            {t("customize.knowledgeBase.enableKnowledgeBase")}
          </span>
          <Switch checked={visible} size="small" />
        </div>
      </div>
    </>
  );
};

export default KnowledgeBaseOptions;
