import React, { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Input, Select, Switch } from "antd";
import { shallow } from "zustand/shallow";
import useStore, { selector } from "../../../../../store/store";
import { VariableNode } from "../../../../../common/nodes/typings";
import { extractAnswerVariables } from "../../../../../helpers/extractAnswerVariables";
import { useTranslation } from "react-i18next";

const VariableNodeDataEditor: FC<VariableNode> = ({ id, type }) => {
  const { getNode, changeNodeData, nodes } = useStore(selector, shallow);
  const state = getNode(id);

  const { t } = useTranslation();

  // State to manage whether to use dropdown or custom input
  const [useCustomNameValue, setUseCustomNameValue] = useState(
    state?.data?.customNameValue
  );
  const [name, setName] = useState(state?.data?.name);
  const [isNumber, setIsNumber] = useState(state?.data?.isNumber);
  const [value, setValue] = useState(state?.data?.value);

  const [allAnswerVariables, setAllAnswerVariables] = useState([]);

  useEffect(() => {
    // Name states
    setUseCustomNameValue(state?.data?.customNameValue || false);
    setName(state?.data?.name);

    // Value states
    setIsNumber(state?.data?.isNumber);
    setValue(state?.data?.value);

    const answerVariables = extractAnswerVariables(nodes);
    setAllAnswerVariables(answerVariables);
  }, [state?.data, nodes]);

  const handleSetIsNumber = useCallback(
    (value: boolean) => {
      setIsNumber(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, isNumber: value },
      });
    },
    [state, type, changeNodeData]
  );

  const handleVariableNameChange = useCallback(
    (newValue: any) => {
      const updatedName = useCustomNameValue ? newValue.target.value : newValue;
      setName(updatedName);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, name: updatedName, useCustomNameValue },
      });
    },
    [state, type, changeNodeData, useCustomNameValue]
  );

  const handleVariableValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setValue(newValue);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, value: newValue },
      });
    },
    [state, type, changeNodeData]
  );

  const toggleUseCustomNameValue = () => {
    setUseCustomNameValue(!useCustomNameValue);

    // Change node data
    changeNodeData({
      ...state,
      type,
      data: { ...state?.data, customNameValue: !useCustomNameValue },
    });
  };

  return (
    <>
      <Row align="middle">
        {/* Description */}
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.logicalNodes.variableNodeInfo")}
          </p>
        </Row>

        {/* Variable Name Input */}
        <Col span={6}>
          <b>{t("flowBuilder.sidebar.editor.logicalNodes.name")}</b>
        </Col>
        <Col span={18}>
          <Switch
            size="small"
            checked={useCustomNameValue}
            onChange={toggleUseCustomNameValue}
          />

          <span style={{ marginLeft: "10px" }}>
            {!useCustomNameValue
              ? "Select from answer variables"
              : "Type manually"}
          </span>
          {!useCustomNameValue ? (
            <Select
              style={{ width: "100%", marginTop: "10px" }}
              value={name}
              onChange={handleVariableNameChange}
            >
              {allAnswerVariables.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              style={{ width: "100%", marginTop: "10px" }}
              type="text"
              value={name}
              onChange={handleVariableNameChange}
            />
          )}
        </Col>

        {/* Spacing */}
        <div style={{ height: "14px", width: "100%" }} />

        {/* Variable Type Toggle */}
        <Col span={24}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ marginRight: "10px" }}>
              <b>Value Type:</b>
            </label>
            <Switch
              size="small"
              checked={isNumber}
              onChange={handleSetIsNumber}
            />
            <span style={{ marginLeft: "10px" }}>
              {isNumber ? "Number" : "String"}
            </span>
          </div>
        </Col>

        {/* Spacing */}
        <div style={{ height: "14px", width: "100%" }} />

        {/* Value Input */}
        <Col span={6}>
          <b>{t("flowBuilder.sidebar.editor.logicalNodes.value")}</b>
        </Col>
        <Col span={18}>
          <Input
            type={isNumber ? "number" : "text"}
            value={value}
            onChange={handleVariableValueChange}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default VariableNodeDataEditor;
