import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select, Spin } from "antd";
import { withRouter } from "react-router";
import {
  getChatbotById,
  updateChatbotById,
} from "../../../api/chatbot/chatbot";
import { getAllCategories } from "../../../api/template/template";
import { SuccessNotification, ErrorNotification } from "../..";

const initialState: any = {
  name: "",
  category: "",
};

const ChatbotUpdateModal = ({
  open,
  handleModalClick,
  chatbotId,
  templateId,
  handleFetchChatbots,
}: any) => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setFetchLoading(true); // Start loading

    fetchData(); // Invoke the async function

    fetchCategories();
  }, []);

  const fetchData = async () => {
    try {
      const res = await getChatbotById(chatbotId);
      setValues(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFetchLoading(false); // End loading once data is fetched
    }
  };

  // Handle fetch categories
  const fetchCategories = async () => {
    setFetchLoading(true);
    const res = await getAllCategories();
    setCategories(res.data);
    setFetchLoading(false);
  };

  // Handle form change
  const handleFormChange = (e: any) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle chatbot update
  const handleUpdate = async (e: any) => {
    e.preventDefault();

    // Validate form
    if (!values.name) {
      return ErrorNotification("Please enter a name");
    }

    // Start loading
    setLoading(true);

    // Update the chatbot
    updateChatbotById(chatbotId, {
      ...values,
      name: values.name,
      category: values.category,
    })
      .then((res) => {
        SuccessNotification(`${res.data.name} updated successfully`);
        handleFetchChatbots();
        handleModalClick();

        // Set loading to false
        setLoading(false);
      })
      .catch((err) => {
        // Set loading to false
        setLoading(false);

        ErrorNotification(err.response.data.message);
      });
  };

  return (
    <>
      <Modal
        title="Create Chatbot"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleUpdate}
            loading={loading}
          >
            Update
          </Button>,
        ]}
      >
        {fetchLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              minHeight: "8rem",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <Form
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              {/* Chatbot name */}
              <Form.Item label="Name">
                <Input
                  name="name"
                  placeholder="Name your bot"
                  onChange={handleFormChange}
                  value={values.name}
                />
              </Form.Item>

              {/* Select drop down with category */}
              <Form.Item label="Category">
                <Select
                  placeholder="Select a category"
                  onChange={(value) =>
                    setValues({ ...values, category: value })
                  }
                  value={values.category}
                >
                  {categories.map((category) => (
                    <Select.Option key={category._id} value={category.name}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default withRouter(ChatbotUpdateModal);
