import { useEffect } from "react";
// Redux Action
import { useSelector } from "react-redux";
import { FlowPreview } from "../../../../../components";
// Components
import { _displayBotMessage } from "./ts/displayStep";
// Handlers
import {
  // ** LOGICAL ** //
  _handleConditionNode,
  _handleBusinessHoursNode,
  _handleMathOperationNode,
  _handleBooleanLogicNode,
  _handleRandomFlowNode,
  _handleVariableNode,
  // ** SPECIAL ** //
  _handleDelayNode,
  // ** INTEGRATIONS ** //
  _handleWebhookNode,
  _handleGPTNode,
} from "./ts/handlers";

// ----- Bot metadata -----
let embedType = "live_chat";
let steps: any = [];

export let nodes: any = [];
export let edges: any = [];

// ----- Bot session data -----
let currentIndex = 0;
let record: any = [];
let transcript: any = [];
let answerVariables: any = [];
let variables: any = {};

// ----- Bot chat data -----
let customizationsRef: any = {};

// Bot Logic Variables
let chatStarted = false;
let showTypingAnimation = false;

// ----- Visitor metadata -----
let visitorName = "";
let visitorEmail = "";
let visitorPhone = "";

// ----- Bot logic functions -----
// Get current steps and index
export const _getCurrentStepsAndIndex: any = () => {
  return { steps, currentIndex };
};

// Get variables
export const _getVariables = () => {
  return variables;
};

// Set variables like this variables[steps[currentIndex].data.varName]
export const _setVariables = (varName: any, value: any) => {
  variables[varName] = value;
};

// Push data to record
export const _pushDataToRecord = (object: any) => {
  // Add time to the object
  object.time = new Date().toISOString();

  record.push(object);
};

// Get answer variables
export const _getAnswerVariables = () => {
  return answerVariables;
};

// Helper function to get the value of an answer variable key
export const _getValueFromAnswerVariableKey = (key: any) => {
  const variable = answerVariables?.find((v: any) => v.key === key);
  return variable ? variable.value : null;
};

// Helper function to get the nodeId of a answer variable key
export const _getNodeIdFromAnswerVariableKey = (key: any) => {
  const variable = answerVariables?.find((v: any) => v.key === key);
  return variable ? variable.nodeId : null;
};

// Set answer variables based on node id
export const _setAnswerVariable = (nodeId: any, value: any) => {
  // In the answerVariables array, find the object where nodeId matches the sent nodeId
  let variableObject = answerVariables?.find(
    (item: any) => item.nodeId === nodeId
  );

  // If such an object exists, update its value
  if (variableObject) {
    variableObject.value = value;
  }
};

// Set user metadata
export const _setUserMetaData = (type: any, value: any) => {
  if (type === "name") {
    visitorName = value;
  } else if (type === "email") {
    visitorEmail = value;
  } else if (type === "phone" || type === "mobile") {
    visitorPhone = value;
  }
};

// Set display next node
export const _displayNextNode = () => {
  // Display the next node
  _findAndDisplayNode();
};

// Display bot message and scroll
export const _displayBotMessageAndScroll = (
  showTypingAnimation: any,
  embedType: any,
  customizationsRef: any,
  steps: any,
  currentIndex: any
) => {
  // Set the show typing animation variable to true
  showTypingAnimation = true;

  _displayBotMessage(
    showTypingAnimation,
    embedType,
    customizationsRef,
    steps,
    currentIndex
  );

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;

  if (chatBody) {
    chatBody.scrollTop = chatBody?.scrollHeight + 800;
  }
};

// Handle add to transcript array
export const _handleAddToTranscript = (by: any, message: any) => {
  // Push the message to the transcript array
  transcript.push({
    by: by,
    message: message,
  });
};

// Get transcript
export const _getTranscript = () => {
  return transcript;
};

// Get customizations ref
export const _getCustomizationsRef = () => {
  return customizationsRef;
};

// Node categorize helper
const _nodeCategorizeHelper = (by: any, node: any) => {
  if (by === "bot") {
    // Switch based on the node type and by
    switch (node.type) {
      // ******** V1 Nodes (Deprecated) ******** //
      case "select-option-node":
        _handleAddToTranscript("bot", node.data.selectPrompt);
        break;
      case "two-choices-node":
        _handleAddToTranscript("bot", node.data.choicePrompt);
        break;
      case "three-choices-node":
        _handleAddToTranscript("bot", node.data.choicePrompt);
        break;
      case "user-rating-node":
        _handleAddToTranscript("bot", node.data.ratingPrompt);
        break;
      case "user-input-node":
        _handleAddToTranscript("bot", node.data.inputPrompt);
        break;
      case "user-range-node":
        _handleAddToTranscript("bot", node.data.rangePrompt);
        break;
      case "quiz-node":
        _handleAddToTranscript("bot", node.data.quizPrompt);
        break;
      // ******** V2 Nodes ******** //
      // ---- Send Response Nodes ---- //
      case "message-node":
        _handleAddToTranscript("bot", node.data.text);
        break;
      case "image-node":
        _handleAddToTranscript("bot", node.data.image);
        break;
      case "video-node":
        _handleAddToTranscript("bot", node.data.video);
        break;
      case "audio-node":
        _handleAddToTranscript("bot", node.data.audio);
        break;
      case "file-node":
        _handleAddToTranscript("bot", node.data.file);
        break;

      // ---- Ask Question Nodes ---- //
      case "ask-name-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-email-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-phone-number-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-number-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-url-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-file-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-location-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-custom-question-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "ask-multiple-questions-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If multiple questions, loop through the questions and add them to the answerVariable, with id being the question node id followed by the index of the question
        node.data.questions.forEach((question: any, index: any) => {
          // If the question has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
          if (question.answerVariable) {
            answerVariables.push({
              nodeId: node.id + index,
              key: question.answerVariable,
              value: null,
            });
          }
        });

        break;
      case "calendar-node":
        _handleAddToTranscript("bot", node.data.calendarPrompt);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "n-select-option-node":
        _handleAddToTranscript("bot", node.data.selectPrompt);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "n-check-options-node":
        _handleAddToTranscript("bot", node.data.checkOptionsPrompt);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      // ---- Flow Operation Nodes ---- //
      case "n-choices-node":
        _handleAddToTranscript("bot", node.data.choicePrompt);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "image-choice-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "rating-choice-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "yes-or-no-choice-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      case "opinion-scale-choice-node":
        _handleAddToTranscript("bot", node.data.questionText);

        // If the node has answerVariable, add it to the answerVariables array with the node id, key as answerVariable and value as null (to be filled later)
        if (node.data.answerVariable) {
          answerVariables.push({
            nodeId: node.id,
            key: node.data.answerVariable,
            value: null,
          });
        }
        break;
      // ---- Special Nodes ---- //
      case "user-redirect-node":
        _handleAddToTranscript("bot", node.data.redirectPrompt);
        break;
      case "html-node":
        _handleAddToTranscript("bot", node.data.html);
        break;

      default:
        break;
    }
  }
};

// Find next node using edge
const _findNextElement = (node: any) => {
  // Find the edge
  const edge = edges?.find((edge: any) => edge.source === node?.id);

  // Push the edge to the record array
  record.push(edge);

  // Find the next node
  const nextNode = nodes?.find((node: any) => node.id === edge?.target);

  return nextNode;
};

// Find and display node
export const _findAndDisplayNode = () => {
  // If length of steps is 0,find the welcome node and add it to the steps array
  if (steps.length === 0 && !chatStarted) {
    steps.push(nodes?.filter((node: any) => node?.type === "welcome-node")[0]);

    // Add the welcome-node node to the record array, also in the welcome node add a time field with the current time
    record.push({
      ...nodes?.filter((node: any) => node?.type === "welcome-node")[0],
      time: new Date().toISOString(),
    });

    // Add by: bot and the welcome-node text to the transcript array
    transcript = [
      {
        by: "bot",
        message: nodes?.find((node: any) => node.type === "welcome-node").data
          .text,
      },
    ];

    // Set the chat started variable to true
    chatStarted = true;

    // Set the show typing animation variable to true
    showTypingAnimation = true;

    _displayBotMessage(
      showTypingAnimation,
      embedType,
      customizationsRef,
      steps,
      currentIndex
    );

    // Select the chat body, scroll to bottom
    const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;

    if (chatBody) {
      chatBody.scrollTop = chatBody?.scrollHeight + 800;
    }
    return;
  }

  // Find the next node
  const nextNode = _findNextElement(steps[currentIndex]);

  if (nextNode) {
    // Push the next node to the steps array
    steps.push(nextNode);

    // Record the next node in the record array and also add a time field with the current time
    record.push({
      ...nextNode,
      time: new Date().toISOString(),
    });

    currentIndex++;

    if (
      // (v1) Legacy nodes
      nextNode.type === "two-choices-node" ||
      nextNode.type === "three-choices-node" ||
      nextNode.type === "select-option-node" ||
      nextNode.type === "user-rating-node" ||
      nextNode.type === "user-range-node" ||
      nextNode.type === "user-input-node" ||
      nextNode.type === "quiz-node" ||
      // (v2) Nodes
      // Send response nodes
      nextNode.type === "image-node" ||
      nextNode.type === "message-node" ||
      nextNode.type === "video-node" ||
      nextNode.type === "audio-node" ||
      nextNode.type === "file-node" ||
      // Ask question nodes
      nextNode.type === "n-select-option-node" ||
      nextNode.type === "n-check-options-node" ||
      nextNode.type === "ask-name-node" ||
      nextNode.type === "ask-email-node" ||
      nextNode.type === "ask-phone-number-node" ||
      nextNode.type === "ask-number-node" ||
      nextNode.type === "ask-url-node" ||
      nextNode.type === "ask-file-node" ||
      nextNode.type === "ask-location-node" ||
      nextNode.type === "ask-custom-question-node" ||
      nextNode.type === "ask-multiple-questions-node" ||
      // Flow operation nodes
      nextNode.type === "n-choices-node" ||
      nextNode.type === "image-choice-node" ||
      nextNode.type === "rating-choice-node" ||
      nextNode.type === "yes-or-no-choice-node" ||
      nextNode.type === "opinion-scale-choice-node" ||
      // Special nodes
      nextNode.type === "user-redirect-node" ||
      nextNode.type === "calendar-node" ||
      nextNode.type === "chat-gpt-node" ||
      nextNode.type === "html-node" ||
      nextNode.type === "navigate-node"
    ) {
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );

      // Call the node categorize helper
      _nodeCategorizeHelper("bot", nextNode);
    } else if (nextNode.type === "email-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    } else if (nextNode.type === "jump-to-node") {
      _handleJumpToNode(nextNode?.data?.nodeId);
    } else if (nextNode.type === "webhook-node") {
      _handleWebhookNode(nextNode?.data, nextNode?.id, answerVariables);

      _findAndDisplayNode();
    } else if (nextNode.type === "business-hours-node") {
      _handleBusinessHoursNode(nextNode?.data);
    } else if (nextNode.type === "condition-node") {
      _handleConditionNode();
    } else if (nextNode.type === "boolean-logic-node") {
      _handleBooleanLogicNode();
    } else if (nextNode.type === "random-flow-node") {
      _handleRandomFlowNode();
    } else if (nextNode.type === "math-operation-node") {
      // Add the answer variable to the answerVariables array
      if (nextNode.data.answerVariable) {
        answerVariables.push({
          nodeId: nextNode.id,
          key: nextNode.data.answerVariable,
          value: null,
        });
      }

      _handleMathOperationNode();
    } else if (nextNode.type === "variable-node") {
      // Add the name key to the answerVariables array if data.customNameValue
      // This means, this answer variable is a custom variable
      if (nextNode.data.name && nextNode.data.customNameValue) {
        answerVariables.push({
          nodeId: nextNode.id,
          key: nextNode.data.name,
          value: null,
        });
      }

      _handleVariableNode();
    } else if (nextNode.type === "delay-node") {
      _handleDelayNode(nextNode?.data);
    } else if (nextNode.type === "human-handover-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    } else if (nextNode.type === "gpt-node") {
      _handleGPTNode(nextNode?.data);
    } else if (nextNode.type === "zapier-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    }
  } else {
    console.log("record", record);
    console.log("transcript", transcript);
    console.log("answerVariables", answerVariables);
    console.log("variables", variables);
    console.log("visitorName", visitorName);
    console.log("visitorEmail", visitorEmail);
    console.log("visitorPhone", visitorPhone);
  }
};

// Find and display node with port number
export const _findAndDisplayNodeWithPort = (sourceHandle?: any) => {
  const nextNodeEdge = edges?.find(
    (edge: any) =>
      edge?.sourceHandle === sourceHandle &&
      edge?.source === steps[currentIndex].id
  );

  if (nextNodeEdge) {
    // Push the edge to the record array
    record.push(nextNodeEdge);

    // Get the target node
    const nextNode = nodes?.find(
      (node: any) => node?.id === nextNodeEdge.target
    );

    // Add the next node to the steps array
    steps.push(nextNode);
    // Add the next node to the record array and also add a time field with the current time
    record.push({
      ...nextNode,
      time: new Date().toISOString(),
    });
    // Increment the currentIndex
    currentIndex++;

    if (
      // (v1) Legacy nodes
      nextNode.type === "two-choices-node" ||
      nextNode.type === "three-choices-node" ||
      nextNode.type === "select-option-node" ||
      nextNode.type === "user-rating-node" ||
      nextNode.type === "user-range-node" ||
      nextNode.type === "user-input-node" ||
      nextNode.type === "quiz-node" ||
      // (v2) Nodes
      // Send response nodes
      nextNode.type === "image-node" ||
      nextNode.type === "message-node" ||
      nextNode.type === "video-node" ||
      nextNode.type === "audio-node" ||
      nextNode.type === "file-node" ||
      // Ask question nodes
      nextNode.type === "n-select-option-node" ||
      nextNode.type === "n-check-options-node" ||
      nextNode.type === "ask-name-node" ||
      nextNode.type === "ask-email-node" ||
      nextNode.type === "ask-phone-number-node" ||
      nextNode.type === "ask-number-node" ||
      nextNode.type === "ask-url-node" ||
      nextNode.type === "ask-file-node" ||
      nextNode.type === "ask-location-node" ||
      nextNode.type === "ask-custom-question-node" ||
      nextNode.type === "ask-multiple-questions-node" ||
      // Flow operation nodes
      nextNode.type === "n-choices-node" ||
      nextNode.type === "image-choice-node" ||
      nextNode.type === "rating-choice-node" ||
      nextNode.type === "yes-or-no-choice-node" ||
      nextNode.type === "opinion-scale-choice-node" ||
      // Special nodes
      nextNode.type === "user-redirect-node" ||
      nextNode.type === "calendar-node" ||
      nextNode.type === "chat-gpt-node" ||
      nextNode.type === "html-node" ||
      nextNode.type === "navigate-node"
    ) {
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );

      // Call the node categorize helper
      _nodeCategorizeHelper("bot", nextNode);
    } else if (nextNode.type === "email-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    } else if (nextNode.type === "jump-to-node") {
      _handleJumpToNode(nextNode?.data?.nodeId);
    } else if (nextNode.type === "webhook-node") {
      _handleWebhookNode(nextNode?.data, nextNode?.id, answerVariables);

      _findAndDisplayNode();
    } else if (nextNode.type === "business-hours-node") {
      _handleBusinessHoursNode(nextNode?.data);
    } else if (nextNode.type === "condition-node") {
      _handleConditionNode();
    } else if (nextNode.type === "boolean-logic-node") {
      _handleBooleanLogicNode();
    } else if (nextNode.type === "random-flow-node") {
      _handleRandomFlowNode();
    } else if (nextNode.type === "math-operation-node") {
      // Add the answer variable to the answerVariables array
      if (nextNode.data.answerVariable) {
        answerVariables.push({
          nodeId: nextNode.id,
          key: nextNode.data.answerVariable,
          value: null,
        });
      }

      _handleMathOperationNode();
    } else if (nextNode.type === "variable-node") {
      // Add the name key to the answerVariables array if data.customNameValue
      // This means, this answer variable is a custom variable
      if (nextNode.data.name && nextNode.data.customNameValue) {
        answerVariables.push({
          nodeId: nextNode.id,
          key: nextNode.data.name,
          value: null,
        });
      }

      _handleVariableNode();
    } else if (nextNode.type === "delay-node") {
      _handleDelayNode(nextNode?.data);
    } else if (nextNode.type === "human-handover-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    } else if (nextNode.type === "gpt-node") {
      _handleGPTNode(nextNode?.data);
    } else if (nextNode.type === "zapier-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    }
  } else {
    console.log("record", record);
    console.log("transcript", transcript);
    console.log("answerVariables", answerVariables);
    console.log("variables", variables);
    console.log("visitorName", visitorName);
    console.log("visitorEmail", visitorEmail);
    console.log("visitorPhone", visitorPhone);
  }
};

// Handle Jump to node
export const _handleJumpToNode = (nodeId: any) => {
  // Find the node
  const node = nodes?.find((node: any) => node.id === nodeId);

  // If the node exists, find the index of the node
  if (node) {
    // Increment the currentIndex
    currentIndex++;

    // Find the node
    const nodeToDisplay = nodes?.find((node: any) => node.id === nodeId);

    // Push the node to the steps array
    steps.push(nodeToDisplay);

    // Push the node to the record array
    record.push(nodeToDisplay);

    if (
      // (v1) Legacy nodes
      nodeToDisplay.type === "two-choices-node" ||
      nodeToDisplay.type === "three-choices-node" ||
      nodeToDisplay.type === "select-option-node" ||
      nodeToDisplay.type === "user-rating-node" ||
      nodeToDisplay.type === "user-range-node" ||
      nodeToDisplay.type === "user-input-node" ||
      nodeToDisplay.type === "quiz-node" ||
      // (v2) Nodes
      // Send response nodes
      nodeToDisplay.type === "image-node" ||
      nodeToDisplay.type === "message-node" ||
      nodeToDisplay.type === "video-node" ||
      nodeToDisplay.type === "audio-node" ||
      nodeToDisplay.type === "file-node" ||
      // Ask question nodes
      nodeToDisplay.type === "n-select-option-node" ||
      nodeToDisplay.type === "n-check-options-node" ||
      nodeToDisplay.type === "ask-name-node" ||
      nodeToDisplay.type === "ask-email-node" ||
      nodeToDisplay.type === "ask-phone-number-node" ||
      nodeToDisplay.type === "ask-number-node" ||
      nodeToDisplay.type === "ask-url-node" ||
      nodeToDisplay.type === "ask-file-node" ||
      nodeToDisplay.type === "ask-location-node" ||
      nodeToDisplay.type === "ask-custom-question-node" ||
      nodeToDisplay.type === "ask-multiple-questions-node" ||
      // Flow operation nodes
      nodeToDisplay.type === "n-choices-node" ||
      nodeToDisplay.type === "image-choice-node" ||
      nodeToDisplay.type === "rating-choice-node" ||
      nodeToDisplay.type === "yes-or-no-choice-node" ||
      nodeToDisplay.type === "opinion-scale-choice-node" ||
      // Special nodes
      nodeToDisplay.type === "user-redirect-node" ||
      nodeToDisplay.type === "calendar-node" ||
      nodeToDisplay.type === "chat-gpt-node" ||
      nodeToDisplay.type === "html-node" ||
      nodeToDisplay.type === "navigate-node"
    ) {
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );

      // Call the node categorize helper
      _nodeCategorizeHelper("bot", nodeToDisplay);
    } else if (nodeToDisplay.type === "email-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    } else if (nodeToDisplay.type === "jump-to-node") {
      _handleJumpToNode(nodeToDisplay?.data?.nodeId);
    } else if (nodeToDisplay.type === "webhook-node") {
      _handleWebhookNode(
        nodeToDisplay?.data,
        nodeToDisplay?.id,
        answerVariables
      );

      _findAndDisplayNode();
    } else if (nodeToDisplay.type === "business-hours-node") {
      _handleBusinessHoursNode(nodeToDisplay?.data);
    } else if (nodeToDisplay.type === "condition-node") {
      _handleConditionNode();
    } else if (nodeToDisplay.type === "boolean-logic-node") {
      _handleBooleanLogicNode();
    } else if (nodeToDisplay.type === "random-flow-node") {
      _handleRandomFlowNode();
    } else if (nodeToDisplay.type === "math-operation-node") {
      // Add the answer variable to the answerVariables array
      if (nodeToDisplay.data.answerVariable) {
        answerVariables.push({
          nodeId: nodeToDisplay.id,
          key: nodeToDisplay.data.answerVariable,
          value: null,
        });
      }

      _handleMathOperationNode();
    } else if (nodeToDisplay.type === "variable-node") {
      // Add the name key to the answerVariables array if data.customNameValue
      // This means, this answer variable is a custom variable
      if (nodeToDisplay.data.name && nodeToDisplay.data.customNameValue) {
        answerVariables.push({
          nodeId: nodeToDisplay.id,
          key: nodeToDisplay.data.name,
          value: null,
        });
      }

      _handleVariableNode();
    } else if (nodeToDisplay.type === "delay-node") {
      _handleDelayNode(nodeToDisplay?.data);
    } else if (nodeToDisplay.type === "human-handover-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    } else if (nodeToDisplay.type === "gpt-node") {
      _handleGPTNode(nodeToDisplay?.data);
    } else if (nodeToDisplay.type === "zapier-node") {
      // Display bit message and scroll
      _displayBotMessageAndScroll(
        showTypingAnimation,
        embedType,
        customizationsRef,
        steps,
        currentIndex
      );
    }
  } else {
    console.log("record", record);
    console.log("transcript", transcript);
    console.log("answerVariables", answerVariables);
    console.log("variables", variables);
    console.log("visitorName", visitorName);
    console.log("visitorEmail", visitorEmail);
    console.log("visitorPhone", visitorPhone);
  }
};

// Chatbot flow preview component
const ChatbotFlowPreview = ({
  closeModal,
  toggleModalSize,
  isCustomize = false,
}: any) => {
  const { name, elements } = useSelector(
    (state: any) => state?.chatbot?.currentBotFlowData ?? {}
  );
  const { customizations } = useSelector((state: any) => state?.chatbot ?? {});

  // Split the elements into two arrays, one for the nodes, and one for the edges
  useEffect(() => {
    if (elements && !chatStarted && !isCustomize) {
      // Get the steps element and clear the innerHTML
      const stepsElement = document.querySelector("#steps") as any;

      // Clear the innerHTML
      stepsElement.innerHTML = "";

      // Set customizations ref
      customizationsRef = customizations;

      nodes = elements?.length > 0 ? elements?.at(0)?.nodes : elements?.nodes;
      edges = elements?.length > 0 ? elements?.at(0)?.edges : elements?.edges;

      // If the current index is 0 and length of steps is 1, display the first step
      if (currentIndex === 0 && steps.length === 0 && !chatStarted) {
        _startBot();
      }
    }

    // Customizations check
    if (isCustomize) {
      // Reset the steps array
      steps = [];

      // Reset the current index
      currentIndex = 0;

      // Reset the chat started variable
      chatStarted = false;
    }
  }, [closeModal, customizations, elements, isCustomize]);

  // Function to start the bot
  const _startBot = () => {
    // If the current index is 0 and length of steps is 1, display the first step
    if (currentIndex === 0 && !chatStarted) {
      // Execute after 200ms
      _findAndDisplayNode();
    }
  };

  // Function to restart the bot
  const _restartBot = () => {
    // Reset the steps array
    steps = [];

    // Reset the current index
    currentIndex = 0;

    // Reset the chat started variable
    chatStarted = false;

    // Reset the is loading variable
    showTypingAnimation = false;

    // Reset the variables
    variables = {};

    // Reset the transcript array
    transcript = [];

    // Reset the answer variables array
    answerVariables = [];

    // Get the steps element and clear the innerHTML
    const stepsElement = document.querySelector("#steps") as any;

    // Clear the innerHTML
    stepsElement.innerHTML = "";

    // Update the state
    nodes = elements?.length > 0 ? elements?.at(0)?.nodes : elements?.nodes;
    edges = elements?.length > 0 ? elements?.at(0)?.edges : elements?.edges;

    // Execute after 200ms
    setTimeout(() => {
      _findAndDisplayNode();
    }, 200);
  };

  // Function to close the preview
  const _closePreview = () => {
    // Reset the steps array
    steps = [];

    // Reset the current index
    currentIndex = 0;

    // Reset the chat started variable
    chatStarted = false;

    // Reset the is loading variable
    showTypingAnimation = false;

    // Reset the variables
    variables = {};

    // Reset the transcript array
    transcript = [];

    // Reset the answer variables array
    answerVariables = [];

    // Get the steps element and clear the innerHTML
    const stepsElement = document.querySelector("#steps") as any;

    // Clear the innerHTML
    if (stepsElement) {
      stepsElement.innerHTML = "";
    }

    // Close the modal
    closeModal();
  };

  return (
    <>
      <FlowPreview
        name={name}
        embedType={embedType}
        customizations={customizations}
        // Toggle Modal Size
        toggleModalSize={toggleModalSize}
        // Restart Bot
        restartBot={_restartBot}
        // Close Preview
        closePreview={_closePreview}
        // isCustomize
        isCustomize={isCustomize}
      />
    </>
  );
};

export default ChatbotFlowPreview;
