import React, { useState, FC, useEffect } from "react";
import { shallow } from "zustand/shallow";
import { Button, InputNumber, Col, Row, List } from "antd";
import useStore, { selector } from "../../../../../store/store";
import { RandomFlowNode } from "../../../../../common/nodes/typings";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const RandomFlowNodeDataEditor: FC<RandomFlowNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const { t } = useTranslation();

  const nodeData = getNode(id);
  const initialBranches = nodeData?.data?.branches || [
    { id: 0, weight: 1 },
    { id: 1, weight: 1 },
  ];
  const [branches, setBranches] = useState(initialBranches);

  useEffect(() => {
    // Ensure there's always at least 2 branches when component mounts or nodeData changes
    if (branches.length < 2) {
      const minimumBranches = [
        { id: 0, weight: 1 },
        { id: 1, weight: 1 },
      ];
      setBranches(minimumBranches);
      updateNodeData(minimumBranches);
    }
  }, [branches, nodeData]);

  const handleAddBranch = () => {
    if (branches.length >= 6) return; // Limit to a maximum of 6 branches
    // Let the ID be the length of the branches array stringified
    const newId = branches.length.toString();
    const newBranch = { id: newId, weight: 1 };
    const updatedBranches = [...branches, newBranch];
    setBranches(updatedBranches);
    updateNodeData(updatedBranches);
  };

  const handleDeleteBranch = (branchId: any) => {
    if (branches.length <= 2) return; // Prevent deletion if only 2 branches exist
    const updatedBranches = branches.filter(
      (branch: any) => branch.id !== branchId
    );
    setBranches(updatedBranches);
    updateNodeData(updatedBranches);
  };

  const handleBranchWeightChange = (value: any, branchId: any) => {
    const updatedBranches = branches.map((branch: any) =>
      branch.id === branchId ? { ...branch, weight: value } : branch
    );
    setBranches(updatedBranches);
    updateNodeData(updatedBranches);
  };

  const updateNodeData = (updatedBranches: any) => {
    changeNodeData({
      ...nodeData,
      type,
      data: {
        ...nodeData.data,
        branches: updatedBranches,
      },
    });
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.logicalNodes.randomFlowNodeInfo")}
          </p>
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            onClick={handleAddBranch}
            icon={<PlusOutlined />}
            disabled={branches.length >= 6} // Disable button if max branches reached
          >
            {t("flowBuilder.sidebar.editor.logicalNodes.addBranch")}
          </Button>
        </Col>
        <Col span={24}>
          <List
            dataSource={branches}
            renderItem={(branch: any) => (
              <List.Item
                actions={[
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteBranch(branch.id)}
                    disabled={branches.length <= 2 || branch.id < 2} // Disable if only 2 branches or trying to delete initial branches
                  />,
                ]}
              >
                <List.Item.Meta
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {t("flowBuilder.sidebar.editor.logicalNodes.weight")}
                      <InputNumber
                        min={0}
                        max={100} // Assuming weight range is 0-100, adjust as needed
                        value={branch.weight}
                        onChange={(value) =>
                          handleBranchWeightChange(value, branch.id)
                        }
                        style={{ marginLeft: "10px", width: "60%" }}
                      />
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default RandomFlowNodeDataEditor;
