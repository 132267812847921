import {
  // *** Legacy Nodes Handlers *** //
  _handleUserDateSelect,
  _handleRatingSelection,
  _handleQuizOptionSelection,

  // *** Ask Question Handlers *** //
  // Multiple Questions Node
  _handleAskMultipleQuestionsLooper,
  // Calendar Node
  _handleCalendarSelection,
  // Check Options Node
  _handleCheckOptionsSelection,
  // Select Option Node
  _handleOptionSelection,

  // *** Special Nodes Handlers *** //
  _handleNavigationDoneSelection,

  // *** Flow Operation Handlers *** //
  // Choice Node
  _handleChoiceSelection,
  // Image Choice Node
  _handleImageChoiceSelection,
  // Rating Choice Node
  _handleRatingChoiceSelection,
  // Yes or No Choice Node
  _handleYesOrNoChoiceSelection,
  // Opinion Choice Node
  _handleOpinionSelection,

  // *** Other Handlers *** //
  _handleOpenImagePreview,
  _handleOpenVideoPreview,
  _handleOpenImageChoicesPreview,
} from "./handlers";

// Helper functions
import { parsedHtmlElement } from "../utils/helpers";
import { _displayNextNode } from "../ChatbotFlowPreview";
import botAvatar from "../../../../../../assets/images/pages/customize/botLogo.png";

// Display bot message
export const _displayBotMessage = (
  showTypingAnimation: any,
  embedType: any,
  customizations: any,
  steps: any,
  currentIndex: any
) => {
  const stepsElement = document.querySelector("#steps");

  // Bot typing animation
  if (showTypingAnimation) {
    let typingAnimation = document.createElement("div");
    typingAnimation.innerHTML = `<div id="typing" class="${embedType}__chat__bubble__wrapper">
            <img src=${
              customizations?.avatar || botAvatar
            } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
              style="display: ${
                customizations?.hideAvatar ? "none" : "block"
              }; height: ${customizations?.avatarSize}px;"
            >
            <div class="typing__bubble you" style="background-color: ${
              customizations?.botMsgColor
            };">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;display: block;nameKey-rendering: auto;width: 43px;height: 20px;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <circle cx="0" cy="44.1678" r="15" fill="#ffffff">
                      <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.6s"></animate>
                  </circle> <circle cx="45" cy="43.0965" r="15" fill="#ffffff">
                  <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.39999999999999997s"></animate>
                  </circle> <circle cx="90" cy="52.0442" r="15" fill="#ffffff">
                      <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.19999999999999998s"></animate>
                  </circle>
              </svg>
            </div>
          </div>`;

    stepsElement?.appendChild(typingAnimation);

    // Remove the typing message after 2s and set showTypingAnimation to false
    setTimeout(() => {
      // Get the typing element and remove it
      const typingElement = document.getElementById("typing");

      // If the typing element exists
      if (typingElement) {
        typingElement.remove();
      }

      // Set showTypingAnimation to false
      showTypingAnimation = false;

      // Call the function to display the node
      _displayBotMessage(
        showTypingAnimation,
        embedType,
        customizations,
        steps,
        currentIndex
      );
      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;
      if (chatBody) {
        chatBody.scrollTop = stepsElement?.scrollHeight + 800;
      }
    }, 2000);
  }

  if (steps[currentIndex] && !showTypingAnimation) {
    // ********************* LEGACY (V1 NODES DEPRECATED) *********************
    // ***** Two Choices Node (DEPRECATED) *****
    if (steps[currentIndex].type === "two-choices-node") {
      // Create a dom element with two choices node
      let twoChoicesNode = document.createElement("div");

      // Get the choice prompt text
      const choicePrompt = steps[currentIndex].data.choicePrompt;

      // Parse the choice prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        choicePrompt,
        "bot",
        customizations
      );

      const parsedChoiceText1 = parsedHtmlElement(
        steps[currentIndex].data.choiceText1,
        "bot",
        customizations,
        true
      );

      let parsedChoiceText2 = "";
      if (!steps[currentIndex].data.disableSecondChoice) {
        parsedChoiceText2 = parsedHtmlElement(
          steps[currentIndex].data.choiceText2,
          "bot",
          customizations,
          true
        );
      }

      twoChoicesNode.innerHTML = `<div style="position: relative;">
            <div class="${embedType}__chat__bubble__wrapper">
              <img src=${
                customizations?.avatar || botAvatar
              } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                style="display: ${
                  customizations?.hideAvatar ? "none" : "block"
                }; height: ${customizations?.avatarSize}px;"
              >
                ${parsedPrompt}
            </div>
            <div class="${embedType}__choice_container ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
            <button
              class="${embedType}__chat__choice__bubble"
              id="2choiceText1_${currentIndex}"
              style="background-color: ${
                customizations?.optionBubbleMsgColor ||
                customizations?.botMsgColor
              }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };">
          ${parsedChoiceText1}
          </button>

          ${
            steps[currentIndex].data.disableSecondChoice
              ? ""
              : `
            <button
                class="${embedType}__chat__choice__bubble"
                id="2choiceText2_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
                  customizations?.optionBubbleTextColor ||
                  customizations?.botTextColor
                }; font-size: ${
                  customizations.fontSize + "px"
                }; border-radius: ${
                  (customizations.optionBubbleBorderRadius ||
                    customizations.bubbleBorderRadius) + "px"
                };"
            >
            ${parsedChoiceText2}
            </button>`
          }
            </div>
          </div>`;
      // Append it to the steps element
      stepsElement?.appendChild(twoChoicesNode);

      // Create userSelectedChoice div element
      let userSelectedChoice = document.createElement("div");
      userSelectedChoice.style.textAlign = "right";

      // Add event listener to the buttons
      const twoChoice1 = document.getElementById(
        "2choiceText1_" + currentIndex
      ) as any;

      twoChoice1?.addEventListener("click", () => {
        // Display the selected choice in the message box on the right
        userSelectedChoice.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
          customizations?.userMsgColor
        }; color: ${customizations?.userTextColor};">
          ${parsedHtmlElement(
            steps[currentIndex].data.choiceText1,
            "user",
            customizations
          )}
        </div>`;

        // Append it to the steps element
        stepsElement?.appendChild(userSelectedChoice);

        // Select the chat body, scroll to bottom
        const chatBody = document.querySelector(
          `.${embedType}__chat__body`
        ) as any;
        if (chatBody) {
          chatBody.scrollTop = stepsElement?.scrollHeight + 800;
        }

        // Call the function to handle the choice
        _handleChoiceSelection("choiceText1", 0, "2choiceNode");
      });

      // Add event listener only if second choice is not disabled
      if (!steps[currentIndex].data.disableSecondChoice) {
        const twoChoice2 = document.getElementById(
          "2choiceText2_" + currentIndex
        ) as any;

        twoChoice2?.addEventListener("click", () => {
          // Display the selected choice in the message box on the right
          userSelectedChoice.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
            customizations?.userMsgColor
          }; color: ${customizations?.userTextColor};">
          ${parsedHtmlElement(
            steps[currentIndex].data.choiceText2,
            "user",
            customizations
          )}
        </div>`;

          // Append it to the steps element
          stepsElement?.appendChild(userSelectedChoice);

          // Select the chat body, scroll to bottom
          const chatBody = document.querySelector(
            `.${embedType}__chat__body`
          ) as any;
          if (chatBody) {
            chatBody.scrollTop = stepsElement?.scrollHeight + 800;
          }

          // Call the function to handle the choice
          _handleChoiceSelection("choiceText2", 1, "2choiceNode");
        });
      }
    }

    // ***** Three Choices Node (DEPRECATED) *****
    if (steps[currentIndex].type === "three-choices-node") {
      let threeChoicesNode = document.createElement("div");

      // Get the choice prompt text
      const choicePrompt = steps[currentIndex].data.choicePrompt;
      const parsedPrompt = parsedHtmlElement(
        choicePrompt,
        "bot",
        customizations
      );

      const parsedChoiceText1 = parsedHtmlElement(
        steps[currentIndex].data.choiceText1,
        "bot",
        customizations,
        true
      );
      const parsedChoiceText2 = parsedHtmlElement(
        steps[currentIndex].data.choiceText2,
        "bot",
        customizations,
        true
      );
      const parsedChoiceText3 = parsedHtmlElement(
        steps[currentIndex].data.choiceText3,
        "bot",
        customizations,
        true
      );

      threeChoicesNode.innerHTML = `<div style="position: relative;">
          <div class="${embedType}__chat__bubble__wrapper">
              <img src=${
                customizations?.avatar
              } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                style="display: ${
                  customizations?.hideAvatar ? "none" : "block"
                }; height: ${customizations?.avatarSize}px;"
              >
                ${parsedPrompt}
              </div>
              <div class="${embedType}__choice_container ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
                <button
                  class="${embedType}__chat__choice__bubble"
                  id="3choiceText1_${currentIndex}"
                  style="background-color: ${
                    customizations?.optionBubbleMsgColor ||
                    customizations?.botMsgColor
                  }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };"
                >${parsedChoiceText1}</button>
                <button
                  class="${embedType}__chat__choice__bubble"
                  id="3choiceText2_${currentIndex}"
                  style="background-color: ${
                    customizations?.optionBubbleMsgColor ||
                    customizations?.botMsgColor
                  }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };"
                >${parsedChoiceText2}</button>
                <button
                  class="${embedType}__chat__choice__bubble"
                  id="3choiceText3_${currentIndex}"
                  style="background-color: ${
                    customizations?.optionBubbleMsgColor ||
                    customizations?.botMsgColor
                  }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };"
                >${parsedChoiceText3}</button>
              </div>
            </div>`;

      stepsElement?.appendChild(threeChoicesNode);

      let userSelectedChoice = document.createElement("div");
      userSelectedChoice.style.textAlign = "right";

      const addButtonListener = (index: any, buttonId: any) => {
        const threeChoice = document.getElementById(
          buttonId + "_" + currentIndex
        ) as any;

        threeChoice?.addEventListener("click", () => {
          // Display the selected choice in the message box on the right
          userSelectedChoice.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
            customizations?.userMsgColor
          }; color: ${customizations?.userTextColor};">
                ${parsedHtmlElement(
                  steps[currentIndex].data["choiceText" + (index + 1)],
                  "user",
                  customizations
                )}
          </div>`;

          stepsElement?.appendChild(userSelectedChoice);

          const chatBody = document.querySelector(
            `.${embedType}__chat__body`
          ) as any;
          if (chatBody) {
            chatBody.scrollTop = stepsElement?.scrollHeight + 800;
          }
          _handleChoiceSelection(buttonId, index, "3choiceNode");
        });
      };

      addButtonListener(0, "3choiceText1");
      addButtonListener(1, "3choiceText2");
      addButtonListener(2, "3choiceText3");
    }

    // ***** Select Option Node (DEPRECATED) *****
    if (steps[currentIndex].type === "select-option-node") {
      // Create a dom element with select option node
      let selectOptionNode = document.createElement("div");

      // Get the select prompt text
      const selectPrompt = steps[currentIndex].data.selectPrompt;

      // Parse the select prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        selectPrompt,
        "bot",
        customizations
      );

      // Parse the option texts as HTML
      const parsedOption1 = parsedHtmlElement(
        steps[currentIndex].data.option1,
        "bot",
        customizations,
        true
      );
      const parsedOption2 = parsedHtmlElement(
        steps[currentIndex].data.option2,
        "bot",
        customizations,
        true
      );
      const parsedOption3 = steps[currentIndex].data.disableOption3
        ? ""
        : parsedHtmlElement(
            steps[currentIndex].data.option3,
            "bot",
            customizations,
            true
          );
      const parsedOption4 = steps[currentIndex].data.disableOption4
        ? ""
        : parsedHtmlElement(
            steps[currentIndex].data.option4,
            "bot",
            customizations,
            true
          );
      const parsedOption5 = steps[currentIndex].data.disableOption5
        ? ""
        : parsedHtmlElement(
            steps[currentIndex].data.option5,
            "bot",
            customizations,
            true
          );

      selectOptionNode.innerHTML = `<div style="position: relative;">
        <div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations.hideAvatar ? "none" : "block"
            }; height: ${customizations.avatarSize}px;"
          >
              ${parsedPrompt}
        </div>
    
        <div class="${embedType}__option_bubble_cont ${
        customizations.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations.avatarSize + 4}px;">
              <button
                class="${embedType}__chat__option__bubble"
                id="selectOption1_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };"
              >${parsedOption1}</button>
              <button
                class="${embedType}__chat__option__bubble"
                id="selectOption2_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };"
              >${parsedOption2}</button>
    
              ${
                steps[currentIndex].data.disableOption3
                  ? ""
                  : `
              <button
                class="${embedType}__chat__option__bubble"
                id="selectOption3_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
                      customizations?.optionBubbleTextColor ||
                      customizations?.botTextColor
                    }; font-size: ${
                      customizations.fontSize + "px"
                    }; border-radius: ${
                      (customizations.optionBubbleBorderRadius ||
                        customizations.bubbleBorderRadius) + "px"
                    };"
              >${parsedOption3}</button>`
              }
              ${
                steps[currentIndex].data.disableOption4
                  ? ""
                  : `
              <button
                class="${embedType}__chat__option__bubble"
                id="selectOption4_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
                      customizations?.optionBubbleTextColor ||
                      customizations?.botTextColor
                    }; font-size: ${
                      customizations.fontSize + "px"
                    }; border-radius: ${
                      (customizations.optionBubbleBorderRadius ||
                        customizations.bubbleBorderRadius) + "px"
                    };"
              >${parsedOption4}</button>`
              }
              ${
                steps[currentIndex].data.disableOption5
                  ? ""
                  : `
              <button
                class="${embedType}__chat__option__bubble"
                id="selectOption5_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
                      customizations?.optionBubbleTextColor ||
                      customizations?.botTextColor
                    }; font-size: ${
                      customizations.fontSize + "px"
                    }; border-radius: ${
                      (customizations.optionBubbleBorderRadius ||
                        customizations.bubbleBorderRadius) + "px"
                    };"
              >${parsedOption5}</button>`
              }
            </div>
          </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(selectOptionNode);

      // Create userSelectedOption div element
      let userSelectedOption = document.createElement("div");
      userSelectedOption.style.textAlign = "right";

      const addButtonListener = (
        buttonId: any,
        optionKey: any,
        optionText: any
      ) => {
        const selectOptionButton = document.getElementById(
          buttonId + "_" + currentIndex
        ) as any;
        if (selectOptionButton) {
          selectOptionButton?.addEventListener("click", () => {
            // Display the selected option in the message box on the right
            userSelectedOption.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
              customizations?.userMsgColor
            }; color: ${customizations?.userTextColor};">
                ${parsedHtmlElement(optionText, "user", customizations)}
                </div>`;

            // Append it to the steps element
            stepsElement?.appendChild(userSelectedOption);

            // Select the chat body, scroll to bottom
            const chatBody = document.querySelector(
              `.${embedType}__chat__body`
            ) as any;
            if (chatBody) {
              chatBody.scrollTop = stepsElement?.scrollHeight + 800;
            }
            // Call the function to handle the option
            _handleOptionSelection(optionKey);
          });
        }
      };

      addButtonListener(
        "selectOption1",
        "option1",
        steps[currentIndex].data.option1
      );
      addButtonListener(
        "selectOption2",
        "option2",
        steps[currentIndex].data.option2
      );
      addButtonListener(
        "selectOption3",
        "option3",
        steps[currentIndex].data.option3
      );
      addButtonListener(
        "selectOption4",
        "option4",
        steps[currentIndex].data.option4
      );
      addButtonListener(
        "selectOption5",
        "option5",
        steps[currentIndex].data.option5
      );
    }

    // ***** User Input Node (DEPRECATED) *****
    if (
      steps[currentIndex].type === "user-input-node" &&
      steps[currentIndex].data.type !== "date" &&
      steps[currentIndex].data.type !== "file"
    ) {
      // Create a dom element with user-input node not date
      let userInputNodeNotDate = document.createElement("div");

      // Get the input prompt text
      const inputPrompt = steps[currentIndex].data.inputPrompt;

      // Parse the input prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        inputPrompt,
        "bot",
        customizations
      );

      userInputNodeNotDate.style.position = "relative";
      userInputNodeNotDate.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          ${parsedPrompt}
        </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(userInputNodeNotDate);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = stepsElement.scrollHeight + 800;
      }

      // Get the input element and toggle disabled to false
      const inputElement = document.querySelector("#inputField") as any;
      inputElement.disabled = false;
      inputElement.focus();
    }

    // ***** User Input Node (Date) (DEPRECATED) *****
    if (
      steps[currentIndex].type === "user-input-node" &&
      steps[currentIndex].data.type === "date"
    ) {
      // Create a dom element with user-input node date
      let userInputNodeDate = document.createElement("div");
      userInputNodeDate.style.position = "relative";

      // Get the input prompt text
      const inputPrompt = steps[currentIndex].data.inputPrompt;

      // Parse the input prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        inputPrompt,
        "bot",
        customizations
      );

      userInputNodeDate.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          <div class="${embedType}__chat__calendar_container">
            ${parsedPrompt}
            <input
              type="date"
              class="custom-calendar-picker"
              data-date-inline-picker="true"
              id="date__input_${currentIndex}"
              style="background-color: ${customizations?.botMsgColor}; color: ${
        customizations?.botTextColor
      };"
            />
          </div>
        </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(userInputNodeDate);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = stepsElement.scrollHeight + 800;
      }

      // Add event listener to the date select
      const dateElement = document.getElementById(
        "date__input_" + currentIndex
      ) as any;

      dateElement?.addEventListener("change", () => {
        _handleUserDateSelect(embedType, customizations);
      });
    }

    // ***** User Input Node (File) (DEPRECATED) *****
    if (
      steps[currentIndex].type === "user-input-node" &&
      steps[currentIndex].data.type === "file"
    ) {
      // Create a dom element with user-input node file
      let userInputNodeFile = document.createElement("div");
      userInputNodeFile.style.position = "relative";

      // Get the input prompt text
      const inputPrompt = steps[currentIndex].data.inputPrompt;

      // Parse the input prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        inputPrompt,
        "bot",
        customizations
      );

      userInputNodeFile.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          ${parsedPrompt}
        </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(userInputNodeFile);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = stepsElement.scrollHeight + 800;
      }

      const uploadSvg = document.querySelector("#uploadSvg") as any;
      uploadSvg.style.cursor = "pointer";

      const uploadBtn = document.querySelector("#uploadBtn") as any;
      uploadBtn.removeAttribute("disabled");

      const uploadNudge = document.querySelector("#uploadNudge") as any;
      uploadNudge.style.display = "flex";
    }

    // ***** User Range Node (DEPRECATED) *****
    if (steps[currentIndex].type === "user-range-node") {
      // Create a dom element with user range node not date
      let userRangeNodeNotDate = document.createElement("div");
      userRangeNodeNotDate.style.position = "relative";

      // Get the range prompt text
      const rangePrompt = steps[currentIndex].data.rangePrompt;

      // Parse the range prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        rangePrompt,
        "bot",
        customizations
      );

      userRangeNodeNotDate.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          ${parsedPrompt}
        </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(userRangeNodeNotDate);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = stepsElement.scrollHeight + 800;
      }

      // Enable and focus on input element
      const inputElement = document.querySelector("#inputField") as any;
      inputElement.disabled = false;
      inputElement.focus();
    }

    // ***** User Rating Node (DEPRECATED) *****
    if (steps[currentIndex].type === "user-rating-node") {
      // Create a dom element with user rating node
      let userRatingNode = document.createElement("div");
      userRatingNode.style.position = "relative";

      // Get the rating prompt text
      const ratingPrompt = steps[currentIndex].data.ratingPrompt;

      // Parse the rating prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        ratingPrompt,
        "bot",
        customizations
      );

      userRatingNode.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
            ${parsedPrompt}
        </div>

        <div class="${embedType}__rating_bubble_cont ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
        ${
          // Loop in the range of data.range and display the number
          steps[currentIndex].data.type === "number"
            ? `
            ${[...Array(Number(steps[currentIndex].data.range))]
              .map((_, i) => {
                const ratingValue = i + 1;
                return `<button
                  class="${embedType}__chat__rating__bubble__number"
                  id="selectRating_${currentIndex}_${ratingValue}"
                  style="background-color: ${
                    customizations?.optionBubbleMsgColor ||
                    customizations?.botMsgColor
                  }; color: ${
                  customizations?.optionBubbleTextColor ||
                  customizations?.botTextColor
                }; font-size: ${
                  customizations.fontSize + "px"
                }; border-radius: ${
                  (customizations.optionBubbleBorderRadius ||
                    customizations.bubbleBorderRadius) + "px"
                };"
                >${ratingValue}</button>`;
              })
              .join("")}`
            : ""
        }
        ${
          // Loop in the range of data.range and display the star
          steps[currentIndex].data.type === "star"
            ? `
            ${[...Array(5)]
              .map((_, i) => {
                const ratingValue = i + 1;
                return `<button
                  class="${embedType}__chat__rating__bubble__star"
                  id="selectRating_${currentIndex}_${ratingValue}"
                  style="background-color: ${
                    customizations?.optionBubbleMsgColor ||
                    customizations?.botMsgColor
                  }; color: ${
                  customizations?.optionBubbleTextColor ||
                  customizations?.botTextColor
                }; font-size: ${
                  customizations.fontSize + "px"
                }; border-radius: ${
                  (customizations.optionBubbleBorderRadius ||
                    customizations.bubbleBorderRadius) + "px"
                };"
                >
                  ${[...Array(ratingValue)]
                    .map((x, i) => {
                      return `<span style="color:yellow;">
                      <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.029296875em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1054 1024" version="1.1"><path d="M570.477039 28.040397L694.725004 290.073071a48.345512 48.345512 0 0 0 36.259134 27.073486l281.370879 43.027506a48.345512 48.345512 0 0 1 27.073486 81.703915l-206.435335 211.269886a48.345512 48.345512 0 0 0-13.053288 42.060595L870.219212 966.910236a48.345512 48.345512 0 0 1-67.683717 52.696607l-253.813936-115.545773a48.345512 48.345512 0 0 0-40.126775 0l-255.747757 115.062318A48.345512 48.345512 0 0 1 185.16331 966.910236l48.345512-268.801046a48.345512 48.345512 0 0 0-13.053288-42.060595L14.020198 443.811798A48.345512 48.345512 0 0 1 41.093685 362.591338l281.370879-43.027505A48.345512 48.345512 0 0 0 358.723697 290.073071L483.455118 28.040397a48.345512 48.345512 0 0 1 87.021921 0z" fill="#FFCE00"/><path d="M1039.911958 443.811798a48.345512 48.345512 0 0 0-27.073486-81.703915l-281.370879-43.027505a48.345512 48.345512 0 0 1-36.742589-29.007307L570.477039 28.040397a48.345512 48.345512 0 0 0-43.510961-28.040397v898.743064a48.345512 48.345512 0 0 1 19.82166 4.351096l253.813937 115.545773A48.345512 48.345512 0 0 0 870.219212 966.910236l-48.345512-268.801046a48.345512 48.345512 0 0 1 13.053288-42.060595z" fill="#FFEC00"/></svg>
                      </span>`;
                    })
                    .join("")}

                </button>`;
              })
              .join("")}`
            : ""
        }
        ${
          // Loop in the range of data.range and display the smiley
          steps[currentIndex].data.type === "smiley"
            ? `
            ${[...Array(5)]
              .map((_, i) => {
                const ratingValue = i + 1;
                return `<button
                  class="${embedType}__chat__rating__bubble__smiley"
                  id="selectRating_${currentIndex}_${ratingValue}"
                  style="background-color: ${
                    customizations?.optionBubbleMsgColor ||
                    customizations?.botMsgColor
                  }; color: ${
                  customizations?.optionBubbleTextColor ||
                  customizations?.botTextColor
                }; font-size: ${
                  customizations.fontSize + "px"
                }; border-radius: ${
                  (customizations.optionBubbleBorderRadius ||
                    customizations.bubbleBorderRadius) + "px"
                };"
                >
                  ${
                    ratingValue === 1
                      ? `<span style="font-size:100%;">&#128545;</span>`
                      : ratingValue === 2
                      ? `<span style="font-size:100%;">&#128542;</span>`
                      : ratingValue === 3
                      ? `<span style="font-size:100%;">&#128528;</span>`
                      : ratingValue === 4
                      ? `<span style="font-size:100%;">&#128513;</span>`
                      : ratingValue === 5
                      ? `<span style="font-size:100%;">&#128525;</span>`
                      : ""
                  }
                </button>`;
              })
              .join("")}`
            : ""
        }
        </div>
        `;

      // Append it to the steps element
      stepsElement?.appendChild(userRatingNode);

      // Add event listener to the buttons in the range of data.range
      for (let i = 1; i <= steps[currentIndex].data.range; i++) {
        const selectRating = document.getElementById(
          `selectRating_${currentIndex}_${i}`
        ) as any;
        selectRating?.addEventListener("click", () => {
          // Display the selected rating in the message box on the right
          let userSelectedRating = document.createElement("div");
          userSelectedRating.style.textAlign = "right";
          userSelectedRating.innerHTML = `<div class="${embedType}__chat__bubble_container">
               <p class="${embedType}__chat__bubble user"
                   style="background-color: ${customizations?.userMsgColor};
                   color: ${customizations?.userTextColor};">
                   ${selectRating.innerHTML}
               </p>
             </div>`;

          // Append it to the steps element
          stepsElement?.appendChild(userSelectedRating);

          // Disable the buttons in the range of data.range after the user selects the rating
          for (let i = 1; i <= steps[currentIndex].data.range; i++) {
            const selectRating = document.getElementById(
              `selectRating_${currentIndex}_${i}`
            ) as any;
            selectRating.disabled = true;
          }

          // Select the chat body, scroll to bottom
          const chatBody = document.querySelector(
            `.${embedType}__chat__body`
          ) as any;
          if (chatBody) {
            chatBody.scrollTop = stepsElement?.scrollHeight + 800;
          }
          // Call the function to handle the rating
          _handleRatingSelection(i);
        });
      }
    }

    // ***** Quiz Node (DEPRECATED) *****-
    if (steps[currentIndex].type === "quiz-node") {
      // Create a dom element with quiz node
      let quizNode = document.createElement("div");

      // Get the quiz prompt text
      const quizPrompt = steps[currentIndex].data.quizPrompt;

      // Parse the quiz prompt text as HTML
      const parsedPrompt = parsedHtmlElement(quizPrompt, "bot", customizations);

      // Parse the option texts as HTML
      const parsedOption1 = parsedHtmlElement(
        steps[currentIndex].data.option1,
        "bot",
        customizations,
        true
      );
      const parsedOption2 = parsedHtmlElement(
        steps[currentIndex].data.option2,
        "bot",
        customizations,
        true
      );
      const parsedOption3 = steps[currentIndex].data.disableOption3
        ? ""
        : parsedHtmlElement(
            steps[currentIndex].data.option3,
            "bot",
            customizations,
            true
          );
      const parsedOption4 = steps[currentIndex].data.disableOption4
        ? ""
        : parsedHtmlElement(
            steps[currentIndex].data.option4,
            "bot",
            customizations,
            true
          );
      const parsedOption5 = steps[currentIndex].data.disableOption5
        ? ""
        : parsedHtmlElement(
            steps[currentIndex].data.option5,
            "bot",
            customizations,
            true
          );

      quizNode.innerHTML = `<div style="position: relative;">
        <div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          ${parsedPrompt}
        </div>

        <div class="${embedType}__option_bubble_cont ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
              <button
                class="${embedType}__chat__option__bubble a"
                id="quizOption1_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };"
              >${parsedOption1}</button>
              <button
                class="${embedType}__chat__option__bubble b"
                id="quizOption2_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
        customizations?.optionBubbleTextColor || customizations?.botTextColor
      }; font-size: ${customizations.fontSize + "px"}; border-radius: ${
        (customizations.optionBubbleBorderRadius ||
          customizations.bubbleBorderRadius) + "px"
      };"
              >${parsedOption2}</button>
              ${
                steps[currentIndex].data.disableOption3
                  ? ""
                  : `
              <button
                class="${embedType}__chat__option__bubble c"
                id="quizOption3_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
                      customizations?.optionBubbleTextColor ||
                      customizations?.botTextColor
                    }; font-size: ${
                      customizations.fontSize + "px"
                    }; border-radius: ${
                      (customizations.optionBubbleBorderRadius ||
                        customizations.bubbleBorderRadius) + "px"
                    };"
              >${parsedOption3}</button>`
              }
              ${
                steps[currentIndex].data.disableOption4
                  ? ""
                  : `
              <button
                class="${embedType}__chat__option__bubble d"
                id="quizOption4_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
                      customizations?.optionBubbleTextColor ||
                      customizations?.botTextColor
                    }; font-size: ${
                      customizations.fontSize + "px"
                    }; border-radius: ${
                      (customizations.optionBubbleBorderRadius ||
                        customizations.bubbleBorderRadius) + "px"
                    };"
              >${parsedOption4}</button>`
              }
              ${
                steps[currentIndex].data.disableOption5
                  ? ""
                  : `
              <button
                class="${embedType}__chat__option__bubble e"
                id="quizOption5_${currentIndex}"
                style="background-color: ${
                  customizations?.optionBubbleMsgColor ||
                  customizations?.botMsgColor
                }; color: ${
                      customizations?.optionBubbleTextColor ||
                      customizations?.botTextColor
                    }; font-size: ${
                      customizations.fontSize + "px"
                    }; border-radius: ${
                      (customizations.optionBubbleBorderRadius ||
                        customizations.bubbleBorderRadius) + "px"
                    };"
              >${parsedOption5}</button>`
              }
            </div>
          </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(quizNode);

      // Create userSelectedOption div element
      let userSelectedOption = document.createElement("div");
      userSelectedOption.style.textAlign = "right";

      // Add event listener to the buttons
      const addButtonListener = (
        buttonId: any,
        optionText: any,
        optionKeyName: any
      ) => {
        const quizOptionButton = document.getElementById(
          buttonId + "_" + currentIndex
        ) as any;
        if (quizOptionButton) {
          quizOptionButton?.addEventListener("click", () => {
            // Display the selected option in the message box on the right
            userSelectedOption.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
              customizations?.userMsgColor
            }; color: ${customizations?.userTextColor};">
                ${parsedHtmlElement(optionText, "user", customizations)}
                </div>`;

            // Append it to the steps element
            stepsElement?.appendChild(userSelectedOption);

            // Select the chat body, scroll to bottom
            const chatBody = document.querySelector(
              `.${embedType}__chat__body`
            ) as any;
            if (chatBody) {
              chatBody.scrollTop = stepsElement?.scrollHeight + 800;
            }

            // Call the function to handle the option
            _handleQuizOptionSelection(
              optionKeyName,
              steps[currentIndex].data.correctAnswer
            );
          });
        }
      };

      addButtonListener(
        "quizOption1",
        steps[currentIndex].data.option1,
        "option1"
      );
      addButtonListener(
        "quizOption2",
        steps[currentIndex].data.option2,
        "option2"
      );
      addButtonListener(
        "quizOption3",
        steps[currentIndex].data.option3,
        "option3"
      );
      addButtonListener(
        "quizOption4",
        steps[currentIndex].data.option4,
        "option4"
      );
      addButtonListener(
        "quizOption5",
        steps[currentIndex].data.option5,
        "option5"
      );
    }

    // ********************* (V2 NODES) *********************
    // =============== STARTER NODE ===============
    // ***** Welcome Node *****
    if (steps[currentIndex].type === "welcome-node") {
      // Create a dom element with welcome node
      let welcomeNode = document.createElement("div");

      // Get the welcome text
      const welcomeText = steps[currentIndex].data.text;

      // Parse the welcome text as HTML
      const parsedHtml = parsedHtmlElement(welcomeText, "bot", customizations);

      welcomeNode.innerHTML = `<div style="position: relative;">
              <div
                class="${embedType}__chat__bubble__wrapper"
              >
                <img src=${
                  customizations?.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations?.hideAvatar ? "none" : "block"
                  }; height: ${customizations?.avatarSize}px;"
                >
                ${parsedHtml}
              </div>
              ${
                steps[currentIndex].data.disableImage
                  ? ""
                  : `<div class="${
                      customizations?.hideAvatar ? "no-logo" : "with-logo"
                    }" style="margin-left: ${
                      customizations?.avatarSize + 4
                    }px; position: relative;">
                        <img class="${embedType}__chat__bubble bot image" id="preview_${currentIndex}" src="${
                      steps[currentIndex].data.image
                    }" alt="image_${currentIndex}" loading="lazy" decoding="async">
                    </div>`
              }
            </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(welcomeNode);

      // Display the next node
      _displayNextNode();

      // If is not disabled, add event listener for image click to open preview
      if (!steps[currentIndex].data.disableImage) {
        const welcomeImgToPreview = welcomeNode.querySelector(
          `#preview_${currentIndex}`
        );

        welcomeImgToPreview.addEventListener("click", () => {
          _handleOpenImagePreview(steps[currentIndex].data.image);
        });
      }
    }

    // =============== SEND A RESPONSE NODES ===============
    // ***** Message Node *****
    if (steps[currentIndex].type === "message-node") {
      // Create a dom element with message node
      let messageNode = document.createElement("div");
      messageNode.style.position = "relative";

      // Get the message text
      const messageText = steps[currentIndex].data.text;

      // Parse the message text as HTML
      const parsedHtml = parsedHtmlElement(messageText, "bot", customizations);

      messageNode.innerHTML = `<div style="position: relative;">
              <div
                class="${embedType}__chat__bubble__wrapper"
              >
                <img src=${
                  customizations?.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations?.hideAvatar ? "none" : "block"
                  }; height: ${customizations?.avatarSize}px;"
                >
                ${parsedHtml}
              </div>
            </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(messageNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();
    }

    // ***** Image Node *****
    if (steps[currentIndex].type === "image-node") {
      // Create a DOM element with image node
      let imageNode = document.createElement("div");
      imageNode.style.position = "relative";
      imageNode.innerHTML = `
      <div class="${embedType}__chat__bubble__wrapper">
        <img src="${
          customizations?.avatar || botAvatar
        }" alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
          style="display: ${
            customizations?.hideAvatar ? "none" : "block"
          }; height: ${customizations?.avatarSize}px;">
        <img class="${embedType}__chat__bubble bot image" id="preview_${currentIndex}"  src="${
        steps[currentIndex].data.image
      }" alt="image_${currentIndex}" loading="lazy" decoding="async">
      </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(imageNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(`.${embedType}__chat__body`);

      if (chatBody) {
        chatBody.scrollTop = chatBody.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();

      // Event listener for image click to open preview
      const imageToPreview = imageNode.querySelector(
        `#preview_${currentIndex}`
      );
      imageToPreview.addEventListener("click", () => {
        _handleOpenImagePreview(steps[currentIndex].data.image);
      });
    }

    // ***** Video Node *****
    if (steps[currentIndex].type === "video-node") {
      // Create a dom element with video node
      let videoNode = document.createElement("div");
      videoNode.style.position = "relative";
      videoNode.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
                <img src=${
                  customizations?.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations?.hideAvatar ? "none" : "block"
                  }; height: ${customizations?.avatarSize}px;"
                >
                  <video class="${embedType}__chat__bubble bot video"
                    id="preview_${currentIndex}" 
                    autoPlay
                    loop
                    muted
                    playsInline>
                    <source src="${
                      steps[currentIndex].data.video
                    }" type="video/mp4">
                  </video>
        </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(videoNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();

      // Event listener for image click to open preview
      const videoToPreview = videoNode.querySelector(
        `#preview_${currentIndex}`
      );
      videoToPreview.addEventListener("click", () => {
        _handleOpenVideoPreview(steps[currentIndex].data.video);
      });
    }

    // ***** Audio Node *****
    if (steps[currentIndex].type === "audio-node") {
      // Create a dom element with audio node
      let audioNode = document.createElement("div");
      audioNode.style.position = "relative";
      audioNode.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
                <img src=${
                  customizations?.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations?.hideAvatar ? "none" : "block"
                  }; height: ${customizations?.avatarSize}px;"
                >
                <audio
                  class="${embedType}__chat__bubble bot audio" style="background-color: ${
        customizations?.botMsgColor
      }; color: ${customizations?.botTextColor};"
                  src="${steps[currentIndex].data.audio}"
                  controls
                />
            </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(audioNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();
    }

    // ***** File Node *****
    if (steps[currentIndex].type === "file-node") {
      // Create a DOM element with file node
      let fileNode = document.createElement("div");
      fileNode.style.position = "relative";
      fileNode.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
              <img src=${
                customizations?.avatar || botAvatar
              } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                style="display: ${
                  customizations?.hideAvatar ? "none" : "block"
                }; height: ${customizations?.avatarSize}px;"
              >
              <a href="${
                steps[currentIndex].data.file
              }" target="_blank" class="${embedType}__chat__bubble bot file" style="background-color: ${
        customizations?.botMsgColor
      }; color: ${
        customizations?.botTextColor
      }; display: flex; align-items: center;">
                ${steps[currentIndex].data.file.split("/").pop()}
                <svg width="12" height="12" style="margin-left: 8px; fill: ${
                  customizations?.botTextColor
                };" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 304 384">
                  <path fill="currentColor" d="M299 128L149 277L0 128h85V0h128v128zM0 320h299v43H0z"/>
                </svg>
              </a>
          </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(fileNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();
    }

    // =============== ASK A QUESTION NODES ===============
    // ***** Ask for a Name/Email/Phone/Number/URL/Location node *****
    if (
      steps[currentIndex].type === "ask-name-node" ||
      steps[currentIndex].type === "ask-email-node" ||
      steps[currentIndex].type === "ask-phone-number-node" ||
      steps[currentIndex].type === "ask-number-node" ||
      steps[currentIndex].type === "ask-url-node" ||
      steps[currentIndex].type === "ask-location-node" ||
      steps[currentIndex].type === "ask-custom-question-node"
    ) {
      let askForNode = document.createElement("div");
      askForNode.style.position = "relative";

      // Get the question text
      const questionText = steps[currentIndex]?.data?.questionText;

      // Parse the question text as HTML
      const parsedPrompt = parsedHtmlElement(
        questionText,
        "bot",
        customizations
      );

      askForNode.innerHTML = `<div style="position: relative;">
          <div class="${embedType}__chat__bubble__wrapper">
              <img src=${
                customizations?.avatar || botAvatar
              } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                style="display: ${
                  customizations?.hideAvatar ? "none" : "block"
                }; height: ${customizations?.avatarSize}px;"
              >
              ${parsedPrompt}
          </div>
      </div>`;

      stepsElement?.appendChild(askForNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Enable and focus on input element
      const inputElement = document.querySelector("#inputField") as any;
      inputElement.disabled = false;
      inputElement.focus();
    }

    // ***** Ask for file node *****
    if (steps[currentIndex].type === "ask-file-node") {
      let askForNameNode = document.createElement("div");
      askForNameNode.style.position = "relative";

      // Get the question text
      const questionText = steps[currentIndex]?.data?.questionText;

      // Parse the question text as HTML
      const parsedPrompt = parsedHtmlElement(
        questionText,
        "bot",
        customizations
      );

      askForNameNode.innerHTML = `<div style="position: relative;">
          <div class="${embedType}__chat__bubble__wrapper">
              <img src=${
                customizations?.avatar || botAvatar
              } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                style="display: ${
                  customizations?.hideAvatar ? "none" : "block"
                }; height: ${customizations?.avatarSize}px;"
              >
              ${parsedPrompt}
          </div>
      </div>`;

      stepsElement?.appendChild(askForNameNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      const uploadSvg = document.querySelector("#uploadSvg") as any;
      uploadSvg.style.cursor = "pointer";

      const uploadBtn = document.querySelector("#uploadBtn") as any;
      uploadBtn.removeAttribute("disabled");

      const uploadNudge = document.querySelector("#uploadNudge") as any;
      uploadNudge.style.display = "flex";
    }

    // *****  Ask multiple questions node *****
    if (steps[currentIndex].type === "ask-multiple-questions-node") {
      // Create a dom element
      let multipleQuestionsNode = document.createElement("div");

      // Get the initial message
      const initialMessage = steps[currentIndex]?.data?.message;

      // Parse the text as HTML
      const parsedMessage = parsedHtmlElement(
        initialMessage,
        "bot",
        customizations
      );

      multipleQuestionsNode.innerHTML = `<div style="position: relative;">
            <div class="${embedType}__chat__bubble__wrapper">
                <img src=${
                  customizations?.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations?.hideAvatar ? "none" : "block"
                  }; height: ${customizations?.avatarSize}px;"
                >
                ${parsedMessage}
            </div>
        </div>`;

      // Append the initial message to the steps element
      stepsElement?.appendChild(multipleQuestionsNode);

      // _handleAskMultipleQuestionsLooper
      _handleAskMultipleQuestionsLooper(embedType, customizations, 0);
    }

    // ***** Calendar Node *****
    if (steps[currentIndex].type === "calendar-node") {
      // Create a dom element with calendar node
      let calendarNode = document.createElement("div");
      calendarNode.style.position = "relative";

      // Get the calendar prompt text
      const calendarPrompt = steps[currentIndex].data.calendarPrompt;

      // Parse the calendar prompt text as HTML
      const parsedHtml = parsedHtmlElement(
        calendarPrompt,
        "bot",
        customizations
      );

      calendarNode.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
          <img src=${
            customizations?.avatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >

          <div class="${embedType}__chat__calendar_container">
            ${parsedHtml}
            <input type="date" id=${
              "calendar_" + currentIndex
            } class="custom-calendar-picker"
            ${
              !steps[currentIndex].data.showPastDates
                ? `min=${new Date().toISOString().split("T")[0]}`
                : ""
            }
            ${
              steps[currentIndex].data.excludeDays.length > 0
                ? `data-exclude-days=${steps[currentIndex].data.excludeDays}`
                : ""
            }
            ${
              steps[currentIndex].data.excludeDates.length > 0
                ? `data-exclude-dates=${steps[currentIndex].data.excludeDates}`
                : ""
            }
            />
          </div>
        </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(calendarNode);

      // Create userSelectedDate div element
      let userSelectedDate = document.createElement("div");
      userSelectedDate.style.textAlign = "right";

      let timeSlots = document.createElement("div");

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;
      if (chatBody) {
        chatBody.scrollTop = stepsElement?.scrollHeight + 800;
      }

      // Add event listener to the calendar
      const calendar = document.getElementById(
        "calendar_" + currentIndex
      ) as any;

      document?.addEventListener("DOMContentLoaded", function () {
        // Focus
        calendar.focus();
      });

      calendar?.addEventListener("change", (e: any) => {
        // Extract the day
        const day = new Date(e.target.value).toLocaleDateString("en-US", {
          weekday: "long",
        });
        // Extract the date in words like (3rd Feb, 2022)
        const date = new Date(e.target.value);
        const dateInWords = date.toDateString().split(" ");
        const dateInWordsFormatted = `${dateInWords[2]} ${dateInWords[1]}, ${dateInWords[3]}`;

        // Change date format to "17-05-2023" (Use Hyphen instead of forward slash)
        const dateInNumbers = e.target.value.split("-").reverse().join("-");

        // If dateInNumbers in present in excludeDates array, show error message
        if (steps[currentIndex].data.excludeDates.includes(dateInNumbers)) {
          // Create userSelectedDate div element
          let userSelectedDate = document.createElement("div");
          userSelectedDate.style.textAlign = "right";

          // Display the selected option in the message box on the right
          userSelectedDate.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${customizations?.userMsgColor}; color: ${customizations?.userTextColor};">
              ${dateInWordsFormatted} is currently full, please choose another
            </div>`;

          // Append it to the steps element
          stepsElement?.appendChild(userSelectedDate);

          // Select the chat body, scroll to bottom
          if (chatBody) {
            chatBody.scrollTop = stepsElement?.scrollHeight + 800;
          }

          return;
        }

        let timeSlotSelected;

        // Display the selected option in the message box on the right
        userSelectedDate.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
          customizations?.userMsgColor
        }; color: ${customizations?.userTextColor}; font-size: ${
          customizations.fontSize + "px"
        }; border-radius: ${customizations.bubbleBorderRadius + "px"};">
            ${dateInWordsFormatted}
          </div>`;

        // Append it to the steps element
        stepsElement?.appendChild(userSelectedDate);

        timeSlots.innerHTML = "";

        // Select the chat body, scroll to bottom
        if (chatBody) {
          chatBody.scrollTop = stepsElement?.scrollHeight + 800;
        }

        // If showTimeSelection is false, calendar.disabled = true
        if (!steps[currentIndex].data.showTimeSelection) {
          calendar.disabled = true;
        }

        // If data.showTimeSelection is true, display the time slots available for the day
        if (steps[currentIndex].data.showTimeSelection) {
          // Create userSelectedSlot div element
          let userSelectedSlot = document.createElement("div");
          userSelectedSlot.style.textAlign = "right";

          // Extract slots for the day from data.weeklyHours, where weeklyHours.dayName === day
          const slots = steps[currentIndex].data.weeklyHours.filter(
            (item: any) => item.dayName === day
          )[0].slots;

          // Check if the day is available
          const isAvailable = steps[currentIndex].data.weeklyHours.filter(
            (item: any) => item.dayName === day
          )[0].available;

          // Create a dom element with time slots
          timeSlots.innerHTML = `<div class="${embedType}__option_bubble_cont">
            <div style="position: relative;">
            ${
              isAvailable
                ? `<div class="${embedType}__chat__bubble__wrapper">
                      <img src=${
                        customizations?.avatar
                      } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                        style="display: ${
                          customizations?.hideAvatar ? "none" : "block"
                        }; height: ${customizations?.avatarSize}px;"
                      >
                        <p class="${embedType}__chat__bubble bot" style="background-color: ${
                    customizations?.botMsgColor
                  }; color: ${
                    customizations?.botTextColor
                  };">Great, Can you please choose a time slot?</p>
                  </div>`
                : ""
            }

              <div class="${embedType}__choice_container">
              ${
                isAvailable
                  ? `<div class="${embedType}__option_bubble_cont ${
                      customizations?.hideAvatar ? "no-logo" : "with-logo"
                    }" style="margin-left: ${
                      customizations?.avatarSize + 4
                    }px;">
                  ${slots
                    .map(
                      (slot: any, index: any) =>
                        `<button
                            class="${embedType}__chat__option__bubble"
                            id="slot_${index}_${currentIndex}"
                            style="background-color: ${
                              customizations?.optionBubbleMsgColor ||
                              customizations?.botMsgColor
                            }; color: ${
                          customizations?.optionBubbleTextColor ||
                          customizations?.botTextColor
                        }; font-size: ${
                          customizations.fontSize + "px"
                        }; border-radius: ${
                          (customizations.optionBubbleBorderRadius ||
                            customizations.bubbleBorderRadius) + "px"
                        }; padding: 8px;"
                          >${slot.start} - ${slot.end}</button>`
                    )
                    .join("")}</div>`
                  : `<div style="position: relative;">
                  <div
                    class="${embedType}__chat__bubble__wrapper"
                  >
                    <img src=${
                      customizations?.avatar
                    } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                      style="display: ${
                        customizations?.hideAvatar ? "none" : "block"
                      }; height: ${customizations?.avatarSize}px;"
                    >
                    <p class="${embedType}__chat__bubble bot" style="background-color: ${
                      customizations?.botMsgColor
                    }; color: ${customizations?.botTextColor};">
                  Sorry, we are not available on ${day}. Please choose a different date above.
                    </p>
                  </div>
                </div>`
              }
                  </div>
                </div>
              </div>
            </div>`;
          // Append it to the steps element
          stepsElement?.appendChild(timeSlots);
          // Select the chat body, scroll to bottom
          if (chatBody) {
            chatBody.scrollTop = stepsElement?.scrollHeight + 800;
          }

          // Add event listener to each time slot
          isAvailable &&
            slots.forEach((slot: any, index: any) => {
              const timeSlot = document.getElementById(
                "slot_" + index + "_" + currentIndex
              ) as any;

              timeSlot?.addEventListener("click", () => {
                // Display the selected option in the message box on the right
                userSelectedSlot.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${customizations?.userMsgColor}; color: ${customizations?.userTextColor};">
                  ${slot.start} - ${slot.end}
                </div>`;
                // Append it to the steps element
                stepsElement?.appendChild(userSelectedSlot);
                // Select the chat body, scroll to bottom
                if (chatBody) {
                  chatBody.scrollTop = stepsElement?.scrollHeight + 800;
                }

                timeSlotSelected = slot.start + " - " + slot.end;

                // Disable the calendar selection and time slot buttons
                calendar.disabled = true;
                slots.forEach((slot: any, index: any) => {
                  const timeSlot = document.getElementById(
                    "slot_" + index + "_" + currentIndex
                  ) as any;
                  timeSlot.disabled = true;
                });

                // Create a date object with the selected date and time slot
                const data = {
                  day,
                  timeSlotSelected,
                  date: dateInWordsFormatted,
                  showTimeSelection: steps[currentIndex].data.showTimeSelection,
                };

                // Call the function to handle the option
                _handleCalendarSelection(data);
              });
            });
        } else {
          const data = {
            day,
            timeSlotSelected,
            date: dateInWordsFormatted,
            showTimeSelection: steps[currentIndex].data.showTimeSelection,
          };

          // Call the function to handle the option
          _handleCalendarSelection(data);
        }
      });
    }

    // ***** N Select Options Node *****
    if (steps[currentIndex].type === "n-select-option-node") {
      // Create a dom element with n options node
      let nOptionsNode = document.createElement("div");

      // Get the select prompt
      const selectPrompt = steps[currentIndex].data.selectPrompt;

      // Parse the select prompt text as HTML
      const parsedHtml = parsedHtmlElement(selectPrompt, "bot", customizations);

      // Filter out empty options before parsing
      const filteredOptions = steps[currentIndex].data.options.filter(
        (option: { optionText: any }) => option.optionText?.trim() !== ""
      );

      // Parse the options text as HTML
      const parsedOptions = filteredOptions.map(
        (option: { optionText: any }) => ({
          ...option,
          optionText: parsedHtmlElement(
            option.optionText,
            "bot",
            customizations,
            true
          ),
        })
      );

      nOptionsNode.innerHTML = `<div style="position: relative;">
                <div class="${embedType}__chat__bubble__wrapper">
                    <img src=${
                      customizations?.avatar || botAvatar
                    } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                      style="display: ${
                        customizations?.hideAvatar ? "none" : "block"
                      }; height: ${customizations?.avatarSize}px;">
                        ${parsedHtml}
                </div>
    
                <div class="${embedType}__option_bubble_cont ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
                ${parsedOptions
                  .map(
                    (option: { optionText: any }, index: any) =>
                      `<button
                    class="${embedType}__chat__option__bubble"
                    id="nOptionText_${index}_${currentIndex}"
                    style="background-color: ${
                      customizations?.optionBubbleMsgColor ||
                      customizations?.botMsgColor
                    }; color: ${
                        customizations?.optionBubbleTextColor ||
                        customizations?.botTextColor
                      }; font-size: ${
                        customizations.fontSize + "px"
                      }; border-radius: ${
                        (customizations.optionBubbleBorderRadius ||
                          customizations.bubbleBorderRadius) + "px"
                      };"
                  >${option.optionText}</button>`
                  )
                  .join("")}
                </div>
              </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(nOptionsNode);

      // Create userSelectedOption div element
      let userSelectedOption = document.createElement("div");
      userSelectedOption.style.textAlign = "right";

      // Add event listener to the buttons
      parsedOptions.forEach(
        (option: { id: any; optionText: any }, index: any) => {
          const selectOption = document.getElementById(
            "nOptionText_" + index + "_" + currentIndex
          ) as any;

          selectOption?.addEventListener("click", () => {
            // Display the selected option in the message box on the right
            userSelectedOption.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
              customizations?.userMsgColor
            }; color: ${customizations?.userTextColor};">
                ${parsedHtmlElement(option.optionText, "user", customizations)}
                </div>`;

            // Append it to the steps element
            stepsElement?.appendChild(userSelectedOption);

            // Select the chat body, scroll to bottom
            const chatBody = document.querySelector(
              `.${embedType}__chat__body`
            ) as any;
            if (chatBody) {
              chatBody.scrollTop = stepsElement?.scrollHeight + 800;
            }

            // Call the function to handle the option
            _handleOptionSelection(option.id);
          });
        }
      );
    }

    // ***** N Check Options Node *****
    if (steps[currentIndex].type === "n-check-options-node") {
      // Create a dom element with n options node
      let nOptionsNode = document.createElement("div");

      // Get the check options prompt text
      const checkOptionsPrompt = steps[currentIndex].data.checkOptionsPrompt;

      // Parse the check options prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        checkOptionsPrompt,
        "bot",
        customizations
      );

      // Filter out empty options
      const filteredOptions = steps[currentIndex].data.options.filter(
        (option: { optionText: any }) => option.optionText?.trim() !== ""
      );

      nOptionsNode.innerHTML = `<div style="position: relative;">
                <div class="${embedType}__chat__bubble__wrapper">
                  <img src=${
                    customizations?.avatar || botAvatar
                  } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                    style="display: ${
                      customizations?.hideAvatar ? "none" : "block"
                    }; height: ${customizations?.avatarSize}px;"
                  >
                      ${parsedPrompt}
                </div>

                <div class="${embedType}__check_option_bubble_cont ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
                  ${filteredOptions
                    .map(
                      (option: { optionText: any }, index: any) =>
                        `<div class="${embedType}__chat__check_option__bubble" style="background-color: ${customizations?.botMsgColor}; color: ${customizations?.botTextColor};">
                          <input type="checkbox" id="nCheckOptionText_${index}_${currentIndex}" name="nCheckOptionText_${index}_${currentIndex}" value="${option.optionText}">
                          <label for="nCheckOptionText_${index}_${currentIndex}">${option.optionText}</label>
                        </div>`
                    )
                    .join("")}
                </div>

                <button class="${embedType}__chat__check_option__bubble  ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" id="nCheckOptionsDone_${currentIndex}"
                  style="background-color: ${
                    customizations?.botMsgColor
                  }; color: ${customizations?.botTextColor};
                    cursor: not-allowed; margin-left: ${
                      customizations?.avatarSize + 4
                    }px;" disabled>
                  Done
                </button>

              </div>`;
      // Append it to the steps element
      stepsElement?.appendChild(nOptionsNode);

      // Add event listener to the done button
      const doneButton = document.getElementById(
        "nCheckOptionsDone_" + currentIndex
      ) as any;

      // Add event listener to the check options checked and unchecked
      const selectedCheckOptions: any = [];
      filteredOptions.forEach((option: { optionText: any }, index: any) => {
        const selectOption = document.getElementById(
          "nCheckOptionText_" + index + "_" + currentIndex
        ) as any;
        selectOption?.addEventListener("click", (e: any) => {
          if (e.target.checked) {
            selectedCheckOptions.push(option.optionText);

            enableDisableDone();
          } else {
            selectedCheckOptions.splice(
              selectedCheckOptions.indexOf(option.optionText),
              1
            );

            enableDisableDone();
          }
        });
      });

      const enableDisableDone = () => {
        // Enable the done button only if at least one option is selected
        if (selectedCheckOptions.length > 0) {
          doneButton.disabled = false;

          // Change cursor to pointer
          doneButton.style.cursor = "pointer";
        } else {
          doneButton.disabled = true;

          // Change cursor to not-allowed
          doneButton.style.cursor = "not-allowed";
        }
      };

      doneButton?.addEventListener("click", () => {
        // Disable the done button
        doneButton.disabled = true;

        // Create userSelectedOption div element
        let userSelectedOption = document.createElement("div");
        userSelectedOption.style.textAlign = "right";
        // Display the selected option in the message box on the right
        userSelectedOption.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
          customizations?.userMsgColor
        }; color: ${customizations?.userTextColor};">
            ${selectedCheckOptions.join(", ")}
          </div>`;

        // Append it to the steps element
        stepsElement?.appendChild(userSelectedOption);

        // Select the chat body, scroll to bottom
        const chatBody = document.querySelector(
          `.${embedType}__chat__body`
        ) as any;
        if (chatBody) {
          chatBody.scrollTop = stepsElement?.scrollHeight + 800;
        }

        // Call the function to handle the check options
        _handleCheckOptionsSelection(selectedCheckOptions.join(", "));
      });
    }

    // =============== FLOW OPERATIONS NODES ===============
    // ***** N Choices Node *****
    if (steps[currentIndex].type === "n-choices-node") {
      // Create a dom element with n choices node
      let nChoicesNode = document.createElement("div");

      // Get the choice prompt text
      const choicePrompt = steps[currentIndex].data.choicePrompt;

      // Parse the choice prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        choicePrompt,
        "bot",
        customizations
      );

      // Filter out empty choices
      const filteredChoices = steps[currentIndex].data.choices.filter(
        (choice: { choiceText: any }) => choice.choiceText?.trim() !== ""
      );

      nChoicesNode.innerHTML = `<div style="position: relative;">
                <div class="${embedType}__chat__bubble__wrapper">
                    <img src=${
                      customizations?.avatar || botAvatar
                    } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                      style="display: ${
                        customizations?.hideAvatar ? "none" : "block"
                      }; height: ${customizations?.avatarSize}px;"
                    >
                    ${parsedPrompt}
                </div>

                <div class="${embedType}__choice_container ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
            ${filteredChoices
              .map((choice: { choiceText: any }, index: any) => {
                const parsedChoiceText = parsedHtmlElement(
                  choice.choiceText,
                  "bot",
                  customizations,
                  true
                );
                return `<button
                      class="${embedType}__chat__choice__bubble"
                      id="nChoiceText_${index}_${currentIndex}"
                      style="background-color: ${
                        customizations?.optionBubbleMsgColor ||
                        customizations?.botMsgColor
                      }; color: ${
                  customizations?.optionBubbleTextColor ||
                  customizations?.botTextColor
                }; font-size: ${
                  customizations.fontSize + "px"
                }; border-radius: ${
                  (customizations.optionBubbleBorderRadius ||
                    customizations.bubbleBorderRadius) + "px"
                };"
                      >${parsedChoiceText}</button>`;
              })
              .join("")}
                </div>
              </div>`;
      // Append it to the steps element
      stepsElement?.appendChild(nChoicesNode);

      // Create userSelectedChoice div element
      let userSelectedChoice = document.createElement("div");
      userSelectedChoice.style.textAlign = "right";

      // Add event listener to the buttons
      filteredChoices.forEach(
        (choice: { id: any; choiceText: any }, index: any) => {
          const nChoice = document.getElementById(
            "nChoiceText_" + index + "_" + currentIndex
          ) as any;
          nChoice?.addEventListener("click", () => {
            // Display the selected choice in the message box on the right
            userSelectedChoice.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
              customizations?.userMsgColor
            }; color: ${customizations?.userTextColor};">
                ${parsedHtmlElement(choice?.choiceText, "user", customizations)}
            </div>`;

            // Append it to the steps element
            stepsElement?.appendChild(userSelectedChoice);

            // Select the chat body, scroll to bottom
            const chatBody = document.querySelector(
              `.${embedType}__chat__body`
            ) as any;

            if (chatBody) {
              chatBody.scrollTop = stepsElement?.scrollHeight + 800;
            }

            // Call the function to handle the choice
            _handleChoiceSelection("choiceText", choice.id, "nChoiceNode");
          });
        }
      );
    }

    // ***** Image Choice Node *****
    if (steps[currentIndex].type === "image-choice-node") {
      // Create a DOM element with the image choice node
      let imageChoiceNode = document.createElement("div");

      // Get the questionText
      const questionText = steps[currentIndex].data.questionText;

      // Parse the select prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        questionText,
        "bot",
        customizations
      );

      // Filter out images with empty URLs or labels
      const filteredImages = steps[currentIndex].data.images.filter(
        (image: any) => image.image?.trim() !== "" && image.label?.trim() !== ""
      );

      // Generate the carousel HTML
      const carouselHtml = `
      <div class="carousel">
        <div class="carousel__track">
          ${filteredImages
            .map((image: any, _: any) => {
              return `<div class="carousel__item">
                    <img src="${image.image}" alt="${image.label}" id="image_${image.id}_${currentIndex}" />
                  </div>`;
            })
            .join("")}
        </div>
        <svg class="preview-icon-carousel" style="background-color: ${
          customizations?.botMsgColor
        };" width="64" height="64" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g fill=${customizations?.botTextColor}>
                <path d="M11.354 9.354a.5.5 0 0 1-.708-.708l4-4a.5.5 0 0 1 .708.708zm-6 6a.5.5 0 0 1-.708-.708l4-4a.5.5 0 0 1 .708.708z"/>
                <path d="M5 15.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
                <path d="M5.5 15a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 1 0zm10-6a.5.5 0 0 1-1 0V5a.5.5 0 0 1 1 0z"/>
                <path d="M11 5.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
            </g>
        </svg>
      </div>`;

      imageChoiceNode.innerHTML = `
      <div style="position: relative;">
        <div class="${embedType}__chat__bubble__wrapper">
          <img src="${
            customizations?.avatar || botAvatar
          }" alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;">
          ${parsedPrompt}
        </div>
        ${carouselHtml}
        <div class="carousel__controls">
          <button class="carousel__prev" style="background-color: ${
            customizations?.botMsgColor
          }; color: ${customizations?.botTextColor};"> < </button>
          <button class="carousel__next" style="background-color: ${
            customizations?.botMsgColor
          }; color: ${customizations?.botTextColor};"> > </button>
        </div>
      </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(imageChoiceNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as HTMLElement;

      if (chatBody) {
        chatBody.scrollTop = chatBody.scrollHeight + 800;
      }

      // Add event listeners for carousel navigation
      const carouselTrack = imageChoiceNode.querySelector(
        ".carousel__track"
      ) as HTMLElement;

      // Add event listeners for carousel navigation
      const prevButton = imageChoiceNode.querySelector(
        ".carousel__prev"
      ) as HTMLElement;
      const nextButton = imageChoiceNode.querySelector(
        ".carousel__next"
      ) as HTMLElement;

      let initialIndex = 0;
      const totalImages = filteredImages.length;
      prevButton.addEventListener("click", () => {
        // Handle previous button click
        if (initialIndex > 0) {
          initialIndex--;
          updateCarousel(initialIndex);
        }
      });

      nextButton.addEventListener("click", () => {
        // Handle next button click
        if (initialIndex < filteredImages.length - 1) {
          initialIndex++; // Increment currentIndex when clicking next
          updateCarousel(initialIndex);
        }
      });

      function updateCarousel(initialIndex: any) {
        // Update the carousel display based on currentIndex
        const translateX = -initialIndex * 100;
        carouselTrack.style.transform = `translateX(${translateX}%)`;
        if (initialIndex === 0) {
          prevButton.style.opacity = "0"; // Hide "Previous" button at the first image
        } else {
          prevButton.style.opacity = "1"; // Show "Previous" button for other images
        }
        if (initialIndex === totalImages - 1) {
          nextButton.style.opacity = "0"; // Hide "Next" button at the last image
        } else {
          nextButton.style.opacity = "1"; // Show "Next" button for other images
        }
      }

      // Show/hide "Previous" and "Next" buttons based on the current index
      // Create userSelectedChoice div element
      let userSelectedChoice = document.createElement("div");
      userSelectedChoice.style.textAlign = "right";

      // Add event listener to the images
      filteredImages.forEach((image: any, _: any) => {
        const imageElement = document.getElementById(
          `image_${image.id}_${currentIndex}`
        ) as HTMLElement;

        // Add event listener to the image
        imageElement?.addEventListener("click", () => {
          // Extract the last part of the URL as a fallback for label
          let fallbackLabel = image.image.substring(
            image.image.lastIndexOf("/") + 1
          );

          // Use the label if it exists, otherwise use the fallbackLabel
          let displayText =
            image?.label && image.label.trim() !== ""
              ? image.label
              : fallbackLabel;

          // Display the selected choice in the message box on the right
          userSelectedChoice.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
            customizations?.userMsgColor
          }; color: ${customizations?.userTextColor}; ">
            ${parsedHtmlElement(displayText, "user", customizations)}
          </div>`;

          // Append it to the steps element
          stepsElement?.appendChild(userSelectedChoice);

          // Select the chat body, scroll to bottom
          const chatBody = document.querySelector(
            `.${embedType}__chat__body`
          ) as HTMLElement;

          if (chatBody) {
            chatBody.scrollTop = stepsElement?.scrollHeight + 800;
          }

          // Call the function to handle the option
          _handleImageChoiceSelection(image.id);
        });
      });

      // Event listener for image click to open preview
      const imageChoicesToPreview = imageChoiceNode.querySelector(
        `.preview-icon-carousel`
      );
      imageChoicesToPreview.addEventListener("click", () => {
        _handleOpenImageChoicesPreview(filteredImages?.[initialIndex]);
      });
    }

    // ***** Rating Choice Node *****
    if (steps[currentIndex].type === "rating-choice-node") {
      // Create a dom element with rating choice node
      let ratingChoiceNode = document.createElement("div");

      // Get the questionText
      const questionText = steps[currentIndex].data.questionText;

      // Parse the select prompt text as HTML
      const parsedPrompt = parsedHtmlElement(
        questionText,
        "bot",
        customizations
      );

      // Generate the rating HTML
      let ratingHtml = "";
      const ratingType = steps[currentIndex].data.ratingType;
      const numRatings = ratingType === "smiley" ? 5 : Number(ratingType);

      if (ratingType === "smiley") {
        // Generate smiley faces
        for (let i = 1; i <= numRatings; i++) {
          let smiley = "";
          switch (i) {
            case 1:
              smiley = "😡"; // Angry
              break;
            case 2:
              smiley = "😐"; // Neutral
              break;
            case 3:
              smiley = "😊"; // Slightly Happy
              break;
            case 4:
              smiley = "😀"; // Very Happy
              break;
            case 5:
              smiley = "😁"; // Excited
              break;
            default:
              smiley = "😊";
          }
          ratingHtml += `
            <button class="rating__button" id="rating_${i}_${currentIndex}">
              ${smiley}
            </button>`;
        }
      } else {
        // Generate stars-based on rating
        for (let i = 1; i <= numRatings; i++) {
          let starHtml = "";
          for (let j = 0; j < i; j++) {
            starHtml += "⭐";
          }
          ratingHtml += `
            <button class="rating__button" id="rating_${i}_${currentIndex}">
              ${starHtml}
            </button>`;
        }
      }

      ratingChoiceNode.innerHTML = `<div style="position: relative;">
            <div class="${embedType}__chat__bubble__wrapper">
                <img src=${
                  customizations?.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations?.hideAvatar ? "none" : "block"
                  }; height: ${customizations?.avatarSize}px;"
                >
                ${parsedPrompt}
            </div>
            <div class="rating__choice">
              ${ratingHtml}
            </div>
          </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(ratingChoiceNode);

      // Create userSelectedChoice div element
      let userSelectedChoice = document.createElement("div");
      userSelectedChoice.style.textAlign = "right";

      // Add event listener to the buttons
      for (let i = 1; i <= numRatings; i++) {
        const ratingButton = document.getElementById(
          `rating_${i}_${currentIndex}`
        ) as any;

        // Add event listener to the button
        ratingButton?.addEventListener("click", () => {
          // Display the selected rating in the message box on the right
          userSelectedChoice.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${customizations?.userMsgColor}; color: ${customizations?.userTextColor};">${ratingButton.innerHTML}</div>`;

          // Append it to the steps element
          stepsElement?.appendChild(userSelectedChoice);

          // Select the chat body, scroll to bottom
          const chatBody = document.querySelector(
            `.${embedType}__chat__body`
          ) as any;
          if (chatBody) {
            chatBody.scrollTop = stepsElement?.scrollHeight + 800;
          }

          // Disable the image after it's clicked
          ratingButton.style.pointerEvents = "none";
          ratingButton.style.opacity = "0.6"; // optional: make the image look "disabled"

          // Call the function to handle the rating choice
          _handleRatingChoiceSelection(i, ratingButton.innerHTML);
        });
      }
    }

    // ***** Yes or No Choice Node *****
    if (steps[currentIndex].type === "yes-or-no-choice-node") {
      // Create a dom element for yes or no choice node
      let yesOrNoNode = document.createElement("div");

      // Get the question text
      const questionText = steps[currentIndex].data.questionText;

      // Parse the question text as HTML
      const parsedPrompt = parsedHtmlElement(
        questionText,
        "bot",
        customizations
      );

      yesOrNoNode.innerHTML = `<div style="position: relative;">
                <div class="${embedType}__chat__bubble__wrapper">
                    <img src=${
                      customizations?.avatar || botAvatar
                    } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                      style="display: ${
                        customizations?.hideAvatar ? "none" : "block"
                      }; height: ${customizations?.avatarSize}px;"
                    >
                    ${parsedPrompt}
                </div>

                <div class="${embedType}__choice_container ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
            ${steps[currentIndex].data.options
              .map((option: { label: any }, index: any) => {
                const parsedOptionText = parsedHtmlElement(
                  option.label,
                  "bot",
                  customizations,
                  true
                );
                return `<button
                      class="${embedType}__chat__choice__bubble"
                      id="yesNoOption_${index}_${currentIndex}"
                      style="background-color: ${
                        customizations?.optionBubbleMsgColor ||
                        customizations?.botMsgColor
                      }; color: ${
                  customizations?.optionBubbleTextColor ||
                  customizations?.botTextColor
                }; font-size: ${
                  customizations.fontSize + "px"
                }; border-radius: ${
                  (customizations.optionBubbleBorderRadius ||
                    customizations.bubbleBorderRadius) + "px"
                };"
                      >${parsedOptionText}</button>`;
              })
              .join("")}
                </div>
              </div>`;
      // Append it to the steps element
      stepsElement?.appendChild(yesOrNoNode);

      // Create userSelectedOption div element
      let userSelectedOption = document.createElement("div");
      userSelectedOption.style.textAlign = "right";

      // Add event listener to the buttons
      steps[currentIndex].data.options.forEach(
        (option: { id: any; label: any }, index: any) => {
          const yesNoOption = document.getElementById(
            "yesNoOption_" + index + "_" + currentIndex
          ) as any;

          // Add event listener to the button
          yesNoOption?.addEventListener("click", () => {
            // Display the selected option in the message box on the right
            userSelectedOption.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${
              customizations?.userMsgColor
            }; color: ${customizations?.userTextColor}; ">
                ${parsedHtmlElement(option?.label, "user", customizations)}
            </div>`;

            // Append it to the steps element
            stepsElement?.appendChild(userSelectedOption);

            // Select the chat body, scroll to bottom
            const chatBody = document.querySelector(
              `.${embedType}__chat__body`
            ) as any;

            if (chatBody) {
              chatBody.scrollTop = stepsElement?.scrollHeight + 800;
            }

            // Call the function to handle the option
            _handleYesOrNoChoiceSelection(option.id);
          });
        }
      );
    }

    // ***** Opinion Scale Choice Node *****
    if (steps[currentIndex].type === "opinion-scale-choice-node") {
      // Create a dom element with opinion scale choices node
      let opinionScaleChoicesNode = document.createElement("div");

      // Get the opinion scale prompt text
      const { questionText, from, fromLabel, to, toLabel } =
        steps[currentIndex].data;

      // Parse the question text as HTML
      const parsedHtml = parsedHtmlElement(questionText, "bot", customizations);

      opinionScaleChoicesNode.innerHTML = `
                <div style="position: relative;">
                    <div class="${embedType}__chat__bubble__wrapper">
                        <img src=${
                          customizations?.avatar || botAvatar
                        } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                          style="display: ${
                            customizations?.hideAvatar ? "none" : "block"
                          }; height: ${customizations?.avatarSize}px;"
                        >
                        ${parsedHtml}
                    </div>
                    <div class="${embedType}__choice_container ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
                ${Array.from({ length: +to - +from + 1 }, (_, i) => +from + i)
                  .map((choice: number, index: any) => {
                    const choiceText = (
                      index === 0
                        ? `${choice} - ${fromLabel || "Worst"}`
                        : index === +to - +from
                        ? `${choice} - ${toLabel || "Best"}`
                        : choice
                    ).toString();

                    const parsedChoiceText = parsedHtmlElement(
                      choiceText,
                      "bot",
                      customizations,
                      true
                    );

                    return `<button
                          class="${embedType}__chat__choice__bubble"
                          id="opinionScaleChoice_${index}_${currentIndex}"
                          style="background-color: ${
                            customizations?.optionBubbleMsgColor ||
                            customizations?.botMsgColor
                          }; color: ${
                      customizations?.optionBubbleTextColor ||
                      customizations?.botTextColor
                    }; font-size: ${
                      customizations.fontSize + "px"
                    }; border-radius: ${
                      (customizations.optionBubbleBorderRadius ||
                        customizations.bubbleBorderRadius) + "px"
                    };"
                          >${parsedChoiceText}</button>`;
                  })
                  .join("")}
                    </div>
                  </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(opinionScaleChoicesNode);

      // Create userSelectedChoice div element
      let userSelectedChoice = document.createElement("div");
      userSelectedChoice.style.textAlign = "right";

      // Add event listener to the buttons
      Array.from({ length: +to - +from + 1 }, (_, i) => +from + i).forEach(
        (choice: number, index: any) => {
          const opinionScaleChoice = document.getElementById(
            "opinionScaleChoice_" + index + "_" + currentIndex
          ) as any;

          opinionScaleChoice?.addEventListener("click", () => {
            const parsedOpinion = parsedHtmlElement(
              choice.toString(),
              "user",
              customizations
            );

            // Display the selected choice in the message box on the right
            userSelectedChoice.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${customizations?.userMsgColor}; color: ${customizations?.userTextColor}; ">
                ${parsedOpinion}
            </div>`;

            // Append it to the steps element
            stepsElement?.appendChild(userSelectedChoice);

            // Select the chat body, scroll to bottom
            const chatBody = document.querySelector(
              `.${embedType}__chat__body`
            ) as any;

            if (chatBody) {
              chatBody.scrollTop = stepsElement?.scrollHeight + 800;
            }

            // Call the function to handle the choice
            _handleOpinionSelection(index, parsedOpinion);
          });
        }
      );
    }

    // =============== SPECIAL NODES ===============
    // ***** HTML Node *****
    if (steps[currentIndex].type === "html-node") {
      // Create a dom element with html node
      let htmlNode = document.createElement("div");
      htmlNode.style.position = "relative";

      htmlNode.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
                <img src=${
                  customizations?.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations?.hideAvatar ? "none" : "block"
                  }; height: ${customizations?.avatarSize}px;"
                >
                <div id="html_${currentIndex}" class="${embedType}__chat__bubble bot custom-html" style="background-color: ${
        customizations?.botMsgColor
      }; color: ${customizations?.botTextColor};">
                  ${steps[currentIndex].data.html}
                </div>
            </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(htmlNode);

      // Display the next node
      _displayNextNode();
    }

    // ***** User Redirect Node *****
    if (steps[currentIndex].type === "user-redirect-node") {
      // Create a dom element with user redirect node
      let userRedirectNode = document.createElement("div");
      userRedirectNode.style.position = "relative";

      // Get the redirect prompt text
      const redirectPrompt = steps[currentIndex].data.redirectPrompt;

      // Parse the redirect prompt text as HTML
      const parsedHtml = parsedHtmlElement(
        redirectPrompt,
        "bot",
        customizations
      );

      userRedirectNode.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
            <img src=${
              customizations?.avatar || botAvatar
            } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
              style="display: ${
                customizations?.hideAvatar ? "none" : "block"
              }; height: ${customizations?.avatarSize}px;"
            >
            <a href="${steps[currentIndex].data.link}" target="_blank">
              ${parsedHtml}
            </a>
        </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(userRedirectNode);

      // Display the next node
      _displayNextNode();
    }

    // ***** Email Node *****
    if (steps[currentIndex].type === "email-node") {
      // Create a dom element with message node
      let messageNode = document.createElement("div");
      messageNode.style.position = "relative";

      // Get the node data
      const nodeData = steps[currentIndex].data;

      // Initialize message parts
      let toMessagePart = "";
      let ccMessagePart = "";
      let bccMessagePart = "";

      // Construct the To part
      if (nodeData.to) {
        toMessagePart = `To: ${
          Array.isArray(nodeData.to) ? nodeData.to.join(", ") : nodeData.to
        }`;
      }

      // Construct the CC part
      if (nodeData.cc && nodeData.cc.length > 0) {
        ccMessagePart = `CC: ${
          Array.isArray(nodeData.cc) ? nodeData.cc.join(", ") : nodeData.cc
        }`;
      }

      // Construct the BCC part
      if (nodeData.bcc && nodeData.bcc.length > 0) {
        bccMessagePart = `BCC: ${
          Array.isArray(nodeData.bcc) ? nodeData.bcc.join(", ") : nodeData.bcc
        }`;
      }

      // Combine the parts into the final message
      const parts = [toMessagePart, ccMessagePart, bccMessagePart].filter(
        (part) => part !== ""
      );
      const messageText = `*** PREVIEW: An email will be sent to ${parts.join(
        " | "
      )}`;

      // Parse the message text as HTML
      const parsedHtml = parsedHtmlElement(messageText, "bot", customizations);

      messageNode.innerHTML = `
      <div style="position: relative;">
        <div
          class="${embedType}__chat__bubble__wrapper"
        >
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          ${parsedHtml}
        </div>
      </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(messageNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();
    }

    // ***** Human Handover Node *****
    if (steps[currentIndex].type === "human-handover-node") {
      // Create a dom element with message node
      let messageNode = document.createElement("div");
      messageNode.style.position = "relative";

      const messageText = `*** PREVIEW: The human handover will be triggered here`;

      // Parse the message text as HTML
      const parsedHtml = parsedHtmlElement(messageText, "bot", customizations);

      messageNode.innerHTML = `
      <div style="position: relative;">
        <div
          class="${embedType}__chat__bubble__wrapper"
        >
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          ${parsedHtml}
        </div>
      </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(messageNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();
    }

    // ***** Email Node *****
    if (steps[currentIndex].type === "zapier-node") {
      // Create a dom element with message node
      let messageNode = document.createElement("div");
      messageNode.style.position = "relative";

      const messageText = `*** PREVIEW: Zapier will be triggered here`;

      // Parse the message text as HTML
      const parsedHtml = parsedHtmlElement(messageText, "bot", customizations);

      messageNode.innerHTML = `
      <div style="position: relative;">
        <div
          class="${embedType}__chat__bubble__wrapper"
        >
          <img src=${
            customizations?.avatar || botAvatar
          } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
            style="display: ${
              customizations?.hideAvatar ? "none" : "block"
            }; height: ${customizations?.avatarSize}px;"
          >
          ${parsedHtml}
        </div>
      </div>`;

      // Append it to the steps element
      stepsElement?.appendChild(messageNode);

      // Select the chat body, scroll to bottom
      const chatBody = document.querySelector(
        `.${embedType}__chat__body`
      ) as any;

      if (chatBody) {
        chatBody.scrollTop = chatBody?.scrollHeight + 800;
      }

      // Display the next node
      _displayNextNode();
    }

    // ***** Navigate Node *****
    if (steps[currentIndex].type === "navigate-node") {
      // Create a dom element with navigate node
      let navigateNode = document.createElement("div");

      // Get the navigate prompt text
      const navigatePrompt = steps[currentIndex].data.navigatePrompt;

      // Assuming `parsedHtmlElement` is a function that returns HTML elements for text.
      const parsedPrompt = parsedHtmlElement(
        navigatePrompt,
        "bot",
        customizations
      );

      // Filter out empty navigate options
      const filteredNavigateOptions = steps[
        currentIndex
      ].data.navigateOptions.filter(
        (option: any) => option.navigateText.trim() !== ""
      );

      navigateNode.innerHTML = `
      <div style="position: relative;">
          <div class="${embedType}__chat__bubble__wrapper">
              <img src=${
                customizations?.avatar || botAvatar
              } alt="Avatar" class="${embedType}__chat__avatar">
              ${parsedPrompt}
          </div>

          <div class="${embedType}__option_bubble_cont ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" style="margin-left: ${customizations?.avatarSize + 4}px;">
              ${filteredNavigateOptions
                .map((option: any, index: any) => {
                  const parsedNavigateText = parsedHtmlElement(
                    option.navigateText,
                    "bot",
                    customizations,
                    true
                  );
                  return `
                  <button
                    class="${embedType}__chat__navigate__option__bubble"
                    id="navigateOption_${index}_${currentIndex}"
                    style="background-color: ${
                      customizations?.optionBubbleMsgColor ||
                      customizations?.botMsgColor
                    }; color: ${
                    customizations?.optionBubbleTextColor ||
                    customizations?.botTextColor
                  }; font-size: ${
                    customizations.fontSize + "px"
                  }; border-radius: ${
                    (customizations.optionBubbleBorderRadius ||
                      customizations.bubbleBorderRadius) + "px"
                  };"
                  >${parsedNavigateText}</button>`;
                })
                .join("")}
          </div>

          <button class="${embedType}__chat__check_option__bubble  ${
        customizations?.hideAvatar ? "no-logo" : "with-logo"
      }" id="doneNavigatingText_${currentIndex}"
                      style="background-color: ${
                        customizations?.botMsgColor
                      }; color: ${customizations?.botTextColor};
                        margin-left: ${customizations?.avatarSize + 4}px;">
                        ${steps[currentIndex].data.doneNavigatingText}
                    </button>
      </div>`;

      stepsElement?.appendChild(navigateNode);

      // Add event listener to the buttons
      filteredNavigateOptions.forEach((option: any, index: any) => {
        const navigateOptionButton = document.getElementById(
          `navigateOption_${index}_${currentIndex}`
        );
        navigateOptionButton.addEventListener("click", () => {
          if (option.openInNewTab) {
            window.open(option.navigateLink, "_blank").focus();
          } else {
            window.location.href = option.navigateLink;
          }
        });
      });

      // Add event listener to the doneNavigatingText button
      const doneNavigatingTextButton = document.getElementById(
        `doneNavigatingText_${currentIndex}`
      ) as any;

      doneNavigatingTextButton.addEventListener("click", () => {
        // Disable the done button
        doneNavigatingTextButton.disabled = true;

        // Change cursor to not-allowed
        doneNavigatingTextButton.style.cursor = "not-allowed";

        // Create userSelectedOption div element
        let userSelectedOption = document.createElement("div");
        userSelectedOption.style.textAlign = "right";

        // Display the selected option in the message box on the right
        userSelectedOption.innerHTML = `<div class="${embedType}__chat__bubble user" style="background-color: ${customizations?.userMsgColor}; color: ${customizations?.userTextColor};">
            ${steps[currentIndex].data.doneNavigatingText}
          </div>`;

        // Append it to the steps element
        stepsElement?.appendChild(userSelectedOption);

        // Select the chat body, scroll to bottom
        const chatBody = document.querySelector(
          `.${embedType}__chat__body`
        ) as any;

        if (chatBody) {
          chatBody.scrollTop = stepsElement?.scrollHeight + 800;
        }

        // Call the function to handle the option
        _handleNavigationDoneSelection();
      });
    }
  }
};
