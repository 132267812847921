import React from "react";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import logoText from "../../../assets/images/logo/logoTextWhite.png";

const Privacy = () => {
  const history = useHistory();
  return (
    <Row
      className="cb-auth-container"
      style={{
        height: "100vh",
      }}
    >
      <Col className="hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={18}
            xl={18}
            lg={20}
            md={22}
            sm={20}
            style={{ textAlign: "center", padding: "0px 10px 0px 10px" }}
          >
            <img
              src={logoText}
              alt="logo"
              style={{
                width: "40%",
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
            />

            <h1 className="hp-mb-sm-0">Privacy Policy</h1>
            <br />
            <h3>Your privacy is critically important to us.</h3>
            <br />
            <p>
              It is Conferbot's policy to respect your privacy regarding any
              information we may collect from you across our website, and other
              sites we own and operate.
            </p>
            <p>
              We don't share your information with anyone except to comply with
              the law, develop our products, or protect our rights.
            </p>

            <p>
              Like most website operators, Conferbot collects
              non-personally-identifying information of the sort that web
              browsers and servers typically make available, such as the browser
              type, language preference, referring site, and the date and time
              of each visitor request.
            </p>

            <p>
              Although most changes are likely to be minor, Conferbot may change
              its Privacy Policy from time to time, and in Conferbot's sole
              discretion. Conferbot encourages visitors to frequently check this
              page for any changes to its Privacy Policy. Your continued use of
              this site after any change in this Privacy Policy will constitute
              your acceptance of such change.
            </p>

            <Col
              className="hp-other-links hp-mt-24"
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button type="primary" onClick={() => history.push("/login")}>
                Go back
              </Button>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Privacy;
