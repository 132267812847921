import {
  nodes,
  edges,
  // Find and display node
  _findAndDisplayNode,
  _findAndDisplayNodeWithPort,
  // Current steps and index
  _getCurrentStepsAndIndex,
  // Variables
  _getVariables,
  _setVariables,
  _handleAddToTranscript,
  _pushDataToRecord,
  _setAnswerVariable,
  _setUserMetaData,
} from "../../ChatbotFlowPreview";
import {
  _sleep,
  _validateEmailHelper,
  _validateUrlHelper,
  _displayErrorMessage,
  parsedHtmlElement,
  _showTypingAnimationForGivenTime,
} from "../../utils/helpers";
import botAvatar from "../../../../../../../assets/images/pages/customize/botLogo.png";

// Handle user input
export const _handleUserInputSubmit = (
  embedType: any,
  customizations: any,
  value: any
) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;
  // Get the input element
  const inputElement = document.querySelector("#inputField") as any;

  // If email, validate email
  if (steps[currentIndex].data.type === "email") {
    // Remove leading and trailing spaces
    const isValidEmail = _validateEmailHelper(value.trim());
    if (!isValidEmail) {
      _displayErrorMessage(
        embedType,
        customizations,
        steps[currentIndex].data.incorrectEmailResponse !== undefined
          ? steps[currentIndex].data.incorrectEmailResponse
          : "Please enter a valid email address"
      );
      return;
    }
  }

  // If its a number, make sure it's a number
  if (steps[currentIndex].data.type === "number") {
    // Remove leading and trailing spaces
    const isValidNumber = !isNaN(value.trim());
    if (!isValidNumber) {
      _displayErrorMessage(
        embedType,
        customizations,
        steps[currentIndex].data.incorrectNumberResponse !== undefined
          ? steps[currentIndex].data.incorrectNumberResponse
          : "Please enter a valid number"
      );
      return;
    }
  }

  // If its a url, make sure it's a valid url
  if (steps[currentIndex].data.type === "url") {
    // Remove leading and trailing spaces
    const isValidUrl = _validateUrlHelper(value.trim());
    if (!isValidUrl) {
      _displayErrorMessage(
        embedType,
        customizations,
        steps[currentIndex].data.incorrectUrlResponse !== undefined
          ? steps[currentIndex].data.incorrectUrlResponse
          : "Please enter a valid url"
      );
      return;
    }
  }

  // Add the user input to the message element
  let userInputMessage = document.createElement("div");
  userInputMessage.style.textAlign = "right";

  userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
            <p class="${embedType}__chat__bubble user" 
              style="background-color: ${customizations.userMsgColor}; 
              color: ${customizations.userTextColor};" 
              id="user-input_${currentIndex}">
              ${value}
            </p>
          </div>`;

  // Add the user input to the steps element
  stepsElement.appendChild(userInputMessage);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`);
  chatBody.scrollTop = stepsElement.scrollHeight + 800;

  // Input element disabled to true
  inputElement.disabled = true;

  // Clear the input field
  inputElement.value = "";

  // If name, make bot say "Hello, {name}"
  if (steps[currentIndex].data.type === "name") {
    const nameGreetMessageText = steps[currentIndex].data.nameGreet
      ? steps[currentIndex].data.nameGreet.replace("{name}", value)
      : `Nice to meet you, ${value}`;

    // Parse the message text as HTML
    const parsedHtml = parsedHtmlElement(
      nameGreetMessageText,
      "bot",
      customizations
    );

    let nameGreetMessage = document.createElement("div");
    nameGreetMessage.style.position = "relative";
    nameGreetMessage.innerHTML = `<div class="${embedType}__chat__bubble__wrapper">
                <img src=${
                  customizations.avatar || botAvatar
                } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
                  style="display: ${
                    customizations.hideAvatar ? "none" : "block"
                  }; height: ${customizations.avatarSize}px;"
                >
                ${parsedHtml}
           </div>`;

    _showTypingAnimationForGivenTime(embedType, customizations, 1000).then(
      () => {
        // Add the bot message to the steps element
        stepsElement.appendChild(nameGreetMessage);

        // Select the chat body, scroll to bottom
        const chatBody = document.querySelector(
          `.${embedType}__chat__body`
        ) as any;
        chatBody.scrollTop = stepsElement.scrollHeight + 800;
      }
    );
  }

  // If name, email or phone number, set _setUserMetaData
  if (
    steps[currentIndex].data.type === "name" ||
    steps[currentIndex]?.data?.type === "email" ||
    steps[currentIndex]?.data?.type === "mobile" ||
    steps[currentIndex]?.data?.type === "phone"
  ) {
    // Set _setUserMetaData with phone number
    _setUserMetaData(steps[currentIndex]?.data?.type, value);
  }

  // Record the user input
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-input-response",
    type: steps[currentIndex].data.type,
    text: value,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", value);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, value);

  _sleep(steps[currentIndex].data.type === "name" ? 2000 : 500).then(() => {
    // Display the next step
    _findAndDisplayNode();
  });
};

// Handle user file upload
export const _handleFileUpload = async (
  embedType: any,
  customizations: any,
  file: any
) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // If the file size is larger than 5MB, display error message
  if (file.size > 5000000) {
    _displayErrorMessage(
      embedType,
      customizations,
      "Please upload a file smaller than 5MB"
    );
    return;
  }

  // Add the uploaded file name to the message element
  let userInputMessage = document.createElement("div");
  userInputMessage.style.textAlign = "right";

  userInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
            <p class="${embedType}__chat__bubble user"
              style="background-color: ${customizations.userMsgColor};
              text-decoration: underline;
              color: ${customizations.userTextColor};"
              id="user-input_${currentIndex}">
              ${file.name}
            </p>
          </div>`;

  // Add the user input to the steps element
  const stepsElement = document.querySelector("#steps") as any;
  stepsElement.appendChild(userInputMessage);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
  chatBody.scrollTop = stepsElement.scrollHeight + 800;

  // uploadBtn disabled to true
  const uploadBtn = document.querySelector("#uploadBtn") as any;
  uploadBtn.disabled = true;

  // Set the display none for uploadNudge id
  const uploadNudge = document.querySelector("#uploadNudge") as any;
  uploadNudge.style.display = "none";

  // Get uploadSvg element and change cursor to not-allowed
  const uploadSvg = document.querySelector("#uploadSvg") as any;
  uploadSvg.style.cursor = "not-allowed";

  // Attach the file to the form data
  const fmData = new FormData();
  fmData.append("file", file);

  // Record the user input
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-input-response",
    type: steps[currentIndex].data.type,
    url: file.name,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", file.name);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, file.name);

  // Display the next step
  _findAndDisplayNode();
};

// Handle user date input
export const _handleUserDateSelect = (embedType: any, customizations: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;
  // Get the input element
  const dateElement = document.getElementById(
    "date__input_" + currentIndex
  ) as any;

  let dateInputMessage = document.createElement("div");
  dateInputMessage.style.textAlign = "right";
  dateInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
      <p class="${embedType}__chat__bubble user" style="background-color: ${customizations?.userMsgColor}; color: ${customizations?.userTextColor};">${dateElement.value}</p>
    </div>`;

  // Add the user input to the steps element
  stepsElement.appendChild(dateInputMessage);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
  chatBody.scrollTop = stepsElement.scrollHeight + 800;

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-input-response",
    type: steps[currentIndex].data.type,
    text: dateElement.value,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", dateElement.value);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, dateElement.value);

  // Date element disabled to true
  dateElement.disabled = true;
  // Display the next step
  _sleep(400).then(() => {
    _findAndDisplayNode();
  });
};

// Handle rating selection
export const _handleRatingSelection = (rating: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-rating",
    type: steps[currentIndex].data.type,
    rating: rating,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", rating);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, rating);

  // Add the next node to the steps array
  _findAndDisplayNode();
};

// Handle quiz option selection
export const _handleQuizOptionSelection = (option: any, correctAnswer: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Record the user selection
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-selected-quiz-option",
    options: steps[currentIndex].data,
    selectedOption: steps[currentIndex].data[option],
    correctAnswer: correctAnswer,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", steps[currentIndex].data[option]);

  // Get the option buttons & toggle disabled to true
  const option1Element = document.getElementById(
    "quizOption1_" + currentIndex
  ) as any;
  option1Element.disabled = true;

  const option2Element = document.getElementById(
    "quizOption2_" + currentIndex
  ) as any;
  option2Element.disabled = true;

  // Select and disable third option only if it is not disabled
  if (!steps[currentIndex].data.disableOption3) {
    const option3Element = document.getElementById(
      "quizOption3_" + currentIndex
    ) as any;
    option3Element.disabled = true;
  }

  // Select and disable forth option only if it is not disabled
  if (!steps[currentIndex].data.disableOption4) {
    const option4Element = document.getElementById(
      "quizOption4_" + currentIndex
    ) as any;
    option4Element.disabled = true;
  }

  // Select and disable fifth option only if it is not disabled
  if (!steps[currentIndex].data.disableOption5) {
    const option5Element = document.getElementById(
      "quizOption5_" + currentIndex
    ) as any;
    option5Element.disabled = true;
  }

  // If the selected option is incorrect, then find the next node
  if (option !== correctAnswer) {
    // Get the source handle key
    const sourceKey = "source-1";

    // Call the _findAndDisplayNodeWithPort function
    _findAndDisplayNodeWithPort(sourceKey);
  } else if (option === correctAnswer) {
    // If the selected option is correct, then find the next node (variable node) using port 1
    const nextNodeEdge = edges.find(
      (edge: any) =>
        edge?.sourceHandle === "source-2" &&
        edge?.source === steps[currentIndex].id
    );

    if (nextNodeEdge) {
      // Get the target node
      const nextNode = nodes?.find(
        (node: any) => node?.id === nextNodeEdge.target
      );

      // If the next node is a variable node
      if (nextNode.type === "variable-node") {
        // Get the variables
        const variables = _getVariables();

        // Set the nextNode.data.name as key and nextNode.data.value as value
        // If previous value exists, add both
        const varName = nextNode.data.name;
        const value = variables[varName]
          ? Number(variables[varName]) + Number(nextNode.data.value)
          : Number(nextNode.data.value);

        // Set the variables
        _setVariables(varName, value);

        // Get the source handle key
        const sourceKey = "source-1";

        // Call the _findAndDisplayNodeWithPort function
        _findAndDisplayNodeWithPort(sourceKey);
      }
    }
  }
};

// Handle user range input
export const _handleUserRangeSubmit = (embedType: any, customizations: any) => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;
  // Get the input element
  const inputElement = document.querySelector("#inputField") as any;

  // Add the user input to the message element
  let userRangeInputMessage = document.createElement("div");
  userRangeInputMessage.style.textAlign = "right";
  userRangeInputMessage.innerHTML = `<div class="${embedType}__chat__bubble_container">
            <p class="${embedType}__chat__bubble user" 
              style="background-color: ${customizations.userMsgColor}; 
              color: ${customizations.userTextColor};" 
              id="user-input_${currentIndex}">
              ${inputElement.value}
            </p>
          </div>`;

  // Add the user input to the steps element
  stepsElement.appendChild(userRangeInputMessage);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
  chatBody.scrollTop = stepsElement.scrollHeight + 800;

  // Input element disabled to true
  inputElement.disabled = true;

  // Clear the input field
  inputElement.value = "";

  // Add the user input to the record array
  _pushDataToRecord({
    id: steps[currentIndex].id,
    shape: "user-input-response-range",
    type: steps[currentIndex].data.type,
    text: inputElement.value,
  });

  // Add the user input to the transcript
  _handleAddToTranscript("user", inputElement.value);

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, inputElement.value);

  // Set the port of the range node to be displayed
  let sourceKey;
  // If input value is less than the minimum value, set source to 1
  if (Number(inputElement.value) < Number(steps[currentIndex].data.minVal)) {
    sourceKey = "source-1";
  } else if (
    Number(inputElement.value) > Number(steps[currentIndex].data.maxVal)
  ) {
    // If input value is greater than the maximum value, set source to 3
    sourceKey = "source-3";
  } else {
    // If input value is between the minimum and maximum value, set source to 2
    sourceKey = "source-2";
  }

  // Clear the input field
  inputElement.value = "";

  // Add the next node to the steps array with the port number
  _findAndDisplayNodeWithPort(sourceKey);
};
