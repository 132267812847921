import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import { useSelector } from "react-redux";
import { BiSolidBot } from "react-icons/bi";
import GraphIcon from "@assets/images/components/graph.png";
import AntennaIcon from "@assets/images/components/signal-tower.png";
import FlowIcon from "@assets/images/components/flow-diagram.png";
import "@assets/css/Metrics.css";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const MetricsOptionsList = ({
  selectedOption,
  setSelectedOption,
  handleSelectChatbot,
  loading,
  selectedChatbot,
}) => {
  const { chatbotNames } = useSelector((state) => state.admin);
  const { t } = useTranslation();
  const [selectedChatbotName, setSelectedChatbotName] = useState("");

  const metricsOptions = [
    {
      id: 1,
      Icon: GraphIcon,
      name: t("metrics.graph"),
      content: t("metrics.graphInfo"),
      color: "blue",
    },
    {
      id: 2,
      Icon: FlowIcon,
      name: t("metrics.responseTable"),
      content: t("metrics.responseTableInfo"),
      color: "blue",
    },
    {
      id: 3,
      Icon: AntennaIcon,
      name: t("metrics.demographics"),
      content: t("metrics.demographicsInfo"),
      color: "blue",
    },
  ];

  useEffect(() => {
    if (chatbotNames && chatbotNames.length > 0) {
      const latestChatbot = chatbotNames[chatbotNames.length - 1];
      handleSelectChatbot(latestChatbot._id);
      setSelectedChatbotName(latestChatbot.name);
    }
  }, [chatbotNames]);

  useEffect(() => {
    if (selectedChatbot) {
      const chatbot = chatbotNames.find((c) => c._id === selectedChatbot);
      if (chatbot) {
        setSelectedChatbotName(chatbot.name);
      }
    }
  }, [selectedChatbot, chatbotNames]);

  const heading = metricsOptions?.filter((a) => selectedOption === a.id);

  return (
    <div style={{ padding: "20px" }}>
      {/* Select Chatbot */}
      <div className="metrics-chats-option">
        <Button
          type="text"
          size="small"
          style={{
            borderRadius: "4px",
            background: "#0b4eb8",
            color: "#fff",
            maxWidth: "100%",
            minWidth: "100%",
          }}
        >
          <Button
            type="text"
            icon={<BiSolidBot size={15} />}
            size="small"
            style={{
              borderRadius: "4px",
              background: "#0b4eb8",
              color: "#fff",
              height: "15px",
              width: "35px",
            }}
          />
          <Select
            showSearch
            style={{
              width: "100%",
              borderRadius: "4px",
              color: "#fff",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            placeholder={t("metrics.selectAChatbotPH")}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size="small"
            bordered={false}
            onChange={(value) => {
              handleSelectChatbot(value);
              const selectedBot = chatbotNames.find((c) => c._id === value);
              if (selectedBot) {
                setSelectedChatbotName(selectedBot.name);
              }
            }}
            value={selectedChatbotName || undefined}
          >
            {!loading &&
              chatbotNames?.length > 0 &&
              chatbotNames?.map((chatbot) => (
                <Option key={chatbot._id} value={chatbot._id}>
                  {chatbot.name}
                </Option>
              ))}
          </Select>
        </Button>
      </div>

      <h4 className="main-heading">{heading[0]?.name}</h4>
      {metricsOptions?.map((item, index) => {
        const { id, Icon, name, content, color } = item;
        return (
          <div
            key={index}
            style={{
              background: selectedOption === id && color,
              transition: "background 0.2s ease-out",
            }}
            className="option-container"
          >
            <div
              className="metrics-option-list"
              onClick={() => setSelectedOption(id)}
            >
              <img src={Icon} alt="icon" />
              <div>
                <span className="card-option-heading">{name}</span>
                <p className="card-option-content">{content}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MetricsOptionsList;
