import { Button } from "antd";
import { useTranslation } from "react-i18next";

const BotsList = ({ chatbotNames, selectedBotId, handleSelectChatbot }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bots-list-container">
        {chatbotNames?.length > 0 ? (
          <>
            {chatbotNames?.map((chatbot, index) => {
              return (
                <div
                  key={index}
                  className="chats-list"
                  onClick={() => handleSelectChatbot(chatbot._id)}
                  style={{
                    backgroundColor:
                      selectedBotId === chatbot?._id ? "#0b4eb8" : "#fff",
                    color: selectedBotId === chatbot?._id ? "#fff" : "#000",
                    transition: "background-color 0.3 ease-out",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      icon={
                        chatbot && chatbot?.name
                          ? chatbot.name?.split(" ").length > 1
                            ? chatbot.name?.split(" ")[0][0]?.toUpperCase() +
                              chatbot.name?.split(" ")[1][0]?.toUpperCase()
                              ? chatbot.name?.split(" ")[0][0]?.toUpperCase()
                              : ""
                            : chatbot.name?.split(" ")[0][0]?.toUpperCase()
                          : chatbot?.name[0]?.toUpperCase()
                      }
                      type="text"
                      size="small"
                      disabled={false}
                      style={{
                        backgroundColor:
                          selectedBotId === chatbot?._id ? "#fff" : " #0b4eb8",
                        color: selectedBotId === chatbot?._id ? "#000" : "#fff",
                        marginLeft: "10px",
                        marginRight: "10px",
                        borderRadius: "50%",
                        minWidth: "30px",
                      }}
                    />

                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {chatbot?.name?.length > 30
                        ? chatbot?.name?.slice(0, 30) + "..."
                        : chatbot?.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <h3
            style={{
              height: "calc(100vh - 220px)",
              marginTop: "1.5rem",
              textAlign: "center",
              color: "#000",
              fontSize: "1.2rem",
              fontWeight: "500",
            }}
          >
            {t("chatMessages.chatList.noBotFound")} <br />
            {t("chatMessages.chatList.itsGhostTown")}
          </h3>
        )}
      </div>
    </>
  );
};

export default BotsList;
