import { useState, useRef } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Switch, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import { PopoverColorPicker } from "../../../../../Popover/PopoverColorPicker";
import RedFlag from "@assets/images/pages/customize/red-flag.png";
import DeleteIcon from "@assets/images/pages/customize/bin.png";
import Arrow from "@assets/images/pages/customize/down.png";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const headerLogoImageRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);

  const { t } = useTranslation();

  // Handle Color Picker
  const handleColor = (value, key) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, value.hex));
  };

  const toggleShowHeaderOptions = () => {
    setVisible(!visible);
  };

  // Spinner indicator
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 35,
      }}
      spin
    />
  );

  // Handle Change Header Logo
  const handleChangeHeaderLogo = async (event) => {
    const file = event.target.files[0];
    const fmData = new FormData();

    // Don't allow size greater than 3MB
    if (file.size > 3 * 1024 * 1024) {
      message.error("Image/GIF size must be less than 3MB");
      return;
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("image", file);
    // Make request to upload image
    try {
      setLoading(true);
      await uploadChatbotMedia(bid, fmData, config)
        .then((res) => {
          dispatch(
            updateCurrentChatbotCustomizationsAction(bid, "logo", res.data.url)
          );
          setLoading(false);
          message.success("Header logo updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Error updating header logo");
        });
    } catch (error) {
      setLoading(false);
      message.error("Error updating header logo");
    }
  };

  const handleHideHeaderLogo = () => {
    setHideLogo(!hideLogo);

    dispatch(
      updateCurrentChatbotCustomizationsAction(bid, "hideLogo", !hideLogo)
    );
  };

  // Handle Enable Tagline
  const handleEnableTagline = () => {
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "enableTagline",
        !customizations?.enableTagline
      )
    );
  };

  return (
    <>
      <div className="dropdown-menu">
        <div onClick={toggleShowHeaderOptions} className="dropdown-trigger">
          <span>
            <img
              src={RedFlag}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="red-flag"
            />
            {t("customize.layout.header")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            alt="arrow"
          />
        </div>
        {visible && (
          <>
            <div
              style={{
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
                color: "#45496e",
              }}
            >
              {/* Header Logo Image */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: customizations?.hideLogo === true && "15px",
                }}
              >
                {t("customize.layout.hideHeaderLogo")}
                <Switch
                  checked={customizations?.hideLogo}
                  size="small"
                  onClick={handleHideHeaderLogo}
                />
              </div>
              {!customizations?.hideLogo && (
                <>
                  <label htmlFor="file-upload">
                    <button className="general-input-btn" htmlFor="file-upload">
                      {t("customize.layout.uploadImage/GIF")}
                      <input
                        id="file-upload"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => handleChangeHeaderLogo(e)}
                        ref={headerLogoImageRef}
                      />
                    </button>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <Spin indicator={antIcon} size="large" />
                      </div>
                    ) : (
                      <>
                        {customizations?.logo !== "" && (
                          <>
                            <img
                              src={customizations?.logo}
                              style={{ height: "80px", width: "80px" }}
                              alt="logo"
                            />
                            <img
                              onClick={() => {
                                dispatch(
                                  updateCurrentChatbotCustomizationsAction(
                                    bid,
                                    "logo",
                                    ""
                                  )
                                );

                                // Clear input file
                                if (headerLogoImageRef.current) {
                                  headerLogoImageRef.current.value = "";
                                }

                                message.success(
                                  "Header logo deleted successfully"
                                );
                              }}
                              src={DeleteIcon}
                              style={{
                                marginTop: "-30px",
                                cursor: "pointer",
                              }}
                              alt="delete-icon"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}

              {/* Header Logo Text */}
              <span>{t("customize.layout.headerText")}</span>
              <div style={{ textAlign: "center" }}>
                <input
                  className="general-input"
                  type="text"
                  value={customizations?.logoText}
                  onChange={(e) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "logoText",
                        e.target.value
                      )
                    )
                  }
                />
              </div>

              {/* Header Tag Line */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                {t("customize.layout.enableTagLine")}
                <Switch
                  checked={customizations?.enableTagline}
                  size="small"
                  onClick={handleEnableTagline}
                />
              </div>
              {customizations?.enableTagline && (
                <div style={{ textAlign: "center" }}>
                  <input
                    type="text"
                    className="general-input"
                    value={customizations?.tagline}
                    onChange={(e) =>
                      dispatch(
                        updateCurrentChatbotCustomizationsAction(
                          bid,
                          "tagline",
                          e.target.value
                        )
                      )
                    }
                  />
                </div>
              )}

              {/* Top Nav Background Color */}
              <label htmlFor="color-picker" className="flex-class-label">
                {t("customize.layout.topBarBackgroundColor")}:
                <div className="color-picker">
                  <PopoverColorPicker
                    color={customizations?.headerBgColor}
                    onChange={(color) => handleColor(color, "headerBgColor")}
                  />
                </div>
              </label>

              {/* Top Bar Text Color */}
              <label htmlFor="color-text-picker" className="flex-class-label">
                {t("customize.layout.topBarTextColor")}:
                <div className="color-picker">
                  <PopoverColorPicker
                    color={customizations?.headerTextColor}
                    onChange={(color) => handleColor(color, "headerTextColor")}
                  />
                </div>
              </label>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
