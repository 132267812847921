import { useEffect, useRef, useState } from "react";
import { Button, Spin, Switch, Tooltip, message } from "antd";
import {
  PaperClipOutlined,
  AudioOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import Picker from "emoji-picker-react";
import { Messages, ChatDate, ChatInput } from "./components";
import { uploadChatInputFile } from "@api/user/member";
import SelectChatSVG from "@assets/images/pages/chatList/select-chat.svg";
import { useTranslation } from "react-i18next";

const ChatbotMessages = ({
  // Selected Chatbot
  selectedBotName,
  // Selected Chat
  selectedChat,
  showAcceptHandoverButton,
  acceptHandoverButtonLoading,
  acceptHandoverFromChat,
  // Chat Input
  chatInput,
  setChatInput,
  handleChatInputChange,
  handleSendMessage,
  handleEndChat,
  handleLeaveChat,
  isChatEnabled,
  isLeaveChatEnabled,
  // File Input
  isFileInput,
  setIsFileInput,
  uploadedFile,
  setUploadedFile,
  uploadedFileUrl,
  setUploadedFileUrl,
  // Visitor Input
  isVisitorInputEnabled,
  handleToggleVisitorInput,
  // Invite to Chat
  handleInviteToChat,
  // Loading
  loading,
  messageLoading,
}) => {
  const fileInputRef = useRef(null);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // Scroll to the bottom of the chatContainer
    const chatContainer = document.getElementById("chatMessageContainer");

    if (chatContainer) {
      setTimeout(() => {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth",
        });
      }, 0);
    }
  }, [selectedChat]);

  // Handle emoji select
  const handleEmojiSelect = (emojiObject) => {
    const emoji = emojiObject.emoji;

    // Insert emoji into the chat input
    setChatInput((prevInput) => ({
      ...prevInput,
      message: prevInput.message + emoji,
    }));

    // Set focus on the chat input
    document.getElementById("chatInput")?.focus();
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fmData = new FormData();
    fmData.append("image", file);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await uploadChatInputFile(fmData, config);

      // Set file input to true
      setIsFileInput(true);

      // Set uploaded file to extract the file name
      setUploadedFile(file);

      // Extract file name from URL
      const url = response.data.url;

      // Set uploaded file URL
      setUploadedFileUrl(url);

      // Set chat input
      setChatInput((prevInput) => ({
        ...prevInput,
        message: url.substring(url.lastIndexOf("/") + 1).replace(/%20/g, " "),
      }));

      // Set focus on the chat input
      document.getElementById("chatInput")?.focus();
    } catch (error) {
      console.error("Error uploading file:", error);

      // Set file input to false
      setIsFileInput(false);

      message.error("Error uploading file");
    }
  };

  return (
    <>
      {emojiPickerVisible && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "absolute",
            backgroundColor: "transparent",
            top: 0,
            left: 0,
            zIndex: 2,
          }}
          onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
        ></div>
      )}

      {loading || messageLoading ? (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 50px)",
            overflow: "hidden",
            fontSize: "20px",
          }}
        />
      ) : (
        <>
          {Object.keys(selectedChat).length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 50px)",
              }}
            >
              <img
                src={SelectChatSVG}
                alt="Select Chat"
                style={{
                  width: "30%",
                  objectFit: "contain",
                  marginBottom: "20px", // optional, for spacing between image and text
                }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                {t("chatMessages.messages.worldFullOfConversations")} 🌍💬,
                <br />
                {t("chatMessages.messages.awaitsYourSelection")} 👉👈🤖
              </p>
            </div>
          ) : (
            <div>
              {/* Chat Message Header  */}
              <div className="chatbot-message-header">
                {/* Chatbot Name and Logo */}
                <div className="header-left">
                  <div className="chatbot-logo">
                    {selectedBotName?.split(" ").length > 1
                      ? selectedBotName?.split(" ")[0][0] +
                        selectedBotName?.split(" ")[1][0]
                      : selectedBotName[0]}
                  </div>
                  <div className="header-details">
                    <div className="chatbot-name">{selectedBotName}</div>
                    <div
                      style={{
                        fontSize: "0.9em",
                        fontWeight: 600,
                        color: "#0b4eb8",
                      }}
                    >
                      Visitor #{selectedChat?.visitorId}
                    </div>
                  </div>
                </div>

                {/* Chat Date */}
                <div className="header-right">
                  {Object.keys(selectedChat).length > 0 && (
                    <ChatDate dateTime={selectedChat.chatDate} />
                  )}
                </div>
              </div>

              {/* Chatbot Messages */}
              <div
                className="chatbot-message-container"
                id="chatMessageContainer"
              >
                {messageLoading ? (
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100vh - 250px)",
                      overflow: "hidden",
                      fontSize: "20px",
                    }}
                  />
                ) : (
                  <Messages
                    selectedChat={selectedChat}
                    selectedBotName={selectedBotName}
                  />
                )}
              </div>

              {/* Input */}
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  background: "#fff",
                }}
              >
                {showAcceptHandoverButton && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "130%",
                      left: "50%",
                      transform: "translate(-50%, 50%)",
                      zIndex: 10,
                    }}
                  >
                    <Button
                      size="large"
                      className="accept-handover-button"
                      onClick={acceptHandoverFromChat}
                      loading={acceptHandoverButtonLoading}
                    >
                      Accept Handover
                    </Button>
                  </div>
                )}

                {/* Chat Input */}
                <ChatInput
                  initialContent={chatInput.message}
                  handleChatInputChange={handleChatInputChange}
                  placeholderText="Type your message here!"
                  handleInviteToChat={handleInviteToChat}
                  disabled={!isChatEnabled}
                  handleSendMessage={handleSendMessage}
                />

                {/* File Upload */}
                <input
                  type="file"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />

                {/* Chat Input Options */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "-20px",
                    border: "1px solid #ccc",
                    borderTop: "none",
                    padding: "4px 8px",
                  }}
                >
                  {/* LEFT OPTIONS */}
                  <div>
                    {/* Voice */}
                    <Button
                      type="text"
                      icon={<AudioOutlined size={15} />}
                      size="small"
                      style={{
                        borderRadius: "4px",
                        marginRight: "6px",
                      }}
                      disabled
                    />

                    {/* Upload */}
                    <Button
                      type="text"
                      icon={<PaperClipOutlined size={15} />}
                      size="small"
                      style={{ borderRadius: "4px", marginRight: "6px" }}
                      onClick={() => fileInputRef.current.click()}
                      disabled={!isChatEnabled}
                    />
                    {isFileInput && uploadedFile && uploadedFileUrl && (
                      <span>
                        {uploadedFileUrl
                          ?.substring(uploadedFileUrl.lastIndexOf("/") + 1)
                          .replace(/%20/g, " ")}
                      </span>
                    )}

                    {/* Emoji Picker */}
                    <Button
                      type="text"
                      icon={<SmileOutlined size={15} />}
                      size="small"
                      style={{
                        borderRadius: "4px",
                        marginRight: "6px",
                      }}
                      onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
                      disabled={!isChatEnabled}
                    />

                    {emojiPickerVisible && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 150,
                          zIndex: 100,
                        }}
                      >
                        <Picker
                          onEmojiClick={handleEmojiSelect}
                          disabled={!isChatEnabled}
                        />
                      </div>
                    )}
                  </div>

                  {/* RIGHT OPTIONS */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* Disable/Enable Visitor Input Switch and Label */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "20px", // Adjust spacing as needed
                      }}
                    >
                      <span style={{ marginRight: "8px" }}>Visitor Input:</span>
                      <Tooltip title="Toggle to enable or disable the visitor's ability to send messages">
                        <Switch
                          checkedChildren="Enabled"
                          unCheckedChildren="Disabled"
                          checked={isVisitorInputEnabled}
                          onChange={(checked) =>
                            handleToggleVisitorInput(
                              selectedChat.chatSessionId,
                              checked
                            )
                          }
                          defaultChecked={true}
                          disabled={!isChatEnabled}
                        />
                      </Tooltip>
                    </div>

                    {/* Leave Chat Button */}
                    <Button
                      type="danger"
                      size="small"
                      onClick={handleLeaveChat}
                      style={{ marginRight: "10px" }}
                      disabled={!isChatEnabled || !isLeaveChatEnabled}
                    >
                      Leave Chat
                    </Button>

                    {/* End Chat Button */}
                    <Button
                      type="danger"
                      size="small"
                      onClick={handleEndChat}
                      style={{ marginRight: "10px" }}
                      disabled={!isChatEnabled}
                    >
                      {t("chatMessages.messages.endChat")}
                    </Button>

                    {/* Send Button */}
                    <Button
                      type="primary"
                      size="small"
                      onClick={handleSendMessage}
                      style={{
                        background: "#0b4eb8",
                        borderColor: "#0b4eb8",
                      }}
                      disabled={!isChatEnabled}
                    >
                      {t("chatMessages.messages.send")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ChatbotMessages;
