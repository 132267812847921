import React, { useState, useEffect, useCallback } from "react";
import { Spin, Card, Row, Col, Statistic } from "antd";
import { useSelector } from "react-redux";
import WorldMap from "react-svg-worldmap";
import { useTranslation } from "react-i18next";

const VisitorDemographics = ({ loading }) => {
  const { chatbotResponsesAnswerVariables } = useSelector(
    (state) => state?.chatbot
  );
  const [mapData, setMapData] = useState([]);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [topCountries, setTopCountries] = useState([]);
  const { t } = useTranslation();

  const processData = useCallback(() => {
    if (!chatbotResponsesAnswerVariables) return;

    const countryData = {};
    let total = 0;

    chatbotResponsesAnswerVariables.forEach((item) => {
      const countryCode = item?.visitorMeta?.country_code;
      if (countryCode) {
        countryData[countryCode] = (countryData[countryCode] || 0) + 1;
        total++;
      }
    });

    const mapDataArray = Object.entries(countryData).map(
      ([country, value]) => ({ country, value })
    );
    const sortedCountries = mapDataArray.sort((a, b) => b.value - a.value);

    setMapData(mapDataArray);
    setTotalVisitors(total);
    setTopCountries(sortedCountries.slice(0, 5));
  }, [chatbotResponsesAnswerVariables]);

  useEffect(() => {
    processData();
  }, [processData]);

  return (
    <div className="visitor-demographics-container">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={18}>
          <Card title={t("metrics.visitorMap")} className="map-card">
            {loading ? (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            ) : (
              <WorldMap
                color="green"
                value-suffix="visitors"
                size="responsive"
                data={mapData}
              />
            )}
          </Card>
        </Col>
        <Col xs={24} lg={6}>
          <Card title={t("metrics.visitorStats")} className="stats-card">
            <Statistic
              title={t("metrics.totalVisitors")}
              value={totalVisitors}
              className="total-visitors"
            />
            <h3>{t("metrics.topCountries")}</h3>
            {topCountries.map((country, index) => (
              <Row key={country.country} className="country-stat">
                <Col span={16}>
                  {index + 1}. {country.country}
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  {country.value}
                </Col>
              </Row>
            ))}
          </Card>
        </Col>
      </Row>
      <style jsx>{`
        .visitor-demographics-container {
          padding: 24px;
        }
        .map-card,
        .stats-card {
          height: calc(100vh - 200px);
          overflow: auto;
        }
        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        .total-visitors {
          margin-bottom: 24px;
        }
        .country-stat {
          margin-bottom: 8px;
        }
        h3 {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      `}</style>
    </div>
  );
};

export default VisitorDemographics;
