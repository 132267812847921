import { FC, useState, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Select,
  Switch,
  Button,
  Tooltip,
  Spin,
  Upload,
  message,
  Radio,
  Divider,
  Modal,
  Card,
} from "antd";
import { shallow } from "zustand/shallow";
import { useSelector } from "react-redux";
import { PiDotsNineBold } from "react-icons/pi";
import { useParams } from "react-router";
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { HumanHandoverNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { getTeamMembers } from "@api/user/admin";
import { getAgentTeamMembers } from "@api/user/member";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const HumanHandoverNodeDataEditor: FC<HumanHandoverNode> = ({ type, id }) => {
  const { t } = useTranslation();
  const { bid } = useParams<{ bid: string }>();
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);
  const { user } = useSelector((state: any) => state.auth);

  // Essential Fields
  const [priority, setPriority] = useState(state?.data?.priority || "low");
  const [handoverMessage, setHandoverMessage] = useState(
    state?.data?.handoverMessage
  );
  const [agentWaitingMessage, setAgentWaitingMessage] = useState(
    state?.data?.agentWaitingMessage
  );

  // Optional Fields
  const [maxWaitTime, setMaxWaitTime] = useState(state?.data?.maxWaitTime || 2);
  const [fallbackMessage, setFallbackMessage] = useState(
    state?.data?.fallbackMessage
  );
  // Add assignmentType state
  const [assignmentType, setAssignmentType] = useState(
    state?.data?.assignmentType || "auto"
  );
  // Manual assignment
  const [assignedAgents, setAssignedAgents] = useState(
    state?.data?.assignedAgents || []
  );
  // Auto assignment
  // How should the incoming chat be assigned to agents? (Round-robin, least busy, etc.)
  const [agentAssignmentStrategy, setAgentAssignmentStrategy] = useState(
    state?.data?.agentAssignmentStrategy
  );

  // Handover notifications
  const [enableHandoverEmail, setEnableHandoverEmail] = useState(
    state?.data?.enableHandoverEmail
  );
  const [enableHandoverSound, setEnableHandoverSound] = useState(
    state?.data?.enableHandoverSound
  );
  // Handover sound
  const [checkedSoundTab, setCheckedSoundTab] = useState("choose"); // 'choose' or 'upload'0
  const [selectedSound, setSelectedSound] = useState("");
  const [isUploadingSound, setIsUploadingSound] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showMoreSounds, setShowMoreSounds] = useState(false);
  const [customHandoverSoundUrl, setCustomHandoverSoundUrl] = useState(
    state?.data?.customHandoverSoundUrl
  );
  const [uploadedFileName, setUploadedFileName] = useState("");

  // Tags
  const [tags, setTags] = useState(state?.data?.tags || []);

  // Pre-chat questions
  const [enablePreChatQuestions, setEnablePreChatQuestions] = useState(
    state?.data?.enablePreChatQuestions || false
  );
  const [preChatQuestions, setPreChatQuestions] = useState(
    state?.data?.preChatQuestions || []
  );

  // Post-chat survey questions
  const [enablePostChatSurveyQuestions, setEnablePostChatSurveyQuestions] =
    useState(state?.data?.enablePostChatSurveyQuestions || false);
  const [postChatSurveyQuestions, setPostChatSurveyQuestions] = useState(
    state?.data?.postChatSurveyQuestions || []
  );

  // Separate active and open states for pre-chat and post-chat questions
  const [preChatOpen, setPreChatOpen] = useState(false);
  const [preChatActive, setPreChatActive] = useState(null);
  const [postChatOpen, setPostChatOpen] = useState(false);
  const [postChatActive, setPostChatActive] = useState(null);

  // Agents
  const [agentsList, setAgentsList] = useState([]);
  const [agentFetchLoading, setAgentFetchLoading] = useState(false);

  // Tab options
  const soundTabOptions = [
    { label: "Choose", value: "choose" },
    { label: "Upload", value: "upload" },
  ];

  // set the states when component will mount
  useEffect(() => {
    setPreChatQuestions(state?.data?.preChatQuestions);
    setPostChatSurveyQuestions(state?.data?.postChatSurveyQuestions);
  }, [state]);

  // adjust chat opens
  useEffect(() => {
    setPreChatOpen(true);
  }, [preChatActive]);

  useEffect(() => {
    setPostChatOpen(true);
  }, [postChatActive]);

  // 20 default sounds
  const sounds = [
    {
      id: 1,
      name: "Tic-tok",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-1.mp3",
    },
    {
      id: 2,
      name: "Ping",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-2.mp3",
    },
    {
      id: 3,
      name: "Bell",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-3.mp3",
    },
    {
      id: 4,
      name: "Rise-up",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-4.mp3",
    },
    {
      id: 5,
      name: "Ping-Tune",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-5.mp3",
    },
    {
      id: 6,
      name: "Wrinkle",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-6.mp3",
    },
    {
      id: 7,
      name: "Frog",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-7.mp3",
    },
    {
      id: 8,
      name: "Ping Me",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-8.mp3",
    },
    {
      id: 9,
      name: "Wake-up",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-9.mp3",
    },
    {
      id: 10,
      name: "Ring-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-10.mp3",
    },
    {
      id: 11,
      name: "Wave-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-11.mp3",
    },
    {
      id: 12,
      name: "Wave-2",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-12.mp3",
    },
    {
      id: 13,
      name: "Victory",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-13.mp3",
    },
    {
      id: 14,
      name: "Blink-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-14.mp3",
    },
    {
      id: 15,
      name: "Nature",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-15.mp3",
    },
    {
      id: 16,
      name: "Door-Bell",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-16.mp3",
    },
    {
      id: 17,
      name: "Blink-2",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-17.mp3",
    },
    {
      id: 18,
      name: "Yahoo",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-18.mp3",
    },
    {
      id: 19,
      name: "Message-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-19.mp3",
    },
    {
      id: 20,
      name: "Message-2",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-20.mp3",
    },
  ];

  // If show More show all sounds else show less sounds
  const displaySounds = showMoreSounds ? sounds : sounds.slice(0, 8);

  // Fetch agents
  useEffect(() => {
    handleFetchAgents();

    // Set the initial assignment type
    setAssignmentType(state?.data?.assignmentType);

    // Set enable handover email
    setEnableHandoverEmail(state?.data?.enableHandoverEmail);

    // Set enable handover sound
    setEnableHandoverSound(state?.data?.enableHandoverSound);

    // Set checked sound tab
    setCheckedSoundTab(state?.data?.checkedSoundTab || "choose");

    // Set checked sound tab (set to "upload" only if "customHandoverSoundUrl" is present)
    if (state?.data?.checkedSoundTab === "upload") {
      // Set the uploaded file name
      const url = state?.data?.customHandoverSoundUrl;
      const fileName = url?.split("/").pop();

      setUploadedFileName(fileName);

      // Set the setCustomHandoverSoundUrl
      setCustomHandoverSoundUrl(url);
    } else if (state?.data?.checkedSoundTab === "choose") {
      // Set the selectedSound
      setSelectedSound(state?.data?.selectedSound);
    }
  }, []);

  // Handle data change
  const handleDataChange = useCallback(
    (content: any, key: string) => {
      if (!state) return;

      // Set local state
      switch (key) {
        case "priority":
          setPriority(content);
          break;
        // ** Handover Message ** //
        case "handoverMessage":
          setHandoverMessage(content);
          break;
        // ** Agent Waiting Message ** //
        case "agentWaitingMessage":
          setAgentWaitingMessage(content);
          break;
        // ** Max Wait Time ** //
        case "maxWaitTime":
          setMaxWaitTime(content);
          break;
        // ** Fallback Message ** //
        case "fallbackMessage":
          setFallbackMessage(content);
          break;
        // ** Assignment Type ** //
        case "assignmentType":
          setAssignmentType(content);
          break;
        // ** Assigned Strategy ** //
        case "agentAssignmentStrategy":
          setAgentAssignmentStrategy(content);
          break;
        // ** Enable Handover Email ** //
        case "enableHandoverEmail":
          setEnableHandoverEmail(content);
          break;
        // ** Enable Handover Sound ** //
        case "enableHandoverSound":
          setEnableHandoverSound(content);
          break;
        // ** Selected Sound ** //
        case "selectedSound":
          setSelectedSound(content);
          break;
        // ** Custom Handover Sound URL ** //
        case "customHandoverSoundUrl":
          setCustomHandoverSoundUrl(content);
          break;
        case "tags":
          setTags(content);
          break;
        case "enablePreChatQuestions":
          setEnablePreChatQuestions(content);
          break;
        case "preChatQuestions":
          setPreChatQuestions(content);
          break;
        case "enablePostChatSurveyQuestions":
          setEnablePostChatSurveyQuestions(content);
          break;
        case "postChatSurveyQuestions":
          setPostChatSurveyQuestions(content);
          break;
        default:
          break;
      }

      // Set node data
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, [key]: content },
      });
    },
    [state, type, changeNodeData]
  );

  // OnDrag Function
  const onDragEnd = (e: any) => {
    const { sourceIndex, targetIndex } = e;
    setPreChatQuestions((prevQuestions: any) => {
      const updatedCards = [...prevQuestions];
      const [movedCard] = updatedCards.splice(sourceIndex, 1);
      updatedCards.splice(targetIndex, 0, movedCard);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state.data,
          preChatQuestions: updatedCards,
        },
      });
      return updatedCards;
    });
  };

  // Toggle function for pre-chat questions
  const togglePreChatDropdown = (id: any) => {
    setPreChatOpen(!preChatOpen);
    setPreChatActive(id);
  };

  // Toggle function for post-chat questions
  const togglePostChatDropdown = (id: any) => {
    setPostChatOpen(!postChatOpen);
    setPostChatActive(id);
  };

  // ** Pre-Chat Questions ** //
  // Handle adding a new question
  const handleAddPreChatQuestion = useCallback(() => {
    // Generate a unique question id alphanumeric string
    const questionId = Math.random().toString(36).substr(2, 9);
    const newQuestion = {
      id: questionId,
      questionText: "",
      answerVariable: "",
    };
    setPreChatActive(questionId);
    setPreChatQuestions([...preChatQuestions, newQuestion]);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        preChatQuestions: [...preChatQuestions, newQuestion],
      },
    });
  }, [preChatQuestions, state, type, changeNodeData]);

  // Handle deleting a question
  const handleDeletePreChatQuestion = useCallback(
    (questionId: any) => {
      const updatedQuestions = preChatQuestions.filter(
        (question: any) => question.id !== questionId
      );

      setPreChatQuestions(updatedQuestions);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          preChatQuestions: updatedQuestions,
        },
      });
    },
    [preChatQuestions, state, type, changeNodeData]
  );

  // ** Post-Chat Survey ** //
  // Handle adding a new question
  const handleAddPostChatSurveyQuestion = useCallback(() => {
    const questionId = Math.random().toString(36).substr(2, 9);
    const newQuestion = {
      id: questionId,
      questionText: "",
      answerVariable: "",
    };

    setPostChatActive(questionId);
    setPostChatSurveyQuestions([...postChatSurveyQuestions, newQuestion]);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        postChatSurveyQuestions: [...postChatSurveyQuestions, newQuestion],
      },
    });
  }, [postChatSurveyQuestions, state, type, changeNodeData]);

  // Handle deleting a question
  const handleDeletePostChatQuestion = useCallback(
    (questionId: any) => {
      const updatedQuestions = postChatSurveyQuestions.filter(
        (question: any) => question.id !== questionId
      );

      setPostChatSurveyQuestions(updatedQuestions);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          postChatSurveyQuestions: updatedQuestions,
        },
      });
    },
    [postChatSurveyQuestions, state, type, changeNodeData]
  );

  // Handle fetch agents
  const handleFetchAgents = () => {
    setAgentFetchLoading(true);
    // Check if user is admin
    if (user?.role === "admin") {
      // Fetch team members
      getTeamMembers()
        .then((res) => {
          setAgentsList(res.data);
          setAgentFetchLoading(false);
        })
        .catch((err) => {
          setAgentFetchLoading(false);
          console.log("Error:", err);
        });
    } else if (user?.role === "agent") {
      // Fetch agent team members
      getAgentTeamMembers()
        .then((res) => {
          setAgentsList(res.data);
          setAgentFetchLoading(false);
        })
        .catch((err) => {
          setAgentFetchLoading(false);
          console.log("Error:", err);
        });
    }
  };

  // Agents options
  const options = agentsList.map((agent) => ({
    label: agent.name,
    value: agent._id,
  }));

  // Handle check agents
  const handleSetAgents = useCallback((checkedValue: any[]) => {
    setAssignedAgents(checkedValue);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        assignedAgents: checkedValue,
      },
    });
  }, []);

  // ** Handover Sound ** //
  // Handle tab change
  const handleSoundTabChange = (e: any) => {
    setCheckedSoundTab(e.target.value);

    // Update the data
    handleDataChange(e.target.value, "checkedSoundTab");
  };

  // Play Sound
  const playUploadedSound = (sound: any) => {
    // Set selected sound src
    setSelectedSound(sound.src);

    // Create sound
    const soundHowl = new Howl({
      src: [sound.src],
      html5: true,
      onend: () => setIsPlaying(false),
    });

    // Play sound
    soundHowl.play();

    // Set isPlaying to true
    setIsPlaying(true);
  };

  // Validate the file before uploading
  const beforeUploadSound = (file: any) => {
    const isAudio =
      file.type === "audio/mpeg" ||
      file.type === "audio/mp3" ||
      file.type === "audio/wav" ||
      file.type === "audio/ogg";
    if (!isAudio) {
      message.error("You can only upload MP3, WAV, or OGG audio files!");
      return Upload.LIST_IGNORE;
    }
    const isLt2MB = file.size / 1024 <= 2048;
    if (!isLt2MB) {
      message.error("Audio must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return isAudio && isLt2MB;
  };

  // Handle the custom sound file upload
  const handleUploadSound = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    formData.append("image", file); // Adjust if your API expects a different key

    setIsUploadingSound(true);
    try {
      const response = await uploadChatbotMedia(bid, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data?.url) {
        setCustomHandoverSoundUrl(response.data?.url);
        setUploadedFileName(file.name); // Set the uploaded file name
        handleDataChange(response.data?.url, "customHandoverSoundUrl");
        message.success("Handover sound uploaded successfully");
        onSuccess(response);
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      message.error("Error uploading sound");
      onError(error);
    } finally {
      setIsUploadingSound(false);
    }
  };

  // Function to play the uploaded sound
  const handlePlayUploadedSound = () => {
    const soundHowl = new Howl({
      src: [customHandoverSoundUrl],
      html5: true,
      onend: () => setIsPlaying(false),
    });

    if (isPlaying) {
      soundHowl.pause(); // Pause if currently playing
      setIsPlaying(false);
    } else {
      soundHowl.play(); // Play the sound
      setIsPlaying(true);
    }
  };

  // Function to delete the uploaded sound
  const handleDeleteUploadedSound = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this sound?",
      content: "Once deleted, you will not be able to recover this sound.",
      onOk: () => {
        setCustomHandoverSoundUrl("");
        setUploadedFileName("");
      },
    });
  };

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t("flowBuilder.sidebar.editor.specialNodes.humanHandoverNodeInfo")}
        </p>
      </Row>

      {/* ********* Essential Fields ********* */}
      {/* Priority */}
      <Row align="middle">
        <Divider orientation="left">
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.priority")}
          </span>
        </Divider>

        <Col span={24}>
          <Select
            value={priority}
            onChange={(value) => handleDataChange(value, "priority")}
            style={{ minWidth: "100%" }}
          >
            <Select.Option value="low">
              {t("flowBuilder.sidebar.editor.specialNodes.low")}
            </Select.Option>
            <Select.Option value="medium">
              {t("flowBuilder.sidebar.editor.specialNodes.medium")}
            </Select.Option>
            <Select.Option value="high">
              {t("flowBuilder.sidebar.editor.specialNodes.high")}
            </Select.Option>
          </Select>
        </Col>
      </Row>

      {/* Handover Message */}
      <Tooltip
        title={t(
          "flowBuilder.sidebar.editor.specialNodes.humanHandoverTooltip"
        )}
        placement="top"
      >
        <Row
          align="middle"
          style={{
            marginTop: "15px",
          }}
        >
          <Divider orientation="left">
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("flowBuilder.sidebar.editor.specialNodes.handoverMessage")}
            </span>
          </Divider>
          <Col span={24}>
            <div>
              <QuillEditor
                initialContent={handoverMessage}
                onEditorChange={(content) => {
                  setHandoverMessage(content);
                  handleDataChange(content, "handoverMessage");
                }}
                placeholderText={t(
                  "flowBuilder.sidebar.editor.specialNodes.handoverMessagePH"
                )}
              />
            </div>
          </Col>
        </Row>
      </Tooltip>

      {/* Agent Waiting Message */}
      <Tooltip
        title={t(
          "flowBuilder.sidebar.editor.specialNodes.agentWaitingMessageTooltip"
        )}
        placement="top"
      >
        <Row align="middle">
          <Divider orientation="left">
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("flowBuilder.sidebar.editor.specialNodes.agentWaitingMessage")}
            </span>
          </Divider>

          <Col span={24}>
            <div>
              <QuillEditor
                initialContent={agentWaitingMessage}
                onEditorChange={(content) => {
                  setAgentWaitingMessage(content);
                  handleDataChange(content, "agentWaitingMessage");
                }}
                placeholderText={t(
                  "flowBuilder.sidebar.editor.specialNodes.agentWaitingMessagePH"
                )}
              />
            </div>
          </Col>
        </Row>
      </Tooltip>

      {/* Max Wait Time */}
      <Tooltip
        title={t("flowBuilder.sidebar.editor.specialNodes.maxWaitTimeTooltip")}
        placement="top"
      >
        <Row align="middle">
          <Divider orientation="left">
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("flowBuilder.sidebar.editor.specialNodes.maxWaitTime")}
            </span>
          </Divider>

          <Col span={24}>
            <Input
              value={maxWaitTime}
              type="number"
              min={0}
              max={20}
              onChange={(e) => handleDataChange(e.target.value, "maxWaitTime")}
            />
          </Col>
        </Row>
      </Tooltip>

      {/* Fallback Message */}
      <Tooltip
        title={t(
          "flowBuilder.sidebar.editor.specialNodes.fallbackMessageTooltip"
        )}
        placement="top"
      >
        <Row
          align="middle"
          style={{
            marginTop: "15px",
          }}
        >
          <Divider orientation="left">
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("flowBuilder.sidebar.editor.specialNodes.fallbackMessage")}
            </span>
          </Divider>

          <Col span={24}>
            <div>
              <QuillEditor
                initialContent={fallbackMessage}
                onEditorChange={(content) => {
                  setFallbackMessage(content);
                  handleDataChange(content, "fallbackMessage");
                }}
                placeholderText={t(
                  "flowBuilder.sidebar.editor.specialNodes.fallbackMessagePH"
                )}
              />
            </div>
          </Col>
        </Row>
      </Tooltip>

      <Row align="middle" style={{ marginBottom: "15px" }}>
        <Divider orientation="left">
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.assignmentType")}
          </span>
        </Divider>

        <Row>
          <p
            style={{
              fontSize: "13px",
              fontStyle: "italic",
              fontWeight: 500,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.assignmentTypeInfo")}
            <br />
            <b>{t("flowBuilder.sidebar.editor.specialNodes.titleAuto")}</b>
            {t("flowBuilder.sidebar.editor.specialNodes.titleAutoInfo")} <br />
            <b>{t("flowBuilder.sidebar.editor.specialNodes.titleManual")}</b>
            {t("flowBuilder.sidebar.editor.specialNodes.titleManualInfo")}
          </p>
        </Row>
        <Col span={24} style={{ marginTop: "10px" }}>
          <Switch
            checked={assignmentType === "auto"}
            onChange={(checked) => {
              const newType = checked ? "auto" : "manual";
              setAssignmentType(newType);
              handleDataChange(newType, "assignmentType");
            }}
            checkedChildren="Auto"
            unCheckedChildren="Manual"
          />
        </Col>
      </Row>

      {/* Select agent */}
      {assignmentType === "manual" && (
        <Row
          align="middle"
          style={{
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          {/* Tooltip saying that, if set, then the agents would be assigned in the order added */}
          <Tooltip
            title={t(
              "flowBuilder.sidebar.editor.specialNodes.selectAgentSetTooltip"
            )}
            placement="top"
          >
            <Col span={24} className="sidebar__heading">
              {t("flowBuilder.sidebar.editor.specialNodes.selectAgents")}
            </Col>
            {agentFetchLoading ? (
              <Spin style={{ marginTop: "10px" }} />
            ) : (
              <Col span={24} style={{ marginTop: "10px" }}>
                <Select
                  mode="multiple"
                  style={{
                    minWidth: "100%",
                    borderRadius: "4px",
                    width: "370px",
                    maxWidth: "370px",
                  }}
                  placeholder="Please select"
                  value={assignedAgents}
                  onChange={(value: any) => handleSetAgents(value)}
                >
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
          </Tooltip>
        </Row>
      )}

      {/* Agent Assignment Strategy */}
      {assignmentType === "auto" && (
        <Row
          align="middle"
          style={{
            marginTop: "15px",
          }}
        >
          <Col span={24} className="sidebar__heading">
            {t(
              "flowBuilder.sidebar.editor.specialNodes.agentAssignmentStrategy"
            )}
          </Col>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Select
              value={agentAssignmentStrategy}
              onChange={(value) =>
                handleDataChange(value, "agentAssignmentStrategy")
              }
              style={{ minWidth: "100%" }}
            >
              <Select.Option value="leastBusy">
                {t("flowBuilder.sidebar.editor.specialNodes.leastBusy")}
              </Select.Option>
              <Select.Option value="roundRobin">
                {t("flowBuilder.sidebar.editor.specialNodes.roundRobin")}
              </Select.Option>
            </Select>
          </Col>
        </Row>
      )}

      {/* Enable Handover Email */}
      <Row
        align="middle"
        style={{
          marginTop: "15px",
        }}
      >
        <Tooltip
          title={t(
            "flowBuilder.sidebar.editor.specialNodes.enableHandoverEmailToolTip"
          )}
          placement="top"
        >
          <Row justify="space-between" align="middle" style={{ width: "100%" }}>
            <Col span={20} className="sidebar__heading">
              {t("flowBuilder.sidebar.editor.specialNodes.enableHandoverEmail")}
            </Col>
            <Col span={4}>
              <Switch
                checked={enableHandoverEmail}
                onChange={(checked) =>
                  handleDataChange(checked, "enableHandoverEmail")
                }
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            </Col>
          </Row>
        </Tooltip>
      </Row>

      {/* Enable Handover Sound */}
      <Row
        align="middle"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Tooltip
          title={t(
            "flowBuilder.sidebar.editor.specialNodes.enableHandoverSoundTooltip"
          )}
          placement="top"
        >
          <Row justify="space-between" align="middle" style={{ width: "100%" }}>
            <Col span={20} className="sidebar__heading">
              {t("flowBuilder.sidebar.editor.specialNodes.enableHandoverSound")}
            </Col>
            <Col span={4} style={{ marginTop: "10px" }}>
              <Switch
                checked={enableHandoverSound}
                onChange={(checked) =>
                  handleDataChange(checked, "enableHandoverSound")
                }
                checkedChildren="On"
                unCheckedChildren="Off"
                style={{ marginBottom: "10px" }}
              />
            </Col>
          </Row>
        </Tooltip>
      </Row>
      {/* Sound Tabs */}
      {enableHandoverSound && (
        <>
          {/* Custom Handover Sounds  */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Radio.Group
              options={soundTabOptions}
              onChange={handleSoundTabChange}
              value={checkedSoundTab}
              optionType="button"
              buttonStyle="solid"
            />
          </div>

          {/* Default sounds */}
          {checkedSoundTab === "choose" && (
            <div
              style={{
                padding: "10px 5px",
                backgroundColor: "#d5d7e4",
                marginTop: "20px",
                border: "1px solid lightGray",
                borderRadius: "10px",
              }}
            >
              <div className="widgetSounds_Wrapper">
                {displaySounds?.map((sound, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="widget_sound"
                        style={{
                          background: "#0100EC",
                          border:
                            selectedSound === sound.src && "3px solid #fff",
                          transform:
                            selectedSound === sound.src
                              ? "scale(1.2)"
                              : "scale(1)",
                          overflow: "hidden",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          playUploadedSound(sound);

                          // Set the selected sound
                          setSelectedSound(sound.src);

                          // Update the data
                          handleDataChange(sound.src, "selectedSound");
                        }}
                      >
                        {selectedSound === sound.src && isPlaying ? (
                          <PauseCircleFilled
                            style={{
                              fontSize: "30px",
                              color: "#fff",
                              marginTop: selectedSound !== sound.src && "2px",
                            }}
                          />
                        ) : (
                          <PlayCircleFilled
                            style={{
                              fontSize: "30px",
                              color: "#fff",
                              marginTop: selectedSound !== sound.src && "2px",
                            }}
                            size={100}
                          />
                        )}
                      </div>
                      <span
                        style={{
                          marginTop: "5px",
                          fontWeight: "600",
                        }}
                        className="ellipsis-text"
                      >
                        {sound.name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <span
                onClick={() => setShowMoreSounds(!showMoreSounds)}
                className="showMore"
                style={{
                  display: "block",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {showMoreSounds ? "Show Less" : "Show More"}
              </span>
            </div>
          )}

          {/* Custom Sound */}
          {checkedSoundTab === "upload" && (
            <Row align="middle" justify="center" style={{ marginTop: "15px" }}>
              <Col
                span={24}
                style={{ textAlign: "center", marginBottom: "10px" }}
              >
                <Card
                  style={{
                    backgroundColor: "#f0f2f5",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  {uploadedFileName ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          color: "#0100EC",
                          fontWeight: "600",
                          textDecoration: "underline",
                        }}
                      >
                        {uploadedFileName}
                      </div>
                      <Button
                        icon={<PlayCircleOutlined />}
                        onClick={handlePlayUploadedSound}
                        size="small"
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#0100EC",
                          color: "white",
                          border: "none",
                        }}
                      >
                        {isPlaying ? "Pause" : "Play"}
                      </Button>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={handleDeleteUploadedSound}
                        size="small"
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                        }}
                      >
                        {t("flowBuilder.sidebar.editor.specialNodes.delete")}
                      </Button>
                    </>
                  ) : (
                    <Upload
                      name="handoverSound"
                      beforeUpload={beforeUploadSound}
                      customRequest={handleUploadSound}
                      showUploadList={false}
                      accept=".mp3,audio/*"
                    >
                      <Button
                        icon={<UploadOutlined />}
                        loading={isUploadingSound}
                      >
                        {t(
                          "flowBuilder.sidebar.editor.specialNodes.uploadSound"
                        )}
                      </Button>
                    </Upload>
                  )}
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}

      {/* Tags */}
      <Row
        align="middle"
        style={{
          marginTop: "15px",
        }}
      >
        <Divider orientation="left">
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.tags")}
          </span>
        </Divider>

        <Col span={24} style={{ marginTop: "10px" }}>
          <Select
            mode="tags"
            style={{ minWidth: "100%", borderRadius: "4px" }}
            placeholder={t("flowBuilder.sidebar.editor.specialNodes.tagsPH")}
            value={tags}
            onChange={(value: any) => handleDataChange(value, "tags")}
          ></Select>
        </Col>
      </Row>

      {/* Horizontal line separator */}
      <Row
        style={{
          marginTop: "25px",
        }}
      >
        <Col span={24}>
          <hr />
        </Col>
      </Row>

      {/* ********* Pre-Chat Survey ********* */}
      <Row>
        <p
          style={{
            fontSize: "13px",
            fontStyle: "italic",
            fontWeight: 500,
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          {t("flowBuilder.sidebar.editor.specialNodes.preChatSurveyTitle")}
        </p>
      </Row>

      {/* Enable Pre-chat Questions */}
      <Row
        style={{
          marginTop: "20px",
        }}
      >
        <Col span={20} className="sidebar__heading">
          {t("flowBuilder.sidebar.editor.specialNodes.enablePreChat")}
        </Col>
        <Col span={4}>
          <Switch
            checked={enablePreChatQuestions}
            onChange={(value) =>
              handleDataChange(value, "enablePreChatQuestions")
            }
          />
        </Col>
      </Row>

      {/* Pre-chat Questions */}
      {enablePreChatQuestions && (
        <>
          <Col
            span={24}
            className="drag_multi_options_container"
            style={{
              alignSelf: "start",
              marginBottom: "15px",
              marginTop: "25px",
            }}
          >
            {preChatQuestions.map((question: any, index: any) => (
              <>
                <Col
                  style={{}}
                  className={`drag_multi_options_inner ${
                    preChatOpen && preChatActive === question?.id ? "open" : ""
                  }`}
                >
                  <div
                    className="drag_dropdown_button"
                    onClick={() => togglePreChatDropdown(question?.id)}
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", index.toString())
                    }
                    onDrop={(e) => {
                      e.preventDefault();
                      onDragEnd({
                        sourceIndex: Number(
                          e.dataTransfer.getData("text/plain")
                        ),
                        targetIndex: index,
                      });
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    style={{
                      position: "relative",
                      border:
                        preChatOpen && preChatActive === question?.id
                          ? "2px solid #fff"
                          : "2px solid transparent",
                      boxShadow:
                        preChatOpen &&
                        preChatActive === question?.id &&
                        "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <Col>
                      <div
                        style={{
                          left: "10px",
                        }}
                      >
                        {t(
                          "flowBuilder.sidebar.editor.specialNodes.preChatQuestion"
                        )}{" "}
                        {index + 1}
                      </div>
                    </Col>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        type="text"
                        icon={<PiDotsNineBold />}
                        size={"small"}
                        className="drag_button"
                      />
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        size={"small"}
                        style={{ borderRadius: "4px" }}
                        onClick={() => handleDeletePreChatQuestion(question.id)}
                        className="drag_dlt_button"
                      />
                    </div>
                  </div>

                  {preChatOpen && preChatActive === question?.id && (
                    <div className="drag_isOpen_container">
                      <Row>
                        <Col span={24}>
                          <QuillEditor
                            initialContent={question.questionText}
                            onEditorChange={(content) => {
                              // Find the index of the matching question in the questions array
                              const questionIndex = preChatQuestions.findIndex(
                                (q: any) => q.id === question?.id
                              );

                              // Copy the question and modify the questionText
                              const updatedQuestion = {
                                ...preChatQuestions[questionIndex],
                                questionText: content,
                              };

                              // Copy the questions array and replace the question at the found index
                              const updatedQuestions = [...preChatQuestions];
                              updatedQuestions[questionIndex] = updatedQuestion;

                              // Save the updated questions array back to the state
                              setPreChatQuestions(updatedQuestions);
                              changeNodeData({
                                ...state,
                                type,
                                data: {
                                  ...state.data,
                                  preChatQuestions: updatedQuestions,
                                },
                              });
                            }}
                            placeholderText="Type question here!"
                          />
                          <SaveAnswer
                            id={id}
                            type={type}
                            variableName={question.answerVariable}
                            preChatQuestionId={question?.id}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </>
            ))}

            <Col span={24}>
              <Button
                onClick={handleAddPreChatQuestion}
                block
                type="primary"
                icon={<PlusOutlined />}
                style={{ borderRadius: "4px" }}
              >
                {t("flowBuilder.sidebar.editor.specialNodes.addQuestion")}
              </Button>
            </Col>
          </Col>
        </>
      )}

      {/* Horizontal line separator */}
      <Row
        style={{
          marginTop: "25px",
        }}
      >
        <Col span={24}>
          <hr />
        </Col>
      </Row>

      {/* ********* Post-Chat Survey ********* */}
      <Row>
        <p
          style={{
            fontSize: "13px",
            fontStyle: "italic",
            fontWeight: 500,
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          {t("flowBuilder.sidebar.editor.specialNodes.postChatSurveyTitle")}
        </p>
      </Row>

      {/* Enable Post-chat Survey */}
      <Row
        style={{
          marginTop: "20px",
        }}
      >
        <Col span={20} className="sidebar__heading">
          {t("flowBuilder.sidebar.editor.specialNodes.enablePostChat")}
        </Col>
        <Col span={4}>
          <Switch
            checked={enablePostChatSurveyQuestions}
            onChange={(value) =>
              handleDataChange(value, "enablePostChatSurveyQuestions")
            }
          />
        </Col>
      </Row>

      {/* Post-chat Survey */}
      {enablePostChatSurveyQuestions && (
        <>
          <Col
            span={24}
            className="drag_multi_options_container"
            style={{
              alignSelf: "start",
              marginBottom: "15px",
              marginTop: "25px",
            }}
          >
            {postChatSurveyQuestions.map((question: any, index: any) => (
              <>
                <Col
                  style={{}}
                  className={`drag_multi_options_inner ${
                    postChatOpen && postChatActive === question?.id
                      ? "open"
                      : ""
                  }`}
                >
                  <div
                    className="drag_dropdown_button"
                    onClick={() => togglePostChatDropdown(question?.id)}
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", index.toString())
                    }
                    onDrop={(e) => {
                      e.preventDefault();
                      onDragEnd({
                        sourceIndex: Number(
                          e.dataTransfer.getData("text/plain")
                        ),
                        targetIndex: index,
                      });
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    style={{
                      position: "relative",
                      border:
                        postChatOpen && postChatActive === question?.id
                          ? "2px solid #fff"
                          : "2px solid transparent",
                      boxShadow:
                        postChatOpen &&
                        postChatActive === question?.id &&
                        "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <Col>
                      <div
                        style={{
                          left: "10px",
                        }}
                      >
                        {t(
                          "flowBuilder.sidebar.editor.specialNodes.postChatSurveyQuestion"
                        )}{" "}
                        {index + 1}
                      </div>
                    </Col>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        type="text"
                        icon={<PiDotsNineBold />}
                        size={"small"}
                        className="drag_button"
                      />
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        size={"small"}
                        style={{ borderRadius: "4px" }}
                        onClick={() =>
                          handleDeletePostChatQuestion(question?.id)
                        }
                        className="drag_dlt_button"
                      />
                    </div>
                  </div>

                  {postChatOpen && postChatActive === question?.id && (
                    <div className="drag_isOpen_container">
                      <Row>
                        <Col span={24}>
                          <QuillEditor
                            initialContent={question.questionText}
                            onEditorChange={(content) => {
                              // Find the index of the matching question in the questions array
                              const questionIndex =
                                postChatSurveyQuestions.findIndex(
                                  (q: any) => q.id === question?.id
                                );

                              // Copy the question and modify the questionText
                              const updatedQuestion = {
                                ...postChatSurveyQuestions[questionIndex],
                                questionText: content,
                              };

                              // Copy the questions array and replace the question at the found index
                              const updatedQuestions = [
                                ...postChatSurveyQuestions,
                              ];
                              updatedQuestions[questionIndex] = updatedQuestion;

                              // Save the updated questions array back to the state
                              setPostChatSurveyQuestions(updatedQuestions);
                              changeNodeData({
                                ...state,
                                type,
                                data: {
                                  ...state.data,
                                  postChatSurveyQuestions: updatedQuestions,
                                },
                              });
                            }}
                            placeholderText="Type question here!"
                          />
                          <SaveAnswer
                            id={id}
                            type={type}
                            variableName={question.answerVariable}
                            postChatSurveyQuestionId={question?.id}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </>
            ))}

            <Col span={24}>
              <Button
                onClick={handleAddPostChatSurveyQuestion}
                block
                type="primary"
                icon={<PlusOutlined />}
                style={{ borderRadius: "4px" }}
              >
                {t("flowBuilder.sidebar.editor.specialNodes.addQuestion")}
              </Button>
            </Col>
          </Col>
        </>
      )}
    </>
  );
};

export default HumanHandoverNodeDataEditor;
