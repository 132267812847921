import React, { FC, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { NChoicesNodeData } from "../../../typings";
import useStore from "../../../../../store/store";
import ContextMenu from "../../../../components/contextMenu/ContextMenu";
import ChoiceNode from "./choice";
import { Choices } from "@assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const NChoicesNode: FC<NodeProps<NChoicesNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);

  const { getNode } = useStore((state: any) => ({
    getNode: state?.getNode,
  }));
  const { t } = useTranslation();
  const node = getNode(id);

  useEffect(() => {
    const getNodeId = node?.id;
    setNodeId(getNodeId);
  }, []);

  if (!node) {
    return null; // or some fallback component
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />

      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "230px",
          maxWidth: "230px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={16}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Col
              span={18}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={Choices}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="Choices"
              />
              {t("flowBuilder.builderNodes.nChoicesNode")}
            </Col>

            {/* Delete & Duplicate Node */}
            <Col span={6}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          <Col span={24} className="hp-text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: node?.data?.choicePrompt,
              }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {t("flowBuilder.builderNodes.choices")}
          </Col>

          {/* Add choices based on the range of count */}
          {node?.data?.choices &&
            node?.data?.choices?.map((choice: any, index: any) => (
              <React.Fragment key={index}>
                <ChoiceNode id={choice?.id} index={index} choiceData={choice} />
              </React.Fragment>
            ))}
        </div>
      </Col>
    </>
  );
};

export default NChoicesNode;
