import { FC, useState, useCallback } from "react";
import { Row, Col, Input, Tooltip, Divider, Switch } from "antd";
import { shallow } from "zustand/shallow";
import { RedirectNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { useTranslation } from "react-i18next";

const RedirectNodeDataEditor: FC<RedirectNode> = ({ id, type }) => {
  const { t } = useTranslation();
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [redirectPrompt, setRedirectPrompt] = useState(
    state?.data?.redirectPrompt
  );
  const [redirectToLink, setRedirectToLink] = useState(state?.data?.link);
  const [openInNewTab, setOpenInNewTab] = useState(
    state?.data?.openInNewTab ?? true
  );

  // Handle redirect prompt change
  const handleRedirectPromptChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the Rating Prompt
      setRedirectPrompt(content);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, redirectPrompt: content },
      });
    },
    [state, type, changeNodeData]
  );

  // // Handle redirect to link change
  const handleRedirectToLinkChange = useCallback(
    (e: any) => {
      if (!state) return;

      // Set the Rating Prompt
      setRedirectToLink(e.target.value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, link: e.target.value },
      });
    },
    [state, type, changeNodeData]
  );

  const handleOpenInNewTabChange = useCallback(
    (checked: any) => {
      if (!state) return;

      setOpenInNewTab(checked); // Update local state

      // Update node data in the store
      changeNodeData({
        ...state,
        type,
        data: { ...state.data, openInNewTab: checked },
      });
    },
    [state, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.redirectNodeInfo")}
          </p>
        </Row>

        {/* Question Text */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.questionText")}
          </Col>
        </Divider>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={redirectPrompt}
              onEditorChange={handleRedirectPromptChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.specialNodes.questionTextPH"
              )}
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "20px",
            width: "100%",
          }}
        />

        <Divider orientation="left">
          <Col span={4} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.link")}
          </Col>
        </Divider>
        <Col span={20}>
          <Tooltip
            placement="top"
            title={t("flowBuilder.sidebar.editor.specialNodes.linkTT")}
          >
            <Input.TextArea
              value={redirectToLink}
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={(e) => handleRedirectToLinkChange(e)}
              style={{ borderRadius: "4px" }}
            />
          </Tooltip>
        </Col>

        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.openInNewTab")}
          </Col>
        </Divider>
        <Col span={24}>
          <Switch
            checkedChildren={t("flowBuilder.sidebar.editor.specialNodes.yes")}
            unCheckedChildren={t("flowBuilder.sidebar.editor.specialNodes.no")}
            checked={openInNewTab}
            onChange={handleOpenInNewTabChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default RedirectNodeDataEditor;
