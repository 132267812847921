import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { MessageNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
// translation
import { useTranslation } from "react-i18next";

const MessageNodeDataEditor: FC<MessageNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [botMessage, setBotMessage] = useState(state?.data?.text);

  const { t } = useTranslation();

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setBotMessage(state?.data?.text);
  }, [id]);

  const handleBotMessageChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the welcome message
      setBotMessage(content);

      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, text: content },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.sendResponseNodes.messageNodeInfo")}
          </p>
        </Row>

        {/* User Prompt */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.sendResponseNodes.message")}
          </Col>
        </Divider>

        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={botMessage}
              onEditorChange={handleBotMessageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MessageNodeDataEditor;
