import { Button } from "antd";
import { MdClose } from "react-icons/md";
import { AnnouncementBannerOptions } from "./subOptions";
import { useTranslation } from "react-i18next";

const AnnouncementBanner = ({ setActiveOption }) => {
  const handleCloseButtonClick = () => {
    setActiveOption(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="option-content">
        <div className="content-header">
          <h5 style={{ textTransform: "uppercase" }}>Announcement Banner</h5>
          <Button
            type="text"
            icon={<MdClose size={20} />}
            onClick={handleCloseButtonClick}
            size="small"
          />
        </div>

        <div className="general-body">
          {/* Announcement Banner Options */}
          <AnnouncementBannerOptions />
        </div>
      </div>
    </>
  );
};

export default AnnouncementBanner;
