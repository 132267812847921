import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import { shallow } from "zustand/shallow";
import { NChoicesNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { PiDotsNineBold } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const NChoicesNodeDataEditor: FC<NChoicesNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [choicePrompt, setChoicePrompt] = useState(state?.data?.choicePrompt);
  const [choices, setChoices] = useState(state?.data?.choices || []);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  const updateTimeout = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }
    };
  }, []);

  // update the choices state
  useEffect(() => {
    setChoices(state?.data?.choices);
    setAnswerVariable(state?.data?.answerVariable);
  }, [state]);

  // Handle choicePrompt change
  const handleChoicePromptChange = useCallback(
    (content: any) => {
      if (!state) return;
      setChoicePrompt(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          choicePrompt: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle adding a new choice
  const handleAddChoice = useCallback(() => {
    const choiceId = uuidv4();
    const newChoice = {
      id: choiceId,
      choiceText: "",
      answerVariable: "",
    };
    setActive(choiceId);
    setChoices([...choices, newChoice]);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        choices: [...choices, newChoice],
      },
    });
  }, [choices, state, type, changeNodeData]);

  // Handle deleting a choice
  const handleDeleteChoice = useCallback(
    (choiceId: any) => {
      const updatedChoices = choices.filter(
        (choice: any) => choice.id !== choiceId
      );

      setChoices(updatedChoices);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          choices: updatedChoices,
        },
      });
    },
    [choices, state, type, changeNodeData]
  );
  // OnDrag Function
  const onDragEnd = (e: any) => {
    const { sourceIndex, targetIndex } = e;
    setChoices((prevQuestions: any) => {
      const updatedCards = [...prevQuestions];
      const [movedCard] = updatedCards.splice(sourceIndex, 1);
      updatedCards.splice(targetIndex, 0, movedCard);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state.data,
          choices: updatedCards,
        },
      });
      return updatedCards;
    });
  };

  // Open and Hide toggle Function
  const toggleDropdown = (id: any) => {
    setIsOpen(!isOpen);
    setActive(id);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [active]);

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t("flowBuilder.sidebar.editor.flowOperationsNodes.nChoicesNodeInfo")}
        </p>
      </Row>

      <div
        style={{
          height: "10px",
          width: "100%",
        }}
      />

      <Row>
        <Divider orientation="left">
          <Col
            span={24}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.questionText")}
          </Col>
        </Divider>

        <Col span={24} style={{ width: "100%" }}>
          <div>
            <QuillEditor
              initialContent={choicePrompt}
              onEditorChange={handleChoicePromptChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.flowOperationsNodes.questionTextPH"
              )}
            />
          </div>
        </Col>

        <Col
          span={24}
          className="drag_multi_options_container"
          style={{ marginBottom: "15px" }}
        >
          {choices.map((choice: any, index: any) => (
            <>
              <Col
                className={`drag_multi_options_inner ${
                  isOpen && active === choice.id ? "open" : ""
                }`}
              >
                <div
                  className="drag_dropdown_button"
                  onClick={() => toggleDropdown(choice?.id)}
                  draggable
                  onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", index.toString())
                  }
                  onDrop={(e) => {
                    e.preventDefault();
                    onDragEnd({
                      sourceIndex: Number(e.dataTransfer.getData("text/plain")),
                      targetIndex: index,
                    });
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    position: "relative",
                    border:
                      isOpen && active === choice.id
                        ? "2px solid #fff"
                        : "2px solid transparent",
                    boxShadow:
                      isOpen &&
                      active === choice.id &&
                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        left: "10px",
                      }}
                    >
                      {t(
                        "flowBuilder.sidebar.editor.flowOperationsNodes.choice"
                      )}{" "}
                      {index + 1}
                    </div>
                  </Col>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      type="text"
                      icon={<PiDotsNineBold />}
                      size={"small"}
                      className="drag_button"
                    />
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      size={"small"}
                      style={{ borderRadius: "4px" }}
                      onClick={() => handleDeleteChoice(choice.id)}
                      className="drag_dlt_button"
                    />
                  </div>
                </div>
                {isOpen && active === choice.id && (
                  <div className="drag_isOpen_container">
                    <Row>
                      <Col span={24}>
                        <QuillEditor
                          initialContent={choice.choiceText}
                          onEditorChange={(content) => {
                            // Find the index of the matching choice in the choices array
                            const choiceIndex = choices.findIndex(
                              (c: any) => c.id === choice.id
                            );

                            // Copy the choice and modify the choiceText
                            const updatedChoice = {
                              ...choices[choiceIndex],
                              choiceText: content,
                            };

                            // Copy the choices array and replace the choice at the found index
                            const updatedChoices = [...choices];
                            updatedChoices[choiceIndex] = updatedChoice;

                            // Save the updated choices array back to the state
                            setChoices(updatedChoices);
                            changeNodeData({
                              ...state,
                              type,
                              data: { ...state.data, choices: updatedChoices },
                            });
                          }}
                          placeholderText={t(
                            "flowBuilder.sidebar.editor.flowOperationsNodes.choiceTextPH"
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </>
          ))}

          <Col span={24}>
            <Button
              onClick={handleAddChoice}
              block
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("flowBuilder.sidebar.editor.flowOperationsNodes.addChoice")}
            </Button>
          </Col>
        </Col>
      </Row>

      <SaveAnswer id={id} type={type} variableName={answerVariable} />
    </>
  );
};

export default NChoicesNodeDataEditor;
