import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Slider, Spin, Switch, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import Avatar from "@assets/images/pages/customize/cat.png";
import DeleteIcon from "@assets/images/pages/customize/bin.png";
import Arrow from "@assets/images/pages/customize/down.png";
import { useTranslation } from "react-i18next";

const ChatAvatar = () => {
  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});

  const chatAvatarRef = useRef(null);
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideChatAvatar, setHideChatAvatar] = useState(false);

  // Spinner indicator
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 35,
      }}
      spin
    />
  );

  const toggleShowAvatarOptions = () => {
    setVisible(!visible);
  };

  const handleChangeAvatar = async (event) => {
    const file = event.target.files[0];
    const fmData = new FormData();

    // Don't allow size greater than 3MB
    if (file.size > 3 * 1024 * 1024) {
      message.error("Image/GIF size must be less than 3MB");
      return;
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload image
    try {
      setLoading(true);
      await uploadChatbotMedia(bid, fmData, config)
        .then((res) => {
          dispatch(
            updateCurrentChatbotCustomizationsAction(
              bid,
              "avatar",
              res.data.url
            )
          );
          setLoading(false);
          message.success("Chat avatar updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Error updating chat avatar");
        });
    } catch (error) {
      setLoading(false);
      message.error("Error updating chat avatar");
    }
  };

  const handleHideChatAvatar = () => {
    setHideChatAvatar(!hideChatAvatar);

    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "hideAvatar",
        !hideChatAvatar
      )
    );
  };
  return (
    <>
      <div className="dropdown-menu">
        <div onClick={toggleShowAvatarOptions} className="dropdown-trigger">
          <span>
            <img
              src={Avatar}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="avatar"
            />
            {t("customize.chat.avatar")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            alt="arrow"
          />
        </div>
        {visible && (
          <>
            <div
              style={{
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
                color: "#45496e",
              }}
            >
              {/* Chat Avatar Image */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: customizations?.hideAvatar === true && "15px",
                }}
              >
                <span>{t("customize.chat.hideChatAvatar")}</span>
                <Switch
                  checked={customizations?.hideAvatar}
                  size="small"
                  onClick={handleHideChatAvatar}
                />
              </div>
              {!customizations?.hideAvatar && (
                <>
                  <div className="avatar-uploadImg">
                    <label
                      htmlFor="file-upload"
                      style={{
                        padding: "6px 10px 0px 10px",
                        backgroundColor: "rgba(138,155,168,.15)",
                        width: "100%",
                        borderRadius: "6px",
                      }}
                    >
                      <button
                        className="general-input-btn"
                        htmlFor="file-upload"
                      >
                        {t("customize.chat.uploadImage/GIF")}
                        <input
                          id="file-upload"
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => handleChangeAvatar(e)}
                          ref={chatAvatarRef}
                        />
                      </button>
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <Spin indicator={antIcon} size="large" />
                      </div>
                    ) : (
                      <>
                        {customizations?.avatar !== "" && (
                          <>
                            <img
                              src={customizations?.avatar}
                              style={{ height: "80px", width: "80px" }}
                              alt="avatar"
                            />
                            <img
                              onClick={() => {
                                dispatch(
                                  updateCurrentChatbotCustomizationsAction(
                                    bid,
                                    "avatar",
                                    ""
                                  )
                                );

                                // Reset the file input
                                if (chatAvatarRef.current) {
                                  chatAvatarRef.current.value = "";
                                }

                                message.success("Avatar deleted successfully");
                              }}
                              src={DeleteIcon}
                              style={{
                                marginTop: "-30px",
                                cursor: "pointer",
                              }}
                              alt="delete-icon"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <span style={{ display: "block", marginBottom: "10px" }}>
                    {t("customize.chat.scale")}
                  </span>
                  <div style={{ marginTop: "15px" }}>
                    <Slider
                      value={customizations?.avatarSize}
                      style={{ marginBottom: "18px" }}
                      onChange={(value) =>
                        dispatch(
                          updateCurrentChatbotCustomizationsAction(
                            bid,
                            "avatarSize",
                            value
                          )
                        )
                      }
                      min={20}
                      max={50}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ChatAvatar;
