import { Menu, Dropdown, Col, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { User, Logout, Discount } from "react-iconly";
import { logoutAction } from "../../../redux/actions/authActions";
import cardBg2 from "../../../assets/images/dashboard/ecommerce-card-bg.png";
import { useTranslation } from "react-i18next";

const HeaderUser = ({ placement }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  // Handle logout
  const handleLogout = () => {
    // Dispatch logout action
    dispatch(logoutAction());
  };

  const LanguageIcon = () => (
    <div style={{ marginRight: "8px", marginTop: "3px" }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M4 21V8a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H8z" />
          <path d="M10 14v-4a2 2 0 1 1 4 0v4m0-2h-4" />
        </g>
      </svg>
    </div>
  );

  const menu = (
    <Menu theme={"light"} style={{ marginBottom: "30px", marginLeft: "10px" }}>
      <Menu.Item
        key={0}
        icon={
          <User
            set="curved"
            className="remix-icon hp-vertical-align-middle hp-text-color-dark-0"
            size={16}
          />
        }
        className="hp-text-color-dark-0"
      >
        <Link to="/profile/information">
          {t("navigation.footer.myProfile")}
        </Link>
      </Menu.Item>

      {/* My Subscription */}
      {user?.role === "admin" && (
        <Menu.Item
          key={1}
          icon={
            <Discount
              set="curved"
              className="remix-icon hp-vertical-align-middle hp-text-color-dark-0"
              size={16}
            />
          }
          className="hp-text-color-dark-0"
        >
          <Link to="/profile/subscription">
            {t("navigation.footer.mySubscription")}
          </Link>
        </Menu.Item>
      )}

      {/* Language */}
      {user?.role === "admin" && (
        <Menu.Item
          key={2}
          icon={<LanguageIcon />}
          className="hp-text-color-dark-0"
        >
          <Link to="/profile/language">{t("navigation.footer.language")}</Link>
        </Menu.Item>
      )}

      <Menu.Item
        key={3}
        icon={
          <Logout
            set="curved"
            className="remix-icon hp-vertical-align-middle hp-text-color-dark-0"
            size={16}
          />
        }
        className="hp-text-color-dark-0"
        onClick={handleLogout}
      >
        {t("navigation.footer.logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} placement={placement}>
        <Col className="hp-d-flex-center" onClick={(e) => e.preventDefault()}>
          <Avatar
            className="hp-text-color-primary-1 hp-bg-color-primary-4"
            size={48}
            style={{
              cursor: "pointer",
              backgroundColor: "#B4DAFD",
              backgroundImage: "url(" + cardBg2 + ")",
              backgroundSize: "cover",
              backgroundPosition: "center right",
            }}
          >
            {user?.name?.charAt(0).toUpperCase()}
            {user?.name?.charAt(user?.name?.length - 1).toUpperCase()}
          </Avatar>
        </Col>
      </Dropdown>
    </div>
  );
};

export default HeaderUser;
