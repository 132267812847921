import React from "react";
import NoArticles from "../../../../assets/images/pages/customize/no-articles.png";
import ArticlePlaceholder from "../../../../assets/images/pages/customize/article-placeholder.png";
import "../../../../assets/css/ChatInitialPageUi.css";

const ChatInitialPageUi = ({
  customizations,
  setIsTab,
  setIsOpenInitialPage,
  recentArticles,
}) => {
  // Handle send msg click function
  const handleSendMessageClick = () => {
    setIsTab("chat");
    setIsOpenInitialPage(false);
  };

  // Handle recent article click
  const handleRecentArticleClick = () => {
    if (customizations?.enableKnowledgeBase) {
      setIsTab("articles");
      setIsOpenInitialPage(false);
    }
  };

  // Function to truncate text and add ellipsis
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + "...";
  };

  return (
    <div
      className="chat__initial_page"
      id="chatbot-initial-page"
      style={{
        backgroundImage: `linear-gradient(
        to bottom,
        ${
          customizations.widgetIconThemeType === "Solid"
            ? customizations.widgetIconBgColor
            : customizations.widgetIconThemeType === "Gradient"
            ? `${customizations.widgetGradientBgOne}, ${customizations.widgetGradientBgTwo}`
            : customizations.widgetIconBgColor
        } ${
          customizations?.enableRecentArticles ? "65%" : "100%"
        }, transparent ${
          customizations?.enableRecentArticles ? "65%" : "100%"
        })`,
      }}
    >
      <div
        id="initial-page-inner"
        style={{ fontFamily: customizations.font, width: "100%" }}
      >
        <div className="initial_page_header">
          <h2>{customizations?.initialPageTitle}</h2>
          <div className="close-icon" id="close-initial-page">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="m3.219 2.154l6.778 6.773l6.706-6.705c.457-.407.93-.164 1.119.04a.777.777 0 0 1-.044 1.035l-6.707 6.704l6.707 6.702c.298.25.298.74.059 1.014c-.24.273-.68.431-1.095.107l-6.745-6.749l-6.753 6.752c-.296.265-.784.211-1.025-.052c-.242-.264-.334-.72-.025-1.042l6.729-6.732l-6.701-6.704c-.245-.27-.33-.764 0-1.075c.33-.311.822-.268.997-.068Z"
              />
            </svg>
          </div>
        </div>

        <div className="initial_page_desc">
          <p> {customizations?.initialPageDescription} </p>
        </div>

        <div className="send-us-message-container">
          <div className="send-us-message">
            <div className="user-info-container">
              <div className="avatar">
                <img
                  src={`${
                    customizations?.initialPageAvatar ??
                    "https://img.freepik.com/free-photo/isolated-shot-cheerful-european-man-with-bristle-smiles-gladfully-happy_273609-45707.jpg?w=900&t=st=1718261200~exp=1718261800~hmac=3bd4b79b2a7980f0f8158f114ba63369528865fc469104954edb18df87c1833a"
                  }`}
                  style={{ height: "50px", width: "50px", objectFit: "cover" }}
                  alt="User avatar"
                />
              </div>

              <div className="user-info">
                <p>{customizations?.initialPageCardSubTitle}</p>
                <p>{customizations?.initialPageCardTagline}</p>
              </div>
            </div>

            <button
              className="send-us-btn"
              id="send-us-message"
              onClick={handleSendMessageClick}
              style={{
                backgroundImage: `linear-gradient(
                  to bottom,
                  ${
                    customizations.widgetIconThemeType === "Solid"
                      ? customizations.widgetIconBgColor
                      : customizations.widgetIconThemeType === "Gradient"
                      ? `${customizations.widgetGradientBgOne}, ${customizations.widgetGradientBgTwo}`
                      : customizations.widgetIconBgColor
                  } ${!customizations?.enableRecentArticles ? "75%" : "65%"},
                  transparent 100%
                )`,
              }}
            >
              {customizations?.initialPageCardButtonText}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 14 14"
                style={{ marginLeft: "12px" }}
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M11.821.098a1.62 1.62 0 0 1 2.077 2.076l-3.574 10.712a1.62 1.62 0 0 1-1.168 1.069a1.599 1.599 0 0 1-1.52-.434l-1.918-1.909l-2.014 1.042a.5.5 0 0 1-.73-.457l.083-3.184l7.045-5.117a.625.625 0 1 0-.735-1.012L2.203 8.088l-1.73-1.73a1.6 1.6 0 0 1-.437-1.447a1.62 1.62 0 0 1 1.069-1.238h.003L11.82.097Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        {customizations?.enableRecentArticles &&
          (recentArticles?.length > 0 ? (
            <div class="recent-articles">
              <h3 class="recent-articles-title">Recent Articles</h3>
              <div class="articles-container" id="recent-articles-container">
                {recentArticles?.map((article) => (
                  <div
                    class="article-card"
                    key={article?._id}
                    onClick={handleRecentArticleClick}
                  >
                    {article.coverImage ? (
                      <img
                        class="article-img"
                        src={article.coverImage}
                        alt={article.title}
                      />
                    ) : (
                      <img
                        class="article-img-placeholder"
                        src={ArticlePlaceholder}
                        alt="article-placeholder"
                      />
                    )}
                    <div class="article-content">
                      <h4 class="article-title">
                        {truncateText(article.title, 100)}
                      </h4>
                      <p class="article-desc">
                        {truncateText(article.description, 120)}
                      </p>
                      <div class="article-meta">
                        <span class="article-author">
                          {article.author.name}
                        </span>
                        <span class="article-date">
                          {new Date(article.publishedDate).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div class="no-articles">
              <img src={NoArticles} alt="No articles available" />
              <h4 class="title">No articles available</h4>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChatInitialPageUi;
