import React from "react";
import { Col, Row } from "antd";
import { LuArrowBigRightDash } from "react-icons/lu";
import EmptyCat1 from "@assets/images/pages/knowledgeBase/empty-categories-1.svg";
import EmptyCat2 from "@assets/images/pages/knowledgeBase/empty-categories-2.svg";
import EmptyCat3 from "@assets/images/pages/knowledgeBase/empty-categories-3.svg";
import EmptyCat4 from "@assets/images/pages/knowledgeBase/empty-categories-4.svg";

const EmptyCategories = () => {
  return (
    <div className="empty-categories">
      {/* Top content */}
      <div className="empty-categories-top-content">
        <img
          src={EmptyCat1}
          className="empty-cat-top-img"
          alt="empty-category-1"
        />
        <div>
          <h5 className="heading">Categories</h5>
          <span style={{ marginBottom: "20px" }}>
            Organize your knowledge base with ease and guide your customers to
            relevant information.
          </span>
          <div class="empty-categories-description">
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Create intuitive navigation by categorizing your articles for
                better discoverability.
              </span>
            </div>
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Personalize category names, icons, and structures in line with
                your brand's essence.
              </span>
            </div>
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Enhance user experience by providing related articles to solve
                customer queries faster.
              </span>
            </div>
            <div class="flex-items">
              <LuArrowBigRightDash style={{ color: "#0b4eb8" }} />
              <span>
                Publish public and private articles and prioritize content with
                custom categories and sort order
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom 3 cards  */}
      <div className="empty-categories-bottom-content">
        <Row gutter={[16, 16]}>
          <Col md={8}>
            <div className="bottom-card-content">
              <img src={EmptyCat2} className="empty-cat-bottom-img" />
              <div style={{ textAlign: "center" }}>
                <h5>Streamline content</h5>
                <span>
                  Organize effortlessly with drag-and-drop category
                  arrangements.
                </span>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="bottom-card-content">
              <img src={EmptyCat3} className="empty-cat-bottom-img" />
              <div style={{ textAlign: "center" }}>
                <h5>Drive engagement</h5>
                <span>
                  Understand which categories attract the most interest and
                  refine your content strategy with built-in analytics.
                </span>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="bottom-card-content">
              <img src={EmptyCat4} className="empty-cat-bottom-img" />
              <div style={{ textAlign: "center" }}>
                <h5>Expand seamlessly</h5>
                <span>
                  As your knowledge base grows, easily scale and adapt your
                  categories to cater to evolving customer needs.
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmptyCategories;
