import React from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";

const MarketingAdminDashboard = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Row
        gutter={[32, 0]}
        style={{
          margin: "20px",
        }}
      >
        <Col span={24}>
          <h2>Welcome back, {user?.name.split(" ")[0]} 👋</h2>
        </Col>

        <Col
          span={24}
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100vh - 100px)",
          }}
        ></Col>
      </Row>
    </>
  );
};

export default MarketingAdminDashboard;
