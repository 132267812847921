import { Col, Row, Skeleton } from "antd";

const AgentsSkeleton = () => {
  return (
    <Row
      style={{
        padding: "0px 15px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      {/* Avatar */}
      <Col style={{ marginRight: "10px" }}>
        <Skeleton.Button shape="circle" active />
      </Col>
      {/* Visitors */}
      <Col style={{ width: "70%" }}>
        <Col
          style={{
            height: "15px",
            overflow: "hidden",
            borderRadius: "20px",
            marginBottom: "8px",
          }}
        >
          <Skeleton.Input
            active={true}
            size={"small"}
            block={true}
            style={{
              backgroundColor: "#0000000f",
              width: "100%",
            }}
          />
        </Col>
        <Col
          style={{
            height: "10px",
            overflow: "hidden",
            borderRadius: "20px",
          }}
        >
          <Skeleton.Input
            active={true}
            size={"small"}
            block={true}
            style={{
              backgroundColor: "#0000000f",
              width: "100%",
            }}
          />
        </Col>
      </Col>
    </Row>
  );
};

export default AgentsSkeleton;
