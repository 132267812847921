import { useEffect } from "react";
import { Col, Button, Card, Tooltip, Row } from "antd";
import { useSelector } from "react-redux";
import { IoMdEye } from "react-icons/io";
import { RiFileCopyFill } from "react-icons/ri";
import { HeartOutlined } from "@ant-design/icons";
import { FaHeart } from "react-icons/fa";
// Redux Action
import { useDispatch } from "react-redux";
import { setTemplatePreviewAction } from "../../../redux/actions/chatbotActions";
import botLogo from "../../../assets/images/pages/chatList/botLogo.png";
import { useState } from "react";

const { Meta } = Card;

const TemplateCard = ({
  template,
  index,
  handleTemplateCopy,
  handleLikeTemplate,
  setModalVisible,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (template?.liked) {
      setLiked(true);
    }
  }, [template]);

  // Handle like click
  const handleLikeClick = () => {
    setLiked(!liked);
    handleLikeTemplate(template?._id);
  };

  // Handle template view click
  const handleTemplateViewClick = () => {
    dispatch(setTemplatePreviewAction(template));
    setModalVisible(true);
  };

  return (
    <>
      <Col
        key={template?._id}
        xs={24}
        sm={12}
        md={8}
        lg={6}
        xl={6}
        style={{
          marginBottom: "20px",
        }}
      >
        <Card
          hoverable
          id={index === 0 && "joyride-bot-template-copy"}
          style={{
            height: "400px", // Fixed total height of the card
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          cover={
            <div
              style={{
                height: "200px",
              }}
            >
              <img
                alt="coverImage"
                src={template?.coverImage || botLogo}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }} // Ensure the image covers the area
              />
            </div>
          }
        >
          {/* Card Content */}
          <>
            <Meta
              title={template?.name}
              description={template?.category}
              style={{
                maxHeight: "160px", // Fixed height for text content
                overflow: "hidden", // Hide overflow
                textOverflow: "ellipsis", // Ellipsis for text overflow
                whiteSpace: "nowrap", // Prevent wrapping
              }}
            />

            <Row
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                textAlign: "end",
                gap: "3px",
              }}
            >
              <Col>
                {/* Like bot */}
                <Tooltip
                  placement="top"
                  title={<span>Like Bot</span>}
                  okText="Yes"
                  cancelText="No"
                  className="hp-ml-8"
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={handleLikeClick}
                    icon={
                      liked ? (
                        <FaHeart
                          style={{
                            fontSize: "1.4rem",
                            color: "red",
                          }}
                        />
                      ) : (
                        <HeartOutlined
                          style={{
                            fontSize: "1.4rem",
                          }}
                        />
                      )
                    }
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#0b4eb8",
                    }}
                    iconOnly
                  ></Button>
                </Tooltip>
              </Col>
              <Col>
                {/* Preview bot */}
                <Tooltip
                  placement="top"
                  title={<span>Preview Bot</span>}
                  okText="Yes"
                  cancelText="No"
                  className="hp-ml-8"
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      handleTemplateViewClick(template?.chatbot);
                    }}
                    icon={
                      <IoMdEye
                        style={{
                          fontSize: "1.6rem",
                        }}
                        id={index === 0 && "joyride-bot-template-preview"}
                      />
                    }
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#0b4eb8",
                    }}
                    iconOnly
                  ></Button>
                </Tooltip>
              </Col>
              <Col>
                {/* Copy chatbot */}
                {user?.role !== "superadmin" && (
                  <Tooltip
                    placement="top"
                    title={<span>Copy Bot</span>}
                    okText="Yes"
                    cancelText="No"
                    className="hp-ml-8"
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      onClick={() => {
                        handleTemplateCopy(template?._id, template?.name);
                      }}
                      icon={
                        <RiFileCopyFill
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />
                      }
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "#0b4eb8",
                      }}
                      iconOnly
                    />
                  </Tooltip>
                )}
              </Col>
            </Row>

            {/* Description */}
            <p
              style={{
                marginTop: "1rem",
                marginBottom: "0rem",
                fontSize: "0.95rem",
              }}
            >
              {/* Show max 25 chars then dots */}
              {template?.description?.length > 40
                ? `${template?.description?.substring(0, 40)}...`
                : template?.description}
            </p>
          </>
        </Card>
      </Col>
    </>
  );
};

export default TemplateCard;
