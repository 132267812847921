import React, { FC } from "react";
import { Row, Col, Input } from "antd";
import { Handle, Position } from "reactflow";
import { useTranslation } from "react-i18next";

const OptionScale: FC<any> = ({
  value,
  index,
  from,
  fromLabel,
  to,
  toLabel,
}: {
  value: any;
  index: any;
  from: any;
  fromLabel: any;
  to: any;
  toLabel: any;
}) => {
  const { t } = useTranslation();
  return (
    <Row
      key={value}
      align="middle"
      style={{
        width: "100%",
        transition: "border-color 0.3s ease",
        cursor: "all-scroll",
        borderRadius: "3px",
        marginTop: "1px",
      }}
    >
      <Col span={24}>
        <Input
          value={
            value === parseInt(from)
              ? `${value} - ${fromLabel || t("flowBuilder.builderNodes.worst")}`
              : value === parseInt(to)
              ? `${value} - ${toLabel || t("flowBuilder.builderNodes.best")}`
              : value
          }
          style={{ borderRadius: "3px" }}
          readOnly
        />
      </Col>

      {from !== null && from !== undefined && (
        <Col span={24}>
          <Handle
            type="source"
            position={Position.Right}
            id={`source-${index}`}
            className="node-source"
            style={{
              background: "#e40000",
              marginTop: "-20px",
            }}
          />
        </Col>
      )}
    </Row>
  );
};

export default OptionScale;
