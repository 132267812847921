import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Table, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllTags, deleteTagById } from "@api/template/template";
import {
  SuccessNotification,
  ErrorNotification,
  TagCreateModal,
  TagUpdateModal,
} from "@components";

const TemplateTagsList = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  // States
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [tagId, setTagId] = useState("");

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    setLoading(true);
    try {
      const { data } = await getAllTags();
      setTags(data);
    } catch (error) {
      console.error("Fetch tags error", error);
    }
    setLoading(false);
  };

  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const handleUpdateModalClick = (id) => {
    setTagId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  const handleTagDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this tag?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteTagById(id);
          fetchTags();
          SuccessNotification("Tag deleted successfully");
        } catch (error) {
          ErrorNotification("Error deleting tag");
        }
      },
    });
  };

  const columns = [
    {
      title: "Tag name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of Templates",
      dataIndex: "templates",
      key: "templates",
      render: (templates) => templates.length,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleUpdateModalClick(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleTagDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {createModalOpen && (
        <TagCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchTags={fetchTags}
        />
      )}

      {updateModalOpen && (
        <TagUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchTags={fetchTags}
          tagId={tagId}
        />
      )}

      <Card style={{ borderRadius: "0", height: "100vh" }}>
        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <h3>Tags List</h3>
          </Col>
          <Col>
            {/* Back Button */}
            <Button
              onClick={() => {
                // If user is superadmin, redirect to chatbot templates
                if (user?.role === "superadmin") {
                  history.push("/super-admin/chatbot-templates");
                } else if (user?.role === "marketing") {
                  history.push("/marketing-admin/chatbot-templates");
                }
              }}
              style={{ marginRight: 16 }}
            >
              Go Back
            </Button>

            {/* Create Tag */}
            <Button type="primary" onClick={handleCreateModalClick}>
              Create Tag
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={tags}
          loading={loading}
          rowKey={(record) => record._id}
        />
      </Card>
    </>
  );
};

export default TemplateTagsList;
