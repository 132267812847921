import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Checkbox } from "antd";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import LeftContent from "./leftContent";
import { loginAction, googleLoginAction } from "@redux/actions/authActions";
import { ErrorNotification } from "@components";
import logoText from "@assets/images/logo/logoText.png";
import Styles from "@assets/css/AuthCommon.Module.css";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Handle email password login
  const handleLoginSubmit = (e) => {
    // Validate email regex
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      ErrorNotification("Please enter a valid email");
      return;
    }

    // Make sure the password is at least 6 characters
    if (password.length < 6) {
      ErrorNotification("Please enter a valid password");
      return;
    }

    e.preventDefault();
    // Dispatch email, password and history to action
    dispatch(loginAction({ email, password, history, setLoading }));
  };

  // Handle google login success
  const handleGoogleSuccess = async (credentialResponse) => {
    const userObject = jwt_decode(credentialResponse.credential);
    const { name, email } = userObject;
    setLoading(true);

    // Dispatch email, password and history to action
    dispatch(googleLoginAction({ name, email, history, setLoading }));
  };

  // Handle google login failure
  const handleGoogleFailure = () => {
    console.log("GOOGLE_LOGIN_ERROR");
  };

  return (
    <>
      <Row
        gutter={[32, 0]}
        className={`hp-authentication-page ${Styles.centerContent}`}
        style={{ overflowY: "auto", height: "100vh" }}
      >
        <LeftContent />

        <Col
          lg={8}
          md={16}
          sm={16}
          span={24}
          className="hp-py-sm-0 hp-py-md-0"
          justify="center"
        >
          <Row className="hp-h-100" align="middle" justify="center">
            <Col
              xxl={11}
              xl={15}
              lg={20}
              md={16}
              sm={24}
              className="hp-px-sm-8 hp-pt-24 hp-pb-48"
            >
              <img
                src={logoText}
                alt="logo"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  margin: "0 auto",
                }}
              />

              <h2
                className="hp-mb-sm-0"
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  color: "#000000",
                }}
              >
                Login
              </h2>
              <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
                Welcome back, please login to your account.
              </p>

              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                className="hp-mt-sm-16 hp-mt-8"
              >
                <Form.Item
                  label="Email :"
                  className="hp-mb-8"
                  rules={[
                    { required: true, message: "Please enter your email" },
                  ]}
                >
                  <Input
                    id="validating"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Password :"
                  className="hp-mb-8"
                  rules={[
                    { required: true, message: "Please enter your password" },
                  ]}
                >
                  <Input.Password
                    id="warning2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>

                <Row align="middle" justify="space-between">
                  <Form.Item className="hp-mb-0">
                    <Checkbox name="remember">Remember me</Checkbox>
                  </Form.Item>

                  <Link
                    className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                    to="/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </Row>

                <Form.Item className="hp-mt-16 hp-mb-8">
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={handleLoginSubmit}
                    loading={loading}
                  >
                    Sign in
                  </Button>
                </Form.Item>
              </Form>

              <Col className="hp-form-info">
                <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                  Don’t you have an account?
                </span>

                <Link
                  className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                  to="/signup"
                >
                  {" "}
                  Create an account
                </Link>
              </Col>

              <Col className="hp-or-line hp-text-center hp-mt-8">
                <span className="hp-caption hp-text-color-black-80 hp-text-color-dark-30 hp-px-16 hp-bg-color-black-0 hp-bg-color-dark-100">
                  Or
                </span>
              </Col>

              <Col
                className="hp-account-buttons hp-mt-8"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleGoogleSuccess(credentialResponse);
                  }}
                  onError={() => {
                    handleGoogleFailure();
                  }}
                />
              </Col>

              <Col
                className="hp-other-links hp-mt-24"
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Link
                  to="/privacy"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Privacy Policy
                </Link>

                {/* A dot in between */}
                <span
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                  style={{ margin: "0 8px", fontSize: "12px" }}
                >
                  .
                </span>

                <Link
                  to="/terms"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Terms of use
                </Link>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
