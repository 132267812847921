import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Collapse,
  Tag,
  Spin,
  Card,
  Button,
  Checkbox,
} from "antd";
import { useHistory } from "react-router";
import { RiArrowRightSLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
  getAdminUsageHistory,
  deleteUsageRecords,
} from "../../../../../api/user/superAdmin";
import { UsageCreateModal, UsageUpdateModal } from "../../../../../components";

const { Panel } = Collapse;

const AdminUsageHistory = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [usageHistory, setUsageHistory] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [usageId, setUsageId] = useState("");
  const [selectedUsageIds, setSelectedUsageIds] = useState([]);

  useEffect(() => {
    handleFetchUsageHistory();
  }, []);

  const handleFetchUsageHistory = async () => {
    setLoading(true);
    const { data } = await getAdminUsageHistory(id);
    setUsageHistory(data);
    setLoading(false);
  };

  const genExtra = () => (
    <RiArrowRightSLine
      size={24}
      className="hp-collapse-arrow hp-text-color-black-60"
    />
  );

  // Handle create tag modal
  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update copilot modal
  const handleUpdateModalClick = (usageId, item) => {
    setUsageId(usageId);
    setUpdateModalOpen(!updateModalOpen);
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedUsageIds(usageHistory.map((item) => item?._id));
    } else {
      setSelectedUsageIds([]);
    }
  };

  const handleRecordCheckboxChange = (id) => {
    if (selectedUsageIds.includes(id)) {
      setSelectedUsageIds(selectedUsageIds.filter((itemId) => itemId !== id));
    } else {
      setSelectedUsageIds([...selectedUsageIds, id]);
    }
  };

  const handleBulkDelete = async () => {
    setLoading(true);

    try {
      // Assuming you have a bulk delete API endpoint or can modify your delete endpoint to accept multiple IDs
      await deleteUsageRecords(id, selectedUsageIds)
        .then(() => {
          handleFetchUsageHistory(); // Refresh the list after deletion
          setSelectedUsageIds([]); // Clear the selection
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error deleting records:", error);
          // Handle error (e.g., show error notification)
        });
    } catch (error) {
      setLoading(false);
      console.error("Error deleting records:", error);
      // Handle error (e.g., show error notification)
    }
  };

  return (
    <>
      {/* Create Tag Modal */}
      {createModalOpen && (
        <UsageCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          handleFetchUsageHistory={handleFetchUsageHistory}
        />
      )}

      {/* Update Tag Modal */}
      {updateModalOpen && (
        <UsageUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          handleFetchUsageHistory={handleFetchUsageHistory}
          usageId={usageId}
          adminId={id}
        />
      )}

      <Card
        style={{
          borderRadius: "0",
          height: "100vh",
        }}
      >
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          {/* Header Buttons */}
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row
              style={{
                marginBottom: "20px",
              }}
              align="middle"
              justify="space-between"
            >
              {/* Left Side: Title  */}
              <Col>
                <h3
                  style={{
                    display: "inline",
                    marginLeft: "10px", // Add some space between the checkbox and the title
                  }}
                >
                  Usage History
                </h3>
              </Col>

              {/* Right Side: Buttons */}
              <Col>
                <Button
                  onClick={handleBulkDelete}
                  disabled={selectedUsageIds.length === 0}
                  size="small"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Delete Selected
                </Button>
                <Button
                  type="primary"
                  onClick={handleCreateModalClick}
                  size="small"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Create New Usage
                </Button>
                <Button
                  size="small"
                  onClick={() => history.goBack()}
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Go Back
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          justify="start"
          style={{
            paddingBottom: "20px",
          }}
        >
          {/* 'Select All' Checkbox Alignment */}
          <Checkbox
            onChange={handleSelectAllChange}
            checked={
              selectedUsageIds.length === usageHistory.length &&
              usageHistory.length > 0
            }
            indeterminate={
              selectedUsageIds.length > 0 &&
              selectedUsageIds.length < usageHistory.length
            }
          >
            Select All
          </Checkbox>
        </Row>

        <Collapse
          style={{
            height: "calc(94vh - 50px)",
            overflowY: "auto",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(80vh - 50px)",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              {usageHistory?.map((item, index) => (
                <Panel
                  header={
                    <Row align="middle" justify="space-between" key={index}>
                      <Checkbox
                        checked={selectedUsageIds.includes(item?._id)}
                        onChange={() => handleRecordCheckboxChange(item?._id)}
                      />

                      <p className="hp-d-flex-center hp-p1-body hp-mb-0">
                        <Tag
                          color={
                            item?.subscriptionPlan === "Free"
                              ? "green"
                              : item?.subscriptionPlan === "Optimizer"
                              ? "blue"
                              : item?.subscriptionPlan === "Wizard"
                              ? "purple"
                              : "orange"
                          }
                        >
                          {item?.subscriptionPlan}
                        </Tag>
                      </p>

                      {/* Start Date */}
                      <div
                        style={{
                          marginLeft: "30px",
                          marginRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          Start:
                        </span>
                        {new Date(item?.subscriptionStartDate).toUTCString()}
                      </div>

                      {/* End Date */}
                      <div
                        style={{
                          marginRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          End:
                        </span>
                        {new Date(item?.subscriptionEndDate).toUTCString()}
                      </div>

                      {/* Extra Used? */}
                      <div
                        style={{
                          marginRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          Extra Used:
                        </span>
                        {item?.extraUsed ? "Yes" : "No"}
                      </div>
                    </Row>
                  }
                  key={index}
                  showArrow={false}
                  extra={genExtra()}
                >
                  {/* Start Date */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      Start Date
                    </Col>

                    <Col sm={9} span={12}>
                      <Input
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        value={new Date(
                          item?.subscriptionStartDate
                        ).toUTCString()}
                      />
                    </Col>
                  </Row>

                  {/* End Date */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      End Date
                    </Col>

                    <Col sm={9} span={12}>
                      <Input
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        value={new Date(
                          item?.subscriptionEndDate
                        ).toUTCString()}
                      />
                    </Col>
                  </Row>

                  {/* Chats Allotted */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      Chats Allotted
                    </Col>

                    <Col sm={9} span={12}>
                      <Input
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        value={item?.chatsAllotted}
                      />
                    </Col>
                  </Row>

                  {/* Chats Used */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      Chats Used
                    </Col>

                    <Col sm={9} span={12}>
                      <Input
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        value={item?.chatsUsed}
                      />
                    </Col>
                  </Row>

                  {/* If extra used is true, loop through extra usage reports and display */}
                  {item?.extraUsed &&
                    item?.extraUsageReports.map((rep, index) => (
                      <Row
                        align="middle"
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <Col
                          sm={15}
                          span={12}
                          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                        >
                          Extra Usage Report {index + 1}
                        </Col>

                        <Col sm={9} span={12}>
                          <p>Reported Date</p>
                          <Input
                            className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                            value={rep?.reportedDate}
                          />

                          <p className="hp-mt-16">Reported Usage</p>
                          <Input
                            className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                            value={rep?.reportedUsage}
                          />
                        </Col>
                      </Row>
                    ))}

                  {/* Edit Usage Button */}
                  <Row
                    align="middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      sm={15}
                      span={12}
                      className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
                    >
                      Edit Usage
                    </Col>

                    <Col sm={9} span={12}>
                      <Button
                        className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
                        type="primary"
                        onClick={() => {
                          handleUpdateModalClick(item?._id, item);
                        }}
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                </Panel>
              ))}
            </>
          )}
        </Collapse>
      </Card>
    </>
  );
};

export default AdminUsageHistory;
