import { Button, notification, Tag } from "antd";
import { RiErrorWarningFill, RiCloseFill } from "react-icons/ri";

// ****** Join Workspace ****** //
// Function to execute upon connecting or re-connecting
export const handleJoinWorkspace = (socket, wid, userId) => {
  // Join the workspace id room
  if (wid) {
    socket.current.emit("join-workspace", { workspaceId: wid, userId });
  }
};

// ****** REFERENCES ****** //
let timerRef;
let notificationSoundRef;

// Notification cleanup handler
export const handleNotificationCleanup = () => {
  clearInterval(timerRef);
  document.title = "Conferbot - A Simple No-Code Chatbot Builder"; // Reset title to original

  if (notificationSoundRef) {
    notificationSoundRef?.pause(); // Stop playing the sound
    notificationSoundRef.currentTime = 0; // Reset sound time
  }

  // Close the notification
  notification.destroy();
};

// ****** Initial Handover Request ****** //
// Listen to 'handover-request' event from server
export const handoverRequestHandler = (
  socket,
  wid,
  userId,
  role,
  data,
  history,
  remainingTime
) => {
  let notificationSound;

  remainingTime = data.maxWaitTime * 60; // Convert minutes to seconds
  const originalTitle = document.title; // Save the original tab title
  let toggleTitle = false; // For toggling tab title

  if (data?.checkedSoundTab === "upload") {
    notificationSound = new Audio(data?.customHandoverSoundUrl);
  } else if (data?.checkedSoundTab === "choose") {
    notificationSound = new Audio(data?.selectedSound);
  }

  const playSound = () => {
    if (data?.enableHandoverSound) {
      notificationSound
        ?.play()
        .catch((error) => console.error("Error playing sound:", error));
    }
  };

  const timer = setInterval(() => {
    remainingTime -= 1;
    updateNotification(remainingTime);

    // Tab title blinking
    document.title = toggleTitle ? "🔔 New Handover Request!" : originalTitle;
    toggleTitle = !toggleTitle;

    // Play sound every 10 seconds if enabled
    if (remainingTime % 10 === 0) {
      playSound();
    }

    if (remainingTime <= 0) {
      clearInterval(timer);
      document.title = originalTitle; // Reset title to original
      notification.destroy(); // Destroy the notification when time runs out
    }
  }, 1000);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const getTimerColor = (time) => {
    return time <= 60 ? "#f50" : "#52c41a"; // Red color for less than a minute, green otherwise
  };

  const updateNotification = (time) => {
    if (time <= 0) return; // Do not update notification if time has run out

    notification.open({
      key: "handoverRequest",
      message: (
        <>
          <Tag color="blue">Priority: {data.priority}</Tag>
        </>
      ),
      description: (
        <>
          <div style={{ fontSize: "16px" }}>
            New handover request from {data.chatSessionId}!
          </div>
          <div
            style={{
              color: getTimerColor(time),
              marginTop: "10px",
              fontSize: "16px",
            }}
          >
            Time remaining: {formatTime(time)}
          </div>
        </>
      ),
      icon: <RiErrorWarningFill style={{ color: "#0647a6" }} size={24} />,
      closeIcon: (
        <div onClick={() => handleNotificationCleanup()}>
          <RiCloseFill
            className="remix-icon hp-text-color-black-80"
            size={24}
          />
        </div>
      ),
      duration: 0,
      btn: (
        <div>
          {/* Updated View Chat button */}
          <Button
            type="primary"
            size="small"
            onClick={() => {
              handleAcceptHandoverChat(wid, role, data, history);
              handleNotificationCleanup();
            }}
          >
            Accept
          </Button>

          {/* Updated Decline button */}
          <Button
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handoverDeclineHandler(
                socket,
                wid,
                userId,
                { ...data, maxWaitTime: remainingTime / 60 },
                data.chatSessionId
              );
              handleNotificationCleanup();
            }}
          >
            Decline
          </Button>
        </div>
      ),
    });
  };

  // Save references for cleanup
  timerRef = timer;
  notificationSoundRef = notificationSound;

  // Update the notification with the remaining time and color
  updateNotification(remainingTime);

  // Play sound immediately if enabled
  if (data?.enableHandoverSound) {
    playSound(); // Play sound immediately if enabled
  }
};

// Handle accepting handover chat
const handleAcceptHandoverChat = (wid, role, data, history) => {
  const basePath = role === "admin" ? "/a/ws" : "/m/ws";
  const targetPath = `${basePath}/${wid}/chats/bot/${data.chatMetaData.botId}/chat/${data.chatMetaData._id}`;

  const currentPath = window.location.pathname;
  const isOnChatsPage = currentPath.includes(`${basePath}/${wid}/chats`);

  // Add isOnChatsPage flag to the handoverData
  const handoverData = { ...data, isOnChatsPage };

  // Redirect to the chat page if not already there
  if (!isOnChatsPage) {
    // Store handoverData in sessionStorage
    sessionStorage.setItem("handoverData", JSON.stringify(handoverData));

    history.push(targetPath);
  } else {
    // If already on the chat page, handle the handoverData appropriately
    window.dispatchEvent(
      new CustomEvent("handleHandover", { detail: handoverData })
    );
  }

  // Close the notification
  notification.destroy();
};

// Handle handover decline
export const handoverDeclineHandler = (
  socket,
  wid,
  userId,
  handoverData,
  chatSessionId
) => {
  // Send handover-decline event to server
  socket.current.emit("handover-decline", {
    workspaceId: wid,
    userId,
    chatSessionId,
    handoverData,
  });

  // Close the notification
  notification.destroy();
};

// ****** Chat Invitation ****** //
export const handleJoinChatInvitationHandler = (
  socket,
  wid,
  userId,
  role,
  data,
  history
) => {
  const basePath = role === "admin" ? "/a/ws" : "/m/ws";
  const targetPath = `${basePath}/${wid}/chats/bot/${data.chatMetaData.botId}/chat/${data.chatMetaData._id}`;
  const currentPath = window.location.pathname;
  const isOnChatsPage = currentPath.includes(`${basePath}/${wid}/chats`);

  let notificationSound;
  const originalTitle = document.title; // Save the original tab title
  let toggleTitle = false; // For toggling tab title

  let remainingTime = data?.handoverData?.maxWaitTime * 60 || 300; // Default to 5 minutes or use maxWaitTime

  // Determine which sound to use
  if (data?.handoverData?.checkedSoundTab === "upload") {
    notificationSound = new Audio(data?.handoverData?.customHandoverSoundUrl);
  } else if (data?.handoverData?.checkedSoundTab === "choose") {
    notificationSound = new Audio(data?.handoverData?.selectedSound);
  }

  const playSound = () => {
    if (data?.handoverData?.enableHandoverSound) {
      notificationSound
        ?.play()
        .catch((error) => console.error("Error playing sound:", error));
    }
  };

  const timer = setInterval(() => {
    remainingTime -= 1;
    if (remainingTime % 10 === 0) {
      playSound();
    }

    document.title = toggleTitle ? "🔔 New Chat Invitation!" : originalTitle;
    toggleTitle = !toggleTitle;

    if (remainingTime <= 0) {
      clearInterval(timer);
      document.title = originalTitle; // Reset title to original
      notification.destroy(); // Destroy the notification when time runs out
    } else {
      // Update the notification with the remaining time and color
      updateNotification(remainingTime);
    }
  }, 1000);

  const handleNotificationCleanup = () => {
    clearInterval(timer);
    document.title = originalTitle; // Reset title to original
    notificationSound.pause(); // Stop playing the sound
    notificationSound.currentTime = 0; // Reset sound time
    notification.destroy(); // Close the notification here
  };

  // Function to format remaining time
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  // Function to get timer color based on remaining time
  const getTimerColor = (time) => {
    return time <= 60 ? "#f50" : "#52c41a"; // Red color for less than a minute, green otherwise
  };

  const updateNotification = (time) => {
    if (time <= 0) return;

    notification.open({
      key: "joinChatInvitation",
      message: "Chat Invitation",
      description: (
        <>
          <div style={{ fontSize: "16px" }}>
            You have been invited by {data.inviterAgentDetails.name} to join a
            chat.
          </div>
          <div
            style={{
              color: getTimerColor(time),
              marginTop: "10px",
              fontSize: "16px",
            }}
          >
            Time remaining: {formatTime(time)}
          </div>
        </>
      ),
      icon: <RiErrorWarningFill style={{ color: "#0647a6" }} size={24} />,
      closeIcon: (
        <div onClick={() => handleNotificationCleanup()}>
          <RiCloseFill
            className="remix-icon hp-text-color-black-80"
            size={24}
          />
        </div>
      ),
      duration: 0, // Keep the notification open until an action is taken
      btn: (
        <div>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              handleAcceptInvitedHandoverChat(
                isOnChatsPage,
                targetPath,
                data,
                history
              );
              handleNotificationCleanup();
            }}
          >
            Accept
          </Button>
          <Button
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleDeclineInvitedHandoverChat(socket, userId, data);
              handleNotificationCleanup();
            }}
          >
            Decline
          </Button>
        </div>
      ),
    });
  };

  // Save references for cleanup
  timerRef = timer;
  notificationSoundRef = notificationSound;

  // Play sound immediately if enabled
  playSound();

  // Initial call to display the notification with remaining time
  updateNotification(remainingTime);
};

// Function to handle the invitation accept data
const handleAcceptInvitedHandoverChat = (
  isOnChatsPage,
  targetPath,
  invitationData,
  history
) => {
  if (!isOnChatsPage) {
    sessionStorage.setItem("invitationData", JSON.stringify(invitationData));

    // Redirect to the chats page if not already there
    history.push(targetPath);
  } else {
    // If already on the chats page, handle the invitation data appropriately
    window.dispatchEvent(
      new CustomEvent("handleInvitation", { detail: invitationData })
    );
  }
  notification.destroy(); // Optionally destroy the notification after handling
};

// Function to handle the invitation decline
const handleDeclineInvitedHandoverChat = (socket, userId, data) => {
  // Send the decline-invitation event to the server
  socket.current.emit("decline-agent-invitation", {
    workspaceId: data.workspaceId,
    chatSessionId: data.chatSessionId,
    inviterAgentDetails: data.inviterAgentDetails,
    invitedAgentDetails: data.invitedAgentDetails,
    userId,
  });

  notification.destroy(); // Optionally destroy the notification after handling
};
