// Node answer variable helper
export const extractAnswerVariables = (nodes: any) => {
  const answerVariables: any = [];

  nodes.forEach((node: any) => {
    // Check for node types that can have answer variables
    switch (node.type) {
      case "ask-name-node":
      case "ask-email-node":
      case "ask-phone-number-node":
      case "ask-number-node":
      case "ask-url-node":
      case "ask-file-node":
      case "ask-location-node":
      case "ask-custom-question-node":
      case "calendar-node":
      case "n-select-option-node":
      case "n-check-options-node":
      case "n-choices-node":
      case "image-choice-node":
      case "rating-choice-node":
      case "yes-or-no-choice-node":
      case "opinion-scale-choice-node":
        // Standard nodes with a single answer variable
        if (node.data.answerVariable) {
          answerVariables.push(node.data.answerVariable);
        }
        break;
      case "ask-multiple-questions-node":
        // Handle multiple questions
        node.data.questions.forEach((question: any) => {
          if (question.answerVariable) {
            answerVariables.push(question.answerVariable);
          }
        });
        break;
      // Add cases for any other node types that might contain answer variables
      default:
        break;
    }
  });

  return answerVariables;
};
