import React from "react";
import { useSelector } from "react-redux";
import { RiArrowGoBackFill } from "react-icons/ri";
import { BlogUpdateForm } from "@components";
import { Card, Row, Col } from "antd";

const BlogUpdate = ({ history }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Card style={{ borderRadius: "0", height: "100vh", overflowY: "auto" }}>
        <Row>
          <Col span={24}>
            <Row>
              <RiArrowGoBackFill
                style={{ fontSize: "2em", cursor: "pointer" }}
                onClick={() => {
                  // If user is superadmin, redirect to blogs
                  if (user?.role === "superadmin") {
                    history.push("/super-admin/blogs");
                  } else if (user?.role === "marketing") {
                    history.push("/marketing-admin/blogs");
                  }
                }}
              />
              <h3
                className="hp-mb-16"
                style={{
                  marginLeft: "1em",
                  fontSize: "1.75em",
                }}
              >
                Update blog
              </h3>
            </Row>
            {/* Space */}
            <Row style={{ marginTop: "1em" }} />
            {/* Blog Update Form */}
            <BlogUpdateForm />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default BlogUpdate;
