import React, { useState, useEffect } from "react";
import { Row, Col, Button, Steps, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import { fireConfetti } from "@utils/confetti";
import { saveUserOnboardingData } from "@api/user/user";
import { logoutAction } from "@redux/actions/authActions";
import logo from "@assets/images/logo/logo.png";

const { Step } = Steps;

// Data Arrays
const industry = [
  {
    id: 1,
    name: "Technology",
  },
  {
    id: 2,
    name: "Healthcare",
  },
  {
    id: 3,
    name: "Education",
  },
  {
    id: 4,
    name: "Finance",
  },
  {
    id: 5,
    name: "Retail",
  },
  {
    id: 6,
    name: "Manufacturing",
  },
  {
    id: 7,
    name: "Other",
  },
];
const department = [
  {
    id: 0,
    name: "I'm a Student",
  },
  {
    id: 1,
    name: "Engineering",
  },
  {
    id: 2,
    name: "Marketing",
  },
  {
    id: 3,
    name: "Human Resources",
  },
  {
    id: 4,
    name: "Sales",
  },
  {
    id: 5,
    name: "Finance",
  },
  {
    id: 6,
    name: "Operations",
  },
  {
    id: 7,
    name: "Other",
  },
];
const features = [
  "Integrations",
  "UI Customizations",
  "Live-chat",
  "Human Takeover",
  "Analytics",
  "Multi-language Support",
  "Other",
];

const Onboarding = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [current, setCurrent] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  // If the user is already subscribed, redirect to dashboard
  useEffect(() => {
    if (user?.onboarding_complete) {
      history.push(`/a/ws/${user.workspaceId}/dashboard`);
    }
  }, [user, history]);

  // Handle logout
  const handleLogout = () => {
    // Dispatch logout action
    dispatch(logoutAction());
  };

  // Onboarding Steps
  const steps = [
    // ** INDUSTRY ** //
    {
      content: (
        <Col span={24} style={{ marginTop: -32 }}>
          <h3 className="hp-mb-16">Tell us about your business</h3>
          <p className="hp-mb-32">
            Your feedback is super important 🌟! It helps us tailor Conferbot to
            your needs and enhance our product. 😊
          </p>

          {/* Select Industry Dropdown */}
          <Select
            className="hp-mb-16"
            placeholder="Select your industry"
            style={{ width: 200, marginRight: 8 }}
            onChange={(value) => setSelectedIndustry(value)}
            value={selectedIndustry}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value="">Select your industry</Select.Option>
            {industry.map((index) => (
              <Select.Option key={index.id} value={index.name}>
                {index.name}
              </Select.Option>
            ))}
          </Select>

          {/* Select Department Dropdown */}
          <Select
            className="hp-mb-16"
            placeholder="Select your department"
            style={{ width: 200, marginRight: 8 }}
            onChange={(value) => setSelectedDepartment(value)}
            value={selectedDepartment}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value="">Select your department</Select.Option>
            {department.map((index) => (
              <Select.Option key={index.id} value={index.name}>
                {index.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      ),
    },
    // ** PURPOSE ** //
    {
      content: (
        <Col span={24} style={{ marginTop: -32 }}>
          <h3 className="hp-mb-16">🚀 Help Us Make Conferbot Even Better!</h3>
          <p className="hp-mb-32">
            Your feedback is super important 🌟! It helps us tailor Conferbot to
            your needs and enhance our product. 😊
          </p>

          {/* Select Purpose of Chatbot Dropdown */}
          <Select
            showSearch
            className="hp-mb-16"
            placeholder="Purpose of your chatbot"
            style={{ width: 250, marginRight: 8 }}
            onChange={(value) => setSelectedPurpose(value)}
            value={selectedPurpose}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value="">Select the purpose</Select.Option>
            <Select.Option value="Customer Support">
              Customer Support
            </Select.Option>
            <Select.Option value="Sales Inquiry">Sales Inquiry</Select.Option>
            <Select.Option value="FAQs">FAQs</Select.Option>
            <Select.Option value="Booking and Reservations">
              Booking and Reservations
            </Select.Option>
            <Select.Option value="Feedback Collection">
              Feedback Collection
            </Select.Option>
            <Select.Option value="Event Management">
              Event Management
            </Select.Option>
            <Select.Option value="Human Resources">
              Human Resources
            </Select.Option>
            <Select.Option value="Educational Assistance">
              Educational Assistance
            </Select.Option>
            <Select.Option value="Health and Wellness">
              Health and Wellness
            </Select.Option>
            <Select.Option value="E-commerce Assistance">
              E-commerce Assistance
            </Select.Option>
            <Select.Option value="Personal Assistant">
              Personal Assistant
            </Select.Option>
            <Select.Option value="Entertainment and Games">
              Entertainment and Games
            </Select.Option>
            <Select.Option value="Travel Assistance">
              Travel Assistance
            </Select.Option>
            <Select.Option value="News and Updates">
              News and Updates
            </Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Col>
      ),
    },
    // ** FEATURES ** //
    {
      content: (
        <Col span={24} style={{ marginTop: -32 }}>
          <h3 className="hp-mb-16">What Features Are You Looking For?</h3>
          <p className="hp-mb-16">Select features you're interested in.</p>

          {/* Select Categories Card */}
          {/* Place 5 categories on the first row and 3 on the second */}
          <Row gutter={[24, 24]} className="hp-mb-16">
            {features.slice(0, 5).map((name, index) => (
              <Row key={index}>
                <Button
                  type="primary"
                  className="hp-btn-outline hp-hover-bg-primary-1"
                  style={{
                    marginRight: 20,
                    backgroundColor: selectedFeatures.includes(name)
                      ? "#2F59F7"
                      : "#fff",
                    color: selectedFeatures.includes(name) ? "#fff" : "#2F59F7",
                  }}
                  onClick={() => {
                    if (selectedFeatures.includes(name)) {
                      setSelectedFeatures(
                        selectedFeatures.filter((item) => item !== name)
                      );
                    } else {
                      setSelectedFeatures([...selectedFeatures, name]);
                    }
                  }}
                >
                  {name}
                </Button>
              </Row>
            ))}
          </Row>
          <Row gutter={[24, 24]} className="hp-mb-16" justify="center">
            {features.slice(5, 8).map((name, index) => (
              <Row key={index}>
                <Button
                  type="primary"
                  className="hp-btn-outline hp-hover-bg-primary-1"
                  style={{
                    marginRight: 20,
                    backgroundColor: selectedFeatures.includes(name)
                      ? "#2F59F7"
                      : "#fff",
                    color: selectedFeatures.includes(name) ? "#fff" : "#2F59F7",
                  }}
                  onClick={() => {
                    if (selectedFeatures.includes(name)) {
                      setSelectedFeatures(
                        selectedFeatures.filter((item) => item !== name)
                      );
                    } else {
                      setSelectedFeatures([...selectedFeatures, name]);
                    }
                  }}
                >
                  {name}
                </Button>
              </Row>
            ))}
          </Row>
        </Col>
      ),
    },
  ];

  // Handle next click
  const next = () => {
    setCurrent(current + 1);
  };

  // Handle previous click
  const prev = () => {
    setCurrent(current - 1);
  };

  // Handle skip click
  const handleSkip = () => {
    // If current step is not the last step, keep going to the next step
    if (current < steps.length - 1) {
      setCurrent(current + 1);
      return;
    }

    // Construct the onboarding data
    const onboardingData = {
      about: [
        {
          industry: selectedIndustry,
          department: selectedDepartment,
          purpose: selectedPurpose,
        },
      ],
      features: selectedFeatures,
    };

    // Make an API call to save the onboarding data
    saveUserOnboardingData(onboardingData)
      .then(() => {
        // If user.currentPlan is "AppSumo", go to dashboard
        if (user?.appSumoUser) {
          history.push(`/a/ws/${user.workspaceId}/dashboard`);
          return;
        }
        // Redirect to the /subscribe page
        history.push("/subscribe");
      })
      .catch((error) => {
        console.log("Save Onboarding Data Error: ", error);
      });
  };

  // Fire confetti when the last step is opened
  useEffect(() => {
    if (current === steps.length - 1) {
      fireConfetti();
    }
  }, [current, steps.length]);

  // Handle onboarding completion
  const handleOnboardingCompletion = () => {
    // Construct the onboarding data
    const onboardingData = {
      about: [
        {
          industry: selectedIndustry,
          department: selectedDepartment,
          purpose: selectedPurpose,
        },
      ],
      features: selectedFeatures,
    };

    // Make an API call to save the onboarding data
    saveUserOnboardingData(onboardingData)
      .then(() => {
        // If user.currentPlan is "AppSumo", go to dashboard
        if (user?.appSumoUser) {
          history.push("/dashboard");
          return;
        }
        // Redirect to the /subscribe page
        history.push("/subscribe");
      })
      .catch((error) => {
        console.log("Save Onboarding Data Error: ", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <img
        className="hp-logo hp-mb-32"
        src={logo}
        alt="logo"
        style={{
          height: 80,
          width: "auto",
          backgroundColor: "transparent",
        }}
      />
      <h2
        className="h1 hp-mb-32"
        style={{
          color: "#2F59F7",
          fontWeight: "500",
          fontSize: "2.5rem",
          lineHeight: "3rem",
        }}
      >
        Welcome to Conferbot
      </h2>

      {/* Steps Header */}
      <div className="hp-overflow-scroll hp-scrollbar-x-hidden">
        <Steps current={current}>
          {steps.map((index) => (
            <Step key={index} />
          ))}
        </Steps>
      </div>

      {/* Steps Content */}
      <div className="hp-text-center hp-p-80 hp-mt-6">
        {steps[current].content}
      </div>

      {/* Steps Footer */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "10px",
            bottom: "10px",
          }}
        >
          <Button
            size="small"
            style={{}}
            onClick={handleLogout}
            icon={
              <FiLogOut
                style={{
                  marginRight: "5px",
                }}
              />
            }
          >
            Logout
          </Button>
        </div>

        <div style={{ textAlign: "center" }}>
          {current > 0 && (
            <>
              <Button className="hp-mx-8" onClick={() => prev()}>
                Previous
              </Button>
              <>
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => handleOnboardingCompletion()}
                  >
                    Let's Go!
                  </Button>
                )}
              </>
            </>
          )}

          {current < steps.length - 1 && (
            <>
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            </>
          )}

          {/* Skip for now */}
          <p
            className="hp-text-underline hp-mt-16"
            onClick={handleSkip}
            style={{
              display: "block",
              marginTop: "10px",
              color: "#2F59F7",
              cursor: "pointer",
            }}
          >
            Skip for now
          </p>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
