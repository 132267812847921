import { CHATBOT_TYPES } from "../constants/chatbotTypes";

// Define the structure of your state
interface ChatbotState {
  currentChatbot: any | null;
  customizations: Record<string, any>;
  currentBotFlowData: any | null;
  templates: any[];
  categories: any[];
  currentFlowAnalytics: any | null;
  chatbotResponsesAnswerVariables: any[];
  currentFlowVersion: any | null;
  publishedVersionHistory: any[];
  sidebarCollapsed: boolean;
  paginationData?: any;
  showNotification?: any;
}

// Define the type for your action
interface ChatbotAction {
  type: string;
  payload: any;
}

// Initial state with type
const initialState: ChatbotState = {
  currentChatbot: null,
  customizations: {},
  currentBotFlowData: null,
  templates: [],
  categories: [],
  currentFlowAnalytics: null,
  chatbotResponsesAnswerVariables: [],
  currentFlowVersion: null,
  publishedVersionHistory: [],
  sidebarCollapsed: false,
  paginationData: {},
  showNotification: false,
};

// Reducer function with types
const chatbotReducer = (
  state = initialState,
  action: ChatbotAction
): ChatbotState => {
  switch (action.type) {
    // ** SET CURRENT BOT FLOW DATA ** //
    case CHATBOT_TYPES.CURRENT_BOT_FLOW_DATA:
      return {
        ...state,
        currentBotFlowData: action.payload,
        currentFlowAnalytics: null,
        currentFlowVersion: null,
      };

    // ** SET CURRENT CHATBOT ** //
    case CHATBOT_TYPES.CURRENT_CHATBOT:
      return {
        ...state,
        currentChatbot: action?.payload.currentChatbot,
        customizations: action?.payload.customizations,
        currentBotFlowData: action?.payload.currentBotFlowData,
        publishedVersionHistory: action?.payload?.publishedVersionHistory,
      };
    // ** TOGGLE NODES SIDEBAR ** //
    case CHATBOT_TYPES.TOGGLE_NODES_SIDEBAR:
      return {
        ...state,
        sidebarCollapsed: action?.payload,
      };

    // ** TEMPLATE PREVIEW ** //
    case CHATBOT_TYPES.TEMPLATE_PREVIEW:
      return {
        ...state,
        customizations: action?.payload.customizations,
        currentBotFlowData: action?.payload.currentBotFlowData,
      };

    // ** UPDATE CHATBOT CUSTOMIZATION ** //
    case CHATBOT_TYPES.UPDATE_CHATBOT_CUSTOMIZATIONS:
      return {
        ...state,
        customizations: {
          ...state.customizations,
          [action?.payload.key]: action?.payload.value,
        },
      };

    // ** GET CATEGORIES TEMPLATES ** //
    case CHATBOT_TYPES.GET_CATEGORIES_TEMPLATES:
      return {
        ...state,
        templates: action?.payload.templates,
        categories: action?.payload.categories,
        paginationData: action?.payload.paginationData,
      };

    // ** GET CATEGORIES TEMPLATES CHATBOT ** //
    case CHATBOT_TYPES.GET_CATEGORY_CHATBOTS:
      return {
        ...state,
        templates: action?.payload.templates,
        paginationData: action?.payload.paginationData,
      };

    // ** GET CHATBOT FLOW ANALYTICS ** //
    case CHATBOT_TYPES.GET_CHATBOT_FLOW_ANALYTICS:
      return {
        ...state,
        currentChatbot: action?.payload.currentChatbot,
        customizations: action?.payload.customizations,
        currentBotFlowData: action?.payload.currentBotFlowData,
        currentFlowAnalytics: action?.payload.currentFlowAnalytics,
        chatbotResponsesAnswerVariables: action?.payload.responses,
      };

    // ** GET CHATBOT RESPONSES ANSWER VARIABLES ** //
    case CHATBOT_TYPES.GET_CHATBOT_RESPONSES_ANSWER_VARIABLES:
      return {
        ...state,
        chatbotResponsesAnswerVariables: action?.payload,
      };

    // ** PUBLISH CHATBOT ** //
    case CHATBOT_TYPES.PUBLISH_CHATBOT:
      return {
        ...state,
        publishedVersionHistory: action?.payload,
      };

    // ** DELETE CHATBOT PUBLISHED FLOW VERSION ** //
    case CHATBOT_TYPES.DELETE_CHATBOT_PUBLISHED_FLOW_VERSION:
      return {
        ...state,
        publishedVersionHistory: action?.payload,
      };

    // ** GET CHATBOT PUBLISHED FLOW VERSION ** //
    case CHATBOT_TYPES.GET_CHATBOT_PUBLISHED_FLOW_VERSION:
      return {
        ...state,
        currentBotFlowData: action?.payload.currentBotFlowData,
        currentFlowVersion: action?.payload.currentFlowVersion,
      };

    // ** CLEAR CHATBOT STATES ** //
    case CHATBOT_TYPES.CLEAR_CHATBOT_STATES:
      return {
        ...state,
        currentChatbot: null,
        currentFlowAnalytics: null,
        chatbotResponsesAnswerVariables: [],
        currentFlowVersion: null,
      };

    // ** SHOW NOTIFICATION ** //
    case CHATBOT_TYPES.SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: action?.payload,
      };

    // ** DEFAULT ** //
    default:
      return state;
  }
};

export default chatbotReducer;
