import React, { useState, useEffect } from "react";
import { Drawer, Spin, Timeline, Typography, Tag, Divider } from "antd";
import { getMemberChatTicketById } from "@api/user/member";

const { Text, Title, Paragraph } = Typography;

const ChatTicket = ({ drawerVisible, setDrawerVisible, selectedChat }) => {
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);

  // Upon load or selected chat change, get ticket details
  useEffect(() => {
    if (selectedChat) {
      setLoading(true);

      // If ticket is present, get ticket details
      if (selectedChat.ticket) {
        getMemberChatTicketById(selectedChat.ticket)
          .then((res) => {
            setTicket(res.data.ticket);
            setLoading(false);
          })
          .catch((err) => {
            console.log("ChatTicket -> err", err);
            setLoading(false);
          });
      }
    }
  }, [selectedChat]);

  // Extract device information
  const extractDeviceInfo = (userAgent) => {
    let deviceInfo = {};

    // Extract browser information
    let browserMatch =
      userAgent?.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    let tempMatch;
    if (/trident/i.test(browserMatch[1])) {
      tempMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      deviceInfo.browser = `IE ${tempMatch[1]}`;
    } else if (browserMatch[1] === "Chrome") {
      tempMatch = userAgent?.match(/\bOPR|Edge\/(\d+)/);
      if (tempMatch != null) {
        deviceInfo.browser = `Opera ${tempMatch[1]}`;
      }
    }
    if (!deviceInfo.browser) {
      browserMatch = browserMatch[2]
        ? [browserMatch[1], browserMatch[2]]
        : [navigator.appName, navigator.appVersion, "-?"];
      tempMatch = userAgent?.match(/version\/(\d+)/i);
      if (tempMatch != null) {
        browserMatch.splice(1, 1, tempMatch[1]);
      }
      deviceInfo.browser = browserMatch.join(" ");
    }

    // Extract operating system information
    let osMatch = userAgent?.match(/(linux|macintosh|windows|android)/i);
    deviceInfo.os = osMatch && osMatch[0];

    return deviceInfo;
  };

  // Utility function to format date and time
  const formatDateAndTime = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options)
      .format(date)
      .replace(",", "");
  };

  // Render timeline items in chronological order
  const renderTimelineItems = () => {
    let events = [];

    // Add open time as an event
    if (ticket) {
      // metaData.chatDate contains the time the chat started, lets show that as well
      ticket.metaData &&
        events.push({
          label: `Chat Started`,
          description: (
            <>
              Chat started at{" "}
              <Text style={{ color: "blue" }}>
                {formatDateAndTime(ticket.response?.createdAt)}
              </Text>
            </>
          ),
          color: "green",
          timestamp: new Date(ticket.response?.createdAt).getTime(),
        });

      events.push({
        label: `Ticket Opened`,
        description: (
          <>
            Opened at{" "}
            <Text style={{ color: "blue" }}>
              {formatDateAndTime(ticket.openTime)}
            </Text>
          </>
        ),
        color: "green",
        timestamp: new Date(ticket.openTime).getTime(),
      });

      // Attempted Agents
      ticket.attemptedAgents.forEach((agent) => {
        events.push({
          label: "Agent Attempted",
          description: (
            <>
              Attempted connecting Agent{" "}
              <Text style={{ color: "blue" }}>{agent.agentId.name}</Text> at{" "}
              <Text style={{ color: "blue" }}>
                {formatDateAndTime(agent.attemptedAt)}
              </Text>
            </>
          ),
          color: "orange",
          timestamp: new Date(agent.attemptedAt).getTime(),
        });
      });

      // Add assigned agents
      ticket.assignedAgents.forEach((agent) => {
        events.push({
          label: `Agent Assigned`,
          description: (
            <>
              Agent <Text style={{ color: "blue" }}>{agent.agentId.name}</Text>{" "}
              was assigned the chat at{" "}
              <Text style={{ color: "blue" }}>
                {formatDateAndTime(agent.assignedAt)}
              </Text>
            </>
          ),
          color: "blue",
          timestamp: new Date(agent.assignedAt).getTime(),
        });
      });

      // Add invited agents
      ticket.invitedAgents.forEach((agent) => {
        events.push({
          label: `Agent Invited`,
          description: (
            <>
              Agent <Text style={{ color: "blue" }}>{agent.agentId.name}</Text>{" "}
              was invited by Agent{" "}
              <Text style={{ color: "blue" }}>{agent.invitedBy.name}</Text> at{" "}
              <Text style={{ color: "blue" }}>
                {formatDateAndTime(agent.invitedAt)}
              </Text>
            </>
          ),
          color: "purple",
          timestamp: new Date(agent.invitedAt).getTime(),
        });
      });

      // Add declined agents
      ticket.declinedAgents.forEach((agent) => {
        events.push({
          label: `Agent Declined`,
          description: (
            <>
              {/* If agent.invitedBy is present */}
              {agent.invitedBy ? (
                <>
                  Agent{" "}
                  <Text style={{ color: "blue" }}>{agent.agentId.name}</Text>{" "}
                  declined the invite sent by Agent{" "}
                  <Text style={{ color: "blue" }}>{agent.invitedBy.name}</Text>{" "}
                  at{" "}
                  <Text style={{ color: "blue" }}>
                    {formatDateAndTime(agent.declinedAt)}
                  </Text>
                </>
              ) : (
                <>
                  Agent{" "}
                  <Text style={{ color: "blue" }}>{agent.agentId.name}</Text>{" "}
                  declined the handover request at{" "}
                  <Text style={{ color: "blue" }}>
                    {formatDateAndTime(agent.declinedAt)}
                  </Text>
                </>
              )}
            </>
          ),
          color: "red",
          timestamp: new Date(agent.declinedAt).getTime(),
        });
      });

      // Add left agents
      ticket.leftAgents.forEach((agent) => {
        events.push({
          label: `Agent Left`,
          description: (
            <>
              Agent <Text style={{ color: "blue" }}>{agent.agentId.name}</Text>{" "}
              left the chat at{" "}
              <Text style={{ color: "blue" }}>
                {formatDateAndTime(agent.leftAt)}
              </Text>
            </>
          ),
          color: "red",
          timestamp: new Date(agent.leftAt).getTime(),
        });
      });

      // Add visitor disconnected event
      ticket.visitorDisconnected &&
        events.push({
          label: `Visitor Disconnected`,
          description: (
            <>
              Visitor{" "}
              <Text style={{ color: "blue" }}>
                #{ticket.visitorDisconnected?.visitorId}
              </Text>{" "}
              disconnected at{" "}
              <Text style={{ color: "blue" }}>
                {formatDateAndTime(ticket.visitorDisconnected?.time)}
              </Text>
            </>
          ),
          color: "red",
          timestamp: new Date(ticket.visitorDisconnected.time).getTime(),
        });

      // Ticket Closed
      if (ticket.status === "closed") {
        events.push({
          label: "Ticket Closed",
          description: (
            <>
              Closed by Agent{" "}
              <Text style={{ color: "blue" }}>{ticket.closedBy.name}</Text> at{" "}
              <Text style={{ color: "blue" }}>
                {formatDateAndTime(ticket.closeTime)}
              </Text>
            </>
          ),
          color: "red",
          timestamp: new Date(ticket.closeTime).getTime(),
        });
      }
    }

    // Sort events by timestamp
    events.sort((a, b) => a.timestamp - b.timestamp);

    // Map sorted events to Timeline.Item components
    return events?.map((event, index) => (
      <Timeline.Item key={index} color={event.color}>
        <Text strong>{event.label}</Text>
        <div>{event.description}</div>
      </Timeline.Item>
    ));
  };

  const renderStats = () => {
    if (!ticket) return null;

    const openTime = new Date(ticket.openTime);
    const closeTime = new Date(ticket.closeTime);
    const totalDuration = closeTime - openTime;

    // Convert milliseconds to a readable format
    const durationString = `${Math.floor(
      totalDuration / 3600000
    )}h ${Math.floor((totalDuration % 3600000) / 60000)}m ${Math.floor(
      (totalDuration % 60000) / 1000
    )}s`;

    return (
      <>
        {/* If duration string is not NaN */}
        {durationString !== "NaNh NaNm NaNs" && (
          <>
            <Title level={4} style={{ color: "darkblue", marginBottom: 16 }}>
              Ticket Stats
            </Title>
            <Paragraph>
              <Text>Total time to close ticket: </Text>
              <Tag color="geekblue">{durationString}</Tag>
            </Paragraph>
            {ticket?.assignedAgents?.map((agent, index) => {
              const assignedAt = new Date(agent.assignedAt);
              const timeToJoin = assignedAt - openTime;
              const joinDurationString = `${Math.floor(
                timeToJoin / 60000
              )}m ${Math.floor((timeToJoin % 60000) / 1000)}s`;
              return (
                <Paragraph
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8,
                    marginTop: 18,
                  }}
                >
                  <Text>
                    <Text style={{ color: "darkblue" }}>
                      {agent.agentId.name}
                    </Text>{" "}
                    joined after:{" "}
                  </Text>
                  <Tag color="purple" style={{ marginLeft: 8 }}>
                    {joinDurationString}
                  </Tag>
                </Paragraph>
              );
            })}
            <Divider />
          </>
        )}
        <Title level={4} style={{ color: "darkblue", marginBottom: 16 }}>
          Handover Details
        </Title>
        <Paragraph>
          <Text strong>Priority: </Text>
          <Tag color="orange">{ticket.handoverData.priority}</Tag>
        </Paragraph>
        <Paragraph>
          <Text strong>Max Wait Time: </Text>
          <Tag color="cyan">{ticket.handoverData.maxWaitTime} minutes</Tag>
        </Paragraph>
        <Paragraph>
          <Text strong>Assignment Type: </Text>
          <Tag color="green">{ticket.handoverData.assignmentType}</Tag>
        </Paragraph>

        {/* If assignment type is manual, then show assignedAgents list */}
        {ticket.handoverData.assignmentType === "manual" && (
          <Paragraph>
            <span style={{ display: "flex", alignItems: "center" }}>
              <Text strong>Allocated Agents: </Text>
              <div>
                {ticket?.handoverData?.assignedAgents?.map((agent, index) => (
                  <Tag color="volcano" key={index} style={{ margin: 4 }}>
                    {agent.name}
                  </Tag>
                ))}
              </div>
            </span>
          </Paragraph>
        )}
      </>
    );
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              padding: "0",
            }}
          >
            <Title level={4} style={{ color: "darkblue" }}>
              Ticket Overview
            </Title>
          </div>
        }
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={640}
      >
        {loading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(80vh - 50px)",
              overflow: "hidden",
              fontSize: "20px",
            }}
          />
        ) : (
          <>
            <Title level={4} style={{ color: "darkblue", marginBottom: 16 }}>
              Visitor Details
            </Title>
            <div style={{ marginBottom: 24 }}>
              <Paragraph>
                <Text strong>Status: </Text>
                <Tag color={ticket?.status === "closed" ? "volcano" : "green"}>
                  {ticket?.status.toUpperCase()}
                </Tag>
              </Paragraph>
              <Paragraph>
                <Text strong>Visitor ID: </Text>
                <Text style={{ color: "darkblue" }}>
                  {ticket?.metaData.visitorId}
                </Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Device Info: </Text>
                <Text style={{ color: "darkblue" }}>Browser</Text> -{" "}
                <Text code>
                  {extractDeviceInfo(ticket?.metaData.deviceInfo).browser}
                </Text>
                , <Text style={{ color: "darkblue" }}>OS</Text> -{" "}
                <Text code>
                  {extractDeviceInfo(ticket?.metaData.deviceInfo).os}
                </Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Location: </Text>
                <Text style={{ color: "darkblue" }}>
                  {ticket?.metaData.location}
                </Text>
              </Paragraph>
            </div>

            <Divider />

            {/* Ticket Timeline */}
            <div>
              <Title level={4} style={{ color: "darkblue", marginBottom: 16 }}>
                Ticket Timeline
              </Title>
              <Timeline mode="alternate">{renderTimelineItems()}</Timeline>
            </div>

            <Divider />

            {/* Ticket Stats */}
            {renderStats()}
          </>
        )}
      </Drawer>
    </>
  );
};

export default ChatTicket;
