import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getValue, setValue } from "@utils/localStorage";
import LanguageSelector from "@components/Language/languageSelector";

function ChangeLanguage() {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setValue("language", lang);
  };

  useEffect(() => {
    const fetchLanguage = async () => {
      const selectedLanguage = await getValue("language");

      if (selectedLanguage) {
        changeLanguage(selectedLanguage);
      }
    };

    fetchLanguage();
  }, []);

  return (
    <div style={{ padding: "30px" }}>
      <LanguageSelector />
    </div>
  );
}

export default ChangeLanguage;
