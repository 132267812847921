import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RoleBasedRedirectWithUser } from "../../utils/roleBasedRedirect";
import { useSelector } from "react-redux";
import "../../assets/css/LoadingToRedirect.css";

const LoadingToRedirect = () => {
  const { user } = useSelector((state: any) => state.auth);
  const [count, setCount] = useState(2);
  let history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currCount) => --currCount);
    }, 1000);
    // redirect once count is 0
    count === 0 && RoleBasedRedirectWithUser(user, history);
    // cleanup
    return () => clearInterval(interval);
  }, [count, history, user]);

  return (
    <>
      <div className="preloader-container">
        <div id="chatbot-dot">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <div id="chatbot-corner"></div>

        <div id="antenna">
          <div id="beam"></div>
          <div id="beam-pulsar"></div>
        </div>
      </div>
    </>
  );
};

export default LoadingToRedirect;
