import { FC, useEffect, useState } from "react";
import { Avatar, Input, List, Col, Divider } from "antd";
import {
  UserOutlined,
  MailOutlined,
  CalendarOutlined,
  MessageOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import useStore, { selector } from "../../../store/store";
import { shallow } from "zustand/shallow";
import "./style.css";
import { useTranslation } from "react-i18next";

interface SaveAnswerProps {
  id: any;
  type: any;
  variableName: any;
  questionId?: string; // this prop is optional
  optionId?: string; // this prop is optional
  choiceId?: string; // this prop is optional
  imageId?: string; // this prop is optional
  preChatQuestionId?: string; // this prop is optional
  postChatSurveyQuestionId?: string; // this prop is optional
}

const AvatarIcon = (
  <Avatar
    shape="circle"
    size={25}
    style={{
      marginRight: "10px",
      background: "linear-gradient(to right, #0059b2, #0073e6)",
    }}
    icon={
      <span
        style={{
          color: "#ffffff",
        }}
      >
        A
      </span>
    }
  />
);

const SaveAnswer: FC<SaveAnswerProps> = ({
  id,
  type,
  variableName,
  questionId,
  optionId,
  choiceId,
  imageId,
  preChatQuestionId,
  postChatSurveyQuestionId,
}) => {
  const { t } = useTranslation();

  const data = [
    {
      option: t("flowBuilder.sidebar.editor.saveAnswerVariable.name.option"),
      icon: <UserOutlined />,
      label: t("flowBuilder.sidebar.editor.saveAnswerVariable.name.label"),
    },
    {
      option: t("flowBuilder.sidebar.editor.saveAnswerVariable.email.option"),
      icon: <MailOutlined />,
      label: t("flowBuilder.sidebar.editor.saveAnswerVariable.email.label"),
    },
    {
      option: t("flowBuilder.sidebar.editor.saveAnswerVariable.phone.option"),
      icon: <MessageOutlined />,
      label: t("flowBuilder.sidebar.editor.saveAnswerVariable.phone.label"),
    },
    {
      option: t("flowBuilder.sidebar.editor.saveAnswerVariable.date.option"),
      icon: <CalendarOutlined />,
      label: t("flowBuilder.sidebar.editor.saveAnswerVariable.date.label"),
    },
  ];

  const [inputText, setInputText] = useState(variableName);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredVariables, setFilteredVariables] = useState(data);

  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const handleInputChange = (e: any) => {
    const text = e.target.value;
    setInputText(text?.toLowerCase());
    setShowDropdown(true);
  };

  useEffect(() => {
    if (inputText?.length > 2 || inputText === "") {
      if (questionId && state?.data?.questions) {
        // If a questionId was provided, update the correct question's answerVariable
        const updatedQuestions = state?.data?.questions.map((question: any) => {
          if (question.id === questionId) {
            return { ...question, answerVariable: inputText };
          }
          return question;
        });

        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            questions: updatedQuestions,
          },
        });
      } else if (preChatQuestionId && state?.data?.preChatQuestions) {
        // If a preChatQuestionId was provided, update the correct preChatQuestion's answerVariable
        const updatedPreChatQuestions = state?.data?.preChatQuestions.map(
          (question: any) => {
            if (question.id === preChatQuestionId) {
              return { ...question, answerVariable: inputText };
            }
            return question;
          }
        );

        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            preChatQuestions: updatedPreChatQuestions,
          },
        });
      } else if (
        postChatSurveyQuestionId &&
        state?.data?.postChatSurveyQuestions
      ) {
        // If a postChatSurveyQuestionId was provided, update the correct postChatSurveyQuestions's answerVariable
        const updatedPostChatSurveyQuestions =
          state?.data?.postChatSurveyQuestions.map((question: any) => {
            if (question.id === postChatSurveyQuestionId) {
              return { ...question, answerVariable: inputText };
            }
            return question;
          });

        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            postChatSurveyQuestions: updatedPostChatSurveyQuestions,
          },
        });
      } else {
        // If no questionId was provided, perform the default update
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            answerVariable: inputText,
          },
        });
      }
    }
  }, [inputText]);

  const handleVariableSelect = (variable: any) => {
    const variableLower = variable?.toLowerCase();
    setInputText(variableLower); // Ensure this updates the state

    if (questionId) {
      // If a questionId was provided, update the correct question's answerVariable
      if (state && state?.data && state?.data?.questions) {
        const updatedQuestions = state?.data?.questions?.map(
          (question: any) => {
            if (question.id === questionId) {
              return { ...question, answerVariable: variable?.toLowerCase() };
            }
            return question;
          }
        );

        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            questions: updatedQuestions,
          },
        });
      }
    } else if (preChatQuestionId) {
      // If a preChatQuestionId was provided, update the correct preChatQuestion's answerVariable
      if (state && state?.data && state?.data?.preChatQuestions) {
        const updatedPreChatQuestions = state?.data?.preChatQuestions?.map(
          (question: any) => {
            if (question.id === preChatQuestionId) {
              return { ...question, answerVariable: variable?.toLowerCase() };
            }
            return question;
          }
        );

        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            preChatQuestions: updatedPreChatQuestions,
          },
        });
      }
    } else if (postChatSurveyQuestionId) {
      // If a postChatSurveyQuestionId was provided, update the correct preChatQuestion's answerVariable
      if (state && state?.data && state?.data?.postChatSurveyQuestions) {
        const updatedPostChatSurveyQuestions =
          state?.data?.postChatSurveyQuestions?.map((question: any) => {
            if (question.id === postChatSurveyQuestionId) {
              return { ...question, answerVariable: variable?.toLowerCase() };
            }
            return question;
          });

        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            postChatSurveyQuestions: updatedPostChatSurveyQuestions,
          },
        });
      }
    } else {
      // If no questionId was provided, perform the default update
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          answerVariable: variable?.toLowerCase(),
        },
      });
    }
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 100);
  };

  const handleInputClick = () => {
    setShowDropdown(true);
  };

  useEffect(() => {
    const matches = data.filter((variable) =>
      variable?.option?.toLowerCase().includes(inputText?.toLowerCase())
    );
    setFilteredVariables(matches);
  }, [inputText]);

  return (
    <div
      style={{
        marginTop: "8px",
        marginBottom: "30px",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      <Divider orientation="left">
        <Col
          span={24}
          style={{ alignSelf: "start" }}
          className="sidebar__heading"
        >
          {t("flowBuilder.sidebar.editor.saveAnswerVariable.saveAnswer")}
        </Col>
      </Divider>

      <Input
        prefix={"@"}
        placeholder={t(
          "flowBuilder.sidebar.editor.saveAnswerVariable.saveAnswerPH"
        )}
        value={inputText}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onClick={handleInputClick}
        style={{ width: "100%", borderRadius: "3px", maxWidth: "100%" }}
      />

      {/* Default options */}
      {showDropdown && filteredVariables?.length > 0 && (
        <div className="matched-list">
          <List
            bordered
            dataSource={filteredVariables}
            style={{
              height: "100%",
              maxHeight: "160px",
              overflowY: "auto",
              borderRadius: "3px",
            }}
            renderItem={(item) => (
              <List.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleVariableSelect(item?.option);
                }}
                className={inputText === item.option ? "selected" : ""}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{AvatarIcon}</span>
                  {item?.option}
                </div>
                <span className="var__label"> {item?.label}</span>
              </List.Item>
            )}
          />
        </div>
      )}

      {/* If number characters is less than 2, show this */}
      {filteredVariables?.length === 0 && inputText?.length <= 2 && (
        <div className="unmatched-list">
          <List bordered style={{ borderRadius: "3px" }}>
            <List.Item>
              {t("flowBuilder.sidebar.editor.saveAnswerVariable.noMatchResult")}
            </List.Item>
          </List>
        </div>
      )}

      {/* Input typing options */}
      {showDropdown &&
        filteredVariables?.length === 0 &&
        inputText?.length > 2 && (
          <div className="matched-list-new">
            <List bordered style={{ borderRadius: "3px" }}>
              <List.Item>
                <PlusCircleOutlined style={{ marginRight: "8px" }} />
                {inputText}
                <span
                  style={{
                    fontStyle: "italic",
                    color: "#292727",
                    marginLeft: "8px",
                    borderRadius: "3px",
                  }}
                >
                  {t("flowBuilder.sidebar.editor.saveAnswerVariable.createNew")}
                </span>
              </List.Item>
            </List>
          </div>
        )}
    </div>
  );
};

export default SaveAnswer;
