import { Layout, Button, Row, Col } from "antd";
import { RiMenuFill } from "react-icons/ri";

import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import HeaderText from "./HeaderText";
import "./menuHeader.css";

const { Header } = Layout;

const MenuHeader = (props) => {
  const { setVisible } = props;

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
  };

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="hp-mobile-sidebar-button hp-mr-24">
          <Button
            className="hp-mobile-sidebar-button"
            type="text"
            onClick={showDrawer}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
              />
            }
          />
        </Col>

        {<HeaderText />}

        <Col>
          <Row align="middle">
            <HeaderNotifications />

            <HeaderUser />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        <Col span={24}>{headerChildren()}</Col>
      </Row>
    </Header>
  );
};

export default MenuHeader;
