import api from "../index";

// Create a subscription
export const createSubscription = async (data: any) => {
  return await api.post("/subscriptions", data);
};

// Manage a subscription
export const manageSubscription = async () => {
  return await api.get("/subscriptions/manage");
};

// Create an onboarding subscription
export const createOnboardingSubscription = async (data: any) => {
  return await api.post("/subscriptions/onboarding", data);
};

// Handle free plan subscription
export const handleFreePlanSubscription = async () => {
  return await api.post("/subscriptions/free-plan");
};

// Handle downgrade to free plan
export const handleDowngradeToFreePlan = async () => {
  return await api.post("/subscriptions/downgrade-to-free");
};
