// Admin Actions
export const MEMBER_TYPES = {
  // Dashboard
  GET_MEMBER_DASHBOARD_ANALYTICS: "GET_MEMBER_DASHBOARD_ANALYTICS",
  // Chatbot
  GET_MEMBER_CHATBOTS_NAME: "GET_MEMBER_CHATBOTS_NAME",
  // Chatbot Responses
  SELECTED_BOT_RESPONSES: "SELECTED_BOT_RESPONSES",
  UPDATE_SELECTED_BOT_RESPONSES: "UPDATE_SELECTED_BOT_RESPONSES",
  UPDATE_CHAT_WITH_LATEST_MESSAGE: "UPDATE_CHAT_WITH_LATEST_MESSAGE",
  UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES:
    "UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES",
  // Team
  GET_MEMBER_TEAM_LIST: "GET_MEMBER_TEAM_LIST",
  // Status
  UPDATE_MEMBER_STATUS: "UPDATE_MEMBER_STATUS",
  // Tickets
  GET_MEMBER_HANDLED_TICKETS: "GET_MEMBER_HANDLED_TICKETS",
  GET_MEMBER_UNHANDLED_TICKETS: "GET_MEMBER_UNHANDLED_TICKETS",
  // Notifications
  GET_MEMBER_NOTIFICATIONS: "GET_MEMBER_NOTIFICATIONS",
  CLEAR_MEMBER_NOTIFICATIONS: "CLEAR_MEMBER_NOTIFICATIONS",
  MARK_ALL_NOTIFICATIONS_AS_READ: "MARK_ALL_NOTIFICATIONS_AS_READ",
  // Subscription
  GET_MEMBER_SUBSCRIPTION: "GET_MEMBER_SUBSCRIPTION",
};
