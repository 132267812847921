import { Row, Col, Divider, Form, Input, Button } from "antd";
import { InfoNotification } from "../Notification/ToastNotification";
import { useTranslation } from "react-i18next";

const PasswordInfo = () => {
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <h2>{t("profile.plans.changePassword")}</h2>
        <p className="hp-p1-body hp-mb-0">
          {t("profile.plans.changePasswordInfo")}
        </p>

        <Divider className={dividerClass} />
      </Col>

      <Col xxl={5} xl={10} md={15} span={24}>
        <Form layout="vertical" name="basic">
          <Form.Item
            label={t("profile.plans.oldPassword")}
            className="hp-mb-8"
            rules={[
              {
                required: true,
                message: t("profile.plans.oldPasswordMessage"),
              },
            ]}
            name="old-password"
          >
            <Input.Password placeholder="Enter old password" id="validating" />
          </Form.Item>

          <Form.Item
            label={t("profile.plans.newPassword")}
            className="hp-mb-8"
            rules={[
              {
                required: true,
                message: t("profile.plans.newPasswordMessage"),
              },
            ]}
            name="new-password"
          >
            <Input.Password
              placeholder={t("profile.plans.newPasswordPH")}
              id="validating"
            />
          </Form.Item>

          <Form.Item
            label={t("profile.plans.confirmPassword")}
            className="hp-mb-8"
            rules={[
              {
                required: true,
                message: t("profile.plans.confirmPasswordMessage"),
              },
            ]}
            name="confirm-new-password"
          >
            <Input.Password
              placeholder={t("profile.plans.confirmPasswordPH")}
              id="validating"
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              onClick={() =>
                InfoNotification(t("profile.plans.changePasswordFromLoginPage"))
              }
            >
              {t("profile.plans.changePassword")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default PasswordInfo;
