import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Tooltip, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { AskNameNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
// translations
import { useTranslation } from "react-i18next";

const AskNameNodeDataEditor: FC<AskNameNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);
  const { t } = useTranslation();

  const [questionText, setQuestionText] = useState(state?.data?.questionText);
  const [nameGreetResponse, setNameGreetResponse] = useState(
    state?.data?.nameGreetResponse || `Nice meeting you, {name}`
  );
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setQuestionText(state?.data?.questionText);
    setNameGreetResponse(
      state?.data?.nameGreetResponse || `Nice meeting you, {name}`
    );
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  // Handle questionText change
  const handleQuestionTextChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the welcome message
      setQuestionText(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle handleNameGreetResponse change
  const handleNameGreetResponseChange = useCallback(
    (content: any) => {
      // Set the setNameGreetResponse
      setNameGreetResponse(content);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          nameGreetResponse: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.askQuestionNodes.askNameNodeInfo")}
          </p>
        </Row>

        {/* Question Text */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.askQuestionNodes.questionText")}
          </Col>
        </Divider>

        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={questionText}
              onEditorChange={handleQuestionTextChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.askQuestionNodes.questionTextPH"
              )}
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />

        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.askQuestionNodes.nameGreetResponse")}
          </Col>
        </Divider>

        <Col span={24}>
          <Tooltip
            placement="top"
            title={t("flowBuilder.sidebar.editor.askQuestionNodes.nameGreetTT")}
          >
            <div>
              <QuillEditor
                initialContent={nameGreetResponse}
                onEditorChange={handleNameGreetResponseChange}
                placeholderText={t(
                  "flowBuilder.sidebar.editor.askQuestionNodes.nameGreetPH"
                )}
              />
            </div>
          </Tooltip>
        </Col>
      </Row>
      <SaveAnswer id={id} type={type} variableName={answerVariable} />
    </>
  );
};

export default AskNameNodeDataEditor;
