import { useEffect, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { IntegrationsCard } from "@components";

import "@assets/css/Integrations.css";
import IntegrationData from "./IntegrationData";
// translations
import { useTranslation } from "react-i18next";

const IntegrationsList = () => {
  const { user } = useSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState("");

  const { CardData } = IntegrationData();

  // translations
  const { t } = useTranslation();

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredData(CardData);
    } else {
      const searchData = CardData.filter((item) => {
        return Object?.values(item.heading)
          ?.join("")
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
      setFilteredData(searchData);
    }
  }, [searchQuery]);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#ffffff",
        height: "100vh",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
      }}
    >
      <div>
        <div className="integration-heading">
          <div>
            <h1
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                margin: 0,
                color: "#2c3e50",
              }}
            >
              {t("integrations.integrationsMarket")}
            </h1>
            <p> {t("integrations.integrationsMarketInfo")}</p>
          </div>

          <span>
            <Input
              placeholder={t("integrations.seacrhAppsPH")}
              prefix={<SearchOutlined />}
              style={{ height: "39px", width: "302px" }}
              onChange={(e) => handleChange(e)}
            />
          </span>
        </div>
      </div>

      <IntegrationsCard
        CardData={filteredData}
        workspaceId={user.workspaceId}
      />
    </div>
  );
};

export default IntegrationsList;
