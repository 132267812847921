import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdDashboardCustomize, MdOutlineLineStyle } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const KnowledgeBaseLeft = ({ setCreateCategoryModal }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const { tab: paramTab } = useParams();
  const [tab, setTab] = useState(paramTab);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      location.pathname.endsWith("/create") ||
      location.pathname.endsWith("/update")
    ) {
      setTab("articles");
    } else {
      setTab(paramTab);
    }
  }, [location.pathname, paramTab]);

  // Handle menu click
  const handleMenuClick = (e) => {
    if (e.key === "create-article") {
      history.push(`/a/ws/${user.workspaceId}/knowledge-base/articles/create`);
    } else if (e.key === "create-category") {
      setCreateCategoryModal(true);
      history.push(`/a/ws/${user.workspaceId}/knowledge-base/categories`);
    }
  };

  // Handle tab change
  const handleTabChange = (tab) => {
    history.push(`/a/ws/${user.workspaceId}/knowledge-base/${tab}`);
  };

  // Dropdown menu's
  const menu = (
    <Menu onClick={handleMenuClick} mode="horizontal">
      <Menu.Item key="create-article" className="create-article">
        <MdOutlineLineStyle
          style={{ marginBottom: "-2px", marginRight: "3px" }}
        />{" "}
        {t("knowledgeBase.createArticle")}
      </Menu.Item>
      <Menu.Item key="create-category" className="create-category">
        <MdDashboardCustomize
          style={{ marginBottom: "-2px", marginRight: "3px" }}
        />{" "}
        {t("knowledgeBase.createCategory")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="knowledge-base-sidebar-main">
      <div className="sidebar-header">
        <h3> {t("knowledgeBase.knowledgeBase")}</h3>

        <Dropdown overlay={menu} trigger={["click"]} placement="bottom">
          <Button className="create-button">
            <IoAddCircleOutline style={{ marginRight: "5px" }} />
            {t("knowledgeBase.create")}
          </Button>
        </Dropdown>
      </div>
      <div className="sidebar-options">
        {/* Articles */}
        <div
          className="sidebar-option articles-tab"
          onClick={() => handleTabChange("articles")}
          style={{
            backgroundColor: tab === "articles" ? "#0b4eb8" : "#eee",
            color: tab === "articles" ? "#fff" : "#000",
          }}
        >
          <MdOutlineLineStyle /> {t("knowledgeBase.articles")}
        </div>
        {/* Categories */}
        <div
          className="sidebar-option categories-tab"
          onClick={() => handleTabChange("categories")}
          style={{
            backgroundColor:
              tab === "categories" || tab === "createCategory"
                ? "#0b4eb8"
                : "#eee",
            color:
              tab === "categories" || tab === "createCategory"
                ? "#fff"
                : "#000",
          }}
        >
          <MdDashboardCustomize /> {t("knowledgeBase.categories")}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBaseLeft;
