import React from "react";
import DOMPurify from "dompurify";
import { ChatDate, BotMessageIcon } from "./subComponents";
import { DownloadOutlined } from "@ant-design/icons";

const Messages = ({ selectedChat, selectedBotName }) => {
  // A helper function to safely parse HTML and extract paragraph elements
  const createParagraphBubbles = (htmlString, singleBubble = false) => {
    const cleanHtmlString = DOMPurify.sanitize(htmlString);
    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanHtmlString, "text/html");

    // Check if the parsed content is just text
    if (!doc.body.children.length && doc.body.textContent.trim()) {
      const textDiv = document.createElement("div");
      textDiv.textContent = doc.body.textContent.trim();
      textDiv.style.margin = "0"; // Set margin to 0
      return [textDiv];
    }

    // If singleBubble is true, return a single bubble with all content
    if (singleBubble) {
      const allContent = doc.body.innerHTML; // Get all inner content as a single string
      const div = document.createElement("div"); // Create a div to hold the content
      div.innerHTML = allContent; // Set the inner HTML of the div
      div.style.margin = "0"; // Set margin to 0
      return [div]; // Return an array with the single div as its item
    }

    // Otherwise, process paragraphs individually
    doc.querySelectorAll("br").forEach((br) => br.remove());
    const paragraphs = Array.from(doc.body.querySelectorAll("p"));
    const nonEmptyParagraphs = paragraphs.filter(
      (p) => p.textContent.trim() !== ""
    );

    // Return non-empty paragraphs as an array with margin set to 0
    return nonEmptyParagraphs.map((p) => {
      p.style.margin = "0"; // Set margin to 0 for each paragraph
      return p;
    });
  };

  // A helper function to safely parse HTML and extract paragraph elements
  const createAgentParagraphBubbles = (htmlString, splitByLine = false) => {
    const cleanHtmlString = DOMPurify.sanitize(htmlString);
    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanHtmlString, "text/html");

    // Remove the last <p> tag if it only contains <br>
    const lastChild = doc.body.lastElementChild;
    if (
      lastChild &&
      lastChild.nodeName.toLowerCase() === "p" &&
      lastChild.innerHTML.trim() === "<br>"
    ) {
      lastChild.remove();
    }

    // Handle text content without HTML tags differently
    if (!doc.body.children.length && doc.body.textContent.trim()) {
      if (splitByLine) {
        // Split by line breaks and return individual divs for each line
        return doc.body.textContent
          .trim()
          .split("\n")
          .filter((line) => line.trim() !== "") // Filter out empty lines
          .map((line) => {
            const div = document.createElement("div");
            div.textContent = line;
            return div;
          });
      } else {
        // Return a single div containing all text
        const div = document.createElement("div");
        div.textContent = doc.body.textContent.trim();
        return [div];
      }
    }

    // Handling HTML content with tags
    if (splitByLine) {
      // Split content into lines/bubbles based on <p> or <br>
      const lines = [];
      const childNodes = Array.from(doc.body.childNodes);
      childNodes.forEach((node) => {
        if (
          node.nodeName.toLowerCase() === "p" ||
          node.nodeName.toLowerCase() === "#text"
        ) {
          const div = document.createElement("div");
          div.innerHTML = node.outerHTML || node.textContent;
          lines.push(div);
        }
      });
      return lines;
    } else {
      // Return all content in a single bubble
      const div = document.createElement("div");
      div.innerHTML = doc.body.innerHTML;
      return [div];
    }
  };

  return (
    <>
      {Object.keys(selectedChat)?.length > 0 && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Chat Date */}
          <div className="container-hr">
            <hr className="hr-line" />
            <span className="centered-text">
              {Object.keys(selectedChat).length > 0 && (
                <ChatDate dateTime={selectedChat.chatDate} />
              )}
            </span>
            <hr className="hr-line" />
          </div>
        </div>
      )}

      {Object.keys(selectedChat).length > 0 &&
        selectedChat?.record?.map((msg, index) => (
          <React.Fragment key={index}>
            <div id="messagesWrapper">
              {/* ********************* LEGACY (V1 NODES DEPRECATED) ********************* */}
              {/* ********** Two Choices Node (DEPRECATED) ********** */}
              {(msg?.shape === "two-choices-node" ||
                msg?.type === "two-choices-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.choicePrompt || ""
                        ).map((paragraph, index) => (
                          <div key={index} className="chatbot-message">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {createParagraphBubbles(
                        msg?.data?.choiceText1 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ marginRight: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {!msg?.data?.disableSecondChoice && (
                        <>
                          {createParagraphBubbles(
                            msg?.data?.choiceText2 || "",
                            true
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ marginRight: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Three Choices Node (DEPRECATED) ********** */}
              {(msg?.shape === "three-choices-node" ||
                msg?.type === "three-choices-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.choicePrompt || ""
                        ).map((paragraph, index) => (
                          <div key={index} className="chatbot-message">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {createParagraphBubbles(
                        msg?.data?.choiceText1 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ marginRight: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {createParagraphBubbles(
                        msg?.data?.choiceText2 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ marginRight: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {createParagraphBubbles(
                        msg?.data?.choiceText3 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ marginRight: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Select Option Node (DEPRECATED) ********** */}
              {(msg?.shape === "select-option-node" ||
                msg?.type === "select-option-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.selectPrompt || ""
                        ).map((paragraph, index) => (
                          <div key={index} className="chatbot-message">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                      {createParagraphBubbles(
                        msg?.data?.option1 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ margin: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {createParagraphBubbles(
                        msg?.data?.option2 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ margin: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {!msg?.data?.disableOption3 && (
                        <>
                          {createParagraphBubbles(
                            msg?.data?.option3 || "",
                            true
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}

                      {!msg?.data?.disableOption4 && (
                        <>
                          {createParagraphBubbles(
                            msg?.data?.option4 || "",
                            true
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}

                      {!msg?.data?.disableOption5 && (
                        <>
                          {createParagraphBubbles(
                            msg?.data?.option5 || "",
                            true
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Input Node (DEPRECATED) ********** */}
              {(msg?.shape === "user-input-node" ||
                msg?.type === "user-input-node") &&
                msg?.type !== "file" && (
                  <>
                    <div className="bot-message-container">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <BotMessageIcon name={selectedBotName} />
                        </div>
                        <div
                          style={{
                            marginLeft: "20px",
                            marginTop: msg?.time ? "-15px" : "-5px",
                          }}
                        >
                          {msg?.time && (
                            <p
                              style={{ marginBottom: "5px", fontSize: "12px" }}
                            >
                              <ChatDate dateTime={msg?.time} />
                            </p>
                          )}

                          {createParagraphBubbles(
                            msg?.data?.inputPrompt || ""
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {/* ********** User Input Response (DEPRECATED) ********** */}
              {(msg?.shape === "user-input-response" ||
                msg?.type === "user-input-response") &&
                msg?.type !== "file" && (
                  <>
                    <div
                      className="user-message-container"
                      style={{ marginLeft: "10px" }}
                    >
                      {msg?.time && (
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "11px",
                            fontStyle: "italic",
                          }}
                        >
                          <ChatDate dateTime={msg?.time} />
                        </p>
                      )}
                      <div className="chatbot-user-message">{msg?.text}</div>
                    </div>
                  </>
                )}

              {/* ********** User Input Node (DEPRECATED) ********** */}
              {(msg?.shape === "user-input-node" ||
                msg?.type === "user-input-node") &&
                msg?.type === "file" && (
                  <>
                    <div className="bot-message-container">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <BotMessageIcon name={selectedBotName} />
                        </div>
                        <div
                          style={{
                            marginLeft: "20px",
                            marginTop: msg?.time ? "-15px" : "-5px",
                          }}
                        >
                          {msg?.time && (
                            <p
                              style={{ marginBottom: "5px", fontSize: "12px" }}
                            >
                              <ChatDate dateTime={msg?.time} />
                            </p>
                          )}

                          {createParagraphBubbles(
                            msg?.data?.inputPrompt || ""
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {/* ********** User Input Response (DEPRECATED) ********** */}
              {(msg?.shape === "user-input-response" ||
                msg?.type === "user-input-response") &&
                msg?.type === "file" && (
                  <>
                    <div
                      className="user-message-container"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        window.open(msg?.url, "_blank");
                      }}
                    >
                      {msg?.time && (
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "11px",
                            fontStyle: "italic",
                          }}
                        >
                          <ChatDate dateTime={msg?.time} />
                        </p>
                      )}
                      <div className="chatbot-user-message">{msg?.url}</div>
                    </div>
                  </>
                )}

              {/* ********** User Range Input Node (DEPRECATED) ********** */}
              {(msg?.shape === "user-range-node" ||
                msg?.type === "user-range-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.rangePrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                      {createParagraphBubbles(
                        msg?.data?.minVal || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ margin: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {createParagraphBubbles(
                        msg?.data?.maxVal || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ margin: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Input Range Response (DEPRECATED) ********** */}
              {(msg?.shape === "user-input-response-range" ||
                msg?.type === "user-input-response-range") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">{msg?.text}</div>
                  </div>
                </>
              )}

              {/* ********** User Rating Node (DEPRECATED) ********** */}
              {(msg?.shape === "user-rating-node" ||
                msg?.type === "user-rating-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.ratingPrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      marginLeft: "50px",
                      marginTop: "20px",
                    }}
                  >
                    {/* If rating type is number  */}
                    {msg?.data?.type === "number" &&
                      [...Array(Number(msg?.data?.range))].map((_, i) => {
                        const ratingValue = i + 1;
                        return (
                          <div
                            key={i}
                            className="message-wrapper"
                            style={{
                              borderRadius: "5px 5px 5px 0px",
                              backgroundColor: "#c2d1ff",
                              marginRight: "10px",
                              color: "#111111",
                            }}
                          >
                            {ratingValue}
                          </div>
                        );
                      })}

                    {/* If rating type is star */}
                    {msg?.data?.type === "star" &&
                      [...Array(Number(5))].map((_, i) => {
                        const ratingValue = i + 1;
                        return (
                          <div
                            key={i}
                            className="message-wrapper"
                            style={{
                              borderRadius: "5px 5px 5px 0px",
                              backgroundColor: "#c2d1ff",
                              marginRight: "10px",
                            }}
                          >
                            {[...Array(ratingValue)].map((_, idx) => (
                              <span
                                key={idx}
                                style={{
                                  fontSize: "100%",
                                  color: "yellow",
                                  marginRight: "4px",
                                }}
                              >
                                ⭐
                              </span>
                            ))}
                          </div>
                        );
                      })}

                    {/* If rating type is smiley */}
                    {msg?.data?.type === "smiley" &&
                      [...Array(Number(5))].map((_, i) => {
                        const ratingValue = i + 1;
                        let emoji;

                        switch (ratingValue) {
                          case 1:
                            emoji = "🙁";
                            break;
                          case 2:
                            emoji = "😐";
                            break;
                          case 3:
                            emoji = "🙂";
                            break;
                          case 4:
                            emoji = "😊";
                            break;
                          case 5:
                            emoji = "🤩";
                            break;
                          default:
                            emoji = "";
                        }

                        return (
                          <div
                            key={i}
                            className="message-wrapper"
                            style={{
                              borderRadius: "5px 5px 5px 0px",
                              backgroundColor: "#c2d1ff",
                              marginRight: "10px",
                            }}
                          >
                            {emoji}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}

              {/* ********** User Selected Rating (DEPRECATED) ********** */}
              {(msg?.shape === "user-selected-rating" ||
                msg?.type === "user-selected-rating") && (
                <>
                  {msg?.type === "number" && (
                    <div
                      className="user-message-container"
                      style={{ marginLeft: "10px" }}
                    >
                      {msg?.time && (
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "11px",
                            fontStyle: "italic",
                          }}
                        >
                          <ChatDate dateTime={msg?.time} />
                        </p>
                      )}
                      <div className="chatbot-user-message">{msg?.rating}</div>
                    </div>
                  )}

                  {msg?.type === "star" && (
                    <div
                      className="message-wrapper"
                      style={{
                        borderRadius: "5px 5px 5px 0px",
                        backgroundColor: "#c2d1ff",
                        marginRight: "10px",
                        marginTop: "20px",
                      }}
                    >
                      {[...Array(msg?.rating)].map((_, idx) => (
                        <span
                          key={idx}
                          style={{
                            fontSize: "100%",
                            color: "yellow",
                            marginRight: "4px",
                          }}
                        >
                          ⭐
                        </span>
                      ))}
                    </div>
                  )}

                  {msg?.type === "smiley" && (
                    <div
                      className="user-message-container"
                      style={{ marginLeft: "10px" }}
                    >
                      <div className="chatbot-user-message">
                        {(() => {
                          switch (msg?.rating) {
                            case 1:
                              return "🙁";
                            case 2:
                              return "😐";
                            case 3:
                              return "🙂";
                            case 4:
                              return "😊";
                            case 5:
                              return "🤩";
                            default:
                              return;
                          }
                        })()}
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* ********** Quiz Node (DEPRECATED) ********** */}
              {(msg?.shape === "quiz-node" || msg?.type === "quiz-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.quizPrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                      {createParagraphBubbles(
                        msg?.data?.option1 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ margin: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {createParagraphBubbles(
                        msg?.data?.option2 || "",
                        true
                      ).map((paragraph, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ margin: "5px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paragraph.outerHTML,
                            }}
                          />
                        </div>
                      ))}

                      {!msg?.data?.disableOption3 && (
                        <>
                          {createParagraphBubbles(
                            msg?.data?.option3 || "",
                            true
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}

                      {!msg?.data?.disableOption4 && (
                        <>
                          {createParagraphBubbles(
                            msg?.data?.option4 || "",
                            true
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}

                      {!msg?.data?.disableOption5 && (
                        <>
                          {createParagraphBubbles(
                            msg?.data?.option5 || "",
                            true
                          ).map((paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Quiz Option (DEPRECATED) ********** */}
              {(msg?.shape === "user-selected-quiz-option" ||
                msg?.type === "user-selected-quiz-option") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    <div className="chatbot-user-message">
                      {msg?.selectedOption}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Quiz Score Option (DEPRECATED) ********** */}
              {(msg?.shape === "user-score-count-message" ||
                msg?.type === "user-score-count-message") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        <div className="chatbot-message">{msg?.count}</div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********************* (V2 NODES) ********************* */}
              {/* ********************* STARTER NODE ********************* */}
              {/* ********** Welcome Node ********** */}
              {(msg?.shape === "welcome-node" ||
                msg?.type === "welcome-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(msg?.data?.text || "").map(
                          (paragraph, index) => (
                            <div key={index} className="chatbot-message">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  {!msg.data.disableImage && (
                    <div
                      className="message-wrapper"
                      style={{
                        marginLeft: "50px",
                        borderRadius: "15px 15px 15px 0px",
                      }}
                    >
                      <img
                        src={msg?.data?.image}
                        alt="welcome node"
                        style={{
                          width: "200px",
                          borderRadius: "15px 15px 15px 0px",
                        }}
                      />
                    </div>
                  )}
                </>
              )}

              {/* ********************* SEND A RESPONSE NODES ********************* */}
              {/* ********** Message Node ********** */}
              {(msg?.shape === "message-node" ||
                msg?.type === "message-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(msg?.data?.text || "").map(
                          (paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** Image Node ********** */}
              {(msg?.shape === "image-node" || msg?.type === "image-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        <div
                          className="message-wrapper"
                          style={{
                            borderRadius: "5px 5px 5px 0px",
                            width: "40%",
                          }}
                        >
                          <img
                            src={msg?.data?.image}
                            alt="response node"
                            style={{
                              borderRadius: "5px 5px 5px 0px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** Video Node ********** */}
              {(msg?.shape === "video-node" || msg?.type === "video-node") && (
                <div className="bot-message-container">
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BotMessageIcon name={selectedBotName} />
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                        marginTop: msg?.time ? "-15px" : "-5px",
                      }}
                    >
                      {msg?.time && (
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "11px",
                            fontStyle: "italic",
                          }}
                        >
                          <ChatDate dateTime={msg?.time} />
                        </p>
                      )}
                      <div
                        className="message-wrapper"
                        style={{
                          borderRadius: "5px 5px 5px 0px",
                        }}
                      >
                        <video
                          src={msg?.data?.video}
                          autoPlay
                          loop
                          muted
                          playsInline
                          style={{
                            objectFit: "cover",
                            borderRadius: "5px",
                            paddingBottom: "12px",
                            maxWidth: "400px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ********** Audio Node ********** */}
              {(msg?.shape === "audio-node" || msg?.type === "audio-node") && (
                <div className="bot-message-container">
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BotMessageIcon name={selectedBotName} />
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                        marginTop: msg?.time ? "-15px" : "-5px",
                      }}
                    >
                      {msg?.time && (
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "11px",
                            fontStyle: "italic",
                          }}
                        >
                          <ChatDate dateTime={msg?.time} />
                        </p>
                      )}
                      <div
                        className="message-wrapper"
                        style={{
                          borderRadius: "5px 5px 5px 0px",
                          border: "none",
                        }}
                      >
                        <audio
                          src={msg?.data?.audio}
                          controls
                          style={{
                            objectFit: "cover",
                            maxWidth: "400px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ********** File Node ********** */}
              {(msg?.shape === "file-node" || msg?.type === "file-node") && (
                <div className="bot-message-container">
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BotMessageIcon name={selectedBotName} />
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                        marginTop: msg?.time ? "-15px" : "-5px",
                      }}
                    >
                      {msg?.time && (
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "11px",
                            fontStyle: "italic",
                          }}
                        >
                          <ChatDate dateTime={msg?.time} />
                        </p>
                      )}
                      <div
                        className="chatbot-message"
                        style={{
                          border: "none",
                          margin: "5px",
                        }}
                      >
                        <a
                          href={msg?.data?.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* Display the file name and an icon or text indicating it's downloadable */}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: "8px" }}>
                              {msg?.data?.file.split("/").pop()}
                            </span>

                            <svg
                              width="12"
                              height="12"
                              style={{ marginLeft: "8px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 304 384"
                            >
                              <path
                                fill="currentColor"
                                d="M299 128L149 277L0 128h85V0h128v128zM0 320h299v43H0z"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ********************* ASK A QUESTION NODES ********************* */}
              {/* ********** Ask X Nodes ********** */}
              {(msg?.type === "ask-name-node" ||
                msg?.type === "ask-email-node" ||
                msg?.type === "ask-phone-number-node" ||
                msg?.type === "ask-number-node" ||
                msg?.type === "ask-url-node" ||
                msg?.type === "ask-custom-question-node" ||
                msg?.type === "ask-location-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.questionText || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Ask X Input Response ********** */}
              {(msg?.shape === "user-ask-name-response" ||
                msg?.shape === "user-ask-email-response" ||
                msg?.shape === "user-ask-phone-number-response" ||
                msg?.shape === "user-ask-number-response" ||
                msg?.shape === "user-ask-url-response" ||
                msg?.shape === "user-ask-custom-question-response" ||
                msg?.shape === "user-ask-location-response") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.text?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Ask Name Response Greet ********** */}
              {msg?.shape === "ask-name-response-greet" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(msg?.text || "").map(
                          (paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** Ask File Node ********** */}
              {msg?.type === "ask-file-node" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        {msg?.data?.questionText && (
                          <>
                            {createParagraphBubbles(
                              msg?.data?.questionText || ""
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Ask File Input Response ********** */}
              {msg?.shape === "user-ask-file-response" && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}

                    <a
                      className="chatbot-user-message"
                      href={msg?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {msg?.fileName}
                    </a>
                  </div>
                </>
              )}

              {/* ********** Ask Multiple Questions Node ********** */}
              {msg?.type === "ask-multiple-questions-node" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        {msg?.data?.message && (
                          <>
                            {createParagraphBubbles(
                              msg?.data?.message || ""
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** Ask Multiple Questions Question ********** */}
              {msg?.shape === "ask-multiple-questions-question" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        {msg?.questionText && (
                          <>
                            {createParagraphBubbles(
                              msg?.questionText || ""
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Ask Multiple Questions Question Response ********** */}
              {msg?.shape ===
                "user-ask-multiple-questions-question-response" && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.text?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Calendar Node ********** */}
              {(msg?.shape === "calendar-node" ||
                msg?.type === "calendar-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.calendarPrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Calendar Response ********** */}
              {(msg?.shape === "user-calendar-selection" ||
                msg?.type === "user-calendar-selection") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.data?.date}
                    </div>
                    {msg?.data?.showTimeSelection && (
                      <div className="chatbot-user-message">
                        {msg?.data?.timeSlotSelected?.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* ********** N Select Option Node ********** */}
              {(msg?.shape === "n-select-option-node" ||
                msg?.type === "n-select-option-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.selectPrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {msg?.data.options
                        .filter((option) => option.optionText?.trim() !== "")
                        .map((option, _) => (
                          <>
                            {createParagraphBubbles(
                              option.optionText || "",
                              true
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Option ********** */}
              {(msg?.shape === "user-selected-option" ||
                msg?.type === "user-selected-option") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.selectedOption?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* ********** N Check Options Node ********** */}
              {(msg?.shape === "n-check-options-node" ||
                msg?.type === "n-check-options-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.checkOptionsPrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {msg?.data.options
                        .filter((option) => option.optionText?.trim() !== "")
                        .map((option, _) => (
                          <>
                            {createParagraphBubbles(
                              option.optionText || "",
                              true
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Check Options ********** */}
              {(msg?.shape === "user-selected-check-options" ||
                msg?.type === "user-selected-check-options") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.selectedCheckOptions?.replace(
                        /<\/?[^>]+(>|$)/g,
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********************* FLOW OPERATION NODES ********************* */}
              {/* ********** N Choices Node ********** */}
              {(msg?.shape === "n-choices-node" ||
                msg?.type === "n-choices-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.choicePrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {msg?.data.choices
                        .filter((choice) => choice.choiceText?.trim() !== "")
                        .map((choice, _) => (
                          <>
                            {createParagraphBubbles(
                              choice.choiceText || "",
                              true
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Choice ********** */}
              {(msg?.shape === "user-selected-choice" ||
                msg?.type === "user-selected-choice") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.selectedChoice?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Image Choice Node ********** */}
              {msg?.type === "image-choice-node" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        {msg?.data?.questionText && (
                          <>
                            {createParagraphBubbles(
                              msg?.data?.questionText || ""
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {msg?.data.images
                        .filter(
                          (image) =>
                            image.image?.trim() !== "" &&
                            image.label?.trim() !== ""
                        )
                        .map((image, _) => (
                          <>
                            {createParagraphBubbles(image.label || "").map(
                              (paragraph, index) => (
                                <div
                                  key={index}
                                  className="chatbot-message"
                                  style={{ margin: "5px" }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: paragraph.outerHTML,
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Image Choice ********** */}
              {msg?.shape === "user-selected-image-choice" && (
                <>
                  <div
                    className="user-message-container"
                    style={{
                      marginLeft: "10px",
                      borderRadius: "15px 15px 15px 0px",
                    }}
                  >
                    <img
                      className="chatbot-user-message"
                      src={msg?.selectedImage}
                      alt="choice"
                      style={{
                        width: "200px",
                      }}
                    />
                  </div>

                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.selectedImageLabel?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Rating Choice Node ********** */}
              {msg?.type === "rating-choice-node" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.questionText || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {Array.from(
                        {
                          length:
                            msg?.data?.ratingType === "smiley"
                              ? 5
                              : Number(msg?.data?.ratingType),
                        },
                        (_, i) => (
                          <div
                            key={i}
                            className="chatbot-message"
                            style={{ marginRight: "5px" }}
                          >
                            {msg?.data?.ratingType === "smiley"
                              ? ["😡", "😐", "😊", "😀", "😁"][i]
                              : Array(i + 1)
                                  .fill("⭐")
                                  .join("")}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Rating Choice ********** */}
              {msg?.shape === "user-selected-rating-choice" && (
                <>
                  <div
                    className="user-message-container"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-rating-message">
                      {msg?.selectedRatingChoice}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Yes-No Choice Node ********** */}
              {msg?.type === "yes-or-no-choice-node" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.questionText || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {msg?.data?.options.map((option, _) => (
                        <>
                          {createParagraphBubbles(option.label || "", true).map(
                            (paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{ margin: "5px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            )
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Yes-No Choice ********** */}
              {msg?.shape === "user-selected-yes-or-no-choice" && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.selectedYesOrNoChoice?.replace(
                        /<\/?[^>]+(>|$)/g,
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Opinion Scale Choice Node ********** */}
              {msg?.type === "opinion-scale-choice-node" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.questionText || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {Array.from(
                        { length: msg?.data?.to - msg?.data?.from + 1 },
                        (_, i) => msg?.data?.from + i
                      ).map((choice, index) => (
                        <div
                          key={index}
                          className="chatbot-message"
                          style={{ marginRight: "5px" }}
                        >
                          {index === 0
                            ? `${choice} - ${
                                msg?.data?.fromLabel?.replace(
                                  /<\/?[^>]+(>|$)/g,
                                  ""
                                ) || "Worst"
                              }`
                            : index === msg?.data?.to - msg?.data?.from
                            ? `${choice} - ${
                                msg?.data?.toLabel?.replace(
                                  /<\/?[^>]+(>|$)/g,
                                  ""
                                ) || "Best"
                              }`
                            : choice}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Selected Opinion Scale Choice ********** */}
              {msg?.shape === "user-selected-opinion-scale-choice" && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.selectedOpinionScaleChoice?.replace(
                        /<\/?[^>]+(>|$)/g,
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********************* SPECIAL NODES ********************* */}
              {/* ********** HTML Node ********** */}
              {(msg?.shape === "html-node" || msg?.type === "html-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        <div
                          className="chatbot-message"
                          dangerouslySetInnerHTML={{ __html: msg?.data?.html }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Redirect Node ********** */}
              {(msg?.shape === "user-redirect-node" ||
                msg?.type === "user-redirect-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}
                        <div className="chatbot-message">
                          <a
                            href={msg?.data?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {createParagraphBubbles(msg?.data?.link || "").map(
                              (paragraph, index) => (
                                <div
                                  key={index}
                                  className="chatbot-message"
                                  style={{ margin: "5px" }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: paragraph.outerHTML,
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** Navigate Node ********** */}
              {(msg?.shape === "navigate-node" ||
                msg?.type === "navigate-node") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.data?.navigatePrompt || ""
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bot-message-container">
                    <div style={{ marginLeft: "50px" }}>
                      {msg?.data.navigateOptions
                        .filter((option) => option.navigateText?.trim() !== "")
                        .map((option, _) => (
                          <>
                            {createParagraphBubbles(
                              option.navigateText || "",
                              true
                            ).map((paragraph, index) => (
                              <div
                                key={index}
                                className="chatbot-message"
                                style={{
                                  margin: "5px",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  color: "#0647a6",
                                }}
                                onClick={() => {
                                  window.open(option.navigateLink, "_blank");
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph.outerHTML,
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Human Handover Node ********** */}
              {/* Pre-Chat Question */}
              {msg?.shape === "pre-chat-question" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(msg?.text || "").map(
                          (paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Pre-Chat Question Response */}
              {msg?.shape === "pre-chat-question-response" && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.text?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* Post-Chat Survey Question */}
              {msg?.shape === "post-chat-survey-question" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(msg?.text || "").map(
                          (paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Pre-Chat Question Response */}
              {msg?.shape === "post-chat-survey-question-response" && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.text?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* ********************* LIVE CHAT ********************* */}
              {/* ********** Agent Joined Message ********** */}
              {(msg?.shape === "agent-joined-message" ||
                msg?.type === "agent-joined-message") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#0647a6",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#0647a6",
                      borderRadius: "10px",
                      padding: "10px",
                      textAlign: "center",
                      boxShadow: "0px 2px 20px rgba(0,0,0,0.2)",
                      maxWidth: "60%",
                      color: "#ffffff",
                    }}
                  >
                    {msg?.name
                      ? `${msg.name.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )} has joined this chat`
                      : "Agent has joined this chat"}
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#0647a6",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
              )}

              {/* ********** Agent Chat Ended ********** */}
              {(msg?.shape === "agent-chat-ended" ||
                msg?.type === "agent-chat-ended") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#0647a6",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#0647a6",
                      borderRadius: "10px",
                      padding: "10px",
                      textAlign: "center",
                      boxShadow: "0px 2px 20px rgba(0,0,0,0.2)",
                      maxWidth: "60%",
                      color: "#ffffff",
                    }}
                  >
                    <span
                      style={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Chat has ended
                    </span>
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#0647a6",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
              )}

              {/* ********** Agent Left Chat ********** */}
              {(msg?.shape === "agent-left-chat" ||
                msg?.type === "agent-left-chat") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#f06809",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#f06809",
                      borderRadius: "10px",
                      padding: "10px",
                      textAlign: "center",
                      boxShadow: "0px 2px 20px rgba(0,0,0,0.2)",
                      maxWidth: "60%",
                      color: "#ffffff",
                    }}
                  >
                    {msg?.name
                      ? `${msg.name.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )} has left this chat`
                      : "Agent has left this chat"}
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#f06809",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
              )}

              {/* ********** Agent Declined Message ********** */}
              {(msg?.shape === "agent-declined-message" ||
                msg?.type === "agent-declined-message") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon
                          name={
                            msg.agentDetails?.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("") || selectedBotName
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(
                          msg?.message ||
                            "Sorry, all our agents are busy at the moment, please try again later"
                        ).map((paragraph, index) => (
                          <div
                            key={index}
                            className="chatbot-message"
                            style={{ margin: "5px 5px 5px 0" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: paragraph.outerHTML,
                              }}
                              style={{ paddingTop: "5px" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** Agent Message ********** */}
              {(msg?.shape === "agent-message" ||
                msg?.type === "agent-message") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon
                          name={
                            msg.agentDetails?.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("") || selectedBotName
                          }
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {/* Show the agent name */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="agent_name__line"
                            style={{
                              backgroundColor: "#0A4EB7",
                            }}
                          ></div>
                          <p
                            className="agent_name__bubble"
                            style={{
                              backgroundColor: "#0A4EB7",
                              color: "#ffffff",
                            }}
                          >
                            {msg.agentDetails?.name}
                          </p>
                        </div>

                        {createAgentParagraphBubbles(msg?.text || "").map(
                          (paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px 5px 5px 0" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                                style={{ paddingTop: "5px" }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** Agent Message File ********** */}
              {(msg?.shape === "agent-message-file" ||
                msg?.type === "agent-message-file") && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon
                          name={
                            msg.agentDetails?.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("") || selectedBotName
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {/* Show the agent name */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="agent_name__line"
                            style={{
                              backgroundColor: "#0A4EB7",
                            }}
                          ></div>
                          <p
                            className="agent_name__bubble"
                            style={{
                              backgroundColor: "#0A4EB7",
                              color: "#ffffff",
                            }}
                          >
                            {msg.agentDetails?.name}
                          </p>
                        </div>

                        <div
                          className="chatbot-message"
                          style={{ margin: "5px 5px 5px 0" }}
                        >
                          {msg?.file && (
                            <span
                              style={{
                                paddingBottom: "5px",
                                display: "block",
                              }}
                            >
                              {msg?.file
                                .substring(msg?.file.lastIndexOf("/") + 1)
                                .replace(/%20/g, " ")}
                              <a
                                href={msg?.file}
                                download
                                style={{ marginLeft: "10px" }}
                              >
                                Download{" "}
                                <DownloadOutlined
                                  size={50}
                                  style={{ marginLeft: "10px" }}
                                />
                              </a>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Message ********** */}
              {(msg?.shape === "user-live-message" ||
                msg?.type === "user-live-message") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.text?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  </div>
                </>
              )}

              {/* ********** User Message File ********** */}
              {(msg?.shape === "user-live-message-file" ||
                msg?.type === "user-live-message-file") && (
                <>
                  <div
                    className="user-message-container"
                    style={{ marginLeft: "10px" }}
                  >
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                    <div className="chatbot-user-message">
                      {msg?.url && (
                        <span
                          style={{
                            paddingBottom: "5px",
                            display: "block",
                          }}
                        >
                          {msg?.url
                            .substring(msg?.url.lastIndexOf("/") + 1)
                            .replace(/%20/g, " ")}
                          <a
                            href={msg?.url}
                            download
                            style={{ marginLeft: "10px" }}
                          >
                            Download{" "}
                            <DownloadOutlined
                              size={50}
                              style={{ marginLeft: "10px" }}
                            />
                          </a>
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* ********** Visitor Disconnected ********** */}
              {(msg?.shape === "visitor-disconnected-message" ||
                msg?.type === "visitor-disconnected-message") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#a91e1e",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#a91e1e",
                      borderRadius: "10px",
                      padding: "10px",
                      textAlign: "center",
                      boxShadow: "0px 2px 20px rgba(0,0,0,0.2)",
                      maxWidth: "60%",
                      color: "#ffffff",
                    }}
                  >
                    <span
                      style={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Visitor has disconnected
                    </span>
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#a91e1e",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
              )}

              {/* ********** Visitor Reconnected ********** */}
              {(msg?.shape === "visitor-reconnected-message" ||
                msg?.type === "visitor-reconnected-message") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#50822d",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#50822d",
                      borderRadius: "10px",
                      padding: "10px",
                      textAlign: "center",
                      boxShadow: "0px 2px 20px rgba(0,0,0,0.2)",
                      maxWidth: "60%",
                      color: "#ffffff",
                    }}
                  >
                    <span
                      style={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Visitor has reconnected
                    </span>
                    {msg?.time && (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "11px",
                          fontStyle: "italic",
                        }}
                      >
                        <ChatDate dateTime={msg?.time} />
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      height: "1px",
                      backgroundColor: "#50822d",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
              )}

              {/* ********************* INTEGRATION NODES ********************* */}
              {/* ********** GPT Response Node ********** */}
              {msg?.type === "gpt-response-node" && (
                <>
                  <div className="bot-message-container">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BotMessageIcon name={selectedBotName} />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: msg?.time ? "-15px" : "-5px",
                        }}
                      >
                        {msg?.time && (
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "11px",
                              fontStyle: "italic",
                            }}
                          >
                            <ChatDate dateTime={msg?.time} />
                          </p>
                        )}

                        {createParagraphBubbles(msg?.value || "").map(
                          (paragraph, index) => (
                            <div
                              key={index}
                              className="chatbot-message"
                              style={{ margin: "5px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.outerHTML,
                                }}
                                style={{ paddingTop: "5px" }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </React.Fragment>
        ))}
    </>
  );
};

export default Messages;
