import React, { useState, useEffect } from "react";
import { Card, Row, Col, Input, Table, Tag } from "antd";
import { getAllVisitorsPaginated } from "@api/user/superAdmin";
import { useHistory, useLocation } from "react-router-dom";

const { Search } = Input;

const VisitorList = () => {
  const history = useHistory(); // Use useHistory to programmatically navigate
  const location = useLocation(); // Use location to access the URL
  const [visitors, setVisitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    // Extract pagination and search parameters from URL
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const search = searchParams.get("search") || "";

    setSearchText(search);
    setPagination({ current: page, pageSize, total: 0 });
    fetchVisitors(page, pageSize, search);
  }, [location.search]); // Rerun effect when URL search params change

  const fetchVisitors = (page, pageSize, searchText = "") => {
    setLoading(true);
    getAllVisitorsPaginated(page, pageSize, searchText)
      .then((res) => {
        setVisitors(res.data.visitors);
        setPagination((p) => ({
          ...p,
          total: res.data.paginationData.totalVisitors,
          current: res.data.paginationData.page,
          pageSize: res.data.paginationData.perPage,
        }));
      })
      .catch((err) => {
        console.error("Fetch visitors error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePaginationChange = (page, pageSize) => {
    const searchParams = new URLSearchParams();
    searchParams.set("page", page);
    searchParams.set("pageSize", pageSize);
    if (searchText) searchParams.set("search", searchText);

    history.push({ search: searchParams.toString() });
    setPagination({ ...pagination, current: page, pageSize });
    fetchVisitors(page, pageSize, searchText);
  };

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    setPagination({ ...pagination, current: 1 }); // Reset to first page
    fetchVisitors(1, pagination.pageSize, searchValue);

    const searchParams = new URLSearchParams();
    searchParams.set("page", "1");
    searchParams.set("pageSize", pagination.pageSize);
    if (searchValue) searchParams.set("search", searchValue);
    history.push({ search: searchParams.toString() });
  };

  // Table columns
  const columns = [
    {
      title: "Country",
      dataIndex: ["meta", "country_name"],
      key: "country",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Source",
      dataIndex: "utm_source",
      key: "source",
      render: (text) => text?.split("utm_source=")[1] || text,
    },
    {
      title: "State",
      dataIndex: ["meta", "state"],
      key: "state",
    },
    {
      title: "City",
      dataIndex: ["meta", "city"],
      key: "city",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Visit Date",
      dataIndex: "createdAt",
      key: "visitDate",
      render: (text) => text?.split("T")[0],
    },
    {
      title: "Country Code",
      dataIndex: ["meta", "country_code"],
      key: "countryCode",
    },
    {
      title: "IP",
      dataIndex: ["meta", "IPv4"],
      key: "ip",
      render: (text) => <Tag color="red">{text}</Tag>,
    },
  ];

  return (
    <Card style={{ borderRadius: "0", height: "100vh" }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h3>All Visitors</h3>
        </Col>
        <Col>
          <Search
            placeholder="Search by country/IP address"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: "300px", marginLeft: 16 }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={visitors}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        loading={loading}
        onChange={({ current, pageSize }) =>
          handlePaginationChange(current, pageSize)
        }
        rowKey={(record) => record._id}
        scroll={{ y: "calc(100vh - 270px)" }} // Adjust height as needed
      />
    </Card>
  );
};

export default VisitorList;
