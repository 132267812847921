import React, { useState, useEffect } from "react";
import { Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChatsList } from "@components";
// Redux Actions
import {
  // Chatbots Names
  getAdminChatbotsNameAction,
  // Responses
  getSelectedChatbotResponsesAction,
} from "@redux/actions/adminActions";
import {
  // Chatbots Names
  getMemberChatbotsNameAction,
  // Team
  getMemberTeamListAction,
  // Responses
  getAgentSelectedChatbotResponsesAction,
  // Tickets
  getMemberHandledTicketsAction,
  getMemberUnHandledTicketsAction,
} from "@redux/actions/memberActions";

const ChatLeft = ({
  // Loaders
  loading,
  setLoading,
  // Chatbot
  isBotSelected,
  setIsBotSelected,
  selectedBotId,
  setSelectedBotId,
  selectedBotName,
  setSelectedBotName,
  handleSelectChatbot,
  // Chats Pagination
  chatsPage,
  setChatsPage,
  chatsPageSize,
  setChatsPageSize,
  // Chat
  handleSelectChat,
  selectedChat,
  setSelectedChat,
  hasChatEnded,
  // Ticket Chat
  handleSelectTicketChat,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  // Loading
  const [botNamesLoading, setBotNamesLoading] = useState(false);
  const [loadMoreChatsLoading, setLoadMoreChatsLoading] = useState(false);
  const [agentFetchLoading, setAgentFetchLoading] = useState(false);
  const [loadMoreHandledTicketsLoading, setLoadMoreHandledTicketsLoading] =
    useState(false);
  const [loadMoreUnHandledTicketsLoading, setLoadMoreUnHandledTicketsLoading] =
    useState(false);

  // Tabs
  const [activeListTab, setActiveListTab] = useState("bots");
  const [activeTicketsTab, setActiveTicketsTab] = useState("handled");

  // Handled Tickets Pagination
  const [handledTicketsPage, setHandledTicketsPage] = useState(1);
  const [handledTicketsPageSize, setHandledTicketsPageSize] = useState(10);
  // Unhandled Tickets Pagination
  const [unHandledTicketsPage, setUnHandledTicketsPage] = useState(1);
  const [unHandledTicketsPageSize, setUnHandledTicketsPageSize] = useState(10);

  // Fetch chatbot names
  useEffect(() => {
    handleFetchChatbotNames();
  }, []);

  // Handle fetch chatbots by calling the redux action
  const handleFetchChatbotNames = () => {
    setBotNamesLoading(true);

    // If admin
    if (user?.role === "admin") {
      dispatch(getAdminChatbotsNameAction(setBotNamesLoading));
    } else if (user?.role === "agent") {
      dispatch(getMemberChatbotsNameAction(setBotNamesLoading));
    }
  };

  // Handle back to chatbots list
  const handleBackToChatbotsList = () => {
    // History push to /a/ws/:wid/chats
    if (user?.role === "admin") {
      history.push(`/a/ws/${user?.workspaceId}/chats`);
    } else if (user?.role === "agent") {
      history.push(`/m/ws/${user?.workspaceId}/chats`);
    }

    // Set isBotSelected to false
    setIsBotSelected(false);

    // Clear the selected bot
    setSelectedBotId("");

    // Clear the selected bot name
    setSelectedBotName("");

    // Reset the chatsPage number
    setChatsPage(1);

    // Reset the chatsPage size
    setChatsPageSize(20);

    // Reset the selected chat
    setSelectedChat({ record: [] });
  };

  // *** Load More *** //
  // Handle load more chats
  const handleLoadMoreChats = () => {
    setLoadMoreChatsLoading(true);

    // Increase chatsPage number
    setChatsPage(chatsPage + 1);

    if (user?.role === "admin") {
      // Dispatch the action to get the chatbot responses
      dispatch(
        getSelectedChatbotResponsesAction(
          selectedBotId,
          chatsPage + 1,
          chatsPageSize,
          setSelectedBotName,
          setLoadMoreChatsLoading
        )
      );
    } else if (user?.role === "agent") {
      dispatch(
        getAgentSelectedChatbotResponsesAction(
          selectedBotId,
          chatsPage + 1,
          chatsPageSize,
          setSelectedBotName,
          setLoadMoreChatsLoading
        )
      );
    }
  };

  // Handle load more handled tickets
  const handleLoadMoreHandledTickets = () => {
    setLoadMoreHandledTicketsLoading(true);

    // Increase handledTicketsPage number
    setHandledTicketsPage(handledTicketsPage + 1);

    // Set per page to 10
    setHandledTicketsPageSize(10);

    // Dispatch the action to get the handled tickets
    dispatch(
      getMemberHandledTicketsAction(
        handledTicketsPage + 1,
        handledTicketsPageSize,
        setLoadMoreHandledTicketsLoading
      )
    );
  };

  // Handle load more unhandled tickets
  const handleLoadMoreUnHandledTickets = () => {
    setLoadMoreUnHandledTicketsLoading(true);

    // Increase unHandledTicketsPage number
    setUnHandledTicketsPage(unHandledTicketsPage + 1);

    // Set per page to 10
    setUnHandledTicketsPageSize(10);

    // Dispatch the action to get the unhandled tickets
    dispatch(
      getMemberUnHandledTicketsAction(
        unHandledTicketsPage + 1,
        unHandledTicketsPageSize,
        setLoadMoreUnHandledTicketsLoading
      )
    );
  };

  // Handle fetch agents
  const handleFetchAgents = () => {
    setAgentFetchLoading(true);

    // Dispatch action to fetch team members
    dispatch(getMemberTeamListAction(setAgentFetchLoading));
  };

  // *** Tabs *** //
  // Handle chats list tab change
  const handleChatsListTabChange = (key) => {
    // If key is agents, fetch agents
    if (key === "agents") {
      // Push to /a/ws/:wid/chats if user is admin
      if (user?.role === "admin") {
        history.push(`/a/ws/${user?.workspaceId}/chats`);
      } else if (user?.role === "agent") {
        history.push(`/m/ws/${user?.workspaceId}/chats`);
      }

      handleFetchAgents();
    } else if (key === "tickets") {
      // Push to /a/ws/:wid/chats if user is admin
      if (user?.role === "admin") {
        history.push(`/a/ws/${user?.workspaceId}/chats`);
      } else if (user?.role === "agent") {
        history.push(`/m/ws/${user?.workspaceId}/chats`);
      }

      // Set loading to true
      setLoading(true);

      // Dispatch the action to get the handled tickets
      dispatch(
        getMemberHandledTicketsAction(
          handledTicketsPage,
          handledTicketsPageSize,
          setLoading
        )
      );
    }

    // if key is tickets, set selected chat to useState({ record: [] });
    if (key === "tickets" || key === "bots") {
      setSelectedChat({ record: [] });
    }

    setActiveListTab(key);
  };

  // Handle handleTicketsTabChange tab change
  const handleTicketsTabChange = (key) => {
    // Set loading to true
    setLoading(true);

    // Set selected chat to useState({ record: [] });
    setSelectedChat({ record: [] });

    // If key is handled, fetch handled tickets
    if (key === "handled") {
      // Dispatch the action to get the handled tickets
      dispatch(
        getMemberHandledTicketsAction(
          handledTicketsPage,
          handledTicketsPageSize,
          setLoading
        )
      );
    } else if (key === "unhandled") {
      // Dispatch the action to get the unhandled tickets
      dispatch(
        getMemberUnHandledTicketsAction(
          unHandledTicketsPage,
          unHandledTicketsPageSize,
          setLoading
        )
      );
    }

    setActiveTicketsTab(key);
  };

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={6}
        xl={5}
        style={{
          background: "#ffffff",
          height: "100vh",
          boxShadow: "0px 0px 15px -12px rgba(0,0,0,0.75)",
        }}
      >
        <ChatsList
          // Chatbot
          selectedBotId={selectedBotId}
          isBotSelected={isBotSelected}
          selectedBotName={selectedBotName}
          handleSelectChatbot={handleSelectChatbot}
          handleBackToChatbotsList={handleBackToChatbotsList}
          // Chat
          selectedChat={selectedChat}
          handleSelectChat={handleSelectChat}
          handleSelectTicketChat={handleSelectTicketChat}
          hasChatEnded={hasChatEnded}
          // Loading
          loading={loading}
          botNamesLoading={botNamesLoading}
          loadMoreChatsLoading={loadMoreChatsLoading}
          agentFetchLoading={agentFetchLoading}
          loadMoreHandledTicketsLoading={loadMoreHandledTicketsLoading}
          loadMoreUnHandledTicketsLoading={loadMoreUnHandledTicketsLoading}
          // Load More
          handleLoadMoreChats={handleLoadMoreChats}
          handleLoadMoreHandledTickets={handleLoadMoreHandledTickets}
          handleLoadMoreUnHandledTickets={handleLoadMoreUnHandledTickets}
          // Tabs
          activeListTab={activeListTab}
          handleChatsListTabChange={handleChatsListTabChange}
          activeTicketsTab={activeTicketsTab}
          handleTicketsTabChange={handleTicketsTabChange}
        />
      </Col>
    </>
  );
};

export default ChatLeft;
