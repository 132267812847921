import React, { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Input, Divider } from "antd";
import { shallow } from "zustand/shallow";
import { HtmlNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { useTranslation } from "react-i18next";

const HtmlNodeDataEditor: FC<HtmlNode> = ({ type, id }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  // Get the node data from the store, We can also use the data from props
  // but this seems more consistent
  const state = getNode(id);
  const [botHtml, setBotHtml] = useState(state?.data?.html);
  const { t } = useTranslation();

  // Each time botHtmlNodeData changes, update the state
  useEffect(() => {
    setBotHtml(state?.data?.html);
  }, [id]);

  // Handle bot message change
  const handleBotHtmlChange = useCallback(
    (e: any) => {
      if (!state) return;

      setBotHtml(e.target.value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, html: e.target.value },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.specialNodes.htmlNodeInfo")}
          </p>
        </Row>

        {/* User Prompt */}
        <Divider orientation="left">
          <Col span={24} className="sidebar__heading">
            {t("flowBuilder.sidebar.editor.specialNodes.html")}
          </Col>
        </Divider>
        <Col span={24}>
          <Input.TextArea
            value={botHtml}
            onChange={(e) => handleBotHtmlChange(e)}
            rows={8}
            placeholder={t("flowBuilder.sidebar.editor.specialNodes.htmlPH")}
            style={{ borderRadius: "4px", marginTop: "5px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default HtmlNodeDataEditor;
