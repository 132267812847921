import { Card, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import cardBg2 from "../../../assets/images/dashboard/ecommerce-card-bg.png";
import UpgradeImage from "../../../assets/images/illustrations/growth.svg";
import { useTranslation } from "react-i18next";

const UpgradePlanCard = () => {
  const { analytics } = useSelector((state) => state.admin);
  const { t } = useTranslation();

  // Function to format date using Moment.js
  const formatDate = (date) => moment(date).format("MMMM Do YYYY");

  return (
    <Card
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Row justify="space-between" style={{ flexDirection: "column" }}>
        <Col className=" hp-mt-12">
          <h4>
            <b>{t("dashboard.currentPlanTitle")}</b>:
            <span className="hp-text-color-primary-1 hp-mx-4 hp-text-color-dark-primary-2">
              {analytics?.usageMetrics?.subscriptionPlan}
            </span>
          </h4>
          <p className="hp-p1-body hp-mb-0">
            {t("dashboard.currentPlanSubTitle")}
          </p>

          {/* List of features */}
          {/* Total Bots */}
          <Row justify="space-between" className="hp-mt-12">
            <Col span={18}>
              <Row>
                <p className="hp-p1-body hp-mb-0"> {t("dashboard.bots")}</p>
              </Row>
            </Col>
            <Col className="hp-text-right" span={6}>
              <Row align="end">
                <p className="hp-mb-0 hp-mr-8 hp-mt-5 hp-align-self-center">
                  {analytics?.totalChatbots}
                </p>
              </Row>
            </Col>
          </Row>

          {/* Team Members */}
          <Row justify="space-between" className="hp-mt-12">
            <Col span={18}>
              <Row>
                <p className="hp-p1-body hp-mb-0">
                  {t("dashboard.teamMemberTitle")}
                </p>
              </Row>
            </Col>
            <Col className="hp-text-right" span={6}>
              <Row align="end">
                <p className="hp-mb-0 hp-mr-8 hp-mt-5 hp-align-self-center">
                  {analytics?.team.length}
                </p>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Usage Metrics */}
        <Col className="hp-mt-32">
          <h4>
            <b> {t("dashboard.usageMetricsTitle")}</b>
          </h4>
          <p className="hp-p1-body hp-mb-0">
            <b> {t("dashboard.usageMetricsSubTitle")}</b>
          </p>
        </Col>

        {/* Chats Used */}
        <Row justify="space-between" className="hp-mt-32">
          <Col span={12}>
            <p className="hp-p1-body hp-mb-0">
              <b> {t("dashboard.chatsUsed")}</b>
            </p>
          </Col>
          <Col className="hp-text-right" span={12}>
            <p className="hp-mb-0 hp-align-self-center">
              {analytics?.usageMetrics?.chatsUsed}
            </p>
          </Col>
        </Row>

        {/* Chats Left */}
        <Row justify="space-between">
          <Col span={12}>
            <p className="hp-p1-body hp-mb-0"> {t("dashboard.chatsLeft")}</p>
          </Col>
          <Col className="hp-text-right" span={12}>
            <p className="hp-mb-0 hp-align-self-center">
              {analytics?.usageMetrics?.chatsLeft}
            </p>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12}>
            <p className="hp-p1-body hp-mb-0"> {t("dashboard.startDate")}</p>
          </Col>
          <Col className="hp-text-right" span={12}>
            <p className="hp-mb-0">
              {formatDate(analytics?.usageMetrics?.subscriptionStartDate)}
            </p>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12}>
            <p className="hp-p1-body hp-mb-0">
              {t("dashboard.nextAllocation")}
            </p>
          </Col>
          <Col className="hp-text-right" span={12}>
            <p className="hp-mb-0">
              {formatDate(analytics?.usageMetrics?.subscriptionEndDate)}
            </p>
          </Col>
        </Row>

        <Button
          className="da-float-right hp-mt-12 da-mt-xs-16 da-text-color-primary-1 da-bg-dark-primary-1 da-border-color-dark-primary-1 da-text-color-dark-0"
          style={{
            backgroundImage: "url(" + cardBg2 + ")",
            backgroundSize: "cover",
            backgroundPosition: "center right",
          }}
        >
          <Link to="/profile/plans">
            {analytics?.usageMetrics?.subscriptionPlan === "Free"
              ? t("dashboard.upgradeNow")
              : t("dashboard.changePlan")}
          </Link>
        </Button>

        <Col
          className="hp-mt-16"
          style={{
            backgroundImage: "url(" + cardBg2 + ")",
            backgroundSize: "cover",
            backgroundPosition: "center right",
            borderRadius: "8px",
          }}
        >
          <img
            src={UpgradeImage}
            alt="Upgrade"
            style={{ width: "240px", height: "100%", objectFit: "contain" }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default UpgradePlanCard;
