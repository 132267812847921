import api from "../index";

// ********** Chatbot ********** //
// Create a chatbot
export const createChatbot = async (values: any) => {
  return await api.post("/chatbot", values);
};

// Get a chatbot by bid
export const getChatbotById = async (bid: any) => {
  return await api.get(`/chatbot/${bid}`);
};

// Get a chatbot by bid with admin check
export const getChatbotByIdWithAdminCheck = async (bid: any) => {
  return await api.get(`/chatbot/${bid}/admin`);
};

// Update a chatbot by bid
export const updateChatbotById = async (bid: any, values: any) => {
  return await api.put(`/chatbot/${bid}`, values);
};

// Update a chatbot customizations by bid
export const updateChatbotCustomizationsById = async (
  bid: any,
  values: any
) => {
  return await api.put(`/chatbot/${bid}/customizations`, values);
};

// Delete a chatbot by bid
export const deleteChatbotById = async (bid: any) => {
  return await api.delete(`/chatbot/${bid}`);
};

// Upload media to s3
export const uploadChatbotMedia = async (
  bid: any,
  values: any,
  config: any
) => {
  return await api.post(`/chatbot/${bid}/media`, values, config);
};

// Get chatbot analytics
export const getChatbotFlowAnalytics = async (bid: any) => {
  return await api.get(`/chatbot-analytics/${bid}`);
};

// Get chatbot with answer variables
export const getChatbotWithAnswerVariables = async (bid: any) => {
  return await api.get(`/chatbot/${bid}/answer-variables`);
};

// Get chatbot with answer variables paginated
export const getChatbotWithAnswerVariablesPaginated = async (
  bid: any,
  page: any,
  perPage: any,
  searchText: any
) => {
  if (!searchText) {
    return await api.get(
      `/chatbot/${bid}/answer-variables/paginated?page=${page}&perPage=${perPage}`
    );
  } else {
    return await api.get(
      `/chatbot/${bid}/answer-variables/paginated?page=${page}&perPage=${perPage}&searchText=${searchText}`
    );
  }
};

// Export chatbot data to csv
export const exportChatbotDataToCsv = async (bid: any) => {
  return await api.get(`/chatbot/${bid}/export-csv`);
};

// Duplicate chatbot
export const duplicateChatbot = async (bid: any) => {
  return await api.post(`/chatbot/${bid}/duplicate`);
};

// Publish chatbot
export const publishChatbot = async (bid: any, elements: any) => {
  return await api.put(`/chatbot/${bid}/publish`, elements);
};

// ********** Chatbot Flow Version ********** //
// Get chatbot published flow version
export const getChatbotPublishedFlowVersion = async (bid: any, vid: any) => {
  return await api.get(`/chatbot/${bid}/published/flow/version/${vid}`);
};

// Delete chatbot published flow version
export const deleteChatbotPublishedFlowVersion = async (bid: any, vid: any) => {
  return await api.delete(`/chatbot/${bid}/published/flow/version/${vid}`);
};

// Restore chatbot published flow version by bid
export const restorePublishedChatbotFlowVersionById = async (
  bid: any,
  vid: any
) => {
  return await api.put(`/chatbot/${bid}/published/flow/version/${vid}/restore`);
};
