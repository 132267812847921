// import localStorage from "@react-native-async-storage/async-storage";

const setValue = async (key: string, value: string): Promise<void> => {
  await localStorage.setItem(key, value);
};

const getValue = async (value: string): Promise<string | null> => {
  const val = await localStorage.getItem(value);
  return val;
};

const deleteValue = async (value: string): Promise<void> => {
  await localStorage.removeItem(value);
};

const deleteMultipleValue = async (value: string[]): Promise<void> => {
  await localStorage.multiRemove(value);
};

const clearValue = async (): Promise<void> => {
  await localStorage.clear();
};

export { setValue, getValue, deleteValue, clearValue, deleteMultipleValue };
