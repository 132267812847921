import { Button } from "antd";
import { MdClose } from "react-icons/md";
import { Header, Footer } from "./subOptions";
import "../../../../assets/css/ChatUi.css";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";

const Layout = ({ setActiveOption }) => {
  const handleCloseButtonClick = () => {
    setActiveOption(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="option-content">
        <div className="content-header">
          <h5 style={{ textTransform: "uppercase" }}>
            {t("customize.layout.layout")}
          </h5>
          <Button
            type="text"
            icon={<MdClose size={20} />}
            onClick={handleCloseButtonClick}
            size="small"
          />
        </div>

        <div className="general-body">
          {/* Header */}
          <Header />

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
