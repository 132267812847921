import { useEffect, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  // ** -------------- Protected Routes -------------- **
  // ** Onboarding Route **
  OnboardingRoute,
  // ** Admin Route **
  AdminRoute,
  // ** Member Route **
  MemberRoute,
  // ** Super Admin Route **
  SuperAdminRoute,
  // ** Marketing Admin Route **
  MarketingAdminRoute,
  // ** Profile Route **
  ProfileRoute,
} from "./routes";
// ** -------------- Redux Actions -------------- **
import { isUserAction } from "./redux/actions/authActions";
import {
  // -------------- Auth views --------------
  Signup,
  AccountActivation,
  Login,
  ForgotPassword,
  NewPassword,
  Terms,
  Privacy,
  TeamInviteAccept,
  // -------------- Onboarding views --------------
  Onboarding,
  Subscribe,
  // -------------- Super Admin views --------------
  // Dashboard
  SuperAdminDashboard,
  // Chatbot
  ChatbotTemplateList,
  // Categories
  TemplateCategoriesList,
  // Tags
  TemplateTagsList,
  // Admins
  AdminList,
  AdminDetails,
  TeamDetails,
  AdminUsageHistory,
  AdminSubscriptionHistory,
  AdminPaymentHistory,
  // Visitors
  VisitorList,
  // Leads
  LeadsList,
  // Blogs
  // Blog Categories
  BlogCategoriesList,
  // Blog Tags
  BlogTagsList,
  // Blogs List
  BlogsList,
  BlogCreate,
  BlogUpdate,
  // Plans
  PlansList,
  PlanCreate,
  PlanUpdate,
  // Changelogs
  ChangelogsList,
  // Coupons
  CouponsList,
  CouponCreate,
  CouponUpdate,
  // ---- SEO ----
  // Chatbot Template Blog
  ChatbotTemplateBlogsList,
  ChatbotTemplateBlogCreate,
  ChatbotTemplateBlogUpdate,
  ChatbotTemplateBlogCategoriesList,

  // -------------- Marketing Admin views --------------
  // Dashboard
  MarketingAdminDashboard,

  // -------------- Admin views --------------
  // Dashboard
  AdminDashboard,
  // Chatbot
  ChatbotList,
  TemplateList,
  ChatbotFlowBuilder,
  ChatbotFlowVersion,
  ChatbotCustomize,
  ChatbotShare,
  ChatbotFlowAnalytics,
  // Knowledge Base
  KnowledgeBase,
  // Chat History
  Chats,
  // Metrics
  Metrics,
  // Team
  TeamList,
  // Workspace
  Workspace,
  // Integrations
  IntegrationsList,
  IntegrationDetails,
  // Subscription
  SubscriptionSuccess,
  PaymentCancel,
  // -------------- Member views --------------
  // Dashboard
  MemberDashboard,
  // -------------- Profile Views --------------
  // Profile
  MyProfile,
  MySecurity,
  MyPassword,
  SubscriptionPlans,
  MySubscription,
  ChangeLanguage,
  // -------------- Wildcard --------------
  RandomPageRedirect,
} from "./views";
import { WebSocketProvider } from "./context/WebSocketContext";
import { getValue, setValue } from "./utils/localStorage";
import "./App.scss";
import "react-loading-skeleton/dist/skeleton.css";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state?.auth);
  const { i18n } = useTranslation();

  // Determine if the current path is an auth route
  const isAuthRoute = [
    "/",
    "/signup",
    "/login",
    "/account/activate",
    "/forgot-password",
    "/new-password",
    "/privacy",
    "/terms",
    "/team/join",
  ].includes(history.location.pathname);

  // If local storage "alLoggInnn" is true and is on the auth pages page, redirect to dashboard
  useEffect(() => {
    if (
      localStorage.getItem("alLoggInnn") === "true" &&
      (history.location.pathname === "/" ||
        history.location.pathname === "/signup" ||
        history.location.pathname === "/login")
    ) {
      // Dispatch the user action
      dispatch(isUserAction({ history }));
    }
  }, [dispatch, history]);

  // set overflow as hidden to all pages instead of profile pages
  useEffect(() => {
    // set isOverflow based on whether the current route starts with "/profile" or "/subscribe".
    const isOverflow =
      history?.location.pathname.startsWith("/profile") ||
      history?.location.pathname.startsWith("/onboarding") ||
      history?.location.pathname.startsWith("/subscribe");

    const bodyOverflow = isOverflow ? "auto" : "hidden";

    // Set overflow Property on the body element
    document.body.style.overflowY = bodyOverflow;
    document.body.style.overflowX = "hidden";

    // Cleanup the effect
    return () => {
      document.body.style.overflowY = "hidden";
      document.body.style.overflowX = "auto";
    };
  }, [history?.location.pathname]);

  // fetch selected language
  useEffect(() => {
    const fetchLanguage = async () => {
      const selectedLanguage = await getValue("language");

      if (selectedLanguage) {
        i18n.changeLanguage(selectedLanguage);
        setValue("language", selectedLanguage);
      }
    };

    fetchLanguage();
  }, []);

  return (
    <div className="App">
      <Suspense
        fallback={
          <>
            <div className="preloader-container">
              <div id="chatbot-dot">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <div id="chatbot-corner"></div>

              <div id="antenna">
                <div id="beam"></div>
                <div id="beam-pulsar"></div>
              </div>
            </div>
          </>
        }
      >
        {!isAuthRoute ? (
          <>
            <WebSocketProvider
              userId={user?._id}
              wid={user?.workspaceId}
              role={user?.role}
              history={history}
            >
              <ProtectedRoutes />
            </WebSocketProvider>
          </>
        ) : (
          <AuthRoutes user={user} />
        )}
      </Suspense>
    </div>
  );
};

// Auth Routes
const AuthRoutes = ({ user }) => {
  return (
    <Switch>
      {/*************** Common Routes ***************/}
      <Route exact path="/" component={!user && Login} />
      <Route exact path="/login" component={!user && Login} />
      <Route exact path="/signup" component={!user && Signup} />
      <Route
        exact
        path="/account/activate"
        component={!user && AccountActivation}
      />
      <Route
        exact
        path="/forgot-password"
        component={!user && ForgotPassword}
      />
      <Route exact path="/new-password" component={!user && NewPassword} />
      <Route exact path="/privacy" component={!user && Privacy} />
      <Route exact path="/terms" component={!user && Terms} />
      <Route exact path="/team/join" component={TeamInviteAccept} />

      {/* Wildcard */}
      <Route path={"*"} component={RandomPageRedirect} />
    </Switch>
  );
};

// Protected Routes
const ProtectedRoutes = () => {
  return (
    <Switch>
      {/*************** Onboarding Routes ***************/}
      {/* Onboarding */}
      <OnboardingRoute exact path="/onboarding" component={Onboarding} />
      {/* Subscribe */}
      <OnboardingRoute exact path="/subscribe" component={Subscribe} />

      {/*************** Super Admin Routes ***************/}
      {/* Dashboard */}
      <SuperAdminRoute
        exact
        path="/super-admin/dashboard"
        component={SuperAdminDashboard}
      />
      {/* Chatbot Templates List */}
      <SuperAdminRoute
        exact
        path="/super-admin/chatbot-templates"
        component={ChatbotTemplateList}
      />
      {/* Template List */}
      <SuperAdminRoute
        exact
        path="/super-admin/chatbot/templates"
        component={TemplateList}
      />
      {/* Template Categories List */}
      <SuperAdminRoute
        exact
        path="/super-admin/templates/categories"
        component={TemplateCategoriesList}
      />
      {/* Template Tags List */}
      <SuperAdminRoute
        exact
        path="/super-admin/templates/tags"
        component={TemplateTagsList}
      />

      {/* Admins */}
      <SuperAdminRoute exact path="/super-admin/admins" component={AdminList} />
      <SuperAdminRoute
        exact
        path="/super-admin/admin/:id"
        component={AdminDetails}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/admin/:id/team-details"
        component={TeamDetails}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/admin/:id/usage-history"
        component={AdminUsageHistory}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/admin/:id/subscription-history"
        component={AdminSubscriptionHistory}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/admin/:id/payment-history"
        component={AdminPaymentHistory}
      />
      {/* Visitors */}
      <SuperAdminRoute
        exact
        path="/super-admin/visitors"
        component={VisitorList}
      />
      {/* Leads */}
      <SuperAdminRoute exact path="/super-admin/leads" component={LeadsList} />
      {/* Blogs */}
      {/* Blog Categories */}
      <SuperAdminRoute
        exact
        path="/super-admin/blogs/blog-categories"
        component={BlogCategoriesList}
      />
      {/* Blog Tags */}
      <SuperAdminRoute
        exact
        path="/super-admin/blogs/tags"
        component={BlogTagsList}
      />
      {/* Blogs List */}
      <SuperAdminRoute exact path="/super-admin/blogs" component={BlogsList} />
      <SuperAdminRoute
        exact
        path="/super-admin/blog/create"
        component={BlogCreate}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/blog/:id/update"
        component={BlogUpdate}
      />

      {/* SEO */}
      {/* Chatbot Template Blog Categories */}
      <SuperAdminRoute
        exact
        path="/super-admin/seo/chatbot-template-blogs/chatbot-template-blog-categories"
        component={ChatbotTemplateBlogCategoriesList}
      />

      {/* Chatbot Template Blogs List */}
      <SuperAdminRoute
        exact
        path="/super-admin/seo/chatbot-template-blogs"
        component={ChatbotTemplateBlogsList}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/seo/chatbot-template-blog/create"
        component={ChatbotTemplateBlogCreate}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/seo/chatbot-template-blog/:id/update"
        component={ChatbotTemplateBlogUpdate}
      />

      {/* Chatbot Flow Builder */}
      <SuperAdminRoute
        exact
        path="/super-admin/chatbot/:bid/builder"
        component={ChatbotFlowBuilder}
      />

      {/* Chatbot Customize */}
      <SuperAdminRoute
        exact
        path="/super-admin/chatbot/:bid/customize"
        component={ChatbotCustomize}
      />

      {/* Chatbot Embed */}
      <SuperAdminRoute
        exact
        path="/super-admin/chatbot/:bid/embed"
        component={ChatbotShare}
      />

      {/* Chatbot Flow Version */}
      <SuperAdminRoute
        exact
        path="/super-admin/chatbot/:bid/version/:vid/builder"
        component={ChatbotFlowVersion}
      />

      {/* Changelogs */}
      <SuperAdminRoute
        exact
        path="/super-admin/changelogs"
        component={ChangelogsList}
      />

      {/* Plans */}
      <SuperAdminRoute exact path="/super-admin/plans" component={PlansList} />
      <SuperAdminRoute
        exact
        path="/super-admin/plans/create"
        component={PlanCreate}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/plans/update/:id"
        component={PlanUpdate}
      />
      {/* Coupons */}
      <SuperAdminRoute
        exact
        path="/super-admin/coupons"
        component={CouponsList}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/coupons/create"
        component={CouponCreate}
      />
      <SuperAdminRoute
        exact
        path="/super-admin/coupons/update/:id"
        component={CouponUpdate}
      />

      {/*************** Marketing Admin Routes ***************/}
      {/* Dashboard */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/dashboard"
        component={MarketingAdminDashboard}
      />
      {/* Chatbot Templates List */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/chatbot-templates"
        component={ChatbotTemplateList}
      />
      {/* Template List */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/chatbot/templates"
        component={TemplateList}
      />
      {/* Template Categories List */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/templates/categories"
        component={TemplateCategoriesList}
      />
      {/* Template Tags List */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/templates/tags"
        component={TemplateTagsList}
      />

      {/* SEO */}
      {/* Chatbot Template Blog Categories */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/seo/chatbot-template-blogs/chatbot-template-blog-categories"
        component={ChatbotTemplateBlogCategoriesList}
      />

      {/* Chatbot Template Blogs List */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/seo/chatbot-template-blogs"
        component={ChatbotTemplateBlogsList}
      />
      <MarketingAdminRoute
        exact
        path="/marketing-admin/seo/chatbot-template-blog/create"
        component={ChatbotTemplateBlogCreate}
      />
      <MarketingAdminRoute
        exact
        path="/marketing-admin/seo/chatbot-template-blog/:id/update"
        component={ChatbotTemplateBlogUpdate}
      />

      {/* Leads */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/leads"
        component={LeadsList}
      />
      {/* Blogs */}
      {/* Blog Categories */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/blogs/blog-categories"
        component={BlogCategoriesList}
      />
      {/* Blog Tags */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/blogs/tags"
        component={BlogTagsList}
      />
      {/* Blogs List */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/blogs"
        component={BlogsList}
      />
      <MarketingAdminRoute
        exact
        path="/marketing-admin/blog/create"
        component={BlogCreate}
      />
      <MarketingAdminRoute
        exact
        path="/marketing-admin/blog/:id/update"
        component={BlogUpdate}
      />
      {/* Chatbot Flow Builder */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/chatbot/:bid/builder"
        component={ChatbotFlowBuilder}
      />

      {/* Chatbot Customize */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/chatbot/:bid/customize"
        component={ChatbotCustomize}
      />

      {/* Chatbot Embed */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/chatbot/:bid/embed"
        component={ChatbotShare}
      />

      {/* Chatbot Flow Version */}
      <MarketingAdminRoute
        exact
        path="/marketing-admin/chatbot/:bid/version/:vid/builder"
        component={ChatbotFlowVersion}
      />

      {/*************** Admin Routes ***************/}
      {/* Dashboard */}
      <AdminRoute
        exact
        path="/a/ws/:wid/dashboard"
        component={AdminDashboard}
      />
      {/* Chatbot */}
      <AdminRoute exact path="/a/ws/:wid/chatbots" component={ChatbotList} />
      <AdminRoute
        exact
        path="/a/ws/:wid/chatbot/templates"
        component={TemplateList}
      />

      {/* Chatbot Flow Builder */}
      <AdminRoute
        exact
        path="/a/ws/:wid/u/:uid/chatbot/:bid/builder"
        component={ChatbotFlowBuilder}
      />

      {/* Chatbot Flow Version */}
      <AdminRoute
        exact
        path="/a/ws/:wid/u/:uid/chatbot/:bid/version/:vid/builder"
        component={ChatbotFlowVersion}
      />

      {/* Chatbot Customize */}
      <AdminRoute
        exact
        path="/a/ws/:wid/u/:uid/chatbot/:bid/customize"
        component={ChatbotCustomize}
      />

      {/* Chatbot Embed */}
      <AdminRoute
        exact
        path="/a/ws/:wid/u/:uid/chatbot/:bid/embed"
        component={ChatbotShare}
      />

      {/* Chatbot Analytics */}
      <AdminRoute
        exact
        path="/a/ws/:wid/u/:uid/chatbot/:bid/analytics"
        component={ChatbotFlowAnalytics}
      />

      {/* Knowledge Base */}
      <AdminRoute
        exact
        path={[
          "/a/ws/:wid/knowledge-base/:tab",
          "/a/ws/:wid/knowledge-base/articles/create",
          "/a/ws/:wid/knowledge-base/articles/:articleId/update",
        ]}
        component={KnowledgeBase}
      />

      {/* Chats */}
      <AdminRoute exact path="/a/ws/:wid/chats" component={Chats} />

      {/* Chats (Bot Selected) */}
      <AdminRoute exact path="/a/ws/:wid/chats/bot/:bid" component={Chats} />

      {/* Chats (Bot + Chat Selected) */}
      <AdminRoute
        exact
        path="/a/ws/:wid/chats/bot/:bid/chat/:cid"
        component={Chats}
      />

      {/* Metrics */}
      <AdminRoute exact path="/a/ws/:wid/metrics/graph" component={Metrics} />
      <AdminRoute
        exact
        path="/a/ws/:wid/metrics/response-table"
        component={Metrics}
      />
      <AdminRoute
        exact
        path="/a/ws/:wid/metrics/demographics"
        component={Metrics}
      />

      {/* Team */}
      <AdminRoute exact path="/a/ws/:wid/team" component={TeamList} />
      {/* Integrations */}
      <AdminRoute
        exact
        path="/a/ws/:wid/integrations"
        component={IntegrationsList}
      />
      {/* Workspace */}
      <AdminRoute exact path="/a/ws/:wid/api" component={Workspace} />
      {/* Notifications */}
      <AdminRoute exact path="/a/ws/:wid/notifications" component={Workspace} />
      {/* Usage */}
      <AdminRoute exact path="/a/ws/:wid/usage" component={Workspace} />
      {/* Integrations */}
      <AdminRoute
        exact
        path="/a/ws/:wid/integration/:id"
        component={IntegrationDetails}
      />
      {/* Subscription */}
      <AdminRoute exact path="/success" component={SubscriptionSuccess} />
      <AdminRoute exact path="/cancel" component={PaymentCancel} />

      {/*************** Member Routes ***************/}
      {/* Dashboard */}
      <MemberRoute
        exact
        path="/m/ws/:wid/dashboard"
        component={MemberDashboard}
      />
      {/* Chats */}
      <MemberRoute exact path="/m/ws/:wid/chats" component={Chats} />
      {/* Chats (Bot Selected) */}
      <MemberRoute exact path="/m/ws/:wid/chats/bot/:bid" component={Chats} />
      {/* Chats (Bot + Chat Selected) */}
      <MemberRoute
        exact
        path="/m/ws/:wid/chats/bot/:bid/chat/:cid"
        component={Chats}
      />

      {/* Team */}
      <MemberRoute exact path="/m/ws/:wid/team" component={TeamList} />

      {/*************** Profile Routes ***************/}
      <ProfileRoute exact path="/profile/information" component={MyProfile} />
      <ProfileRoute exact path="/profile/plans" component={SubscriptionPlans} />
      <ProfileRoute exact path="/profile/security" component={MySecurity} />
      <ProfileRoute exact path="/profile/password" component={MyPassword} />
      <ProfileRoute
        exact
        path="/profile/subscription"
        component={MySubscription}
      />
      <ProfileRoute exact path="/profile/language" component={ChangeLanguage} />

      <Route path={"*"} component={RandomPageRedirect} />
    </Switch>
  );
};

export default App;
