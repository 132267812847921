import React from "react";
import { Card, Row, Col } from "antd";
import { RiArrowGoBackFill } from "react-icons/ri";
import { PlanUpdateForm } from "@components";

const PlanUpdate = ({ history }) => {
  return (
    <Card style={{ borderRadius: "0", height: "100vh" }}>
      <Row>
        <Col span={24}>
          <Row>
            <RiArrowGoBackFill
              style={{ fontSize: "2em", cursor: "pointer" }}
              onClick={() => history.push("/super-admin/plans")}
            />
            <h3
              className="hp-mb-16"
              style={{
                marginLeft: "1em",
                fontSize: "1.75em",
              }}
            >
              Update plan
            </h3>
          </Row>
          {/* Space */}
          <Row style={{ marginTop: "1em" }} />
          {/* Plan Update Form */}
          <PlanUpdateForm />
        </Col>
      </Row>
    </Card>
  );
};

export default PlanUpdate;
