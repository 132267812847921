import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Input, Modal, Dropdown, Button, Menu } from "antd";
import { shallow } from "zustand/shallow";
import { HubspotNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { Conferbot, HubSpot } from "@assets/images/pages/builder";
import { DownOutlined } from "@ant-design/icons";

const HubspotNodeDataEditor: FC<HubspotNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [url, setUrl] = useState(state?.data?.questionText);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setUrl(state?.data?.questionText);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  const handleUrlChange = useCallback(
    (e: any) => {
      if (!state) return;

      // Set the welcome message
      setUrl(e.target.value);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: e.target.value,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // handle dropdown menu click
  const handleMenuClick = (value: string) => {
    setSelectedValue(value);
  };

  const menuProps: React.ReactElement<Menu> = (
    <Menu
      onClick={(e) => handleMenuClick(e.key)}
      style={{ borderRadius: "3px" }}
    >
      <Menu.Item key="Option 1">Option 1</Menu.Item>
      <Menu.Item key="Option 2">Option 2</Menu.Item>
      <Menu.Item key="Option 3">Option 3</Menu.Item>
    </Menu>
  );
  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            ℹ️ Use this node for HubSpot.
          </p>
        </Row>

        {/* Connect HubSpot */}
        <div className="connect_wrapper">
          <span className="heading">Connect your HubSpot account</span>
          {/* dropdown */}
          <Col span={24} style={{ width: "100%" }}>
            <Dropdown overlay={menuProps} placement="bottomLeft">
              <Button
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "3px",
                  marginBottom: "25px",
                }}
              >
                <span>{selectedValue || "Select an option"}</span>
                <span>
                  <DownOutlined />
                </span>
              </Button>
            </Dropdown>
          </Col>
          <button className="connect_btn">Add New Account</button>
        </div>

        {/* Question Text */}
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>

        <Col span={24}>
          <Input.TextArea
            value={url}
            placeholder="Enter your question here"
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={(e) => handleUrlChange(e)}
          />
        </Col>

        <SaveAnswer
          id={id}
          type={type}
          variableName={answerVariable ? answerVariable : "url"}
        />
      </Row>
    </>
  );
};

export default HubspotNodeDataEditor;
