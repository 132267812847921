import { useEffect, useRef } from "react";
import {
  Background,
  BackgroundVariant,
  MiniMap,
  ReactFlow,
  Controls,
  useReactFlow,
} from "reactflow";
import { useSelector } from "react-redux";
// Custom Edge
import CustomEdge from "./edges/CustomEdgeFlow";
// Connection Line
import ConnectionLine from "../flowBuilder/edges/ConnectionLine";
// Store
import useStore, { selector } from "../store/store";
import { shallow } from "zustand/shallow";
import { motion } from "framer-motion";
import "reactflow/dist/style.css";

// Node Types
import { nodeTypes } from "../types/nodeTypes";

// Edge Types
const edgeTypes = {
  customedge: CustomEdge,
};

// Flow Analytics
const FlowAnalytics = () => {
  // Use store to get the state and actions
  const {
    nodes,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    defaultEdgeOptions,
    edges,
    onConnect,
    onEdgeUpdate,
  } = useStore(selector, shallow);

  const { zoomIn, zoomOut } = useReactFlow();
  const reactFlowInstance = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const { currentFlowAnalytics } = useSelector(
    (state: any) => state?.chatbot ?? {}
  );

  // Remove the resizeObserver error
  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  // If currentChatbot is not null, then migrate the data
  useEffect(() => {
    if (currentFlowAnalytics && currentFlowAnalytics[0].nodes.length > 0) {
      setNodes(currentFlowAnalytics[0]?.nodes);
      setEdges(currentFlowAnalytics[0]?.edges);

      // Find the 'welcome-node' from the nodes
      const welcomeNode = currentFlowAnalytics[0]?.nodes.find(
        (node: any) => node.type === "welcome-node"
      );
      if (welcomeNode) {
        // Set the position to the welcome node's position
        reactFlowInstance.setCenter(
          welcomeNode.position.x + 500,
          welcomeNode.position.y + 150
        );
      } else {
        // If there's no 'welcome-node', then set the position to some default values
        reactFlowInstance.setCenter(500, 370);
      }

      // This will set the zoom level to 0.8
      reactFlowInstance.zoomTo(0.8);
    } else {
      setEdges([]);

      setNodes([]);

      // This will set the center of the flow
      reactFlowInstance.setCenter(500, 370);

      // This will set the zoom level to 0.8
      reactFlowInstance.zoomTo(0.8);
    }
  }, [currentFlowAnalytics, reactFlowInstance, setEdges, setNodes]);

  return (
    <>
      {!currentFlowAnalytics ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            minHeight: "30rem",
          }}
        >
          <div className="builder-dual-ring"></div>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", duration: 0.2, delay: 0.1 }}
          className="hp-d-flex hp-h-100"
        >
          <div
            style={{
              height: "92vh",
              width: "100%",
              background: "#cee2fa", // Replace with the color you want
            }}
            className="react-flow-container"
            ref={reactFlowWrapper}
          >
            <ReactFlow
              id="container"
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              defaultEdgeOptions={defaultEdgeOptions}
              onConnect={onConnect}
              connectionLineComponent={ConnectionLine}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onEdgeUpdate={onEdgeUpdate}
              selectNodesOnDrag={false}
              minZoom={0.4}
              maxZoom={0.85}
              deleteKeyCode={null} // Disable backspace key from deleting nodes
            >
              <Controls
                onZoomIn={() => zoomIn({ duration: 800 })}
                onZoomOut={() => zoomOut({ duration: 800 })}
                style={{
                  bottom: "100px",
                  left: "20px",
                  transform: "scale(2)",
                }}
              />

              <MiniMap
                id="minimap"
                nodeColor={"#cee2fa"}
                nodeStrokeWidth={3}
                zoomable
                pannable
                style={{ bottom: "80px" }}
              />

              <Background variant={BackgroundVariant.Lines} gap={50} size={1} />
            </ReactFlow>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default FlowAnalytics;
