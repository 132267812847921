import { MEMBER_TYPES } from "../constants/memberTypes";

// Define the structure of your state
interface MemberState {
  chatbots: any[];
  exampleTemplates: any[];
  chatbotNames: string[];
  selectedBotResponses: any;
  handledTicketsData: any;
  unHandledTicketsData: any;
  notificationsData: any;
  analytics?: any;
  teamList?: any;
}

// Define the type for your action
interface MemberAction {
  type: string;
  payload: any; // Replace 'any' with a more specific type if needed
}

// Initial state with type
const initialState: MemberState = {
  chatbots: [],
  exampleTemplates: [],
  chatbotNames: [],
  selectedBotResponses: {},
  handledTicketsData: {},
  unHandledTicketsData: {},
  notificationsData: {},
  analytics: {},
  teamList: [],
};

// Reducer function with types
const memberReducer = (
  state = initialState,
  action: MemberAction
): MemberState => {
  switch (action.type) {
    // -------------- DASHBOARD --------------
    case MEMBER_TYPES.GET_MEMBER_DASHBOARD_ANALYTICS:
      return {
        ...state,
        selectedBotResponses: {},
        analytics: action?.payload,
      };
    // -------------- CHATBOT --------------
    case MEMBER_TYPES.GET_MEMBER_CHATBOTS_NAME:
      return {
        ...state,
        chatbotNames: action?.payload,
      };

    // -------------- CHATBOT RESPONSES --------------
    case MEMBER_TYPES.SELECTED_BOT_RESPONSES:
      // If the selectedBotResponses.id is same as the action.payload.id, then only update the selectedBotResponses.responses and selectedBotResponses.page
      if (state.selectedBotResponses?.id === action?.payload?.id) {
        return {
          ...state,
          selectedBotResponses: {
            ...state.selectedBotResponses,
            responses:
              action?.payload?.page === 1
                ? action?.payload?.responses
                : [
                    ...state.selectedBotResponses.responses,
                    ...action?.payload?.responses,
                  ],
            tickets: action?.payload?.tickets,
            page: action?.payload?.page,
            hasMore: action?.payload?.hasMore,
          },
        };
      } else {
        return {
          ...state,
          selectedBotResponses: action?.payload,
        };
      }
    case MEMBER_TYPES.UPDATE_SELECTED_BOT_RESPONSES:
      // Push the new response to the top of selectedBotResponses.responses array
      return {
        ...state,
        selectedBotResponses: {
          ...state.selectedBotResponses,
          responses: [action?.payload, ...state.selectedBotResponses.responses],
        },
      };
    case MEMBER_TYPES.UPDATE_CHAT_WITH_LATEST_MESSAGE:
      return {
        ...state,
        selectedBotResponses: {
          ...state.selectedBotResponses,
          responses: state.selectedBotResponses.responses.map(
            (response: any) => {
              if (response.chatSessionId === action?.payload?.chatSessionId) {
                return {
                  ...response,
                  record: action?.payload?.data?.record,
                  answerVariables: action?.payload?.data?.answerVariables,
                };
              } else {
                return response;
              }
            }
          ),
        },
      };
    case MEMBER_TYPES.UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES:
      return {
        ...state,
        selectedBotResponses: {
          ...state.selectedBotResponses,
          responses: state.selectedBotResponses.responses.map(
            (response: any) => {
              if (response.chatSessionId === action?.payload?.chatSessionId) {
                return {
                  ...response,
                  record: action?.payload?.record,
                  answerVariables: action?.payload?.answerVariables,
                };
              } else {
                return response;
              }
            }
          ),
        },
      };

    // -------------- TEAM --------------
    case MEMBER_TYPES.GET_MEMBER_TEAM_LIST:
      return {
        ...state,
        teamList: action?.payload,
      };

    // -------------- STATUS --------------
    case MEMBER_TYPES.UPDATE_MEMBER_STATUS:
      return {
        ...state,
        teamList: state.teamList.map((member: any) => {
          if (member._id === action?.payload?._id) {
            return {
              ...member,
              isOnline: action?.payload?.isOnline,
              isEngaged: action?.payload?.isEngaged,
            };
          } else {
            return member;
          }
        }),
      };

    // -------------- TICKETS --------------
    // Handled tickets
    case MEMBER_TYPES.GET_MEMBER_HANDLED_TICKETS:
      // Check if the action is for the current list (either handled or unhandled) and whether it's an append operation
      if (state.handledTicketsData.page >= 1 && action.payload.page > 1) {
        return {
          ...state,
          handledTicketsData: {
            ...state.handledTicketsData,
            handledTickets: [
              ...state.handledTicketsData.handledTickets,
              ...action.payload.handledTickets.filter(
                (newTicket: any) =>
                  !state.handledTicketsData.handledTickets.some(
                    (existingTicket: any) =>
                      existingTicket._id === newTicket._id
                  )
              ),
            ],
            page: action.payload.page,
            hasMore: action.payload.hasMore,
          },
        };
      } else {
        // Initial load or resetting to first page
        return {
          ...state,
          handledTicketsData: action.payload,
        };
      }

    // Unhandled tickets
    case MEMBER_TYPES.GET_MEMBER_UNHANDLED_TICKETS:
      // Similar logic as above for unhandled tickets
      if (state.unHandledTicketsData.page >= 1 && action.payload.page > 1) {
        return {
          ...state,
          unHandledTicketsData: {
            ...state.unHandledTicketsData,
            unHandledTickets: [
              ...state.unHandledTicketsData.unHandledTickets,
              ...action.payload.unHandledTickets.filter(
                (newTicket: any) =>
                  !state.unHandledTicketsData.unHandledTickets.some(
                    (existingTicket: any) =>
                      existingTicket._id === newTicket._id
                  )
              ),
            ],
            page: action.payload.page,
            hasMore: action.payload.hasMore,
          },
        };
      } else {
        // Initial load or resetting to first page
        return {
          ...state,
          unHandledTicketsData: action.payload,
        };
      }

    // -------------- NOTIFICATIONS --------------
    case MEMBER_TYPES.GET_MEMBER_NOTIFICATIONS:
      // Check if the action is for appending notifications to an existing list
      if (state.notificationsData.page >= 1 && action.payload.page > 1) {
        return {
          ...state,
          notificationsData: {
            ...state.notificationsData,
            notifications: [
              ...state.notificationsData.notifications,
              ...action.payload.notifications.filter(
                (newNotification: any) =>
                  !state.notificationsData.notifications.some(
                    (existingNotification: any) =>
                      existingNotification._id === newNotification._id
                  )
              ),
            ],
            page: action.payload.page,
            hasMore: action.payload.hasMore,
          },
        };
      } else {
        // Initial load or resetting to the first page
        return {
          ...state,
          notificationsData: action.payload,
        };
      }
    case MEMBER_TYPES.MARK_ALL_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        notificationsData: {
          ...state.notificationsData,
          notifications: state.notificationsData.notifications.map(
            (notification: any) => ({
              ...notification,
              read: true,
            })
          ),
        },
      };

    default:
      return state;
  }
};

export default memberReducer;
