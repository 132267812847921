import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Tag,
  Menu,
  Modal,
  Dropdown,
  Button,
  Input,
  Table,
} from "antd";
import { FiMoreVertical } from "react-icons/fi";
import { RiEyeFill, RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlinePersonAddDisabled } from "react-icons/md";
import {
  getAllAdminsPaginated,
  toggleDisableUserAccount,
} from "@api/user/superAdmin";
import { deleteUserAccount } from "@api/user/user";
import { useHistory } from "react-router-dom";

const { Search } = Input;

const AdminList = () => {
  const history = useHistory();
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const search = searchParams.get("search") || "";

    setSearchText(search);
    setPagination({ current: page, pageSize, total: 0 });
    fetchAdmins(page, pageSize, search);
  }, []);

  const fetchAdmins = async (page, pageSize, search) => {
    setLoading(true);
    try {
      const res = await getAllAdminsPaginated(page, pageSize, search);
      setAdmins(res.data.admins);
      setPagination((prev) => ({
        ...prev,
        total: res.data.paginationData.totalAdmins,
        current: page,
        pageSize: pageSize,
      }));
    } catch (error) {
      console.error("Fetch admins error", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    const searchParams = new URLSearchParams();
    searchParams.set("page", page);
    searchParams.set("pageSize", pageSize);
    if (searchText) searchParams.set("search", searchText);

    history.push({ search: searchParams.toString() });
    setPagination({ ...pagination, current: page, pageSize });
    fetchAdmins(page, pageSize, searchText);
  };

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    setPagination({ ...pagination, current: 1 }); // Reset to first page
    fetchAdmins(1, pagination.pageSize, searchValue);

    const searchParams = new URLSearchParams();
    searchParams.set("page", "1");
    searchParams.set("pageSize", pagination.pageSize);
    if (searchValue) searchParams.set("search", searchValue);
    history.push({ search: searchParams.toString() });
  };

  // Function to handle admin view
  const handleAdminView = (id) => {
    history.push(`/super-admin/admin/${id}`);
  };

  // Function to handle delete user account
  const handleDeleteUserAccount = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user account?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteUserAccount(id);
          fetchAdmins(); // Refetch admins after deletion
        } catch (error) {
          console.error("Error deleting user", error);
        }
      },
    });
  };

  // Toggle user disable
  const handleToggleUserDisable = (id) => {
    toggleDisableUserAccount(id)
      .then(() => {
        fetchAdmins();
      })
      .catch((err) => {
        console.log("Toggle admin disable error", err);
      });
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-US", options)
      .replace(/(\d+)(st|nd|rd|th)/, "$1");
  };

  // Table columns
  const columns = [
    // Name
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150, // Adjust as needed
      render: (text, record) => (
        <a onClick={() => handleAdminView(record._id)}>{text}</a>
      ),
    },

    // Email
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250, // Adjust as needed
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    // Disabled
    {
      title: "Disabled",
      dataIndex: "disabled",
      key: "disabled",
      render: (disabled) =>
        disabled ? (
          <Tag color="red">Disabled</Tag>
        ) : (
          <Tag color="#87d068">Active</Tag>
        ),
    },
    // Join Date
    {
      title: "Join Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150, // Adjust as needed
      render: (text) => formatDate(text),
    },
    // Plan
    {
      title: "Plan",
      dataIndex: "currentPlan",
      key: "currentPlan",
      render: (text) => <Tag color="#461cea">{text}</Tag>,
    },
    // Billing Admin
    {
      title: "Billing Admin",
      dataIndex: "billingAdmin",
      key: "billingAdmin",
      render: (billingAdmin) =>
        billingAdmin ? (
          <Tag color="#87d068">Yes</Tag>
        ) : (
          <Tag color="red">No</Tag>
        ),
    },
    // No. of Chatbots
    {
      title: "No. of Chatbots",
      dataIndex: "chatbots",
      key: "chatbots",
      render: (chatbots) => <Tag color="purple">{chatbots.length}</Tag>,
    },
    // Team Size
    {
      title: "Team Size",
      dataIndex: "team",
      key: "team",
      render: (team) => (
        <Tag
          color="#2db7f5
      "
        >
          {team.length}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => handleAdminView(record._id)}
                icon={<RiEyeFill size={16} />}
              >
                View Admin
              </Menu.Item>
              <Menu.Item
                key={0}
                onClick={() => handleToggleUserDisable(record.user)}
                icon={
                  <MdOutlinePersonAddDisabled
                    size={16}
                    style={{ marginRight: "14px" }}
                  />
                }
              >
                {record.disabled ? "Enable" : "Disable"} Account
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => handleDeleteUserAccount(record.user)}
                icon={<RiDeleteBin6Line size={16} />}
              >
                Delete Account
              </Menu.Item>
            </Menu>
          }
        >
          <Button size="small" style={{ border: "none" }}>
            <FiMoreVertical size={24} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Card style={{ borderRadius: "0", height: "100vh" }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h3>All Admins</h3>
        </Col>
        <Col>
          <Search
            placeholder="Search by name/email"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: "300px", marginLeft: 16 }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={admins}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        loading={loading}
        onChange={({ current, pageSize }) =>
          handlePaginationChange(current, pageSize)
        }
        rowKey={(record) => record._id}
        scroll={{ y: "calc(100vh - 270px)" }} // Adjust height as needed
      />
    </Card>
  );
};

export default AdminList;
