import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Row, Upload, message, Input, Divider } from "antd";
import { useParams } from "react-router";
import { shallow } from "zustand/shallow";
import { uploadChatbotMedia } from "../../../../../../../../../api/chatbot/chatbot";
import { ImageChoiceNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { RiAddLine, RiLoaderLine } from "react-icons/ri";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { PiDotsNineBold } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const ImageChoiceNodeDataEditor: FC<ImageChoiceNode> = ({ id, type }) => {
  const { bid } = useParams<{ bid: string }>();

  const { getNode, changeNodeData } = useStore(selector, shallow);
  const [loading, setLoading] = useState(false);

  const state = getNode(id);

  const [questionText, setQuestionText] = useState(state?.data?.questionText);
  const [images, setImages] = useState(state?.data?.images || []);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  const updateTimeout = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }
    };
  }, []);

  // update the images state
  useEffect(() => {
    setImages(state?.data?.images);
    setQuestionText(state?.data?.questionText);
  }, [state]);

  // Handle Question Text Change
  const handleQuestionTextChange = useCallback(
    (content: any) => {
      if (!state) return;
      setQuestionText(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle adding a new image
  const handleAddImage = useCallback(() => {
    const imageId = uuidv4();
    const newImage = {
      id: imageId,
      image: "",
      answerVariable: "",
    };
    setActive(imageId);
    setImages([...images, newImage]);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        images: [...images, newImage],
      },
    });
  }, [images, state, type, changeNodeData]);

  // Handle deleting a image
  const handleDeleteImage = useCallback(
    (imageId: any) => {
      const updatedImages = images.filter((image: any) => image.id !== imageId);

      setImages(updatedImages);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          images: updatedImages,
        },
      });
    },
    [images, state, type, changeNodeData]
  );

  // Handle Image label change
  const handleImageLabelChange = useCallback(
    (value: any, imgId: any) => {
      const updatedImageIndex = images.findIndex(
        (image: any) => image.id === imgId
      );

      if (updatedImageIndex < 0) return;

      let updatedImages = [...images];
      updatedImages[updatedImageIndex] = {
        ...updatedImages[updatedImageIndex],
        label: value,
      };

      setImages(updatedImages);

      if (!state) return;

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          images: updatedImages,
        },
      });
    },
    [images, state, type, changeNodeData]
  );
  // Handle welcome image upload
  const beforeUpload = (file: any) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG/GIF file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image/GIF must smaller than 5MB!");
    }
    return isJpgOrPng && isLt5M;
  };

  // Handle upload image
  const handleUploadImage = async (options: any, imgId: any) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    try {
      setLoading(true);
      const res = await uploadChatbotMedia(bid, fmData, config);

      const updatedImage = res.data.url;

      const updatedImages = images.map((image: any) => {
        if (image.id === imgId.toString()) {
          return { ...image, image: updatedImage };
        }
        return image;
      });

      setImages(updatedImages);

      changeNodeData({
        ...state,
        type,
        data: { ...state.data, images: updatedImages },
      });

      setLoading(false);
      message.success("Image/GIF updated successfully");
    } catch (error) {
      onError(error);
      setLoading(false);
      message.error("Error updating Image/GIF");
    }
  };

  // OnDrag Function
  const onDragEnd = (e: any) => {
    const { sourceIndex, targetIndex } = e;
    setImages((prevQuestions: any) => {
      const updatedCards = [...prevQuestions];
      const [movedCard] = updatedCards.splice(sourceIndex, 1);
      updatedCards.splice(targetIndex, 0, movedCard);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state.data,
          images: updatedCards,
        },
      });
      return updatedCards;
    });
  };

  // Open and Hide toggle Function
  const toggleDropdown = (id: any) => {
    setIsOpen(!isOpen);
    setActive(id);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [active]);

  const uploadButton = (
    <div style={{ width: "100%", borderRadius: "15px" }}>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>
        {t("flowBuilder.sidebar.editor.flowOperationsNodes.upload")}
      </div>
    </div>
  );

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t(
            "flowBuilder.sidebar.editor.flowOperationsNodes.imageChoiceNodeInfo"
          )}
        </p>
      </Row>

      <div
        style={{
          height: "10px",
          width: "100%",
        }}
      />

      {/* Question Text */}
      <Divider orientation="left">
        <Col
          span={24}
          style={{ alignSelf: "start" }}
          className="sidebar__heading"
        >
          {t("flowBuilder.sidebar.editor.flowOperationsNodes.questionText")}
        </Col>
      </Divider>

      <Col span={24} style={{ width: "100%" }}>
        <div style={{ marginTop: "6px", marginBottom: "10px" }}>
          <QuillEditor
            initialContent={questionText}
            onEditorChange={handleQuestionTextChange}
            placeholderText={t(
              "flowBuilder.sidebar.editor.flowOperationsNodes.questionTextPH"
            )}
          />
        </div>
      </Col>

      <Col
        span={24}
        className="drag_multi_options_container"
        style={{ marginBottom: "25px" }}
      >
        {images?.map((image: any, index: any) => (
          <>
            <Col
              className={`drag_multi_options_inner ${
                isOpen && active === image.id ? "open" : ""
              }`}
            >
              <div
                className="drag_dropdown_button"
                onClick={() => toggleDropdown(image?.id)}
                draggable
                onDragStart={(e) =>
                  e.dataTransfer.setData("text/plain", index.toString())
                }
                onDrop={(e) => {
                  e.preventDefault();
                  onDragEnd({
                    sourceIndex: Number(e.dataTransfer.getData("text/plain")),
                    targetIndex: index,
                  });
                }}
                onDragOver={(e) => e.preventDefault()}
                style={{
                  position: "relative",
                  border:
                    isOpen && active === image.id
                      ? "2px solid #fff"
                      : "2px solid transparent",
                  boxShadow:
                    isOpen &&
                    active === image.id &&
                    "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <Col>
                  <div
                    style={{
                      left: "10px",
                    }}
                  >
                    {t("flowBuilder.sidebar.editor.flowOperationsNodes.image")}{" "}
                    {index + 1}
                  </div>
                </Col>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    type="text"
                    icon={<PiDotsNineBold />}
                    size={"small"}
                    className="drag_button"
                  />
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    size={"small"}
                    style={{ borderRadius: "4px" }}
                    onClick={() => handleDeleteImage(image.id)}
                    className="drag_dlt_button"
                  />
                </div>
              </div>
              {isOpen && active === image.id && (
                <div className="drag_isOpen_container">
                  <Row>
                    <Col span={24}>
                      <Input
                        placeholder={t(
                          "flowBuilder.sidebar.editor.flowOperationsNodes.imageLabelPH"
                        )}
                        value={image.label || ""}
                        onChange={(e) =>
                          handleImageLabelChange(e.target.value, image.id)
                        }
                        style={{
                          marginTop: "5px",
                          borderRadius: "3px",
                          marginBottom: "8px",
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      {/* Handle Image upload here */}
                      <Col span={16}>
                        <Upload
                          name="imageChoice"
                          listType="picture-card"
                          customRequest={(options) =>
                            handleUploadImage(options, image.id)
                          }
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          multiple={false}
                          accept="image/*,image/gif"
                        >
                          {image.image ? (
                            <img
                              src={image?.image}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Col>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </>
        ))}

        <Col span={24}>
          <Button
            onClick={handleAddImage}
            block
            type="primary"
            icon={<PlusOutlined />}
          >
            {t("flowBuilder.sidebar.editor.flowOperationsNodes.addImage")}
          </Button>
        </Col>
      </Col>

      <SaveAnswer id={id} type={type} variableName={answerVariable} />
    </>
  );
};

export default ImageChoiceNodeDataEditor;
