import React, { useState, useEffect } from "react";
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { updateTutorialStatusAction } from "../../../redux/actions/adminActions";
import { redirectToDashboard } from "../../../utils/roleBasedRedirect";
import logoSmall from "../../../assets/images/logo/logo.png";

import MenuLogo from "./logo";
import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";
// Guide Tour
import { Tour } from "../../../components";
import {
  adminSidebarSteps,
  memberSidebarSteps,
} from "../../../helpers/shepherd/sidebarSteps";
import { fireConfetti } from "../../../utils/confetti";

const { Sider } = Layout;

const Sidebar = (props) => {
  const dispatch = useDispatch();

  const { visible, setVisible } = props;

  // Redux
  const customize = useSelector((state) => state.customize);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [sidebarTour, setSidebarTour] = useState(
    user?.tutorials_complete?.sideBar ||
      JSON.parse(localStorage.getItem("shepherd"))?.sideBar
  );

  // Collapsed
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const shepherdData = JSON.parse(localStorage.getItem("shepherd"));
    if (shepherdData) {
      // Check each tutorial status and update the server if it's not already updated
      if (!user?.tutorials_complete?.sideBar && sidebarTour) {
        dispatch(updateTutorialStatusAction(shepherdData));
      }
    }
  }, []);

  useEffect(() => {
    if (customize.sidebarCollapsed) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [customize]);

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };

  // Menu
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  // Handle Sidebar Guide Tour finish
  const handleSidebarTourComplete = (_, completionStatus) => {
    if (completionStatus) {
      // Set sidebarTour status to true
      setSidebarTour(true);

      // Update LocalStorage guideTour status
      const existingData = localStorage.getItem("shepherd");
      const parsedData = JSON.parse(existingData);
      const updatedData = { ...parsedData, sideBar: true };

      localStorage.setItem("shepherd", JSON.stringify(updatedData));

      // Update server with tutorial status
      dispatch(updateTutorialStatusAction(updatedData));

      fireConfetti();
    }
  };

  return (
    <>
      {/* Guide Tour */}
      {user?.role !== "superadmin" && !loading && !sidebarTour && (
        <Tour
          steps={
            user?.role === "admin" ? adminSidebarSteps : memberSidebarSteps
          }
          tutComplete={sidebarTour}
          tourId="sidebarTour"
          handleTourComplete={handleSidebarTourComplete}
        />
      )}

      {user && (
        <>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={266}
            className="hp-sidebar"
            style={{
              background: "#fff",
              borderRight: "1px solid #e8e8e8",
            }}
          >
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ type: "spring", duration: 0.3, delay: 0.1 }}
              className="hp-d-flex hp-h-100"
              style={{
                flexDirection: "column",
              }}
            >
              <Row align="bottom" justify="space-between">
                {/* Expanded Logo */}
                <Col>
                  {collapsed === false ? <MenuLogo onClose={onClose} /> : ""}
                </Col>

                {/* Collapse or Expand Icon */}
                {customize.sidebarCollapseButton &&
                  (collapsed === false ? (
                    <Col className="hp-pr-0">
                      <div className="hp-cursor-pointer" onClick={toggle}>
                        <svg
                          width="2em"
                          height="2em"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#636E71"
                            d="m19.095 8.43l-1.424-1.404l-4.914 4.985l4.985 4.914l1.404-1.424l-2.502-2.467l6.497.05l.016-2l-6.628-.05zM5.467 15.562l1.416 1.412l4.944-4.956l-4.956-4.943L5.459 8.49l2.591 2.585l-7.206.024l.006 2l7.097-.024z"
                          ></path>
                        </svg>
                      </div>
                    </Col>
                  ) : (
                    <Col span={24} className="hp-d-flex-full-center">
                      <div className="hp-cursor-pointer" onClick={toggle}>
                        <svg
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                        >
                          <path
                            fill="#636E71"
                            d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"
                          ></path>
                        </svg>
                      </div>
                    </Col>
                  ))}

                {/* Collapsed Logo */}
                {collapsed && (
                  <Col span={24} className="hp-mt-12 hp-d-flex-full-center">
                    <Link to={redirectToDashboard(user)} onClick={onClose}>
                      <img
                        className="hp-logo"
                        src={logoSmall}
                        alt="logo"
                        style={{ backgroundColor: "transparent" }}
                      />
                    </Link>
                  </Col>
                )}
              </Row>

              {/* Sidebar Items */}
              <MenuItem onClose={onClose} />

              {/* Footer */}
              <MenuFooter
                onClose={onClose}
                collapsed={collapsed}
                mobileMenu={false}
              />
            </motion.div>
          </Sider>
          <MenuMobile onClose={onClose} visible={visible} />
        </>
      )}
    </>
  );
};

export default Sidebar;
