import { Row, Button } from "antd";
import LiveChat from "../../../../assets/images/pages/embed/live-chat.png";
import Popup from "../../../../assets/images/pages/embed/popup.png";
import FullPage from "../../../../assets/images/pages/embed/full-page.png";
import { useTranslation } from "react-i18next";

const EmbedType = ({ embedType, handleEmbedTypeChange }) => {
  const isSelected = (type) => embedType === type;

  const { t } = useTranslation();

  return (
    <>
      <h5>{t("share.embedTypeHeading")}</h5>
      <Row>
        <div>
          <Button
            className={`${isSelected("live_chat") ? "selected-embed" : ""}`}
            style={{
              marginRight: "0.6rem",
              transform: "scale(0.5)",
              marginBottom: "-1rem",
            }}
            onClick={() => {
              handleEmbedTypeChange("live_chat");
            }}
          >
            <img src={LiveChat} alt="Widget" style={{ width: "100px" }} />
          </Button>
          <h5
            style={{
              textAlign: "center",
              marginLeft: "-0.6rem",
            }}
          >
            {t("share.widget")}
          </h5>
        </div>

        <div>
          <Button
            className={`${isSelected("popup_chat") ? "selected-embed" : ""}`}
            style={{
              marginRight: "0.6rem",
              transform: "scale(0.5)",
              marginBottom: "-1rem",
            }}
            onClick={() => {
              handleEmbedTypeChange("popup_chat");
            }}
          >
            <img src={Popup} alt="Popup" style={{ width: "100px" }} />
          </Button>
          <h5
            style={{
              textAlign: "center",
              marginLeft: "-0.6rem",
            }}
          >
            {t("share.popUp")}
          </h5>
        </div>

        <div>
          <Button
            className={`${isSelected("fullpage_chat") ? "selected-embed" : ""}`}
            style={{
              marginRight: "0.6rem",
              transform: "scale(0.5)",
              marginBottom: "-1rem",
            }}
            onClick={() => {
              handleEmbedTypeChange("fullpage_chat");
            }}
          >
            <img src={FullPage} alt="Full Page" style={{ width: "100px" }} />
          </Button>
          <h5
            style={{
              textAlign: "center",
              marginLeft: "-0.6rem",
            }}
          >
            {t("share.fullPage")}
          </h5>
        </div>
      </Row>

      {/* Selected Embed Type */}
      <div>
        <h5
          style={{
            marginTop: "1rem",
            marginBottom: "-1rem",
            fontWeight: "bold",
          }}
        >
          {t("share.selectedEmbedType")}
          {embedType === "live_chat"
            ? t("share.widget")
            : embedType === "popup_chat"
            ? t("share.popUp")
            : t("share.fullPage")}
        </h5>
      </div>
    </>
  );
};

export default EmbedType;
