import {
  // Find and display node
  _findAndDisplayNode,
  _findAndDisplayNodeWithPort,
  // Current steps and index
  _getCurrentStepsAndIndex,
  // Variables
  _setVariables,
  _getValueFromAnswerVariableKey,
  _getNodeIdFromAnswerVariableKey,
  _setAnswerVariable,
} from "../../ChatbotFlowPreview";

// Handle condition Node
export const _handleConditionNode = () => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  let conditionToEval: boolean = false;

  // Evaluate the condition
  const currentStep = steps[currentIndex].data;
  const isNumber = currentStep.isNumber;
  const leftValue = _getValueFromAnswerVariableKey(currentStep.leftValue);
  const rightValue = _getValueFromAnswerVariableKey(currentStep.rightValue);
  const operator = currentStep.operator;

  // Convert values to Number if isNumber is true
  const leftVal = isNumber ? Number(leftValue) : leftValue;
  const rightVal = isNumber ? Number(rightValue) : rightValue;

  switch (operator) {
    case "<":
      conditionToEval = isNumber ? leftVal < rightVal : false;
      break;
    case ">":
      conditionToEval = isNumber ? leftVal > rightVal : false;
      break;
    case "=":
      conditionToEval = leftVal === rightVal;
      break;
    case "!=":
      conditionToEval = leftVal !== rightVal;
      break;
    case "<=":
      conditionToEval = isNumber ? leftVal <= rightVal : false;
      break;
    case ">=":
      conditionToEval = isNumber ? leftVal >= rightVal : false;
      break;
    case "contains":
      conditionToEval = !isNumber && leftVal.includes(rightVal);
      break;
    case "does not contain":
      conditionToEval = !isNumber && !leftVal.includes(rightVal);
      break;
    case "starts with":
      conditionToEval = !isNumber && leftVal.startsWith(rightVal);
      break;
    case "ends with":
      conditionToEval = !isNumber && leftVal.endsWith(rightVal);
      break;
    case "matches":
      conditionToEval = !isNumber && new RegExp(rightVal).test(leftVal);
      break;
    case "does not match":
      conditionToEval = !isNumber && !new RegExp(rightVal).test(leftVal);
      break;
    // Add more cases if there are other operators to consider
    default:
      break;
  }

  // Determine the source key based on the condition evaluation
  const sourceKey = conditionToEval ? "source-0" : "source-1";

  // Call the _findAndDisplayNodeWithPort function
  _findAndDisplayNodeWithPort(sourceKey);
};

// Handle math operation Node
export const _handleMathOperationNode = () => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Retrieve the current step's data
  const currentStep = steps[currentIndex].data;
  const leftValue = _getValueFromAnswerVariableKey(currentStep.leftValue);
  const rightValue = _getValueFromAnswerVariableKey(currentStep.rightValue);
  const operator = currentStep.operator;

  // Convert values to Number for math operations
  const leftVal = Number(leftValue);
  const rightVal = Number(rightValue);

  let result = 0;

  switch (operator) {
    case "+":
      result = leftVal + rightVal;
      break;
    case "-":
      result = leftVal - rightVal;
      break;
    case "*":
      result = leftVal * rightVal;
      break;
    case "/":
      if (rightVal !== 0) {
        result = leftVal / rightVal;
      } else {
        result = 0;
      }

      break;
    // Add more cases if there are other operators to consider
    default:
      // Handle invalid operator error
      break;
  }

  // Set the answer variable (send node id and value)
  _setAnswerVariable(steps[currentIndex].id, result);

  // Call the function to move to the next node or action
  _findAndDisplayNode();
};

// Handle business hours choice node
export const _handleBusinessHoursNode = (nodeData: any) => {
  // The nodeData contains the timezone, excludedDays, excludedDates, weeklyHours
  // If the chatbot visitors current local time falls within the above data, them find the nextNode with id source-0, else source-1
  // Extract the necessary data from nodeData
  const { timezone, excludeDays, excludeDates, weeklyHours } = nodeData;

  // Convert current time to the specified timezone
  const options: any = {
    timeZone: timezone,
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };
  const currentTime = new Date().toLocaleTimeString("en-US", options);

  // Get the current day name and date in numbers
  const dayOptions: any = { timeZone: timezone, weekday: "long" };
  const currentDayName = new Date().toLocaleDateString("en-US", dayOptions);

  const dateOptions: any = {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const currentDateInNumbers = new Date().toLocaleDateString(
    "en-US",
    dateOptions
  );
  const formattedDate = currentDateInNumbers.split("/").reverse().join("-");

  let sourceKey;

  // Check if the current day or date is excluded
  if (
    excludeDays.includes(currentDayName) ||
    excludeDates.includes(formattedDate)
  ) {
    sourceKey = "source-1";
    _findAndDisplayNodeWithPort(sourceKey);
    return;
  }

  const isTimeWithinSlot = (
    currentTime: any,
    startTime: any,
    endTime: any,
    timezone: any
  ) => {
    const options: any = {
      timeZone: timezone,
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    };
    const currentDate = new Date();

    const startDateTime = new Date(
      currentDate.toLocaleDateString("en-US") + " " + startTime
    ).toLocaleTimeString("en-US", options);
    const endDateTime = new Date(
      currentDate.toLocaleDateString("en-US") + " " + endTime
    ).toLocaleTimeString("en-US", options);

    return currentTime >= startDateTime && currentTime <= endDateTime;
  };

  // Check if the current time is within the available business hours
  const currentDayHours = weeklyHours.find(
    (item: any) => item.dayName === currentDayName
  );
  if (currentDayHours && currentDayHours.available) {
    for (const slot of currentDayHours.slots) {
      if (isTimeWithinSlot(currentTime, slot.start, slot.end, timezone)) {
        sourceKey = "source-0";
        _findAndDisplayNodeWithPort(sourceKey);
        return;
      }
    }
  }

  sourceKey = "source-1";
  _findAndDisplayNodeWithPort(sourceKey);
};

// Handle variable Node
export const _handleVariableNode = () => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Evaluate the condition
  const currentStep = steps[currentIndex].data;
  const isCustomNameValue = currentStep.customNameValue;
  const name = currentStep.name;
  const isNumber = currentStep.isNumber;
  const value = currentStep.value;

  // Convert values to Number if isNumber is true
  const val = isNumber ? Number(value) : value;

  // If isCustomNameValue is true, then its a new answerVariable and we can set it using this node id
  if (isCustomNameValue) {
    // Set the answer variable (send node id and value)
    _setAnswerVariable(steps[currentIndex].id, val);
  } else {
    // It means that the answerVariable already exists and belongs to a different node
    // Get the node id from the answerVariable name
    const nodeId = _getNodeIdFromAnswerVariableKey(name);

    // Set the answer variable (send node id and value)
    _setAnswerVariable(nodeId, val);
  }

  // Set the variable
  _setVariables(name, val);

  // Call the _findAndDisplayNode function
  _findAndDisplayNode();
};

// Handle boolean logic Node
export const _handleBooleanLogicNode = () => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  let booleanLogicResult = false;

  // Get the current step data
  const currentStep = steps[currentIndex].data;
  const leftValue = _getValueFromAnswerVariableKey(currentStep.leftValue);
  const rightValue = _getValueFromAnswerVariableKey(currentStep.rightValue);
  const operator = currentStep.operator;

  // Convert the values to boolean if they are not already
  const leftValBool = Boolean(leftValue);
  const rightValBool = Boolean(rightValue);

  // Handling different boolean operators
  switch (operator) {
    case "AND":
      booleanLogicResult = leftValBool && rightValBool;
      break;
    case "OR":
      booleanLogicResult = leftValBool || rightValBool;
      break;
    case "NOT":
      // Interpreted as 'left NOT right' - true if left is true and right is false
      booleanLogicResult = leftValBool && !rightValBool;
      break;
    case "XOR":
      booleanLogicResult = leftValBool !== rightValBool;
      break;
    case "NAND":
      booleanLogicResult = !(leftValBool && rightValBool);
      break;
    case "NOR":
      booleanLogicResult = !(leftValBool || rightValBool);
      break;
    case "XNOR":
      booleanLogicResult = leftValBool === rightValBool;
      break;
    // Add more cases if there are other operators to consider
    default:
      break;
  }

  // Determine the source key based on the boolean logic evaluation
  const sourceKey = booleanLogicResult ? "source-0" : "source-1";

  // Call the _findAndDisplayNodeWithPort function
  _findAndDisplayNodeWithPort(sourceKey);
};

// Handle random flow Node
export const _handleRandomFlowNode = () => {
  // Get _getCurrentStepsAndIndex
  const { steps, currentIndex } = _getCurrentStepsAndIndex();

  // Get the current step data
  const currentStep = steps[currentIndex].data;
  const branches = currentStep.branches;

  let totalWeight = 0;
  let accumulatedWeights: any = [];

  // Calculate total weight and accumulate weights for probability distribution
  branches.forEach((branch: any, index: any) => {
    totalWeight += branch.weight;
    accumulatedWeights[index] = totalWeight;
  });

  // Generate a random number between 0 and totalWeight
  const randomNum = Math.random() * totalWeight;

  // Determine the branch based on the random number
  let selectedBranchIndex = accumulatedWeights.findIndex(
    (accumulatedWeight: any) => randomNum < accumulatedWeight
  );

  console.log("selected branch index", selectedBranchIndex);

  // If for some reason the selected branch index is -1 (not found), default to the first branch
  if (selectedBranchIndex === -1) {
    selectedBranchIndex = 0;
  }

  // Assuming there's a method to get the source key of the selected branch
  const sourceKey = `source-${selectedBranchIndex}`;

  // Call the _findAndDisplayNodeWithPort function using the selected branch's source key
  _findAndDisplayNodeWithPort(sourceKey);
};
