import React, { useState, useEffect, useCallback, FC } from "react";
import { Row, Col, Select } from "antd";
import { shallow } from "zustand/shallow";
import { MathOperationNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import { extractAnswerVariables } from "../../../../../helpers/extractAnswerVariables";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { useTranslation } from "react-i18next";

const MathOperationNodeDataEditor: FC<MathOperationNode> = ({ id, type }) => {
  const { getNode, changeNodeData, nodes } = useStore(selector, shallow);
  const state = getNode(id);
  const { t } = useTranslation();

  const [leftValue, setLeftValue] = useState(state?.data?.leftValue);
  const [operator, setOperator] = useState(state?.data?.operator || "+");
  const [rightValue, setRightValue] = useState(state?.data?.rightValue);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );

  const [allAnswerVariables, setAllAnswerVariables] = useState([]);

  useEffect(() => {
    setLeftValue(state?.data?.leftValue);
    setOperator(state?.data?.operator);
    setRightValue(state?.data?.rightValue);
    setAnswerVariable(state?.data?.answerVariable);

    // Get all answer variables from nodes
    const answerVariables = extractAnswerVariables(nodes);
    setAllAnswerVariables(answerVariables);
  }, [id, state?.data, nodes]);

  const handleLeftValueChange = useCallback(
    (value: any) => {
      setLeftValue(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state.data, leftValue: value },
      });
    },
    [state, changeNodeData]
  );

  const handleOperatorChange = useCallback(
    (value: any) => {
      setOperator(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state.data, operator: value },
      });
    },
    [state, changeNodeData]
  );

  const handleRightValueChange = useCallback(
    (value: any) => {
      setRightValue(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state.data, rightValue: value },
      });
    },
    [state, changeNodeData]
  );

  // Define math operators with their names and symbols
  const mathOperators = [
    { name: "Add", symbol: "+" },
    { name: "Subtract", symbol: "-" },
    { name: "Multiply", symbol: "*" },
    { name: "Divide", symbol: "/" },
  ];

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.logicalNodes.mathOperationNodeInfo")}
          </p>
        </Col>

        {/* Left Value */}
        <Col span={24}>
          <label>
            {t("flowBuilder.sidebar.editor.logicalNodes.leftValue")}
          </label>
          <Select
            style={{ width: "100%" }}
            value={leftValue}
            onChange={handleLeftValueChange}
          >
            {allAnswerVariables.map((variable) => (
              <Select.Option key={variable} value={variable}>
                {variable}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {/* Operator */}
        <Col span={24}>
          <label>{t("flowBuilder.sidebar.editor.logicalNodes.operator")}</label>
          <Select
            style={{ width: "100%" }}
            value={operator}
            onChange={handleOperatorChange}
          >
            {mathOperators.map((op) => (
              <Select.Option key={op.symbol} value={op.symbol}>
                {`${op.name} (${op.symbol})`}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {/* Right Value */}
        <Col span={24}>
          <label>
            {t("flowBuilder.sidebar.editor.logicalNodes.rightValue")}
          </label>
          <Select
            style={{ width: "100%" }}
            value={rightValue}
            onChange={handleRightValueChange}
          >
            {allAnswerVariables.map((variable) => (
              <Select.Option key={variable} value={variable}>
                {variable}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {/* Output Variable */}
        <Col span={24}>
          {/* <label>
            {t("flowBuilder.sidebar.editor.logicalNodes.outputVariable")}
          </label> */}
          <SaveAnswer id={id} type={type} variableName={answerVariable} />
        </Col>
      </Row>
    </>
  );
};

export default MathOperationNodeDataEditor;
