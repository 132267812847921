import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Spin,
  Dropdown,
  Menu,
  Tag,
  message,
  Divider,
  Alert,
  Typography,
  Layout,
} from "antd";
import { PlusOutlined, LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { inviteTeamMember, removeTeamMember } from "../../../api/user/admin";
import { updateTeamMemberAccess } from "../../../api/user/member";
import { getMemberTeamListAction } from "../../../redux/actions/memberActions";
import { getAdminChatbotsNameAction } from "../../../redux/actions/adminActions";
import { validateEmail } from "../../../utils/validations";
import InviteTeamImage from "../../../assets/images/pages/team/invite_team.svg";
import "../../../assets/css/TeamList.css";

const { Option } = Select;
const { Text } = Typography;
const { Content } = Layout;

// Define types for our component props and state
interface TeamMember {
  _id: string;
  name: string;
  email: string;
  role: string;
  isOnline: boolean;
  isEngaged: boolean;
  createdAt: string;
  chatbots: Array<{ _id: string; name: string }>;
}

interface Chatbot {
  _id: string;
  name: string;
}

interface RootState {
  auth: {
    user: {
      role: string;
      email: string;
      currentPlan: string;
    };
  };
  member: {
    teamList: TeamMember[];
  };
  admin: {
    chatbotNames: Chatbot[];
  };
}

const TeamList: React.FC = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux state
  const { user } = useSelector((state: RootState) => state.auth);
  const { teamList } = useSelector((state: RootState) => state.member);
  const { chatbotNames } = useSelector((state: RootState) => state.admin);

  // Local state
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [selectedChatbots, setSelectedChatbots] = useState<string[]>([]);
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [editingMember, setEditingMember] = useState<TeamMember | null>(null);
  const [editingMemberChatbots, setEditingMemberChatbots] = useState<string[]>(
    []
  );
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  // Effect to fetch team members and chatbot names on component mount
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setDataLoaded(false);
      await Promise.all([
        dispatch(getMemberTeamListAction(() => {})),
        dispatch(getAdminChatbotsNameAction(() => {})),
      ]);
      setIsLoading(false);
      setDataLoaded(true);
    };

    fetchData();
  }, [dispatch]);

  // Function to show the invite modal
  const showModal = () => {
    if (user.currentPlan === "Free") {
      message.info(t("team.upgradeToInvite"));
      return;
    }
    setIsModalVisible(true);
  };

  // Function to handle modal cancellation
  const handleCancel = () => {
    setIsModalVisible(false);
    setEmail("");
    setRole("");
    setSelectedChatbots([]);
  };

  // Function to handle adding a new team member
  const handleAddTeammate = () => {
    if (user.currentPlan === "Free") {
      message.info(t("team.upgradeToInvite"));
      return;
    }

    // Check team size limits based on the current plan
    const maxTeamSize =
      user.currentPlan === "Business"
        ? 16
        : user.currentPlan === "Pro"
        ? 6
        : user.currentPlan === "Starter" || user.currentPlan === "Lifetime"
        ? 2
        : 1;

    if (teamList.length >= maxTeamSize) {
      message.info(t("team.maxTeamSizeReached"));
      return;
    }

    setInviteLoading(true);

    if (!validateEmail(email)) {
      message.error(t("team.emailError"));
      setInviteLoading(false);
      return;
    }

    const dataObject = {
      email: email,
      role: role,
      chatbots:
        role === "admin" ? chatbotNames.map((c) => c._id) : selectedChatbots,
    };

    inviteTeamMember(dataObject)
      .then(() => {
        message.success(t("team.inviteSent"));
        setEmail("");
        setRole("");
        setIsModalVisible(false);
        setInviteLoading(false);
      })
      .catch((err) => {
        message.error(t("team.somethingWentWrong"));
        setInviteLoading(false);
        console.error("Error: ", err);
      });
  };

  // Function to show delete confirmation modal
  const showDeleteConfirm = (uid: string) => {
    Modal.confirm({
      title: t("team.wantsToRemoveTeamMember"),
      content: t("team.actionNotDone"),
      okText: t("team.okText"),
      okType: "danger",
      cancelText: t("team.no"),
      onOk() {
        removeTeamMember(uid)
          .then(() => {
            setIsLoading(true);
            message.success(t("team.removeTeammate"));
            dispatch(getMemberTeamListAction(() => setIsLoading(false)));
          })
          .catch((err) => {
            setIsLoading(false);
            console.error(err);
            message.error(t("team.failedToRemoveTeammate"));
          });
      },
    });
  };

  // Function to show edit member modal
  const showEditModal = (member: TeamMember) => {
    setEditingMember(member);
    setEditingMemberChatbots(member.chatbots.map((chatbot) => chatbot._id));
    setIsEditModalVisible(true);
  };

  // Function to handle edit modal cancellation
  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setEditingMember(null);
    setEditingMemberChatbots([]);
  };

  // Function to handle editing member access
  const handleEditAccess = () => {
    if (!editingMember) return;

    const dataObject = {
      userId: editingMember._id,
      chatbots: editingMemberChatbots,
    };

    updateTeamMemberAccess(dataObject)
      .then(() => {
        message.success(t("team.accessUpdated"));
        handleEditCancel();
        dispatch(getMemberTeamListAction(() => {}));
      })
      .catch((err) => {
        message.error(t("team.failedToUpdateAccess"));
        console.error("Error: ", err);
      });
  };

  // Function to handle chatbot tag click in edit modal
  const handleChatbotTagClick = (chatbotId: string) => {
    if (editingMember?.role === "admin") return;

    setEditingMemberChatbots((prev) =>
      prev.includes(chatbotId)
        ? prev.filter((id) => id !== chatbotId)
        : [...prev, chatbotId]
    );
  };

  // Table columns configuration
  const columns: Array<{
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: any, record: TeamMember) => React.ReactNode;
  }> = [
    {
      title: t("team.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("team.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("team.role"),
      dataIndex: "role",
      key: "role",
      render: (role: string) => (
        <Tag color={role === "admin" ? "geekblue" : "green"}>
          {role.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("team.status"),
      dataIndex: "isOnline",
      key: "isOnline",
      render: (_: any, record: TeamMember) =>
        record.isEngaged ? (
          <span style={{ color: "red" }}>● {t("team.engaged")}</span>
        ) : record.isOnline ? (
          <span style={{ color: "green" }}>● {t("team.online")}</span>
        ) : (
          <span style={{ color: "grey" }}>● {t("team.offline")} </span>
        ),
    },
    {
      title: t("team.joinDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("Do MMMM, YYYY"),
    },
  ];

  // Add actions column for admin users
  if (user.role === "admin") {
    columns.push({
      title: t("team.actions"),
      key: "actions",
      dataIndex: "actions", // Add this line to fix the TypeScript error
      render: (_: any, record: TeamMember) => {
        if (record.email !== user.email) {
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={() => showEditModal(record)}>
                    {t("team.editAccess")}
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => showDeleteConfirm(record._id)}
                  >
                    {t("team.removeMember")}
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        } else {
          return <Tag color="blue">{t("team.me")}</Tag>;
        }
      },
    });
  }

  // Function to render the main content
  const renderContent = () => {
    if (isLoading || !dataLoaded) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 200px)",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (!teamList.length) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 200px)",
          }}
        >
          <img
            src={InviteTeamImage}
            alt="Invite your team"
            style={{ width: "40%", height: "40%", objectFit: "contain" }}
          />
          <Text
            strong
            style={{ fontSize: "1.5rem", color: "#2c3e50", marginTop: "50px" }}
          >
            👥 {t("team.teamWorkLine")} 🚀
          </Text>
        </div>
      );
    }

    return (
      <Table
        dataSource={teamList}
        columns={columns}
        pagination={false}
        rowKey={(record) => record._id}
      />
    );
  };

  return (
    <Layout className="team-list-layout">
      <Content className="team-list-content">
        <div className="team-list-header">
          <Text strong style={{ fontSize: "1.5rem", color: "#2c3e50" }}>
            {t("team.myTeam")}
          </Text>
          {user?.role === "admin" && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={showModal}
              size="small"
              className="add-teammate-button"
            >
              {t("team.addTeammate")}
            </Button>
          )}
        </div>
        {renderContent()}

        {/* Invite Teammate Modal */}
        <Modal
          title={t("team.addATeammate")}
          visible={isModalVisible}
          onCancel={handleCancel}
          confirmLoading={inviteLoading}
          onOk={handleAddTeammate}
        >
          <Form layout="vertical">
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: t("team.emailMessage") }]}
            >
              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: t("team.roleMessage") }]}
            >
              <Select
                placeholder={t("team.selectRole")}
                onChange={(value: string) => setRole(value)}
              >
                <Option value="admin">{t("team.admin")}</Option>
                <Option value="agent">{t("team.agent")}</Option>
              </Select>
            </Form.Item>
            {role === "agent" && (
              <Form.Item
                name="chatbots"
                label="Chatbots"
                rules={[{ required: true, message: t("team.chatbotsMessage") }]}
              >
                <Select
                  mode="multiple"
                  placeholder={t("team.selectChatbots")}
                  value={selectedChatbots}
                  onChange={(values: string[]) => setSelectedChatbots(values)}
                >
                  {chatbotNames?.map((chatbot) => (
                    <Option key={chatbot._id} value={chatbot._id}>
                      {chatbot.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form>
        </Modal>

        {/* Edit Access Modal */}
        <Modal
          title={t("team.editAccess")}
          visible={isEditModalVisible}
          onCancel={handleEditCancel}
          onOk={handleEditAccess}
          width={900}
        >
          <div style={{ marginBottom: 24 }}>
            <Text strong>{t("team.role")}:</Text>{" "}
            <Tag color={editingMember?.role === "admin" ? "blue" : "green"}>
              {editingMember?.role.toUpperCase()}
            </Tag>
          </div>

          <Alert
            message={
              editingMember?.role === "admin"
                ? t("team.adminAccessAllDescription")
                : t("team.agentAccessDescription")
            }
            type="warning"
            showIcon
            style={{ marginBottom: 24 }}
          />

          <Divider />

          <div style={{ marginBottom: 16 }}>
            <Text strong>{t("team.accessibleChatbots")}:</Text>
            {editingMember?.role !== "admin" && (
              <Text type="secondary" italic style={{ marginLeft: 8 }}>
                ({t("team.clickToToggleAccess")})
              </Text>
            )}
          </div>

          <div
            style={{
              marginBottom: 24,
              maxHeight: "350px",
              overflowY: "auto",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              padding: "12px",
            }}
          >
            {chatbotNames?.map((chatbot) => (
              <Tag
                key={chatbot._id}
                color={
                  editingMemberChatbots.includes(chatbot._id)
                    ? "blue"
                    : "default"
                }
                style={{
                  cursor:
                    editingMember?.role === "admin" ? "not-allowed" : "pointer",
                  marginBottom: 8,
                  marginRight: 8,
                }}
                onClick={() =>
                  editingMember?.role !== "admin" &&
                  handleChatbotTagClick(chatbot._id)
                }
              >
                {chatbot.name}
              </Tag>
            ))}
          </div>
        </Modal>
      </Content>
    </Layout>
  );
};

export default TeamList;
