import React from "react";
import { Row, Col, Button, Divider } from "antd";
import { useDispatch } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import { OnboardSubscribe, OnboardTestimonial } from "@components";
import { logoutAction } from "@redux/actions/authActions";
import logo from "@assets/images/logo/logo.png";
import { useTranslation } from "react-i18next";

const Subscribe = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Handle logout
  const handleLogout = () => {
    // Dispatch logout action
    dispatch(logoutAction());
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <img
            className="hp-logo"
            src={logo}
            alt="logo"
            style={{
              height: 50,
              width: "auto",
              backgroundColor: "transparent",
              marginTop: "15px",
              marginLeft: "15px",
            }}
          />

          <Button
            size="small"
            style={{
              float: "right",
              marginTop: "15px",
              marginRight: "25px",
            }}
            onClick={handleLogout}
            icon={
              <FiLogOut
                style={{
                  marginRight: "5px",
                }}
              />
            }
          >
            {t("onBoarding.logout")}
          </Button>
        </Col>
      </Row>

      <Row gutter={[32, 0]}>
        <Col
          span={24}
          style={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <OnboardSubscribe />
        </Col>
      </Row>

      <Divider
        className={
          "hp-mt-64 hp-mb-32 hp-border-color-black-40 hp-border-color-dark-80"
        }
      />

      <Row
        align="middle"
        justify="center"
        className="hp-mt-32 hp-mb-32"
        style={{
          padding: "32px",
        }}
      >
        <OnboardTestimonial />
      </Row>
    </div>
  );
};

export default Subscribe;
