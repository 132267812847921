import { combineReducers } from "redux";

// Import reducers
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import chatbotReducer from "./chatbotReducer";
import customizeReducer from "./customizeReducer";
import adminReducer from "./adminReducer";
import memberReducer from "./memberReducer";
import superAdminReducer from "./superAdminReducer";

// Define the RootState interface
export interface RootState {
  auth: ReturnType<typeof authReducer>;
  alert: ReturnType<typeof alertReducer>;
  chatbot: ReturnType<typeof chatbotReducer>;
  customize: ReturnType<typeof customizeReducer>;
  admin: ReturnType<typeof adminReducer>;
  member: ReturnType<typeof memberReducer>;
  superAdmin: ReturnType<typeof superAdminReducer>;
}

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  chatbot: chatbotReducer,
  customize: customizeReducer,
  admin: adminReducer,
  member: memberReducer,
  superAdmin: superAdminReducer,
});

export { rootReducer };
