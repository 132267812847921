// Function to open image preview modal
export const _handleOpenImagePreview = (imageSrc: any) => {
  // Create the backdrop
  const backdrop = document.createElement("div");
  backdrop.className = "image-preview-backdrop";

  // Create the modal
  const modal = document.createElement("div");
  modal.className = "image-preview-modal";

  // Populate the modal with content
  modal.innerHTML = `
    <span class="image-preview-close">&times;</span>
    <img class="image-preview-img" src="${imageSrc}" alt="Preview">
  `;

  // Append backdrop and modal to body or main chat container
  document.body.appendChild(backdrop);
  document.body.appendChild(modal);

  // Add the 'active' class to both elements to start the animation
  setTimeout(() => {
    backdrop.classList.add("active");
    modal.classList.add("active");
  }, 10); // setTimeout is used to ensure the CSS transitions take effect

  // Add close event listener to both backdrop and close button
  const closePreview = () => {
    // Remove the 'active' class to start the hide animation
    backdrop.classList.remove("active");
    modal.classList.remove("active");

    // Remove the elements from the DOM after the animation
    setTimeout(() => {
      backdrop.remove();
      modal.remove();
    }, 300); // Should match the transition duration
  };

  modal
    .querySelector(".image-preview-close")
    .addEventListener("click", closePreview);
  backdrop.addEventListener("click", closePreview);
};

// Function to open video preview modal
export const _handleOpenVideoPreview = (videoSrc: any) => {
  // Create the backdrop
  const backdrop = document.createElement("div");
  backdrop.className = "video-preview-backdrop";

  // Create the modal
  const modal = document.createElement("div");
  modal.className = "video-preview-modal";

  // Populate the modal with content
  modal.innerHTML = `
    <span class="video-preview-close">&times;</span>
    <video class="video-preview"
      autoPlay
      loop
      muted
      playsInline>
      <source src="${videoSrc}" type="video/mp4">
    </video>
  `;

  // Append backdrop and modal to body or main chat container
  document.body.appendChild(backdrop);
  document.body.appendChild(modal);

  // Add the 'active' class to both elements to start the animation
  setTimeout(() => {
    backdrop.classList.add("active");
    modal.classList.add("active");
  }, 10); // setTimeout is used to ensure the CSS transitions take effect

  // Add close event listener to both backdrop and close button
  const closePreview = () => {
    // Remove the 'active' class to start the hide animation
    backdrop.classList.remove("active");
    modal.classList.remove("active");

    // Remove the elements from the DOM after the animation
    setTimeout(() => {
      backdrop.remove();
      modal.remove();
    }, 300); // Should match the transition duration
  };

  modal
    .querySelector(".video-preview-close")
    .addEventListener("click", closePreview);
  backdrop.addEventListener("click", closePreview);
};

// Function to open image preview modal
export const _handleOpenImageChoicesPreview = (selectedImage: any) => {
  // Create the backdrop
  const backdrop = document.createElement("div");
  backdrop.className = "image-preview-backdrop";

  // Create the modal
  const modal = document.createElement("div");
  modal.className = "image-preview-modal";

  // Populate the modal with content
  modal.innerHTML = `
    <span class="image-preview-close">&times;</span>
    <img class="image-preview-img" src="${selectedImage?.image}" alt="Preview">
  `;

  // Append backdrop and modal to body or main chat container
  document.body.appendChild(backdrop);
  document.body.appendChild(modal);

  // Add the 'active' class to both elements to start the animation
  setTimeout(() => {
    backdrop.classList.add("active");
    modal.classList.add("active");
  }, 10); // setTimeout is used to ensure the CSS transitions take effect

  // Add close event listener to both backdrop and close button
  const closePreview = () => {
    // Remove the 'active' class to start the hide animation
    backdrop.classList.remove("active");
    modal.classList.remove("active");

    // Remove the elements from the DOM after the animation
    setTimeout(() => {
      backdrop.remove();
      modal.remove();
    }, 300); // Should match the transition duration
  };

  modal
    .querySelector(".image-preview-close")
    .addEventListener("click", closePreview);
  backdrop.addEventListener("click", closePreview);
};
