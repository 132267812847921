// Admin Actions
export const ADMIN_TYPES = {
  // Tutorial Status
  UPDATE_TUTORIAL_STATUS: "UPDATE_TUTORIAL_STATUS",
  // Dashboard
  GET_ADMIN_DASHBOARD_ANALYTICS: "GET_ADMIN_DASHBOARD_ANALYTICS",
  // Chatbot
  GET_ADMIN_CHATBOTS_WITH_TEMPLATES: "GET_ADMIN_CHATBOTS_WITH_TEMPLATES",
  GET_ADMIN_CHATBOTS_NAME: "GET_ADMIN_CHATBOTS_NAME",
  CLEAR_ADMIN_CHATBOTS_WITH_TEMPLATES: "CLEAR_ADMIN_CHATBOTS_WITH_TEMPLATES",
  // Chatbot Responses
  SELECTED_BOT_RESPONSES: "SELECTED_BOT_RESPONSES",
  UPDATE_SELECTED_BOT_RESPONSES: "UPDATE_SELECTED_BOT_RESPONSES",
  UPDATE_CHAT_WITH_LATEST_MESSAGE: "UPDATE_CHAT_WITH_LATEST_MESSAGE",
  ARCHIVE_BOT_RESPONSE: "ARCHIVE_BOT_RESPONSE",
  // Chat Messages
  UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES:
    "UPDATE_SELECTED_CHAT_WITH_LATEST_RESPONSE_MESSAGES",
  // Subscription
  GET_ADMIN_SUBSCRIPTION: "GET_ADMIN_SUBSCRIPTION",
};
