import { useState, useEffect } from "react";
import {
  Col,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Upload,
  message,
  Spin,
} from "antd";
import { RiLoaderLine, RiAddLine } from "react-icons/ri";
import { withRouter } from "react-router";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  getChatbotTemplateById,
  updateChatbotTemplate,
  uploadTemplateMedia,
  getAllCategories,
  getAllTags,
} from "../../../api/template/template";
import { SuccessNotification, ErrorNotification } from "../..";

const initialState: any = {
  name: "",
  description: "",
  coverImage: "",
  category: "",
  tags: [],
  content: {},
  images: [],
};

const TemplateUpdateModal = ({
  open,
  handleModalClick,
  templateId,
  handleFetchChatbots,
}: any) => {
  const initialEditorState = () => EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initialEditorState);
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetchData(); // Invoke the async function
  }, []);

  const fetchData = async () => {
    setFetchLoading(true); // Start loading

    try {
      // Get template by id
      const { data } = await getChatbotTemplateById(templateId);

      // Fetch categories
      const cats = await getAllCategories();
      setCategories(cats.data);

      // Fetch tags
      const tags = await getAllTags();
      setTags(tags.data);

      // Get content state
      const contentState =
        data?.content && convertFromRaw(JSON.parse(data?.content));

      if (contentState) {
        // Set editor state
        setEditorState(EditorState.createWithContent(contentState));
      }

      // Set tags
      data.tags = data.tags.map((tag: any) => tag._id);

      setValues(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFetchLoading(false); // End loading once data is fetched
    }
  };

  // Handle form change
  const handleFormChange = (e: any) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Before image upload
  const beforeUpload = (file: any) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG/GIF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Image/GIF must smaller than 3MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // Handle upload image
  const handleUploadImage = async (options: any) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload image
    try {
      setLoading(true);
      const res = await uploadTemplateMedia(fmData, config);
      setValues({
        ...values,
        coverImage: res.data.url,
      });
      setLoading(false);
    } catch (error) {
      onError(error);
      setLoading(false);
    }
  };

  // Handle chatbot update
  const handleTemplateUpdate = async (e: any) => {
    e.preventDefault();

    // Validate form
    if (
      !values.name ||
      !values.description ||
      !values.category ||
      !values.content
    ) {
      return ErrorNotification("Please fill all the required fields");
    }

    // Start loading
    setLoading(true);
    // Update the chatbot template
    updateChatbotTemplate(templateId, {
      values,
    })
      .then(() => {
        SuccessNotification(`Template updated successfully`);
        handleFetchChatbots();
        handleModalClick();

        // Set loading to false
        setLoading(false);
      })
      .catch((err) => {
        // Set loading to false
        setLoading(false);

        ErrorNotification(err.response.data.message);
      });
  };

  const uploadButton = (
    <div>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  // Upload content image
  const uploadImageCallBack = (file: any, callback: any) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const fmData = new FormData();

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        fmData.append("image", file);
        const res = await uploadTemplateMedia(fmData, config);
        setValues({
          ...values,
          images: [...values.images, res.data.url],
        });
        resolve({ data: { link: res.data.url } });
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Modal
        title={
          <span
            style={{
              color: "#0d6efd",
              fontWeight: 500,
              fontSize: "1.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Update Chatbot Template
          </span>
        }
        visible={open}
        onOk={handleModalClick}
        closable={false}
        width={"94%"}
        style={{ top: "20px" }}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleTemplateUpdate}
            loading={loading}
          >
            Update
          </Button>,
        ]}
      >
        {fetchLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              minHeight: "8rem",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <Form
              labelCol={{
                span: 3,
              }}
              wrapperCol={{
                span: 21,
              }}
            >
              {/* Chatbot name */}
              <Form.Item label="Name">
                <Input
                  name="name"
                  placeholder="Name your bot"
                  onChange={handleFormChange}
                  value={values.name}
                />
              </Form.Item>

              {/* Chatbot description */}
              <Form.Item label="Description">
                <Input.TextArea
                  name="description"
                  placeholder="Describe your bot"
                  onChange={handleFormChange}
                  value={values.description}
                />
              </Form.Item>

              {/* Chatbot cover image */}
              <Form.Item label="Cover Image">
                <Upload
                  name="coverImage"
                  listType="picture-card"
                  customRequest={handleUploadImage}
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  accept="image/*,image/gif"
                >
                  {values.coverImage ? (
                    <img
                      src={values.coverImage}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>

              {/* Select drop down with category */}
              <Form.Item label="Category">
                <Select
                  placeholder="Select a category"
                  onChange={(value) =>
                    setValues({ ...values, category: value })
                  }
                  value={values.category}
                >
                  {categories.map((category) => (
                    <Select.Option key={category._id} value={category.name}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Tags */}
              {/* Select drop down with tags */}
              <Form.Item label="Tags">
                <Select
                  mode="multiple"
                  placeholder="Select tags"
                  onChange={(value) => setValues({ ...values, tags: value })}
                  value={values.tags}
                >
                  {tags?.map((tag) => (
                    <Select.Option key={tag._id} value={tag._id}>
                      {tag.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Template Content */}
              <Form.Item label="Content">
                <Col
                  style={{
                    border: "1px solid #dfe6e9",
                    borderRadius: "8px",
                    padding: "16px",
                    minHeight: "500px",
                  }}
                >
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    ariaLabel="draftEditor"
                    onEditorStateChange={(value) => {
                      setEditorState(value);
                      setValues({
                        ...values,
                        content: JSON.stringify(
                          convertToRaw(value.getCurrentContent())
                        ),
                      });
                    }}
                    toolbar={{
                      inline: { inDropdown: true },
                      fontFamily: {
                        options: ["Arial"],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      image: {
                        uploadCallback: uploadImageCallBack,
                        previewImage: true,
                        inputAccept:
                          "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                        alt: { present: false, mandatory: false },
                        defaultSize: {
                          height: "auto",
                          width: "auto",
                        },
                      },
                    }}
                  />
                </Col>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default withRouter(TemplateUpdateModal);
