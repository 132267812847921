import { FC, useState, useEffect } from "react";
import { Row, Col } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import useStore from "../../../../../store/store";
import { BusinessHoursNodeData } from "../../../typings";
import ContextMenu from "../../../../components/contextMenu/ContextMenu";
import { Calendar } from "@assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const BusinessHoursNode: FC<NodeProps<BusinessHoursNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);

  const { getNode } = useStore((state: any) => ({
    getNode: state?.getNode,
  }));
  const node = getNode(id);
  const { t } = useTranslation();

  useEffect(() => {
    const getNodeId = node?.id;
    setNodeId(getNodeId);
  }, []);

  if (!node) {
    return null; // or some fallback component
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />

      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "250px",
          maxWidth: "250px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={16}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Col
              span={18}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={Calendar}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="Business Hours Choice Node"
              />
              {t("flowBuilder.builderNodes.businessHoursNode")}
            </Col>

            {/* Delete & Duplicate Node */}
            <Col span={4}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>
        <div>
          <Col span={24} style={{ margin: "0px 0px 8px 0px" }}>
            <div
              dangerouslySetInnerHTML={{ __html: node?.data?.timezone }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
              }}
            />
          </Col>
        </div>

        {/* Open */}
        <Row
          align="middle"
          style={{
            width: "100%",
            transition: "border-color 0.3s ease",
            cursor: "all-scroll",
            borderRadius: "3px",
            marginTop: "-3px",
          }}
        >
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{
                __html: t("flowBuilder.builderNodes.open"),
              }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
                background: "#83c645",
                color: "#fff",
              }}
            />
          </Col>

          <Col span={24}>
            <Handle
              type="source"
              position={Position.Right}
              id={`source-0`}
              className="node-source"
              style={{
                background: "#83c645",
                marginTop: "-20px",
              }}
            />
          </Col>
        </Row>

        {/* Closed */}
        <Row
          align="middle"
          style={{
            width: "100%",
            transition: "border-color 0.3s ease",
            cursor: "all-scroll",
            borderRadius: "3px",
            marginTop: "-3px",
          }}
        >
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{
                __html: t("flowBuilder.builderNodes.closed"),
              }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
                background: "#e40000",
                color: "#fff",
              }}
            />
          </Col>

          <Col span={24}>
            <Handle
              type="source"
              position={Position.Right}
              id={`source-1`}
              className="node-source"
              style={{
                background: "#e40000",
                marginTop: "-20px",
              }}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default BusinessHoursNode;
