import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Spin } from "antd";
import { withRouter } from "react-router";
import { getBlogCategoryById, updateBlogCategoryById } from "@api/blog/blog";
import { SuccessNotification, ErrorNotification } from "../../..";

const initialState = {
  name: "",
};

const BlogCategoryUpdateModal = ({
  open,
  handleModalClick,
  fetchCategories,
  categoryId,
}) => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch blog category by id
    fetchBlogCategoryById();
  }, []);

  // Handle fetch blog category by id
  const fetchBlogCategoryById = async () => {
    setLoading(true);
    try {
      const { data } = await getBlogCategoryById(categoryId);

      setValues(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("GET_BLOG_CATEGORY_BY_ID_ERROR", err);
    }
  };

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle blog category update
  const handleBlogCategoryUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validate form
    if (!values.name) {
      setLoading(false);
      return ErrorNotification("Please enter a name");
    }

    // Update blog category
    updateBlogCategoryById(categoryId, values)
      .then(() => {
        setValues(initialState);
        handleModalClick();
        fetchCategories();
        SuccessNotification("Category updated successfully");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response.data.message);
      });
  };

  return (
    <>
      <Modal
        title="Update Blog Category"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleBlogCategoryUpdate}
            loading={loading}
          >
            Update
          </Button>,
        ]}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(80vh - 50px)",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Form
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            {/* Category name */}
            <Form.Item label="Name">
              <Input
                name="name"
                placeholder="Enter blog category name"
                onChange={handleFormChange}
                value={values.name}
                rules={[{ required: true }]}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default withRouter(BlogCategoryUpdateModal);
