import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  MetricsOptionsList,
  Graphs,
  ResponseTable,
  VisitorDemographics,
} from "@components";
import { motion } from "framer-motion";
import { getAdminChatbotsNameAction } from "@redux/actions/adminActions";
import { getChatbotResponsesAnswerVariablesAction } from "@redux/actions/chatbotActions";

const Metrics = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { wid } = useParams();
  const location = useLocation();

  const [initialLoading, setInitialLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedChatbot, setSelectedChatbot] = useState(null);

  const { chatbotNames } = useSelector((state) => state.admin);

  // Function to determine the selected option based on the current URL
  const getSelectedOptionFromUrl = useCallback((path) => {
    if (path.includes("/metrics/graph")) return 1;
    if (path.includes("/metrics/response-table")) return 2;
    if (path.includes("/metrics/demographics")) return 3;
    return 1; // Default to 1 if no match
  }, []);

  useEffect(() => {
    handleFetchChatbotNames();
  }, []);

  useEffect(() => {
    // Set the selected option based on the current URL when the component mounts
    // or when the URL changes
    const option = getSelectedOptionFromUrl(location.pathname);
    setSelectedOption(option);
  }, [location.pathname, getSelectedOptionFromUrl]);

  useEffect(() => {
    if (chatbotNames && chatbotNames.length > 0 && !selectedChatbot) {
      handleSelectChatbot(chatbotNames[0]._id);
    }
    if (location.pathname.includes("/metrics/response-table")) {
      handleOptionSelect(2);
    }
  }, [chatbotNames, selectedChatbot]);

  const handleFetchChatbotNames = useCallback(() => {
    setInitialLoading(true);
    dispatch(
      getAdminChatbotsNameAction(() => {
        setInitialLoading(false);
      })
    );
  }, [dispatch]);

  const handleSelectChatbot = useCallback(
    (botId) => {
      setSelectedChatbot(botId);
      setContentLoading(true);
      dispatch(
        getChatbotResponsesAnswerVariablesAction(botId, () => {
          setContentLoading(false);
        })
      );
    },
    [dispatch]
  );

  const handleOptionSelect = useCallback(
    (option) => {
      setSelectedOption(option);
      switch (option) {
        case 1:
          history.push(`/a/ws/${wid}/metrics/graph`);
          break;
        case 2:
          history.push(`/a/ws/${wid}/metrics/response-table`);
          break;
        case 3:
          history.push(`/a/ws/${wid}/metrics/demographics`);
          break;
        default:
          history.push(`/a/ws/${wid}/metrics/graph`);
      }
    },
    [history, wid]
  );

  if (initialLoading) {
    return (
      <div className="loading-container">
        <Spin
          size="large"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }

  return (
    <Row className="metrics-container">
      <Col span={8} md={6} className="metrics-sidebar">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
          className="metrics-sidebar-content"
        >
          <MetricsOptionsList
            selectedOption={selectedOption}
            setSelectedOption={handleOptionSelect}
            handleSelectChatbot={handleSelectChatbot}
            selectedChatbot={selectedChatbot}
            chatbots={chatbotNames}
          />
        </motion.div>
      </Col>
      <Col span={16} md={18} className="metrics-content">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", duration: 0.5, delay: 0.2 }}
          className="metrics-content-inner"
        >
          {contentLoading ? (
            <div className="content-loading-container">
              <Spin size="large" />
            </div>
          ) : (
            <Switch>
              <Route path={`/a/ws/${wid}/metrics/graph`}>
                <Graphs />
              </Route>
              <Route path={`/a/ws/${wid}/metrics/response-table`}>
                <ResponseTable
                  parentLoading={contentLoading}
                  selectedChatbot={selectedChatbot}
                />
              </Route>
              <Route path={`/a/ws/${wid}/metrics/demographics`}>
                <VisitorDemographics />
              </Route>
            </Switch>
          )}
        </motion.div>
      </Col>

      <style jsx>{`
        .metrics-container {
          height: 100vh;
          overflow: hidden;
          background-color: #fff;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
        .metrics-sidebar {
          overflow-y: auto;
          border-right: 1px solid #f0f0f0;
          background-color: #fafafa;
        }
        .metrics-sidebar-content,
        .metrics-content-inner {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .loading-container,
        .content-loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }
        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #f0f2f5;
        }
        .content-loading-container {
          height: 100%;
        }
        .metrics-content {
          background-color: #ffffff;
          position: relative;
        }
      `}</style>
    </Row>
  );
};

export default Metrics;
