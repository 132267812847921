import { Row, Col } from "antd";
import { FC, useEffect, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { FileNodeData } from "../../../typings";
import useStore from "../../../../../store/store";
import ContextMenu from "../../../../components/contextMenu/ContextMenu";
import { File2, FilePlaceholder } from "@assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const FileNode: FC<NodeProps<FileNodeData>> = ({ id }) => {
  const [nodeId, setNodeId] = useState(null);
  const { getNode } = useStore((state: any) => ({
    getNode: state?.getNode,
  }));

  const { t } = useTranslation();
  const node = getNode(id);

  useEffect(() => {
    const getNodeId = node?.id;
    setNodeId(getNodeId);
  }, []);

  if (!node) {
    return null; // or some fallback component
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        className="node-target"
      />
      <Col
        className="node-common-class"
        style={{
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          border: node.selected ? "2px solid #24b4e9" : "none",
          minWidth: "270px",
          maxWidth: "270px",
          transition: "border-color 0.3s ease",
          cursor: "all-scroll",
          backgroundColor: "lightGray",
          animation: node.selected
            ? "verySmoothBlink 2s linear infinite"
            : "none",
        }}
      >
        <div
          className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius"
          style={{
            fontSize: "14px", // reduce font size
            background: "#fff",
            borderRadius: "4px 4px 0px 0px",
            padding: "4px",
          }}
        >
          <Row
            gutter={22}
            align="middle"
            style={{ padding: "0px 6px 0px 8px" }}
          >
            <Col
              span={20}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              <img
                src={File2}
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="responseImageNode"
              />
              {t("flowBuilder.builderNodes.fileNode")}
            </Col>

            {/* Delete & Duplicate Node */}
            <Col span={4} style={{ marginBottom: "4px" }}>
              <ContextMenu nodeId={nodeId} />
            </Col>
          </Row>
        </div>

        <div>
          <Col span={24} className="hp-text-center">
            {/* File */}
            {node?.data?.file ? (
              <div>
                <a
                  href={node?.data?.file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {node?.data?.file.split("/").pop()}
                </a>
              </div>
            ) : (
              <img
                src={FilePlaceholder}
                alt="filePlaceholder"
                style={{ borderRadius: "5px" }}
              />
            )}
          </Col>
        </div>
      </Col>
      <Handle
        type="source"
        position={Position.Right}
        id="source"
        className="node-source"
      />
    </>
  );
};

export default FileNode;
