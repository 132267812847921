import { useTranslation } from "react-i18next";

const Member = () => {
  const { t } = useTranslation();

  const memberNavArray = [
    // ** Dashboard ** //
    {
      id: "dashboard",
      title: t("navigation.member.dashboard"),
      icon: (
        <svg
          className="joy-dash-member"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0b4eb8"
            d="M13 9V3h8v6h-8ZM3 13V3h8v10H3Zm10 8V11h8v10h-8ZM3 21v-6h8v6H3Z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/m/ws/${workspaceId}/dashboard`,
    },
    // ** Chats ** //
    {
      id: "chats",
      title: t("navigation.member.chats"),
      icon: (
        <svg
          className="joy-chats-member"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="#0b4eb8" d="M10 6V0H6v6H4l4 5l4-5z"></path>
          <path
            fill="#0b4eb8"
            d="M13 1h-2v1h1.3l2.6 8H11v2H5v-2H1.1l2.6-8H5V1H3l-3 9v5h16v-5z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/m/ws/${workspaceId}/chats`,
    },
    // ** Team ** //
    {
      id: "team",
      title: t("navigation.member.team"),
      icon: (
        <svg
          className="joy-team-member"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0b4eb8"
            d="M12 10a4 4 0 1 0 0-8a4 4 0 0 0 0 8Zm-6.5 3a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5ZM21 10.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0Zm-9 .5a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5Zm-7 5c0-.693.1-1.362.288-1.994l-.17.014A3.5 3.5 0 0 0 2 17.5V22h3v-6Zm17 6v-4.5a3.5 3.5 0 0 0-3.288-3.494c.187.632.288 1.301.288 1.994v6h3Z"
          ></path>
        </svg>
      ),
      navLink: (workspaceId) => `/m/ws/${workspaceId}/team`,
    },
  ];
  return { memberNavArray };
};

export default Member;
