const fonts = [
  "Arial",
  "Charlesworth",
  "Courier New",
  "Lucida Console",
  "Helvetica",
  "Herald",
];

export default fonts;
