import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tag, Table } from "antd";
import { getAllLeads } from "@api/user/superAdmin";

const LeadsList = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLeads();
  }, []);

  // Function to fetch leads
  const fetchLeads = () => {
    setLoading(true);
    getAllLeads()
      .then((res) => {
        setLeads(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // Define table columns
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: ["meta", "country_name"],
      key: "country",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "State",
      dataIndex: ["meta", "state"],
      key: "state",
    },
    {
      title: "City",
      dataIndex: ["meta", "city"],
      key: "city",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Visit Date",
      dataIndex: "createdAt",
      key: "visitDate",
      render: (text) => text?.split("T")[0],
    },
    {
      title: "Country Code",
      dataIndex: ["meta", "country_code"],
      key: "countryCode",
    },
    {
      title: "IP",
      dataIndex: ["meta", "IPv4"],
      key: "ip",
      render: (text) => <Tag color="red">{text}</Tag>,
    },
  ];

  return (
    <Card style={{ borderRadius: "0", height: "100vh" }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h3>All Leads</h3>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={leads}
        loading={loading}
        rowKey={(record) => record._id}
      />
    </Card>
  );
};

export default LeadsList;
