import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { BsChatSquareTextFill } from "react-icons/bs";

const TotalChats = () => {
  const { t } = useTranslation();
  const { analytics } = useSelector((state) => state.admin);

  // Calculate the percentage change in total chats
  const calculateTotalChatsPercentageChange = () => {
    const { totalChats, last7DaysChats } = analytics;

    // Assuming you have the previous total chats count available
    const previousTotalChats = 0; // Replace with the actual previous total chats count

    if (previousTotalChats === 0) {
      return 0;
    }

    const percentageChange =
      ((totalChats - previousTotalChats) / previousTotalChats) * 100;
    return percentageChange.toFixed(2);
  };

  const totalChatsPercentageChange = calculateTotalChatsPercentageChange();
  const isTotalChatsPositiveChange = totalChatsPercentageChange >= 0;

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-center">
          <div className="analytics-card">
            <div className="analytics-header">
              <div>
                <BsChatSquareTextFill
                  style={{
                    fontSize: "24px",
                    marginRight: "5px",
                    color: "#0b4eb8",
                  }}
                />
              </div>
              <h5> {t("dashboard.chats")}</h5>
            </div>
            <div
              className="analytcis-middle"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "35px",
              }}
            >
              {/* Answered */}
              <div style={{ textAlign: "start" }}>
                <h5>{t("dashboard.totalChats")} </h5>
                <div style={{ position: "relative" }}>
                  <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                    {analytics?.totalChats}
                  </span>{" "}
                  <FaArrowTrendUp
                    style={{
                      color: isTotalChatsPositiveChange ? "green" : "red",
                      marginRight: "5px",
                      marginLeft: "12px",
                      fontWeight: "bold",
                      fontSize: "24px",
                      marginBottom: "-5px",
                    }}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {totalChatsPercentageChange}%
                  </span>
                </div>
              </div>
              {/* Missed */}
              <div style={{ textAlign: "start" }}>
                <h5> {t("dashboard.missed")} </h5>
                <div style={{ position: "relative" }}>
                  <span style={{ fontSize: "28px", fontWeight: "bold" }}>
                    0{" "}
                  </span>{" "}
                  <FaArrowTrendUp
                    style={{
                      color: "#0b4eb8",
                      marginRight: "5px",
                      marginLeft: "12px",
                      fontWeight: "bold",
                      fontSize: "24px",
                      marginBottom: "-5px",
                    }}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                    0.0%
                  </span>
                </div>
              </div>
            </div>
            <div className="analytics-bottom">
              <h5> {t("dashboard.last7days")}</h5>
              <div className="flex-property">
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltUp
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    />
                  </div>
                  <div>{analytics?.last7DaysChats || 0}</div>
                </div>
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltDown
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    />
                  </div>
                  <div> 0 </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TotalChats;
