import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Pagination } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import { RouteComponentProps } from "react-router-dom";
import {
  Tour,
  ChatbotCard,
  TemplateCard,
  ChatbotCreateModal,
  ChatbotUpdateModal,
  SuccessNotification,
  ErrorNotification,
} from "../../../components";
// Skeleton
import { ChatbotCardSkeleton } from "../../../components/Loaders/skeleton/ChatbotList";
import { TemplateCardSkeletonOne } from "../../../components/Loaders/skeleton/Templates";
import {
  deleteChatbotById,
  duplicateChatbot,
} from "../../../api/chatbot/chatbot";
import {
  copyChatbotTemplate,
  likeChatbotTemplate,
} from "../../../api/template/template";
import {
  updateTutorialStatusAction,
  getAdminChatbotsWithTemplatesAction,
} from "../../../redux/actions/adminActions";
import { disableChatbotAction } from "../../../redux/actions/chatbotActions";

// Guide Tour
import {
  templatesOrScratchSteps,
  clonedFirstBotSteps,
} from "../../../helpers/shepherd/chatbotViewSteps";
import DraggableModal from "./builder/shared/draggableModal";
import ChatbotFlowPreview from "./builder/preview/ChatbotFlowPreview";
// Bot Backgrounds
import botBackground1 from "../../../assets/images/illustrations/bot-back-1.svg";
import botBackground2 from "../../../assets/images/illustrations/bot-back-2.svg";
import botBackground3 from "../../../assets/images/illustrations/bot-back-3.svg";
import botBackground4 from "../../../assets/images/illustrations/bot-back-4.svg";
import botBackground5 from "../../../assets/images/illustrations/bot-back-5.svg";
// No Chatbots Yet
import noChatbots from "../../../assets/images/pages/chatList/no-chatbots.svg";
import { useTranslation } from "react-i18next";

const bgImages = [
  botBackground1,
  botBackground2,
  botBackground3,
  botBackground4,
  botBackground5,
];

const ChatbotList: React.FC<RouteComponentProps> = ({ history }) => {
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { chatbots, exampleTemplates, adminBotsPagination } = useSelector(
    (state: any) => state.admin
  );

  // Pagination
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: adminBotsPagination?.page || 1,
    pageSize: adminBotsPagination?.perPage || 4,
    total: adminBotsPagination?.totalChatbots || 0,
  });

  // Create and update modals
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [updateChatbotId, setUpdateChatbotId] = useState("");
  const [loading, setLoading] = useState(true);

  // Shepherd tour
  const [tutTemplateOrScratchTour, setTutTemplateOrScratchTour] = useState(
    user?.tutorials_complete?.templatesOrScratch ||
      JSON.parse(localStorage.getItem("shepherd"))?.templatesOrScratch
  );
  const [tutClonedFirstBotFinishedTour, setTutClonedFirstBotFinishedTour] =
    useState(
      user?.tutorials_complete?.clonedFirstBotTut ||
        JSON.parse(localStorage.getItem("shepherd"))?.clonedFirstBotTut
    );

  // Preview modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalSize, setModalSize] = useState("mobile");

  // Handle fetch admin chatbots with templates
  useEffect(() => {
    handleFetchAdminChatbotsWithTemplates();
  }, []);

  // Update local pagination state when Redux state changes
  useEffect(() => {
    if (adminBotsPagination) {
      setPagination({
        current: adminBotsPagination.page || 1,
        pageSize: adminBotsPagination.perPage || 10,
        total: adminBotsPagination.totalChatbots || 0,
      });
    }
  }, [adminBotsPagination]);

  useEffect(() => {
    const shepherdData = JSON.parse(localStorage.getItem("shepherd"));
    if (shepherdData) {
      // Check each tutorial status and update the server if it's not already updated
      if (
        !user?.tutorials_complete?.templatesOrScratch &&
        tutTemplateOrScratchTour
      ) {
        dispatch(updateTutorialStatusAction(shepherdData));
      }
      if (
        !user?.tutorials_complete?.clonedFirstBotTut &&
        tutClonedFirstBotFinishedTour
      ) {
        dispatch(updateTutorialStatusAction(shepherdData));
      }
    }
  }, []);

  // Handle fetch chatbots with templates
  const handleFetchAdminChatbotsWithTemplates = () => {
    setLoading(true);

    dispatch(
      getAdminChatbotsWithTemplatesAction(
        pagination.current,
        pagination.pageSize,
        searchText,
        setLoading
      )
    );
  };

  // Handle create chatbot
  const handleCreateChatbot = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update chatbot
  const handleUpdateChatbot = (id: string) => {
    setUpdateChatbotId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  // Handle disable chatbot
  const handleDisableChatbot = async (id: string, checked: any) => {
    // Dispatch disableChatbotAction
    dispatch(
      disableChatbotAction(id, {
        disabled: checked,
      })
    );

    // Handle fetch chatbots with templates
    handleFetchAdminChatbotsWithTemplates();
  };

  // Handle open chatbot builder
  const handleOpenChatbotBuilder = (id: string) => {
    // admin/last 5 chars of id
    history.push(
      `/a/ws/${user.workspaceId}/u/${user._id.slice(-5)}/chatbot/${id}/builder`
    );
  };

  // Handle duplicate chatbot
  const handleDuplicateChatbot = (id: string) => {
    Modal.confirm({
      title: `Are you sure you want to duplicate this chatbot?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: async () => {
        try {
          // Set loading
          setLoading(true);

          duplicateChatbot(id).then(() => {
            // Set loading
            setLoading(false);

            handleFetchAdminChatbotsWithTemplates();
          });
        } catch (error) {
          // Set loading
          setLoading(false);

          ErrorNotification("Error duplicating chatbot");
        }
      },
    });
  };

  // Handle delete chatbot
  const handleDeleteChatbot = (id: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this chatbot?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteChatbotById(id);
          handleFetchAdminChatbotsWithTemplates();
          SuccessNotification("Chatbot deleted successfully");
        } catch (error) {
          ErrorNotification("Error deleting chatbot");
        }
      },
    });
  };

  // Handle template copy
  const handleTemplateCopy = (tid: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to copy ${name}?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: async () => {
        try {
          // Set loading
          setLoading(true);

          copyChatbotTemplate(tid).then(() => {
            const shepherdObj = JSON.parse(localStorage.getItem("shepherd"));
            if (shepherdObj?.noChatbotsYet) {
              shepherdObj.clonedFirstBot = true;
              localStorage.setItem("shepherd", JSON.stringify(shepherdObj));
            }

            handleFetchAdminChatbotsWithTemplates();
          });
        } catch (error) {
          // Set loading
          setLoading(false);

          ErrorNotification("Error copying chatbot");
        }
      },
    });
  };

  // Handle like template click
  const handleLikeTemplate = (tid: any) => {
    likeChatbotTemplate(tid)
      .then(() => {
        // Do nothing
      })
      .catch((error) => {
        // Show error notification
        console.log("Error liking template", error);
        ErrorNotification("Error liking template");
      });
  };

  // Handle tour complete
  const handleTemplateOrScratchComplete = (
    tourId: string,
    completionStatus: boolean
  ) => {
    if (completionStatus) {
      setTutTemplateOrScratchTour(true);

      const existingData = localStorage.getItem("shepherd");
      const parsedData = JSON.parse(existingData);
      const newData = {
        templatesOrScratch: true,
        noChatbotsYet: chatbots.length === 0,
      };
      const updatedData = { ...parsedData, ...newData };

      // Save to local storage
      localStorage.setItem("shepherd", JSON.stringify(updatedData));

      // Dispatch updateTutorialStatusAction
      dispatch(updateTutorialStatusAction(updatedData));
    }
  };

  // Handle cloned first bot tour complete
  const handleClonedFirstBotComplete = (
    tourId: string,
    completionStatus: boolean
  ) => {
    if (completionStatus) {
      setTutClonedFirstBotFinishedTour(true);

      const existingData = localStorage.getItem("shepherd");
      const parsedData = JSON.parse(existingData);
      const newData = {
        clonedFirstBotTut: true,
        noChatbotsYet: chatbots.length === 0,
      };
      const updatedData = {
        ...parsedData,
        ...newData,
      };

      // Save to local storage
      localStorage.setItem("shepherd", JSON.stringify(updatedData));

      // Dispatch updateTutorialStatusAction
      dispatch(updateTutorialStatusAction(updatedData));
    }
  };

  // Handle page change
  const handlePageChange = (page: any, pageSize: any) => {
    setPagination({ ...pagination, current: page });

    // Set loading
    setLoading(true);

    // Dispatch getAdminChatbotsWithTemplatesAction
    dispatch(
      getAdminChatbotsWithTemplatesAction(
        page,
        pageSize,
        searchText,
        setLoading
      )
    );
  };

  // Handle close modal
  const closeModal = () => {
    setModalVisible(false);
  };

  // Handle set mobile / desktop preview
  const toggleModalSize = (value: any) => {
    setModalSize(value);
  };

  return (
    <>
      {/* Build from scratch or copy from templates shepherd flow */}
      {!loading && !tutTemplateOrScratchTour && exampleTemplates[0] && (
        <Tour
          steps={templatesOrScratchSteps}
          tutComplete={tutTemplateOrScratchTour}
          tourId="tutTemplateOrScratchTour"
          handleTourComplete={handleTemplateOrScratchComplete}
        />
      )}

      {!loading &&
        !tutClonedFirstBotFinishedTour &&
        exampleTemplates[0] &&
        tutTemplateOrScratchTour &&
        JSON.parse(localStorage.getItem("shepherd"))?.clonedFirstBot && (
          <Tour
            steps={clonedFirstBotSteps}
            tutComplete={tutClonedFirstBotFinishedTour}
            tourId="clonedFirstBotTour"
            handleTourComplete={handleClonedFirstBotComplete}
          />
        )}

      {createModalOpen && (
        <ChatbotCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateChatbot}
        />
      )}

      {updateModalOpen && (
        <ChatbotUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateChatbot}
          chatbotId={updateChatbotId}
          templateId=""
          handleFetchChatbots={handleFetchAdminChatbotsWithTemplates}
        />
      )}

      <Col
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          padding: "20px",
          height: "calc(100vh - 0px)",
          backgroundColor: "#ffffff",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 18px 2px",
        }}
      >
        <Row>
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row justify="space-between">
              <Col span={16} xs={8} sm={16} md={24} lg={32}>
                <h1
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#2c3e50",
                  }}
                >
                  {t("chatbotList.myBots")}
                </h1>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            flexWrap: "nowrap",
            marginBottom: "1rem",
          }}
        >
          {/* Create Chatbot Plus Button */}
          <Button
            type="dashed"
            className="hp-border-color-primary-1 hp-hover-border-color-primary-2"
            style={{
              width: "14rem",
              minWidth: "14rem",
              height: "14rem",
              marginTop: "3rem",
              marginLeft: "2rem",
              marginRight: "2rem",
              borderRadius: "15px",
              color: "#0b4eb8",
              backgroundColor: "#ffffff",
            }}
            onClick={handleCreateChatbot}
            id="joyride-bot-scratch"
          >
            <AiOutlinePlus size={50} />
            {t("chatbotList.createBot")}
          </Button>
          {loading ? (
            <>
              {Array.from({ length: 4 }, (_, index) => (
                <ChatbotCardSkeleton key={index} />
              ))}
            </>
          ) : (
            <>
              {chatbots.length > 0 ? (
                chatbots?.map((chatbot: any, index: any) => (
                  <React.Fragment key={chatbot._id}>
                    <ChatbotCard
                      index={index}
                      chatbot={chatbot}
                      // Open
                      handleOpenChatbotBuilder={handleOpenChatbotBuilder}
                      // Update
                      handleUpdateChatbot={handleUpdateChatbot}
                      // Disable
                      handleDisableChatbot={handleDisableChatbot}
                      // Duplicate
                      handleDuplicateChatbot={handleDuplicateChatbot}
                      // Delete
                      handleDeleteChatbot={handleDeleteChatbot}
                      // Background Image
                      backgroundImage={bgImages[index % bgImages.length]}
                    />
                  </React.Fragment>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    marginTop: "2rem",
                    marginLeft: "100px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={noChatbots}
                    alt="No chatbots yet"
                    style={{
                      width: "100%", // Change to 100% width
                      maxWidth: "650px", // Add a max-width
                      height: "auto", // Set height to auto
                    }}
                  />
                </div>
              )}
            </>
          )}
        </Row>

        {/* Pagination */}
        {exampleTemplates[0] && (
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handlePageChange}
            />
          </Row>
        )}

        {/* Preview Modal */}
        <DraggableModal visible={modalVisible} positionValueY={1300}>
          {/* Content of the modal */}
          <div
            style={{
              height: "75vh",
              width: modalSize === "mobile" ? "25vw" : "45vw",
              transition: "all 0.1s ease-out",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              overflow: "hidden",
            }}
          >
            {/* Modal body */}
            <div>
              <ChatbotFlowPreview
                closeModal={closeModal}
                toggleModalSize={toggleModalSize}
              />
            </div>
          </div>
        </DraggableModal>

        <Row>
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row justify="space-between">
              <Col span={16} xs={8} sm={16} md={24} lg={32}>
                <h1
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#2c3e50",
                  }}
                >
                  {t("templates.templateTitle")}
                </h1>
              </Col>

              <Col
                span={6}
                xs={8}
                sm={16}
                md={24}
                lg={32}
                style={{
                  textAlign: "right",
                  transform: "translateY(-2rem)",
                }}
              >
                <Button
                  onClick={() => {
                    history.push(`/a/ws/${user.workspaceId}/chatbot/templates`);
                  }}
                  style={{
                    backgroundColor: "#0b4eb8",
                    marginRight: "15px",
                  }}
                  id="joyride-bot-templates"
                  type="primary"
                >
                  {t("templates.viewAllTemplate")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {!exampleTemplates[0] ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {Array.from({ length: 4 }, (_, index) => (
                <TemplateCardSkeletonOne key={index} />
              ))}
            </div>
          ) : (
            exampleTemplates?.length > 0 &&
            exampleTemplates.map((template: any, index: any) => (
              <React.Fragment key={template._id}>
                <TemplateCard
                  // Template
                  template={template}
                  index={index}
                  // Copy
                  handleTemplateCopy={handleTemplateCopy}
                  // Like
                  handleLikeTemplate={handleLikeTemplate}
                  // Modal
                  setModalVisible={setModalVisible}
                />
              </React.Fragment>
            ))
          )}
        </Row>
      </Col>
    </>
  );
};

export default ChatbotList;
