import { useEffect, useState } from "react";

import { Row, Col } from "antd";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ImUsers } from "react-icons/im";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { MdPreview } from "react-icons/md";

const TotalLeads = () => {
  const { t } = useTranslation();
  const { analytics } = useSelector((state) => state.admin);
  const [chartWidth, setChartWidth] = useState("80%");

  useEffect(() => {
    const timer = setTimeout(() => {
      setChartWidth("100%");
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  const [data] = useState({
    series: [
      {
        data: [
          {
            x: "category A",
            y: 40,
          },
          {
            x: "category B",
            y: 68,
          },
          {
            x: "category C",
            y: 83,
          },
          {
            x: "category D",
            y: 33,
          },
          {
            x: "category E",
            y: 93,
          },
        ],
      },
    ],
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          top: -12,
          bottom: -32,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          startingShape: "rounded",
          colors: {
            backgroundBarColors: [],
            backgroundBarRadius: 5,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#00F7BF"],
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        max: 100,
      },
      tooltip: {
        enabled: false,
        x: {
          show: false,
        },
      },
    },
  });

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Row gutter={16} align="middle">
        <Col span={24}>
          <div className="analytics-card">
            <div className="analytics-header">
              <div>
                <MdPreview
                  style={{
                    fontSize: "24px",
                    marginRight: "5px",
                    color: "#0b4eb8",
                  }}
                />
              </div>
              <h5> {t("dashboard.leads")} </h5>
            </div>
            <div className="analytcis-middle">
              <h5>{t("dashboard.totalLeads")}</h5>
              <div style={{ position: "relative" }}>
                <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {analytics?.totalEmailLeads}{" "}
                </span>{" "}
                <FaArrowTrendUp
                  style={{
                    color: "#0b4eb8",
                    marginRight: "5px",
                    marginLeft: "12px",
                    fontWeight: "bold",
                    fontSize: "24px",
                    marginBottom: "-5px",
                  }}
                />
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  0.0%
                </span>
              </div>
            </div>
            <div className="analytics-bottom">
              <h5> {t("dashboard.last7days")} </h5>
              <div className="flex-property">
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltUp
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    />
                  </div>
                  <div> 0 </div>
                </div>
                <div className="flex-property">
                  <div>
                    <FaLongArrowAltDown
                      style={{
                        marginTop: "-5px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    />
                  </div>
                  <div> 0 </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TotalLeads;
