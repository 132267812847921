import api from "../index";

// Check current user
export const currentUser = () => {
  return api.post("/currentUser");
};

// Get an user by ID
export const getUserById = (id: any) => {
  return api.get(`/user/id/${id}`);
};

// Save user onboarding data
export const saveUserOnboardingData = (data: any) => {
  return api.post("/user/onboarding", data);
};

// Save user tutorial status
export const saveUserTutorialStatus = (data: any) => {
  return api.post("/user/tutorial/status", data);
};

// Update an user profile
export const updateUserProfile = (values: any) => {
  return api.put("/user/profile/update", values);
};

// Delete user account
export const deleteUserAccount = async (id: any) => {
  return await api.post(`/user/delete`, { userId: id });
};
