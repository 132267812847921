import React, { FC } from "react";
import { Row, Col } from "antd";
import { Handle, Position } from "reactflow";

const ImageChoice: FC<any> = ({
  id,
  index,
  imageData,
}: {
  id: string;
  index: any;
  imageData: any;
}) => {
  const node = imageData;

  return (
    <Row
      key={index}
      align="middle"
      style={{
        width: "100%",
        transition: "border-color 0.3s ease",
        cursor: "all-scroll",
        borderRadius: "3px",
        margin: "-3px 0px -4px 0px",
      }}
    >
      <Col span={24}>
        <React.Fragment key={index}>
          <Col span={24} className="hp-text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: node.label,
              }}
              className="quill-textArea"
              style={{
                lineHeight: "1.2",
              }}
            />
          </Col>
        </React.Fragment>
      </Col>

      <Col span={24}>
        <Handle
          type="source"
          position={Position.Right}
          id={`source-${id}`}
          className="node-source"
          style={{
            background: "#e40000",
            marginTop: "-20px",
          }}
        />
      </Col>
    </Row>
  );
};

export default ImageChoice;
