import React, { FC } from "react";
import { Row, Col } from "antd";
import { Handle, Position } from "reactflow";

const ChoiceNode: FC<any> = ({
  id,
  index,
  choiceData,
}: {
  id: string;
  index: any;
  choiceData: any;
}) => {
  const node = choiceData;

  return (
    <Row
      key={index}
      align="middle"
      style={{
        width: "100%",
        transition: "border-color 0.3s ease",
        cursor: "all-scroll",
        borderRadius: "3px",
        marginTop: "-3px",
      }}
    >
      <Col span={24}>
        <div
          dangerouslySetInnerHTML={{ __html: node?.choiceText }}
          className="quill-textArea"
          style={{
            lineHeight: "1.2",
          }}
        />
      </Col>

      <Col span={24}>
        <Handle
          type="source"
          position={Position.Right}
          id={`source-${id}`}
          className="node-source"
          style={{
            background: "#e40000",
            marginTop: "-20px",
          }}
        />
      </Col>
    </Row>
  );
};

export default ChoiceNode;
