import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Spin, Upload, Col, message } from "antd";
import { RiUploadLine } from "react-icons/ri"; // Assuming you're using react-icons for upload icon
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { withRouter } from "react-router";
import { getCategoryById, updateCategoryById } from "@api/template/template";
import { uploadBlogCoverImage } from "@api/blog/blog";
import { SuccessNotification, ErrorNotification } from "../../.."; // Adjust the import path as necessary
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const initialState = {
  name: "",
  title: "",
  coverImage: "",
  description: "",
  content: "", // This will be populated with a JSON string from the API
};

const CategoryUpdateModal = ({
  open,
  handleModalClick,
  fetchCategories,
  categoryId,
}) => {
  const [values, setValues] = useState(initialState);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchTemplateCategoryById();
  }, [categoryId]); // Ensure dependency on categoryId for re-fetching when it changes

  const fetchTemplateCategoryById = async () => {
    setLoading(true);
    try {
      const { data } = await getCategoryById(categoryId);
      setValues(data);

      if (data.content) {
        const contentState = convertFromRaw(JSON.parse(data.content));
        setEditorState(EditorState.createWithContent(contentState));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("GET_TEMPLATE_CATEGORY_BY_ID_ERROR", err);
    }
  };

  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Before image upload
  const beforeCoverUpload = (file) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Image must smaller than 3MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // Handle cover image upload
  const handleUploadCoverImage = async (options) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload image
    try {
      setUploading(true);
      const res = await uploadBlogCoverImage(fmData, config);
      setValues({
        ...values,
        coverImage: res.data.url,
      });
      setUploading(false);
    } catch (error) {
      onError(error);
      setUploading(false);
    }
  };

  const handleTemplateCategoryUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!values.name) {
      setLoading(false);
      return ErrorNotification("Please enter a name");
    }

    updateCategoryById(categoryId, values)
      .then(() => {
        setValues(initialState);
        handleModalClick();
        fetchCategories();
        SuccessNotification("Category updated successfully");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response.data.message);
      });
  };

  return (
    <>
      <Modal
        title="Update Template Category"
        visible={open}
        onCancel={handleModalClick}
        closable={true}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleTemplateCategoryUpdate}
            loading={loading}
          >
            Update
          </Button>,
        ]}
        width={1800}
        style={{
          top: 20, // Adjust top to 0 to align with the top of the viewport
          height: "100vh", // Set height to cover the full viewport height
          width: "100vw", // Set width to cover the full viewport width
          overflowY: "auto", // Add scroll to the modal content if it exceeds the viewport height
          display: "flex",
          flexDirection: "column", // Adjust the direction to column to support full height use
          alignItems: "center", // Center align the content for better appearance
          justifyContent: "flex-start", // Adjust content to start from the top
        }}
      >
        {loading ? (
          <Spin />
        ) : (
          <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input the category name!" },
              ]}
            >
              <Input
                name="name"
                placeholder="Enter template category name"
                onChange={handleFormChange}
                value={values.name}
                defaultValue={values.name}
              />
            </Form.Item>

            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input the title!" }]}
            >
              <Input
                name="title"
                placeholder="Enter title"
                onChange={handleFormChange}
                value={values.title}
                defaultValue={values.title}
              />
            </Form.Item>

            <Form.Item label="Cover Image" name="coverImage">
              <Upload
                name="coverImage"
                listType="picture-card"
                customRequest={handleUploadCoverImage}
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeCoverUpload}
                multiple={false}
                accept="image/*,image/gif"
              >
                {values.coverImage ? (
                  <img
                    src={values.coverImage}
                    alt="cover"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <RiUploadLine size={24} loading={uploading} />
                )}
              </Upload>
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please input the description!" },
              ]}
            >
              <Input.TextArea
                name="description"
                placeholder="Enter description"
                onChange={handleFormChange}
                value={values.description}
                defaultValue={values.description}
              />
            </Form.Item>

            <Form.Item label="Content">
              <Col
                style={{
                  border: "1px solid #dfe6e9",
                  borderRadius: "8px",
                  padding: "16px",
                  minHeight: "500px",
                }}
              >
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  ariaLabel="draftEditor"
                  onEditorStateChange={(value) => {
                    setEditorState(value);
                    setValues({
                      ...values,
                      content: JSON.stringify(
                        convertToRaw(value.getCurrentContent())
                      ),
                    });
                  }}
                  toolbar={{
                    inline: { inDropdown: true },
                    fontFamily: {
                      options: ["Arial"],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "auto",
                      },
                    },
                  }}
                />
              </Col>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default withRouter(CategoryUpdateModal);
