import { useTranslation } from "react-i18next";
import { Home, Send } from "react-iconly";
import { FaBlogger } from "react-icons/fa";
import { FcFlowChart } from "react-icons/fc";

const MarketingAdmin = () => {
  const { t } = useTranslation();

  const marketingAdminNavArray = [
    {
      id: "dashboard",
      title: t("navigation.superAdmin.dashboard"),
      icon: <Home set="curved" className="remix-icon" />,
      navLink: "/marketing-admin/dashboard",
    },
    {
      id: "chatbot templates",
      title: t("navigation.superAdmin.chatbotTemplates"),
      icon: <FcFlowChart set="curved" className="remix-icon" />,
      navLink: "/marketing-admin/chatbot-templates",
    },
    {
      id: "blogs",
      title: t("navigation.superAdmin.blogs"),
      icon: <FaBlogger set="curved" className="remix-icon" />,
      navLink: "/marketing-admin/blogs",
    },
    // ------- SEO -------
    {
      id: "seo",
      title: "SEO",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="remix-icon"
          width="18px"
          height="18px"
          viewBox="0 0 48 48"
        >
          <mask id="ipSSeo0">
            <g fill="none" stroke-width="4">
              <rect
                width="40"
                height="32"
                x="4"
                y="8"
                fill="#fff"
                stroke="#fff"
                rx="1.633"
              />
              <path
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16 18.948c-2-2.948-5.502-1.01-5.251 2.02C11 24 15 24 15.249 27.032C15.5 30.062 12 32 10 29.051M26 18h-4v13h4m-4-6h4"
              />
              <rect
                width="6"
                height="13"
                x="32"
                y="18"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                rx="3"
              />
            </g>
          </mask>
          <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSSeo0)" />
        </svg>
      ),
      navLink: "/marketing-admin/seo/chatbot-template-blogs",
    },
    {
      id: "leads",
      title: t("navigation.superAdmin.leads"),
      icon: <Send set="curved" className="remix-icon" />,
      navLink: "/marketing-admin/leads",
    },
  ];
  return { marketingAdminNavArray };
};

export default MarketingAdmin;
