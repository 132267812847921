import { Row, Col } from "antd";
import cardBg2 from "../../../assets/images/dashboard/analytics-payment-bg.svg";
import { useTranslation } from "react-i18next";

const WelcomeCard = () => {
  // Translation
  const { t } = useTranslation();

  return (
    <div
      className="hp-position-relative hp-bg-primary-6 hp-p-24 hp-border-radius-xxl hp-card-1 hp-overflow-hidden"
      style={{
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "8px",
        height: "100%",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <div
        className="hp-position-absolute-top-left hp-w-100 hp-h-100 hp-nft-dashboard-own-nft-bg"
        style={{
          backgroundImage: "url(" + cardBg2 + ")",
          backgroundSize: "cover",
          backgroundPosition: "right",
          filter: "brightness(0.90)",
        }}
      ></div>

      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <h2 className="h4 hp-text-color-black-1 hp-mb-10 hp-d-block">
            {t("dashboard.greetingTitle")}
          </h2>

          <p
            className="hp-p1-body hp-mb-0"
            style={{
              color: "#000000",
            }}
          >
            {t("dashboard.greetingSubTitle")}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default WelcomeCard;
