import { useState } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { withRouter } from "react-router";
import { createKnowledgeBaseArticleCategory } from "@api/knowledge-base/knowledgeBase";
import { SuccessNotification, ErrorNotification } from "../..";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { Option } = Select;

const initialState = {
  name: "",
  description: "",
  chatbotId: "",
};

const KnowledgeBaseCategoryCreateModal = ({
  open,
  handleModalClick,
  fetchCategories,
  chatbots,
  selectedChatbot,
}) => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle chatbot select
  const handleChatbotSelect = (value) => {
    setValues({
      ...values,
      chatbotId: value,
    });
  };

  // Function to filter chatbots based on input
  const filterChatbots = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // Handle knowledge base category create
  const handleKnowledgeBaseCategoryCreate = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validate form
    if (!values.name || !values.chatbotId) {
      setLoading(false);
      return ErrorNotification(
        t("knowledgeBase.pleaseEnterNameAndSelectChatbot")
      );
    }

    // Create a new knowledge base category
    createKnowledgeBaseArticleCategory(values)
      .then(() => {
        setValues(initialState);
        handleModalClick();

        // Fetch article categories
        fetchCategories(selectedChatbot);

        SuccessNotification(t("knowledgeBase.categoryCreated"));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response.data.message);
      });
  };

  return (
    <>
      <Modal
        title={t("knowledgeBase.createKbCategory")}
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            {t("knowledgeBase.cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleKnowledgeBaseCategoryCreate}
            loading={loading}
          >
            {t("knowledgeBase.create")}
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Category name */}
          <Form.Item label={t("knowledgeBase.name")}>
            <Input
              name="name"
              placeholder={t("knowledgeBase.categoryNamePH")}
              onChange={handleFormChange}
              value={values.name}
              rules={[{ required: true }]}
            />
          </Form.Item>

          {/* Chatbot selection */}
          <Form.Item label={t("knowledgeBase.chatbot")}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={t("knowledgeBase.chatbotPH")}
              optionFilterProp="children"
              onChange={handleChatbotSelect}
              filterOption={filterChatbots}
              value={values.chatbotId}
            >
              {chatbots?.map((chatbot) => (
                <Option key={chatbot._id} value={chatbot._id}>
                  {chatbot.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Category description */}
          <Form.Item label={t("knowledgeBase.description")}>
            <TextArea
              name="description"
              placeholder={t("knowledgeBase.description")}
              onChange={handleFormChange}
              value={values.description}
              rules={[{ required: true }]}
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default withRouter(KnowledgeBaseCategoryCreateModal);
