import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Spin, Table, Input } from "antd";
import {
  deleteChatbotTemplateBlogCategoryById,
  getAllChatbotTemplateBlogsCategoriesPaginatedSearch,
} from "@api/chatbot-template-blog/chatbotTemplateBlog";
import {
  SuccessNotification,
  ErrorNotification,
  ChatbotTemplateBlogCategoryCreateModal,
  ChatbotTemplateBlogCategoryUpdateModal,
} from "@components";
import { useHistory } from "react-router-dom";

const { Search } = Input;

const ChatbotTemplateBlogCategoriesList = () => {
  const history = useHistory();
  const [chatbotTemplateBlogCategories, setChatbotTemplateBlogCategories] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const search = searchParams.get("search") || "";

    setSearchText(search);
    setPagination({ current: page, pageSize, total: 0 });
    fetchChatbotTemplateBlogCategories(page, pageSize, search);
  }, []);

  const fetchChatbotTemplateBlogCategories = async (
    page,
    pageSize,
    searchText = ""
  ) => {
    setLoading(true);
    try {
      const res = await getAllChatbotTemplateBlogsCategoriesPaginatedSearch(
        page,
        pageSize,
        searchText
      );
      setChatbotTemplateBlogCategories(res.data.chatbotTemplateBlogCategories);
      setPagination((p) => ({
        ...p,
        total: res.data.paginationData.totalChatbotTemplateBlogCategories,
        current: res.data.paginationData.page,
        pageSize: res.data.paginationData.perPage,
      }));
    } catch (error) {
      console.error("Fetch chatbotTemplateBlog categories error", error);
    }
    setLoading(false);
  };

  const handlePaginationChange = (page, pageSize) => {
    const searchParams = new URLSearchParams();
    searchParams.set("page", page);
    searchParams.set("pageSize", pageSize);
    if (searchText) searchParams.set("search", searchText);

    history.push({ search: searchParams.toString() });
    setPagination({ ...pagination, current: page, pageSize });
    fetchChatbotTemplateBlogCategories(page, pageSize, searchText);
  };

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    setPagination({ ...pagination, current: 1 }); // Reset to first page
    fetchChatbotTemplateBlogCategories(1, pagination.pageSize, searchValue);

    const searchParams = new URLSearchParams();
    searchParams.set("page", "1");
    searchParams.set("pageSize", pagination.pageSize);
    if (searchValue) searchParams.set("search", searchValue);
    history.push({ search: searchParams.toString() });
  };

  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const handleUpdateModalClick = (id) => {
    setCategoryId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  const handleCategoryDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteChatbotTemplateBlogCategoryById(id);
          fetchChatbotTemplateBlogCategories(
            pagination.current,
            pagination.pageSize,
            searchText
          );
          SuccessNotification("Category deleted successfully");
        } catch (error) {
          ErrorNotification("Error deleting category");
        }
      },
    });
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of Chatbot Template Blogs",
      dataIndex: "blogs",
      key: "blogs",
      render: (chatbotTemplateBlogs) => chatbotTemplateBlogs.length,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleUpdateModalClick(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleCategoryDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {createModalOpen && (
        <ChatbotTemplateBlogCategoryCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchCategories={() =>
            fetchChatbotTemplateBlogCategories(
              pagination.current,
              pagination.pageSize,
              searchText
            )
          }
        />
      )}

      {updateModalOpen && (
        <ChatbotTemplateBlogCategoryUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchCategories={() =>
            fetchChatbotTemplateBlogCategories(
              pagination.current,
              pagination.pageSize,
              searchText
            )
          }
          categoryId={categoryId}
        />
      )}

      <Card
        style={{
          borderRadius: "0",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row justify="space-between">
          <Col span={12}>
            <h3 className="hp-mb-16">Chatbot Template Blog Categories List</h3>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* Search */}
            <Search
              placeholder="Search by name"
              style={{ width: "200px", marginRight: "16px" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={handleSearch}
            />

            {/* Back Button */}
            <Button
              onClick={() => history.goBack()}
              style={{ marginRight: "16px" }}
            >
              Go Back
            </Button>

            {/* Create Chatbot Template Blog Category */}
            <Button type="primary" onClick={handleCreateModalClick}>
              Create Chatbot Template Blog Category
            </Button>
          </Col>
        </Row>

        {loading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={chatbotTemplateBlogCategories}
            rowKey="_id"
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: handlePaginationChange,
            }}
          />
        )}
      </Card>
    </>
  );
};

export default ChatbotTemplateBlogCategoriesList;
