import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Spin, Divider } from "antd";
import {
  MetaData,
  Analytics,
  Team,
  UsageDetails,
  CurrentSubscription,
} from "../../../../components";
import { useParams } from "react-router-dom";
import {
  getAdminDetailsById,
  toggleDisableUserAccount,
} from "../../../../api/user/superAdmin";
import { useHistory } from "react-router";

const AdminDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const [adminDetails, setAdminDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAdminDetails();
  }, []);

  // Handle fetch admin details
  const fetchAdminDetails = async () => {
    setLoading(true);
    const { data } = await getAdminDetailsById(id);
    setAdminDetails(data);
    setLoading(false);
  };

  // Toggle user disable
  const handleToggleUserDisable = (userId) => {
    toggleDisableUserAccount(userId)
      .then(() => {
        fetchAdminDetails();
      })
      .catch((err) => {
        console.log("Toggle admin disable error", err);
      });
  };

  return (
    <>
      <Card
        style={{
          borderRadius: "0",
          height: "100vh",
        }}
      >
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          {/* Header Buttons */}
          <Col span={24}>
            <Row justify="space-between">
              {/* Heading */}
              <Col>
                <h3>Admin Details</h3>
                <p>Control the access of your admins</p>
              </Col>

              {/* Go Back Button */}
              <Col>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => history.goBack()}
                >
                  Go Back
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Col
            style={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 160px)", // Adjust this height based on your header/footer size
            }}
          >
            {/* Meta Data */}
            <MetaData
              adminMetaData={adminDetails?.adminMetaData}
              handleToggleUserDisable={handleToggleUserDisable}
            />

            <Divider />

            {/* Analytics */}
            <Analytics analytics={adminDetails?.analytics} />

            <Divider />

            {/* Team */}
            <Team team={adminDetails?.team} />

            <Divider />

            {/* Usage Details */}
            <UsageDetails
              currentUsageDetails={adminDetails?.currentUsageDetails}
            />

            <Divider />

            {/* Current Subscription */}
            <CurrentSubscription
              currentSubscription={adminDetails?.currentSubscription}
            />
          </Col>
        )}
      </Card>
    </>
  );
};

export default AdminDetails;
