import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Modal,
  Radio,
  Row,
  Switch,
  Upload,
  message,
} from "antd";
import { useParams } from "react-router-dom";
import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons";
import { Howl } from "howler";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import SoundIcon from "@assets/images/pages/customize/toggleSound.png";
import { useTranslation } from "react-i18next";
import {
  DeleteOutlined,
  UploadOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { uploadChatbotMedia } from "@api/chatbot/chatbot";

const WidgetToggleSound = () => {
  const { t } = useTranslation();

  const { bid } = useParams();
  const dispatch = useDispatch();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [enableSound, setEnableSound] = useState(
    customizations?.enableWidgetToggleSound
  );
  // Sound Tab
  const [checkedSoundTab, setCheckedSoundTab] = useState(
    customizations?.checkedSoundTab || "choose"
  ); // 'choose' or 'upload'
  // Choose
  const [selectedSound, setSelectedSound] = useState(
    customizations?.widgetToggleSound
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [showMore, setShowMore] = useState(false);
  // Upload
  const [isUploadingSound, setIsUploadingSound] = useState(false);
  const [customWidgetSoundUrl, setCustomWidgetSoundUrl] = useState(
    customizations?.customWidgetSoundUrl
  );
  const [uploadedFileName, setUploadedFileName] = useState("");

  useEffect(() => {
    setEnableSound(customizations?.enableWidgetToggleSound);
    setCheckedSoundTab(customizations?.checkedSoundTab);
    setCustomWidgetSoundUrl(customizations?.customWidgetSoundUrl);

    // If the sound is uploaded, get the file name
    if (customizations?.customWidgetSoundUrl) {
      const url = customizations?.customWidgetSoundUrl;
      const fileName = url.split("/").pop();
      setUploadedFileName(fileName);
    }
  }, [customizations]);

  const widgetSounds = [
    {
      id: 1,
      name: "Tic-tok",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-1.mp3",
    },
    {
      id: 2,
      name: "Ping",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-2.mp3",
    },
    {
      id: 3,
      name: "Bell",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-3.mp3",
    },
    {
      id: 4,
      name: "Rise-up",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-4.mp3",
    },
    {
      id: 5,
      name: "Ping-Tune",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-5.mp3",
    },
    {
      id: 6,
      name: "Wrinkle",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-6.mp3",
    },
    {
      id: 7,
      name: "Frog",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-7.mp3",
    },
    {
      id: 8,
      name: "Ping Me",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-8.mp3",
    },
    {
      id: 9,
      name: "Wake-up",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-9.mp3",
    },
    {
      id: 10,
      name: "Ring-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-10.mp3",
    },
    {
      id: 11,
      name: "Wave-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-11.mp3",
    },
    {
      id: 12,
      name: "Wave-2",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-12.mp3",
    },
    {
      id: 13,
      name: "Victory",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-13.mp3",
    },
    {
      id: 14,
      name: "Blink-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-14.mp3",
    },
    {
      id: 15,
      name: "Nature",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-15.mp3",
    },
    {
      id: 16,
      name: "Door-Bell",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-16.mp3",
    },
    {
      id: 17,
      name: "Blink-2",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-17.mp3",
    },
    {
      id: 18,
      name: "Yahoo",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-18.mp3",
    },
    {
      id: 19,
      name: "Message-1",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-19.mp3",
    },
    {
      id: 20,
      name: "Message-2",
      src: "https://s3.ap-south-1.amazonaws.com/conferbot.defaults/sounds/sound-20.mp3",
    },
  ];

  // Tab options
  const soundTabOptions = [
    { label: "Choose", value: "choose" },
    { label: "Upload", value: "upload" },
  ];

  // Handle Enable Sound Switch
  const toggleEnableSound = () => {
    setEnableSound(!enableSound);
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "enableWidgetToggleSound",
        !enableSound
      )
    );
  };

  // ** Widget Sound ** //
  // Handle tab change
  const handleSoundTabChange = (e) => {
    setCheckedSoundTab(e.target.value);
    const checkedSound = e.target.value;

    // Update the data
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "checkedSoundTab",
        checkedSound
      )
    );
  };

  // Play Sound
  const playSound = (sound) => {
    // Set selected sound src
    setSelectedSound(sound.src);

    // Create sound
    const soundHowl = new Howl({
      src: [sound.src],
      html5: true,
      onend: () => setIsPlaying(false),
    });

    // Play sound
    soundHowl.play();

    // Set isPlaying to true
    setIsPlaying(true);

    // Update widgetToggleSound
    dispatch(
      updateCurrentChatbotCustomizationsAction(
        bid,
        "widgetToggleSound",
        sound.src
      )
    );
  };

  // Function to play the uploaded sound
  const handlePlayUploadedSound = () => {
    const soundHowl = new Howl({
      src: [customWidgetSoundUrl],
      html5: true,
      onend: () => setIsPlaying(false),
    });

    if (isPlaying) {
      soundHowl.pause(); // Pause if currently playing
      setIsPlaying(false);
    } else {
      soundHowl.play(); // Play the sound
      setIsPlaying(true);
    }
  };

  // Validate the file before uploading
  const beforeUploadSound = (file) => {
    const isAudio =
      file.type === "audio/mpeg" ||
      file.type === "audio/mp3" ||
      file.type === "audio/wav" ||
      file.type === "audio/ogg";
    if (!isAudio) {
      message.error("You can only upload MP3, WAV, or OGG audio files!");
      return Upload.LIST_IGNORE;
    }
    const isLt2MB = file.size / 1024 <= 2048;
    if (!isLt2MB) {
      message.error("Audio must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return isAudio && isLt2MB;
  };

  // Handle the custom sound file upload
  const handleUploadSound = async (options) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    formData.append("image", file); // Adjust if your API expects a different key

    setIsUploadingSound(true);
    try {
      const response = await uploadChatbotMedia(bid, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.url) {
        setCustomWidgetSoundUrl(response.data.url);
        setUploadedFileName(file.name); // Set the uploaded file name
        dispatch(
          updateCurrentChatbotCustomizationsAction(
            bid,
            "customWidgetSoundUrl",
            response.data.url
          )
        );
        message.success("Widget sound uploaded successfully");
        onSuccess(response);
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      message.error("Error uploading sound");
      onError(error);
    } finally {
      setIsUploadingSound(false);
    }
  };

  // Function to delete the uploaded sound
  const handleDeleteUploadedSound = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this sound?",
      content: "Once deleted, you will not be able to recover this sound.",
      onOk: () => {
        setCustomWidgetSoundUrl("");
        setUploadedFileName("");
      },
    });
  };

  // handleShowMore
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  // If show More show all sounds else show less sounds
  const displayWidgetSounds = showMore
    ? widgetSounds
    : widgetSounds.slice(0, 8);

  return (
    <>
      <div className="dropdown-menu">
        <div className="dropdown-trigger" onClick={toggleEnableSound}>
          <span>
            <img
              src={SoundIcon}
              style={{ height: "20px", width: "20px", marginRight: "8px" }}
              alt="sound-icon"
            />
            {t("customize.widget.widgetToggleSound")}
          </span>
          <Switch checked={enableSound} size="small" />
        </div>
        {enableSound && (
          <>
            {/* Custom Widget Sounds  */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginTop: "20px",
                backgroundColor: "#d5d7e4",
                paddingTop: "20px",
              }}
            >
              <Radio.Group
                options={soundTabOptions}
                onChange={handleSoundTabChange}
                value={checkedSoundTab}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            {/* Widget Sound  */}
            {/* Default sounds */}
            {checkedSoundTab === "choose" && (
              <div style={{ padding: "20px 25px", backgroundColor: "#d5d7e4" }}>
                {t("customize.widget.widgetSound")}
                <div className="widgetSounds_Wrapper">
                  {displayWidgetSounds?.map((sound, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="widget_sound"
                          style={{
                            background:
                              (customizations?.widgetIconThemeType ===
                                "Solid" &&
                                customizations?.widgetIconBgColor) ||
                              (customizations?.widgetIconThemeType ===
                                "Gradient" &&
                                `linear-gradient(to right, ${customizations?.widgetGradientBgOne}, ${customizations?.widgetGradientBgTwo})`),
                            border:
                              selectedSound === sound.src && "3px solid #fff",
                            transform:
                              selectedSound === sound.src
                                ? "scale(1.2)"
                                : "scale(1)",
                            overflow: "hidden",
                            textAlign: "center",
                          }}
                          onClick={() => playSound(sound)}
                        >
                          {selectedSound === sound.src && isPlaying ? (
                            <PauseCircleFilled
                              style={{
                                fontSize: "30px",
                                color: "#fff",
                                marginTop: selectedSound !== sound.src && "2px",
                              }}
                            />
                          ) : (
                            <PlayCircleFilled
                              style={{
                                fontSize: "30px",
                                color: "#fff",
                                marginTop: selectedSound !== sound.src && "2px",
                              }}
                              size={100}
                            />
                          )}
                        </div>
                        <span
                          style={{
                            marginTop: "5px",
                            fontWeight: "600",
                          }}
                          className="ellipsis-text"
                        >
                          {sound.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <span
                  onClick={handleShowMore}
                  className="showMore"
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {showMore
                    ? t("customize.widget.showLess")
                    : t("customize.widget.showMore")}
                </span>
              </div>
            )}

            {/* Custom Sound */}
            {checkedSoundTab === "upload" && (
              <Row
                align="middle"
                justify="center"
                style={{ backgroundColor: "#d5d7e4" }}
              >
                <Col
                  span={24}
                  style={{ textAlign: "center", marginBottom: "10px" }}
                >
                  <Card
                    style={{
                      backgroundColor: "#d5d7e4",
                      padding: "10px",
                      borderRadius: "0px",
                      border: "none",
                    }}
                  >
                    {uploadedFileName ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            color: "#0100EC",
                            fontWeight: "600",
                            textDecoration: "underline",
                          }}
                        >
                          {uploadedFileName}
                        </div>
                        <Button
                          icon={<PlayCircleOutlined />}
                          onClick={handlePlayUploadedSound}
                          size="small"
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#0100EC",
                            color: "white",
                            border: "none",
                          }}
                        >
                          {isPlaying ? "Pause" : "Play"}
                        </Button>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={handleDeleteUploadedSound}
                          size="small"
                          style={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                          }}
                        >
                          Delete
                        </Button>
                      </>
                    ) : (
                      <Upload
                        name="handoverSound"
                        beforeUpload={beforeUploadSound}
                        customRequest={handleUploadSound}
                        showUploadList={false}
                        accept=".mp3,audio/*"
                      >
                        <Button
                          icon={<UploadOutlined />}
                          loading={isUploadingSound}
                        >
                          Upload Sound
                        </Button>
                      </Upload>
                    )}
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default WidgetToggleSound;
