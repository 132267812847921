import React, { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Select, Input } from "antd";
import { shallow } from "zustand/shallow";
import { RangeNode } from "../../../../common/nodes/typings";
import useStore, { selector } from "../../../../store/store";
import QuillEditor from "../../../../common/components/quillEditor/QuillEditor";
import SaveAnswer from "../../../../common/components/saveAnswer/saveAnswer";

const RangeNodeDataEditor: FC<RangeNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [rangePrompt, setRangePrompt] = useState(state?.data?.rangePrompt);
  const [rangeType, setRangeType] = useState(state?.data?.type);
  const [minVal, setMinVal] = useState(state?.data?.minVal);
  const [maxVal, setMaxVal] = useState(state?.data?.maxVal);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  // Each time botRangeNodeData changes, update the state
  useEffect(() => {
    setRangePrompt(state?.data?.rangePrompt);
    setRangeType(state?.data?.type);
    setMinVal(state?.data?.minVal);
    setMaxVal(state?.data?.maxVal);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  // Handle range prompt change
  const handleRangePromptChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the Rating Prompt
      setRangePrompt(content);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, text: content },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle range type change
  const handleRangeTypeChange = useCallback(
    (value: any) => {
      if (!state) return;

      setRangeType(value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, type: value },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle min value change
  const handleMinValueChange = useCallback(
    (e: any) => {
      if (!state) return;

      setMinVal(e.target.value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, minVal: e.target.value },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle max value change
  const handleMaxValueChange = useCallback(
    (e: any) => {
      if (!state) return;

      setMaxVal(e.target.value);
      changeNodeData({
        ...state,
        type,
        data: { ...state?.data, maxVal: e.target.value },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              color: "gray",
            }}
          >
            Use this node when you want to deviate the flow based on the range
            of the user input. Ex: range of number, currency or dates
          </p>
        </Row>

        {/* Question Text */}
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={rangePrompt}
              onEditorChange={handleRangePromptChange}
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "20px",
            width: "100%",
          }}
        />

        <Col span={6} className="sidebar__heading">
          Type
        </Col>
        <Col span={18}>
          <Select
            style={{ width: "100%" }}
            value={rangeType}
            onChange={(value: any) => handleRangeTypeChange(value)}
          >
            <Select.Option value="number">Number</Select.Option>
            <Select.Option value="date">Date</Select.Option>
            <Select.Option value="currency">Currency</Select.Option>
          </Select>
        </Col>

        {/* If input type is number, show min and max inputs */}
        <>
          {/* 1 Line space */}
          <div
            style={{
              height: "20px",
              width: "100%",
            }}
          />

          <Col span={6} className="sidebar__heading">
            Min
          </Col>
          <Col span={18}>
            <Input
              type={
                state?.data.type === "number"
                  ? "number"
                  : state?.data.type === "date"
                  ? "date"
                  : "text"
              }
              value={minVal}
              onChange={(e) =>
                handleMinValueChange(e as React.ChangeEvent<HTMLInputElement>)
              }
            />
          </Col>

          {/* 1 Line space */}
          <div
            style={{
              height: "10px",
              width: "100%",
            }}
          />

          <Col span={6} className="sidebar__heading">
            Max
          </Col>
          <Col span={18}>
            <Input
              type={
                state?.data.type === "number"
                  ? "number"
                  : state?.data.type === "date"
                  ? "date"
                  : "text"
              }
              value={maxVal}
              onChange={(e) =>
                handleMaxValueChange(e as React.ChangeEvent<HTMLInputElement>)
              }
            />
          </Col>
        </>
        <SaveAnswer
          id={id}
          type={type}
          variableName={answerVariable ? answerVariable : "range"}
        />
      </Row>
    </>
  );
};

export default RangeNodeDataEditor;
