import { useState, useEffect } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Header from "./header";
import { CustomizeSidebar, ChatUi } from "../../../../components";
import {
  getAdminChatbotAction,
  getSuperAdminChatbotAction,
} from "../../../../redux/actions/chatbotActions";

const ChatbotCustomize = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { error } = useSelector((state: any) => state.alert);
  const { bid } = useParams<{ bid: string }>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    // If user role is admin
    if (user?.role === "admin") {
      // Dispatch action to set current chatbot
      dispatch(getAdminChatbotAction(bid, setLoading));
    } else if (user?.role === "superadmin" || user?.role === "marketing") {
      // Dispatch action to set current chatbot
      dispatch(getSuperAdminChatbotAction(bid, setLoading));
    }
  }, [dispatch, bid, user?.role]);

  return (
    <>
      <div>
        <Header isCustomize={true} />
      </div>

      <div
        style={{
          display: "flex",
          position: "relative",
          height: "calc(100vh - 61px)",
          width: "100%",
        }}
      >
        {loading || error ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "6px",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {/* Customize Sidebar and Chat UI */}
            <CustomizeSidebar />
            <ChatUi />
          </div>
        )}
      </div>
    </>
  );
};

export default ChatbotCustomize;
