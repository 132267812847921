import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Spin, Table } from "antd";
import {
  SuccessNotification,
  ErrorNotification,
  ChangelogCreateModal,
  ChangelogUpdateModal,
} from "@components";
import {
  getAllChangelogs,
  deleteChangelogById,
} from "@api/workspace/changelog"; // Update the import path based on your API directory structure

const ChangelogsList = () => {
  const [changelogs, setChangelogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [changelogId, setChangelogId] = useState("");

  useEffect(() => {
    fetchChangelogs();
  }, []);

  const fetchChangelogs = async () => {
    setLoading(true);
    try {
      const { data } = await getAllChangelogs();
      setChangelogs(data);
    } catch (error) {
      console.error("Fetch changelogs error", error);
    }
    setLoading(false);
  };

  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const handleUpdateModalClick = (id) => {
    setChangelogId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  const handleChangelogDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this changelog?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteChangelogById(id);
          fetchChangelogs();
          SuccessNotification("Changelog deleted successfully");
        } catch (error) {
          ErrorNotification("Error deleting changelog");
        }
      },
    });
  };

  const columns = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Release Date",
      dataIndex: "releaseDate",
      key: "releaseDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleUpdateModalClick(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleChangelogDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {createModalOpen && (
        <ChangelogCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchChangelogs={fetchChangelogs}
        />
      )}

      {updateModalOpen && (
        <ChangelogUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchChangelogs={fetchChangelogs}
          changelogId={changelogId}
        />
      )}

      <Card style={{ borderRadius: "0", height: "100vh", overflowY: "auto" }}>
        <Row justify="space-between">
          <Col>
            <h3>Changelog List</h3>
          </Col>
          <Col>
            <Button type="primary" onClick={handleCreateModalClick}>
              Create Changelog
            </Button>
          </Col>
        </Row>

        {loading ? (
          <Spin />
        ) : (
          <Table
            columns={columns}
            dataSource={changelogs}
            rowKey="_id"
            scroll={{ y: "calc(100vh - 300px)" }}
          />
        )}
      </Card>
    </>
  );
};

export default ChangelogsList;
