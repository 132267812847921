import { useState, useEffect, FC, useCallback } from "react";
import { Row, Col, Input } from "antd";
import { shallow } from "zustand/shallow";
import { ZohoNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";

const ZohoNodeDataEditor: FC<ZohoNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [url, setUrl] = useState(state?.data?.questionText);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setUrl(state?.data?.questionText);
    setAnswerVariable(state?.data?.answerVariable);
  }, [id]);

  const handleUrlChange = useCallback(
    (e: any) => {
      if (!state) return;

      // Set the welcome message
      setUrl(e.target.value);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          questionText: e.target.value,
        },
      });
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            ℹ️ Use this node for Zoho.
          </p>
        </Row>

        {/* Question Text */}
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>

        <Col span={24}>
          <Input.TextArea
            value={url}
            placeholder="Enter your question here"
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={(e) => handleUrlChange(e)}
          />
        </Col>

        <SaveAnswer
          id={id}
          type={type}
          variableName={answerVariable ? answerVariable : "url"}
        />
      </Row>
    </>
  );
};

export default ZohoNodeDataEditor;
