import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from "reactflow";
import useStore, { selector } from "../../store/store";
import { shallow } from "zustand/shallow";
import DeleteIcon from "../../../../../../assets/images/pages/builder/dlt-btn.png";
import "./customEdge.css";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { deleteEdge } = useStore(selector, shallow);

  const onEdgeRemoveClick = (e: any, id: any) => {
    e.stopPropagation();

    // Remove the edge
    deleteEdge(id);
  };

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          strokeWidth: "2px",
          stroke: "#fff",
          filter: "drop-shadow(0 0 5px #fff) drop-shadow(0 0 3px #fff)",
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            fontSize: 22,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
        >
          <span
            className="cnt-dlt__btn"
            onClick={(event) => onEdgeRemoveClick(event, id)}
          >
            <img src={DeleteIcon} alt="delete-icon" />
          </span>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
