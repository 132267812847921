import { Card, Skeleton } from "antd";

const AnalyticsCardSkeleton = () => (
  <Card
    bodyStyle={{ padding: "10px" }}
    hoverable
    style={{
      height: "100%",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      position: "relative",
      overflow: "hidden",
      margin: "0 auto",
      borderRadius: "8px",
    }}
  >
    {/* Image */}
    <div
      style={{
        height: "95px",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "0px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          height: "100px",
          width: "100%",
        }}
      />
    </div>

    {/* input text */}
    <div
      style={{
        height: "20px",
        width: "80%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "20px",
        margin: "20px auto 10px auto",
      }}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{
          backgroundColor: "#0000000f",
          width: "100%",
        }}
      />
    </div>
  </Card>
);
export default AnalyticsCardSkeleton;
