import { _getValueFromAnswerVariableKey } from "../ChatbotFlowPreview";

import botAvatar from "../../../../../../assets/images/pages/customize/botLogo.png";

// Email validation helper function
export const _validateEmailHelper = (email: any) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Phone number validation helper function
export const _validatePhoneNumberHelper = (phoneNumber: any) => {
  const re = /^\d{6,15}$/;
  return re.test(String(phoneNumber));
};

// URL validation helper function
export const _validateUrlHelper = (url: any) => {
  const re = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
  return re.test(String(url).toLowerCase());
};

// Sleep helper function
export const _sleep = (time: any) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

// Display error message
export const _displayErrorMessage = (
  embedType: any,
  customizations: any,
  message: any
) => {
  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;
  // Get the input element
  const inputElement = document.querySelector("#inputField") as any;

  // Render the typing animation for 300ms and the display the error message
  let typingAnimation = document.createElement("div");
  typingAnimation.innerHTML = `<div id="typing" class="${embedType}__chat__bubble__wrapper">
    <img src=${
      customizations.avatar || botAvatar
    } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
      style="display: ${
        customizations.hideAvatar ? "none" : "block"
      }; height: ${customizations.avatarSize}px;"
    >
    <div class="typing__bubble you" style="background-color: ${
      customizations.botMsgColor
    };">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;display: block;shape-rendering: auto;width: 43px;height: 20px;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="0" cy="44.1678" r="15" fill="#ffffff">
            <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.6s"></animate>
        </circle> <circle cx="45" cy="43.0965" r="15" fill="#ffffff">
        <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.39999999999999997s"></animate>
        </circle> <circle cx="90" cy="52.0442" r="15" fill="#ffffff">
            <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.19999999999999998s"></animate>
        </circle>
    </svg>
  </div>
</div>`;

  stepsElement.appendChild(typingAnimation);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
  chatBody.scrollTop = stepsElement.scrollHeight + 800;

  _sleep(700).then(() => {
    document.querySelector("#typing")!.remove();
    // Display the error message
    let errorMessage = document.createElement("div");
    errorMessage.style.position = "relative";

    // Get the error message text
    const errorMessageText = message;

    // Parse the message text as HTML
    const parsedHtml = parsedHtmlElement(
      errorMessageText,
      "bot",
      customizations
    );

    // Get the error message element
    errorMessage.innerHTML = `<div 
      class="${embedType}__chat__bubble__wrapper"
    >
      <img src=${
        customizations.avatar || botAvatar
      } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
      style="display: ${
        customizations.hideAvatar ? "none" : "block"
      }; height: ${customizations.avatarSize}px;"
      >
        ${parsedHtml}
    </div>`;

    stepsElement.appendChild(errorMessage);

    // Select the chat body, scroll to bottom
    const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
    chatBody.scrollTop = stepsElement.scrollHeight + 800;
  });
  inputElement.focus();
  return;
};

// ----- Formatted text helpers
const styleElement = (
  elements: any,
  styleFor: any,
  customizations: any,
  isOption?: boolean
) => {
  // Define the common styles to apply
  const commonStyles = {
    borderRadius: "1.15rem 0.8rem 0.8rem 0",
    display: "block",
    wordBreak: "break-word",
    fontSize: customizations?.fontSize,
    fontFamily: customizations?.font,
  };

  // Define the styles specific to bot messages
  const botStyles = {
    ...commonStyles, // Spread the common styles here
    bgColor: !isOption
      ? customizations?.botMsgColor
      : customizations?.optionBubbleMsgColor || "#f5f5f5",
    textColor: !isOption
      ? customizations?.botTextColor
      : customizations?.optionBubbleTextColor || "#000",
    margin: !isOption ? "10px 0px" : "10px 5px 10px 5px",
  };

  // Define the styles specific to user messages
  const userStyles = {
    ...commonStyles, // Spread the common styles here
    bgColor: customizations?.userMsgColor,
    textColor: customizations?.userTextColor,
    margin: "0",
  };

  // Apply styles based on whether the style is for the bot or user
  const styles = styleFor === "bot" ? botStyles : userStyles;

  // Iterate through each element and apply styles
  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    let tagName = element.tagName.toLowerCase();

    // Apply common styles
    element.style.fontSize = styles.fontSize + "px";
    element.style.backgroundColor = styles.bgColor;
    element.style.color = styles.textColor;
    element.style.borderRadius = styles.borderRadius;
    element.style.wordBreak = styles.wordBreak;
    element.style.margin = styles.margin;

    // Set display style based on the element type
    if (tagName === "a") {
      element.style.display = "inline";
      element.style.textDecoration = "underline";
      element.style.textDecorationThickness = "2px"; // Adjust thickness as needed
      element.style.textUnderlineOffset = "5px"; // Adjust offset as needed
      element.style.color = "white";
      element.style.padding = "0px";
      element.style.margin = "0px";
    } else if (
      tagName === "b" ||
      tagName === "strong" ||
      tagName === "em" ||
      tagName === "u" ||
      tagName === "s"
    ) {
      element.style.display = "inline";
      element.style.padding = "1px";
    } else {
      // Other elements follow the common display style
      element.style.display = styles.display;
    }
  }
};

// Create parsedHtmlElement function
export const parsedHtmlElement = (
  htmlString: any,
  styleFor: any,
  customizations: any,
  isOption?: boolean
) => {
  // Function to replace variable placeholders with actual values
  const replaceVariables = (text: string): string => {
    return text.replace(/\{(\w+)\}/g, (match, key) => {
      const value = _getValueFromAnswerVariableKey(key);
      return value !== null ? value : match; // Return the matched key if no variable is found
    });
  };

  // First, replace variables in the HTML string
  htmlString = replaceVariables(htmlString);

  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlString, "text/html");

  const paragraphWrapper = document.createElement("div");
  paragraphWrapper.style.display = "block";

  // Iterate over child nodes and process them
  Array.from(parsedHtml.body.childNodes).forEach((node: any) => {
    // Check if the node is a <p> tag containing only a <br> tag, then skip it
    if (
      node.nodeName.toLowerCase() === "p" &&
      node.childNodes.length === 1 &&
      node.childNodes[0].nodeName.toLowerCase() === "br"
    ) {
      // Skip appending this node as it only contains <br>
    } else if (
      node.nodeType === Node.TEXT_NODE &&
      node.textContent.trim() !== ""
    ) {
      // Wrap text nodes in a <p> tag
      let pTag = document.createElement("p");
      pTag.appendChild(node.cloneNode(true));
      paragraphWrapper.appendChild(pTag);
    } else {
      // Directly append other elements
      paragraphWrapper.appendChild(node.cloneNode(true));
    }
  });

  // Clear the original body and append the paragraphWrapper
  parsedHtml.body.innerHTML = "";
  parsedHtml.body.appendChild(paragraphWrapper);

  // Apply styles
  styleElement(
    parsedHtml.getElementsByTagName("p"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("b"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("strong"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("i"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("em"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("ul"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("ol"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("a"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("blockquote"),
    styleFor,
    customizations,
    isOption
  );
  styleElement(
    parsedHtml.getElementsByTagName("span"),
    styleFor,
    customizations,
    isOption
  );

  // Append the paragraphWrapper to the body of the parsed HTML
  parsedHtml.body.appendChild(paragraphWrapper);

  return parsedHtml.body.innerHTML;
};

// ----- Typing animation helper
export const _showTypingAnimationForGivenTime = (
  embedType: any,
  customizations: any,
  time: number
) => {
  return new Promise((resolve: any) => {
    const stepsElement = document.querySelector("#steps") as any;

    const typingAnimation = document.createElement("div");
    typingAnimation.innerHTML = `<div id="typing" class="${embedType}__chat__bubble__wrapper">
      <img src=${
        customizations?.avatar || botAvatar
      } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async" style="display: ${
      customizations?.hideAvatar ? "none" : "block"
    }; height: ${customizations?.avatarSize}px;">
      <div class="typing__bubble you" style="background-color: ${
        customizations?.botMsgColor
      };">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;display: block;nameKey-rendering: auto;width: 43px;height: 20px;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="0" cy="44.1678" r="15" fill="#ffffff">
            <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.6s"></animate>
          </circle>
          <circle cx="45" cy="43.0965" r="15" fill="#ffffff">
            <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.39999999999999997s"></animate>
          </circle>
          <circle cx="90" cy="52.0442" r="15" fill="#ffffff">
            <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.19999999999999998s"></animate>
          </circle>
        </svg>
      </div>
    </div>`;

    stepsElement?.appendChild(typingAnimation);

    // Select the chat body, scroll to bottom
    const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;

    if (chatBody) {
      chatBody.scrollTop = stepsElement?.scrollHeight + 800;
    }

    // Remove the typing message after the given time
    setTimeout(() => {
      const typingElement = document.getElementById("typing");

      // If the typing element exists
      if (typingElement) {
        typingElement.remove();
      }

      resolve();
    }, time);
  });
};

// Display bot message
export const _displayBotMessageHelper = (
  embedType: any,
  customizations: any,
  message: any
) => {
  // Get the steps element
  const stepsElement = document.querySelector("#steps") as any;

  // Display the bot message
  let botMessage = document.createElement("div");
  botMessage.style.position = "relative";

  // Get the bot message text
  const botMessageText = message;

  // Parse the message text as HTML
  const parsedHtml = parsedHtmlElement(botMessageText, "bot", customizations);

  botMessage.innerHTML = `<div 
      class="${embedType}__chat__bubble__wrapper"
    >
      <img src=${
        customizations.avatar || botAvatar
      } alt="Avatar" class="${embedType}__chat__avatar" loading="lazy" decoding="async"
      style="display: ${
        customizations.hideAvatar ? "none" : "block"
      }; height: ${customizations.avatarSize}px;"
      >
        ${parsedHtml}
    </div>`;

  stepsElement.appendChild(botMessage);

  // Select the chat body, scroll to bottom
  const chatBody = document.querySelector(`.${embedType}__chat__body`) as any;
  chatBody.scrollTop = stepsElement.scrollHeight + 800;

  return;
};
