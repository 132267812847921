import { useState } from "react";
import { Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PopoverColorPicker } from "../../../../../Popover/PopoverColorPicker";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import ChatIcon from "@assets/images/pages/customize/chat-icon.png";
import Arrow from "@assets/images/pages/customize/down.png";
import { useTranslation } from "react-i18next";

const MessageBubbles = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  // Handle Color Picker
  const handleColor = (value, key) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, value.hex));
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => {
            setVisible(!visible);
          }}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={ChatIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="chat-icon"
            />
            {t("customize.chat.messageBubbles")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            alt="arrow"
          />
        </div>
        {visible && (
          <>
            <div style={{ padding: "20px 20px", backgroundColor: "#d5d7e4" }}>
              {/* Bubble Radius */}
              <span style={{ display: "block", marginBottom: "10px" }}>
                {t("customize.chat.bubbleBorderRadius")}
              </span>
              <div style={{ marginTop: "15px" }}>
                <Slider
                  style={{ marginBottom: "18px" }}
                  defaultValue={customizations?.bubbleBorderRadius}
                  min={0}
                  max={50}
                  onChange={(value) =>
                    dispatch(
                      updateCurrentChatbotCustomizationsAction(
                        bid,
                        "bubbleBorderRadius",
                        value
                      )
                    )
                  }
                />
              </div>

              {/* Bot Message Background */}
              <label htmlFor="color-picker" className="flex-class-label">
                {t("customize.chat.botMessageBackgroundColor")}:
                <div className="color-picker">
                  <PopoverColorPicker
                    color={customizations?.botMsgColor}
                    onChange={(color) => handleColor(color, "botMsgColor")}
                  />
                </div>
              </label>

              {/* Bot Message Text */}
              <label htmlFor="color-picker" className="flex-class-label">
                {t("customize.chat.botMessagesTextColor")}:
                <div className="color-picker">
                  <PopoverColorPicker
                    color={customizations?.botTextColor}
                    onChange={(color) => handleColor(color, "botTextColor")}
                  />
                </div>
              </label>

              {/* User Message Background */}
              <label htmlFor="color-picker" className="flex-class-label">
                {t("customize.chat.userMessageBackgroundColor")}:
                <div className="color-picker">
                  <PopoverColorPicker
                    color={customizations?.userMsgColor}
                    onChange={(color) => handleColor(color, "userMsgColor")}
                  />
                </div>
              </label>

              {/* User Message Text */}
              <label htmlFor="color-picker" className="flex-class-label">
                {t("customize.chat.userMessagesTextColor")}:
                <div className="color-picker">
                  <PopoverColorPicker
                    color={customizations?.userTextColor}
                    onChange={(color) => handleColor(color, "userTextColor")}
                  />
                </div>
              </label>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MessageBubbles;
