import { useState } from "react";
import { Input, Select } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentChatbotCustomizationsAction } from "@redux/actions/chatbotActions";
import FontIcon from "@assets/images/pages/customize/fonts.png";
import Arrow from "@assets/images/pages/customize/down.png";
import fonts from "@utils/fonts";
import { useTranslation } from "react-i18next";

const Font = () => {
  const dispatch = useDispatch();
  const { bid } = useParams();
  const { customizations } = useSelector((state) => state?.chatbot ?? {});
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  // Handle update
  const handleUpdate = (key, value) => {
    dispatch(updateCurrentChatbotCustomizationsAction(bid, key, value));
  };

  return (
    <>
      <div className="dropdown-menu">
        <div
          onClick={() => {
            setVisible(!visible);
          }}
          className="dropdown-trigger"
        >
          <span>
            <img
              src={FontIcon}
              style={{
                height: "20px",
                width: "20px",
                marginRight: "8px",
              }}
              alt="font-icon"
            />
            {t("customize.general.font")}
          </span>
          <img
            src={Arrow}
            className={visible ? "arrow-rotate" : ""}
            alt="arrow"
          />
        </div>

        {visible && (
          <>
            <div
              style={{
                padding: "20px 25px",
                backgroundColor: "#d5d7e4",
              }}
            >
              {/* Font Family */}
              <span
                style={{
                  display: "block",
                  marginBottom: "5px",
                }}
              >
                {t("customize.general.fontFamily")}
              </span>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <Select
                  style={{ width: "100%" }}
                  className="general-select"
                  defaultValue={customizations?.font}
                  onChange={(value) => handleUpdate("font", value)}
                >
                  {fonts.map((font, index) => (
                    <Select.Option key={index} value={font}>
                      {font}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {/* Font Size */}
              <span>{t("customize.general.fontSize")}</span>
              <div style={{ textAlign: "center" }}>
                <Input
                  placeholder="Enter font size"
                  className="general-input"
                  type="number"
                  value={customizations?.fontSize}
                  onChange={(e) => handleUpdate("fontSize", e.target.value)}
                  // Min and max
                  min={12}
                  max={24}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Font;
