import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";

import "./assets/icons/remixicon.css";
import "./assets/less/netraga-theme.less";

// Redux
import { DataProvider } from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./localization";

const browserHistory = createBrowserHistory();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <DataProvider>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID}>
      <Router history={browserHistory}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Router>
    </GoogleOAuthProvider>
  </DataProvider>
);
