import { NodeTypes } from "../../types/types";

import {
  WaveIcon,
  Text,
  Image,
  Choices,
  Select,
  Check,
  TwoChoices,
  Rating,
  Range,
  Redirect,
  InputIcon,
  Html,
  Quiz,
  Video,
  Feedback,
  MultiImages,
  YesNo,
  Opinions,
  Variable,
  Email,
  Name,
  Url,
  Phone,
  File,
  Questions,
  Numbers,
  Calendar,
  Condition,
  Location,
  Audio,
  MoreQuestions,
  Api,
  Step,
  GoogleSheets,
  Zapier,
  Slack,
  Airtable,
  Zoho,
  HubSpot,
  Mailchimp,
  Segment,
  Salesforce,
  File2,
  HumanHandover,
  Delay,
  GPT,
  Boolean,
  Operations,
  RandomFlow,
  Navigate,
} from "../../../../../../assets/images/pages/builder";
import { useTranslation } from "react-i18next";

const Constants = () => {
  const { t } = useTranslation();

  // Text to show on the DnD Sidebar for the nodes
  const labelsMap = {
    // **********  v1 (Legacy Nodes) **********
    // ** DEPRECATED NODES **
    [NodeTypes.SelectOption]: t(
      "flowBuilder.sidebar.editor.labels.selectOption"
    ),
    [NodeTypes.TwoChoices]: t("flowBuilder.sidebar.editor.labels.twoChoices"),
    [NodeTypes.ThreeChoices]: t(
      "flowBuilder.sidebar.editor.labels.threeChoices"
    ),
    [NodeTypes.Rating]: t("flowBuilder.sidebar.editor.labels.rating"),
    [NodeTypes.Range]: t("flowBuilder.sidebar.editor.labels.range"),
    [NodeTypes.UserInput]: t("flowBuilder.sidebar.editor.labels.userInput"),
    [NodeTypes.Quiz]: t("flowBuilder.sidebar.editor.labels.quiz"),

    // **********  v2 (New Nodes) **********
    // ** STARTER NODE **
    [NodeTypes.Welcome]: t("flowBuilder.sidebar.editor.labels.welcome"),

    // ** SEND RESPONSE NODES **
    [NodeTypes.Message]: t("flowBuilder.sidebar.editor.labels.message"),
    [NodeTypes.Image]: t("flowBuilder.sidebar.editor.labels.image"),
    [NodeTypes.Video]: t("flowBuilder.sidebar.editor.labels.video"),
    [NodeTypes.Audio]: t("flowBuilder.sidebar.editor.labels.audio"),
    [NodeTypes.File]: t("flowBuilder.sidebar.editor.labels.file"),

    // ** ASK QUESTION NODES **
    [NodeTypes.AskName]: t("flowBuilder.sidebar.editor.labels.askName"),
    [NodeTypes.AskEmail]: t("flowBuilder.sidebar.editor.labels.askEmail"),
    [NodeTypes.AskPhoneNumber]: t("flowBuilder.sidebar.editor.labels.askPhone"),
    [NodeTypes.AskFile]: t("flowBuilder.sidebar.editor.labels.askFile"),
    [NodeTypes.AskNumber]: t("flowBuilder.sidebar.editor.labels.askNumber"),
    [NodeTypes.AskUrl]: t("flowBuilder.sidebar.editor.labels.askUrl"),
    [NodeTypes.AskMultipleQuestions]: t(
      "flowBuilder.sidebar.editor.labels.multiQuestions"
    ),
    [NodeTypes.NCheckOptions]: t(
      "flowBuilder.sidebar.editor.labels.nCheckOptions"
    ),
    [NodeTypes.NSelectOption]: t(
      "flowBuilder.sidebar.editor.labels.nSelectOption"
    ),
    [NodeTypes.AskLocation]: t("flowBuilder.sidebar.editor.labels.askLocation"),
    [NodeTypes.AskCustomQuestion]: t(
      "flowBuilder.sidebar.editor.labels.customQuestion"
    ),

    // ** FLOW OPERATION NODES **
    [NodeTypes.NChoices]: t("flowBuilder.sidebar.editor.labels.nChoices"),
    [NodeTypes.ImageChoice]: t("flowBuilder.sidebar.editor.labels.imageChoice"),
    [NodeTypes.RatingChoice]: t(
      "flowBuilder.sidebar.editor.labels.ratingChoice"
    ),
    [NodeTypes.YesOrNo]: t("flowBuilder.sidebar.editor.labels.yesOrNo"),
    [NodeTypes.OpinionScale]: t(
      "flowBuilder.sidebar.editor.labels.opinionScale"
    ),

    // ** LOGICAL NODES **
    [NodeTypes.Condition]: "Condition",
    [NodeTypes.BusinessHours]: t(
      "flowBuilder.sidebar.editor.labels.businessHours"
    ),
    [NodeTypes.BooleanLogic]: t(
      "flowBuilder.sidebar.editor.labels.booleanLogic"
    ),
    [NodeTypes.MathOperation]: t(
      "flowBuilder.sidebar.editor.labels.mathOperation"
    ),
    [NodeTypes.RandomFlow]: t("flowBuilder.sidebar.editor.labels.randomFlow"),
    [NodeTypes.Variable]: t("flowBuilder.sidebar.editor.labels.variable"),

    // ** SPECIAL NODES **
    [NodeTypes.Html]: t("flowBuilder.sidebar.editor.labels.html"),
    [NodeTypes.Email]: t("flowBuilder.sidebar.editor.labels.sendAnEmail"),
    [NodeTypes.Gpt]: t("flowBuilder.sidebar.editor.labels.gpt"),
    [NodeTypes.Redirect]: t("flowBuilder.sidebar.editor.labels.redirect"),
    [NodeTypes.Calendar]: t("flowBuilder.sidebar.editor.labels.calendar"),
    [NodeTypes.JumpTo]: t("flowBuilder.sidebar.editor.labels.jumpTo"),
    [NodeTypes.HumanHandover]: t(
      "flowBuilder.sidebar.editor.labels.humanHandover"
    ),
    [NodeTypes.Delay]: t("flowBuilder.sidebar.editor.labels.delay"),
    [NodeTypes.Navigate]: "Navigate",

    // ** INTEGRATION NODES **
    [NodeTypes.Webhook]: t("flowBuilder.sidebar.editor.labels.webhook"),
    [NodeTypes.Zapier]: t("flowBuilder.sidebar.editor.labels.zapier"),
    [NodeTypes.GoogleSheets]: t(
      "flowBuilder.sidebar.editor.labels.googleSheets"
    ),
    [NodeTypes.Slack]: t("flowBuilder.sidebar.editor.labels.slack"),
    [NodeTypes.Airtable]: t("flowBuilder.sidebar.editor.labels.airtable"),
    [NodeTypes.Zoho]: t("flowBuilder.sidebar.editor.labels.zoho"),
    [NodeTypes.HubSpot]: t("flowBuilder.sidebar.editor.labels.hubSpot"),
    [NodeTypes.MailChimp]: t("flowBuilder.sidebar.editor.labels.mailChimp"),
    [NodeTypes.Segment]: t("flowBuilder.sidebar.editor.labels.segment"),
    [NodeTypes.Salesforce]: t("flowBuilder.sidebar.editor.labels.salesforce"),
  };

  // Icon to show on the DnD Sidebar for the nodes
  const iconsMap = {
    // **********  v1 (Legacy Nodes) **********
    // ** DEPRECATED NODES **
    [NodeTypes.SelectOption]: Select,
    [NodeTypes.TwoChoices]: TwoChoices,
    [NodeTypes.ThreeChoices]: TwoChoices,
    [NodeTypes.Rating]: Rating,
    [NodeTypes.Range]: Range,
    [NodeTypes.UserInput]: InputIcon,
    [NodeTypes.Quiz]: Quiz,

    // **********  v2 (New Nodes) **********
    // ** STARTER NODE **
    [NodeTypes.Welcome]: WaveIcon,

    // ** SEND RESPONSE NODES **
    [NodeTypes.Message]: Text,
    [NodeTypes.Image]: Image,
    [NodeTypes.Video]: Video,
    [NodeTypes.Audio]: Audio,
    [NodeTypes.File]: File2,

    // ** ASK QUESTION NODES **
    [NodeTypes.AskName]: Name,
    [NodeTypes.AskEmail]: Email,
    [NodeTypes.AskPhoneNumber]: Phone,
    [NodeTypes.AskFile]: File,
    [NodeTypes.AskNumber]: Numbers,
    [NodeTypes.AskUrl]: Url,
    [NodeTypes.AskMultipleQuestions]: Questions,
    [NodeTypes.NCheckOptions]: Check,
    [NodeTypes.NSelectOption]: Select,
    [NodeTypes.AskLocation]: Location,
    [NodeTypes.AskCustomQuestion]: MoreQuestions,

    // ** FLOW OPERATION NODES **
    [NodeTypes.NChoices]: Choices,
    [NodeTypes.ImageChoice]: MultiImages,
    [NodeTypes.RatingChoice]: Feedback,
    [NodeTypes.YesOrNo]: YesNo,
    [NodeTypes.OpinionScale]: Opinions,

    // ** LOGICAL NODES **
    [NodeTypes.Condition]: Condition,
    [NodeTypes.BusinessHours]: Calendar,
    [NodeTypes.BooleanLogic]: Boolean,
    [NodeTypes.MathOperation]: Operations,
    [NodeTypes.RandomFlow]: RandomFlow,
    [NodeTypes.Variable]: Variable,

    // ** SPECIAL NODES **
    [NodeTypes.Html]: Html,
    [NodeTypes.Email]: Email,
    [NodeTypes.Redirect]: Redirect,
    [NodeTypes.Calendar]: Calendar,
    [NodeTypes.JumpTo]: Step,
    [NodeTypes.HumanHandover]: HumanHandover,
    [NodeTypes.Delay]: Delay,
    [NodeTypes.Navigate]: Navigate,

    // ** INTEGRATION NODES **
    [NodeTypes.Webhook]: Api,
    [NodeTypes.Gpt]: GPT,
    [NodeTypes.Zapier]: Zapier,
    [NodeTypes.GoogleSheets]: GoogleSheets,
    [NodeTypes.Slack]: Slack,
    [NodeTypes.Airtable]: Airtable,
    [NodeTypes.Zoho]: Zoho,
    [NodeTypes.HubSpot]: HubSpot,
    [NodeTypes.MailChimp]: Mailchimp,
    [NodeTypes.Segment]: Segment,
    [NodeTypes.Salesforce]: Salesforce,
  };

  // How to use YouTube link map for the nodes
  const howToUseLinksMap = {
    // **********  v1 (Legacy Nodes) **********
    // ** DEPRECATED NODES **
    [NodeTypes.SelectOption]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.TwoChoices]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.ThreeChoices]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Rating]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Range]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.UserInput]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Quiz]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",

    // **********  v2 (New Nodes) **********
    // ** STARTER NODE **
    [NodeTypes.Welcome]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",

    // ** SEND RESPONSE NODES **
    [NodeTypes.Message]: "https://youtu.be/ch8YCzkqi2g",
    [NodeTypes.Image]: "https://youtu.be/ncqQOPgAb88",
    [NodeTypes.Video]: "https://youtu.be/oQP57z8at5Q",
    [NodeTypes.Audio]: "https://youtu.be/R-pyIpZaYiI",
    [NodeTypes.File]: "https://youtu.be/U7_xK3B-p-Q",

    // ** ASK QUESTION NODES **
    [NodeTypes.AskName]: "https://youtu.be/lfokkR_DLc8",
    [NodeTypes.AskEmail]: "https://youtu.be/hI9y4l8x0Tk",
    [NodeTypes.AskPhoneNumber]: "https://youtu.be/sQPGQ8aO9eA",
    [NodeTypes.AskFile]: "https://youtu.be/KUgTTDc8wZA",
    [NodeTypes.AskNumber]: "https://youtu.be/PZJwaGOc5Jc",
    [NodeTypes.AskUrl]: "https://youtu.be/yTzAFa7NQ-0",
    [NodeTypes.AskMultipleQuestions]: "https://youtu.be/k5mIeFE83_Q",
    [NodeTypes.NCheckOptions]: "https://youtu.be/WVS5jLaRwLQ",
    [NodeTypes.NSelectOption]: "https://youtu.be/6q6B_lMHFx0",
    [NodeTypes.AskLocation]: "https://youtu.be/ElYX5x_BXgg",
    [NodeTypes.AskCustomQuestion]: "https://youtu.be/SoXcQKK-f_Q",

    // ** FLOW OPERATION NODES **
    [NodeTypes.NChoices]: "https://youtu.be/eI3oTlOYgdQ",
    [NodeTypes.ImageChoice]: "https://youtu.be/wrSg99QWYUU",
    [NodeTypes.RatingChoice]: "https://youtu.be/msE7duwcgW8",
    [NodeTypes.YesOrNo]: "https://youtu.be/2WgqA9W3ksc",
    [NodeTypes.OpinionScale]: "https://youtu.be/USsUz0C0Olc",

    // ** LOGICAL NODES **
    [NodeTypes.Condition]: "https://youtu.be/6EyDHq9_t_4",
    [NodeTypes.BusinessHours]: "https://youtu.be/CMi7KdCsOok",
    [NodeTypes.BooleanLogic]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.MathOperation]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.RandomFlow]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Variable]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",

    // ** SPECIAL NODES **
    [NodeTypes.Html]: "https://youtu.be/wV-cR0Ln8dI",
    [NodeTypes.Email]: "https://youtu.be/PWROjpzjW6M",
    [NodeTypes.Redirect]: "https://youtu.be/2mRBq5v1CfM",
    [NodeTypes.Calendar]: "https://youtu.be/V3xnCqqREug",
    [NodeTypes.JumpTo]: "https://youtu.be/DRWA3dP2HJg",
    [NodeTypes.HumanHandover]: "https://youtu.be/1ncehvfy-dk",
    [NodeTypes.Delay]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Navigate]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",

    // ** INTEGRATION NODES **
    [NodeTypes.Webhook]: "https://youtu.be/iHIUAiXWq2Y",
    [NodeTypes.Gpt]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Zapier]: "https://youtu.be/DA9YUQs_HHc",
    [NodeTypes.GoogleSheets]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Slack]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Airtable]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Zoho]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.HubSpot]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.MailChimp]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Segment]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
    [NodeTypes.Salesforce]: "https://www.youtube.com/watch?v=5yB2ZbZdZ5A",
  };

  // Default states for the nodes
  const nodeDefaultStates = {
    // **********  v1 (Legacy Nodes) **********
    // ** DEPRECATED NODES **
    [NodeTypes.SelectOption]: {
      data: {
        option1: "Option 1",
        option2: "Option 2",
        option3: "Option 3",
        option4: "Option 4",
        option5: "Option 5",
        disableOption3: false,
        disableOption4: false,
        disableOption5: false,
      },
    },
    [NodeTypes.TwoChoices]: {
      data: {},
    },
    [NodeTypes.ThreeChoices]: {
      data: {},
    },
    [NodeTypes.Rating]: {
      data: {},
    },
    [NodeTypes.Range]: {
      data: {},
    },
    [NodeTypes.UserInput]: {
      data: {},
    },
    [NodeTypes.Quiz]: {
      data: {},
    },
    [NodeTypes.Variable]: {
      data: {},
    },

    // **********  v2 (New Nodes) **********
    // ** STARTER NODE **
    [NodeTypes.Welcome]: {
      data: {
        text: "Welcome to the chatbot!",
        image:
          "https://botdefaults.s3.ap-south-1.amazonaws.com/welcome_gif_1.gif",
        disableImage: false,
      },
    },

    // ** SEND RESPONSE NODES **
    [NodeTypes.Message]: {
      data: { text: "" },
    },
    [NodeTypes.Image]: {
      data: {},
    },
    [NodeTypes.Video]: {
      data: {},
    },
    [NodeTypes.Audio]: {
      data: {},
    },
    [NodeTypes.File]: {
      data: {},
    },

    // ** ASK QUESTION NODES **
    [NodeTypes.AskName]: {
      data: {
        questionText: "",
        answerVariable: "",
        nameGreetResponse: "Nice meeting you, {name}",
      },
    },
    [NodeTypes.AskEmail]: {
      data: { questionText: "", answerVariable: "" },
    },
    [NodeTypes.AskPhoneNumber]: {
      data: { questionText: "", showCountryCode: "", answerVariable: "" },
    },
    [NodeTypes.AskFile]: {
      data: {
        text: "",
        questionText: "",
        files: "",
        answerVariable: "",
      },
    },
    [NodeTypes.AskNumber]: {
      data: {
        text: "",
        questionText: "",
        enableConditions: { format: "", prefix: "", minVal: "", maxVal: "" },
        answerVariable: "",
      },
    },
    [NodeTypes.AskUrl]: {
      data: { questionText: "", answerVariable: "" },
    },
    [NodeTypes.AskMultipleQuestions]: {
      data: {
        questions: [
          {
            questionText: "",

            answerVariable: "",
          },
        ],
      },
    },
    [NodeTypes.Calendar]: {
      data: {},
    },
    [NodeTypes.NCheckOptions]: {
      data: {
        options: [
          {
            optionText: "",

            answerVariable: "",
          },
        ],
      },
    },
    [NodeTypes.NSelectOption]: {
      data: {
        options: [
          {
            text: "Option 1",
          },
          {
            text: "Option 2",
          },
        ],
      },
    },
    [NodeTypes.AskLocation]: {
      data: { questionText: "", answerVariable: "" },
    },
    [NodeTypes.AskCustomQuestion]: {
      data: { text: "", answerVariable: "" },
    },

    // ** FLOW OPERATION NODES **
    [NodeTypes.NChoices]: {
      data: {
        choices: [
          {
            optionText: "",

            answerVariable: "",
          },
        ],
      },
    },
    [NodeTypes.ImageChoice]: {
      data: {
        message: "Multi Images",
        questionText: "",
        images: [{ title: "", image: "" }],
        answerVariable: "",
      },
    },
    [NodeTypes.RatingChoice]: {
      data: {
        questionText: "Rating Choice",
        ratingType: "",
        answerVariable: "",
        range: "",
      },
    },
    [NodeTypes.YesOrNo]: {
      data: {
        options: [
          { id: "1", questionText: "Yes", label: "Yes", answerVariable: "" },
          { id: "2", questionText: "No", label: "No", answerVariable: "" },
        ],
      },
    },
    [NodeTypes.OpinionScale]: {
      data: {
        questionText: "",
        from: "",
        to: "",
        numOfOptions: 1,
        answerVariable: "",
      },
    },

    // ** LOGICAL NODES **
    [NodeTypes.Condition]: {
      data: {},
    },
    [NodeTypes.BusinessHours]: {
      data: {
        timezone: "",
      },
    },
    [NodeTypes.BooleanLogic]: {
      data: {},
    },
    [NodeTypes.MathOperation]: {
      data: {},
    },
    [NodeTypes.RandomFlow]: {
      data: {},
    },

    // ** SPECIAL NODES **
    [NodeTypes.Html]: {
      data: { html: "" },
    },
    [NodeTypes.Email]: {
      data: {},
    },
    [NodeTypes.Redirect]: {
      data: {},
    },
    [NodeTypes.JumpTo]: {
      data: {},
    },
    [NodeTypes.HumanHandover]: {
      data: {},
    },
    [NodeTypes.Delay]: {
      data: {},
    },
    [NodeTypes.Navigate]: {
      data: {},
    },

    // ** INTEGRATION NODES **
    [NodeTypes.Webhook]: {
      data: {},
    },
    [NodeTypes.Gpt]: {
      data: { text: "", answerVariable: "" },
    },
    [NodeTypes.GoogleSheets]: {
      data: {},
    },
    [NodeTypes.Zapier]: {
      data: {},
    },
    [NodeTypes.Slack]: {
      data: {},
    },
    [NodeTypes.Airtable]: {
      data: {},
    },
    [NodeTypes.Zoho]: {
      data: {},
    },
    [NodeTypes.HubSpot]: {
      data: {},
    },
    [NodeTypes.MailChimp]: {
      data: {},
    },
    [NodeTypes.Segment]: {
      data: {},
    },
    [NodeTypes.Salesforce]: {
      data: {},
    },
  };
  return { labelsMap, iconsMap, howToUseLinksMap, nodeDefaultStates };
};

export default Constants;
