import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Table, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllCategories, deleteCategoryById } from "@api/template/template";
import {
  SuccessNotification,
  ErrorNotification,
  CategoryCreateModal,
  CategoryUpdateModal,
} from "@components";

const TemplateCategoriesList = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  // States
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  // Fetch all categories
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const { data } = await getAllCategories();
      setCategories(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ErrorNotification("Error fetching categories");
    }
  };

  // Handle create category modal
  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update category modal
  const handleUpdateModalClick = (id) => {
    setCategoryId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  // Handle category delete
  const handleCategoryDelete = (id) => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteCategoryById(id);
          // Fetch all categories
          fetchCategories();
          // Show success notification
          SuccessNotification("Category deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting category");
        }
      },
    });
  };

  // Define columns for Ant Design Table
  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of Templates",
      dataIndex: "templates",
      key: "templates",
      render: (templates) => templates.length,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {/* Edit */}
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={() => handleUpdateModalClick(record._id)}
          >
            Edit
          </Button>

          {/* Delete */}
          <Button
            type="danger"
            size="small"
            onClick={() => handleCategoryDelete(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {/* Create and Update Category Modals */}
      {createModalOpen && (
        <CategoryCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchCategories={fetchCategories}
        />
      )}

      {updateModalOpen && (
        <CategoryUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchCategories={fetchCategories}
          categoryId={categoryId}
        />
      )}

      {/* Categories List Table */}
      <Card style={{ borderRadius: "0", height: "100vh" }}>
        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <h3>Categories List</h3>
          </Col>
          <Col>
            {/* Back Button */}
            <Button
              onClick={() => {
                // If user is superadmin, redirect to chatbot templates
                if (user?.role === "superadmin") {
                  history.push("/super-admin/chatbot-templates");
                } else if (user?.role === "marketing") {
                  history.push("/marketing-admin/chatbot-templates");
                }
              }}
              style={{ marginRight: 16 }}
            >
              Go Back
            </Button>

            {/* Create Category */}
            <Button type="primary" onClick={handleCreateModalClick}>
              Create Category
            </Button>
          </Col>
        </Row>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(80vh - 50px)",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={categories}
            rowKey={(record) => record._id}
            pagination={false} // Disable pagination
          />
        )}
      </Card>
    </>
  );
};

export default TemplateCategoriesList;
