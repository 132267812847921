import { Button } from "antd";
import { useTranslation } from "react-i18next";

const UnhandledTickets = ({
  // Chat
  handleSelectChat,
  selectedChat,
  // Tickets
  unHandledTicketsData,
  // Load More
  loadMoreUnHandledTicketsRef,
  showLoadMoreUnHandledTickets,
  loadMoreUnHandledTicketsLoading,
  handleLoadMoreUnHandledTickets,
}) => {
  const formatTicketInfo = (ticket) => {
    return `${ticket._id.substring(0, 16)}`;
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="unhandled-tickets-list-container">
        {unHandledTicketsData?.unHandledTickets?.length > 0 ? (
          <>
            {unHandledTicketsData?.unHandledTickets?.map((ticket, index) => {
              return (
                <div
                  key={index}
                  className="unhandled-tickets-list"
                  onClick={() => handleSelectChat(ticket.response)}
                  style={{
                    backgroundColor:
                      selectedChat?.chatSessionId ===
                      ticket.response?.chatSessionId
                        ? "#d83347"
                        : "#fff",
                    color:
                      selectedChat?.chatSessionId ===
                      ticket.response?.chatSessionId
                        ? "#fff"
                        : "#000",
                    transition: "background-color 0.3 ease-out",
                  }}
                  ref={
                    index === unHandledTicketsData?.unHandledTickets?.length - 1
                      ? loadMoreUnHandledTicketsRef
                      : null
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      icon={"T"}
                      type="text"
                      size="small"
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        borderRadius: "50%",
                        minWidth: "30px",
                        backgroundColor: "#d83247",
                        color: "#fff",
                      }}
                    />

                    <div className="ticket-text">
                      {t("chatMessages.chatList.ticket")} #
                      {formatTicketInfo(ticket)}
                      <p
                        style={{
                          padding: "0",
                          margin: "0",
                          wordWrap: "break-word",
                          bottom: "1px",
                          fontSize: "12px",
                        }}
                      >
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "short", // 'short' will give you the abbreviated month name
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }).format(new Date(ticket?.openTime))}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Load More Button */}
            {showLoadMoreUnHandledTickets &&
              unHandledTicketsData?.unHandledTickets?.length >= 10 &&
              unHandledTicketsData.hasMore && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0.5rem",
                    }}
                  >
                    <Button
                      type="text"
                      size="small"
                      className="end-btn"
                      onClick={handleLoadMoreUnHandledTickets}
                      loading={loadMoreUnHandledTicketsLoading}
                      style={{
                        background: "#d83247",
                        color: "#fff",
                        borderRadius: "4px",
                        height: "30px",
                        width: "100%",
                      }}
                    >
                      {t("chatMessages.chatList.loadMore")}
                    </Button>
                  </div>
                </>
              )}
          </>
        ) : (
          <h3
            style={{
              height: "calc(100vh - 220px)",
              marginTop: "1.5rem",
              textAlign: "center",
              color: "#000",
              fontSize: "1.2rem",
              fontWeight: "500",
            }}
          >
            {t("chatMessages.chatList.noTicketsFound")} <br /> 👻
          </h3>
        )}
      </div>
    </>
  );
};

export default UnhandledTickets;
