import { useState, useEffect, FC } from "react";
import { Row, Col, Input, message, Modal, Button, Tooltip } from "antd";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { shallow } from "zustand/shallow";
import useStore, { selector } from "../../../../../store/store";
import { ZapierNode } from "../../../../../common/nodes/typings";
import {
  generateApiToken,
  revokeApiToken,
} from "../../../../../../../../../api/workspace/workspace";

import { useTranslation } from "react-i18next";

const ZapierNodeDataEditor: FC<ZapierNode> = ({ id, type }) => {
  const { bid } = useParams<{ bid: string }>();
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const { user } = useSelector((state: any) => state.auth);

  const state = getNode(id);

  const { t } = useTranslation();

  const [botId, setBotId] = useState(bid);
  const [nodeId, setNodeId] = useState(id); // State to store the node ID
  const [tokenData, setTokenData]: any = useState({}); // State to store the tokenData
  const [loading, setLoading] = useState(false); // State for loading indicator

  console.log("state", state);

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setBotId(bid);
    setNodeId(id);
    setTokenData(state?.data?.tokenData || {}); // Update tokenData state

    // Set the botId and nodeId to the state
    changeNodeData({
      ...state,
      type,
      data: {
        ...state.data,
        botId: bid,
        nodeId: id,
      },
    });
  }, [id]);

  // Handle generate key button click
  const handleGenerateToken = () => {
    setLoading(true);

    Modal.confirm({
      title: "Are you sure you want to generate a new key?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          generateApiToken()
            .then((res) => {
              message.success("Token generated successfully");

              // Update the tokenData state
              setTokenData(res.data);

              // Update the node data with the new tokenData
              changeNodeData({
                ...state,
                type,
                data: {
                  ...state.data,
                  tokenData: res.data,
                  botId: bid,
                  nodeId: id,
                },
              });

              // Reset loading state
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error generating key:", error);
              message.error("Failed to generate key");

              // Reset loading state
              setLoading(false);
            });
        } catch (error) {
          message.error("Failed to generate key");
        }
      },
      onCancel: () => {
        setLoading(false);
      },
    });
  };

  // Handle revoke key button click
  const handleRevokeToken = (tokenId: any) => {
    setLoading(true);

    Modal.confirm({
      title: "Are you sure you want to revoke this key?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          revokeApiToken(tokenId)
            .then(() => {
              message.success("Token revoked successfully");

              // Update the tokenData state
              setTokenData("");

              // Update the node data with the new tokenData
              changeNodeData({
                ...state,
                type,
                data: {
                  ...state.data,
                  tokenData: "",
                },
              });

              // Reset loading state
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error revoking key:", error);
              message.error("Failed to revoke key");

              // Reset loading state
              setLoading(false);
            });
        } catch (error) {
          message.error("Failed to revoke key");
        }
      },
      onCancel: () => {
        setLoading(false);
      },
    });
  };

  // Handle Copy Token
  const handleCopyToken = () => {
    const inputField = document.getElementById("myInput") as HTMLInputElement;
    if (inputField) {
      inputField.select();
      document.execCommand("copy");
      message.success("Copied to clipboard");
    }
  };

  // Function to copy text to clipboard
  const copyToClipboard = (text: string) => {
    // Set the botId and nodeId to the state
    changeNodeData({
      ...state,
      type,
      data: {
        ...state.data,
        botId: bid,
        nodeId: id,
      },
    });

    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    message.success("Copied to clipboard");
  };

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            {t("flowBuilder.sidebar.editor.integrationNodes.zapierNodeInfo")}
          </p>
        </Row>

        <div className="zapier_options">
          <div className="zapier_auth">
            <Col span={24}>
              <span className="title">
                {t(
                  "flowBuilder.sidebar.editor.integrationNodes.conferbotToken"
                )}
              </span>
              <Input
                readOnly
                suffix={
                  tokenData?._id ? (
                    <>
                      <Tooltip title="Copy Token">
                        <CopyOutlined
                          onClick={handleCopyToken}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      <Tooltip title="Revoke Token">
                        <DeleteOutlined
                          onClick={() => handleRevokeToken(tokenData._id)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                          }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      size="small"
                      onClick={handleGenerateToken}
                      style={{
                        fontWeight: "600",
                        cursor: "pointer",
                        borderRadius: "3px",
                      }}
                      className="copy_btn"
                      loading={loading}
                    >
                      {t(
                        "flowBuilder.sidebar.editor.integrationNodes.generate"
                      )}
                    </Button>
                  )
                }
                id="myInput"
                value={tokenData?.key}
                style={{
                  borderRadius: "3px",
                  marginBottom: "15px",
                  marginTop: "5px",
                }}
              />
            </Col>

            <hr />

            {/* Bot Meta Data */}
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <span className="title">
                  {t("flowBuilder.sidebar.editor.integrationNodes.botId")}
                </span>
                <Input
                  readOnly
                  suffix={
                    <Tooltip title="Copy Bot ID">
                      <CopyOutlined
                        onClick={() => copyToClipboard(botId)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  }
                  value={botId}
                  style={{
                    borderRadius: "3px",
                    marginBottom: "15px",
                    marginTop: "5px",
                  }}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <span className="title">
                  {t("flowBuilder.sidebar.editor.integrationNodes.nodeId")}
                </span>
                <Input
                  readOnly
                  suffix={
                    <Tooltip title="Copy Node ID">
                      <CopyOutlined
                        onClick={() => copyToClipboard(nodeId)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  }
                  value={nodeId}
                  style={{
                    borderRadius: "3px",
                    marginBottom: "15px",
                    marginTop: "5px",
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Row>

      <hr
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      />

      <span
        style={{
          fontSize: "16px",
          fontStyle: "italic",
          fontWeight: 600,
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        Choose from the following Zapier templates
      </span>

      <zapier-zap-templates
        sign-up-email={user.email}
        sign-up-first-name={user.name}
        sign-up-last-name={user.name}
        client-id={process.env.REACT_APP_ZAPIER_CLIENT_ID}
        theme="light"
        apps="conferbot"
        create-without-template="show"
        limit={5}
        link-target="new-tab"
        presentation="row"
        use-this-zap="show"
      ></zapier-zap-templates>
    </>
  );
};

export default ZapierNodeDataEditor;
