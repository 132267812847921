import React, { useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import KnowledgeBaseLeft from "./components/KnowledgeBaseLeft";
import KnowledgeBaseRight from "./components/KnowledgeBaseRight";
import "@assets/css/KnowledgeBase.css";
import { knowledgeBaseSteps } from "src/helpers/shepherd/knowledgeBaseSteps";
import { Tour } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { updateTutorialStatusAction } from "../../../redux/actions/adminActions";

const KnowledgeBase = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [createCategoryModal, setCreateCategoryModal] = useState(false);

  const [kbTour, setKbTour] = useState(
    user?.tutorials_complete?.knowledgeBase ||
      JSON.parse(localStorage.getItem("shepherd"))?.knowledgeBase
  );

  useEffect(() => {
    const shepherdData = JSON.parse(localStorage.getItem("shepherd"));
    if (shepherdData) {
      // Check each tutorial status and update the server if it's not already updated
      if (!user?.tutorials_complete?.knowledgeBase && kbTour) {
        dispatch(updateTutorialStatusAction(shepherdData));
      }
    }
  }, []);

  // Handle Sidebar Guide Tour finish
  const handleSidebarTourComplete = (_, completionStatus) => {
    if (completionStatus) {
      // Set KnowledgeBase Tour status to true
      setKbTour(true);

      // Update LocalStorage guideTour status
      const existingData = localStorage.getItem("shepherd");
      const parsedData = JSON.parse(existingData);
      const updatedData = { ...parsedData, knowledgeBase: true };

      localStorage.setItem("shepherd", JSON.stringify(updatedData));

      // Update server with tutorial status
      dispatch(updateTutorialStatusAction(updatedData));
    }
  };

  return (
    <>
      {/* Knowledge-Base Guide Tour */}
      {!kbTour && (
        <Tour
          steps={knowledgeBaseSteps}
          tutComplete={kbTour}
          tourId="kbTour"
          handleTourComplete={handleSidebarTourComplete}
        />
      )}

      <Layout style={{ height: "100vh" }}>
        <Row className="knowledge-base-container">
          {/* Left */}
          <Col md={5} sm={5} xs={5}>
            <div className="knowledge-base-left-container">
              <KnowledgeBaseLeft
                setCreateCategoryModal={setCreateCategoryModal}
              />
            </div>
          </Col>

          {/* Right */}
          <Col md={19} sm={19} xs={19}>
            <div className="knowledge-base-right-container">
              <KnowledgeBaseRight
                createCategoryModal={createCategoryModal}
                setCreateCategoryModal={setCreateCategoryModal}
              />
            </div>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default KnowledgeBase;
