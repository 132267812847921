import { Col, Row, Skeleton } from "antd";

const ChatbotListSkeleton = () => {
  return (
    <Row
      style={{
        padding: "0px 15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      {/* Avatar */}
      <Col>
        <Skeleton.Button shape="circle" active />
      </Col>
      {/* Chat Bot Name */}
      <Col
        style={{
          height: "25px",
          overflow: "hidden",
          borderRadius: "20px",
          width: "80%",
        }}
      >
        <Skeleton.Input
          active={true}
          size={"small"}
          block={true}
          style={{
            backgroundColor: "#0000000f",
            width: "100%",
          }}
        />
      </Col>
    </Row>
  );
};

export default ChatbotListSkeleton;
