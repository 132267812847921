import { FC, useState, useEffect, useCallback } from "react";
import { Row, Col } from "antd";
import { shallow } from "zustand/shallow";
import { ThreeChoicesNode } from "../../../../common/nodes/typings";
import useStore, { selector } from "../../../../store/store";
import QuillEditor from "../../../../common/components/quillEditor/QuillEditor";

const ThreeChoicesNodeDataEditor: FC<ThreeChoicesNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);

  const state = getNode(id);

  const [choicePrompt, setChoicePrompt] = useState(state?.data?.choicePrompt);
  const [choiceText1, setChoiceText1] = useState(state?.data?.choiceText1);
  const [choiceText2, setChoiceText2] = useState(state?.data?.choiceText2);
  const [choiceText3, setChoiceText3] = useState(state?.data?.choiceText3);

  // Each time botMessageNodeData changes, update the state
  useEffect(() => {
    setChoicePrompt(state?.data?.choicePrompt);
    setChoiceText1(state?.data?.choiceText1);
    setChoiceText2(state?.data?.choiceText2);
    setChoiceText3(state?.data?.choiceText3);
  }, [id]);

  const onTextAreaChange = useCallback(
    (content: any) => {
      if (!state) return;

      // Set the setChoicePrompt
      setChoicePrompt(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          choicePrompt: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle choice text change
  const handleChoiceTextChange = useCallback(
    (content: any, attrKey: string) => {
      if (!state) return;

      if (attrKey === "choiceText1") {
        // Set the setChoiceText1
        setChoiceText1(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            choiceText1: content,
          },
        });
      }
      if (attrKey === "choiceText2") {
        // Set the setChoiceText1
        setChoiceText2(content);
        changeNodeData({
          ...state,
          type,
          data: {
            ...state?.data,
            choiceText2: content,
          },
        });
      }
    },
    [state, type, changeNodeData]
  );

  return (
    <>
      <Row align="middle">
        <Row>
          <p
            style={{
              fontSize: "14px",
              color: "gray",
            }}
          >
            Use this node when you want the conversation to go in two different
            directions, based on the user's choice.
          </p>
        </Row>

        {/* Question Text*/}
        <Col span={24} className="sidebar__heading">
          Question Text
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={choicePrompt}
              onEditorChange={onTextAreaChange}
              placeholderText="Ex: What would you like to do?"
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "15px",
            width: "100%",
          }}
        />

        <Col span={24} className="sidebar__heading">
          Choice 1
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={choiceText1}
              onEditorChange={(content) =>
                handleChoiceTextChange(content, "choiceText1")
              }
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />
        <Col span={24} className="sidebar__heading">
          Choice 2
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={choiceText2}
              onEditorChange={(content) =>
                handleChoiceTextChange(content, "choiceText2")
              }
            />
          </div>
        </Col>

        {/* 1 Line space */}
        <div
          style={{
            height: "10px",
            width: "100%",
          }}
        />
        <Col span={24} className="sidebar__heading">
          Choice 3
        </Col>
        <Col span={24}>
          <div>
            <QuillEditor
              initialContent={choiceText3}
              onEditorChange={(content) =>
                handleChoiceTextChange(content, "choiceText3")
              }
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ThreeChoicesNodeDataEditor;
