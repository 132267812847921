import { useEffect } from "react";
import { Col, Row } from "antd";
import { useParams } from "react-router";
import { ReactFlowProvider } from "reactflow";

import Header from "./header";
// Redux Action
import { useDispatch, useSelector } from "react-redux";
import { getChatbotFlowVersionAction } from "../../../../redux/actions/chatbotActions";
// Version components
import FlowVersion from "./flowVersion";

const ChatbotFlowVersion = () => {
  const { loading } = useSelector((state: any) => state.alert);

  const { bid, vid } = useParams<{ bid: string; vid: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch action to get current chatbot flow analytics
    dispatch(getChatbotFlowVersionAction(bid, vid));
  }, [dispatch, vid]);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "6px",
            overflow: "hidden",
          }}
        >
          <ReactFlowProvider>
            {/* Header */}
            <Header isVersion={true} />

            {loading ? (
              <div className="builder-dual-ring"></div>
            ) : (
              <Row>
                <Col
                  span={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    right: 0,
                    top: "60px",
                    background: "#f0f2f8",
                    transition: "all 0.2s ease-out",
                    zIndex: 90,
                  }}
                >
                  {/* Flow Version */}
                  <FlowVersion />
                </Col>
              </Row>
            )}
          </ReactFlowProvider>
        </div>
      </div>
    </>
  );
};

export default ChatbotFlowVersion;
