import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import { shallow } from "zustand/shallow";
import { NSelectOptionNode } from "../../../../../common/nodes/typings";
import useStore, { selector } from "../../../../../store/store";
import QuillEditor from "../../../../../common/components/quillEditor/QuillEditor";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import SaveAnswer from "../../../../../common/components/saveAnswer/saveAnswer";
import { PiDotsNineBold } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const NSelectOptionNodeDataEditor: FC<NSelectOptionNode> = ({ id, type }) => {
  const { getNode, changeNodeData } = useStore(selector, shallow);
  const state = getNode(id);

  const [questionText, setQuestionText] = useState(state?.data?.selectPrompt);
  const [options, setOptions] = useState(state?.data?.options || []);
  const [answerVariable, setAnswerVariable] = useState(
    state?.data?.answerVariable
  );
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);
  const { t } = useTranslation();

  // Handle question change
  const handleQuestionChange = useCallback(
    (content: any) => {
      if (!state) return;
      setQuestionText(content);

      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          selectPrompt: content,
        },
      });
    },
    [state, type, changeNodeData]
  );

  // Handle adding a new option
  const handleAddOption = useCallback(() => {
    const optionId = uuidv4();
    const newOption = {
      id: optionId,
      optionText: "",
    };
    setActive(optionId);
    setOptions([...options, newOption]);
    changeNodeData({
      ...state,
      type,
      data: {
        ...state?.data,
        options: [...options, newOption],
      },
    });
  }, [options, state, type, changeNodeData]);

  // Handle deleting an option
  const handleDeleteOption = useCallback(
    (optionId: any) => {
      const updatedOptions = options.filter(
        (option: any) => option.id !== optionId
      );

      setOptions(updatedOptions);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state?.data,
          options: updatedOptions,
        },
      });
    },
    [options, state, type, changeNodeData]
  );

  // OnDrag Function
  const onDragEnd = (e: any) => {
    const { sourceIndex, targetIndex } = e;
    setOptions((prevQuestions: any) => {
      const updatedCards = [...prevQuestions];
      const [movedCard] = updatedCards.splice(sourceIndex, 1);
      updatedCards.splice(targetIndex, 0, movedCard);
      changeNodeData({
        ...state,
        type,
        data: {
          ...state.data,
          options: updatedCards,
        },
      });
      return updatedCards;
    });
  };

  // Open and Hide toggle Function
  const toggleDropdown = (id: any) => {
    setIsOpen(!isOpen);
    setActive(id);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [active]);

  return (
    <>
      <Row>
        <p
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: 600,
          }}
        >
          {t(
            "flowBuilder.sidebar.editor.askQuestionNodes.nSelectOptionsNodeInfo"
          )}
        </p>
      </Row>

      <Row>
        {/* Question Text */}
        <Divider orientation="left">
          <Col
            span={24}
            style={{ alignSelf: "start" }}
            className="sidebar__heading"
          >
            {t("flowBuilder.sidebar.editor.askQuestionNodes.questionText")}
          </Col>
        </Divider>

        <Col span={24} style={{ width: "100%" }}>
          <div>
            <QuillEditor
              initialContent={questionText}
              onEditorChange={handleQuestionChange}
              placeholderText={t(
                "flowBuilder.sidebar.editor.askQuestionNodes.questionTextPH"
              )}
            />
          </div>
        </Col>
        <Col span={24} className="drag_multi_options_container">
          {options.map((option: any, index: any) => (
            <>
              <Col
                className={`drag_multi_options_inner ${
                  isOpen && active === option.id ? "open" : ""
                }`}
              >
                <div
                  className="drag_dropdown_button"
                  onClick={() => toggleDropdown(option?.id)}
                  draggable
                  onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", index.toString())
                  }
                  onDrop={(e) => {
                    e.preventDefault();
                    onDragEnd({
                      sourceIndex: Number(e.dataTransfer.getData("text/plain")),
                      targetIndex: index,
                    });
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    position: "relative",
                    border:
                      isOpen && active === option.id
                        ? "2px solid #fff"
                        : "2px solid transparent",
                    boxShadow:
                      isOpen &&
                      active === option.id &&
                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        left: "10px",
                      }}
                    >
                      {t("flowBuilder.sidebar.editor.askQuestionNodes.option")}{" "}
                      {index + 1}
                    </div>
                  </Col>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      type="text"
                      icon={<PiDotsNineBold />}
                      size={"small"}
                      className="drag_button"
                    />
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      size={"small"}
                      style={{ borderRadius: "4px" }}
                      onClick={() => handleDeleteOption(option.id)}
                      className="drag_dlt_button"
                    />
                  </div>
                </div>
                {isOpen && active === option.id && (
                  <div className="drag_isOpen_container">
                    <Row>
                      <Col span={24}>
                        <QuillEditor
                          initialContent={option.optionText}
                          onEditorChange={(content) => {
                            // Find the index of the matching option in the questions array
                            const questionIndex = options.findIndex(
                              (q: any) => q.id === option.id
                            );

                            // Copy the option and modify the questionText
                            const updatedQuestion = {
                              ...options[questionIndex],
                              optionText: content,
                            };

                            // Copy the questions array and replace the option at the found index
                            const updatedQuestions = [...options];
                            updatedQuestions[questionIndex] = updatedQuestion;

                            // Save the updated questions array back to the state
                            setOptions(updatedQuestions);
                            changeNodeData({
                              ...state,
                              type,
                              data: {
                                ...state.data,
                                options: updatedQuestions,
                              },
                            });
                          }}
                          placeholderText="Type option here!"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </>
          ))}

          <Col span={24}>
            <Button
              onClick={handleAddOption}
              block
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("flowBuilder.sidebar.editor.askQuestionNodes.addOption")}
            </Button>
          </Col>
        </Col>
      </Row>

      <SaveAnswer id={id} type={type} variableName={answerVariable} />
    </>
  );
};

export default NSelectOptionNodeDataEditor;
