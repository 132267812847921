import { NodeTypes } from "../types/types";

export const dndAllNodes = {
  // **********  v1 (Legacy Nodes) **********
  // ** DEPRECATED NODES **
  [NodeTypes.TwoChoices]: {
    data: {
      choicePrompt: "",
      choiceText1: "",
      choiceText2: "",
      disableSecondChoice: false,
    },
  },
  [NodeTypes.ThreeChoices]: {
    data: {
      choicePrompt: "",
      choiceText1: "",
      choiceText2: "",
      choiceText3: "",
    },
  },
  [NodeTypes.SelectOption]: {
    data: {
      selectPrompt: "",
      optionText1: "",
      optionText2: "",
      optionText3: "",
      optionText4: "",
      optionText5: "",
      disableOption3: false,
      disableOption4: false,
      disableOption5: false,
    },
  },
  [NodeTypes.Rating]: {
    data: {
      ratingPrompt: "",
      type: "",
      range: "",
    },
  },
  [NodeTypes.Range]: {
    data: {
      rangePrompt: "",
      type: "",
      range: "",
      minVal: "",
      maxVal: "",
    },
  },
  [NodeTypes.UserInput]: {
    data: {
      inputPrompt: "",
      type: "",
      customInput: "",
      nameGreet: "Nice to meet you, {name}",
      incorrectEmailResponse: "Please enter a valid email address",
    },
  },
  [NodeTypes.Quiz]: {
    data: {
      quizPrompt: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      option5: "",
      disableOption3: false,
      disableOption4: false,
      disableOption5: false,
      correctAnswer: "",
    },
  },

  // **********  v2 (New Nodes) **********
  // ** STARTER NODE **
  [NodeTypes.Welcome]: {
    data: {
      text: "",
      image:
        "https://botdefaults.s3.ap-south-1.amazonaws.com/welcome_gif_1.gif",
      disableImage: false,
    },
  },

  // ** SEND RESPONSE NODES **
  [NodeTypes.Message]: {
    data: {
      text: "",
    },
  },
  [NodeTypes.Image]: {
    data: {
      image: "",
    },
  },
  [NodeTypes.Video]: {
    data: {
      video: "",
    },
  },
  [NodeTypes.Audio]: {
    data: {
      audio: "",
    },
  },
  [NodeTypes.File]: {
    data: {
      file: "",
    },
  },

  // ** ASK QUESTION NODES **
  [NodeTypes.AskName]: {
    data: {
      questionText: "",
      nameGreetResponse: "Nice meeting you, {name}",
      answerVariable: "name",
    },
  },
  [NodeTypes.AskEmail]: {
    data: {
      questionText: "",
      incorrectEmailResponse: "Please enter a valid email address",
      answerVariable: "email",
    },
  },
  [NodeTypes.AskPhoneNumber]: {
    data: {
      questionText: "",
      answerVariable: "phone_number",
    },
  },
  [NodeTypes.AskNumber]: {
    data: {
      questionText: "",
      enableConditions: { format: "", prefix: "", minVal: "", maxVal: "" },
      answerVariable: "number",
    },
  },
  [NodeTypes.AskFile]: {
    data: {
      questionText: "",
      answerVariable: "file",
    },
  },
  [NodeTypes.AskUrl]: {
    data: {
      questionText: "",
      answerVariable: "url",
    },
  },
  [NodeTypes.AskLocation]: {
    data: {
      questionText: "",
      answerVariable: "location",
    },
  },
  [NodeTypes.AskMultipleQuestions]: {
    data: {
      message: "Answer the following questions",
      questions: [{ id: "1", questionText: "", answerVariable: "" }],
    },
  },
  [NodeTypes.AskCustomQuestion]: {
    data: {
      questionText: "",
      answerVariable: "name",
    },
  },
  [NodeTypes.Calendar]: {
    data: {
      calendarPrompt: "",
      showPastDates: true,
      excludeDays: [""],
      excludeDates: [""],
      showTimeSelection: false,
      weeklyHours: [
        {
          day: 0,
          dayName: "Sunday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 1,
          dayName: "Monday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 2,
          dayName: "Tuesday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 3,
          dayName: "Wednesday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 4,
          dayName: "Thursday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 5,
          dayName: "Friday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 6,
          dayName: "Saturday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
      ],
      answerVariable: "calendar_answer",
    },
  },
  [NodeTypes.NCheckOptions]: {
    data: {
      checkOptionsPrompt: "",
      options: [{ id: "1", optionText: "" }],
      answerVariable: "selected_options",
    },
  },
  [NodeTypes.NSelectOption]: {
    data: {
      selectPrompt: "",
      options: [
        { id: "1", optionText: "" },
        { id: "2", optionText: "" },
      ],
      answerVariable: "selected_options",
    },
  },

  // ** FLOW OPERATION NODES **
  [NodeTypes.NChoices]: {
    data: {
      choicePrompt: "",
      choices: [{ id: "1", choiceText: "" }],
      answerVariable: "selected_choice",
    },
  },
  [NodeTypes.ImageChoice]: {
    data: {
      questionText: "",
      images: [{ id: "1", label: "", image: "" }],
      answerVariable: "selected_image",
    },
  },
  [NodeTypes.RatingChoice]: {
    data: {
      questionText: "",
      ratingType: "",
      answerVariable: "rating_choice",
    },
  },
  [NodeTypes.YesOrNo]: {
    data: {
      questionText: "",
      options: [
        { id: "1", label: "Yes" },
        { id: "2", label: "No" },
      ],
      answerVariable: "yes_no_answer",
    },
  },
  [NodeTypes.OpinionScale]: {
    data: {
      questionText: "",
      from: "",
      fromLabel: "",
      to: "",
      toLabel: "",
      answerVariable: "opinion_scale",
    },
  },

  // ** LOGIC NODES **
  [NodeTypes.BusinessHours]: {
    data: {
      timezone: "",
      excludeDays: [""],
      excludeDates: [""],
      weeklyHours: [
        {
          day: 0,
          dayName: "Sunday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 1,
          dayName: "Monday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 2,
          dayName: "Tuesday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 3,
          dayName: "Wednesday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 4,
          dayName: "Thursday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 5,
          dayName: "Friday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
        {
          day: 6,
          dayName: "Saturday",
          available: false,
          slots: [
            {
              start: "09:00",
              end: "17:00",
            },
          ],
        },
      ],
    },
  },
  [NodeTypes.Condition]: {
    data: {
      isNumber: true,
      customLeftValue: false,
      leftValue: "",
      operator: "",
      customRightValue: false,
      rightValue: "",
    },
  },
  [NodeTypes.MathOperation]: {
    data: {
      leftValue: "",
      operator: "",
      rightValue: "",
      answerVariable: "math_operations",
    },
  },
  [NodeTypes.BooleanLogic]: {
    data: {
      leftValue: "",
      operator: "",
      rightValue: "",
    },
  },
  [NodeTypes.RandomFlow]: {
    data: {
      branches: [
        { id: "0", weight: 1 },
        { id: "1", weight: 1 },
      ],
    },
  },
  [NodeTypes.Variable]: {
    data: {
      customNameValue: false,
      name: "",
      isNumber: true,
      value: "",
    },
  },

  // ** SPECIAL NODES **
  [NodeTypes.Email]: {
    data: {
      to: [] as string[],
      cc: [] as string[],
      bcc: [] as string[],
      subject: "",
      body: "",
      includeChatTranscript: false,
    },
  },
  [NodeTypes.Html]: {
    data: {
      html: "",
    },
  },
  [NodeTypes.Redirect]: {
    data: {
      redirectPrompt: "",
      link: "",
    },
  },
  [NodeTypes.JumpTo]: {
    data: {
      nodeId: "",
    },
  },
  [NodeTypes.HumanHandover]: {
    data: {
      priority: "",
      handoverMessage: "Please wait while we connect you to a human agent",
      agentWaitingMessage: "Please wait while we connect you to an agent",
      maxWaitTime: 2,
      fallbackMessage:
        "Sorry, all our agents are busy at the moment, please try again later",
      // AssignmentType can be auto or manual
      assignmentType: "auto",
      assignedAgents: [] as string[],
      agentAssignmentStrategy: "leastBusy",
      // We need handover notification to be sent to the assigned agents
      enableHandoverEmail: true,
      enableHandoverSound: true,
      // Tags
      tags: [] as string[],
      // Pre chat questions
      enablePreChatQuestions: false,
      preChatQuestions: [
        {
          id: Math.random().toString(36).substr(2, 9),
          questionText: "",
          answerVariable: "",
        },
      ],
      // enablePostChatSurveyQuestions
      enablePostChatSurveyQuestions: false,
      postChatSurveyQuestions: [
        {
          id: Math.random().toString(36).substr(2, 9),
          questionText: "",
          answerVariable: "",
        },
      ],
    },
  },
  [NodeTypes.Delay]: {
    data: {
      delay: "",
    },
  },
  [NodeTypes.Navigate]: {
    data: {
      navigatePrompt: "",
      navigateOptions: [
        {
          id: Math.random().toString(36).substr(2, 9),
          navigateText: "",
          navigateLink: "",
          openInNewTab: false,
        },
      ],
      doneNavigatingText: "",
      answerVariable: "navigate_choice",
    },
  },

  // ** INTEGRATION NODES **
  [NodeTypes.Webhook]: {
    data: {
      authentication: {
        username: "",
        password: "",
        tokenUrl: "",
      },
      url: "",
      method: "",
      headers: [] as string[],
      body: "",
      includeAnswerVariables: true,
      answerVariable: "webhookresponse",
    },
  },
  [NodeTypes.Gpt]: {
    data: {
      context: "",
      apiKey: "",
      selectedModel: "",
      responseVariable: "",
    },
  },
  [NodeTypes.GoogleSheets]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
  [NodeTypes.Zapier]: {
    data: {
      botId: "",
      nodeId: "",
      tokenData: {},
    },
  },
  [NodeTypes.Slack]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
  [NodeTypes.Airtable]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
  [NodeTypes.Zoho]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
  [NodeTypes.HubSpot]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
  [NodeTypes.MailChimp]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
  [NodeTypes.Segment]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
  [NodeTypes.Salesforce]: {
    data: {
      questionText: "",
      answerVariable: "",
    },
  },
};
