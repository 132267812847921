import React, { createContext, useContext, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { showNotificationAction } from "../redux/actions/chatbotActions";
import {
  handleJoinWorkspace,
  handoverRequestHandler,
  handleNotificationCleanup,
  handleJoinChatInvitationHandler,
} from "../helpers/socket";

// Create a context
const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, userId, wid, role, history }) => {
  const socket = useRef(null);
  const dispatch = useDispatch();

  // Global reference for handover-notification cleanup
  let remainingTime = useRef(0);

  useEffect(() => {
    // Initialize WebSocket connection
    socket.current = io(`${process.env.REACT_APP_BACKEND_SOCKET}`, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99999,
    });

    // Listen to 'connect' event for initial connection and re-connection
    socket.current.on("connect", () =>
      handleJoinWorkspace(socket, wid, userId)
    );

    // ** Listener for handover-request
    socket.current.on("handover-request", ({ data }) => {
      // Show bell notification
      dispatch(showNotificationAction(true));

      // Call the handler
      handoverRequestHandler(
        socket,
        wid,
        userId,
        role,
        data,
        history,
        remainingTime.current
      );
    });

    // ** Listener for invitation-to-join-chat
    socket.current.on("invitation-to-join-chat", (data) => {
      // Show bell notification
      dispatch(showNotificationAction(true));

      // Call the handler
      handleJoinChatInvitationHandler(socket, wid, userId, role, data, history);
    });

    // ** Listen for 'destroy-notification' event from server
    socket.current.on("destroy-notification", () => {
      // Set the remaining time to 0
      remainingTime.current = 0;

      // Call the cleanup handler
      handleNotificationCleanup();
    });

    return () => {
      // Disconnect from the workspace id room
      socket.current.emit("disconnect-user", { workspaceId: wid, userId });

      // Unregister event listeners
      socket.current.off("connect", handleJoinWorkspace);

      // Remove the global listener for handover-request
      socket.current.off("handover-request", handoverRequestHandler);

      // Remove listener for 'destroy-notification'
      socket.current.off("destroy-notification");

      // Close the WebSocket connection
      socket.current.close();
    };
  }, [wid]);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook for accessing WebSocket context
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
