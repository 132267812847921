import React, { useState } from "react";
import { Col, Input, Menu, Row } from "antd";
import { CloseSquare } from "react-iconly";
import SidebarOptions from "../../sidebar/meta/options";
import Constants from "../../sidebar/meta/constants";
import "./EdgeDropContextMenu.css";

interface Props {
  edgeDropCoordinates: { x: number; y: number };
  setNodeOptionsVisible: any;
  handleNodeOptionClick: (nodeType: string) => void;
}

const EdgeDropContextMenu: React.FC<Props> = ({
  edgeDropCoordinates,
  setNodeOptionsVisible,
  handleNodeOptionClick,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const { iconsMap } = Constants();

  const {
    SendResponseNodes,
    AskQuestionNodes,
    FlowOperations,
    LogicalNodes,
    SpecialNodes,
    IntegrationNodes,
  } = SidebarOptions();

  // All nodes
  const allNodes = [
    ...SendResponseNodes,
    ...AskQuestionNodes,
    ...FlowOperations,
    ...LogicalNodes,
    ...SpecialNodes,
    ...IntegrationNodes,
  ];

  // Handle nodes search
  const handleNodesSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredResults = allNodes.filter(
      (node: any) =>
        node.label.toLowerCase().includes(term) ||
        node.description.toLowerCase().includes(term)
    );
    setSearchResults(filteredResults);
  };

  // Capitalize Text
  function capitalizeText(text: string) {
    return text
      .split("-")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Determine which set of nodes to render based on searchTerm
  const nodesToRender = searchTerm.length > 0 ? searchResults : allNodes;

  return (
    <div
      className="edge-drop-menu-container"
      style={{
        top: `calc(${edgeDropCoordinates.y}px - 60px)`,
        left: `calc(${edgeDropCoordinates.x}px - 390px)`,
      }}
    >
      <div
        style={{
          display: "block",
          fontWeight: "600",
          color: "#000",
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
          zIndex: 999999999,
          paddingBottom: "8px",
          borderRadius: "6px 6px 0px 0px",
        }}
      >
        <Row
          justify={"space-between"}
          style={{ padding: "6px 10px", marginTop: "6px" }}
        >
          <Col>Type to search</Col>
          <Col onClick={() => setNodeOptionsVisible(false)}>
            <CloseSquare
              style={{
                height: "20px",
                width: "20px",
                cursor: "pointer",
                color: "red",
              }}
            />
          </Col>
        </Row>
        {/* Search Nodes */}
        <div
          className="switch-container"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        >
          <Input
            bordered={false}
            id="chatInput"
            placeholder={"Search nodes"}
            value={searchTerm}
            onChange={(e) => handleNodesSearch(e)}
            style={{
              boxShadow: "none",
              border: "0.5px solid lightGray",
              borderRadius: "4px",
              backgroundColor: "#fff",
            }}
            suffix={
              <>
                {searchTerm.length > 0 && (
                  <span
                    onClick={() => setSearchTerm("")}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CloseSquare
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                )}
              </>
            }
          />
        </div>
      </div>
      <Menu className="edge-drop-menu" selectable={false}>
        {nodesToRender.map((node: any) => {
          const { id, type, label } = node;
          const icon: string = iconsMap[type as keyof typeof iconsMap];
          return (
            <Menu.Item
              key={id}
              onClick={() => handleNodeOptionClick(type)}
              className="edge-drop-menu-item"
              style={{ lineHeight: "1.5" }}
            >
              <div className="edge-drop-menu-item-inner">
                <img
                  src={icon}
                  style={{ height: "20px", width: "20px" }}
                  alt={label}
                />
                <span style={{ marginLeft: "8px" }}>
                  {capitalizeText(label)}
                </span>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
};

export default EdgeDropContextMenu;
