import { useCallback, useState, useEffect } from "react";
import { Button, Modal, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { HistoryOutlined } from "@ant-design/icons";
import { HeaderNav, PublishHistoryDrawer } from "./components";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../../../../components";
import {
  setCurrentBotFlowDataAction,
  publishChatbotAction,
  deleteChatbotFlowVersionAction,
} from "../../../../../redux/actions/chatbotActions";
import { restorePublishedChatbotFlowVersionById } from "../../../../../api/chatbot/chatbot";
import useStore from "../store/store";
import BotLogo from "../../../../../assets/images/pages/chatList/botLogo.png";
import DraggableModal from "../shared/draggableModal";
import ChatbotFlowPreview from "../preview/ChatbotFlowPreview";
import { updateChatbotById } from "../../../../../api/chatbot/chatbot";
// translation
import { useTranslation } from "react-i18next";

const Header: any = ({
  isBuilder,
  isVersion,
  isCustomize,
  isShare,
  isAnalytics,
}: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const { error } = useSelector((state: any) => state.alert);
  const { currentBotFlowData, publishedVersionHistory } = useSelector(
    (state: any) => state?.chatbot ?? {}
  );
  const { t } = useTranslation();

  // Get bot id from Url
  const { bid, vid } = useParams<{ bid: string; vid: string }>();

  const [saving, setSaving] = useState(false);
  const { nodes, edges } = useStore((state) => ({
    nodes: state.nodes,
    edges: state.edges,
  }));
  const [modalVisible, setModalVisible] = useState(false);
  const [modalSize, setModalSize] = useState("mobile");
  // Publish history
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  // Restore
  const [isRestoring, setIsRestoring] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Autosave every 15 seconds, when the user is on the builder page
  useEffect(() => {
    // If isBuilder
    if (!isBuilder) return;
    const interval = setInterval(() => {
      // If nodes and edges are not empty
      if (nodes.length === 0 && edges.length === 0) return;

      saveInBackground(nodes, edges);
    }, 15000);
    return () => clearInterval(interval);
  }, [nodes, edges, bid]);

  // Handle cancel click
  const handleCancel = () => {
    const url =
      user.role === "superadmin"
        ? `/super-admin/chatbot/${bid}/builder`
        : user.role === "marketing"
        ? `/marketing-admin/chatbot/${bid}/builder`
        : `/a/ws/${user.workspaceId}/u/${user._id.slice(
            -5
          )}/chatbot/${bid}/builder`;

    // Push to the builder page
    history.push(url);
  };

  // Function to open/close the drawer
  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  // Handle preview click
  const handlePreview = () => {
    let updatedData = {
      ...currentBotFlowData,
      elements: {
        nodes,
        edges,
      },
    };

    // Dispatch action to set the current bot flow data
    dispatch(setCurrentBotFlowDataAction(updatedData));

    // Set modal visible
    setModalVisible(true);
  };

  // Handle save chatbot
  const saveFlow = useCallback(() => {
    setSaving(true);

    // 'selected' attribute is set to false for all nodes
    const modifiedNodes = nodes.map((node) => ({
      ...node,
      selected: false,
    }));

    // Combine modifiedNodes and edges to elements
    const elements = {
      nodes: modifiedNodes,
      edges,
    };

    // Also save version to v2
    // Save to API
    updateChatbotById(bid, { elements, version: "v2" })
      .then(() => {
        SuccessNotification("Chat flow saved");
      })
      .catch((err) => {
        ErrorNotification("Error saving chat flow");
        console.log("SAVE_FLOW_ERROR", err);
      });

    setSaving(false);
  }, [nodes, edges]);

  // Save in the background
  const saveInBackground = (nodes: any, edges: any) => {
    setSaving(true);

    // 'selected' attribute is set to false for all nodes
    const modifiedNodes = nodes.map((node: any) => ({
      ...node,
      selected: false,
    }));

    // Combine modifiedNodes and edges to elements
    const elements = {
      nodes: modifiedNodes,
      edges,
    };

    // Make an API call to save the flow
    updateChatbotById(bid, { elements, version: "v2" })
      .then(() => {})
      .catch((err) => {
        console.log("SAVE_FLOW_BG_ERROR", err);
      });

    setTimeout(() => {
      setSaving(false);
    }, 2000);
  };

  // Handle close modal
  const closeModal = () => {
    setModalVisible(false);
  };

  // Handle set mobile / desktop preview
  const toggleModalSize = (value: any) => {
    setModalSize(value);
  };

  // Function to handle publish action
  const handlePublish = () => {
    // Only allow max 20 published versions, if more, ask them to delete
    if (publishedVersionHistory.length >= 40) {
      ErrorNotification(
        "You have reached the maximum limit of 40 published versions. Please delete some versions to publish a new one."
      );
      return;
    }

    // Set publish loading to true
    setPublishLoading(true);

    // 'selected' attribute is set to false for all nodes
    const modifiedNodes = nodes.map((node) => ({
      ...node,
      selected: false,
    }));

    // Combine modifiedNodes and edges to elements
    const elements = {
      nodes: modifiedNodes,
      edges,
    };

    // Dispatch action to publish the chatbot
    dispatch(publishChatbotAction(bid, elements, setPublishLoading));
  };

  // Function to delete a version
  const handleDeleteVersion = (versionId: any) => {
    Modal.confirm({
      title:
        "Are you sure you want to delete this version? You cannot undo this action.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          setDeleting(true);

          // Dispatch the delete action
          dispatch(deleteChatbotFlowVersionAction(bid, versionId, setDeleting));
        } catch (error) {
          ErrorNotification("Error deleting published version");
        }
      },
    });
  };

  // Function to view a version from the history
  const handleViewVersion = (versionId: any) => {
    const basePath =
      user.role === "superadmin"
        ? `/super-admin/chatbot/${bid}/version/${versionId}/builder`
        : user.role === "marketing"
        ? `/marketing-admin/chatbot/${bid}/version/${versionId}/builder`
        : `/a/ws/${user.workspaceId}/u/${user._id.slice(
            -5
          )}/chatbot/${bid}/version/${versionId}/builder`;

    // Push to the version builder page
    history.push(basePath);
  };

  // Function to restore a version
  const handleRestoreVersion = () => {
    Modal.confirm({
      title:
        "Are you sure you want to restore this version? Your existing flow changes will be replaced.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Implement restore logic
          setIsRestoring(true);

          // Make an API call to restore the version
          restorePublishedChatbotFlowVersionById(bid, vid)
            .then(() => {
              SuccessNotification("Chat flow restored");

              // Determine the base URL based on user role
              const url =
                user.role === "superadmin"
                  ? `/super-admin/chatbot/${bid}/builder`
                  : user.role === "marketing"
                  ? `/marketing-admin/chatbot/${bid}/builder`
                  : `/a/ws/${user.workspaceId}/u/${user._id.slice(
                      -5
                    )}/chatbot/${bid}/builder`;

              // Push to the builder page
              history.push(url);
            })
            .catch((err) => {
              console.log("RESTORE_FLOW_ERROR", err);
              ErrorNotification("Error restoring published version");
            });
        } catch (error) {
          ErrorNotification("Error restoring published version");
        }
      },
    });
  };

  return (
    <div
      style={{
        padding: "10px",
        background: "white",
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        borderRadius: isBuilder ? "8px 8px 0px 0px" : "0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Bot Logo */}
        <img
          src={currentBotFlowData?.logo ? currentBotFlowData?.logo : BotLogo}
          alt="Chatbot Logo"
          style={{
            marginRight: "15px",
            width: "40px",
            height: "auto",
            borderRadius: "50%",
          }}
        />

        {/* Bot Name */}
        <h4 style={{ margin: 0, fontSize: "16px" }}>
          {currentBotFlowData?.name?.length > 30
            ? currentBotFlowData?.name?.slice(0, 30) + "..."
            : currentBotFlowData?.name}
        </h4>
      </div>

      {/* Middle Flow Section */}
      {!isVersion && (
        <div className="builder-header-nav-container">
          <HeaderNav
            user={user}
            bid={bid}
            isBuilder={isBuilder}
            isCustomize={isCustomize}
            isShare={isShare}
            isAnalytics={isAnalytics}
          />
        </div>
      )}

      {/* Right Section */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {isBuilder && (
          <>
            {/* Preview */}
            <Tooltip title="Flow preview">
              <Button
                id="flow-preview"
                className="header__Btn"
                style={{ backgroundColor: "blue", color: "white" }}
                onClick={() => handlePreview()}
                size="small"
                disabled={saving || publishLoading || deleting || error}
              >
                {t("flowBuilder.header.preview")}
              </Button>
            </Tooltip>

            {/* Save */}
            <Tooltip title="Save current flow">
              <Button
                id="save-flow"
                className="header__Btn"
                style={{ backgroundColor: "green", color: "white" }}
                onClick={saveFlow}
                loading={saving}
                size="small"
                disabled={saving || publishLoading || deleting || error}
              >
                {saving
                  ? t("flowBuilder.header.saving")
                  : t("flowBuilder.header.save")}
              </Button>
            </Tooltip>

            {/* Publish Button */}
            <Tooltip title="Publish current flow">
              <Button
                id="publish-flow"
                size="small"
                className="header__Btn"
                onClick={handlePublish}
                loading={publishLoading}
                style={{
                  marginRight: "10px",
                }}
                disabled={saving || publishLoading || deleting || error}
              >
                {publishLoading
                  ? t("flowBuilder.header.publishing")
                  : t("flowBuilder.header.publish")}
              </Button>
            </Tooltip>

            {/* History Icon */}
            <Tooltip title="View publish history">
              <HistoryOutlined
                onClick={toggleDrawer}
                disabled={saving || publishLoading || deleting || error}
                style={{
                  fontSize: "22px",
                  marginLeft: "6px",
                  marginRight: "6px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>

            {/* Drawer for showing published versions */}
            <PublishHistoryDrawer
              drawerVisible={drawerVisible}
              toggleDrawer={toggleDrawer}
              publishedVersionHistory={publishedVersionHistory}
              handleViewVersion={handleViewVersion}
              handleDeleteVersion={handleDeleteVersion}
              deleting={deleting}
            />
          </>
        )}

        {isVersion && (
          <>
            {/* Restore Button */}
            <Button
              size="small"
              className="header__Btn"
              style={{ backgroundColor: "orange", color: "white" }}
              onClick={handleRestoreVersion}
              loading={isRestoring}
              disabled={isRestoring}
            >
              {t("flowBuilder.header.restore")}
            </Button>

            {/* Cancel Button */}
            <Button
              size="small"
              className="header__Btn"
              style={{ backgroundColor: "grey", color: "white" }}
              onClick={handleCancel}
            >
              {t("flowBuilder.header.cancel")}
            </Button>
          </>
        )}
      </div>

      {/* Preview Modal */}
      <DraggableModal visible={modalVisible} positionValueY={50}>
        {/* Content of the modal */}
        <div
          style={{
            height: "75vh",
            minWidth: isMobile ? "90vw" : "25vw",
            width: isMobile ? "90vw" : modalSize === "mobile" ? "22vw" : "45vw",
            transition: "all 0.1s ease-out",
            overflow: "hidden",
          }}
        >
          {/* Modal body */}
          <div>
            <ChatbotFlowPreview
              closeModal={closeModal}
              toggleModalSize={toggleModalSize}
            />
          </div>
        </div>
      </DraggableModal>
    </div>
  );
};

export default Header;
