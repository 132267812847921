import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import {
  getAdminTeamDetails,
  assignChatbotToMember,
  unassignChatbotFromMember,
} from "@api/user/superAdmin";
import {
  Table,
  Card,
  Row,
  Col,
  Typography,
  Tag,
  Button,
  Space,
  Modal,
  message,
  Popconfirm,
} from "antd";
import {
  UserOutlined,
  RobotOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const TeamDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allChatbots, setAllChatbots] = useState([]);

  const fetchTeamDetails = async () => {
    try {
      const res = await getAdminTeamDetails(id);
      const team = res.data.team;
      setTeamData(team);
      const chatbots = new Set();
      team.forEach((member) => {
        member.chatbots.forEach((bot) => chatbots.add(bot._id));
      });
      setAllChatbots(Array.from(chatbots));
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamDetails();
  }, [id]);

  const handleAssignChatbot = async (userId, chatbotId) => {
    try {
      await assignChatbotToMember(userId, chatbotId);
      message.success("Chatbot assigned successfully");
      await fetchTeamDetails(); // Refresh the data
    } catch (error) {
      console.error("Error assigning chatbot:", error);
      message.error("Failed to assign chatbot");
    }
  };

  const handleUnassignChatbot = async (userId, chatbotId) => {
    try {
      await unassignChatbotFromMember(userId, chatbotId);
      message.success("Chatbot unassigned successfully");
      await fetchTeamDetails(); // Refresh the data
    } catch (error) {
      console.error("Error unassigning chatbot:", error);
      message.error("Failed to unassign chatbot");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          <UserOutlined />
          <Text strong>{text}</Text>
        </Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => (
        <Tag color={role === "admin" ? "blue" : "green"}>
          {role.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Chatbots",
      dataIndex: "chatbots",
      key: "chatbots",
      render: (chatbots) => (
        <Space wrap>
          {chatbots.slice(0, 3).map((bot) => (
            <Tag key={bot._id} icon={<RobotOutlined />} color="purple">
              {bot.name}
            </Tag>
          ))}
          {chatbots.length > 3 && (
            <Tag color="default">+{chatbots.length - 3} more</Tag>
          )}
        </Space>
      ),
    },
  ];

  const ChatbotComparisonModal = () => {
    const comparisonColumns = [
      {
        title: "Team Member",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 150,
      },
      ...allChatbots.map((botId) => ({
        title:
          teamData
            .find((member) => member.chatbots.some((bot) => bot._id === botId))
            ?.chatbots.find((bot) => bot._id === botId)?.name || "Unknown",
        dataIndex: botId,
        key: botId,
        render: (hasAccess, record) =>
          hasAccess ? (
            <Popconfirm
              title="Are you sure you want to unassign this chatbot?"
              onConfirm={() => handleUnassignChatbot(record._id, botId)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                icon={<MinusOutlined />}
                size="small"
              >
                Unassign
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              size="small"
              onClick={() => handleAssignChatbot(record._id, botId)}
            >
              Assign
            </Button>
          ),
      })),
    ];

    const dataSource = teamData.map((member) => {
      const row = { name: member.name, _id: member._id._id };
      allChatbots.forEach((botId) => {
        row[botId] = member.chatbots.some((bot) => bot._id === botId);
      });
      return row;
    });

    return (
      <Modal
        title="Chatbot Comparison and Assignment"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width="90%"
        style={{ top: 20 }}
        bodyStyle={{ height: "calc(90vh - 110px)", overflowY: "auto" }}
        footer={null}
      >
        <Table
          columns={comparisonColumns}
          dataSource={dataSource}
          scroll={{ x: "max-content", y: "calc(90vh - 200px)" }}
          pagination={false}
        />
      </Modal>
    );
  };

  return (
    <Card
      style={{
        borderRadius: "0",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space
            align="center"
            style={{
              marginBottom: 16,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Title level={2}>Team Details</Title>
            <Space>
              <Button type="primary" onClick={() => setIsModalVisible(true)}>
                View Chatbot Comparison
              </Button>
              <Button onClick={() => history.goBack()}>Go Back</Button>
            </Space>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={teamData}
            rowKey={(record) => record._id._id}
            loading={loading}
            pagination={false}
          />
        </Col>
      </Row>
      <ChatbotComparisonModal />
    </Card>
  );
};

export default TeamDetails;
