import React, { useState, useEffect } from "react";
import { Spin, Button } from "antd";
import { getMemberChangelogs } from "../../../../api/user/member"; // Adjust the import path based on your API structure
import NoChangelogsImage from "../../../../assets/images/pages/chats/selectChat.svg"; // Use an appropriate placeholder
import { useTranslation } from "react-i18next";
import "../../../../assets/css/Changelog.css";

const Changelog = () => {
  const { t } = useTranslation();
  const [changelogs, setChangelogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 2;
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchChangelogs(page, pageSize);
  }, []);

  const fetchChangelogs = async (currentPage: any, currentPageSize: any) => {
    setLoading(true);
    try {
      const response = await getMemberChangelogs(currentPage, currentPageSize);
      if (response.data) {
        setChangelogs((prevChangelogs) => [
          ...prevChangelogs,
          ...response.data.changelogs,
        ]);
        setHasMore(response.data.hasMore);
      }
    } catch (error) {
      console.error("Error fetching changelogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchChangelogs(nextPage, pageSize);
  };

  return (
    <div>
      {loading && (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(50vh - 50px)",
            overflow: "hidden",
            fontSize: "20px",
          }}
        />
      )}

      {!loading && changelogs.length === 0 && (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <img
            src={NoChangelogsImage}
            alt="no-changelogs"
            style={{ width: "40%", height: "40%", objectFit: "contain" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            {t("changelog.noChangelogFound")}
          </p>
        </div>
      )}

      {changelogs.map((changelog, index) => (
        <div key={index} className="changelog-container">
          <h1 className="changelog-version">
            {t("changelog.version")} {changelog.version}
          </h1>
          <hr />
          <p className="changelog-date">
            {t("changelog.releaseDate")}{" "}
            {new Date(changelog.releaseDate).toLocaleDateString()}
          </p>
          <div
            className="changelog-updates"
            dangerouslySetInnerHTML={{ __html: changelog.updates }}
          />
        </div>
      ))}

      {!loading && hasMore && (
        <div style={{ textAlign: "center", margin: "10px 0" }}>
          <Button onClick={handleLoadMore}>{t("changelog.loadMore")}</Button>
        </div>
      )}
    </div>
  );
};

export default Changelog;
